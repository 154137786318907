import { ApolloError } from '@apollo/client'
import {
  DataPoint,
  Document,
  FeedbackHighlightInput,
  FeedbackStatus,
  FeedbackTypeTemplate,
  FirstLookStatus,
  UserLearningOptionName,
  UserOption,
  VerifiedSample
} from '../../graphql/codegen/schemas'
import { FeedbackDocumentPageWrapper } from '../../components/Feedback'
import { Opening, useOpening } from '@hoologic/use-opening'
import { _SystemPromptData } from '../../components/Feedback/SystemPromptDialog'
import { size } from 'lodash'
import { useAppContext } from '../../app'
import { useContextInit } from '../../hooks/useContextInit'
import {
  useLatestVerifiedSampleLazyQuery,
  useLatestVerifiedSampleStatusLazyQuery,
  usePublishDataPointFieldMutation,
  useSubmitFeedbackMutation
} from '../../graphql/codegen/hooks'
import { useLocation, useParams } from 'react-router-dom'
import ErrorPage from '../ErrorPage/ErrorPage'
import React, { Dispatch, SetStateAction, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useQString from '../../hooks/useQString'

// types

type _FeedbackPageContext = {
  dataPoint?: DataPoint | null
  document?: Document | null
  feedbackComment: string
  feedbackDataTableOpening: Opening
  feedbackHighlightList: any[]
  feedbackPanelRef: React.MutableRefObject<HTMLDivElement | null>
  feedbackSessionEventList: any[]
  feedbackStatus?: FeedbackStatus | null
  isDocumentReady: boolean
  isLoadingFeedbackPage: boolean
  isPublishMutationPending: boolean
  isPublishingInProgress: boolean
  isSampleValueCorrect: boolean
  isSubmittingFeedback: boolean
  latestVerifiedSample: VerifiedSample | null
  selectedExtractionFeedbackOption: FeedbackTypeTemplate | null
  selectedLearningFeedbackOption: UserLearningOptionName | null
  setFeedbackComment: Dispatch<SetStateAction<string>>
  setFeedbackHighlightList: Dispatch<SetStateAction<any[]>>
  setIsDocumentReady: Dispatch<SetStateAction<boolean>>
  setSelectedExtractionFeedbackOption: Dispatch<SetStateAction<FeedbackTypeTemplate | null>>
  setSelectedLearningFeedbackOption: Dispatch<SetStateAction<UserLearningOptionName | null>>
  setSystemPromptData: Dispatch<SetStateAction<_SystemPromptData>>
  submitFeedback: () => void
  submitFeedbackMutationError: ApolloError | undefined
  systemPromptData: _SystemPromptData
  systemPromptDialogOpening: Opening
  userOptionList: FeedbackTypeTemplate[]
}

// enums

export enum ExtractionFeedbackOptionLabels {
  COMPLETELY_MISSED = 'Completely missed',
  CORRECTLY_EXTRACTED = 'Correctly extracted',
  CORRECTLY_NOT_EXTRACTED = 'Correctly not extracted',
  INCORRECTLY_EXTRACTED = 'Incorrectly extracted'
}

export enum FeedbackTypes {
  DATA_POINT = 'DATA-POINT',
  DEAL = 'DEAL',
  DOCUMENT = 'DOCUMENT',
  PRE_ANNOTATION = 'PRE-ANNOTATION'
}

export enum LearningFeedbackOptionLabels {
  LEARNING_IS_CORRECT = 'Learning is correct',
  LEARNING_NEEDS_IMPROVEMENT = 'Learning needs improvement'
}

// constants

export const FEEDBACK_PAGE_PATH = '/feedback/documents/'

// context

const FeedbackPageContext = createContext<_FeedbackPageContext | null>(null)

// hooks

export const useFeedbackPageContext = () => useContextInit(FeedbackPageContext)

export const useIsFeedbackPage = () => {
  const { pathname } = useLocation()

  return pathname.startsWith(FEEDBACK_PAGE_PATH)
}

// functions

const extractFeedbackHighlights = (sample: VerifiedSample) =>
  (sample?.feedback?.feedback_highlights?.edges?.map(edge => ({
    internal_name: edge?.node?.internal_name,
    positions: edge?.node?.positions?.edges?.map(position => ({
      page_number: position?.node?.page_number,
      page_height: position?.node?.page_height,
      page_width: position?.node?.page_width,
      bounding_rect: {
        x1: position?.node?.bounding_rect?.x1,
        x2: position?.node?.bounding_rect?.x2,
        y1: position?.node?.bounding_rect?.y1,
        y2: position?.node?.bounding_rect?.y2
      },
      rects: (position?.node?.rects?.edges || []).map(rectEdge => ({
        x1: rectEdge?.node?.x1,
        x2: rectEdge?.node?.x2,
        y1: rectEdge?.node?.y1,
        y2: rectEdge?.node?.y2
      }))
    }))
  })) || []) as FeedbackHighlightInput[]

// components

export default function FeedbackPage() {
  const { documentId } = useParams<{ documentId: string }>()
  const { autoPublish, feedbackDataPointFieldId } = useQString()
  const { setErrorMessage, setExtendedErrorMessage } = useAppContext()

  const [feedbackComment, setFeedbackComment] = useState('')
  const [feedbackHighlightList, setFeedbackHighlightList] = useState<FeedbackHighlightInput[]>([])
  const [isDocumentReady, setIsDocumentReady] = useState(false)
  const [isPublishingInProgress, setIsPublishingInProgress] = useState(false)
  const [selectedExtractionFeedbackOption, setSelectedExtractionFeedbackOption] = useState<FeedbackTypeTemplate | null>(null)
  const [selectedLearningFeedbackOption, setSelectedLearningFeedbackOption] = useState<UserLearningOptionName | null>(null)
  const [systemPromptData, setSystemPromptData] = useState<_SystemPromptData>(null)

  const feedbackPanelRef = useRef<HTMLDivElement>(null)

  const feedbackDataTableOpening = useOpening()
  const systemPromptDialogOpening = useOpening()

  // latest verified sample – lazy query

  const [
    getLatestVerifiedSample,
    { called: isLatestVerifiedSampleQueryCalled, data: latestVerifiedSampleData, error: latestVerifiedSampleError, loading: isLatestVerifiedSampleLoading }
  ] = useLatestVerifiedSampleLazyQuery({
    errorPolicy: 'all', // TODO: The backend sends `data` and `errors` together in a 200 response. This prevents reading `data` when benign errors are present.
    fetchPolicy: 'network-only',
    variables: { dataPointFieldId: feedbackDataPointFieldId as string, documentId }
  })

  const isLoadingFeedbackPage = useMemo(() => Boolean(!isDocumentReady || isLatestVerifiedSampleLoading), [isDocumentReady, isLatestVerifiedSampleLoading])

  const latestVerifiedSample = useMemo(() => (latestVerifiedSampleData?.latest_verified_sample as VerifiedSample) || null, [latestVerifiedSampleData])

  const isSampleValueCorrect = useMemo(
    () =>
      latestVerifiedSample?.feedback?.user_option === UserOption.CorrectlyExtracted ||
      latestVerifiedSample?.feedback?.user_option === UserOption.CorrectlyNotExtracted ||
      selectedExtractionFeedbackOption?.user_option === UserOption.CorrectlyExtracted ||
      selectedExtractionFeedbackOption?.user_option === UserOption.CorrectlyNotExtracted,
    [latestVerifiedSample, selectedExtractionFeedbackOption]
  )

  // Ensure `dataPoint` and `document` reference the same `latestVerifiedSample.data_point_annotations` for proper display of annotations.
  // The `annotations` field is structured differently to accommodate the requirements of `InputRow` and `FeedbackPDFHighlighter`, respectively.
  const dataPoint = useMemo(
    () =>
      ({
        ...(latestVerifiedSample?.data_point_snapshot || {}),
        annotations: [{ annotations: latestVerifiedSample?.data_point_annotations?.edges?.filter(Boolean).map(edge => edge?.node) || [] }]
      } as DataPoint),
    [latestVerifiedSample]
  )

  const document = useMemo(
    () =>
      ({
        ...(latestVerifiedSample?.data_point_snapshot?.document || {}),
        annotations: { edges: latestVerifiedSample?.data_point_annotations?.edges?.filter(Boolean) || [] }
      } as Document),
    [latestVerifiedSample]
  )

  const feedbackSessionEventList = useMemo(() => {
    const rawEvents =
      latestVerifiedSample?.feedback?.feedback_session_events?.edges?.filter(Boolean).map((edge: any) => ({
        event_log: JSON.parse(edge?.node?.event_log || '{}'),
        feedback_session_event_type: edge?.node?.feedback_session_event_type
      })) || []

    const mergedEvents = []

    for (let i = 0; i < rawEvents.length; i++) {
      const currentEvent = rawEvents[i]

      if (currentEvent.feedback_session_event_type !== 'USER_FEEDBACK') {
        if (rawEvents[i + 1] && rawEvents[i + 1].feedback_session_event_type === 'USER_FEEDBACK') {
          mergedEvents.push({
            ...currentEvent.event_log,
            ...rawEvents[i + 1].event_log,
            feedback_session_event_type: currentEvent.feedback_session_event_type
          })
          i++
        } else {
          mergedEvents.push({
            ...currentEvent.event_log,
            feedback_session_event_type: currentEvent.feedback_session_event_type
          })
        }
      }
    }

    return mergedEvents
  }, [latestVerifiedSample])

  const feedbackStatus = useMemo(() => latestVerifiedSample?.status, [latestVerifiedSample])

  const userOptionList = useMemo(() => {
    const { feedback_type_templates } = latestVerifiedSample?.feedback?.feedback_template || {}
    const hasAnnotations = size(document?.annotations?.edges) > 0

    // This can be hardcoded because the backend will never need to provide a `user_feedback_template` for a correct option.
    const correctOption = { user_feedback_template: '', user_option: hasAnnotations ? UserOption.CorrectlyExtracted : UserOption.CorrectlyNotExtracted }

    const incorrectOption = feedback_type_templates?.edges?.find(edge =>
      hasAnnotations ? edge?.node?.user_option === UserOption.IncorrectlyExtracted : edge?.node?.user_option === UserOption.CompletelyMissed
    )?.node

    return [correctOption, incorrectOption] as FeedbackTypeTemplate[]
  }, [document, latestVerifiedSample])

  // latest verified sample status – lazy query

  const [getLatestVerifiedSampleStatus, { error: latestVerifiedSampleStatusError }] = useLatestVerifiedSampleStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data?.latest_verified_sample?.id !== latestVerifiedSample?.id) {
        // TODO: Replace with `getLatestVerifiedSample` and figure out how to handle the buggy behavior.
        window.location.reload()
      }
    }
  })

  // publish data point field – mutation

  const [publishDataPointField, { loading: isPublishMutationPending }] = usePublishDataPointFieldMutation({
    onCompleted: () => setIsPublishingInProgress(true)
  })

  // submit feedback – mutation

  const [submitFeedbackMutation, { error: submitFeedbackMutationError, loading: isSubmittingFeedback }] = useSubmitFeedbackMutation({
    onCompleted: () => {
      setFeedbackComment('')

      if (autoPublish && feedbackDataPointFieldId) {
        publishDataPointField({ variables: { dataPointFieldId: feedbackDataPointFieldId as string } })
      }
    }
  })

  const submitFeedback = useCallback(() => {
    if (!latestVerifiedSample || !selectedExtractionFeedbackOption?.user_option) return

    const feedbackInput = {
      feedback_highlights: feedbackHighlightList,
      user_feedback: isSampleValueCorrect ? '' : feedbackComment,
      user_learning_option: selectedLearningFeedbackOption,
      user_option: selectedExtractionFeedbackOption.user_option,
      verified_sample_id: latestVerifiedSample.id
    }

    submitFeedbackMutation({ variables: { feedbackInput } })
  }, [
    submitFeedbackMutation,
    feedbackComment,
    feedbackHighlightList,
    isSampleValueCorrect,
    latestVerifiedSample,
    selectedExtractionFeedbackOption,
    selectedLearningFeedbackOption
  ])

  // context value

  const context = useMemo(
    () => ({
      dataPoint,
      document,
      feedbackComment,
      feedbackDataTableOpening,
      feedbackHighlightList,
      feedbackPanelRef,
      feedbackSessionEventList,
      feedbackStatus,
      isDocumentReady,
      isLoadingFeedbackPage,
      isPublishMutationPending,
      isPublishingInProgress,
      isSampleValueCorrect,
      isSubmittingFeedback,
      latestVerifiedSample,
      selectedExtractionFeedbackOption,
      selectedLearningFeedbackOption,
      setFeedbackComment,
      setFeedbackHighlightList,
      setIsDocumentReady,
      setSelectedExtractionFeedbackOption,
      setSelectedLearningFeedbackOption,
      setSystemPromptData,
      submitFeedback,
      submitFeedbackMutationError,
      systemPromptData,
      systemPromptDialogOpening,
      userOptionList
    }),
    [
      dataPoint,
      document,
      feedbackComment,
      feedbackDataTableOpening,
      feedbackHighlightList,
      feedbackSessionEventList,
      feedbackStatus,
      isDocumentReady,
      isLoadingFeedbackPage,
      isPublishMutationPending,
      isPublishingInProgress,
      isSampleValueCorrect,
      isSubmittingFeedback,
      latestVerifiedSample,
      selectedExtractionFeedbackOption,
      selectedLearningFeedbackOption,
      submitFeedback,
      submitFeedbackMutationError,
      systemPromptData,
      systemPromptDialogOpening,
      userOptionList
    ]
  )

  // effects

  useMemo(() => {
    if (!documentId || !feedbackDataPointFieldId) return

    getLatestVerifiedSample()
  }, [documentId, feedbackDataPointFieldId, getLatestVerifiedSample])

  useEffect(() => {
    const error = latestVerifiedSampleStatusError || submitFeedbackMutationError

    if (error) {
      const { message, stack } = error

      setErrorMessage(message)
      setExtendedErrorMessage(stack || message)
    }
  }, [latestVerifiedSampleStatusError, setErrorMessage, setExtendedErrorMessage, submitFeedbackMutationError])

  useEffect(() => {
    const sampleGenerationEvent = feedbackSessionEventList.find((event: any) => event?.feedback_session_event_type === 'SAMPLE_GENERATION')

    if (!sampleGenerationEvent) return

    setSelectedExtractionFeedbackOption(userOptionList.find(option => option?.user_option === sampleGenerationEvent?.user_option) || null)
  }, [feedbackSessionEventList, userOptionList])

  useEffect(() => {
    if (latestVerifiedSample?.feedback?.feedback_highlights) {
      setFeedbackHighlightList(extractFeedbackHighlights(latestVerifiedSample))
    }
  }, [latestVerifiedSample])

  useEffect(() => {
    if (latestVerifiedSample?.first_look_status === FirstLookStatus.FeedbackInProgressExtractionInProgress) {
      const intervalId = setInterval(() => {
        getLatestVerifiedSampleStatus({
          variables: { dataPointFieldId: dataPoint?.data_point_field?.id as string, documentId }
        })
      }, 10000)

      return () => clearInterval(intervalId)
    }
  }, [dataPoint, documentId, getLatestVerifiedSampleStatus, latestVerifiedSample])

  // render

  if (isLatestVerifiedSampleQueryCalled && !latestVerifiedSample && latestVerifiedSampleError)
    return <ErrorPage message={`Document not found.\n\n${latestVerifiedSampleError.message}`} />

  if (!document) return <ErrorPage message="You do not have access to this document." />

  return (
    <FeedbackPageContext.Provider value={context}>
      <FeedbackDocumentPageWrapper />
    </FeedbackPageContext.Provider>
  )
}
