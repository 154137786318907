import { Box, Button, List, ListItem, Typography } from '@mui/material'
import { CollisionTypes } from '../ChecklistTab/SharedInputs/CollisionTypeInput'
import { DELETE } from '../../../../../../utils/cci'
import { DataTableMatchRules } from '../ChecklistTab/SharedInputs/MatchRule'
import { ModalOptions, useCciMainContext } from '../../../../CCI_Main'
import { Sources } from '../ChecklistTab/CreateInputs/SourceInput'
import { grey } from '@mui/material/colors'
import { isEmpty, size } from 'lodash'
import { useCciChecklistGptContext } from './CCI_RightPanel_ChecklistGptTab'
import CircleIcon from '@mui/icons-material/Circle'
import React, { FC, useEffect, useState } from 'react'
import WithTooltip from '../../../../../../components/WithTooltip'

// functions

const validateCreateViewInputs = (values?: any, setDisabledMessage?: any) => {
  const errors: any[] = []

  if (!values?.name) {
    errors.push('Field Name')
  }

  if (!values?.action_type_id) {
    errors.push('Action Type')
  }

  if (values?.action_type_id && !values?.source) {
    errors.push('Source System')
  }

  if (!values?.group) {
    errors.push('Section')
  }

  if (values?.action_type_id && values?.source && !values?.field_type) {
    errors.push('Field Type')
  }

  if (values?.table_composition_config && isEmpty(values.table_composition_config)) {
    errors.push('This action type requires at least one column')
  }

  if (values?.source === Sources.INTERNAL && !values?.collision_type) {
    errors.push('This action type requires a collision type')
  }

  if ((values?.field_type === 'DROP_DOWN' || values?.field_type === 'MULTI_SELECT_DROP_DOWN') && (!values?.options?.length || values?.options?.length < 1)) {
    errors.push('Dropdown and Multi-Dropdowns must have at least one option')
  }

  if (values?.field_type === 'DATE' && !values?.value_format) {
    errors.push('Value Format is required when Field Type is DATE')
  }

  if (values?.source === Sources.MATCHING) {
    if (!values?.match_rule) errors.push('Match Rule')

    if (size(values?.match_children_fields) < 2) {
      errors.push('Match Fields (minimum two)')
    } else if (
      [
        DataTableMatchRules.TABLE_MATCHING,
        DataTableMatchRules.TABLE_MATCHING_ALPHA,
        DataTableMatchRules.TABLE_MATCHING_BETA,
        DataTableMatchRules.TABLE_MATCHING_GAMMA
      ].includes(values?.match_rule) &&
      size(values?.match_children_fields) > 2
    ) {
      errors.push('Match Fields (maximum two for “Table Matching” match rule)')
    }
  }

  if (values?.collision_type === CollisionTypes.CASCADE && isEmpty(values?.resolution_strategy)) {
    errors.push('Cascade collision type requires a resolution strategy')
  }

  if (values?.options?.length) {
    const formattedValues = values?.options?.map((item: any) => item?.option) || []

    const duplicateTags =
      formattedValues.filter((item?: any) => {
        if (!item || item === DELETE) return false

        // Each item should match itself once, but if the count is more than one, then there are duplicates in the array.
        const duplicates = formattedValues?.filter((newValue?: any) => item === newValue)

        return duplicates?.length > 1
      }) || []

    if (duplicateTags.length > 0) {
      errors.push('Options must be unique')
    }
  }

  if (errors.length > 0) {
    setDisabledMessage(
      <Box sx={{ p: 1 }}>
        <Typography sx={{ fontSize: 14 }}>Missing required values:</Typography>

        <List sx={{ pb: 0 }}>
          {errors.map((error: string) => (
            <ListItem key={error} sx={{ alignItems: 'baseline', gap: 1, py: 0.5 }}>
              <CircleIcon sx={{ bottom: 1, fontSize: 6, position: 'relative' }} />

              <Typography sx={{ fontSize: 14 }}>{error}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    )
  } else {
    setDisabledMessage('')
  }
}

const validateEditViewInputs = (selectedItem: any, values: any, setDisabledMessage: any, existingErrors: string[]) => {
  const errors = [...existingErrors]

  if (
    isEmpty(existingErrors) &&
    (!values || (Object.keys(values).length === 0 && values.constructor === Object)) &&
    (selectedItem?.source !== Sources.INTERNAL || !isEmpty(selectedItem?.extraction_field_type))
  ) {
    errors.push('No changes have been made')
  }

  if (selectedItem?.source === 'INTERNAL' && !selectedItem?.collision_type && !values?.collision_type) {
    errors.push('Collision Type is required for this field')
  }

  if (
    (selectedItem?.field_type === 'DROP_DOWN' ||
      selectedItem?.field_type === 'MULTI_SELECT_DROP_DOWN' ||
      values?.field_type === 'DROP_DOWN' ||
      values?.field_type === 'MULTI_SELECT_DROP_DOWN') &&
    (!selectedItem?.options?.length || selectedItem?.options?.length < 1) &&
    (!values?.options?.length || values?.options?.length < 1)
  ) {
    errors.push('Dropdown and Multi-Dropdowns must have at least one option')
  }

  if ((selectedItem?.field_type === 'DATE' || values?.field_type === 'DATE') && !selectedItem?.value_format && !values?.value_format) {
    errors.push('Value Format is required when Field Type is DATE')
  }

  if (selectedItem?.options?.length || values?.options?.length) {
    const formattedValues = values?.options?.map((item: any) => item?.newValue || item?.currentValue) || []

    const duplicateTags =
      formattedValues.filter((item?: any) => {
        if (!item || item === DELETE) return false

        const newValueDuplicates = formattedValues?.filter((newValue?: any) => item === newValue)

        if (newValueDuplicates?.length > 1) return true

        const currentValueDuplicates =
          selectedItem?.options?.filter((currentValue?: any, currentValueIndex?: any) => {
            if (!currentValue) return false

            // Ignore currentValue if there is a new value for it; otherwise return true if there is a match.
            return Boolean(currentValue && !formattedValues[currentValueIndex] && item === currentValue)
          }) || []

        return currentValueDuplicates?.length > 1
      }) || []

    if (duplicateTags.length > 0) {
      errors.push('Options must be unique')
    }
  }

  if (
    (values?.collision_type === CollisionTypes.CASCADE && isEmpty(values?.resolution_strategy)) ||
    (selectedItem?.collision_type === CollisionTypes.CASCADE && !values?.collision_type && values?.resolution_strategy && isEmpty(values?.resolution_strategy))
  ) {
    errors.push('Cascade collision type requires a resolution strategy')
  }

  if (errors.length > 0) {
    setDisabledMessage(
      <Box sx={{ p: 1 }}>
        <Typography sx={{ fontSize: 14 }}>Missing required values:</Typography>

        <List sx={{ pb: 0 }}>
          {errors.map((error: string) => (
            <ListItem key={error} sx={{ alignItems: 'baseline', gap: 1, py: 0.5 }}>
              <CircleIcon sx={{ bottom: 1, fontSize: 6, position: 'relative' }} />

              <Typography sx={{ fontSize: 14 }}>{error}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    )
  } else {
    setDisabledMessage('')
  }
}

// components

export const ConfigurationDetailsFooter: FC = () => {
  const { errors, openModal, selectedItem, setActiveComponent } = useCciMainContext()
  const { extractionMethodId, fieldValues, isCreateView, isEditView, submitExtractionMethod } = useCciChecklistGptContext()
  const [disabledMessage, setDisabledMessage] = useState('')

  // functions

  const openCreateNewFieldModal = () => {
    openModal({
      content: { dataPointName: fieldValues?.name, extractionMethodId, submitExtractionMethod, values: fieldValues },
      modalOption: ModalOptions.CREATE_NEW_FIELD,
      title: ''
    })
  }

  const openReviewEditsModal = () => {
    openModal({
      content: { dataPointId: selectedItem.id, dataPointName: selectedItem.name, values: fieldValues },
      modalOption: ModalOptions.REVIEW_EDITS
    })
  }

  // effects

  useEffect(() => {
    if (isCreateView) {
      validateCreateViewInputs(fieldValues, setDisabledMessage)
    }
  }, [fieldValues, isCreateView])

  useEffect(() => {
    if (isEditView) {
      validateEditViewInputs(selectedItem, fieldValues, setDisabledMessage, errors)
    }
  }, [errors, isEditView, fieldValues, selectedItem])

  // render

  return (
    <Box sx={{ borderTop: `1px solid ${grey[300]}`, display: 'flex', ml: -2.5, pt: 2.5, px: 2.5, width: 'calc(100% + 40px)' }}>
      {isCreateView && (
        <Button color="tertiary" onClick={() => setActiveComponent(null)} variant="text">
          Cancel
        </Button>
      )}

      <Box sx={{ ml: 'auto' }}>
        <WithTooltip content={disabledMessage}>
          <Button disabled={Boolean(disabledMessage)} onClick={isCreateView ? openCreateNewFieldModal : openReviewEditsModal} variant="contained">
            {isCreateView ? 'Submit' : 'Review Edits'}
          </Button>
        </WithTooltip>
      </Box>
    </Box>
  )
}
