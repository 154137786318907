import Button from '../../../../../../../components/Button'
import React, { FC, useMemo, useState } from 'react'

import { Box } from '@mui/system'
import TypeToConfirm from '../../../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../../../components/WithTooltip'

type _ExtractionConfirmationModalContentProps = {
  isLoading: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ExtractionConfirmationModalContent: FC<_ExtractionConfirmationModalContentProps> = ({ isLoading, onClose, onSubmit }) => {
  const [confirmed, setConfirmed] = useState(false)
  const isDisabled = useMemo(() => !confirmed, [confirmed])

  return (
    <>
      <Box margin="8px auto 0" maxWidth={600} textAlign="center">
        <p>
          Are you sure you want to save these extraction configurations? This is a delicate operation and any changes made will affect the behavior of the
          system. Please confirm that you understand the consequences of saving these configurations before proceeding.
        </p>
      </Box>
      <TypeToConfirm setConfirmed={setConfirmed} />
      <Box display="flex" justifyContent="center" mt={2.5}>
        <Box mr={0.5}>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
        </Box>
        <WithTooltip content="Type Confirm to proceed">
          <Button disabled={isDisabled || isLoading} loading={isLoading} onClick={onSubmit}>
            Submit
          </Button>
        </WithTooltip>
      </Box>
    </>
  )
}
