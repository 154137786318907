import { DependencyList, useEffect } from 'react'
import { useCciMainContext } from '../CCI_Main'

// hooks

export const useErrorCheck = (condition: boolean, dependencyList: DependencyList, error: string) => {
  /* `condition` determines whether `errorConstant` should be added or removed from the `errors` array.
   * `dependencyList` is passed to `useEffect`.
   * if `error` is not a `const` it should be included in `dependencyList`. */

  const { setErrors } = useCciMainContext()

  useEffect(
    () => setErrors(current => (condition ? (current.includes(error) ? current : [...current, error]) : current.filter(item => item !== error))),
    dependencyList // eslint-disable-line react-hooks/exhaustive-deps
  )
}
