import { Box, Typography } from '@mui/material'
import { DELETE } from '../../../../../../../utils/cci'
import { Features, Permissions, useUserAccess } from '../../../../../../../hooks/useUserAccess'
import { FiChevronDown, FiChevronUp, FiTrash2, FiXSquare } from 'react-icons/fi'
import { useCciChecklistGptContext } from '../CCI_RightPanel_ChecklistGptTab'
import { useCciMainContext } from '../../../../../CCI_Main'
import Button from '../../../../../../../components/Button'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import React, { FC, KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// types

type _OptionInputCreateViewProps = {
  handleChange?: any
  index: number
  isNew?: boolean
  optionList?: any
  placeholder?: string
  value?: string
}

type _OptionInputEditViewProps = {
  handleChange?: any
  index: number
  isNew?: boolean
  optionList?: any
  placeholder?: string
  value?: string
}

type _ReorderButtonsProps = { index: number; optionList?: any; updateFieldValue?: any }

// constants

const DATE_FORMAT_OPTIONS = [
  { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
  { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
  { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
  { value: 'MMMM DD, YYYY', label: 'MMMM DD, YYYY' },
  { value: 'DD MMMM, YYYY', label: 'DD MMMM, YYYY' },
  { value: 'MMMM/DD/YYYY', label: 'MMMM/DD/YYYY' }
]

export const DEFAULT_DATE_OPTION_VALUE = 'MM/DD/YYYY'

const DROPDOWN_FIELD_TYPES = ['DROP_DOWN', 'MULTI_SELECT_DROP_DOWN']

const FIELD_TYPES_WITH_OPTIONS = [...DROPDOWN_FIELD_TYPES, 'DATE']

// components

export const FieldOptions: FC = () => {
  const { isModalOpen, selectedItem } = useCciMainContext()
  const { fieldValues, isEditView, updateFieldValue } = useCciChecklistGptContext()
  const { default_value: defaultValue, deleted_options: deletedOptions, field_type: fieldType, options, value_format: valueFormat } = fieldValues || {}

  const [deletedOptionList, setDeletedOptionList] = useState<Array<any>>(deletedOptions)
  const [optionList, setOptionList] = useState<Array<any>>(options)

  const initialDefaultValue = useMemo(() => (selectedItem ? JSON.parse(selectedItem?.inheritance_logic)?.default_value : ''), [selectedItem])
  const initialFieldType = useMemo(() => selectedItem?.field_type || '', [selectedItem])
  const initialOptions = useMemo(() => selectedItem?.options, [selectedItem])
  const initialValueFormat = useMemo(() => selectedItem?.value_format, [selectedItem])

  const isDropdownFieldType = Boolean(DROPDOWN_FIELD_TYPES?.includes(fieldType) || (!fieldType && DROPDOWN_FIELD_TYPES?.includes(initialFieldType)))
  const isFieldTypeWithOptions = Boolean(FIELD_TYPES_WITH_OPTIONS?.includes(fieldType) || (!fieldType && FIELD_TYPES_WITH_OPTIONS?.includes(initialFieldType)))

  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  // functions

  const handleCreateViewChange = useCallback(
    (value: string | null, index: number, isNew?: boolean) => {
      const _optionList = optionList ? [...optionList] : []

      if (!isNew && _optionList[index]?.option === defaultValue) {
        updateFieldValue('default_value', value === DELETE ? null : value)
      }

      if (value === DELETE) {
        _optionList.splice(index, 1)
      } else if (isNew) {
        _optionList.push({ option: value })
      } else {
        _optionList[index].option = value
      }

      updateFieldValue('options', _optionList)

      setOptionList(_optionList)
    },
    [defaultValue, optionList, updateFieldValue]
  )

  const handleEditViewChange = useCallback(
    (value: string | null, index: number, isNew?: boolean) => {
      const _optionList: any[] = [...optionList]
      const _deletedOptionList: any[] = [...deletedOptionList]

      if (!isNew && (defaultValue || initialDefaultValue)) {
        if (
          _optionList[index].newValue === (defaultValue || initialDefaultValue) ||
          (!_optionList[index].newValue && _optionList[index].currentValue === (defaultValue || initialDefaultValue))
        ) {
          updateFieldValue('default_value', value === null ? _optionList[index].currentValue : value)
        }
      }

      if (value === DELETE) {
        const element = _optionList[index]

        _optionList.splice(index, 1)

        if (element?.currentValue) {
          _deletedOptionList.push({ currentValue: element.currentValue, newValue: value })
        }
      } else if (isNew) {
        if (value) {
          _optionList.push({ currentValue: null, newValue: value })
        }
      } else {
        _optionList[index].newValue = value
      }

      const changedOptions = _optionList?.filter(option => !(option?.currentValue && !option?.newValue))

      updateFieldValue('options', _deletedOptionList?.length > 0 || changedOptions?.length > 0 ? _optionList : undefined)
      updateFieldValue('deleted_options', _deletedOptionList?.length > 0 ? _deletedOptionList : undefined)

      setOptionList(_optionList)
      setDeletedOptionList(_deletedOptionList)
    },
    [initialDefaultValue, defaultValue, deletedOptionList, updateFieldValue, optionList]
  )

  const handleChange = useCallback(
    (value: string | null, index: number, isNew?: boolean) => {
      isEditView ? handleEditViewChange(value, index, isNew) : handleCreateViewChange(value, index, isNew)
    },
    [handleCreateViewChange, handleEditViewChange, isEditView]
  )

  const handleDateFormat = (item: any) => {
    if (initialValueFormat) {
      if (item?.value !== initialValueFormat) {
        updateFieldValue('value_format', item?.value)
      }
    } else {
      updateFieldValue('value_format', item?.value)
    }
  }

  // effects

  useEffect(() => {
    // Generate/wipe initial lists when the field type changes.
    if (isModalOpen) return

    setOptionList(isEditView ? initialOptions?.map((option: any) => ({ currentValue: option, newValue: null })) : [])
    setDeletedOptionList([])

    updateFieldValue('options', null)
  }, [initialFieldType, initialOptions, fieldType, isEditView, isModalOpen, updateFieldValue])

  // render

  if (!isFieldTypeWithOptions) return null

  return (
    <>
      {isDropdownFieldType && (
        <>
          <Typography sx={{ fontWeight: 600, mb: 1, mt: 2 }} variant="body2">
            Field Options
          </Typography>

          {optionList?.length ? (
            optionList?.map((option: any, index: number) => (
              <div className={css.inputWrapper} key={`${option}${index}`} style={{ flexWrap: 'wrap' }}>
                {isEditView ? (
                  <OptionInputEditView
                    handleChange={handleChange}
                    index={index}
                    optionList={optionList}
                    placeholder={option?.currentValue}
                    value={option?.newValue || ''}
                  />
                ) : (
                  <OptionInputCreateView
                    handleChange={handleChange}
                    index={index}
                    optionList={optionList}
                    placeholder={option?.option}
                    value={option?.option || ''}
                  />
                )}
              </div>
            ))
          ) : (
            <></>
          )}

          {isEditView ? (
            hasFullEditAccess && <OptionInputEditView handleChange={handleChange} index={9999} isNew placeholder="Add item…" />
          ) : (
            <OptionInputCreateView handleChange={handleChange} index={9999} isNew placeholder="Add item…" />
          )}
        </>
      )}

      {(fieldType === 'DATE' || (!fieldType && initialFieldType === 'DATE')) && (
        <div className={css.inputWrapper}>
          <Typography component="label" htmlFor="default-value-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
            Value Format
          </Typography>

          <SelectInput
            id="value-format-select-input"
            isClearable
            isDisabled={!hasFullEditAccess}
            onChange={handleDateFormat}
            options={DATE_FORMAT_OPTIONS}
            placeholder={valueFormat === DELETE ? 'Select Date Format' : valueFormat || initialValueFormat || 'Select Date Format'}
            value={valueFormat || initialValueFormat || ''}
          />
        </div>
      )}
    </>
  )
}

const OptionInputCreateView: FC<_OptionInputCreateViewProps> = ({ handleChange, index, isNew, optionList, placeholder, value }) => {
  const { updateFieldValue } = useCciChecklistGptContext()

  const [isFocused, setFocused] = useState(false)
  const [addItemValue, setAddItemValue] = useState('')

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (isFocused && e?.key === 'Enter') {
      setFocused(false)
      // @ts-ignore
      e?.target?.blur()
    }
  }

  useEffect(() => {
    if (!isFocused && addItemValue) {
      handleChange(addItemValue || null, index, isNew)
      setAddItemValue('')
    } else {
      setAddItemValue(value || '')
    }
    // eslint-disable-next-line
  }, [isFocused])

  return (
    <>
      <Box className={isNew ? css.optionInputWrapper : css.inputWrapper} sx={isNew ? { ml: 22.5 } : { m: 0 }}>
        <Box className={css.textInput}>
          {!isNew && <ReorderButtons index={index} optionList={optionList} updateFieldValue={updateFieldValue} />}

          <FreeText
            isFocused={isFocused}
            onChange={(event: any) => setAddItemValue(event?.target?.value)}
            onKeyDown={handleKeyDown}
            placeholder={isNew ? 'Add item…' : placeholder}
            setFocused={setFocused}
            value={isFocused ? addItemValue || '' : value || ''}
          />
        </Box>

        {(!isNew || !placeholder) && (
          <Button icon={<FiTrash2 color="#fc3535" />} onClick={() => handleChange(DELETE, index)} style={{ margin: '-1px 0 0 8px', borderRadius: '4px' }} />
        )}
      </Box>
    </>
  )
}

const OptionInputEditView: FC<_OptionInputEditViewProps> = ({ handleChange, index, isNew, optionList, placeholder, value }) => {
  const { updateFieldValue } = useCciChecklistGptContext()

  const [isFocused, setFocused] = useState(false)
  const [addItemValue, setAddItemValue] = useState('')

  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  const isOnlyOption = optionList?.filter((option: any) => option?.newValue !== DELETE).length === 1

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (isFocused && e?.key === 'Enter') {
      setFocused(false)
      // @ts-ignore
      e?.target?.blur()
    }
  }

  useEffect(() => {
    if (!isFocused && addItemValue) {
      handleChange(addItemValue || null, index, isNew)
      setAddItemValue('')
    } else {
      setAddItemValue(value || '')
    }
    // eslint-disable-next-line
  }, [isFocused])

  const canDeleteOption = !(value && !placeholder) //! (value && !placeholder) checks if it is a new option because the old value will be null

  return (
    <>
      {value !== DELETE && value !== placeholder && (
        <Box className={isNew ? css.optionInputWrapper : css.inputWrapper} sx={isNew ? { ml: 22.5 } : { m: 0 }}>
          <Box className={css.textInput}>
            {hasFullEditAccess && !isNew && <ReorderButtons index={index} optionList={optionList} updateFieldValue={updateFieldValue} />}

            <FreeText
              isDisabled={!hasFullEditAccess}
              isFocused={isFocused}
              onChange={(event: any) => setAddItemValue(event?.currentTarget?.value)}
              onKeyDown={handleKeyDown}
              placeholder={isNew ? 'Add item…' : placeholder}
              setFocused={setFocused}
              value={isFocused ? addItemValue || '' : value || ''}
            />
          </Box>

          {hasFullEditAccess && !isNew && placeholder && (addItemValue || value) && (
            <Button
              className={css.cciButton}
              icon={<FiXSquare />}
              onClick={() => {
                handleChange(null, index)
                setAddItemValue('')
              }}
            />
          )}

          {hasFullEditAccess && !isOnlyOption && (!isNew || !placeholder) && canDeleteOption && (
            <Button icon={<FiTrash2 color="#fc3535" />} onClick={() => handleChange(DELETE, index)} style={{ margin: '-1px 0 0 8px', borderRadius: '4px' }} />
          )}
        </Box>
      )}
    </>
  )
}

const ReorderButtons: FC<_ReorderButtonsProps> = ({ index, optionList }) => {
  const { updateFieldValue } = useCciChecklistGptContext()

  const handleClick = (direction: string) => {
    if (direction === 'up' && index !== 0) {
      updateFieldValue('options', reorderArray(index, index - 1))
    } else if (direction === 'down' && index !== optionList?.length - 1) {
      updateFieldValue('options', reorderArray(index, index + 1))
    }
  }

  const reorderArray = (fromIndex: number, toIndex: number) => {
    const element = optionList[fromIndex]

    optionList.splice(fromIndex, 1)
    optionList.splice(toIndex, 0, element)

    return [...optionList]
  }

  return (
    <Box sx={{ display: 'flex', mr: 2, width: 164 }}>
      <Box className={css.toolbarButton} onClick={() => handleClick('up')} style={{ visibility: index === 0 ? 'hidden' : 'unset' }} title="Move option up">
        <FiChevronUp />
      </Box>

      <Box
        className={css.toolbarButton}
        onClick={() => handleClick('down')}
        sx={{ visibility: index === optionList?.length - 1 ? 'hidden' : 'unset' }}
        title="Move option down"
      >
        <FiChevronDown />
      </Box>
    </Box>
  )
}
