import { Box, Paper, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { captureError } from '../../utils/sentry'
import { fetchIDP } from '../../utils/sessionApiUtils'
import { oktaClient } from '../../utils/oktaClient'
import { useLocation } from 'react-router'
import Button from '../../components/Button'
import Input from '../../components/Input'
import React, { SyntheticEvent, useState } from 'react'
import logo from '../../assets/klarity.png'

// components

const Login = () => {
  const [{ email, error, loading }, setState] = useState<{ email: string; error?: any; loading: boolean }>({ loading: false, error: null, email: '' })
  const location = useLocation<any>()

  const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!email) {
      return setState(prev => ({ ...prev, loading: false, error: 'Please enter your email' }))
    }
    setState(prev => ({ ...prev, loading: true }))
    if (location?.state?.from?.pathname) {
      window.localStorage.setItem('from', location?.state?.from?.pathname)
    }

    // idp will be an empty string for internal users
    try {
      const idp = await fetchIDP(email)
      const options = idp ? { idp } : undefined
      oktaClient.token.getWithRedirect(options)
    } catch (e: any) {
      captureError(e)

      const defaultErrorMessage = (
        <>
          An error has occurred. Please double-check your credentials and <Link to="/contact-us">contact us</Link> if the problem persists.
        </>
      )
      const error = e?.response?.data?.error || defaultErrorMessage

      return setState(prev => ({ ...prev, loading: false, error }))
    }
  }

  return (
    <Box alignItems="center" bgcolor="#f3f4f8" display="flex" justifyContent="center" width="100vw">
      <form onSubmit={handleSubmit}>
        <Paper elevation={2}>
          <Box bgcolor="white" borderRadius={4} display="flex" flexDirection="column" gap={2} justifyContent="stretch" p={4} width={400}>
            <img alt="Klarity" src={logo} style={{ marginBottom: 24 }} />

            <Input
              name="email"
              onChange={({ currentTarget }) => setState({ email: currentTarget.value, error: false, loading: false })}
              placeholder="Email"
              type="email"
              value={email}
            />

            <Button disabled={loading || error} htmlType="submit">
              Log in
            </Button>

            {error && <Typography color="error.main">{error}</Typography>}

            <Link to="/contact-us">
              <Button disabled={loading || error} style={{ width: '100%' }} variant="secondary">
                Sign up
              </Button>
            </Link>
          </Box>
        </Paper>
      </form>
    </Box>
  )
}

export default Login
