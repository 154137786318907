import { ActiveComponents, ModalOptions, useCciMainContext } from '../../../../CCI_Main'
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material'
import { Features, Permissions, useUserAccess } from '../../../../../../hooks/useUserAccess'
import { Info } from 'react-feather'
import { SUBSTATUS_VALUE_LABEL_MAP, Substatuses, getFieldTypeLabel } from '../../../../../../utils/cci'
import { Source } from '../../../../../../components/DatapointField/Source'
import { amber, blue, common, green, lightGreen, red } from '@mui/material/colors'
import DOMPurify from 'dompurify'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import React, { FC, useLayoutEffect, useMemo, useRef } from 'react'
import Tippy from '@tippyjs/react'
import clsx from 'clsx'
import css from '../../style.module.scss'

// types

type _CciLeftPanelChecklistCardProps = { dataPointField: any }
type _SubstatusChipProps = { substatus: string }

// components

export const CciLeftPanelChecklistCard: FC<_CciLeftPanelChecklistCardProps> = ({ dataPointField }) => {
  const { description, external_mapping, field_type, id, inheritance_logic, internal_mapping, name, options } = dataPointField
  const { documentTypesField, isChecklistGptEnabled, openModal, selectedItem, setActiveComponent, setSelectedItem } = useCciMainContext()
  const canDeleteFields = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.DELETE_FIELD })
  const canViewAutomationTags = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.CREATE_FIELD })

  const ref = useRef<HTMLDivElement>(null)

  const createDescriptionHTML = (description?: string) => {
    if (!description) {
      return { __html: DOMPurify.sanitize('') }
    } else {
      return { __html: DOMPurify.sanitize(description) }
    }
  }

  useLayoutEffect(() => {
    if (selectedItem && selectedItem.id === dataPointField.id) {
      const timeoutId = setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 250)

      return () => clearTimeout(timeoutId)
    }
  }, [dataPointField, selectedItem])

  return (
    <Tooltip followCursor title={dataPointField.is_deleted ? 'Pending removal…' : ''}>
      <div
        className={clsx(css.field, selectedItem?.id === dataPointField?.id && css.isFieldActive)}
        onClick={() => {
          if (!dataPointField.is_deleted) {
            setActiveComponent(id === documentTypesField?.id ? ActiveComponents.EDIT_DOCUMENT_TYPES : ActiveComponents.EDIT_FIELD)
            setSelectedItem(dataPointField)
          }
        }}
        ref={ref}
      >
        <div className={clsx(css.labelRow, css.noSelect)}>
          <div className={css.label}>
            <Typography component="span" sx={{ fontWeight: 600, mr: 1.5 }} variant="body2">
              {name}
            </Typography>

            {description && (
              <Tippy
                className={clsx(description?.length > 500 && css.description)}
                content={<div dangerouslySetInnerHTML={createDescriptionHTML(description)} />}
              >
                <Info />
              </Tippy>
            )}

            {isChecklistGptEnabled && <SubstatusChip substatus={dataPointField.sub_status} />}

            <Source {...dataPointField} style={{ marginLeft: 0 }} />
          </div>

          {canDeleteFields && !internal_mapping?.includes('document_type') && (
            <ul className={css.actions}>
              <li style={{ ...(dataPointField.is_deleted && { cursor: 'default' }) }}>
                <Tooltip arrow title="Delete">
                  <span>
                    <IconButton
                      disabled={dataPointField.is_deleted}
                      onClick={event => {
                        event.stopPropagation()

                        openModal({ content: { dataPointId: id, dataPointName: name }, modalOption: ModalOptions.DELETE_FIELD })

                        setActiveComponent(ActiveComponents.DELETE_FIELD)
                        setSelectedItem(dataPointField)
                      }}
                    >
                      <DeleteIcon color={dataPointField.is_deleted ? 'disabled' : undefined} />
                    </IconButton>
                  </span>
                </Tooltip>
              </li>
            </ul>
          )}
        </div>

        <div className={clsx(css.textRow, css.noSelect)}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', mt: 0.5, width: '100%' }}>
              <Typography sx={{ flex: 1, pr: 1 }} variant="body2">
                Field Type:
              </Typography>

              <Typography sx={{ flex: 1.5 }} variant="body2">
                {getFieldTypeLabel(field_type)}
              </Typography>
            </Box>

            {options?.length > 0 && (
              <Box sx={{ display: 'flex', mt: 1, width: '100%' }}>
                <Typography sx={{ flex: 1, pr: 1 }} variant="body2">
                  Options:
                </Typography>

                <Box sx={{ display: 'flex', flex: 1.5, flexWrap: 'wrap' }}>
                  {options?.map((item: string, index: number) => (
                    <Typography key={name + item} variant="body2">
                      {item}
                      {index !== options?.length - 1 && <>,&nbsp;</>}
                    </Typography>
                  ))}
                </Box>
              </Box>
            )}

            {JSON.parse(inheritance_logic)?.default_value && (
              <Box sx={{ display: 'flex', mt: 1, width: '100%' }}>
                <Typography sx={{ flex: 1, pr: 1 }} variant="body2">
                  Default Value:
                </Typography>

                <Typography sx={{ flex: 1.5 }} variant="body2">
                  {JSON.parse(inheritance_logic)?.default_value}
                </Typography>
              </Box>
            )}

            {!isChecklistGptEnabled && canViewAutomationTags && external_mapping?.length > 0 && (
              <Box sx={{ display: 'flex', mt: 1, width: '100%' }}>
                <Typography sx={{ flex: 1, pr: 1 }} variant="body2">
                  Automation Tag{external_mapping?.length > 1 ? 's' : ''}:
                </Typography>

                <Box sx={{ display: 'flex', flex: 1.5, flexWrap: 'wrap' }}>
                  {external_mapping?.map((tag: string, index: number) => (
                    <Typography key={`${name}-${tag}-${index}`} variant="body2">
                      {tag}
                      {index !== external_mapping?.length - 1 && <>,&nbsp;</>}
                    </Typography>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </div>
    </Tooltip>
  )
}

const SubstatusChip: FC<_SubstatusChipProps> = ({ substatus }) => {
  const chipProps = useMemo(() => {
    switch (substatus) {
      case Substatuses.PENDING_SAMPLES_GATHERING:
        return { bgcolor: amber[200], color: common.black }
      case Substatuses.SAMPLES_READY_FOR_REVIEW:
        return { bgcolor: blue[100], color: common.black }
      case Substatuses.SAMPLES_REVIEW_IN_PROGRESS:
        return { bgcolor: blue[100], color: common.black }
      case Substatuses.REVIEW_COMPLETED:
        return { bgcolor: lightGreen[300], color: common.black }
      case Substatuses.PUBLISHING_IN_PROGRESS:
        return { bgcolor: amber[200], color: common.black }
      case Substatuses.PUBLISHING_FAILED:
        return { bgcolor: red[100], color: common.black }
      case Substatuses.PUBLISHED:
        return { bgcolor: green[700], color: common.white }
      case Substatuses.ARCHIVED:
        return { bgcolor: red[100], color: common.black }
      default:
        return { bgcolor: red[100], color: common.black }
    }
  }, [substatus])

  const { bgcolor, color } = chipProps

  if (!substatus) return null

  return (
    <Chip
      label={SUBSTATUS_VALUE_LABEL_MAP.get(substatus)}
      size="small"
      sx={{ bgcolor, color, cursor: 'inherit', fontSize: 10, fontWeight: 600, height: 18, px: 0.5 }}
    />
  )
}
