import useCurrentUser from './useCurrentUser'

// Hook for hardcoded features for this task: https://app.asana.com/0/0/1200501099177444/f
export default function useIsRapid7LegalTeam() {
  const currentUser = useCurrentUser()
  const poc_role_id = 'Um9sZTo2MDgwNDZlYWNjMzc5YTNmN2NlY2YzMGY='
  const prod_role_id_1 = 'Um9sZTo2MGRjNjBiMzFiZGQxYTM3MzJlMjRlNjA=' // "Legal"
  const prod_role_id_2 = 'Um9sZTo2MjM4MWFhM2MxNTA5Njc1NDNkODg3YWQ=' // "Legal (Viewer)""

  for (const r of currentUser?.roles?.edges || []) {
    if (r?.node?.id === poc_role_id || r?.node?.id === prod_role_id_1 || r?.node?.id === prod_role_id_2) {
      return true
    }
  }

  return false
}
