import { Box, Button, Typography } from '@mui/material'
import { ErrorMessage } from '../Layout/Layout'
import { FallbackProps, ErrorBoundary as RErrorBoundary } from 'react-error-boundary'
import { Link } from 'react-router-dom'
import { QueryTypes } from '../../hooks/usePrevQueryType'
import { captureError } from '../../utils/sentry'
import { useAppContext } from '../../app'
import React, { PropsWithChildren, useEffect } from 'react'
import useCurrentUser from '../../hooks/useCurrentUser'

// functions

const clearPreviousQueryData = () => {
  // Some errors are caused by the user's previous queries. This data must be removed to prevent such errors from reoccurring on page load.
  Object.values(QueryTypes).forEach(queryType => {
    Object.keys(localStorage).forEach(key => {
      if (key.includes(queryType)) {
        localStorage.removeItem(key)
      }
    })
  })

  // Remove all search params (except `feedbackDataPointFieldId`) from the URL to prevent an erroneous query from being executed again if the user refreshes the page.
  const url = new URL(window.location.href)

  url.searchParams.forEach((_, key) => {
    if (key !== 'feedbackDataPointFieldId') {
      url.searchParams.delete(key)
    }
  })
}

// components

const Fallback = ({ error }: FallbackProps) => {
  const { errorMessage, extendedErrorMessage, setErrorMessage, setExtendedErrorMessage } = useAppContext()
  const currentUser = useCurrentUser()

  useEffect(() => {
    captureError(error)

    setErrorMessage(error.message)

    setExtendedErrorMessage(error.stack || error.message)

    clearPreviousQueryData()
  }, [error, setErrorMessage, setExtendedErrorMessage])

  return (
    <>
      {(errorMessage || extendedErrorMessage) && <ErrorMessage />}

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: 'auto',
          maxWidth: 800,
          minHeight: 'calc(100vh - 73px)',
          textAlign: 'center'
        }}
      >
        <Typography sx={{ mb: 4 }} variant="h1">
          Application Error
        </Typography>

        <Typography>We apologize for the inconvenience. It appears that something went wrong on our end.</Typography>

        <Typography>Please contact us so we can address the issue and prevent it from happening again.</Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Button component={Link} to="/contact-us" variant="contained">
            Contact us
          </Button>

          <Button onClick={() => (window.location.href = '/')} sx={{ ml: 2 }} variant="outlined">
            Return to the {currentUser ? 'Dashboard' : 'home page'}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export const ErrorBoundary = ({ children }: PropsWithChildren<any>) => <RErrorBoundary FallbackComponent={Fallback}>{children}</RErrorBoundary>
