import { captureError } from './sentry'

class RuntimeEnvironment {
  readonly REACT_APP_ANALYTICS_URL: string
  readonly REACT_APP_API_ROOT: string
  readonly REACT_APP_NEXT_JS_API_ROOT: string
  readonly REACT_APP_OKTA_CLIENT_ID: string
  readonly REACT_APP_OKTA_URL: string
  readonly REACT_APP_PRIZMDOC_EDITOR_ROOT: string
  readonly REACT_APP_SENTRY_DSN: string

  constructor(params: any) {
    const {
      REACT_APP_ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL,
      REACT_APP_API_ROOT = process.env.REACT_APP_API_ROOT,
      REACT_APP_NEXT_JS_API_ROOT = process.env.REACT_APP_NEXT_JS_API_ROOT,
      REACT_APP_OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID,
      REACT_APP_OKTA_URL = process.env.REACT_APP_OKTA_URL,
      REACT_APP_PRIZMDOC_EDITOR_ROOT = process.env.REACT_APP_PRIZMDOC_EDITOR_ROOT,
      REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
    } = params

    this.REACT_APP_ANALYTICS_URL = REACT_APP_ANALYTICS_URL
    this.REACT_APP_API_ROOT = REACT_APP_API_ROOT
    this.REACT_APP_NEXT_JS_API_ROOT = REACT_APP_NEXT_JS_API_ROOT
    this.REACT_APP_OKTA_CLIENT_ID = REACT_APP_OKTA_CLIENT_ID
    this.REACT_APP_OKTA_URL = REACT_APP_OKTA_URL
    this.REACT_APP_PRIZMDOC_EDITOR_ROOT = REACT_APP_PRIZMDOC_EDITOR_ROOT
    this.REACT_APP_SENTRY_DSN = REACT_APP_SENTRY_DSN
  }
}

function loadSettingsSync() {
  let result = new RuntimeEnvironment({})
  const req = new XMLHttpRequest()
  req.overrideMimeType('application/json')
  req.open('GET', '/env.json', false)
  req.onload = () => {
    try {
      if (req.status === 200) {
        const jsonResponse = JSON.parse(req.responseText)
        result = new RuntimeEnvironment(jsonResponse)
      }
    } catch (error) {
      captureError(error, `Error loading runtime settings: ${error}. Using build defaults.`)
    }
  }
  req.onerror = () => {
    captureError(`Error loading runtime settings: ${req.status} ${req.statusText}. Using build defaults.`)
  }
  req.send(null)
  return result
}

export const CurrentRuntimeEnvironment = process.env.NODE_ENV === 'production' ? loadSettingsSync() : new RuntimeEnvironment({})
