import { ChevronDown } from 'react-feather'
import { formatKey } from '../../../../../../utils/stringUtils'
import { useCciLeftPanelContext } from '../../CCI_LeftPanel'
import { useCciMainContext } from '../../../../CCI_Main'
import { useIntegrationsLazyQuery } from '../../../../../../graphql/codegen/hooks'
import CCI_LeftPanel_IntegrationCard from './CCI_LeftPanel_IntegrationCard'
import ChecklistSkeleton from '../../../../../../components/DataPointPanel/ChecklistSkeleton'
import Collapse, { Panel } from 'rc-collapse'
import React, { useEffect } from 'react'

export default function CCI_LeftPanel_IntegrationList() {
  const { setContentData, setLeftPanelLoading, tab } = useCciMainContext()
  const { activeKeys, searchTerm, setActiveKeys, setSearchableData } = useCciLeftPanelContext()

  const [getIntegrations, { data: integrationData, loading: integrationDataLoading }] = useIntegrationsLazyQuery()

  // eslint-disable-next-line
  useEffect(() => { getIntegrations(); }, [])

  // this handles resetting contentData from cached values when switching tabs
  useEffect(() => {
    if (tab === 'integrations') {
      setContentData(integrationData?.integrations?.edges || '')

      setSearchableData(integrationData?.integrations?.edges || null)
    }
  }, [integrationData?.integrations?.edges, setContentData, setSearchableData, tab])

  useEffect(() => setLeftPanelLoading(!integrationData || integrationDataLoading), [integrationData, integrationDataLoading, setLeftPanelLoading])

  return (
    <>
      {integrationDataLoading ? (
        <ChecklistSkeleton />
      ) : searchTerm ? (
        <Collapse
          activeKey={activeKeys}
          expandIcon={({ isActive }: { isActive: boolean }) => <ChevronDown style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }} />}
          onChange={setActiveKeys}
        >
          {integrationData?.integrations?.edges
            ?.filter(integration => integration?.node?.integration_type?.toLowerCase().includes(searchTerm.toLowerCase()))
            .map(integration => (
              <Panel
                header={
                  <div className="groupHeader">
                    <h3>{integration?.node?.integration_type}</h3>
                  </div>
                }
                key={formatKey(integration?.node?.id)}
              >
                <CCI_LeftPanel_IntegrationCard integration={integration} />
              </Panel>
            ))}
        </Collapse>
      ) : (
        <Collapse
          activeKey={activeKeys}
          expandIcon={({ isActive }: { isActive: boolean }) => <ChevronDown style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }} />}
          onChange={setActiveKeys}
        >
          {/* @ts-ignore */}
          {integrationData?.integrations?.edges?.length > 0 ? (
            integrationData?.integrations?.edges?.map(integration => {
              return (
                // forceRender is a hack for cloudflare to act as if the card was opened to fire setting the integration as selected
                <Panel
                  forceRender
                  header={
                    <div className="groupHeader">
                      <h3>{integration?.node?.integration_type}</h3>
                    </div>
                  }
                  key={formatKey(integration?.node?.id)}
                >
                  <CCI_LeftPanel_IntegrationCard integration={integration} />
                </Panel>
              )
            })
          ) : (
            <>
              <p style={{ marginLeft: '16px', backgroundColor: 'white' }}>{`No integrations found.`}</p>
              <p style={{ marginLeft: '16px', backgroundColor: 'white' }}>{`Click the button below to create one`}</p>
            </>
          )}
        </Collapse>
      )}
    </>
  )
}
