import { useMemo } from 'react'

// functions

export const useParseJsonString = <T>(jsonString: string | undefined): T | undefined =>
  useMemo(() => {
    try {
      return JSON.parse(jsonString || '') as T
    } catch (error) {
      console.error('useParseJsonString: Failed to parse JSON string', { error, jsonString })

      return undefined
    }
  }, [jsonString])
