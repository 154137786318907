import { DataPoint } from '../../graphql/codegen/schemas'
import { MutationResult } from '@apollo/client'
import CommentBox from '../CommentBox'
import CommentContainer from '../../containers/CommentContainer'
import React from 'react'
import Skeleton from './Skeleton'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type _CommentSectionProps = {
  commentState?: MutationResult<any>
  comments?: any
  dataPoint: DataPoint
  dealIsFinalized: boolean
  handleCreate?: (c: any) => void
  isNestedChildDataPoint?: boolean
  loading?: boolean
}

// components

export default function CommentSection({
  commentState,
  comments,
  dataPoint,
  dealIsFinalized,
  handleCreate,
  isNestedChildDataPoint,
  loading
}: _CommentSectionProps) {
  const isEmpty = !loading && (!comments || (comments && !comments.length))

  return (
    <div className={css.commentSection}>
      <div className={css.commentListWrapper}>
        <div className={clsx(isEmpty && css.empty)}>
          {!loading ? (
            !isEmpty &&
            comments.map((comment: any) => (
              <CommentContainer
                comment={comment}
                dataPoint={dataPoint}
                dealIsFinalized={dealIsFinalized}
                isNestedChildDataPoint={isNestedChildDataPoint}
                key={comment.id}
              />
            ))
          ) : (
            <Skeleton />
          )}
        </div>
      </div>

      {handleCreate && (
        <div className={css.bottomRow}>
          <CommentBox commentState={commentState} handleCreate={handleCreate} />
        </div>
      )}
    </div>
  )
}
