import * as queryString from 'query-string'
import { API_ROOT } from './apiUtils'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { oktaClient } from './oktaClient'
import NotFoundPage from '../pages/NotFoundPage'
import React, { ComponentProps, ReactNode, useEffect, useState } from 'react'
import axios from 'axios'
import useCurrentUser from '../hooks/useCurrentUser'

export function ProtectedRoute({ children, ...rest }: { children: ReactNode } & ComponentProps<typeof Route>) {
  const currentUser = useCurrentUser()
  return <Route {...rest} render={({ location }) => (currentUser ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />)} />
}

// If logged in, redirect away from login pages
export function AuthRoute({ children, ...rest }: { children: ReactNode } & ComponentProps<typeof Route>) {
  const currentUser = useCurrentUser()
  const location = useLocation()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // This UseEffect is checking for ?domain=value param on /authorization-code/callback
    // if domain param is present calls the backend to ask for an idp_id
    // if idp_id response is null user gets redirected to klarity okta login, otherwise gets sent to a customer's okta login
    // if domain param is not present or the post request errors the user will get redirected to the normal /login page
    if (location.pathname === '/authorization-code/callback') {
      const domain = queryString.parse(location.search).domain
      if (domain) {
        axios
          .post(`${API_ROOT}/idp/email_domain`, { domain })
          .then(response => {
            const options = response?.data?.idp_id ? { idp: response?.data?.idp_id } : undefined
            oktaClient.token.getWithRedirect(options)
          })
          .catch(() => {
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }, [location])

  return (
    <>
      {loading ? (
        <div id="root-loader" style={{ opacity: '1', visibility: 'visible', backgroundColor: 'rgba(255,255,255,.6' }}>
          <div></div>
        </div>
      ) : (
        <Route
          {...rest}
          render={({ location }) =>
            !currentUser ? (
              window.location.pathname === '/authorization-code/callback' ? (
                <Redirect to={{ pathname: '/login' }} />
              ) : (
                children
              )
            ) : (
              <Redirect to={{ pathname: '/', state: { from: location } }} />
            )
          }
        />
      )}
    </>
  )
}

export function AdminOnlyRoute({ children, ...rest }: { children: ReactNode } & ComponentProps<typeof Route>) {
  const currentUser = useCurrentUser()
  return <Route {...rest} render={() => (currentUser?.is_super_admin ? children : <NotFoundPage />)} />
}
