import { FiXSquare } from 'react-icons/fi'
import { Typography } from '@mui/material'
import Button from '../../../../../../../components/Button'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import React, { KeyboardEvent, SyntheticEvent, useState } from 'react'
import css from './style.module.scss'

export default function NameInput({ currentValue, handleData, newValue }: { currentValue?: string; handleData: any; newValue?: string }) {
  const [isFocused, setFocused] = useState(false)

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (isFocused && event?.key === 'Enter') {
      setFocused(false)
      // @ts-ignore
      event?.target?.blur()
    }
  }

  return (
    <div className={css.inputWrapper}>
      <Typography component="label" htmlFor="name-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        Name
      </Typography>

      <FreeText
        id="name-input"
        isFocused={isFocused}
        onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => handleData('name', event.currentTarget.value)}
        onKeyDown={handleKeyDown}
        placeholder={currentValue || 'Enter a name for the field'}
        setFocused={setFocused}
        value={newValue || ''}
      />

      {newValue && <Button className={css.cciButton} icon={<FiXSquare />} onClick={() => handleData('name', null)} />}
    </div>
  )
}
