import { Box, Button, IconButton, InputAdornment, List, ListItem, TextField, Tooltip, Typography } from '@mui/material'
import { DragDropContext, Draggable, DraggableProvidedDragHandleProps, DropResult, Droppable } from 'react-beautiful-dnd'
import { FieldTypes } from '../CreateInputs/FieldTypeInput'
import { MatchFieldConfigEdge } from '../../../../../../../graphql/codegen/schemas'
import { isEmpty, sortBy } from 'lodash'
import { reorderList } from '../../../../../../../utils/cci'
import { useContextInit } from '../../../../../../../hooks/useContextInit'
import { useMatchingChildrenDataPointFieldsQuery } from '../../../../../../../graphql/codegen/hooks'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InfoIcon from '@mui/icons-material/Info'
import React, { Dispatch, FC, SetStateAction, createContext, useCallback, useEffect, useMemo, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'

// types

type _DataPointField = { documentType?: _DocumentType; documentTypeList?: _DocumentType[]; fieldType: FieldTypes; label: string; value: string }
export type _DocumentType = { label: string; value: string }
type _ExistingFieldProps = { dragHandleProps?: DraggableProvidedDragHandleProps; matchField: _DataPointField }

type _MatchFieldsContext = {
  dataPointFieldList: _DataPointField[]
  fieldType?: FieldTypes
  isLoading: boolean
  isReadOnly: boolean
  isViewOnly: boolean
  matchFieldList: _DataPointField[]
  setMatchFieldList: Dispatch<SetStateAction<_DataPointField[]>>
}

type _MatchFieldsProps = { handleData?: (type: string, value: any) => void; isReadOnly?: boolean; isViewOnly?: boolean; list: MatchFieldConfigEdge[] }

// context

const MatchFieldsContext = createContext<_MatchFieldsContext | null>(null)

// functions

const getInputProps = (dragHandleProps: DraggableProvidedDragHandleProps) => ({
  endAdornment: (
    <InputAdornment {...dragHandleProps} position="end" tabIndex={-1}>
      <DragHandleIcon />
    </InputAdornment>
  )
})

// hooks

const useMatchFieldsContext = () => useContextInit(MatchFieldsContext)

// components

const ExistingField: FC<_ExistingFieldProps> = ({ dragHandleProps, matchField }) => {
  const { isReadOnly, isViewOnly, matchFieldList } = useMatchFieldsContext()

  const value = `${matchField.label}${matchField.documentType ? ` — ${matchField.documentType.label}` : ''}`

  if (isReadOnly) {
    return <Typography fontSize={isViewOnly ? undefined : 14}>{value}</Typography>
  }

  return (
    <TextField
      InputProps={{
        ...(dragHandleProps && (matchFieldList.length > 1 || undefined) && getInputProps(dragHandleProps)),
        style: { background: 'white', fontSize: 14 }
      }}
      disabled
      fullWidth
      size="small"
      sx={{ fontSize: 14 }}
      value={value}
    />
  )
}

export const MatchFields: FC<_MatchFieldsProps> = ({ handleData = () => undefined, isReadOnly = false, isViewOnly = false, list = [] }) => {
  const { data, loading: isLoading } = useMatchingChildrenDataPointFieldsQuery()
  const [fieldType, setFieldType] = useState<FieldTypes>()

  const [matchFieldList, setMatchFieldList] = useState(() =>
    list.reduce<_DataPointField[]>(
      (previous, { node }) =>
        node?.data_point_field
          ? [
              ...previous,
              {
                documentType: node.document_type?.id && node.document_type.name ? { label: node.document_type.name, value: node.document_type.id } : undefined,
                fieldType: node.data_point_field.field_type as FieldTypes,
                label: node.data_point_field.name,
                value: node.data_point_field.id
              }
            ]
          : previous,
      []
    )
  )

  const dataPointFieldList = useMemo(
    () =>
      data?.matching_children_data_point_fields?.edges
        ? sortBy(
            data.matching_children_data_point_fields.edges.reduce<_DataPointField[]>(
              (previous, current) =>
                current?.node
                  ? [
                      ...previous,
                      {
                        ...(current.node.document_types &&
                          !isEmpty(current.node.document_types.edges) && {
                            documentTypeList: current.node.document_types.edges.reduce<_DocumentType[]>(
                              (previous, current) => (current?.node ? [...previous, { label: current.node.name, value: current.node.id }] : previous),
                              []
                            )
                          }),
                        fieldType: current.node.field_type as FieldTypes,
                        label: current.node.name,
                        value: current.node.id
                      }
                    ]
                  : previous,
              []
            ),
            'label'
          )
        : [],
    [data?.matching_children_data_point_fields]
  )

  const context = useMemo<_MatchFieldsContext>(
    () => ({ dataPointFieldList, fieldType, isLoading, isReadOnly, isViewOnly, matchFieldList, setFieldType, setMatchFieldList }),
    [dataPointFieldList, fieldType, isLoading, isReadOnly, isViewOnly, matchFieldList]
  )

  useMemo(() => {
    if (isEmpty(matchFieldList)) {
      setFieldType(undefined)
    } else if (matchFieldList.length === 1) {
      setFieldType(dataPointFieldList.find(dataPointField => dataPointField.fieldType === matchFieldList[0].fieldType)?.fieldType)
    }
  }, [dataPointFieldList, matchFieldList])

  useEffect(
    () => {
      handleData(
        'match_children_fields',
        matchFieldList.map((matchField, index) => ({
          is_truth_field: !index,
          data_point_field_id: matchField.value,
          ...(matchField.documentType && { document_type_id: matchField.documentType.value })
        }))
      )

      handleData('matchFieldType', matchFieldList[0]?.fieldType)

      handleData(
        'matchFieldLabelList',
        matchFieldList.map(matchField => {
          const field = dataPointFieldList.find(dataPointField => dataPointField.value === matchField.value)

          const documentType = field?.documentTypeList?.find(documentType => documentType.value === matchField.documentType?.value)

          return `${field?.label}${documentType ? ` — ${documentType.label}` : ''}`
        })
      )
    },
    [matchFieldList] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleDelete = (index: number) => setMatchFieldList(previous => previous.filter((_, localIndex) => localIndex !== index))

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return

    setMatchFieldList(previous => reorderList(previous, result.source.index, result.destination!.index))
  }

  return (
    <Box alignItems="baseline" display="flex">
      {!isViewOnly && (
        <Typography component="label" sx={{ display: 'flex', fontWeight: 600, justifyContent: 'space-between', mr: 2, width: 164 }} variant="body2">
          <>Match Fields</>

          {!isReadOnly && (
            <Tooltip arrow placement="top-start" title="This match field is the matching reference.">
              <InfoIcon color="primary" sx={{ left: 8, position: 'relative' }} />
            </Tooltip>
          )}
        </Typography>
      )}

      <MatchFieldsContext.Provider value={context}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {({ droppableProps, innerRef, placeholder }) => (
              <Box flex={1}>
                <Box>
                  <List {...droppableProps} ref={innerRef} sx={{ py: 0 }}>
                    {matchFieldList.map((matchField, index) => (
                      <Draggable
                        draggableId={`${matchField.value}-${matchField.documentType?.value}`}
                        index={index}
                        isDragDisabled={isReadOnly || matchFieldList.length < 2}
                        key={`${matchField.value}-${matchField.documentType?.value}`}
                      >
                        {({ dragHandleProps, draggableProps, innerRef }) => (
                          <ListItem
                            {...draggableProps}
                            ref={innerRef}
                            style={draggableProps.style}
                            sx={{ ...(isViewOnly ? { justifyContent: 'flex-end', pb: 0 } : { pb: 1 }), pt: 0, px: 0 }}
                          >
                            <ExistingField dragHandleProps={dragHandleProps} matchField={matchField} />

                            {isReadOnly ? null : (
                              <IconButton color="error" onClick={() => handleDelete(index)}>
                                <HighlightOffIcon fontSize="small" />
                              </IconButton>
                            )}
                          </ListItem>
                        )}
                      </Draggable>
                    ))}

                    {placeholder}
                  </List>

                  <NewField />
                </Box>
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </MatchFieldsContext.Provider>
    </Box>
  )
}

const NewField: FC = () => {
  const { dataPointFieldList, fieldType, isLoading, isReadOnly, matchFieldList, setMatchFieldList } = useMatchFieldsContext()
  const [dataPointField, setDataPointField] = useState<_DataPointField | null>(null)

  const filteredDataPointFieldList = useMemo<_DataPointField[]>(
    () =>
      dataPointFieldList
        .filter(
          dataPointField =>
            !matchFieldList.some(
              matchField =>
                matchField.value === dataPointField.value &&
                (!matchField.documentType ||
                  matchFieldList.filter(matchFieldTwo => matchFieldTwo.value === dataPointField.value).length === matchField.documentTypeList?.length)
            )
        )
        .filter(dataPointField => !fieldType || dataPointField.fieldType === fieldType),
    [dataPointFieldList, fieldType, matchFieldList]
  )

  const filteredDocumentTypeList = useMemo<_DocumentType[]>(
    () =>
      dataPointField?.documentTypeList
        ? sortBy(
            dataPointField.documentTypeList.filter(
              documentType =>
                !matchFieldList.some(matchField => matchField.value === dataPointField.value && matchField.documentType?.value === documentType.value)
            ),
            'label'
          )
        : [],
    [dataPointField?.documentTypeList, dataPointField?.value, matchFieldList]
  )

  const addMatchField = useCallback(() => {
    if (dataPointField) {
      setMatchFieldList(previous => [...previous, dataPointField])

      setDataPointField(null)
    }
  }, [dataPointField, setMatchFieldList])

  const dataPointFieldEnterCallback = useMemo(
    () => (!dataPointField?.documentTypeList || dataPointField?.documentType ? addMatchField : undefined),
    [addMatchField, dataPointField?.documentType, dataPointField?.documentTypeList]
  )

  const documentTypeEnterCallback = useMemo(() => (dataPointField?.documentType ? addMatchField : undefined), [addMatchField, dataPointField?.documentType])

  const handleDocumentTypeChange = useCallback(
    (documentType: _DocumentType | null) => {
      if (dataPointField) {
        setDataPointField({ ...dataPointField, documentType: documentType || undefined })
      }
    },
    [dataPointField]
  )

  if (isReadOnly) return null

  return (
    <Box display="flex" gap={1} mb={1}>
      <Box flex={1}>
        <SelectInput
          isClearable
          isDisabled={isLoading}
          onChange={setDataPointField}
          onEnter={dataPointFieldEnterCallback}
          options={filteredDataPointFieldList}
          placeholder={`Select a${isEmpty(matchFieldList) ? '' : 'nother'} match field`}
          value={dataPointField}
        />
      </Box>

      {Boolean(dataPointField?.documentTypeList) && (
        <Box flex={1}>
          <SelectInput
            isClearable
            isDisabled={isLoading}
            onChange={handleDocumentTypeChange}
            onEnter={documentTypeEnterCallback}
            options={filteredDocumentTypeList}
            placeholder="Select a document type"
            value={dataPointField?.documentType || null}
          />
        </Box>
      )}

      <Button disabled={!dataPointField || (dataPointField.documentTypeList && !dataPointField.documentType)} onClick={addMatchField} variant="contained">
        Add
      </Button>
    </Box>
  )
}
