import { IDToken, OktaAuth, UserClaims } from '@okta/okta-auth-js'
import { OKTA_CONFIG, OKTA_EXPIRE_EARLY_SECONDS } from './apiUtils'
import { SentryTag, captureError, setSentryTag } from './sentry'
import { clearLocalAuthValues } from '../pages/Logout/Logout'
import { logOut } from './sessionApiUtils'

// types

export type _UserClaims = UserClaims & { idp: string }

// variables

export const oktaClient = new OktaAuth(OKTA_CONFIG)

let logoutRedirectTimeoutId: ReturnType<typeof setTimeout>

// functions

/**
 * Always start the Okta client before loading the app to ensure the token manager is initialized and the ID token is renewed if close to expiring.
 * This is necessary because a valid ID token is required for the CurrentUserDocument query to succeed (via index.tsx > loadApp).
 */
export const startOktaClient = async () => {
  await oktaClient.start()

  const idToken = await oktaClient.tokenManager.get('idToken')

  const shouldIdTokenBeRenewed = idToken?.expiresAt * 1000 - Date.now() < OKTA_EXPIRE_EARLY_SECONDS * 1000

  if (shouldIdTokenBeRenewed) {
    try {
      await oktaClient.tokenManager.renew('idToken')
    } catch (error) {
      captureError(error, 'Error renewing token on start')
    }
  }
}

const getErrorMessage = (stringifiedError: string) => {
  if (stringifiedError.includes('The JWT was issued in the future')) {
    return 'Your system clock is out of sync with Okta’s authentication server. Please set your system clock to the exact time for your time zone and try again.'
  }

  if (stringifiedError.includes('access_denied')) {
    return 'Our identity provider (okta.com) for single sign-on integration encountered an error during your login process. This is likely due to a permissions issue on your company’s account. Please contact your company’s IT administrator for assistance. You may also contact Klarity support and provide the error information below.'
  }

  return 'Our identity provider (okta.com) for single sign-on integration encountered an error while processing your request. Please contact Klarity support and provide the error information below.'
}

export const handleOktaLoginRedirectError = async (error: unknown) => {
  captureError(error, 'Error on Okta login redirect')

  const stringifiedError = JSON.stringify(error)

  const errorMessage = getErrorMessage(stringifiedError)

  window.prompt(errorMessage, stringifiedError)

  clearLocalAuthValues()

  window.location.replace('/')
}

// events

oktaClient.tokenManager.on('added', async (_, idToken) => {
  const { claims } = idToken as IDToken
  const { idp } = claims as _UserClaims

  setSentryTag(SentryTag.OKTA_IDP, idp)
})

oktaClient.tokenManager.on('renewed', async () => {
  if (process.env.NODE_ENV !== 'production') {
    const date = new Date()

    console.info(`Okta token renewed at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`)
  }

  clearTimeout(logoutRedirectTimeoutId)
})

oktaClient.tokenManager.on('expired', () => {
  if (window.location.pathname !== '/login') {
    if (process.env.NODE_ENV !== 'production') {
      const date = new Date()

      console.info(`Okta token expired at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}. Starting 30 second timeout before redirecting`)
    }

    logoutRedirectTimeoutId = setTimeout(logOut, 30000)
  }
})

oktaClient.tokenManager.on('error', async error => {
  clearTimeout(logoutRedirectTimeoutId)

  if (window.location.pathname === '/login') {
    console.error('Error renewing token. Clearing local auth values…', error)

    clearLocalAuthValues()
  } else {
    console.error('Error renewing token. Logging out…', error)

    logOut()
  }
})
