import { Search } from 'react-feather'
import React, { ChangeEvent, FC } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type _InputProps = {
  autoComplete?: string
  isDisabled?: boolean
  name?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  type?: string
  value?: string
  variant?: string
}

// components

const Input: FC<_InputProps> = ({ isDisabled = false, placeholder, type = `text`, variant, ...rest }) => {
  const isSearch = variant === `search`

  return (
    <div className={css.wrapper}>
      {isSearch && <Search className={css.icon} />}

      <input
        aria-label={placeholder || (isSearch ? `Search` : `Enter a value`)}
        className={clsx(css.input, isSearch && css.hasIcon)}
        disabled={isDisabled}
        placeholder={placeholder}
        type={type}
        {...rest}
      />
    </div>
  )
}

export default Input
