import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useIsAnnotator } from '../hooks/useUserAccess'
import { useModalContext } from '.'
import useCurrentUser from '../hooks/useCurrentUser'

// functions

const deleteCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

const deleteDeprecatedColumnWidths = () => {
  const deprecatedKeyPrefix = /^columnWidths__/
  const deprecatedKeys = Object.keys(localStorage).filter(key => deprecatedKeyPrefix.test(key))

  if (isEmpty(deprecatedKeys)) return

  for (const deprecatedKey of deprecatedKeys) {
    localStorage.removeItem(deprecatedKey)
  }
}

/**
 * Migrates column width keys to a format supporting multi-tenant storage.
 *
 * Example: `columnWidths__/dashboard/documents` -> `${customerId}__COLUMN_WIDTHS/dashboard/documents`.
 */
const migrateColumnWidths = () => {
  const deprecatedKeyPrefix = /^columnWidths__/
  const deprecatedKeys = Object.keys(localStorage).filter(key => deprecatedKeyPrefix.test(key))

  if (isEmpty(deprecatedKeys)) return

  for (const deprecatedKey of deprecatedKeys) {
    const currentValue = localStorage.getItem(deprecatedKey)
    const customerId = localStorage.getItem('customerId')
    const newKey = `${customerId}__COLUMN_WIDTHS${deprecatedKey.replace(deprecatedKeyPrefix, '')}`

    if (currentValue) {
      localStorage.setItem(newKey, currentValue)
    }

    localStorage.removeItem(deprecatedKey)
  }
}

/**
 * Migrates deprecated query keys to a format supporting multi-tenant storage.
 *
 * Example: `PREVIOUS_DEAL_QUERY` -> `${customerId}__PREVIOUS_DEAL_QUERY`.
 */
const migratePreviousQueries = () => {
  const deprecatedKeyPrefix = /^PREVIOUS_/
  const deprecatedKeys = Object.keys(localStorage).filter(key => deprecatedKeyPrefix.test(key))

  if (isEmpty(deprecatedKeys)) return

  for (const deprecatedKey of deprecatedKeys) {
    const currentValue = localStorage.getItem(deprecatedKey)
    const customerId = localStorage.getItem('customerId')
    const newKey = `${customerId}__${deprecatedKey}`

    if (currentValue) {
      localStorage.setItem(newKey, currentValue)
    }

    localStorage.removeItem(deprecatedKey)
  }
}

/**
 * Migrates deprecated selected columns keys to a format supporting multi-tenant storage.
 *
 * Example: `KLARITY_SELECTED_COLUMNS/dashboard/documents` -> `${customerId}__SELECTED_COLUMNS/dashboard/documents`.
 */
const migrateSelectedColumns = () => {
  const deprecatedKeyPrefix = /^KLARITY_SELECTED_COLUMNS/
  const deprecatedKeys = Object.keys(localStorage).filter(key => deprecatedKeyPrefix.test(key))

  if (isEmpty(deprecatedKeys)) return

  for (const deprecatedKey of deprecatedKeys) {
    const currentValue = localStorage.getItem(deprecatedKey)
    const customerId = localStorage.getItem('customerId')
    const newKey = `${customerId}__SELECTED_COLUMNS${deprecatedKey.replace(deprecatedKeyPrefix, '')}`

    if (currentValue) {
      localStorage.setItem(newKey, currentValue)
    }

    localStorage.removeItem(deprecatedKey)
  }
}

const removeIntercomAndGoogleCookies = () => {
  const cookiesToRemove = ['intercom-id', 'intercom-session', '1P_JAR', '_ga', '_gid']
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    const cookieName = ca[i].split('=')[0]
    for (let c = 0; c < cookiesToRemove.length; c++) {
      if (cookieName?.includes(cookiesToRemove[c])) {
        deleteCookie(cookieName)
        break
      }
    }
  }
}

// components

/**
 * Manages app-level utility useEffects outside of the main `app/index.tsx` file.
 */
export const AppSetupUtils = () => {
  const { closeModal, openModal } = useModalContext()
  const history = useHistory()
  const currentUser = useCurrentUser()
  const isAnnotator = useIsAnnotator()
  const [isStorageListenerLoaded, setIsStorageListenerLoaded] = useState(false)

  // TODO: Remove on or after July 11, 2024 (to ensure one fiscal quarter has been allotted for the migration to occur).
  useEffect(() => {
    migratePreviousQueries()
    migrateSelectedColumns()

    // Multi-tenant users (improperly referred to as "super admins") should have their deprecated column widths removed from local storage.
    // The deprecated implementation of this feature failed to recreate the relevant local storage keys when the user switched tenants,
    // so the deprecated keys for such users will contain a mix of values for multiple tenants within a single key. It is more practical
    // to remove these keys entirely and allow such users to start fresh with the new tenant-specific keys.
    currentUser?.is_super_admin ? deleteDeprecatedColumnWidths() : migrateColumnWidths()
  }, [currentUser])

  useEffect(() => {
    removeIntercomAndGoogleCookies()
  }, [])

  useEffect(() => {
    // this block sets up current customer tracking for the wrong customer modal and the ability to force sign out of all tabs on logout/token expiration
    if (currentUser && !isStorageListenerLoaded) {
      const currentCustomerName = localStorage.getItem('customerName')
      window.addEventListener('storage', e => {
        let currentName = currentCustomerName
        if (e.key === 'okta-token-storage' && e.newValue === null) {
          // this is used to log out of all tabs when the okta-token-storage value has been cleared.
          history.push('/login') // redirect to /login to not needlessly clear values/sign out of Okta again and potentially cause infinite looping back to /logout
        } else if (e.key === 'customerName' && isAnnotator) {
          if (!currentName) {
            currentName = e.newValue
          } else if (currentName === e.newValue) {
            closeModal()
          } else {
            openModal(`WrongCustomer`, `Wrong Customer`, { customerName: currentName })
          }
        }
      })
      setIsStorageListenerLoaded(true)
    }
    // eslint-disable-next-line
    }, [currentUser, isAnnotator]);

  return null
}
