import { captureError } from './sentry'
import { format, formatDistance } from 'date-fns'

/**
 * Convert ISO datestring to X time ago...
 * If no timezone designator, assume UTC and append '+00:00'
 */
export function formatTimeAgo(datestring: string) {
  if (!datestring) {
    return ''
  }
  if (!datestring.includes('+')) {
    datestring += '+00:00'
  }

  const datetime = new Date(datestring)
  const now = new Date()
  return formatDistance(datetime, now)
}

export function formatDate(datestring: string) {
  if (!datestring) {
    return ''
  }

  // Safari strictly requires ISO 8601 format for parsing date strings. However, the backend applies a custom format to some date fields, e.g.:
  //   f'{document_data["created_at"].strftime(datetime_format)} UTC' => '2021-01-01 00:00:00 UTC'
  // This is not a valid ISO 8601 format, and it must be converted to '2021-01-01T00:00:00Z' before parsing.
  const isSafari = /^((?!chrome).)*safari/i.test(navigator.userAgent)

  if (isSafari && datestring.includes(' ') && datestring.endsWith(' UTC')) {
    datestring = datestring.replace(' ', 'T').replace(' UTC', 'Z')
  }

  const datetime = new Date(datestring)

  try {
    return format(datetime, 'MMM d, yyyy')
  } catch (error) {
    captureError(error)

    return ''
  }
}

export const formatDateTime = (dateTime: Date) =>
  new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
    month: '2-digit',
    timeZoneName: 'short',
    year: '2-digit'
  })
    .format(dateTime)
    .replace(',', '')
