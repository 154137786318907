import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { useOpening } from '@hoologic/use-opening'
import LinkIcon from '@mui/icons-material/Link'
import React, { FC, useRef } from 'react'
import WithTooltip from '../WithTooltip/WithTooltip'
import urlRegexSafe from 'url-regex-safe'

// types

type _UrlMenuProps = {
  staticUrls?: string[]
  value: string
}

// functions

const extractUrls = (input: string): string[] => input?.match(urlRegexSafe({ ipv6: false, strict: true })) || []

// components

export const UrlMenu: FC<_UrlMenuProps> = ({ staticUrls = [], value }) => {
  const iconButtonRef = useRef<HTMLButtonElement | null>(null)
  const opening = useOpening()
  const urls = Array.from(new Set([...staticUrls, ...extractUrls(value)]))

  const closeMenu = () => {
    opening.close()

    setTimeout(() => iconButtonRef.current?.blur()) // Must be wrapped in setTimeout to remove focus.
  }

  if (isEmpty(urls)) return null

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          borderRadius: '50%',
          display: 'flex',
          height: 36,
          justifyContent: 'center',
          width: 24
        }}
      >
        <WithTooltip content="View links">
          <IconButton
            aria-label="View links"
            onClick={opening.toggle}
            ref={iconButtonRef}
            sx={{
              backgroundColor: 'transparent',
              border: '1px solid transparent',
              color: '#57575b',
              opacity: 0.5,
              p: 0.25,
              transition: 'all 0.1s ease-in-out',
              '&:focus, &:hover': { backgroundColor: '#f3f4f8', opacity: 1, zIndex: 1 }
            }}
            tabIndex={-1} // Ensure users can tab between field inputs without focusing on this button.
          >
            <LinkIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </WithTooltip>
      </Box>

      <Menu
        MenuListProps={{ dense: true }}
        PaperProps={{ sx: { maxHeight: '50vh', maxWidth: 412 } }}
        anchorEl={opening.anchor}
        autoFocus={false}
        onClose={closeMenu}
        open={opening.isOpen}
        sx={{ ml: 3 }}
      >
        {urls.map(url => (
          <MenuItem
            component="a"
            href={url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`}
            key={url}
            onClick={closeMenu}
            rel="noreferrer"
            sx={{ fontSize: 13, py: 1 }}
            tabIndex={0}
            target="_blank"
          >
            <Typography noWrap sx={{ color: '#333', fontSize: 13 }}>
              {url}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
