import { CreateAnnotationRetry, MutateAnnotation } from '../../graphql/codegen/schemas'
import { ModalProps } from '../Modal/Modal'
import AnnotationRetryForm from './AnnotationRetryForm'
import Modal from '../Modal'
import React, { FC } from 'react'
import css from './style.module.scss'

// types

type _AnnotationRetryModalProps = ModalProps & {
  documentId: string
  modalContent: { create_annotation: CreateAnnotationRetry; prevAnnotation: MutateAnnotation; prevSelectedLabel: string | null } | null
}

// components

const AnnotationRetryModal: FC<_AnnotationRetryModalProps> = ({ documentId, modalContent, onRequestClose, ...rest }) => {
  if (!modalContent) return null

  const { create_annotation, prevAnnotation, prevSelectedLabel } = modalContent

  return (
    <Modal title="Please try again" {...rest} isDraggable>
      <p style={{ maxWidth: '600px', marginBottom: 8 }}>
        The selected text was not considered valid for the tag <q>{prevSelectedLabel}</q>.
      </p>

      <p style={{ maxWidth: '600px' }}>Please manually update the related field(s) below.</p>

      <div className={css.retryModal}>
        <AnnotationRetryForm create_annotation={create_annotation} documentId={documentId} onRequestClose={onRequestClose} prevAnnotation={prevAnnotation} />
      </div>
    </Modal>
  )
}

export default AnnotationRetryModal
