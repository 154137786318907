import { RefObject, useEffect, useRef } from 'react'

type UseAutoScrollOnTabProps = {
  containerRef: RefObject<HTMLElement> // The container in which scrolling should occur.
  elementSelector?: string // Identify the parent element that should be scrolled to (e.g. '[data-id="my-element"]'), if not the focused element.
}

/**
 * Automatically scroll the container to the focused element (or its parent, if specified) when the user tabs to it.
 */
export const useAutoScrollOnTabKeyPress = ({ containerRef, elementSelector }: UseAutoScrollOnTabProps) => {
  const isTabKeyPressedRef = useRef(false)

  useEffect(() => {
    const setTabKeyPressed = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        isTabKeyPressedRef.current = true
      }
    }

    const unsetTabKeyPressed = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        isTabKeyPressedRef.current = false
      }
    }

    document.addEventListener('keydown', setTabKeyPressed)
    document.addEventListener('keyup', unsetTabKeyPressed)

    return () => {
      document.removeEventListener('keydown', setTabKeyPressed)
      document.removeEventListener('keyup', unsetTabKeyPressed)
    }
  }, [])

  useEffect(() => {
    const adjustScrollOnFocus = (event: FocusEvent) => {
      const container = containerRef.current
      const focusedElement = event.target as HTMLElement

      if (!container || !focusedElement || !isTabKeyPressedRef.current) return

      const activeElement = elementSelector ? (document.activeElement as HTMLElement)?.closest(elementSelector) : (document.activeElement as HTMLElement)

      if (!activeElement || !container.contains(activeElement)) return

      const activeElementRect = activeElement.getBoundingClientRect()
      const containerRect = container.getBoundingClientRect()
      const isElementVisibleInContainer = activeElementRect.top >= containerRect.top && activeElementRect.bottom <= containerRect.bottom

      if (!isElementVisibleInContainer) {
        const centerPosition = containerRect.height / 2
        const scrollOffset = activeElementRect.top - containerRect.top - centerPosition

        container.scrollBy({ top: scrollOffset, behavior: 'auto' })
      }
    }

    document.addEventListener('focusin', adjustScrollOnFocus)

    return () => document.removeEventListener('focusin', adjustScrollOnFocus)
  }, [containerRef, elementSelector])
}
