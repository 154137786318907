import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { Loader } from 'react-feather'
import { Placement } from '@popperjs/core'
import { useHistory } from 'react-router'
import { usePopper } from 'react-popper'
import Button from '../Button'
import FreeText from '../DatapointInput/FreeText'
import React, { CSSProperties, FC, KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'
import useOnClickOutside from '../../hooks/useOnClickOutside'

// types

type _Item = { href?: string; label: string; onClick: (...args: any) => void }

type _QueryMenuProps = {
  label?: string | null
  loading?: boolean
  placement?: Placement
  queryData?: any
  queryFunction: any
  queryItemName?: string
  queryPlaceholder?: string
  queryVars?: any
  style?: CSSProperties
  styleOffset?: any
}

// components

const QueryMenu: FC<_QueryMenuProps> = ({
  label,
  placement = 'right',
  loading,
  style,
  styleOffset = [8, 8],
  queryFunction,
  queryVars,
  queryData,
  queryPlaceholder,
  queryItemName
}) => {
  const history = useHistory()
  const ref = useRef<HTMLDivElement | null>(null)
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | HTMLSpanElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [isVisible, setIsVisible] = useState(false)
  const [isFocused, setFocused] = useState(false)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const { attributes, styles } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 16 } },
      { name: 'offset', options: { offset: styleOffset } }
    ]
  })
  useOnClickOutside(ref, () => setIsVisible(false))

  const [menuItems, setMenuItems] = useState<Array<_Item>>([])
  const [offset, setOffset] = useState<any>()
  const [searchTerm, setSearchTerm] = useState('')
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(true)

  useEffect(() => {
    // May need to add additional map functions/if statements to support different query types
    const items: Array<_Item> = []
    queryData?.deals_by_counter_party?.forEach((item: any, index: any) => {
      if (item?.name && index < 10) {
        items.push({
          label: item?.alias || item?.name,
          href: `/deals/${item?.id}`,
          onClick: () => {
            history.push(`/deals/${item?.id}`)
          }
        })
      }
    })
    setMenuItems([...items])

    // @ts-ignore
    if (queryData?.deals_by_counter_party?.length < 20) {
      setNextButtonDisabled(true)
    }
    if (offset === 0) {
      setPrevButtonDisabled(true)
    }
  }, [queryData, history, offset])

  const checkForRequiredValues = () => {
    if (queryVars?.required) {
      for (const key of Object.keys(queryVars?.required)) {
        if (!queryVars?.required[key]) {
          return false
        }
      }
      return true
    } else {
      return true
    }
  }

  const handleNext = () => {
    if (checkForRequiredValues()) {
      const newOffset = offset + 10
      queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm, offset: newOffset, size: 20 } })
      setOffset(newOffset)
    }
  }

  const handlePrev = () => {
    if (checkForRequiredValues()) {
      const newOffset = offset - 10 <= 0 ? 0 : offset - 10
      queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm, offset: newOffset, size: 20 } })
      setOffset(newOffset)
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (checkForRequiredValues()) {
        queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm, offset, size: 20 } })
        setOffset(0)
      }
    }
  }

  return (
    <div ref={ref} style={style}>
      <span ref={setReferenceElement}>
        <Button className={css.subTitle} onClick={() => setIsVisible(prev => !prev)}>
          {label}
        </Button>
      </span>

      {isVisible && (
        <div className={css.menuPane} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <ul style={{ cursor: 'default' }}>
            <FreeText
              isFocused={isFocused}
              onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => setSearchTerm(event.currentTarget.value)}
              onKeyDown={handleKeyDown}
              placeholder={queryPlaceholder || 'Search…'}
              setFocused={setFocused}
              value={searchTerm}
            />

            {loading ? (
              <div className={css.rotate}>
                <Loader />
              </div>
            ) : (
              menuItems?.map(item => <MenuItem key={item.label} {...item} setIsVisible={setIsVisible} />)
            )}

            {!loading && menuItems?.length === 0 && <li className={css.menuItem} style={{ cursor: 'default' }}>{`No ${queryItemName} found`}</li>}

            {!loading && (
              <li className={clsx(css.pagination, nextButtonDisabled && prevButtonDisabled && css.isDisabled)}>
                <Button
                  aria-label="Previous page"
                  className={clsx(prevButtonDisabled && css.isDisabled)}
                  icon={<FiChevronLeft />}
                  onClick={prevButtonDisabled ? undefined : handlePrev}
                />

                <Button
                  aria-label="Next page"
                  className={clsx(nextButtonDisabled && css.isDisabled)}
                  icon={<FiChevronRight />}
                  onClick={nextButtonDisabled ? undefined : handleNext}
                />
              </li>
            )}
          </ul>

          <div ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </div>
  )
}

const MenuItem = ({ href, label, onClick, setIsVisible }: _Item & { setIsVisible: (b: boolean) => void }) => {
  const handleClick = () => {
    setIsVisible(false)
    onClick()
  }

  return (
    <li className={css.menuItem} onClick={handleClick}>
      {href ? (
        <Link title={'Open link'} to={href}>
          {label}
        </Link>
      ) : (
        label
      )}
    </li>
  )
}

export default QueryMenu
