import { KLARITY_BLUE } from '../../../../utils/styleUtils'
import React, { FC, useEffect, useRef } from 'react'
import Select from 'react-select'

// types

type _DropdownProps = {
  ariaLabel?: string
  data_point_field: any
  isError: boolean
  isFocused: boolean
  isMulti?: boolean
  onChange?: (e: any) => void
  setFocused: (arg: boolean) => void
  value: any
}

// components

const Dropdown: FC<_DropdownProps> = ({ ariaLabel, data_point_field, isError, isFocused, isMulti, onChange, setFocused, value }) => {
  const ref = useRef<HTMLElement>()
  const { name, options } = data_point_field

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus()
    }
  }, [isFocused])

  return (
    <Select
      aria-label={ariaLabel || name || 'Select an option'}
      isClearable
      isMulti={isMulti}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      onBlur={() => setFocused(false)}
      onChange={onChange}
      onFocus={() => setFocused(true)}
      openMenuOnFocus
      options={options.map((label: string) => ({ value: label.toLowerCase().replace(' ', '_'), label }))}
      placeholder={name}
      // @ts-ignore
      ref={ref}
      styles={{
        clearIndicator: (provided, { isFocused }) => ({ ...provided, visibility: isFocused ? 'visible' : 'hidden' }),
        container: provided => ({ ...provided, padding: 0 }),
        control: provided => ({
          ...provided,
          fontSize: 14,
          borderRadius: 4,
          borderColor: '#bdbec2',
          boxShadow: 'none',
          border: `${isError ? '1px solid red' : ''}`
        }),
        dropdownIndicator: () => ({ display: 'none' }),
        indicatorSeparator: () => ({ display: 'none' }),
        menuPortal: provided => ({ ...provided, zIndex: 999999999 }),
        multiValue: provided => ({ ...provided, maxWidth: '300px' }),
        option: provided => ({ ...provided, fontSize: 14 }),
        placeholder: provided => ({ ...provided, color: '#e0e0e0' })
      }}
      tabSelectsValue={false}
      theme={theme => ({ ...theme, borderRadius: 2, colors: { ...theme.colors, primary: KLARITY_BLUE } })}
      value={value}
    />
  )
}

export default Dropdown
