import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { Accordions, useCciMainContext } from '../../../../CCI_Main'
import { BooleanInputs } from './inputs/BooleanInputs'
import { CollisionTypeInput } from '../ChecklistTab/SharedInputs/CollisionTypeInput'
import { DefaultValueInput } from './inputs/DefaultValueInput'
import { ExtractionMethodInput } from './inputs/ExtractionMethodInput'
import { Features, Permissions, useUserAccess } from '../../../../../../hooks/useUserAccess'
import { ResolutionStrategyInput } from './inputs/ResolutionStrategyInput'
import { Sources } from '../ChecklistTab/CreateInputs/SourceInput'
import { TooltipTextInput } from './inputs/TooltipTextInput'
import { useCciChecklistGptContext } from './CCI_RightPanel_ChecklistGptTab'
import { useIsKlarityEmployee } from '../../../../../../hooks/useCurrentUser'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { FC, useMemo } from 'react'

// constants

const ADVANCED_SETTINGS_SX = {
  border: '1px solid #e0e0e0',
  borderRadius: 1,
  boxShadow: 'none',
  mt: 4,
  '.MuiAccordionSummary-root': { pointerEvents: 'auto' },
  '.MuiCollapse-wrapper': { borderTop: '1px solid #e0e0e0' },
  '&:before': { opacity: 0 }
}

// components

export const AdvancedSettings: FC = () => {
  const { expandedAccordionMap, selectedItem, toggleAccordion } = useCciMainContext()
  const { fieldValues, updateFieldValue } = useCciChecklistGptContext()

  const actionTypeId = fieldValues?.action_type_id || selectedItem?.action_type_id
  const collisionType = fieldValues?.collision_type || selectedItem?.collision_type
  const source = fieldValues?.source || selectedItem?.source

  const hasCCIExtractionConfigurationAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_EXTRACTION_CONFIGURATION })
  const isKlarityEmployee = useIsKlarityEmployee()

  const isDefaultValueInputVisible = fieldValues?.source !== Sources.MATCHING && selectedItem?.source !== Sources.MATCHING
  const isInternalSource = source === 'INTERNAL'
  const isExtractionMethodInputVisible = hasCCIExtractionConfigurationAccess && isKlarityEmployee

  const resolutionStrategyList = useMemo(
    () => fieldValues?.resolution_strategy || (selectedItem?.inheritance_logic ? JSON.parse(selectedItem.inheritance_logic).resolution_strategy : []) || [],
    [fieldValues, selectedItem]
  )

  return (
    <Accordion
      disableGutters
      expanded={Boolean(expandedAccordionMap[Accordions.ADVANCED_SETTINGS])}
      onChange={() => toggleAccordion(Accordions.ADVANCED_SETTINGS)}
      sx={ADVANCED_SETTINGS_SX}
    >
      <AccordionSummary aria-controls="advanced-settings-content" expandIcon={<ExpandMoreIcon />} id="advanced-settings-header">
        <Typography sx={{ p: 0.5 }} variant="h4">
          Advanced Settings
        </Typography>
      </AccordionSummary>

      <AccordionDetails key={actionTypeId + selectedItem?.id} sx={{ pb: 2.5, pt: 1.5, px: 2.5 }}>
        <CollisionTypeInput collisionType={collisionType} handleData={updateFieldValue} source={source} />

        <ResolutionStrategyInput collisionType={collisionType} handleData={updateFieldValue} resolutionStrategyList={resolutionStrategyList} source={source} />

        {isExtractionMethodInputVisible && isInternalSource && <ExtractionMethodInput />}

        <TooltipTextInput />

        {isDefaultValueInputVisible && <DefaultValueInput />}

        <BooleanInputs />
      </AccordionDetails>
    </Accordion>
  )
}
