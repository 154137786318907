import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Tooltip, Typography } from '@mui/material'
import { ExpandButton } from '../DatapointInput/EnhancedTextField/EnhancedTextField'
import { Opening, useOpening } from '@hoologic/use-opening'
import { grey } from '@mui/material/colors'
import { useOverflowDetection } from '../../hooks/useOverflowDetection'
import LanguageIcon from '@mui/icons-material/Language'
import React, { FC, ReactNode, SyntheticEvent, useRef, useState } from 'react'
import copy from 'copy-to-clipboard'

// types

type _CellViewProps = { children?: ReactNode; header: string; isCustomerLevelDocument?: boolean; menu?: ReactNode; textValue: string; title?: string }
type _CellViewDialogProps = { header: string; opening: Opening; textValue: string }

// constants

const EXPAND_VIEW_ICON_DISPLAY_DELAY = 667

// components

export const CellView: FC<_CellViewProps> = ({ children, header, isCustomerLevelDocument = false, menu, textValue, title }) => {
  const opening = useOpening()
  const displayValueRef = useRef(null)
  const isHoveringRef = useRef(false)
  const { isOverflowingHorizontally } = useOverflowDetection(displayValueRef)
  const [isExpandViewIconShowing, setIsExpandViewIconShowing] = useState(false)

  if (!textValue) return null

  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault()

    handleMouseLeave()

    opening.open()
  }

  const handleMouseEnter = () => {
    if (opening.isOpen) return

    isHoveringRef.current = true

    setTimeout(() => {
      if (isHoveringRef.current && isOverflowingHorizontally) {
        setIsExpandViewIconShowing(true)
      }
    }, EXPAND_VIEW_ICON_DISPLAY_DELAY)
  }

  const handleMouseLeave = () => {
    if (opening.isOpen) return

    setIsExpandViewIconShowing(false)

    isHoveringRef.current = false
  }

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} sx={{ px: 1.5, py: 1 }}>
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        {menu && <Box sx={{ ml: -1.5, my: -1.25 }}>{menu}</Box>}

        {isCustomerLevelDocument && (
          <Tooltip arrow placement="top" title="Customer-level Document">
            <LanguageIcon sx={{ color: grey[600] }} />
          </Tooltip>
        )}

        <Box ref={displayValueRef} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', p: 0.25, ...(isCustomerLevelDocument && { ml: 1 }) }} title={title}>
          {children || textValue}
        </Box>
      </Box>

      <Box
        key={String(isHoveringRef.current)} // Re-render to ensure proper handling of focus/hover states.
        sx={{
          background: 'white',
          display: isOverflowingHorizontally ? 'block' : 'none',
          opacity: isExpandViewIconShowing ? 1 : 0,
          pointerEvents: isExpandViewIconShowing ? 'auto' : 'none',
          position: 'absolute',
          px: 1,
          py: 0.25,
          right: 0,
          top: 0,
          '&::before': {
            background: `linear-gradient(to right, transparent, white)`,
            content: '""',
            height: '100%',
            left: -6,
            position: 'absolute',
            top: 0,
            width: 6
          }
        }}
      >
        <ExpandButton
          iconButtonProps={{
            onFocusVisible: () => isOverflowingHorizontally && setIsExpandViewIconShowing(true),
            onBlur: () => !isHoveringRef.current && setIsExpandViewIconShowing(false),
            tabIndex: 0
          }}
          onClick={handleClick}
        />
      </Box>

      {opening.isOpen && <CellViewDialog header={header} opening={opening} textValue={textValue} />}
    </Box>
  )
}

const CellViewDialog: FC<_CellViewDialogProps> = ({ header, opening, textValue }) => {
  const copiedOpening = useOpening()

  const handleCopy = (event: SyntheticEvent) => {
    event.stopPropagation()

    copy(textValue, { format: 'text/plain' })

    copiedOpening.open()
  }

  return (
    <Dialog PaperProps={{ sx: { minWidth: '60vh' } }} onClose={opening.close} open={opening.isOpen}>
      <DialogTitle>{header}</DialogTitle>

      <DialogContent>
        <Typography sx={{ border: `1px solid ${grey[300]}`, borderRadius: 1, fontSize: 14, maxHeight: 240, overflow: 'auto', p: 1.5 }}>{textValue}</Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCopy} variant="outlined">
          Copy all
        </Button>

        <Button color="primary" onClick={opening.close} variant="contained">
          Done
        </Button>

        <Snackbar autoHideDuration={3000} onClose={copiedOpening.close} open={copiedOpening.isOpen}>
          <Alert severity="success">Copied.</Alert>
        </Snackbar>
      </DialogActions>
    </Dialog>
  )
}
