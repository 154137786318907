import { Box, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// types

export type _ExtractionMethod = { label: string; value: string | undefined }

export type _ExtractionMethodTypeInputProps = {
  currentMethodId?: string | null | undefined
  currentMethodValue?: string
  extractionMethodList: _ExtractionMethod[]
  handleData: (key: string, value: string | undefined) => void
  isDisabled?: boolean
}

// components

export const ExtractionMethodTypeInput: FC<_ExtractionMethodTypeInputProps> = ({ currentMethodId, extractionMethodList, handleData, isDisabled }) => {
  const [selectedExtractionMethod, setSelectedExtractionMethod] = useState<_ExtractionMethod | null>(null)

  const handleChange = ({ label, value }: _ExtractionMethod) => {
    handleData('extraction_method', value)

    setSelectedExtractionMethod({ label, value })
  }

  useEffect(() => {
    // Initialize selectedExtractionMethod with the corresponding label and value when currentMethodId is passed as a prop
    if (currentMethodId) {
      const initialMethod = extractionMethodList.find(({ value }) => value === currentMethodId)

      if (initialMethod) {
        setSelectedExtractionMethod({ label: initialMethod.label, value: initialMethod.value })
      }
    } else {
      setSelectedExtractionMethod({ label: 'Select an extraction method type', value: undefined })
    }
  }, [currentMethodId, extractionMethodList])

  return (
    <Box className={css.inputWrapper}>
      <Typography component="label" htmlFor="extraction-method-type-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        Extraction Method Type
      </Typography>

      <SelectInput
        id="extraction-method-type-input"
        isDisabled={isDisabled}
        onChange={handleChange}
        options={extractionMethodList}
        placeholder={selectedExtractionMethod !== null ? selectedExtractionMethod.label : 'Select an extraction method type'}
        value={currentMethodId ? selectedExtractionMethod : null}
      />
    </Box>
  )
}
