import { Box, CircularProgress, InputAdornment, TextField } from '@mui/material'
import React, { Dispatch, FC, SetStateAction } from 'react'
import SearchIcon from '@mui/icons-material/Search'

// types

type _SearchBarProps = { isLoading?: boolean; placeholder?: string; setFilter: Dispatch<SetStateAction<string>>; value?: string }

// components

export const SearchBar: FC<_SearchBarProps> = ({
  isLoading = false,
  placeholder = isLoading ? 'Loading checklist items, please wait…' : 'Search checklist items…',
  value,
  setFilter
}) => (
  <Box sx={{ p: 2, width: '100%' }}>
    <TextField
      InputProps={{
        autoComplete: 'off',
        startAdornment: <InputAdornment position="start">{isLoading ? <CircularProgress color="inherit" size={16} /> : <SearchIcon />}</InputAdornment>,
        sx: { fontSize: 13 }
      }}
      disabled={isLoading}
      fullWidth
      inputProps={{ 'aria-label': placeholder }}
      onChange={({ currentTarget: { value } }) => setFilter(value)}
      placeholder={placeholder}
      size="small"
      sx={{ '& .MuiInputAdornment-root': { ml: isLoading ? 1 : 0, mr: 0.5 }, '& .MuiInputBase-root': { pl: 1 } }}
      type="search"
      value={value}
    />
  </Box>
)
