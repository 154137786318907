import { EditDataPointManualReviewMutation } from '../../graphql/codegen/operations'
import { MutationFunctionOptions, MutationResult } from '@apollo/client'
import { Tooltip } from '@mui/material'
import React, { FC, useCallback } from 'react'
import css from './style.module.scss'
import type { CustomState, DataPoint, Exact } from '../../graphql/codegen/schemas'

// types

type _ManualReviewCheckboxProps = {
  dealIsFinalized: boolean
  handleChange: () => void
  isLoading: boolean
  isReviewed: boolean
  priority: number
  state: CustomState | null
  tabIndex?: number
}

type _ManualReviewCheckboxesProps = {
  dataPoint: DataPoint
  dealIsFinalized: boolean
  mutation: (options: MutationFunctionOptions<EditDataPointManualReviewMutation, Exact<{ dataPointId: string; isReviewed: boolean; priority: number }>>) => void
  mutationState: MutationResult<EditDataPointManualReviewMutation>
  states: CustomState[]
  tabIndex?: number
}

// components

const ManualReviewCheckbox: FC<_ManualReviewCheckboxProps> = ({ dealIsFinalized, handleChange, isLoading, isReviewed, priority, state, tabIndex = 0 }) => {
  const tooltipTitle = dealIsFinalized
    ? `This field has${isReviewed ? `` : ` not`} been manually reviewed`
    : priority === state?.priority
    ? `Check this box after manual review`
    : `Disabled${state ? ` while in L${state.priority! - 1}` : ``}`

  return (
    <Tooltip arrow placement="top" title={tooltipTitle}>
      <label
        className={css.manualReviewCheckboxLabel}
        style={{ cursor: dealIsFinalized || isLoading || priority !== state?.priority ? 'default' : 'pointer', opacity: isLoading ? 0.5 : 1 }}
      >
        {`L${priority - 1}`}

        <input
          aria-label={tooltipTitle}
          checked={isReviewed}
          className={css.manualReviewCheckbox}
          disabled={dealIsFinalized || isLoading || priority !== state?.priority}
          onChange={handleChange}
          tabIndex={tabIndex}
          type="checkbox"
        />
      </label>
    </Tooltip>
  )
}

export const ManualReviewCheckboxes = ({ dataPoint, dealIsFinalized, mutation, mutationState, states, tabIndex }: _ManualReviewCheckboxesProps) => {
  const { loading: isLoading } = mutationState
  const state = dealIsFinalized ? null : states.find(state => state.active)!

  // @ts-ignore
  const { id, [`is_reviewed_${state?.priority - 1}`]: isReviewed } = dataPoint

  const handleChange = useCallback(() => {
    if (state) {
      mutation({ variables: { dataPointId: id, isReviewed: !isReviewed, priority: state.priority! } })
    }
  }, [id, isReviewed, mutation, state])

  return (
    <>
      {states.map(({ priority }) => {
        // @ts-ignore
        const isReviewed = dataPoint[`is_reviewed_${priority - 1}`]

        return (
          <ManualReviewCheckbox
            dealIsFinalized={dealIsFinalized}
            handleChange={handleChange}
            isLoading={isLoading}
            isReviewed={isReviewed}
            key={priority!}
            priority={priority!}
            state={state}
            tabIndex={tabIndex}
          />
        )
      })}
    </>
  )
}
