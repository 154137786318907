import { Typography } from '@mui/material'
import React, { FC } from 'react'

// types

type _DisplayYesOrNoProps = {
  value: any
}

// components

export const DisplayYesOrNo: FC<_DisplayYesOrNoProps> = ({ value }) => <Typography>{value ? 'Yes' : 'No'}</Typography>

export const Error: FC<{ message?: string }> = ({ message }) => <Typography sx={{ color: 'error.main', fontSize: 12, mt: 0.5 }}>{message}</Typography>
