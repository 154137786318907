import { Tab, TabProps, Tooltip, TooltipProps } from '@mui/material'
import React, { ReactElement } from 'react'

// types

type TooltipOnDisabledTabProps = TabProps & {
  tooltipProps: Omit<TooltipProps, 'children'>
}

type WrapperProps = Omit<TabProps, 'children'> & {
  children: (tabProps: TabProps) => ReactElement
}

// components

/**
 * This convoluted workaround is necessary because MUI's Tabs component does not allow a disabled Tab to be wrapped in a Tooltip.
 *
 * @link https://stackoverflow.com/questions/70031664/tooltip-on-disabled-tab-material-ui-react
 */
const Wrapper = ({ children, ...rest }: WrapperProps) => children(rest)

export const TabWithTooltipOnDisable = ({ tooltipProps, ...tabProps }: TooltipOnDisabledTabProps) => (
  <Wrapper {...tabProps}>
    {(_tabProps: TabProps) => (
      <Tooltip disableHoverListener={!tabProps.disabled} {...tooltipProps}>
        <div>
          <Tab {..._tabProps} />
        </div>
      </Tooltip>
    )}
  </Wrapper>
)
