import DateInput from './DateInput'
import Dropdown from './Dropdown'
import FreeText from './FreeText'
import NumberInput from './NumberInput'
import Percentage from './Percentage'
import React, { FC, SyntheticEvent, useState } from 'react'
import type { DataPointField } from '../../../graphql/codegen/schemas'

// types

type _UploadInputsProps = {
  dataPointField: DataPointField
  handleChange: any
  isError: boolean
  value: any
  valueType: string
}

// components

const UploadInputs: FC<_UploadInputsProps> = ({ dataPointField, handleChange, isError, value, valueType }) => {
  const [isFocused, setFocused] = useState<boolean>(false)
  const { field_type, id, name, value_format } = dataPointField || {}

  switch (field_type) {
    case 'TEXT':
    case 'DURATION':
    case 'CURRENCY':
    case 'CURRENCY_TYPE':
    case 'GEO_CITY':
    case 'GEO_STATE':
    case 'GEO_COUNTRY':
    case 'ADDRESS':
      return (
        <FreeText
          ariaLabel={name}
          isError={isError}
          isFocused={isFocused}
          onChange={(e: SyntheticEvent<HTMLTextAreaElement>) => handleChange(valueType, id, e.currentTarget.value)}
          placeholder={name}
          setFocused={setFocused}
          value={value}
        />
      )

    case 'DROP_DOWN':
    case 'BOOLEAN':
      return (
        <Dropdown
          ariaLabel={name}
          data_point_field={dataPointField}
          isError={isError}
          isFocused={isFocused}
          onChange={v => handleChange(valueType, id, v?.label || '')}
          setFocused={setFocused}
          value={value ? { value: value.toLowerCase().replace(' ', '_'), label: value } : null}
        />
      )

    case 'MULTI_SELECT_DROP_DOWN':
      return (
        <Dropdown
          ariaLabel={name}
          data_point_field={dataPointField}
          isError={isError}
          isFocused={isFocused}
          isMulti
          onChange={(v: any) => {
            const mappedValues = v?.map((v: any) => v.label) || []
            // @ts-ignore
            const duplicatesRemoved = [...new Set(mappedValues)]
            handleChange(valueType, id, '', duplicatesRemoved)
          }}
          setFocused={setFocused}
          value={value?.length > 0 ? value?.map((v: any) => ({ label: v, value: v })) : ''}
        />
      )

    case 'PERCENTAGE':
      return (
        <Percentage
          ariaLabel={name}
          isError={isError}
          isFocused={isFocused}
          name={name}
          onChange={e => handleChange(valueType, id, e.currentTarget.value)}
          setFocused={setFocused}
          value={value}
        />
      )

    case 'FLOAT':
    case 'NUMBER':
      return (
        <NumberInput
          ariaLabel={name}
          decimalScale={field_type === 'FLOAT' ? 3 : 0}
          isError={isError}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => handleChange(valueType, id, e.currentTarget.value)}
          placeholder={name}
          value={value}
        />
      )

    case 'DATE':
      return (
        <DateInput
          ariaLabel={name}
          displayFormat={value_format}
          isError={isError}
          onChange={v => {
            setFocused(false)
            handleChange(valueType, id, v || '')
          }}
          setFocused={setFocused}
          value={value}
        />
      )

    default:
      if (process.env.NODE_ENV !== 'production') {
        console.info(`Unsupported field_type: ${field_type}`)
      }
      return null
  }
}

export default UploadInputs
