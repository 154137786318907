import { Box, Button, Typography } from '@mui/material'
import { Features, Permissions, useUserAccess } from '../../../../../../../hooks/useUserAccess'
import { ModalOptions, useCciMainContext } from '../../../../../CCI_Main'
import React, { FC } from 'react'
import css from './style.module.scss'

// types

type _NeutralTagRowProps = { oldTag?: string }

// constants

const LABEL = 'Automation Tag'

// components

export const NeutralTagRow: FC<_NeutralTagRowProps> = ({ oldTag }) => {
  const { openModal, selectedItem } = useCciMainContext()
  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  const handleClick = () => {
    openModal({
      content: {
        oldTag: oldTag || (selectedItem?.external_mapping?.length === 1 ? selectedItem?.external_mapping[0] : undefined),
        fieldName: selectedItem?.name,
        fieldId: selectedItem?.id
      },
      modalOption: ModalOptions.EDIT_AUTOMATION_TAG
    })
  }

  return (
    <Box className={css.neutralTagInputWrapper} sx={oldTag ? { mr: 4.5, width: '100%' } : { my: 0 }}>
      <Typography sx={{ fontWeight: 600, ml: oldTag ? 22.5 : 0, mr: 2, width: 164 }} variant="body2">
        {LABEL}
      </Typography>

      <Box>
        <Typography variant="body2">{oldTag || selectedItem?.external_mapping}</Typography>

        {hasFullEditAccess && (
          <Button disableElevation id={`${LABEL}-form-item`} onClick={handleClick} size="small" variant="outlined">
            Edit
          </Button>
        )}
      </Box>
    </Box>
  )
}
