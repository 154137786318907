import { useAnnotationTagsetQuery } from '../../graphql/codegen/hooks'
import React, { FC, useMemo } from 'react'
import SelectInput from '../SelectInput'

// types

type _DataPointSelectorProps = { documentId: string; onChange: (value: any) => void }

// components

const DataPointSelector: FC<_DataPointSelectorProps> = ({ documentId, onChange }) => {
  const { data, loading } = useAnnotationTagsetQuery({ variables: { documentId } })

  const options = useMemo(() => {
    if (!data?.annotation_tagset) return []

    return data.annotation_tagset.map(tag => ({ label: tag?.display_name, value: tag?.internal_name }))
  }, [data])

  return (
    <div style={{ width: 300 }}>
      <SelectInput aria-label="Assign to tag" isLoading={loading} onChange={onChange} options={options} />
    </div>
  )
}

export default DataPointSelector
