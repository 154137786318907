import { Box, TextField, Typography } from '@mui/material'
import { getFieldTypeLabel } from '../../../../../../../utils/cci'
import { sortBy } from 'lodash'
import React, { FC, useEffect, useMemo } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// types

export enum FieldTypeLabels {
  BOOLEAN = 'Boolean',
  CURRENCY = 'Currency',
  CURRENCY_TYPE = 'Currency Type',
  DATA_TABLE = 'Data Table',
  DATE = 'Date',
  DROPDOWN = 'Dropdown',
  DURATION = 'Duration',
  FLOAT = 'Number – Decimal',
  GEO_CITY = 'Geo – City',
  GEO_COUNTRY = 'Geo – Country',
  GEO_STATE = 'Geo – State',
  MULTI_SELECT_DROPDOWN = 'Dropdown – Multi-select',
  NUMBER = 'Number – Non-decimal',
  PERCENTAGE = 'Number – Percentage',
  TEXT = 'Text'
}

export enum FieldTypes {
  BOOLEAN = 'BOOLEAN',
  CURRENCY = 'CURRENCY',
  CURRENCY_TYPE = 'CURRENCY_TYPE',
  DATA_TABLE = 'DATA_TABLE',
  DATE = 'DATE',
  DROPDOWN = 'DROP_DOWN',
  DURATION = 'DURATION',
  FLOAT = 'FLOAT',
  GEO_CITY = 'GEO_CITY',
  GEO_COUNTRY = 'GEO_COUNTRY',
  GEO_STATE = 'GEO_STATE',
  MULTI_SELECT_DROPDOWN = 'MULTI_SELECT_DROP_DOWN',
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  TEXT = 'TEXT'
}

type _FieldTypeInputProps = {
  handleData: (type: string, value: any) => void
  isReadOnly?: boolean
  value?: string
}

// components

export const FieldTypeInput: FC<_FieldTypeInputProps> = ({ handleData, isReadOnly = false, value }) => {
  useEffect(() => {
    handleData('default_value', null)
    handleData('value_format', null)

    // Preserve existing options only when changing between dropdown field types.
    if (value !== FieldTypes.DROPDOWN && value !== FieldTypes.MULTI_SELECT_DROPDOWN) {
      handleData('options', null)
    }
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  const fieldTypeOptions = useMemo(
    () =>
      sortBy(
        Object.entries(FieldTypes).map(([key, value]) => ({
          label: FieldTypeLabels[key as keyof typeof FieldTypes],
          value
        })),
        'label'
      ),
    []
  )

  if (isReadOnly) {
    return (
      <Box className={css.inputWrapper}>
        <Typography sx={{ fontSize: 14, fontWeight: 600, mr: 2, width: 164 }}>Field Type</Typography>

        <TextField InputProps={{ style: { fontSize: 14 } }} disabled size="small" sx={{ fontSize: 14 }} value={getFieldTypeLabel(value)} />
      </Box>
    )
  }

  return (
    <div className={css.inputWrapper}>
      <Typography component="label" htmlFor="field-type-select-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        Field Type
      </Typography>

      <SelectInput
        id="field-type-select-input"
        isClearable
        onChange={(item: { value: string }) => handleData('field_type', item?.value)}
        options={fieldTypeOptions}
        placeholder={getFieldTypeLabel(value)}
        value={value}
      />
    </div>
  )
}
