import { FiDownloadCloud } from 'react-icons/fi'
import { useAttachmentCountLazyQuery, useDashboardExportLazyQuery, useDealCountLazyQuery, useDocumentCountLazyQuery } from '../../../graphql/codegen/hooks'
import { useIsAnnotator } from '../../../hooks/useUserAccess'
import Button from '../../Button'
import ComponentLoadingOverlay from '../../ComponentLoadingOverlay'
import Modal from '../../Modal'
import RadioButton from '../../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/SharedInputs/RadioButton/RadioButton'
import React, { useEffect, useState } from 'react'
import WithTooltip from '../../WithTooltip'
import css from './style.module.scss'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useDashboardQString from '../../../hooks/useDashboardQString'

type DashboardType = 'DEAL' | 'DOCUMENT' | 'ATTACHMENT'

export function BulkExportWithPdfs({ dashboard_type, selectedColumns }: { dashboard_type: DashboardType; selectedColumns?: any }) {
  const chosenDataPointFieldIds = selectedColumns?.map((dp: any) => dp.data_point_field_id)
  const { gqlFilters } = useDashboardQString()
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [itemCount, setItemCount] = useState<number | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [exportType, setExportType] = useState(dashboard_type === 'ATTACHMENT' ? 'exportAll' : undefined)
  const [includeDocuments, setIncludeDocuments] = useState<any>(undefined)
  const [shouldBlockSubmit, setShouldBlockSubmit] = useState(false)
  const isAnnotator = useIsAnnotator()
  const currentUser = useCurrentUser()

  const [getDealCounts, { data: dealCountsData, error: dealCountsError, loading: dealCountsLoading }] = useDealCountLazyQuery({
    context: { queryName: 'BulkExport.tsx getDealCounts' }
  })
  const [getDocumentCounts, { data: documentCountsData, error: documentCountsError, loading: documentCountsLoading }] = useDocumentCountLazyQuery({
    context: { queryName: 'BulkExport.tsx getDocumentCounts' }
  })
  const [getAttachmentCounts, { data: attachmentCountsData, error: attachmentCountsError, loading: attachmentCountsLoading }] = useAttachmentCountLazyQuery({
    context: { queryName: 'BulkExport.tsx getAttachmentCounts' }
  })
  const [dashboardExport, { error: dashboardExportError, loading: dashboardExportLoading }] = useDashboardExportLazyQuery({
    context: { queryName: 'BulkExport.tsx dashboardExport' },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (isAnnotator) {
        // Initiate download after the file is returned
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = data?.fetch_dashboard_export?.spreadsheet_export?.file_contents || ''
        a.download = data?.fetch_dashboard_export?.spreadsheet_export?.file_name || ''
        document.body.appendChild(a)
        a.click()
        setSuccess(true)
        setLoading(false)
        setLoadingMessage('')
      } else {
        setSuccess(true)
        setLoading(false)
        setLoadingMessage('')
      }
    }
  })

  const closeModal = () => {
    if (!loading) {
      setExportType(dashboard_type === 'ATTACHMENT' ? 'exportAll' : undefined)
      setIncludeDocuments(undefined)
      setItemCount(undefined)
      setSuccess(false)
      setError(false)
      setLoading(false)
      setLoadingMessage('')
      setIsOpen(false)
    }
  }

  const handleChange = (e: any) => {
    const actualValue = e?.target?.value === 'true'
    if (e?.target?.name === 'exportType') {
      setExportType(e?.target?.value)
    } else {
      setIncludeDocuments(actualValue)
    }
  }

  // # flow_type — "SHEET_EXPORT"/"FULL_EXPORT"
  const handleExport = () => {
    dashboardExport({
      variables: {
        dashboardType: dashboard_type,
        flowType: includeDocuments ? 'FULL_EXPORT' : 'SHEET_EXPORT',
        ...gqlFilters,
        ...(exportType === 'exportAll' ? {} : { chosenDataPointFieldIds })
      }
    })
  }

  useEffect(() => {
    if (isOpen) {
      if (dashboard_type === 'DEAL') {
        getDealCounts({ variables: { ...gqlFilters, size: null, offset: 0 } })
      } else if (dashboard_type === 'DOCUMENT') {
        getDocumentCounts({ variables: { ...gqlFilters, size: null, offset: 0 } })
      } else if (dashboard_type === 'ATTACHMENT') {
        getAttachmentCounts({ variables: { ...gqlFilters, size: null, offset: 0 } })
      }
    }
    // eslint-disable-next-line
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      if (dealCountsData) {
        setItemCount(dealCountsData?.fetch_advanced_deals_dashboard_count?.total_number_of_filtered_documents || undefined)
      } else if (documentCountsData) {
        setItemCount(documentCountsData?.fetch_advanced_dashboard_count?.total_number_of_filtered_documents || undefined)
      } else if (attachmentCountsData) {
        setItemCount(attachmentCountsData?.fetch_advanced_attachments_dashboard_count?.total_number_of_filtered_documents || undefined)
      }
    }
    // eslint-disable-next-line
  }, [isOpen, attachmentCountsData, dealCountsData, documentCountsData])

  useEffect(() => {
    if (isOpen) {
      if (dashboardExportLoading) {
        setLoading(true)
        setLoadingMessage(isAnnotator ? 'Exporting data…' : 'Starting export…')
      } else if (attachmentCountsLoading || documentCountsLoading || dealCountsLoading) {
        setLoading(true)
      } else if (!dashboardExportLoading) {
        setLoading(false)
      }
    }
    // eslint-disable-next-line
  }, [attachmentCountsLoading, dealCountsLoading, documentCountsLoading, dashboardExportLoading])

  useEffect(() => {
    if (isOpen) {
      if (attachmentCountsError || documentCountsError || dealCountsError || dashboardExportError) {
        setError(true)
      } else {
        setError(false)
      }
    }
    // eslint-disable-next-line
  }, [attachmentCountsError, documentCountsError, dealCountsError, dashboardExportError])

  useEffect(() => {
    if (includeDocuments === true) {
      if (itemCount && itemCount > 500) {
        setShouldBlockSubmit(true)
      } else {
        setShouldBlockSubmit(false)
      }
    } else {
      setShouldBlockSubmit(false)
    }
    // eslint-disable-next-line
  }, [isOpen, exportType, includeDocuments])

  return (
    <>
      <div style={{ marginRight: 5 }}>
        <WithTooltip content="Bulk data export">
          <Button aria-label="Bulk data export" icon={<FiDownloadCloud />} onClick={() => setIsOpen(true)} />
        </WithTooltip>
      </div>

      <Modal isOpen={isOpen} onRequestClose={closeModal} title={'Bulk Data Export'}>
        {loading && <ComponentLoadingOverlay loading={loading} message={loadingMessage} />}
        <div style={{ textAlign: 'center', minWidth: '555px' }}>
          {success ? (
            <ModalSuccess closeModal={closeModal} isAnnotator={isAnnotator} />
          ) : error ? (
            <ModalError
              attachmentCountsError={attachmentCountsError}
              closeModal={closeModal}
              dashboardExportError={dashboardExportError}
              dealCountsError={dealCountsError}
              documentCountsError={documentCountsError}
            />
          ) : (
            <>
              {dashboard_type === 'ATTACHMENT' ? (
                <p>{`This will export the selected columns into a spreadsheet.`}</p>
              ) : (
                <p>{`This will export Checklist values and Comments into a spreadsheet.`}</p>
              )}
              {isAnnotator && <p style={{ marginTop: '8px' }}>{`Spreadsheet will be downloaded immediately.`}</p>}
              <div className={css.radioRow} style={{ margin: '16px auto 0 auto' }}>
                <p>{`What would you like to export?`}</p>
                <div>
                  <RadioButton
                    handleChange={handleChange}
                    id={'exportType_true'}
                    isSelected={exportType === 'exportCurrentSelection'}
                    label={'Current Field Selection'}
                    name={'exportType'}
                    style={{ marginRight: '16px' }}
                    value={'exportCurrentSelection'}
                  />
                  <RadioButton
                    handleChange={handleChange}
                    id={'exportType_false'}
                    isSelected={exportType === 'exportAll'}
                    label={'All Fields'}
                    name={'exportType'}
                    value={'exportAll'}
                  />
                </div>
              </div>
              {!isAnnotator && (
                <div className={css.radioRow} style={{ margin: '8px auto 0 auto' }}>
                  <p>{`Also include ${dashboard_type === 'ATTACHMENT' ? 'attachment files?' : 'PDFs?'}`}</p>
                  <div>
                    <RadioButton
                      handleChange={handleChange}
                      id={'includeDocuments_true'}
                      isSelected={!!includeDocuments}
                      label={'Yes'}
                      name={'includeDocuments'}
                      style={{ marginRight: '16px' }}
                      value={true}
                    />
                    <RadioButton
                      handleChange={handleChange}
                      id={'includeDocuments_false'}
                      isSelected={includeDocuments === false}
                      label={'No'}
                      name={'includeDocuments'}
                      value={false}
                    />
                  </div>
                </div>
              )}
              {shouldBlockSubmit ? (
                <>
                  <h4 style={{ textAlign: 'left', margin: '16px 0 8px 0' }}>{`Error:`}</h4>
                  <div style={{ border: '1px solid red' }}>
                    <p style={{ marginTop: '8px' }}>{`We are currently limiting document exports to 500 at a time.`}</p>
                    <p style={{ marginTop: '8px' }}>{`Your selection includes ${itemCount} items.`}</p>
                    <p style={{ margin: '8px 0' }}>{`Please reduce your selection or do not include documents to continue.`}</p>
                  </div>
                </>
              ) : (
                !isAnnotator &&
                exportType !== undefined &&
                includeDocuments !== undefined && (
                  <>
                    <p style={{ margin: '16px 0 8px 0' }}>{`This may take up to ${
                      includeDocuments ? '1-2 hours' : '30 minutes'
                    } to process depending on the export size.`}</p>
                    <p>{`We'll email you the results at ${currentUser?.user_name}`}</p>
                  </>
                )
              )}
              <div className={css.modalButtonRow}>
                <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
                <WithTooltip
                  content={
                    shouldBlockSubmit
                      ? 'Cannot export documents for more than 4,000 items.'
                      : isAnnotator
                      ? exportType === undefined
                        ? 'Select an option'
                        : ''
                      : exportType === undefined || includeDocuments === undefined
                      ? 'Select a value for each option'
                      : ''
                  }
                >
                  <Button
                    disabled={!!(shouldBlockSubmit || exportType === undefined || (!isAnnotator && includeDocuments === undefined))}
                    onClick={handleExport}
                  >
                    {`Submit`}
                  </Button>
                </WithTooltip>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

function ModalSuccess({ closeModal, isAnnotator }: any) {
  return (
    <>
      <p>{isAnnotator ? 'Successfully exported file.' : `Successfully started export.`}</p>
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} style={{ marginRight: 0 }} variant={'secondary'}>{`Close`}</Button>
      </div>
    </>
  )
}

function ModalError({ attachmentCountsError, closeModal, dashboardExportError, dealCountsError, documentCountsError }: any) {
  return (
    <>
      {attachmentCountsError && <p>{attachmentCountsError?.message || `Error exporting data: ${attachmentCountsError}`}</p>}
      {dashboardExportError && <p>{dashboardExportError?.message || `Error exporting data: ${dashboardExportError}`}</p>}
      {dealCountsError && <p>{dealCountsError?.message || `Error loading data: ${dealCountsError}`}</p>}
      {documentCountsError && <p>{documentCountsError?.message || `Error loading data: ${documentCountsError}`}</p>}
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} style={{ marginRight: 0 }} variant={'secondary'}>{`Close`}</Button>
      </div>
    </>
  )
}
