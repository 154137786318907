import { ChevronDown } from 'react-feather'
import { formatKey } from '../../../../../../utils/stringUtils'
import { useCciLeftPanelContext } from '../../CCI_LeftPanel'
import { useCciMainContext } from '../../../../CCI_Main'
import { useCciRolesWithFeaturesLazyQuery, useCciUsersLazyQuery } from '../../../../../../graphql/codegen/hooks'
import CCI_LeftPanel_RoleCard from './CCI_LeftPanel_RoleCard'
import ChecklistSkeleton from '../../../../../../components/DataPointPanel/ChecklistSkeleton'
import Collapse, { Panel } from 'rc-collapse'
import React, { useEffect, useState } from 'react'

export default function CCI_LeftPanel_RoleList() {
  const [dataPrepared, setDataPrepared] = useState(false)
  const [usersLoaded, setUsersLoaded] = useState(false)
  const [rolesLoaded, setRolesLoaded] = useState(false)
  const [combinedData, setCombinedData] = useState<any>('')
  const { setContentData, tab } = useCciMainContext()
  const { activeKeys, searchTerm, setActiveKeys, setSearchableData } = useCciLeftPanelContext()

  const [getUsers, { data: userData, loading: userDataLoading }] = useCciUsersLazyQuery({
    onCompleted: () => {
      setUsersLoaded(true)
    }
  })

  const [getRoles, { data: roleData, loading: roleDataLoading }] = useCciRolesWithFeaturesLazyQuery({
    onCompleted: () => {
      setRolesLoaded(true)
    }
  })

  // eslint-disable-next-line
  useEffect(() => { getUsers(); getRoles(); }, [])

  useEffect(() => {
    if (userDataLoading) {
      setUsersLoaded(false)
      setCombinedData('')
    } else if (roleDataLoading) {
      setRolesLoaded(false)
      setCombinedData('')
    } else if ((usersLoaded && rolesLoaded) || (userData && roleData)) {
      generateCombinedData()
    }
    // eslint-disable-next-line
  }, [userData, roleData, userDataLoading, roleDataLoading, usersLoaded, rolesLoaded])

  // this handles resetting contentData from cached values when switching tabs
  useEffect(() => {
    if (tab === 'roles') {
      setContentData(roleData?.cci_roles || '')

      setSearchableData(roleData?.cci_roles || null)
    }
  }, [roleData?.cci_roles, setContentData, setSearchableData, tab])

  const generateCombinedData = () => {
    if (roleData?.cci_roles) {
      const dataObj = {}
      for (let i = 0; i < roleData?.cci_roles?.length; i++) {
        const roleArray: any = []
        userData?.cci_users?.forEach(user => {
          user?.roles?.edges?.forEach(role => {
            // @ts-ignore
            if (role?.node?.id === roleData?.cci_roles[i].id) {
              roleArray.push(user)
            }
          })
        })
        // @ts-ignore
        dataObj[roleData?.cci_roles[i].id] = [...roleArray]
      }
      setCombinedData({ ...dataObj })
      setDataPrepared(true)
    } else {
      setCombinedData('')
    }
  }

  return (
    <>
      {!dataPrepared || roleDataLoading || userDataLoading ? (
        <ChecklistSkeleton />
      ) : searchTerm ? (
        <Collapse
          activeKey={activeKeys}
          expandIcon={({ isActive }: { isActive: boolean }) => <ChevronDown style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }} />}
          onChange={setActiveKeys}
        >
          {roleData?.cci_roles
            ?.filter(role => role?.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .map(role => (
              <Panel
                header={
                  <div className="groupHeader">
                    <h3>{role?.name}</h3>
                  </div>
                }
                key={formatKey(role?.id)}
              >
                {/* @ts-ignore */}
                {combinedData[role?.id]?.length ? ( // @ts-ignore
                  <CCI_LeftPanel_RoleCard users={combinedData[role?.id]} />
                ) : (
                  <p>{`No users have been assigned this role.`}</p>
                )}
              </Panel>
            ))}
        </Collapse>
      ) : (
        <Collapse
          activeKey={activeKeys}
          expandIcon={({ isActive }: { isActive: boolean }) => <ChevronDown style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }} />}
          onChange={setActiveKeys}
        >
          {roleData?.cci_roles?.map(role => {
            return (
              <Panel
                header={
                  <div className="groupHeader">
                    <h3>{role?.name}</h3>
                  </div>
                }
                key={formatKey(role?.id)}
              >
                {/* @ts-ignore */}
                {combinedData[role?.id]?.length ? ( // @ts-ignore
                  <CCI_LeftPanel_RoleCard users={combinedData[role?.id]} />
                ) : (
                  <p>{`No users have been assigned this role.`}</p>
                )}
              </Panel>
            )
          })}
        </Collapse>
      )}
    </>
  )
}
