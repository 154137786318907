import { FiXSquare } from 'react-icons/fi'
import { Typography } from '@mui/material'
import Button from '../../../../../../../components/Button'
import CCI_Input from './CCI_Input'
import React, { useEffect, useState } from 'react'
import css from './style.module.scss'

export default function DefaultValueInput({
  defaultValue,
  fieldType,
  handleData,
  options,
  valueFormat
}: {
  defaultValue?: string
  fieldType?: string
  handleData: any
  options?: string[]
  valueFormat?: string
}) {
  const [value, setValue] = useState<any>(defaultValue || '')

  useEffect(() => {
    handleData('default_value', value)
    // eslint-disable-next-line
    }, [value])

  useEffect(() => {
    setValue('')
    // eslint-disable-next-line
    }, [fieldType])

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const isDropdown = fieldType === 'DROP_DOWN' || fieldType === 'BOOLEAN' || fieldType === 'MULTI_SELECT_DROP_DOWN'

  return fieldType === 'DATA_TABLE' ? null : (
    <>
      {fieldType && (
        <>
          {isDropdown && !options?.length ? (
            <></>
          ) : (
            <>
              <div className={css.inputWrapper} style={{ height: '37px' }}>
                <Typography component="label" htmlFor="default-value-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
                  Default Value
                </Typography>

                <div className={css.optionInputWrapper}>
                  <div className={css.optionInputWrapper} style={{ flexGrow: 1, height: '37px' }}>
                    <CCI_Input
                      fieldType={fieldType || ''}
                      id="default-value-input"
                      options={options}
                      placeholder={defaultValue || ''}
                      setValue={setValue}
                      value={value}
                      valueFormat={valueFormat}
                    />
                  </div>

                  {value && !isDropdown && (
                    <Button
                      className={css.cciButton}
                      icon={<FiXSquare />}
                      onClick={() => {
                        setValue(defaultValue)
                        handleData('default_value', null)
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
