import React, { CSSProperties, FC } from 'react'
import Skeleton from 'react-loading-skeleton'

// types

type _ChecklistSkeletonProps = { style?: CSSProperties }

// components

const ChecklistSkeleton: FC<_ChecklistSkeletonProps> = ({ style = {} }) => {
  return (
    <div style={{ padding: 16, ...style }}>
      <Skeleton count={4} height={24} style={{ margin: '8px 0' }} />
    </div>
  )
}

export const GroupDataPointsSkeleton = () => {
  return (
    <div style={{ background: '#fff', padding: '2px 8px 0' }}>
      <Skeleton height={24} style={{ margin: '8px 0' }} />
    </div>
  )
}

export default ChecklistSkeleton
