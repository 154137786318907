import { DataPointFieldMentions } from '../../../../../../../components/DataPointFieldMentions'
import { FiXSquare } from 'react-icons/fi'
import { Typography } from '@mui/material'
import Button from '../../../../../../../components/Button'
import React, { FC } from 'react'
import css from './style.module.scss'

// types

type _ExtractionDescriptionInputProps = {
  currentValue?: string
  handleData: (key: string, value: string | undefined) => void
  isDisabled?: boolean
}

// components

export const ExtractionDescriptionInput: FC<_ExtractionDescriptionInputProps> = ({ currentValue = '', handleData, isDisabled }) => {
  const label = 'Extraction Description'

  return (
    <div className={css.inputWrapper}>
      <Typography component="label" htmlFor="extraction-description-input" sx={{ fontWeight: 600, mr: 2, minWidth: 164 }} variant="body2">
        {label}
      </Typography>

      <DataPointFieldMentions
        id="extraction-description-input"
        isDisabled={isDisabled}
        label={label}
        onChange={(event: { target: { value: string } }) => handleData('extraction_description', event.target.value)}
        value={currentValue}
      />

      {currentValue && !isDisabled && <Button className={css.cciButton} icon={<FiXSquare />} onClick={() => handleData('extraction_description', undefined)} />}
    </div>
  )
}
