import { Box, Tooltip } from '@mui/material'
import { FEEDBACK_PAGE_PATH } from '../../pages/FeedbackPage'
import { Link, useParams } from 'react-router-dom'
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined'
import React, { FC, useMemo } from 'react'
import useQString from '../../hooks/useQString'

// types

type _FeedbackButtonProps = { dataPointFieldId: string }

// components

export const FeedbackButton: FC<_FeedbackButtonProps> = ({ dataPointFieldId }) => {
  const { documentId } = useParams<{ documentId?: string }>()
  const { documentTab } = useQString() // Only applies to Deal pages, but only after a specific item in the <DocumentSelector> is clicked.

  // On the Deal page, the first document in the <DocumentSelector> is the active document by default (prior to `documentTab` being explicitly set).
  // Given the complexity involved in passing that information to this component, we can query the DOM for the `activeDocumentId` instead.
  // TODO: Ideally, we should be relying the Context API to manage the `activeDocumentId` across all components on either a Deal or Document page.
  const activeDocumentId = useMemo(
    () => documentId || documentTab || document.querySelector('[data-active-document-id]')?.getAttribute('data-active-document-id'),
    [documentId, documentTab]
  )

  return (
    <Box sx={{ ml: 0.5, mt: 0.25 }}>
      <Tooltip arrow placement="top" title="Submit feedback">
        <Link
          aria-label="Submit feedback"
          target="_blank"
          to={`${FEEDBACK_PAGE_PATH}${activeDocumentId}?feedbackDataPointFieldId=${dataPointFieldId}&autoPublish=true`}
        >
          <MapsUgcOutlinedIcon aria-hidden />
        </Link>
      </Tooltip>
    </Box>
  )
}
