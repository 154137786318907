import { ActiveComponents, useCciMainContext } from '../../../../../CCI_Main'
import { Box, Typography } from '@mui/material'
import { CREATE_FIELD_REFETCH_QUERIES } from '../../../../Modal_components/ChecklistGptTab/CreateNewField'
import { CreateFieldExtractionMethodInstanceMutation } from '../../../../../../../graphql/codegen/operations'
import { Exact, InputMaybe } from '../../../../../../../graphql/codegen/schemas'
import { MutationFunctionOptions } from '@apollo/client'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { useCciChecklistGptContext } from '../CCI_RightPanel_ChecklistGptTab'
import {
  useCreateFieldExtractionMethodInstanceMutation,
  useEditFieldExtractionMethodInstanceMutation,
  useExtractionMethodsQuery
} from '../../../../../../../graphql/codegen/hooks'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// types

type _CreateMutationVariables = MutationFunctionOptions<
  CreateFieldExtractionMethodInstanceMutation,
  Exact<{
    dataPointFieldId: string
    extractionDescription?: InputMaybe<string>
    extractionMethodId: string
    isEnabled?: InputMaybe<boolean>
  }>
>['variables']

export type _ExtractionMethod = {
  description?: string
  extraction_method_id: string
  extraction_method_instance_id?: string
  extraction_method_name: string
  is_enabled?: boolean
}

export type _ExtractionMethodOption = { label: string; value: string | undefined }

// hooks

export const useExtractionMethodConfiguration = () => {
  const { data: extractionMethodsData } = useExtractionMethodsQuery()
  const { closeModal, expandAllCallback, selectedItem, setActiveComponent, setMessageTitle, setModalError, setSelectedItem } = useCciMainContext()

  const [selectedExtractedMethod, setSelectedExtractionMethod] = useState<_ExtractionMethod | null>(null)
  const [extractionMethodId, setExtractionMethodId] = useState<string | undefined>(selectedExtractedMethod?.extraction_method_id)

  const extractionMethodList = useMemo(
    () => extractionMethodsData?.extraction_methods?.edges.map(item => ({ label: item?.node?.display_name || '', value: item?.node?.id || '' })) || [],
    [extractionMethodsData]
  )

  const [createFieldExtractionMethodInstanceMutation, { loading: isCreateExtractionMethodLoading }] = useCreateFieldExtractionMethodInstanceMutation({
    awaitRefetchQueries: true,
    onCompleted: data => {
      closeModal({ isForced: true })
      expandAllCallback?.()
      setActiveComponent(ActiveComponents.EDIT_FIELD)

      setSelectedItem({
        ...selectedItem,
        extraction_method_instances_config: [data.create_field_extraction_method_instance?.data_point_field?.extraction_method_instances_config || []],
        prompt_fragments: data.create_field_extraction_method_instance?.data_point_field?.prompt_fragments || []
      })

      setExtractionMethodId(undefined)

      toast.success('Field successfully created', { autoClose: 5000 })
    },
    onError: error => {
      console.error(error)

      setMessageTitle('Error saving AI provider:')
      setModalError(error)
    },
    refetchQueries: CREATE_FIELD_REFETCH_QUERIES
  })

  const [editFieldExtractionMethodInstanceMutation, { loading: isEditExtractionMethodLoading }] = useEditFieldExtractionMethodInstanceMutation({
    onCompleted: () => {
      closeModal({ isForced: true })

      setExtractionMethodId(undefined)

      toast.success('AI provider saved', { autoClose: 5000 })
    },
    onError: error => {
      console.error(error)

      setMessageTitle('Error saving AI provider:')
      setModalError(error)
    }
  })

  const getVariablesForCreate = useCallback(
    (dataPointFieldId?: string) => {
      let variables: _CreateMutationVariables

      if (dataPointFieldId) {
        variables = { dataPointFieldId, extractionDescription: '', extractionMethodId: extractionMethodId || '', isEnabled: true }

        return variables
      }

      return variables
    },
    [extractionMethodId]
  )

  const getVariablesForEdit = useCallback(
    () => ({
      extractionMethodInstanceId: selectedExtractedMethod!.extraction_method_instance_id,
      isEnabled: true,
      ...(selectedItem?.id && { dataPointFieldId: selectedItem.id }),
      ...(extractionMethodId && extractionMethodId !== selectedExtractedMethod?.extraction_method_id && { extractionMethodId })
    }),
    [selectedExtractedMethod, extractionMethodId, selectedItem]
  )

  const submitExtractionMethod = useCallback(
    async (dataPointFieldId?: string) => {
      const isEditing = Boolean(selectedExtractedMethod?.extraction_method_instance_id)

      if (isEditing) {
        editFieldExtractionMethodInstanceMutation({ variables: getVariablesForEdit() })
      } else {
        createFieldExtractionMethodInstanceMutation({ variables: getVariablesForCreate(dataPointFieldId) })
      }
    },
    [
      selectedExtractedMethod,
      createFieldExtractionMethodInstanceMutation,
      editFieldExtractionMethodInstanceMutation,
      getVariablesForEdit,
      getVariablesForCreate
    ]
  )

  useEffect(() => {
    if (selectedItem) {
      const selectedExtractedMethod = JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}') as _ExtractionMethod

      setSelectedExtractionMethod(selectedExtractedMethod)

      if (selectedExtractedMethod?.extraction_method_instance_id) {
        setExtractionMethodId(selectedExtractedMethod?.extraction_method_id)
      } else {
        setExtractionMethodId(undefined)
      }
    } else {
      setSelectedExtractionMethod(null)
      setExtractionMethodId(undefined)
    }
  }, [selectedItem])

  return {
    isCreateExtractionMethodLoading,
    isEditExtractionMethodLoading,
    extractionMethodId,
    extractionMethodList,
    setExtractionMethodId,
    submitExtractionMethod
  }
}

// constants

const LABEL = 'AI Provider'

const PLACEHOLDER = 'Select an AI provider'

// components

export const ExtractionMethodInput: FC = () => {
  const { selectedItem, setIsModalOpen, setModalLoading, setModalLoadingMessage } = useCciMainContext()
  const {
    actionTypeMap,
    extractionMethodId,
    extractionMethodList,
    fieldValues,
    isCreateExtractionMethodLoading,
    isEditExtractionMethodLoading,
    isEditView,
    setExtractionMethodId,
    submitExtractionMethod
  } = useCciChecklistGptContext()
  const [selectedExtractionMethod, setSelectedExtractionMethod] = useState<_ExtractionMethodOption | null>(null)

  const actionType = useMemo(
    () => actionTypeMap[fieldValues?.action_type_id || selectedItem?.action_type?.id] || null,
    [actionTypeMap, fieldValues?.action_type_id, selectedItem]
  )

  const optionList = useMemo(() => {
    if (!actionType) return extractionMethodList

    return extractionMethodList.filter(item => actionType.extraction_methods?.edges.some((edge: any) => edge.node.id === item.value))
  }, [actionType, extractionMethodList])

  const isDisabled = isCreateExtractionMethodLoading || isEditExtractionMethodLoading

  const handleChange = useCallback(
    ({ label, value }: _ExtractionMethodOption) => {
      setSelectedExtractionMethod({ label, value })

      if (isEditView) {
        submitExtractionMethod()

        setIsModalOpen(true)
        setModalLoading(true)
        setModalLoadingMessage('Saving…')
      }
    },
    [isEditView, submitExtractionMethod, setIsModalOpen, setModalLoading, setModalLoadingMessage]
  )

  useEffect(() => {
    // Initialize the selected extraction method.
    if (!selectedExtractionMethod) {
      const initialMethod = JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}') as _ExtractionMethod

      if (!isEmpty(initialMethod)) {
        setSelectedExtractionMethod({ label: initialMethod.extraction_method_name, value: initialMethod.extraction_method_id })
      } else if (actionType) {
        const defaultMethod = optionList.find(({ value }) => value === actionType.default_extraction_method?.id)

        setSelectedExtractionMethod(defaultMethod || null)
      }
    }
  }, [actionType, extractionMethodId, optionList, selectedItem, selectedExtractionMethod])

  useEffect(() => {
    if (selectedExtractionMethod?.value) setExtractionMethodId(selectedExtractionMethod?.value)
  }, [selectedExtractionMethod, setExtractionMethodId])

  if (isEmpty(optionList)) return null

  return (
    <Box className={css.inputWrapper} sx={{ mt: 1 }}>
      <Typography component="label" htmlFor="extraction-method-type-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}
      </Typography>

      <SelectInput
        id="extraction-method-type-input"
        isDisabled={isDisabled}
        onChange={handleChange}
        options={optionList}
        placeholder={selectedExtractionMethod ? selectedExtractionMethod.label : PLACEHOLDER}
        value={selectedExtractionMethod}
      />
    </Box>
  )
}
