import { Box } from '@mui/material'
import { MoreMenu, _MenuItem } from '../MoreMenu'
import { captureError } from '../../utils/sentry'
import { useAppContext } from '../../app'
import Loader from '../Loader/Loader'
import React, { FC, useCallback, useMemo, useState } from 'react'
import axiosClient from '../../utils/axiosClient'

// types

type _DownloadChecklistButtonProps = {
  dealId: string
}

// components

export const DownloadChecklistButton: FC<_DownloadChecklistButtonProps> = ({ dealId }) => {
  const { setErrorMessage, setExtendedErrorMessage } = useAppContext()
  const [isLoading, setIsLoading] = useState(false)

  const downloadChecklist = useCallback(async () => {
    setIsLoading(true)

    try {
      const response = await axiosClient.get(`/deals/${dealId}/checklist-export`, { responseType: 'blob' })
      const contentDisposition = response.headers['content-disposition']
      const filename = contentDisposition?.match(/filename=(.+)/i)?.[1] || 'Checklist.xlsx'
      const url = window.URL.createObjectURL(response.data)
      const link = document.createElement('a')

      link.href = url
      link.download = filename

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
    } catch (error) {
      captureError(error)

      const { message, stack } = error as Error

      setErrorMessage(message)
      setExtendedErrorMessage(stack || message)
    } finally {
      setIsLoading(false)
    }
  }, [dealId, setErrorMessage, setExtendedErrorMessage])

  const menuItemList: _MenuItem[] = useMemo(() => [{ label: 'Download checklist', onClick: downloadChecklist }], [downloadChecklist])

  return (
    <Box sx={{ display: 'flex', height: 30, px: isLoading ? '15px' : 1 }}>{isLoading ? <Loader size="s" /> : <MoreMenu menuItemList={menuItemList} />}</Box>
  )
}
