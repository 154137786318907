import { useCurrentUserQuery } from '../graphql/codegen/hooks'

// Fetching current user currently requires multiple queries, so this is a wrapper around those requests
export default function useCurrentUser() {
  const { data } = useCurrentUserQuery()
  return data?.current_user
}

export const useIsKlarityEmployee = () => {
  const currentUser = useCurrentUser()
  if (currentUser?.user_name?.includes('@klaritylaw.com')) {
    return true
  } else {
    return false
  }
}
