import { Context, useContext } from 'react'

// hooks

export const useContextInit = <T extends unknown>(context: Context<T>): Exclude<T, null> => {
  const localContext = useContext(context)

  if (localContext === null) {
    throw new Error()
  }

  return localContext as Exclude<T, null>
}

export const useContextSilentFailInit = <T extends unknown>(context: Context<T>): Exclude<T, null> | Partial<Exclude<T, null>> => {
  const localContext = useContext(context)

  if (localContext === null) {
    return {} as Partial<Exclude<T, null>>
  }

  return localContext as Exclude<T, null>
}
