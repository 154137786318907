import { BsPersonFill } from 'react-icons/bs'
import { useIsGoogleTenant } from '../../hooks/useIsGoogleTenant'
import React, { CSSProperties, FC } from 'react'
import css from './style.module.scss'

// types

type _LogoProps = { externalSource?: string | null; style?: CSSProperties }
type _SourceProps = { external_source?: string | null; source?: string; style?: CSSProperties }

// components

const Logo: FC<_LogoProps> = ({ externalSource, style }) => {
  const isGoogleTenant = useIsGoogleTenant(externalSource as string)

  if (!externalSource) return null

  return (
    <span className={css.sourceWrapper} style={style}>
      <img
        alt={`${isGoogleTenant ? 'Google' : externalSource?.toLowerCase()} logo`}
        src={require(`../../../public/${isGoogleTenant ? 'google' : `s/${externalSource}`}.png`)}
      />
    </span>
  )
}

export const Source: FC<_SourceProps> = ({ external_source, source, style }) => (
  <>
    {[external_source, source].includes('MANUAL') ? (
      <BsPersonFill style={{ height: 20, margin: '-1px 0 0 8px', width: 20, ...style }} />
    ) : (
      <Logo externalSource={external_source} style={style} />
    )}
  </>
)
