import { Check } from 'react-feather'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { MoreMenu, _MenuItem } from '../MoreMenu'
import { StateEdge } from '../../graphql/codegen/schemas'
import { useModalContext } from '../../app'
import { useParams } from 'react-router-dom'
import { useSetDealAssigneeMutation, useSetDealStateMutation, useSetDocumentAssigneeMutation, useSetDocumentStateMutation } from '../../graphql/codegen/hooks'
import AssigneeModal from './FooterAssigneeModal'
import Button from '../Button'
import React, { useState } from 'react'
import SelectInput from '../SelectInput'
import css from './style.module.scss'
import useDealTransitions from '../../hooks/useDealTransitions'
import useDocumentTransitions from '../../hooks/useDocumentTransitions'
import useIsRapid7LegalTeam from '../../hooks/useIsRapid7LegalTeam'

interface Props {
  currentState?: any
  dealIsFinalized?: boolean
  handleClick: (nextStateId: string) => void
  isNonLinear?: boolean | null
  loading: boolean
  stateEdges?: any
}

function Footer({ currentState, dealIsFinalized, handleClick, isNonLinear, loading, stateEdges }: Props) {
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const [assigneeModalOpen, setAssigneeModalOpen] = useState(false)
  const hasDealAccess = useUserAccess({ feature: Features.DEAL_DASHBOARD, permission: Permissions.READ })
  const isRapid7LegalTeam = useIsRapid7LegalTeam()

  const [setDealAssignee, { loading: setDealAssigneeLoading }] = useSetDealAssigneeMutation({
    onCompleted: () => setAssigneeModalOpen(false)
  })
  const [setDocumentAssignee, { loading: setDocumentAssigneeLoading }] = useSetDocumentAssigneeMutation({
    onCompleted: () => setAssigneeModalOpen(false)
  })

  const handleSave = (assigneeId: string) => {
    if (dealId) {
      setDealAssignee({ variables: { dealId, assigneeId } })
    } else if (documentId) {
      setDocumentAssignee({ variables: { documentId, assigneeId } })
    }
  }

  if (isNonLinear) {
    const opts = stateEdges
      ?.map((e: any) => {
        if (e.node.name === 'Processing') return null
        return { label: e.node.name, value: e.node.id }
      })
      .filter(Boolean)

    const active = opts?.filter((o: any) => o.value === currentState.id)

    return (
      <div className={css.footer}>
        <div className={css.stateOptionsContainer}>
          <SelectInput
            isDisabled={loading}
            isLoading={loading}
            menuPlacement="auto"
            onChange={(o: any) => handleClick(o.value)}
            options={opts}
            value={active}
          />
        </div>
      </div>
    )
  }
  if (!stateEdges) return null

  const states = stateEdges.filter((edge: StateEdge) => edge.node?.name !== 'Processing')
  const currIdx = states.findIndex(({ node }: any) => node.id === currentState.id)
  const next = !states[currIdx]?.node?.final && states[currIdx + 1]?.node
  const availableStates: any[] = states.slice(0, currIdx).concat(states.slice(currIdx + 2))

  // Take states that users should be able to jump to, format as menu items
  const menuItemList = availableStates.reduce<_MenuItem[]>((previous, current) => {
    const { final, id, name } = current.node || {}

    if (!id || !name) return previous

    if (states[currIdx]?.node?.final && final) return previous

    return [...previous, { label: `Move to ${name}`, onClick: () => handleClick(id) }]
  }, [])

  // Add the option to change assignee as long as this customer has assignee access on that entity (for example deal-level customers can assign on deal page, document-level customers on document page)
  if ((!dealIsFinalized && hasDealAccess) || ((!hasDealAccess || isRapid7LegalTeam) && documentId)) {
    menuItemList.push({ label: 'Update assignee', onClick: () => setAssigneeModalOpen(true) })
  }

  return (
    <div className={css.footer} style={{ paddingLeft: '20px' }}>
      {next ? (
        <Button disabled={loading} loading={loading} onClick={() => handleClick(next.id)}>{`Move to ${next.name}`}</Button>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
          <Check style={{ color: '#59d129', marginRight: 8 }} />
          <span>{`Review complete.`}</span>
        </div>
      )}

      {loading || <MoreMenu menuItemList={menuItemList} />}

      <AssigneeModal
        handleSave={handleSave}
        isOpen={assigneeModalOpen}
        loading={setDealAssigneeLoading || setDocumentAssigneeLoading}
        setIsOpen={setAssigneeModalOpen}
      />
    </div>
  )
}

export function DocumentChecklistFooterContainer() {
  const { documentId } = useParams<{ documentId: string }>()
  const { currentState, data, isNonLinear, loading } = useDocumentTransitions(documentId)

  const [setDocumentState, { loading: setDocumentStateLoading }] = useSetDocumentStateMutation({
    refetchQueries: ['latestStateAudits'],
    awaitRefetchQueries: true
  })

  return (
    <Footer
      currentState={currentState}
      handleClick={stateId => setDocumentState({ variables: { documentId, stateId } })}
      isNonLinear={isNonLinear}
      loading={loading || setDocumentStateLoading}
      stateEdges={data?.states?.edges}
    />
  )
}

export function DealChecklistFooterContainer({ dealIsFinalized }: { dealIsFinalized: boolean }) {
  const { openModal, setModalError } = useModalContext()
  const { dealId } = useParams<{ dealId: string }>()
  const { currentState, data, isNonLinear, loading } = useDealTransitions(dealId)

  const [setDealState, { loading: setDealStateLoading }] = useSetDealStateMutation({
    onError: error => {
      setModalError({ title: 'Set Deal State Error:', message: error?.message })

      openModal()
    },
    refetchQueries: ['latestStateAudits'],
    awaitRefetchQueries: true
  })

  return (
    <Footer
      currentState={currentState}
      dealIsFinalized={dealIsFinalized}
      handleClick={async stateId => {
        if (await confirm('Are you sure you want to change this deal’s status?')) {
          setDealState({ variables: { dealId, stateId } })
        }
      }}
      isNonLinear={isNonLinear}
      loading={loading || setDealStateLoading}
      stateEdges={data?.states?.edges}
    />
  )
}
