import { Alert, AlertProps, Box } from '@mui/material'
import React, { ReactNode } from 'react'

type Props = {
  message: string | ReactNode
  minHeight?: number
  severity?: AlertProps['severity']
}

export const EmptyStateMessage = ({ message, minHeight = 100, severity = 'info' }: Props) => (
  <Box alignContent="center" alignItems="center" display="flex" justifyContent="center" justifyItems="center" minHeight={minHeight} textAlign="center">
    <Alert severity={severity}>{message}</Alert>
  </Box>
)
