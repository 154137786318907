import { CciLeftPanelChecklistCard } from './CCI_LeftPanel_ChecklistCard'
import { ChevronDown } from 'react-feather'
import { DraggableWrapper } from './DraggableWrapper'
import { Features, Permissions, useUserAccess } from '../../../../../../hooks/useUserAccess'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Z_INDEX_DRAG } from '../../../../../../utils/styleUtils'
import { common, grey } from '@mui/material/colors'
import { formatKey } from '../../../../../../utils/stringUtils'
import Collapse, { Panel } from 'rc-collapse'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import React, { FC, useEffect, useMemo, useState } from 'react'
import css from './style.module.scss'

//  types

type _SortablePanel = { activeKeys: any; disableCollapse?: boolean; id: string; isReorderingEnabled?: boolean; listItems: any; setActiveKeys: any }

// components

export const SortablePanel: FC<_SortablePanel> = ({ activeKeys, disableCollapse, id, isReorderingEnabled = true, listItems, setActiveKeys }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [cardIds, setCardIds] = useState<any>([])
  const isReorderingAllowed = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.REORDER })

  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    disabled: isOpen || !isReorderingAllowed || !isReorderingEnabled
  })

  const dragIndicatorSx = useMemo(
    () => ({
      color: isDragging ? common.black : grey[400],
      cursor: isDragging ? 'grabbing' : 'grab',
      fontSize: 18,
      left: 3,
      position: 'absolute',
      top: 12,
      transition: 'color 0.2s',
      visibility: 'hidden',
      ...(isOpen && { display: 'none' }),
      ':hover': { color: common.black }
    }),
    [isDragging, isOpen]
  )

  const isDragEnabled = useMemo(() => isReorderingAllowed && isReorderingEnabled, [isReorderingAllowed, isReorderingEnabled])

  const style = useMemo(
    () => ({
      cursor: isDragging ? 'grabbing' : 'pointer',
      ...(isDragging && { zIndex: Z_INDEX_DRAG }),
      ...(transform && { transform: `translate3d(${transform.x}px, ${transform.y}px, 0)` }),
      ...(transition && { transition })
    }),
    [isDragging, transform, transition]
  )

  const handleChange = (e: any) => {
    if (!disableCollapse) {
      if (e?.includes(formatKey(id))) {
        setIsOpen(true)
      } else {
        setIsOpen(false)
      }
      setActiveKeys(e)
    }
  }

  useEffect(() => {
    setCardIds(
      listItems?.map((dataPointField: any) => {
        return dataPointField?.id
      }) || []
    )
    // eslint-disable-next-line
    }, [listItems])

  if (!listItems) return null

  return (
    <div className={css.section} ref={setNodeRef} style={{ ...style, position: 'relative' }}>
      <Collapse
        activeKey={activeKeys}
        expandIcon={({ isActive }: { isActive: boolean }) => <ChevronDown style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }} />}
        onChange={handleChange}
      >
        <Panel
          header={
            <div className="groupHeader">
              <h3 style={{ ...(isDragEnabled && { marginLeft: 8 }) }}>{id}</h3>

              {isDragEnabled && (
                <DragIndicatorIcon
                  className="drag-region-icon"
                  onClick={event => event.stopPropagation()}
                  sx={dragIndicatorSx}
                  {...attributes}
                  {...listeners}
                />
              )}
            </div>
          }
          key={formatKey(id)}
        >
          <ul>
            <SortableContext id={id} items={cardIds} strategy={verticalListSortingStrategy}>
              {listItems?.map((dataPointField: any) => (
                <DraggableWrapper canReorderItems={isReorderingAllowed && isReorderingEnabled} id={dataPointField?.id} key={id + dataPointField?.id}>
                  <CciLeftPanelChecklistCard dataPointField={dataPointField} />
                </DraggableWrapper>
              ))}
            </SortableContext>
          </ul>
        </Panel>
      </Collapse>
    </div>
  )
}
