import { Box, Button, FormControl, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import { Document } from '../../graphql/codegen/schemas'
import { KLARITY_BLUE } from '../../utils/styleUtils'
import { useFormik } from 'formik'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useSubmitChatQueryMutation } from '../../app/restApi'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import Loader from '../Loader/Loader'
import React, { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react'
import useQString from '../../hooks/useQString'

// types

type ChatTabProps = {
  dealDocuments?: (Document & { isChatAvailable: boolean })[]
}

type Message = {
  text: string
  timestamp: number
  type: MessageType
}

enum MessageType {
  QUERY,
  RESPONSE
}

// components

export const ChatTab = ({ dealDocuments }: ChatTabProps) => {
  const { documentId: documentPageDocumentId } = useParams<{ documentId: string }>()
  const { documentTab: dealPageDocumentTab } = useQString()
  const { url } = useRouteMatch()
  const history = useHistory()

  const activeDocumentId = useMemo(
    () => documentPageDocumentId || (dealPageDocumentTab as string) || (dealDocuments?.[0]?.id as string) || '',
    [dealDocuments, dealPageDocumentTab, documentPageDocumentId]
  )

  const isChatAvailableForActiveDealDocument = useMemo(
    () => dealDocuments?.find(({ id }) => id === activeDocumentId)?.isChatAvailable,
    [activeDocumentId, dealDocuments]
  )

  const isDocumentSelectVisible = useMemo(() => dealDocuments && dealDocuments.length > 1, [dealDocuments])

  const [inputValue, setInputValue] = useState('')
  const [messages, setMessages] = useState<Message[]>([])

  const inputRef = useRef<HTMLInputElement>(null)
  const messagesRef = useRef<HTMLDivElement>(null)

  const [submitChatQuery, { data: chatQueryResponse, isLoading }] = useSubmitChatQueryMutation()

  const formik = useFormik({
    initialValues: { query: '' },

    onSubmit: ({ query }) => {
      if (!activeDocumentId || !query) return

      resetForm()

      handleNewMessage(query, MessageType.QUERY)

      submitChatQuery({ documentId: activeDocumentId, query })
    }
  })

  const { getFieldProps, handleSubmit, resetForm } = formik

  // functions

  const handleNewMessage = (text: string, type: MessageType) => {
    const newMessage = { text, timestamp: Date.now(), type }
    const newMessages = [...messages, newMessage]

    // TODO: Remove this when we are able to persist messages on the backend
    const storedMessages = JSON.parse(localStorage.getItem('messages') || '{}')
    storedMessages[activeDocumentId] = newMessages
    localStorage.setItem('messages', JSON.stringify(storedMessages))

    setMessages(newMessages)
  }

  const handleTextInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // Allow users to insert newlines in the chat input when pressing `Shift+Enter`, but submit the query when only pressing `Enter`
    if (event.key === 'Enter' && !event.shiftKey) handleSubmit()
  }

  const renderSelectValue = (value: string) => {
    const document = dealDocuments?.find(({ id }) => id === value)

    return (
      <Typography
        sx={{ fontSize: '13px', opacity: document?.isChatAvailable ? 1 : 0.38, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        variant="body2"
      >
        {document?.document_type?.name ? `${document.document_type.name} • ` : ''} {document?.name}
      </Typography>
    )
  }

  // effects

  useEffect(() => {
    setMessages(JSON.parse(localStorage.getItem('messages') || '{}')?.[activeDocumentId] || [])
  }, [activeDocumentId])

  useEffect(() => {
    if (!chatQueryResponse) return

    handleNewMessage(chatQueryResponse, MessageType.RESPONSE)
  }, [chatQueryResponse]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Scroll to the bottom of the chat panel and refocus on the input when a new message is added
    if (messagesRef.current) messagesRef.current.scrollTop = messagesRef.current.scrollHeight

    inputRef.current?.focus()
  }, [isLoading, messages])

  // render

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
      {isDocumentSelectVisible && (
        <Box sx={{ alignItems: 'center', borderBottom: '1px solid #f3f4f8', display: 'flex', justifyContent: 'space-between', p: 2 }}>
          <Select
            inputProps={{ 'aria-label': 'Select a document' }}
            onChange={({ target: { value } }) => history.replace(`${url}?documentTab=${value}`)}
            renderValue={renderSelectValue}
            size="small"
            sx={{ fontSize: '13px', width: 'calc(100% - 35px)' }}
            value={activeDocumentId}
          >
            {dealDocuments?.map(({ document_type, id, isChatAvailable, name }) => {
              const menuItem = (
                <MenuItem disabled={!isChatAvailable} key={id} sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }} value={id}>
                  {document_type?.name ? `${document_type.name} • ` : ''} {name}
                </MenuItem>
              )

              // If a menu item is wrapped in a tooltip, then the menu item will not be selectable. Any menu item that is available for chat must not be wrapped in a tooltip.
              return isChatAvailable ? (
                menuItem
              ) : (
                <Tooltip arrow key={id} placement="right" title="Chat is not currently available for this document">
                  <div>{menuItem}</div>
                </Tooltip>
              )
            })}
          </Select>

          <Tooltip arrow placement="top" title="Chat conversations are organized by document">
            <InfoOutlined fontSize="small" sx={{ opacity: 0.6 }} />
          </Tooltip>
        </Box>
      )}

      <Box ref={messagesRef} sx={{ flex: 1, overflow: 'auto', paddingY: 0 }}>
        {isChatAvailableForActiveDealDocument || documentPageDocumentId ? (
          [...messages].map((message, index) => (
            <Box key={index} sx={{ borderTop: '1px solid #f3f4f8', boxShadow: '0 2px 0 #f3f4f8', p: 2 }}>
              <Typography sx={{ fontWeight: 900, mb: 1 }} variant="subtitle2">
                {message.type === MessageType.QUERY ? 'You' : 'Klarity'}
              </Typography>

              <Typography sx={{ overflowWrap: 'break-word', whiteSpace: 'break-spaces' }} variant="body2">
                {message.text}
              </Typography>
            </Box>
          ))
        ) : (
          <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', p: 2 }}>
            <Typography sx={{ mb: 1 }} variant="body2">
              Chat is not currently available for this document.
            </Typography>

            <Typography variant="body2">Please select another document.</Typography>
          </Box>
        )}

        {isLoading && (
          <Box sx={{ p: 2, width: 'fit-content' }}>
            <Loader size="xs" />
          </Box>
        )}
      </Box>

      <Box sx={{ borderTop: '2px solid #f3f4f8', p: 2, position: 'sticky' }}>
        <FormControl autoComplete="off" component="form" fullWidth onSubmit={handleSubmit}>
          <TextField
            InputProps={{ autoFocus: true, id: 'query', inputRef, sx: { fontSize: 13, mb: 2, '.MuiInputBase-input': { pl: 0 } }, ...getFieldProps('query') }}
            disabled={(dealDocuments && !isChatAvailableForActiveDealDocument) || isLoading}
            fullWidth
            inputProps={{ 'aria-label': 'Enter a message' }}
            maxRows={8}
            multiline
            onChange={({ currentTarget: { value } }) => setInputValue(value)}
            onKeyDown={handleTextInputKeyDown}
            size="small"
            type="search"
            value={inputValue}
          />

          <Button
            color="primary"
            disableElevation
            disabled={(dealDocuments && !isChatAvailableForActiveDealDocument) || isLoading}
            sx={{ background: KLARITY_BLUE, color: '#fff', '&:hover': { background: '#004fdb', color: '#fff' } }}
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </FormControl>
      </Box>
    </Box>
  )
}
