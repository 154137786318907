import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useDropzone } from 'react-dropzone'
import Loader from '../Loader'
import React, { FC, ReactNode, useMemo } from 'react'

// types

type _DropzoneProps = {
  accept?: string
  idleContent?: ReactNode
  idleMessage?: string
  isError?: boolean
  isLoading?: boolean
  maxFiles?: number
  multiple?: boolean
  onDrop: (x: any) => void
}

// constants

const baseStyle = {
  alignItems: 'center',
  backgroundColor: grey[50],
  borderColor: grey[200],
  borderRadius: 2,
  borderStyle: 'dashed',
  borderWidth: 2,
  color: grey[700],
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  padding: '20px',
  transition: 'border .24s ease-in-out'
}

const activeStyle = { borderColor: '#2196f3' }

const errorStyle = { borderColor: 'red' }

const rejectStyle = { color: 'red', fontSize: '13px', marginTop: 8 }

// components

const Dropzone: FC<_DropzoneProps> = ({ accept, idleContent, idleMessage, isError, isLoading, maxFiles = 100, multiple = true, onDrop }) => {
  const { fileRejections, getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept || 'image/*,.pdf,.doc,.dot,.dotx,.dotm,.docx,.txt,.rtf,.xps,.odt,.xls,.xlsx,.csv',
    maxSize: 104_857_600,
    maxFiles,
    multiple
  })

  const message = idleMessage || 'Drag and drop files here, or click to select files'

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isError ? errorStyle : {})
    }),
    [isDragActive, isError]
  )

  const rejectList = (
    <ul style={rejectStyle}>
      {fileRejections.map(({ errors, file }: any) => {
        console.error(errors)
        const e = errors[0]

        let errorMessage
        switch (e.code) {
          case 'file-invalid-type':
            errorMessage = 'File type not supported'
            break
          case 'file-too-large':
            errorMessage = 'File exceeds size limit (100MB)'
            break
          case 'too-many-files':
            if (multiple === false) {
              errorMessage = 'Only one file upload is allowed at a time'
            } else {
              errorMessage = `Too many files (Limit ${maxFiles})`
            }
            break
          default:
            errorMessage = 'Error'
            break
        }
        return (
          <li key={file.path}>
            <b>{errorMessage}:</b> {file.path}
          </li>
        )
      })}
    </ul>
  )

  return (
    <>
      {/* @ts-ignore */}
      <div {...getRootProps({ style })}>
        <input aria-label={message} {...(getInputProps() as any)} />

        {isDragActive ? <Box>Drop here…</Box> : isLoading ? <Loader /> : <Box>{idleContent || message}</Box>}
      </div>

      {!!fileRejections.length && rejectList}
    </>
  )
}

export default Dropzone
