import { Dispatch } from 'react'
import { captureError } from '../utils/sentry'
import { formatFieldList } from '../utils/fieldUtils'
import { isEmpty } from 'lodash'

// types

export type Column = { [k: string]: string; label: string; value: string }
type State = { selectedColumns: Column[] }
interface AddColumnAction {
  selectedColumn: Column
  type: 'ADD_COLUMN'
}
interface RemoveColumnAction {
  selectedColumn: Column
  type: 'REMOVE_COLUMN'
}
interface ReorderColumns {
  newColumnOrderById: string[]
  type: 'REORDER_COLUMNS'
}
interface ReorderTemporaryColumns {
  newColumnOrderById: string[]
  type: 'REORDER_TEMPORARY_COLUMNS'
}
interface SaveSelectedColumnsAction {
  selectedColumns: Column[] | null
  type: 'SAVE_SELECTED_COLUMNS'
}
interface SaveTemporaryColumnsAction {
  selectedColumns: Column[] | null
  type: 'SAVE_TEMPORARY_COLUMNS'
}

export type DocumentQueryAction =
  | AddColumnAction
  | RemoveColumnAction
  | ReorderColumns
  | ReorderTemporaryColumns
  | SaveSelectedColumnsAction
  | SaveTemporaryColumnsAction

// constants

export const initialState = { selectedColumns: [] }

// functions

export default function dashboardQueryReducer(prevState: State, action: DocumentQueryAction): State {
  switch (action.type) {
    case 'ADD_COLUMN': {
      const selectedColumns = [...prevState.selectedColumns, action.selectedColumn]
      const duplicatesRemoved = Array.from(new Set(selectedColumns))
      saveSelectedColumns(duplicatesRemoved)
      return { selectedColumns: duplicatesRemoved }
    }
    case 'REMOVE_COLUMN': {
      const selectedColumns = prevState.selectedColumns.filter(c => c.value !== action.selectedColumn.value)
      const duplicatesRemoved = Array.from(new Set(selectedColumns))
      saveSelectedColumns(duplicatesRemoved)
      return { selectedColumns: duplicatesRemoved }
    }
    case 'REORDER_COLUMNS': {
      const localColumns = prevState?.selectedColumns?.length > 0 ? [...prevState.selectedColumns] : undefined
      const sorted = localColumns?.sort((a, b) => action?.newColumnOrderById?.indexOf(a?.value) - action?.newColumnOrderById?.indexOf(b?.value))
      const duplicatesRemoved = Array.from(new Set(sorted))
      saveSelectedColumns(duplicatesRemoved)
      return { selectedColumns: duplicatesRemoved }
    }
    case 'REORDER_TEMPORARY_COLUMNS': {
      const localColumns = prevState?.selectedColumns?.length > 0 ? [...prevState.selectedColumns] : undefined
      const sorted = localColumns?.sort((a, b) => action?.newColumnOrderById?.indexOf(a?.value) - action?.newColumnOrderById?.indexOf(b?.value))
      const duplicatesRemoved = Array.from(new Set(sorted))
      return { selectedColumns: duplicatesRemoved }
    }
    case 'SAVE_SELECTED_COLUMNS': {
      const duplicatesRemoved = Array.from(new Set(action.selectedColumns))
      saveSelectedColumns(duplicatesRemoved)
      return { selectedColumns: duplicatesRemoved }
    }
    case 'SAVE_TEMPORARY_COLUMNS': {
      const duplicatesRemoved = Array.from(new Set(action.selectedColumns))
      return { selectedColumns: duplicatesRemoved }
    }
    default:
      return prevState
  }
}

export function saveSelectedColumns(columns: Column[] | null): void {
  const selectedColumnsKey = `${localStorage.getItem('customerId')}__SELECTED_COLUMNS`

  if (!isEmpty(columns)) {
    localStorage.setItem(selectedColumnsKey + window.location.pathname, JSON.stringify(columns))
  } else {
    localStorage.removeItem(selectedColumnsKey + window.location.pathname)
  }
}

export function getSavedColumns(): Column[] | null {
  const selectedColumnsKey = `${localStorage.getItem('customerId')}__SELECTED_COLUMNS`

  const columnString = localStorage.getItem(selectedColumnsKey + window.location.pathname)
  try {
    return columnString ? JSON.parse(columnString) : null
  } catch (error) {
    captureError(error, 'getSavedColumns error')

    return null
  }
}

export function resetToDefaultColumns(data: any, fieldsToExclude: string[] | null, dispatch: Dispatch<DocumentQueryAction>) {
  if (data) {
    const fields = formatFieldList(data, fieldsToExclude)
    const defaultDatapointColumns = Object.values(fields).filter(dp => {
      return dp.default_table_column
    })
    dispatch({ type: 'SAVE_SELECTED_COLUMNS', selectedColumns: defaultDatapointColumns })
  } else {
    return null
  }
}

export function parseTemporaryColumnsFromQuery(data: any, formattedQueryColumns: any, fieldsToExclude: string[]) {
  if (data && formattedQueryColumns) {
    const tempColumnIds = formattedQueryColumns?.map((column: any) => (typeof column === 'string' ? column : column?.value))
    const fields = formatFieldList(data, fieldsToExclude)

    if (!tempColumnIds) {
      return Object.values(fields).filter(dp => {
        if (tempColumnIds) {
          return tempColumnIds.includes(dp.value) || formattedQueryColumns?.includes(dp.value)
        } else {
          return dp.default_table_column || formattedQueryColumns?.includes(dp.value)
        }
      })
    } else {
      // this block properly orders the columns to be returned- returning Object.values directly will return whatever matched were found first in the fields,
      // which would not match the column order of the saved query
      const fullColumns = Object.values(fields).filter(dp => {
        return tempColumnIds.includes(dp.value) || formattedQueryColumns?.includes(dp.value)
      })
      const properlyOrderedColumns = []
      for (let i = 0; i < tempColumnIds?.length; i++) {
        for (let c = 0; c < fullColumns?.length; c++) {
          if (fullColumns[c].value === tempColumnIds[i]) {
            properlyOrderedColumns.push(fullColumns[c])
            break
          }
        }
      }
      return properlyOrderedColumns
    }
  } else {
    return null
  }
}

// compare temp and saved columns and append any saved columns not included in the query to the temp columns
export function showSavedColumns(dispatch: Dispatch<DocumentQueryAction>, savedColumns?: Column[], selectedColumns?: Column[]) {
  if (!savedColumns?.length) {
    return null
  }
  const selectColumnIds = selectedColumns?.map(column => {
    return column.value
  })
  const savedColumnIds = savedColumns?.map(column => {
    return column.value
  })
  const combinedColumns = selectedColumns ? [...selectedColumns] : []
  for (let i = 0; i < savedColumnIds?.length; i++) {
    if (!selectColumnIds?.includes(savedColumnIds[i])) {
      combinedColumns.push(savedColumns[i])
    }
  }
  dispatch({ type: 'SAVE_SELECTED_COLUMNS', selectedColumns: combinedColumns })
}

// set temp columns to the columns from the current query
export function resetToQueryColumns(dispatch: Dispatch<DocumentQueryAction>, queryColumns?: Column[]) {
  dispatch({ type: 'SAVE_SELECTED_COLUMNS', selectedColumns: queryColumns || [] })
}
