import * as queryString from 'query-string'
import { DashboardQueriesDocument, useDashboardQueriesQuery, useDeleteDashboardQueryMutation } from '../../../graphql/codegen/hooks'
import { FiEyeOff, FiTrash2 } from 'react-icons/fi'
import { User } from '../../../graphql/codegen/schemas'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '../../Button'
import Loader from '../../Loader'
import React, { FC, MouseEvent, useEffect, useState } from 'react'
import SelectInput from '../../SelectInput'
import WithTooltip from '../../WithTooltip'
import css from './style.module.scss'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useIsRapid7LegalTeam from '../../../hooks/useIsRapid7LegalTeam'

// types

type _QueryOptionItem = { created_by: User; id: string; is_shared: boolean; label: string; value: string }

type _SavedQuerySelectorProps = { isDealsDashboard?: boolean }

// components

const SavedQuerySelector: FC<_SavedQuerySelectorProps> = ({ isDealsDashboard }) => {
  const history = useHistory()
  const location = useLocation()
  const [options, setOptions] = useState<any>(undefined)
  const [deletedId, setDeletedId] = useState<any>(undefined)
  const isAttachmentsDashboard = location?.pathname?.includes('/dashboard/attachments') || false
  const currentUser = useCurrentUser()
  const isRapid7LegalTeam = useIsRapid7LegalTeam()

  const { data: savedQueryData, loading: savedQueryDataLoading } = useDashboardQueriesQuery({
    variables: { dashboard: isAttachmentsDashboard ? 'ATTACHMENT' : isDealsDashboard ? 'DEAL' : 'DOCUMENT' }
  })

  const [deleteSavedQuery, { loading: deleteQueryLoading }] = useDeleteDashboardQueryMutation({
    refetchQueries: [
      {
        query: DashboardQueriesDocument,
        variables: { dashboard: isAttachmentsDashboard ? 'ATTACHMENT' : isDealsDashboard ? 'DEAL' : 'DOCUMENT' }
      }
    ],
    awaitRefetchQueries: true
  })

  const handleSelect = ({ value }: { value: string }) => {
    const getCurrentDashboardTab = () => {
      if (isRapid7LegalTeam) {
        return location?.pathname === '/' ? 'Documents' : 'Deals'
      } else {
        return location?.pathname === '/' || location?.pathname === '/dashboard/deals' ? 'Deals' : 'Documents'
      }
    }
    const getPathFix = () => {
      if (isAttachmentsDashboard) {
        return null
      }
      const currentDashboardTab = getCurrentDashboardTab()
      if (currentDashboardTab === 'Deals' && !isDealsDashboard) {
        return isRapid7LegalTeam ? '/dashboard/deals' : '/'
      } else if (isDealsDashboard && currentDashboardTab === 'Documents') {
        return isRapid7LegalTeam ? '/' : '/dashboard/documents'
      } else {
        return null
      }
    }
    // This is to prevent a R7 specific bug of loading a saved query in the wrong dashboard tab and then crashing. This is possible because R7's legal team has different URL rotes for their tabs.
    const pathFix = getPathFix()
    // these two lines hard refresh the page to refire the search, likely not necessary now after refactoring to allow pressing the search button again to refire the query using location key change
    if (queryString.stringify({ q: value }, { arrayFormat: 'comma' }) === location?.search?.substring(1)) {
      window.location.reload()
    } else if (value === location?.search?.substring(1)) {
      window.location.reload()
    }
    // this handles new logic where the saved string value has already been query-stringified
    else if (value?.slice(0, 5) === 'cols=') {
      history.push(pathFix ? { pathname: pathFix, search: value } : { search: value })
    } else {
      history.push(
        pathFix
          ? { pathname: pathFix, search: queryString.stringify({ q: value }, { arrayFormat: 'comma' }) }
          : { search: queryString.stringify({ q: value }, { arrayFormat: 'comma' }) }
      )
    }
  }

  const handleDelete = (event: MouseEvent, dashboard_query_id: string, isCreatedByCurrentUser: boolean) => {
    event.stopPropagation()

    const isConfirmed = confirm(`Are you sure you want to ${isCreatedByCurrentUser ? 'delete' : 'hide'} this query? This action cannot be undone.`)

    if (!isConfirmed) return

    setDeletedId(dashboard_query_id)

    deleteSavedQuery({
      variables: { dashboard_query_id },
      update(cache) {
        // this removes the item from the cache after deleting completes
        const normalizedId = cache.identify({ id: dashboard_query_id, __typename: 'DashboardQuery' })
        cache.evict({ id: normalizedId })
        cache.gc()
      }
    })
  }

  const formatOptionLabel = ({ created_by, id, is_shared, label }: _QueryOptionItem) => {
    const isCreatedByCurrentUser = created_by?.id === currentUser?.id

    return (
      <>
        {(deleteQueryLoading || savedQueryDataLoading) && id === deletedId ? (
          <Loader size={'m'} style={{ padding: '10px 0' }} />
        ) : (
          <div className={css.customOptionLabel}>
            <div>
              <p>{label}</p>

              <WithTooltip content={isCreatedByCurrentUser ? 'Delete Query' : 'Hide Query'}>
                <Button
                  aria-label={isCreatedByCurrentUser ? 'Delete Query' : 'Hide Query'}
                  icon={isCreatedByCurrentUser ? <FiTrash2 /> : <FiEyeOff />}
                  onClick={event => handleDelete(event, id, isCreatedByCurrentUser)}
                />
              </WithTooltip>
            </div>

            {is_shared ? (
              isCreatedByCurrentUser ? (
                <div>Shared with others</div>
              ) : (
                <div className={css.textRow}>
                  <p>
                    Shared by: ${created_by?.first_name} ${created_by?.last_name}
                  </p>
                </div>
              )
            ) : null}
          </div>
        )}
      </>
    )
  }

  useEffect(() => {
    const userId = currentUser?.id
    const options =
      savedQueryData?.dashboard_queries?.edges?.map(queryEdge => {
        const { name, query_string, ...rest } = queryEdge?.node || {}
        return { ...rest, label: name, value: query_string }
      }) || undefined

    const sortedOptions = options
      ?.sort((a: any, b: any) => (a?.label > b?.label ? 1 : -1))
      ?.sort((a: any, b: any) => a?.is_shared - b?.is_shared) // @ts-ignore
      ?.sort((a: any, b: any) => (a?.created_by?.id === userId) - (b?.created_by?.id === userId))

    setOptions(sortedOptions ? [...sortedOptions] : undefined)
    // eslint-disable-next-line
  }, [savedQueryData])

  return options?.length ? (
    <span style={{ marginRight: 12, width: 200 }}>
      <SelectInput
        formatOptionLabel={formatOptionLabel}
        onChange={handleSelect}
        options={options}
        placeholder="Select Saved Query"
        styles={{ placeholder: { color: '#000', whiteSpace: 'nowrap' } }}
      />
    </span>
  ) : null
}

export default SavedQuerySelector
