import { useCallback, useState } from 'react'

// types

type _UseRefCallbackReturn<T> = {
  element: T | null
  refCallback: (node: T | null) => void
}

// hooks

export const useRefCallback = <T extends HTMLElement>(): _UseRefCallbackReturn<T> => {
  const [element, setElement] = useState<T | null>(null)

  const refCallback = useCallback((node: T | null) => {
    setElement(node)
  }, [])

  return { element, refCallback }
}
