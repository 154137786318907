import * as pdfjs from '../../../../pdfjs-dist-local'
import { Annotation } from '../../graphql/codegen/schemas'
import { DocumentProps } from '../DocumentPanel/DocumentPanel'
import { PDFHighlighterWrapper } from './PDFHighlighterWrapper'
import { _IsConfidenceMutationBusyMap } from '../Confidence'
import { captureError } from '../../utils/sentry'
import { getDocument } from '../../utils/documentApiUtils'
import { klarityAnnotationToHighlighterFormat } from './utils'
import { useDocumentsQuery } from '../../graphql/codegen/hooks'
import DocumentPlaceholder from '../DocumentPlaceholder'
import PdfjsWorker from '../../../../pdfjs-dist-local/build/pdf.worker.entry'
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import type { _TableCell } from '../DocumentPanel/TablesQueryButton/TablesQueryButton'

// @ts-ignore
pdfjs.GlobalWorkerOptions.workerSrc = PdfjsWorker

export default function PDFHighlighterDoc({
  dealIsFinalized,
  documentFormat,
  documentId,
  isConfidenceMutationBusyMap,
  isDataTableSelectedList,
  onDocumentReady,
  onPageChange,
  scale,
  setIsConfidenceMutationBusyMap,
  setIsDataTableSelectedList,
  showAllTags,
  showHighlights,
  tables
}: DocumentProps & {
  dealIsFinalized: boolean
  isConfidenceMutationBusyMap: _IsConfidenceMutationBusyMap
  isDataTableSelectedList: boolean[][]
  onDocumentReady: (pdfDocument: any) => void
  onPageChange: (pageNumber: number) => void
  scale: number | 'auto'
  setIsConfidenceMutationBusyMap: Dispatch<SetStateAction<_IsConfidenceMutationBusyMap>>
  setIsDataTableSelectedList: Dispatch<SetStateAction<boolean[][]>>
  showAllTags: boolean
  showHighlights: boolean
  tables: _TableCell[][][][]
}) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [pdfDocumentProxy, setPdfDocumentProxy] = useState<any>(null)

  useEffect(() => {
    const setDocument = async () => {
      if (documentId) {
        try {
          // Convert the document from base64 encoding to a bytestring for pdfjs (use cMapUrl and cMapPacked to handle East Asian characters)
          const base64EncodedDocument = await getDocument(documentId, documentFormat)
          const data = atob(base64EncodedDocument.split('base64,')[1])
          const doc = await pdfjs.getDocument({ data, cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`, cMapPacked: true }).promise
          setPdfDocumentProxy(doc)
        } catch (error) {
          captureError(error)
        }
      }
    }

    setPdfDocumentProxy(null)
    setDocument()
  }, [documentId, documentFormat])

  const { data: documentData } = useDocumentsQuery({ skip: !documentId, variables: { documentId } })

  const highlights = documentData?.documents?.edges?.[0]?.node?.annotations?.edges?.map(edge => klarityAnnotationToHighlighterFormat(edge?.node as Annotation))

  const novelSegments =
    documentData?.documents?.edges?.[0]?.node?.novel_para_annotations.map(novelSegment => klarityAnnotationToHighlighterFormat(novelSegment as Annotation)) ||
    []

  return (
    <div ref={wrapperRef} style={{ height: '100%', overflow: 'hidden', maxWidth: '100%' }}>
      {(!pdfDocumentProxy || !highlights) && (
        <div>
          <DocumentPlaceholder />
        </div>
      )}

      {pdfDocumentProxy && highlights && (
        <PDFHighlighterWrapper
          dealIsFinalized={dealIsFinalized}
          documentId={documentId}
          highlights={showHighlights ? highlights : []}
          isConfidenceMutationBusyMap={isConfidenceMutationBusyMap}
          isDataTableSelectedList={isDataTableSelectedList}
          novelSegments={showHighlights ? novelSegments : []}
          onDocumentReady={onDocumentReady}
          onPageChange={onPageChange}
          pdfDocumentProxy={pdfDocumentProxy}
          scale={scale}
          setIsConfidenceMutationBusyMap={setIsConfidenceMutationBusyMap}
          setIsDataTableSelectedList={setIsDataTableSelectedList}
          showAllTags={showAllTags}
          tables={showHighlights ? tables : []}
        />
      )}
    </div>
  )
}
