import * as Sentry from '@sentry/browser'
import { ApolloError } from '@apollo/client'
import { CurrentRuntimeEnvironment } from '../utils/envUtils'
import { ReplayFrameEvent } from '@sentry/replay'
import { _UserClaims } from './oktaClient'

// types

type _RecordingEvent = ReplayFrameEvent & { data: { payload: { category?: string; message?: string } } }

// enums

export enum SentryTag {
  OKTA_IDP = 'okta.idp'
}

// constants

const EXCEPTION_VALUES_TO_IGNORE = [
  'Non-Error promise rejection captured with value:',
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded'
]

// functions

export const captureError = (error: unknown, message?: string) => {
  // Apollo errors are already logged by the Apollo client.
  if (!(error instanceof ApolloError)) {
    if (message) {
      console.error(message, error)
    } else {
      console.error(error)
    }
  }

  Sentry.captureException(error)
}

const filterEvent = (event: Sentry.Event) => {
  if (event.exception?.values?.some((exception: Sentry.Exception) => exception.value && EXCEPTION_VALUES_TO_IGNORE.includes(exception.value))) {
    return null
  }

  return event
}

export const initializeSentry = () => {
  Sentry.init({
    beforeBreadcrumb: redactBreadcrumb,
    beforeSend: filterEvent,
    dsn: CurrentRuntimeEnvironment.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.Replay({ beforeAddRecordingEvent: redactRecordingEvent })],
    replaysOnErrorSampleRate: 1.0
  })

  const { idToken } = JSON.parse(localStorage.getItem('okta-token-storage') || '{}')

  const { idp } = (idToken?.claims as _UserClaims) || {}

  setSentryTag(SentryTag.OKTA_IDP, idp)
}

const redactBreadcrumb = (breadcrumb: Sentry.Breadcrumb) => {
  if (breadcrumb.category?.startsWith('ui') && breadcrumb.message) {
    breadcrumb.message = removeAttributesFromSelector(breadcrumb.message)
  }

  return breadcrumb
}

const redactEmail = (email: string): string => {
  const [localPart, domain] = email.split('@')
  const [secondLevelDomain, topLevelDomain] = domain.split('.')

  const redact = (part: string) => part.substring(0, Math.floor(part.length / 2)) + '*'.repeat(Math.ceil(part.length / 2))

  return `${redact(localPart)}@${redact(secondLevelDomain)}*${'*'.repeat(topLevelDomain.length)}`
}

const redactRecordingEvent = (event: _RecordingEvent) => {
  if (event.data.payload.category?.startsWith('ui') && event.data.payload.message) {
    event.data.payload.message = removeAttributesFromSelector(event.data.payload.message)
  }

  return event
}

const removeAttributesFromSelector = (selector: string) => selector.replace(/(\[[\w-]+="[^"]*"\])/g, '') // Example: div[title="John Doe"] => div

export const setSentryTag = (key: string, value: string) => Sentry.setTag(key, value)

export const setSentryUsername = (username: string) => Sentry.setUser({ username: redactEmail(username) })
