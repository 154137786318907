import { useDealsAffectedByDocumentDeleteLazyQuery, useDeleteDocumentMutation } from '../../../graphql/codegen/hooks'
import { useHistory } from 'react-router-dom'
import Button from '../../Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../TypeToConfirm'
import WithTooltip from '../../WithTooltip'
import css from './style.module.scss'

export default function DeleteDocument({
  closeModal,
  deal_id,
  document_id,
  documentName,
  setLoadingMessage,
  setModalLoading
}: {
  closeModal: any
  deal_id?: string
  documentName?: string
  document_id?: string
  setLoadingMessage?: any
  setModalLoading?: any
}) {
  const history = useHistory()
  const [mutationSuccess, setMutationSuccess] = useState<boolean>(false)
  const [confirmed, setConfirmed] = useState(false)

  const [getAffectedCounts, { data: dealsAffectedData, error: dealsAffectedError, loading: dealsAffectedLoading }] = useDealsAffectedByDocumentDeleteLazyQuery()
  const [deleteDocument, { error: deleteDocumentError, loading: deleteDocumentLoading }] = useDeleteDocumentMutation({
    onCompleted: () => {
      setLoadingMessage('')
      setMutationSuccess(true)
    },
    update(cache) {
      const normalizedId = cache.identify({ id: document_id, __typename: 'AdvancedDashboardDocumentMeta' })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })

  const handleSubmit = () => {
    if (document_id) {
      deleteDocument({ variables: { document_id, deal_id } })
    }
  }

  const handleClose = () => {
    if (deal_id) {
      history.push(`/deals/${deal_id}`)
      closeModal()
    } else {
      closeModal()
    }
  }

  useEffect(() => {
    if (dealsAffectedLoading) {
      setModalLoading(true)
      setLoadingMessage('')
    } else if (deleteDocumentLoading) {
      setModalLoading(true)
      setLoadingMessage('Deleting Document…')
    } else {
      setModalLoading(false)
      if (setModalLoading) {
        setModalLoading(false)
      }
    }
    // eslint-disable-next-line
  }, [dealsAffectedLoading, deleteDocumentLoading])

  useEffect(() => {
    if (document_id) {
      getAffectedCounts({ variables: { document_id } })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <h5 style={{ textAlign: 'center' }}>{documentName}</h5>
      {mutationSuccess ? (
        <>
          <h5 style={{ textAlign: 'center' }}>{`Successfully deleted the document.`}</h5>
          <h5 style={{ textAlign: 'center' }}>{`It may still appear on deals for a few minutes while they are being updated.`}</h5>
          <div className={css.modalButtonRow}>
            <Button onClick={handleClose} style={{ maxWidth: '50%', marginLeft: 'auto', marginRight: 'auto' }} variant={'secondary'}>{`Close`}</Button>
          </div>
        </>
      ) : (
        !dealsAffectedError &&
        !deleteDocumentError && (
          <AffectedCountsAndConfirm
            confirmed={confirmed}
            countsData={dealsAffectedData?.deals_affected_by_document_delete}
            handleClose={closeModal}
            handleSubmit={handleSubmit}
            setConfirmed={setConfirmed}
          />
        )
      )}
      {dealsAffectedError && <p>{`Error loading data: ${dealsAffectedError?.message}`}</p>}
      {deleteDocumentError && <p>{`Error deleting document: ${deleteDocumentError?.message}`}</p>}
    </>
  )
}

function AffectedCountsAndConfirm({
  confirmed,
  countsData,
  handleClose,
  handleSubmit,
  setConfirmed
}: {
  confirmed?: boolean
  countsData?: any
  handleClose?: any
  handleSubmit?: any
  setConfirmed?: any
}) {
  return (
    <>
      <p style={{ marginLeft: '8px', textAlign: 'center', fontWeight: 501 }}>{`Completed deals will be unaffected.`}</p>
      {!countsData || countsData?.length === 0 ? (
        <p style={{ fontWeight: 501 }}>{`No deals will be affected by this change.`}</p>
      ) : countsData?.length > 10 ? (
        <p style={{ fontWeight: 501 }}>{`${countsData?.length} active deals will be updated.`}</p>
      ) : (
        <>
          <p style={{ fontWeight: 501 }}>{`These active deals will be updated:`}</p>
          {countsData?.map((item: any, index: number) => {
            return (
              <p key={item?.name + index} style={{ fontSize: '14px', textAlign: 'left' }}>
                {item?.name}
              </p>
            )
          })}
        </>
      )}
      <p style={{ marginTop: '16px', textAlign: 'center' }}>{`Are you sure you want to delete this document?`}</p>
      <TypeToConfirm setConfirmed={setConfirmed} />
      <div className={css.modalButtonRow}>
        <Button onClick={handleClose} variant={'secondary'}>{`Cancel`}</Button>
        <WithTooltip content={confirmed ? '' : 'Type Confirm to proceed'}>
          <Button disabled={!confirmed} onClick={handleSubmit}>{`Submit`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
