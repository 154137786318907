import { Annotation, Document } from '../../graphql/codegen/schemas'
import { Box, Button, CircularProgress, IconButton, Tooltip, Typography, alpha } from '@mui/material'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { _HLTW, getBoundingRect } from '../../utils/pdfHighlighterUtils'
import { common, green, orange } from '@mui/material/colors'
import { inRange, isEmpty, size } from 'lodash'
import { useContextInit } from '../../hooks/useContextInit'
import { useDocumentsQuery } from '../../graphql/codegen/hooks'
import { useHistory } from 'react-router-dom'
import { useOpening } from '@hoologic/use-opening'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import FlagIcon from '@mui/icons-material/Flag'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React, { Dispatch, FC, SetStateAction, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'

// types

type _ConfidenceDocumentContext = {
  activeDocument: Document
  id: string
  novelSegments: Annotation[]
  search: string
  totalNovelSegmentCount: number
  unreviewedNovelSegmentCount: number
}

export type _ConfidenceGlobalContext = {
  isConfidenceVisibleMap: _IsConfidenceVisibleMap
  isReviewingNovelSegmentsMap: _IsReviewingNovelSegmentsMap
  novelSegmentIndexMap: _NovelSegmentIndexMap
  setIsConfidenceVisibleMap: Dispatch<SetStateAction<_IsConfidenceVisibleMap>>
  setIsReviewingNovelSegmentsMap: Dispatch<SetStateAction<_IsReviewingNovelSegmentsMap>>
  setNovelSegmentIndexMap: Dispatch<SetStateAction<_NovelSegmentIndexMap>>
}

type _ConfidenceProps = { activeDocument: Document }
type _ConfidenceStatusProps = { documentId: string }
export type _IsConfidenceMutationBusyMap = { [key: string]: boolean }
export type _IsConfidenceVisibleMap = { [key: string]: boolean }
export type _IsReviewingNovelSegmentsMap = { [key: string]: boolean }
export type _NovelSegmentIndexMap = { [key: string]: number }

type _NovelSegmentProps = {
  isConfidenceMutationBusyMap: _IsConfidenceMutationBusyMap
  novelSegment: { id: string; position: { boundingRect: _HLTW; pageNumber: number; rects: _HLTW[] }; review_state?: string | null }
  toggleReviewState: (novelSegment: any) => void
}

type _Point = { x: number; y: number }
type _Rect = { height: number; left: number; top: number; width: number }

export enum ReviewStates {
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  REVIEWED = 'REVIEWED'
}

// constants

const DISABLED_TOOLTIP = 'The only unreviewed novel segment is already visible.'

// context

const ConfidenceDocumentContext = createContext<_ConfidenceDocumentContext | null>(null)
export const ConfidenceGlobalContext = createContext<_ConfidenceGlobalContext | null>(null)

// functions

const isPointWithinCircleBoundedByRect = (point: _Point, rect: _Rect) => {
  const centerX = rect.left + rect.width / 2
  const centerY = rect.top + rect.height / 2
  const radius = Math.min(rect.width, rect.height) / 2
  const dx = point.x - centerX
  const dy = point.y - centerY

  return dx * dx + dy * dy <= radius * radius
}

// hooks

const useConfidenceDocumentContext = () => useContextInit(ConfidenceDocumentContext)
export const useConfidenceGlobalContext = () => useContextInit(ConfidenceGlobalContext)

// components

const Banner: FC = () => {
  const { activeDocument, novelSegments, search, totalNovelSegmentCount, unreviewedNovelSegmentCount } = useConfidenceDocumentContext()
  const { isReviewingNovelSegmentsMap, setIsConfidenceVisibleMap, setIsReviewingNovelSegmentsMap, setNovelSegmentIndexMap } = useConfidenceGlobalContext()

  const history = useHistory()

  const forCustomerName = useMemo(() => (localStorage.getItem('customerName') ? ` for ${localStorage.getItem('customerName')}` : ''), [])
  const iconSx = useMemo(() => ({ borderRadius: '50%', color: common.white, p: 0.375 }), [])
  const innerBoxSx = useMemo(() => ({ alignItems: 'center', display: 'flex', gap: 1.5, m: 2 }), [])
  const outerBoxSx = useMemo(() => ({ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }), [])

  const reviewNovelSegments = useCallback(() => {
    let novelSegmentIndex = novelSegments.findIndex(novelSegment => novelSegment.review_state !== ReviewStates.REVIEWED)
    novelSegmentIndex = novelSegmentIndex === -1 ? 0 : novelSegmentIndex

    history.replace({ hash: `#highlight-${novelSegments[novelSegmentIndex].id}`, search })

    setIsReviewingNovelSegmentsMap(current => ({ ...current, [activeDocument.id]: true }))
    setNovelSegmentIndexMap(current => ({ ...current, [activeDocument.id]: novelSegmentIndex }))
  }, [activeDocument.id, history, novelSegments, search, setIsReviewingNovelSegmentsMap, setNovelSegmentIndexMap])

  useEffect(() => {
    if (!unreviewedNovelSegmentCount) {
      setIsReviewingNovelSegmentsMap(current => ({ ...current, [activeDocument.id]: true }))
      setNovelSegmentIndexMap(current => ({ ...current, [activeDocument.id]: 0 }))
    }
  }, [activeDocument.id, setIsReviewingNovelSegmentsMap, setNovelSegmentIndexMap, unreviewedNovelSegmentCount])

  if (unreviewedNovelSegmentCount)
    return (
      <Box sx={{ ...outerBoxSx, bgcolor: orange[50] }}>
        <Box sx={innerBoxSx}>
          <FlagIcon sx={{ ...iconSx, bgcolor: orange[700] }} />

          <Typography variant="body2">
            <>
              <>Review and annotate the </>
            </>

            <Box
              component="span"
              sx={{ bgcolor: orange[700], borderRadius: 1, color: common.white, display: 'inline-block', fontWeight: 700, mx: 0.2, px: 0.75 }}
            >
              {unreviewedNovelSegmentCount}
            </Box>

            <> of {totalNovelSegmentCount} novel </>

            {`segment${totalNovelSegmentCount === 1 ? '' : 's'}`}

            <> identified in this document. This will help Klarity AI learn over </>

            <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
              <>time.</>

              <Tooltip
                arrow
                title={`Novel segments are language that Klarity has not encountered in any previous document${forCustomerName}. As such, they have a higher likelihood of being non-standard. Please review them, add any annotations that may be missing and mark as “reviewed”. Klarity AI will learn from your feedback and reflect that on future documents! Note: all AI model updates pass through a rigorous Klarity change management process and will take one week to reflect.`}
              >
                <InfoOutlinedIcon fontSize="small" sx={{ opacity: 0.5, ml: 0.75, my: 0, p: 0.125, position: 'absolute' }} />
              </Tooltip>
            </Box>
          </Typography>
        </Box>

        {!isReviewingNovelSegmentsMap[activeDocument.id] && (
          <Button color="orange" onClick={reviewNovelSegments} size="small" sx={{ flex: 'none', mr: 1.5 }} variant="contained">
            {`${unreviewedNovelSegmentCount < totalNovelSegmentCount ? 'Continue ' : ''}Review`}
          </Button>
        )}
      </Box>
    )

  return (
    <Box sx={{ ...outerBoxSx, bgcolor: green[50] }}>
      <Box sx={innerBoxSx}>
        <CheckIcon sx={{ ...iconSx, bgcolor: green[700] }} />

        <Typography variant="body2">All novel segments in this document have been reviewed. Klarity is now processing your feedback. Stay tuned!</Typography>
      </Box>

      <IconButton onClick={() => setIsConfidenceVisibleMap(current => ({ ...current, [activeDocument.id]: false }))} size="small" sx={{ mr: 2 }}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

export const Confidence: FC<_ConfidenceProps> = ({ activeDocument }) => {
  const { isConfidenceVisibleMap } = useConfidenceGlobalContext()
  const history = useHistory()
  const [id, setId] = useState(history.location.hash.slice('#highlight-'.length))
  const [search, setSearch] = useState(history.location.search)
  const isConfidenceEnabled = useUserAccess({ feature: Features.NOVELTY, permission: Permissions.READ })

  const novelSegments = useMemo(
    () =>
      [...activeDocument.novel_para_annotations].sort(
        (a, b) =>
          (a.positions?.edges[0]?.node?.page_number as number) - (b.positions?.edges[0]?.node?.page_number as number) ||
          (a.positions?.edges[0]?.node?.bounding_rect?.y1 as number) - (b.positions?.edges[0]?.node?.bounding_rect?.y1 as number)
      ),
    [activeDocument.novel_para_annotations]
  )

  const totalNovelSegmentCount = useMemo(() => size(activeDocument.novel_para_annotations), [activeDocument.novel_para_annotations])

  const unreviewedNovelSegmentCount = useMemo(
    () => size(activeDocument.novel_para_annotations.filter(novelParaAnnotation => novelParaAnnotation.review_state !== ReviewStates.REVIEWED)),
    [activeDocument.novel_para_annotations]
  )

  const confidenceDocumentContext = useMemo(
    () => ({ activeDocument, id, novelSegments, search, totalNovelSegmentCount, unreviewedNovelSegmentCount }),
    [activeDocument, id, novelSegments, search, totalNovelSegmentCount, unreviewedNovelSegmentCount]
  )

  useEffect(() => history.listen(location => setId(location.hash.slice('#highlight-'.length))), [history])
  useEffect(() => history.listen(location => setSearch(location.search)), [history])

  useEffect(() => {
    // if the initial URL contains a `#highlight` that references a novel segment, clear the hash (i.e. start fresh)

    if (novelSegments.some(novelSegment => novelSegment.id === id)) {
      history.replace({ hash: '', search })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!document || !isConfidenceEnabled || !isConfidenceVisibleMap[activeDocument.id]) return null

  return (
    <ConfidenceDocumentContext.Provider value={confidenceDocumentContext}>
      <>
        <Banner />

        <Navigator />
      </>
    </ConfidenceDocumentContext.Provider>
  )
}

export const ConfidenceStatus: FC<_ConfidenceStatusProps> = ({ documentId }) => {
  const { data: documentsData } = useDocumentsQuery({ skip: !documentId, variables: { documentId } })

  const activeDocument = useMemo(() => documentsData?.documents?.edges[0]?.node as Document | undefined, [documentsData?.documents?.edges])
  const areNovelSegmentsPresent = useMemo(() => !isEmpty(activeDocument?.novel_para_annotations), [activeDocument?.novel_para_annotations])
  const iconSx = useMemo(() => ({ color: common.white, borderRadius: '50%', ml: 1, p: 0.25, transform: 'translateY(2px)' }), [])

  const remainingNovelSegmentCount = useMemo(
    () => size(activeDocument?.novel_para_annotations.filter(novelParaAnnotation => novelParaAnnotation?.review_state !== ReviewStates.REVIEWED)),
    [activeDocument?.novel_para_annotations]
  )

  if (!areNovelSegmentsPresent || !remainingNovelSegmentCount) return null

  return (
    <>
      <FlagIcon sx={{ bgcolor: orange[700], ...iconSx }} />

      <Box component="span" sx={{ color: orange[700], fontSize: 13, fontWeight: 700, ml: 0.5 }}>
        {remainingNovelSegmentCount}
      </Box>
    </>
  )
}

const Navigator: FC = () => {
  const { activeDocument, id, novelSegments, search, totalNovelSegmentCount, unreviewedNovelSegmentCount } = useConfidenceDocumentContext()
  const { novelSegmentIndexMap, setNovelSegmentIndexMap } = useConfidenceGlobalContext()
  const history = useHistory()
  const opening = useOpening()

  const isDisabled = useMemo(
    () =>
      Boolean(
        novelSegments.find(novelSegment => novelSegment.id === id && novelSegment.review_state !== ReviewStates.REVIEWED) && unreviewedNovelSegmentCount === 1
      ),
    [id, novelSegments, unreviewedNovelSegmentCount]
  )

  const handleNextArrow = useCallback(() => {
    if (novelSegmentIndexMap[activeDocument.id] === undefined) return // to satisfy TS; should never happen

    if (isDisabled) {
      opening.open()

      return
    }

    if (!id) {
      // case 1: no novel segment ID in URL; replace hash with the ID of the current novel segment

      history.replace({ hash: `#highlight-${novelSegments[novelSegmentIndexMap[activeDocument.id]].id}`, search })

      return
    }

    if (unreviewedNovelSegmentCount) {
      // case 2: there are unreviewed novel segments; navigate to the next unreviewed novel segment

      let nextUnreviewedNovelSegmentIndex = novelSegments.findIndex(
        (novelSegment, index) => novelSegment.review_state !== ReviewStates.REVIEWED && index > novelSegmentIndexMap[activeDocument.id]!
      )

      if (nextUnreviewedNovelSegmentIndex !== -1) {
        setNovelSegmentIndexMap(current => ({ ...current, [activeDocument.id]: nextUnreviewedNovelSegmentIndex }))
      } else {
        nextUnreviewedNovelSegmentIndex = novelSegments.findIndex(novelSegment => novelSegment.review_state !== ReviewStates.REVIEWED)

        if (nextUnreviewedNovelSegmentIndex !== -1) {
          setNovelSegmentIndexMap(current => ({ ...current, [activeDocument.id]: nextUnreviewedNovelSegmentIndex }))
        }
      }

      return
    }

    // case 3: there are no unreviewed novel segments; navigate to the next novel segment

    setNovelSegmentIndexMap(current => ({
      ...current,
      [activeDocument.id]: current[activeDocument.id] === totalNovelSegmentCount - 1 ? 0 : current[activeDocument.id] + 1
    }))
  }, [
    activeDocument.id,
    history,
    id,
    isDisabled,
    novelSegmentIndexMap,
    novelSegments,
    opening,
    search,
    setNovelSegmentIndexMap,
    totalNovelSegmentCount,
    unreviewedNovelSegmentCount
  ])

  const handlePreviousArrow = useCallback(() => {
    if (novelSegmentIndexMap[activeDocument.id] === undefined) return // to satisfy TS; should never happen

    if (isDisabled) {
      opening.open()

      return
    }

    if (!id) {
      // case 1: no novel segment ID in URL; replace hash with the ID of the current novel segment

      history.replace({ hash: `#highlight-${novelSegments[novelSegmentIndexMap[activeDocument.id]].id}`, search })

      return
    }

    if (unreviewedNovelSegmentCount) {
      // case 2: there are unreviewed novel segments; navigate to the previous unreviewed novel segment

      let previousUnreviewedNovelSegmentIndex = novelSegments
        .slice(0, novelSegmentIndexMap[activeDocument.id])
        .reverse()
        .findIndex(novelSegment => novelSegment.review_state !== ReviewStates.REVIEWED)

      if (previousUnreviewedNovelSegmentIndex !== -1) {
        setNovelSegmentIndexMap(current => ({
          ...current,
          [activeDocument.id]: novelSegmentIndexMap[activeDocument.id] - previousUnreviewedNovelSegmentIndex - 1
        }))
      } else {
        previousUnreviewedNovelSegmentIndex = [...novelSegments].reverse().findIndex(novelSegment => novelSegment.review_state !== ReviewStates.REVIEWED)

        if (previousUnreviewedNovelSegmentIndex !== -1) {
          setNovelSegmentIndexMap(current => ({ ...current, [activeDocument.id]: totalNovelSegmentCount - previousUnreviewedNovelSegmentIndex - 1 }))
        }
      }

      return
    }

    // case 3: there are no unreviewed novel segments; navigate to the previous novel segment

    setNovelSegmentIndexMap(current => ({
      ...current,
      [activeDocument.id]: current[activeDocument.id] === 0 ? totalNovelSegmentCount - 1 : current[activeDocument.id] - 1
    }))
  }, [
    activeDocument.id,
    history,
    id,
    isDisabled,
    novelSegmentIndexMap,
    novelSegments,
    opening,
    search,
    setNovelSegmentIndexMap,
    totalNovelSegmentCount,
    unreviewedNovelSegmentCount
  ])

  useEffect(() => {
    if (novelSegmentIndexMap[activeDocument.id] !== undefined) {
      history.replace({ hash: `#highlight-${novelSegments[novelSegmentIndexMap[activeDocument.id]].id}`, search })
    }
  }, [activeDocument.id, history, novelSegmentIndexMap, novelSegments, search])

  if (novelSegmentIndexMap[activeDocument.id] === undefined) return null

  return (
    <Box sx={{ bgcolor: unreviewedNovelSegmentCount ? orange[50] : green[50], mt: 24.375, p: 0.5, position: 'absolute', zIndex: 9999 }}>
      <Tooltip arrow onClose={opening.close} open={opening.isOpen} title={DISABLED_TOOLTIP}>
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <IconButton onClick={handlePreviousArrow}>
            <ArrowUpwardIcon fontSize="small" />
          </IconButton>

          <IconButton onClick={handleNextArrow}>
            <ArrowDownwardIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  )
}

export const NovelSegment = ({ isConfidenceMutationBusyMap, novelSegment, toggleReviewState }: _NovelSegmentProps) => {
  const novelSegmentRef = useRef<HTMLDivElement | null>(null)
  const [documentNode, setDocumentNode] = useState<HTMLDivElement>()
  const [pdfHighlighterNode, setPdfHighlighterNode] = useState<HTMLDivElement>()
  const [isHoveringOverIcon, setIsHoveringOverIcon] = useState(false)
  const [isHoveringOverNovelSegment, setIsHoveringOverNovelSegment] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  const boundingRect = useMemo(() => getBoundingRect(novelSegment.position.rects, 5), [novelSegment.position.rects])
  const iconCheckSx = useMemo(() => ({ ...(isHoveringOverIcon && { cursor: 'pointer' }), p: 0.375, pointerEvents: 'auto', zIndex: 3 }), [isHoveringOverIcon])
  const iconCommonSx = useMemo(() => ({ borderRadius: '50%', left: -36, position: 'relative', top: -2 }), [])
  const isReviewed = useMemo(() => novelSegment.review_state === ReviewStates.REVIEWED, [novelSegment.review_state])

  const icon = useMemo(() => {
    if (isConfidenceMutationBusyMap[novelSegment.id])
      return <CircularProgress size={24} sx={{ bgcolor: isReviewed ? orange[50] : green[50], color: isReviewed ? orange[700] : green[700], ...iconCommonSx }} />

    if (isHoveringOverIcon || isReviewed)
      return (
        <Tooltip arrow placement="top" title={`Mark as ${isReviewed ? 'needs review' : 'reviewed'}`}>
          <CheckIcon sx={{ bgcolor: green[700], color: common.white, ...iconCheckSx, ...iconCommonSx }} />
        </Tooltip>
      )

    if (isHoveringOverNovelSegment)
      return <CheckIcon sx={{ bgcolor: common.white, border: `1px solid ${green[700]}`, color: green[700], ...iconCheckSx, ...iconCommonSx }} />

    return <FlagIcon sx={{ bgcolor: orange[700], color: common.white, p: 0.375, ...iconCommonSx }} />
  }, [iconCheckSx, iconCommonSx, isConfidenceMutationBusyMap, isHoveringOverIcon, isHoveringOverNovelSegment, isReviewed, novelSegment.id])

  useEffect(() => setDocumentNode(novelSegmentRef.current?.parentNode?.parentNode as HTMLDivElement), [])
  useEffect(() => setPdfHighlighterNode(document.querySelector('.PdfHighlighter') as HTMLDivElement), [])

  useEffect(() => {
    if (!documentNode || !novelSegmentRef.current || !pdfHighlighterNode) return

    const { bottom, left, right, top } = novelSegmentRef.current.getBoundingClientRect()

    const handleClick = (event: MouseEvent) => {
      event.stopPropagation()

      if (isHoveringOverIcon) {
        toggleReviewState(novelSegment)
      }
    }

    const handleMouseMove = ({ clientX, clientY }: MouseEvent) => {
      setIsHoveringOverNovelSegment(
        (inRange(clientX, left, right) && inRange(clientY, top, bottom)) || (inRange(clientX, left - 22, left) && inRange(clientY, top, top + 24))
      )

      setIsHoveringOverIcon(isPointWithinCircleBoundedByRect({ x: clientX, y: clientY }, { height: 24, left: left - 34, top, width: 24 }))
    }

    const handleScroll = () => setScrollPosition(pdfHighlighterNode.scrollTop)

    documentNode.addEventListener('click', handleClick)
    documentNode.addEventListener('mousemove', handleMouseMove)
    pdfHighlighterNode.addEventListener('scroll', handleScroll)

    return () => {
      documentNode.removeEventListener('click', handleClick)
      documentNode.removeEventListener('mousemove', handleMouseMove)
      pdfHighlighterNode.removeEventListener('scroll', handleScroll)
    }
  }, [documentNode, isHoveringOverIcon, novelSegment, pdfHighlighterNode, scrollPosition, toggleReviewState])

  return (
    <Box
      ref={novelSegmentRef}
      sx={{
        bgcolor: isReviewed ? alpha(green[200], 0.1) : alpha(orange[200], 0.1),
        border: '2px dotted',
        borderColor: isReviewed ? green[700] : orange[700],
        pointerEvents: 'none',
        position: 'absolute',
        transition: 'background-color 150ms ease-in-out, border-color 150ms ease-in-out',
        ...boundingRect
      }}
    >
      {icon}
    </Box>
  )
}
