import { CurrentRuntimeEnvironment } from '../../utils/envUtils'
import { GraphQLClient } from 'graphql-request'
import { createApi } from '@reduxjs/toolkit/query/react'
import { getIdToken } from '../../utils/sessionApiUtils'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

const client = new GraphQLClient(`${CurrentRuntimeEnvironment.REACT_APP_API_ROOT}/graphql`)

export const baseApi = createApi({
  baseQuery: graphqlRequestBaseQuery({
    // @ts-ignore
    client,
    prepareHeaders: async headers => {
      const idToken = await getIdToken()

      if (idToken) {
        headers.set('Authorization', `Bearer ${btoa(`${idToken}__||__${localStorage.getItem('customerId')}`)}`)
      }

      return headers
    }
  }),

  endpoints: () => ({})
})
