import { EditDataPointAccountingImpactMutation } from '../../graphql/codegen/operations'
import { FetchResult, MutationResult } from '@apollo/client'
import { IconButton, Tooltip } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import React, { FC, useCallback } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'
import type { DataPoint } from '../../graphql/codegen/schemas'

// types

type _AccountingImpactButtonProps = {
  dataPoint: DataPoint
  dealIsFinalized?: boolean
  mutation: (options?: any) => Promise<FetchResult>
  mutationState: MutationResult<EditDataPointAccountingImpactMutation>
  tabIndex?: number
}

// components

export const AccountingImpactButton: FC<_AccountingImpactButtonProps> = ({ dataPoint, dealIsFinalized, mutation, mutationState, tabIndex = 0 }) => {
  const { has_accounting_impact, id } = dataPoint
  const { loading: isLoading } = mutationState

  const tooltipTitle = dealIsFinalized
    ? `This field ${has_accounting_impact ? 'has' : 'does not have'} accounting impact.`
    : 'Click once to indicate presence of accounting impact (red). Click again to indicate no accounting impact (green).'

  const handleSave = useCallback(() => {
    let nextState

    if (has_accounting_impact === null) {
      nextState = true
    } else if (has_accounting_impact === true) {
      nextState = false
    }

    mutation({ variables: { dataPointId: id, accountingImpact: nextState } })
  }, [has_accounting_impact, id, mutation])

  return (
    <>
      {(dealIsFinalized ? has_accounting_impact || has_accounting_impact === false : true) && (
        <Tooltip arrow placement="top" title={tooltipTitle}>
          <IconButton
            aria-label={tooltipTitle}
            onClick={dealIsFinalized || isLoading ? undefined : handleSave}
            sx={{ borderRadius: 0.5, height: 20, width: 20, '&:hover': { background: 'none' } }}
            tabIndex={tabIndex}
          >
            <AttachMoneyIcon
              className={clsx(css.accountingImpactIcon, { [css.noImpact]: has_accounting_impact === false, [css.withImpact]: has_accounting_impact === true })}
              style={dealIsFinalized ? { cursor: 'default' } : isLoading ? { cursor: 'default', opacity: 0.5 } : {}}
            />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}
