import { Features, Permissions, useIsAnnotator, useUserAccess } from '../../../hooks/useUserAccess'
import { Lifecycle } from '../../../components/Lifecycle'
import { useHistory, useParams } from 'react-router-dom'
import Clock from '../../../components/Clock'
import PageHeader from '../../../components/PageHeader'
import React from 'react'
import css from './style.module.scss'
import useIsRapid7LegalTeam from '../../../hooks/useIsRapid7LegalTeam'
import useQString from '../../../hooks/useQString'

function DocumentPageHeader({
  alias,
  counter_party,
  created_at,
  internal_assignee,
  internal_state,
  name,
  sourceInfo,
  state
}: {
  alias?: string
  counter_party: any
  created_at: any
  internal_assignee: any
  internal_state: any
  name?: string
  sourceInfo?: any
  state: any
}) {
  const history = useHistory()
  const { documentId } = useParams<{ documentId: string }>()
  const { from } = useQString()
  const hasDealAccess = useUserAccess({ feature: Features.DEAL_DASHBOARD, permission: Permissions.READ })
  const isAnnotator = useIsAnnotator()
  const fromInbox = from === 'inbox' && isAnnotator
  const isRapid7LegalTeam = useIsRapid7LegalTeam()

  return (
    <div className={css.documentPageHeader}>
      <PageHeader
        alias={alias}
        breadcrumbSource={fromInbox ? 'Inbox' : 'Documents'}
        contentRight={
          isAnnotator ? (
            <div className={css.annotatorDiv}>
              <div>
                {internal_assignee?.user_name && (
                  <span style={{ marginLeft: '8px' }}>
                    {`Assignee: `}
                    <b>{internal_assignee.user_name}</b>
                  </span>
                )}
                <span style={{ marginLeft: 8 }}>
                  {`Level: `}
                  <b>{internal_state?.name || 'Unknown'}</b>
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginLeft: '8px' }}>
                  <Clock />
                </span>
              </div>
            </div>
          ) : (
            (!hasDealAccess || isRapid7LegalTeam) && <Lifecycle created_at={created_at} documentId={documentId} state={state} />
          )
        }
        counterpartyId={counter_party?.id}
        hasDealAccess={hasDealAccess}
        name={name}
        onBack={() => {
          history.push({ pathname: (hasDealAccess || isAnnotator) && !fromInbox && !isRapid7LegalTeam ? '/dashboard/documents' : '/', search: '' })
        }}
        sourceInfo={sourceInfo}
        subTitle={counter_party?.name}
      />
    </div>
  )
}

export default DocumentPageHeader
