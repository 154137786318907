import { Alert, Snackbar } from '@mui/material'
import { Z_INDEX_MODAL } from '../utils/styleUtils'
import { createPortal } from 'react-dom'
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react'

// types

type _ToastProps = { message: string; setMessage: Dispatch<SetStateAction<string>> }

// components

export const Toast: FC<_ToastProps> = ({ message, setMessage }) => {
  const handleClose = useCallback(() => {
    setMessage('')
  }, [setMessage])

  if (!message) return null

  return createPortal(
    <Snackbar autoHideDuration={4000} onClose={handleClose} open={Boolean(message)} sx={{ zIndex: Z_INDEX_MODAL }}>
      <Alert severity="success">{message}</Alert>
    </Snackbar>,
    document.body
  )
}
