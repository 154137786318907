import * as queryString from 'query-string'
import { AiOutlineFileSearch } from 'react-icons/ai'
import { Box, IconButton } from '@mui/material'
import { useAppContext } from '../../app'
import { useHistory, useLocation } from 'react-router-dom'
import React, { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react'
import SaveProgressIcon from './SaveProgressIcon'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'

// types

type _JumpToPageIconProps = { documentId?: string; pages: number[] }

// components

export const JumpToPageIcon: FC<_JumpToPageIconProps> = ({ documentId, pages = [] }) => {
  const { setErrorMessage } = useAppContext()
  const history = useHistory()
  const location = useLocation()
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)

  // page-related

  const [page, setPage] = useState<string | string[] | null>('')

  useMemo(() => setPage(queryString.parse(location.search).page), [location.search])

  // nextPageIndex-related

  const [nextPageIndex, setNextPageIndex] = useState(0)

  useMemo(() => {
    const pageIndex = pages.findIndex(item => item === Number(page))

    setNextPageIndex(pageIndex === -1 || pageIndex === pages.length - 1 ? 0 : pageIndex + 1)
  }, [page, pages])

  // other

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation()

      const documentTab = location.pathname.includes('deals') ? documentId : ''
      const search = queryString.stringify({ ...(documentTab ? { documentTab } : {}), page: pages[nextPageIndex] })
      history.push({ search })

      setErrorMessage('')
      setShowLoadingSpinner(true)
      setTimeout(() => setShowLoadingSpinner(false), 1000)
    },
    [documentId, history, location.pathname, nextPageIndex, pages, setErrorMessage]
  )

  const tooltipContent = useMemo(() => `Jump to page ${pages[nextPageIndex]}`, [nextPageIndex, pages])

  return (
    <>
      {showLoadingSpinner ? (
        <SaveProgressIcon error={false} loading={showLoadingSpinner} success={false} />
      ) : (
        <WithTooltip content={tooltipContent} style={{ display: 'flex' }}>
          <IconButton
            aria-label={tooltipContent}
            className={css.scrollIcon}
            onClick={handleClick}
            sx={{ borderRadius: 0.5, color: 'primary.main', height: 16, ml: 0.5, p: 0, width: 16 }}
          >
            <AiOutlineFileSearch />

            <Box className={css.badge}>{pages.length > 99 ? '99+' : pages.length}</Box>
          </IconButton>
        </WithTooltip>
      )}
    </>
  )
}
