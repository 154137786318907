import { Alert, Box, Button, Typography } from '@mui/material'
import { ButtonLoadingSmall } from '../ButtonLoading'
import { DataPoint, DataPointEdge } from '../../graphql/codegen/schemas'
import { DataPointDetailDocument, useDataPointDetailQuery, useResolveCollisionMutation } from '../../graphql/codegen/hooks'
import { Maybe } from 'graphql/jsutils/Maybe'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { Opening } from '@hoologic/use-opening'
import { areEqualSets, formatResolutionMessage } from './collisionUtils'
import { captureError } from '../../utils/sentry'
import { grey } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import ChecklistSkeleton from '../DataPointPanel/ChecklistSkeleton'
import InputRow from '../DatapointField/InputRow'
import React, { FC, SyntheticEvent, useEffect, useState } from 'react'
import css from './style.module.scss'
import usePromptOnUnmount from '../../hooks/usePromptOnUnmount'

// types

type _DataPointCollisionFormControlProps = { opening: Opening }
type _DataPointCollisionFormLoaderProps = { dataPoint: DataPoint; dealIsFinalized: boolean; opening: Opening }
type _DataPointCollisionFormProps = { buttonsAreVisible?: boolean; dataPoint: DataPoint; dealIsFinalized: boolean; isSummary?: boolean; opening?: Opening }

// components

export const DataPointCollisionForm: FC<_DataPointCollisionFormProps> = ({ buttonsAreVisible, dataPoint, dealIsFinalized, isSummary, opening }) => {
  const collisionType = dataPoint.data_point_field?.collision_type
  const defaultResolvedIds = new Set(dataPoint?.resolved_data_points?.edges.map(dp => String(dp?.node?.id)) || [])
  const resolutionMessage = formatResolutionMessage(dataPoint)
  const { url } = useRouteMatch()
  const [errorMessage, setErrorMessage] = useState('')
  const [selected, setSelected] = useState(defaultResolvedIds)

  /**
   * Form validation depends on dataPoint.data_point_field.collision_type
   *  CASCADE & EQUIVALENCE: only one value is allowed
   *  UNION: any combination
   */
  function handleChange(e: SyntheticEvent<HTMLInputElement>) {
    const { checked, value } = e.currentTarget

    if (collisionType !== 'UNION') {
      setSelected(new Set([value]))
    }

    setSelected(prevSelected => {
      // Copy the set so we don't mutate the old one
      // @ts-ignore
      const toMutate = new Set([...prevSelected])
      if (checked) {
        return toMutate.add(value)
      }
      toMutate.delete(value)
      return toMutate
    })

    setErrorMessage('')
  }

  const [resolveCollisionMutation, { error, loading: isLoading }] = useResolveCollisionMutation({
    refetchQueries: [{ query: DataPointDetailDocument, variables: { id: dataPoint.id } }]
  })

  async function handleSubmit() {
    try {
      // @ts-ignore
      await resolveCollisionMutation({ variables: { dataPointId: dataPoint.id, resolvedDataPointIds: [...selected] } })

      opening?.close()
    } catch (error) {
      captureError(error)
    }
  }

  function handleCancel() {
    setSelected(defaultResolvedIds)
    setErrorMessage('')

    opening?.close()
  }

  const isChange = !areEqualSets(new Set(defaultResolvedIds || []), selected)
  usePromptOnUnmount(isChange, 'Are you sure you want to leave without resolving these changes?')

  useEffect(() => {
    if (error) {
      setErrorMessage(collisionType === 'UNION' ? 'Error setting prevailing value(s).' : 'Error: Select one prevailing value.')
    }
  }, [collisionType, error])

  return (
    <div className={css.collisionForm}>
      {isSummary ||
        (resolutionMessage && (
          <Box sx={{ background: 'white' }}>
            <Alert icon={false} severity={dataPoint.is_collision_resolved === false ? 'warning' : 'info'} variant="outlined">
              {resolutionMessage}
            </Alert>
          </Box>
        ))}

      <Box>
        {dataPoint?.document_data_points?.edges.map((dataPointEdge: Maybe<DataPointEdge>) => {
          const documentDataPoint = dataPointEdge?.node

          if (!documentDataPoint) return null

          const documentLabel = `${documentDataPoint.document?.document_type?.name}: ${documentDataPoint.document?.alias || documentDataPoint.document?.name}`
          const checkboxAriaLabel = `Select "${documentDataPoint.display_value}" from "${documentLabel}" as the value for "${dataPoint.data_point_field?.name}"`
          const inputRowAriaLabel = `"${dataPoint.data_point_field?.name}" from "${documentLabel}"`

          if (documentDataPoint.document?.external_document === true) {
            let src
            const source = documentDataPoint.document?.document_type?.name.toUpperCase()
            if (source && source !== 'MANUAL') {
              try {
                src = require(`../../../public/s/${source}.png`)
              } catch (error) {
                captureError(error)
              }
            }

            return (
              <div className={css.occurrence} key={documentDataPoint.id}>
                <div className={css.inputColumn}>
                  <span style={{ color: grey[600], fontSize: 10, marginLeft: 8, display: 'flex', alignItems: 'center' }}>
                    {src && <img alt={`${source?.toLowerCase()} logo`} src={src} style={{ maxHeight: 20, maxWidth: 40, marginRight: 8 }} />}

                    {documentLabel}
                  </span>

                  <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <input
                      aria-label={checkboxAriaLabel}
                      checked={selected.has(documentDataPoint.id)}
                      onChange={handleChange}
                      type="checkbox"
                      value={documentDataPoint.id}
                    />

                    <InputRow ariaLabel={inputRowAriaLabel} dataPoint={documentDataPoint} dealIsFinalized={dealIsFinalized} setFocused={() => null} />
                  </Box>
                </div>
              </div>
            )
          }

          return (
            <div className={css.occurrence} key={documentDataPoint.id}>
              <div className={css.inputColumn}>
                <NavLink className={css.documentTitle} to={`${url}?documentTab=${documentDataPoint.document?.id}`}>
                  {documentLabel}
                </NavLink>

                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                  <input
                    aria-label={checkboxAriaLabel}
                    checked={selected.has(documentDataPoint.id)}
                    onChange={handleChange}
                    type="checkbox"
                    value={documentDataPoint.id}
                  />

                  <InputRow ariaLabel={inputRowAriaLabel} dataPoint={documentDataPoint} dealIsFinalized={dealIsFinalized} setFocused={() => null} />
                </Box>
              </div>
            </div>
          )
        })}
      </Box>

      {errorMessage && (
        <Typography color="error" sx={{ fontSize: 14 }}>
          {errorMessage}
        </Typography>
      )}

      {(buttonsAreVisible || isChange) && (
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mr: '50px', mt: 1 }}>
          <Button color="tertiary" disabled={isLoading} onClick={handleCancel} size="small" sx={{ bgcolor: 'white' }} variant="outlined">
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={!isChange || isLoading}
            onClick={handleSubmit}
            size="small"
            sx={{ bgcolor: 'white' }}
            type="submit"
            variant="outlined"
          >
            <span style={{ opacity: isLoading ? 0 : 1 }}>Confirm</span>

            {isLoading && <ButtonLoadingSmall />}
          </Button>
        </Box>
      )}
    </div>
  )
}

export const DataPointCollisionFormLoader: FC<_DataPointCollisionFormLoaderProps> = ({ dataPoint, dealIsFinalized, opening }) => {
  const { data, loading: isLoading } = useDataPointDetailQuery({ fetchPolicy: 'network-only', variables: { id: dataPoint.id } })
  const dataPointDetail = data?.data_points?.edges[0]?.node as DataPoint
  const hasDocumentDataPoints = !isEmpty(dataPointDetail?.document_data_points?.edges)

  useEffect(() => {
    if (!hasDocumentDataPoints && !isLoading) opening?.close()
  }, [hasDocumentDataPoints, isLoading, opening])

  return (
    <>
      {isLoading ? (
        <ChecklistSkeleton style={{ padding: 8 }} />
      ) : (
        hasDocumentDataPoints && (
          <DataPointCollisionForm
            buttonsAreVisible
            dataPoint={dataPointDetail}
            dealIsFinalized={dealIsFinalized}
            key={JSON.stringify(dataPointDetail)}
            opening={opening}
          />
        )
      )}
    </>
  )
}

export const DataPointCollisionFormControl: FC<_DataPointCollisionFormControlProps> = ({ opening }) => (
  <Button onClick={opening.toggle} sx={{ display: 'flex', mb: -0.5, p: 1, pt: 1.5, width: 'fit-content', '&:hover': { background: 'none' } }}>
    <Typography color="primary" sx={{ fontSize: 14, fontWeight: 600 }}>
      Show source fields
    </Typography>
  </Button>
)
