import { Box } from '@mui/system'
import { ExtractionFeedbackOptionLabels, useFeedbackPageContext } from '../../pages/FeedbackPage'
import {
  FeedbackCard,
  FeedbackComment,
  FeedbackLearning,
  FeedbackOptionButton,
  FeedbackSampleValue,
  NegativeFeedbackSubmissionControls,
  PositiveFeedbackSubmissionControls
} from '.'
import { FirstLookStatus, UserOption } from '../../graphql/codegen/schemas'
import { Typography } from '@mui/material'
import { common, grey } from '@mui/material/colors'
import { formatDateTime } from '../../utils/datetimeUtils'
import { isEmpty, size } from 'lodash'
import ChecklistSkeleton from '../DataPointPanel/ChecklistSkeleton'
import React, { FC, Fragment, useEffect, useMemo } from 'react'

// functions

const getFeedbackOptionLabel = (userOption: UserOption, hasAnnotations: boolean) =>
  ExtractionFeedbackOptionLabels[
    !hasAnnotations && userOption === UserOption.CorrectlyExtracted
      ? ('CORRECTLY_NOT_EXTRACTED' as keyof typeof ExtractionFeedbackOptionLabels)
      : (userOption as keyof typeof ExtractionFeedbackOptionLabels)
  ]

// components

export const FeedbackPanel: FC = () => {
  const {
    dataPoint,
    document,
    feedbackComment,
    feedbackHighlightList,
    feedbackPanelRef,
    feedbackSessionEventList,
    isLoadingFeedbackPage,
    isSampleValueCorrect,
    isSubmittingFeedback,
    latestVerifiedSample,
    selectedExtractionFeedbackOption,
    setFeedbackComment,
    setSelectedExtractionFeedbackOption,
    submitFeedback,
    submitFeedbackMutationError,
    userOptionList
  } = useFeedbackPageContext()

  const hasAnnotations = size(document?.annotations?.edges) > 0

  const initialFeedbackEvent = feedbackSessionEventList.find((event: any) => event?.feedback_session_event_type === 'SAMPLE_GENERATION')
  const isInitialFeedbackSubmitted = Boolean(initialFeedbackEvent?.user_feedback) || isSampleValueCorrect
  const isInitialFeedbackCardDisabled = isSubmittingFeedback || isInitialFeedbackSubmitted

  // Differs from `isSampleValueCorrect` insofar as this value will be `true` only after server-side verification, not merely after client-side selection.
  const isSampleVerified =
    latestVerifiedSample?.feedback?.user_option === UserOption.CorrectlyExtracted ||
    latestVerifiedSample?.feedback?.user_option === UserOption.CorrectlyNotExtracted

  const isSubmitButtonDisabled =
    isInitialFeedbackCardDisabled ||
    !feedbackComment ||
    feedbackComment === selectedExtractionFeedbackOption?.user_feedback_template ||
    isEmpty(feedbackHighlightList)

  const submitButtonTooltip = useMemo(() => {
    const messages: string[] = []

    if (!selectedExtractionFeedbackOption) {
      return 'Select an option'
    }

    if (!isSampleValueCorrect) {
      if (!feedbackComment || feedbackComment === selectedExtractionFeedbackOption.user_feedback_template) {
        messages.push('Provide additional feedback in the comment box')
      }
      if (isEmpty(feedbackHighlightList)) {
        messages.push('Draw a box around any sections of text which have not been correctly extracted')
      }
    }

    return messages.length > 1
      ? messages.map(message => (
          <Fragment key={message}>
            &bull; {message}
            <br />
          </Fragment>
        ))
      : messages[0] || ''
  }, [selectedExtractionFeedbackOption, isSampleValueCorrect, feedbackComment, feedbackHighlightList])

  const feedbackLearningList = useMemo(
    () => feedbackSessionEventList.filter((event: any) => event?.feedback_session_event_type === 'LOCAL_LEARNING_RESPONSE'),
    [feedbackSessionEventList]
  )

  // effects

  useEffect(() => {
    if (feedbackPanelRef.current) {
      feedbackPanelRef.current.scrollTop = feedbackPanelRef.current.scrollHeight
    }
  }, [feedbackPanelRef, isSubmittingFeedback, feedbackLearningList])

  // render

  return (
    <Box sx={{ background: isLoadingFeedbackPage ? common.white : grey[50], display: 'flex', flexDirection: 'column', position: 'relative', width: '100%' }}>
      {/* Hack to cover up excess top portion of SplitPane Resizer */}
      <Box sx={{ background: common.white, height: 48, position: 'absolute', right: -6, top: 0, width: 12 }} />

      {isLoadingFeedbackPage || !latestVerifiedSample ? (
        <Box sx={{ pb: 1, pt: 3.25, px: 1 }}>
          <ChecklistSkeleton />
        </Box>
      ) : (
        <>
          <FeedbackSampleValue />

          <Typography sx={{ bgcolor: common.white, borderTop: `1px solid ${grey[300]}`, color: grey[800], fontWeight: 600, px: 3, py: 1.25 }} variant="caption">
            Give Klarity Feedback
          </Typography>

          <Box
            ref={feedbackPanelRef}
            sx={{ bgcolor: grey[50], borderTop: `1px solid ${grey[300]}`, display: 'flex', flexDirection: 'column', overflow: 'auto', pb: 6, px: 3, pt: 3 }}
          >
            {latestVerifiedSample.first_look_status === FirstLookStatus.FeedbackAppliedReadyForReview && (
              <Typography sx={{ color: grey[700], pb: 2, textAlign: 'center' }} variant="caption">
                This document has been re-processed with the latest learnings.
                <br />
                The field sample was last updated on {formatDateTime(new Date(`${latestVerifiedSample.modified_at}Z`))}.
              </Typography>
            )}

            <FeedbackCard>
              <Typography sx={{ textWrap: 'pretty' }} variant="body2">
                Does “
                {
                  <Typography component="span" sx={{ fontWeight: 600 }} variant="body2">
                    {dataPoint?.data_point_field?.name}
                  </Typography>
                }
                ” look correctly configured and extracted?
              </Typography>

              <Box sx={{ gridTemplateColumns: 'min-content min-content', display: 'grid', gap: 1 }}>
                {userOptionList.map(option => (
                  <FeedbackOptionButton
                    isDisabled={isInitialFeedbackCardDisabled}
                    isSelected={
                      latestVerifiedSample.feedback?.user_option === option.user_option || selectedExtractionFeedbackOption?.user_option === option.user_option
                    }
                    key={option.user_option}
                    label={getFeedbackOptionLabel(option.user_option as UserOption, hasAnnotations)}
                    onClick={() => setSelectedExtractionFeedbackOption(option)}
                  />
                ))}
              </Box>

              {latestVerifiedSample.first_look_status !== FirstLookStatus.SampleIsCorrect && isSampleValueCorrect && isEmpty(feedbackLearningList) && (
                <PositiveFeedbackSubmissionControls
                  hasError={Boolean(submitFeedbackMutationError)}
                  isLoading={isSubmittingFeedback}
                  loadingMessage="Verifying sample…"
                  onCancel={() => setSelectedExtractionFeedbackOption(null)}
                  onSubmit={submitFeedback}
                />
              )}

              {selectedExtractionFeedbackOption && !isSampleValueCorrect && (
                <>
                  <FeedbackComment
                    helperText={
                      isInitialFeedbackCardDisabled ? '' : 'Don’t forget to draw a box around any sections of text that have not been correctly extracted.'
                    }
                    isDisabled={isInitialFeedbackCardDisabled}
                    label="Tell us why in a bit more detail (required)"
                    onValueChange={setFeedbackComment}
                    value={initialFeedbackEvent?.user_feedback || feedbackComment || selectedExtractionFeedbackOption.user_feedback_template}
                  />

                  {!isInitialFeedbackSubmitted && (
                    <NegativeFeedbackSubmissionControls
                      buttonTooltip={submitButtonTooltip}
                      isButtonDisabled={isSubmitButtonDisabled}
                      isLoading={isSubmittingFeedback}
                      onSubmit={submitFeedback}
                    />
                  )}
                </>
              )}
            </FeedbackCard>

            {isSampleVerified ? (
              <>
                <Typography sx={{ color: grey[700], py: 2, textAlign: 'center' }} variant="caption">
                  Sample verified on {formatDateTime(new Date(`${latestVerifiedSample?.feedback?.created_at}Z`))}
                </Typography>
              </>
            ) : (
              feedbackLearningList.map((learningEvent, index) => <FeedbackLearning key={index} learningEvent={learningEvent} />)
            )}
          </Box>
        </>
      )}
    </Box>
  )
}
