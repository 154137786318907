import { CurrentRuntimeEnvironment } from '../utils/envUtils'
import { OktaAuthOptions } from '@okta/okta-auth-js'

export const API_ROOT = CurrentRuntimeEnvironment.REACT_APP_API_ROOT

const REACT_APP_NEXT_JS_API_ROOT = CurrentRuntimeEnvironment.REACT_APP_NEXT_JS_API_ROOT
export const NEXT_JS_API_DATA_MAPPING = `${REACT_APP_NEXT_JS_API_ROOT}/data-mapping`

// Kibana auth redirect is proxied by nginx
export const ANALYTICS_URL = CurrentRuntimeEnvironment.REACT_APP_ANALYTICS_URL

// Okta
const OKTA_CLIENT_ID = CurrentRuntimeEnvironment.REACT_APP_OKTA_CLIENT_ID
export const OKTA_EXPIRE_EARLY_SECONDS = 120
export const OKTA_LOGIN_REDIRECT_PATH = '/login/callback'
const OKTA_LOGOUT_REDIRECT_PATH = '/logout'
const OKTA_URL = CurrentRuntimeEnvironment.REACT_APP_OKTA_URL

export const OKTA_CONFIG: OktaAuthOptions = {
  pkce: false,
  issuer: `${OKTA_URL}/oauth2/default`,
  clientId: OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}${OKTA_LOGIN_REDIRECT_PATH}`,
  postLogoutRedirectUri: `${window.location.origin}${OKTA_LOGOUT_REDIRECT_PATH}`,
  scopes: ['openid', 'profile', 'email'],

  // Override the default authorize and userinfo URLs
  authorizeUrl: `${OKTA_URL}/oauth2/default/v1/authorize`,
  tokenUrl: `${OKTA_URL}/oauth2/default/v1/token`,
  userinfoUrl: `${OKTA_URL}/oauth2/default/v1/userinfo`,
  responseType: 'id_token',
  responseMode: 'fragment',

  tokenManager: { storage: 'localStorage', autoRenew: true, expireEarlySeconds: OKTA_EXPIRE_EARLY_SECONDS }
}

// constants

export const DOCUMENT_BULK_UPLOAD_URL = `${API_ROOT}/document/bulk_upload`
export const DOCUMENT_BULK_UPLOAD_GET_SIGNED_URL_URL = `${API_ROOT}/document/bulk_upload/get_signed_url`
