import { Box, Typography } from '@mui/material'
import { CreateOrSelectNeutralTag } from '../EditInputs/CreateOrSelectNeutralTag'
import React, { FC } from 'react'
import css from './style.module.scss'

// types

type _NeutralTagInputProps = { handleData: (type: string, value: any) => void; value?: string }

// components

export const NeutralTagInput: FC<_NeutralTagInputProps> = ({ handleData, value = '' }) => (
  <Box className={css.inputWrapper}>
    <Typography component="label" htmlFor="automation-tag-value-select-input" sx={{ flexShrink: 0, fontWeight: 600, mr: 2, width: 164 }} variant="body2">
      Automation Tag
    </Typography>

    <CreateOrSelectNeutralTag id="automation-tag-value-select-input" neutralTag={value} setNeutralTag={(value: string) => handleData('neutral_tag', value)} />
  </Box>
)
