import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import React, { FC, useEffect } from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'

// types

type _PositiveFeedbackSubmissionControlsProps = { hasError: boolean; isLoading: boolean; loadingMessage: string; onCancel: () => void; onSubmit: () => void }

// components

export const PositiveFeedbackSubmissionControls: FC<_PositiveFeedbackSubmissionControlsProps> = ({
  hasError,
  isLoading,
  loadingMessage,
  onCancel,
  onSubmit
}) => {
  useEffect(() => {
    if (hasError || isLoading) return

    const timeoutId = setTimeout(onSubmit, 2000)

    return () => clearTimeout(timeoutId)
  }, [hasError, isLoading, onSubmit])

  return hasError ? (
    <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
      <Typography color="error" variant="body2">
        Error submitting feedback.
      </Typography>

      <Tooltip arrow title="Retry">
        <IconButton onClick={onSubmit} size="small">
          <RefreshIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  ) : (
    <Box sx={{ alignItems: 'flex-start', display: 'flex', gap: 1.25 }}>
      <CircularProgress size={16} sx={{ flexShrink: 0, mt: '7px' }} />

      {isLoading ? (
        <Typography sx={{ py: '5px' }} variant="body2">
          {loadingMessage}
        </Typography>
      ) : (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
          <Typography variant="body2">Submitting feedback…</Typography>

          <Tooltip arrow title="Cancel">
            <IconButton onClick={onCancel} size="small">
              <CancelIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  )
}
