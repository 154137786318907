// FIXME: removing tschecking for automated upload demo in order to use CreatableSelect and Select interchangeably
// @ts-nocheck
import CreatableSelect from 'react-select/creatable'
import React, { CSSProperties, FC, useCallback, useRef } from 'react'
import Select from 'react-select'

// types

type _SelectInputProps = {
  [key: string]: any
  creatable?: boolean
  isDisabled?: boolean
  isError?: any
  onEnter?: () => void
  onEscapeKeyDown?: () => void
  onMenuClose?: () => void
  onMenuOpen?: () => void
  placeholder?: string
  styles?: { [key: string]: CSSProperties }
}

// components

const SelectInput: FC<_SelectInputProps> = ({
  creatable,
  isDisabled,
  isError,
  onEnter,
  onEscapeKeyDown,
  onMenuClose,
  onMenuOpen,
  placeholder,
  styles,
  ...rest
}) => {
  const isMenuOpenRef = useRef(false)

  const handleKeyDown = useCallback(
    event => {
      if (onEnter && event.key === 'Enter' && !isMenuOpenRef.current) {
        onEnter()
      } else if (onEscapeKeyDown && event.key === 'Escape') {
        onEscapeKeyDown()
      }
    },
    [onEnter, onEscapeKeyDown]
  )

  const handleMenuClose = useCallback(() => {
    isMenuOpenRef.current = false

    onMenuClose?.()
  }, [onMenuClose])

  const handleMenuOpen = useCallback(() => {
    isMenuOpenRef.current = true

    onMenuOpen?.()
  }, [onMenuOpen])

  const Component = creatable ? CreatableSelect : Select

  return (
    <Component
      aria-label={placeholder || 'Select an option'}
      {...rest}
      isDisabled={isDisabled}
      isFocused
      onKeyDown={handleKeyDown}
      onMenuClose={handleMenuClose}
      onMenuOpen={handleMenuOpen}
      placeholder={placeholder}
      styles={{
        clearIndicator: provided => ({ ...provided, visibility: 'hidden', ...styles?.clearIndicator }),
        container: provided => ({ ...provided, ...styles?.container }),
        control: provided => ({
          ...provided,
          borderColor: isError ? 'red' : provided.borderColor,
          fontSize: 14,
          '&:hover': { borderColor: isError ? 'red' : provided.borderColor },
          ...styles?.control
        }),
        dropdownIndicator: provided => ({ ...provided, paddingBottom: 0, paddingTop: 0, ...styles?.dropdownIndicator }),
        menu: provided => ({ ...provided, zIndex: 999999999, ...styles?.menu }),
        menuPortal: provided => ({ ...provided, fontSize: 14, zIndex: 999999999, ...styles?.menuPortal }),
        multiValue: provided => ({ ...provided, fontSize: 14, maxWidth: '300px', ...styles?.multiValue }),
        option: provided => ({ ...provided, fontSize: 14, ...styles?.option }),
        placeholder: provided => ({ ...provided, color: '#57575b', fontFamily: 'Open Sans', fontSize: 14, ...styles?.placeholder }),
        singleValue: (provided, state) => ({
          ...provided,
          fontSize: 14,
          opacity: state.isDisabled ? 0.5 : 1,
          transition: 'opacity 300ms',
          padding: 0,
          ...styles?.singleValue
        }),
        valueContainer: provided => ({ ...provided, paddingLeft: 6, paddingRight: 6, ...styles?.valueContainer })
      }}
    />
  )
}

export default SelectInput
