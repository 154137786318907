import { Box, Typography } from '@mui/material'
import { Features, Permissions, useUserAccess } from '../../../../../../../hooks/useUserAccess'
import { Sources } from '../CreateInputs/SourceInput'
import { getExtractionFieldTypeLabel } from '../../../../../../../utils/cci'
import { sortBy } from 'lodash'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'

// types

type _ExtractionFieldTypeInputProps = { handleData: (type: string, value: any) => void; source: Sources; value: ExtractionFieldTypes[] }

export enum ExtractionFieldTypeLabels {
  'DYNAMIC-FORMULA' = 'Dynamic Formula',
  'FREE-TEXT' = 'Free Text',
  'FREE-TEXT-OR-TABULAR' = 'Free Text or Tabular',
  'PRE-ANNOTATION' = 'Pre-annotation',
  'TABLE-COMPOSITION' = 'Table Composition',
  TABULAR = 'Tabular'
}

export enum ExtractionFieldTypes {
  'DYNAMIC-FORMULA' = 'DYNAMIC-FORMULA',
  'FREE-TEXT' = 'FREE-TEXT',
  'FREE-TEXT-OR-TABULAR' = 'FREE-TEXT-OR-TABULAR',
  'PRE-ANNOTATION' = 'PRE-ANNOTATION',
  'TABLE-COMPOSITION' = 'TABLE-COMPOSITION',
  TABULAR = 'TABULAR'
}

// functions

export const displayExtractionFieldType = (value: ExtractionFieldTypes[]) => ExtractionFieldTypeLabels[value[0] as keyof typeof ExtractionFieldTypeLabels]

// components

export const ExtractionFieldTypeInput: FC<_ExtractionFieldTypeInputProps> = ({ handleData, source, value }) => {
  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  const extractionFieldTypeList = useMemo(
    () =>
      sortBy(
        Object.values(ExtractionFieldTypes).map(value => ({
          label: ExtractionFieldTypeLabels[value],
          value
        })),
        'label'
      ),
    []
  )

  const handleChange = useCallback(
    (labelValue: { value: string }) => handleData('extraction_field_type', labelValue?.value ? [labelValue.value] : null),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const extractionFieldType = useMemo(() => value?.[0] || null, [value])

  useEffect(() => {
    if (source !== Sources.INTERNAL) {
      handleData('extraction_field_type', null)
    }
  }, [source]) // eslint-disable-line react-hooks/exhaustive-deps

  if (source !== Sources.INTERNAL) return null

  return (
    <Box sx={{ alignItems: 'baseline', display: 'flex', mb: 1 }}>
      <Typography component="label" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        Extraction Type
      </Typography>

      <Box sx={{ flex: 1 }}>
        <SelectInput
          isClearable
          isDisabled={!hasFullEditAccess}
          onChange={handleChange}
          options={extractionFieldTypeList}
          placeholder={getExtractionFieldTypeLabel(extractionFieldType)}
          value={extractionFieldType}
        />
      </Box>
    </Box>
  )
}
