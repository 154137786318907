import { AdvancedDashboardData, DocumentsRerunExtractionResult } from '../../graphql/codegen/schemas'
import { Box, Tooltip, Typography } from '@mui/material'
import { ExtractionStatuses } from '../../utils/extractionUtils'
import { Row } from 'react-table'
import { useApolloClient } from '@apollo/client'
import { useAppContext } from '../../app'
import { useRerunExtractionMutation } from '../../graphql/codegen/hooks'
import Button from '../Button'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

// types

type _RerunDocumentExtractionButtonProps = { rows: Row<{}>[]; selectedFlatRows: Row<{}>[] }

// components

export const RerunDocumentExtractionButton: FC<_RerunDocumentExtractionButtonProps> = ({ rows, selectedFlatRows }) => {
  const { setErrorMessage } = useAppContext()
  const [hasUserTriggeredRerun, setHasUserTriggeredRerun] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const apolloClient = useApolloClient()

  const areMultipleRowsSelected = selectedFlatRows.length > 1

  const isUnprocessedRowSelected = useMemo(() => selectedFlatRows.some(row => !(row.original as AdvancedDashboardData).processed), [selectedFlatRows])

  const isExtractionInProgress = useMemo(
    () => rows.some(row => (row.original as AdvancedDashboardData).extraction_status?.value === ExtractionStatuses.PROCESSING),
    [rows]
  )

  const tooltipContent = useMemo(
    () =>
      isExtractionInProgress || areMultipleRowsSelected ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="caption">
            {isExtractionInProgress
              ? 'A document extraction is currently in progress. You can only run an extraction on one document at a time.'
              : 'You can only run an extraction on one document at a time.'}
          </Typography>

          <Typography variant="caption">Contact Klarity if you need to run multiple extractions simultaneously.</Typography>
        </Box>
      ) : isUnprocessedRowSelected ? (
        <Typography variant="caption">You can only re-run extractions on processed documents.</Typography>
      ) : (
        ''
      ),
    [isExtractionInProgress, areMultipleRowsSelected, isUnprocessedRowSelected]
  )

  const [rerunExtraction, { data: rerunExtractionData, loading: isRerunExtractionLoading }] = useRerunExtractionMutation()

  const updateExtractionStatus = useCallback(
    (status: string) => {
      const documentId = selectedFlatRows[0].values.document.id

      apolloClient.cache.modify({
        id: apolloClient.cache.identify({ id: documentId, __typename: 'AdvancedDashboardData' }),
        fields: { extraction_status: () => status }
      })
    },
    [apolloClient, selectedFlatRows]
  )

  const handleClick = useCallback(async () => {
    setIsHovered(false)

    const documentId = selectedFlatRows[0].values.document.id

    rerunExtraction({ variables: { rerunExtractionInput: { document_ids: [documentId] } } })
  }, [rerunExtraction, selectedFlatRows])

  useEffect(() => {
    if (rerunExtractionData) {
      const result = rerunExtractionData.rerun_extraction?.rerun_extraction_result as DocumentsRerunExtractionResult | null
      const documentId = selectedFlatRows[0].values.document.id

      if (result?.already_processing_documents.includes(documentId) || result?.queued_documents.includes(documentId)) {
        updateExtractionStatus(ExtractionStatuses.PROCESSING)
        setHasUserTriggeredRerun(true)
      } else if (result?.failed_to_queue_documents.includes(documentId)) {
        updateExtractionStatus(ExtractionStatuses.FAILED)
        setErrorMessage('Failed to queue document for extraction')
      }
    }
  }, [rerunExtractionData, selectedFlatRows, updateExtractionStatus, setErrorMessage])

  return (
    <>
      <Tooltip arrow placement="top" title={isHovered && tooltipContent ? tooltipContent : ''}>
        <span onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          <Button disabled={Boolean(tooltipContent) || hasUserTriggeredRerun || isRerunExtractionLoading} onClick={handleClick} variant="primary">
            {hasUserTriggeredRerun || isRerunExtractionLoading ? 'Processing…' : 'Re-run Extraction'}
          </Button>
        </span>
      </Tooltip>
    </>
  )
}
