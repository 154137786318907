import { AdvancedDashboardQuery } from '../../graphql/codegen/operations'
import { CellView } from '../dashboard/CellView'
import { Column, DocumentQueryAction } from '../../reducers/dashboardQueryReducer'
import { ExtractionStatusLabels, ExtractionStatuses } from '../../utils/extractionUtils'
import { Features, Permissions, useIsAnnotator, useIsSuperAnnotator, useUserAccess } from '../../hooks/useUserAccess'
import { LazyQueryResultTuple } from '@apollo/client'
import { Link, useHistory } from 'react-router-dom'
import { MoreMenu, _MenuItem } from '../MoreMenu'
import { PAGE_LENGTHS, PAGE_LENGTH_PREFERENCE_KEY } from '../Table/Pagination/Pagination'
import { RerunDocumentExtractionButton } from '../RerunExtraction'
import { Row } from 'react-table'
import { Table } from '../Table'
import { captureError } from '../../utils/sentry'
import { formatDataPointId } from '../../utils/fieldUtils'
import { formatDate } from '../../utils/datetimeUtils'
import { generateDashboardColumns, generateSortDirection, generateSortFunction } from '../../utils/dashboardColumnUtils'
import { hideFileExtension } from '../../utils/stringUtils'
import {
  useAdvancedDashboardExtractionStatusesQuery,
  useBulkEditDocumentAssigneeMutation,
  useBulkEditInternalAssigneeMutation,
  useDashboardConfigQuery,
  useDocumentCountQuery
} from '../../graphql/codegen/hooks'
import { useIsExtractionRerunEnabled } from '../../hooks/useIsExtractionRerunEnabled'
import { useIsKlarityEmployee } from '../../hooks/useCurrentUser'
import { useModalContext } from '../../app'
import AddDocToDeal from '../ModalOptions/AddDocToDeal'
import BulkEditAssigneeButton from '../../containers/BulkEditAssigneeButton'
import BulkEditStatusButton from '../../containers/BulkEditStatusButton'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import ConvertDocumentLevel from '../ModalOptions/ConvertDocumentLevel'
import DeleteDocument from '../ModalOptions/DeleteDocument'
import Modal from '../Modal'
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import RenameFile from '../RenameFile'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'
import useDashboardQString from '../../hooks/useDashboardQString'
import useIsRapid7LegalTeam from '../../hooks/useIsRapid7LegalTeam'
import useTablePagination from '../../hooks/useTablePagination'

// types

type _DocumentListProps = {
  advancedDashboardLazyQueryResult: LazyQueryResultTuple<AdvancedDashboardQuery, any>
  dispatch: Dispatch<DocumentQueryAction>
  selectedColumns: Column[]
  setClearAll: Dispatch<SetStateAction<boolean>>
  setFilteredCount: Dispatch<SetStateAction<number>>
  setTotalCount: Dispatch<SetStateAction<number>>
  totalCount?: number
}

// functions

const getAccessor = (value: string) => {
  switch (value) {
    case '_id':
      return 'document_id'
    case 'is_legacy_str':
      return 'is_legacy'
    default:
      return value
  }
}

const getPageLengthEquivalent = () => {
  // provides an immediate pageLength equivalent that generally matches the actual pageLength that is set after useDocumentCountQuery responds

  const pageLengthPreference = localStorage.getItem(PAGE_LENGTH_PREFERENCE_KEY)

  return pageLengthPreference
    ? PAGE_LENGTHS.includes(Number(pageLengthPreference))
      ? Number(pageLengthPreference)
      : PAGE_LENGTHS.slice(-1)[0]
    : PAGE_LENGTHS[0]
}

// components

export const DocumentList: FC<_DocumentListProps> = ({
  advancedDashboardLazyQueryResult,
  dispatch,
  selectedColumns,
  setClearAll,
  setFilteredCount,
  setTotalCount
}) => {
  const [advancedDashboardQuery, { data: dataAdvanced, loading: loadingAdvanced }] = advancedDashboardLazyQueryResult
  const { data: dashboardConfigurationData } = useDashboardConfigQuery()
  const history = useHistory()
  const [rowCount, setRowCount] = useState(10)
  const { offset, pageLength, ...paginationControls } = useTablePagination({ total: rowCount })
  const { gqlFilters, queryBuilderQuery, sortConfig } = useDashboardQString()

  const canChangeDocumentCounterparty = useUserAccess({ feature: Features.DOCUMENT, permission: Permissions.UPDATE_COUNTERPARTY })
  const canDeleteDocument = useUserAccess({ feature: Features.DOCUMENT, permission: Permissions.DELETE })
  const hasAccountLevelDocumentAccess = useUserAccess({ feature: Features.DOCUMENT, permission: Permissions.ACCOUNT_LEVEL })
  const hasDealAccess = useUserAccess({ feature: Features.DEAL_DASHBOARD, permission: Permissions.READ })

  const isAnnotator = useIsAnnotator()
  const isExtractionRerunEnabled = useIsExtractionRerunEnabled()
  const isKlarityEmployee = useIsKlarityEmployee()
  const isRapid7LegalTeam = useIsRapid7LegalTeam()
  const isSuperAnnotator = useIsSuperAnnotator()

  const { refetch: getExtractionStatuses } = useAdvancedDashboardExtractionStatusesQuery({
    fetchPolicy: 'network-only',
    skip: !isExtractionRerunEnabled,
    variables: { ...gqlFilters, offset, size: pageLength || getPageLengthEquivalent() }
  })

  useMemo(
    () => advancedDashboardQuery({ variables: { ...gqlFilters, offset, size: pageLength || getPageLengthEquivalent() } }),
    [advancedDashboardQuery, gqlFilters, offset, pageLength]
  )

  const { data: dataCount, loading: loadingCount, refetch: refetchCount } = useDocumentCountQuery({ fetchPolicy: 'network-only', variables: gqlFilters })

  const isLoading = loadingAdvanced || loadingCount

  useEffect(() => {
    refetchCount()
  }, [dataAdvanced, refetchCount])

  useEffect(() => {
    if (!dataAdvanced || !isExtractionRerunEnabled) return

    const intervalId = setInterval(() => {
      if (dataAdvanced?.fetch_advanced_dashboard?.dashboard_data?.some(item => item?.extraction_status?.value === ExtractionStatuses.PROCESSING)) {
        getExtractionStatuses()
      }
    }, 30000)

    return () => clearInterval(intervalId)
  }, [dataAdvanced, getExtractionStatuses, gqlFilters, isExtractionRerunEnabled, offset, pageLength])

  useEffect(() => {
    // ONLY recount if the search changed!
    if (dataCount && dataCount.fetch_advanced_dashboard_count) {
      setFilteredCount(dataCount.fetch_advanced_dashboard_count.total_number_of_filtered_documents!)
      setTotalCount(dataCount.fetch_advanced_dashboard_count.total_number_of_documents!)
    } else {
      setFilteredCount(0)
      setTotalCount(0)
    }
  }, [dataCount, setFilteredCount, setTotalCount])

  const totalFiltered = dataCount?.fetch_advanced_dashboard_count?.total_number_of_filtered_documents
  useEffect(() => {
    setRowCount(totalFiltered || 0)
  }, [totalFiltered])

  const tableData = useMemo(() => {
    if (!dataAdvanced) return []
    const dashboardData = dataAdvanced.fetch_advanced_dashboard?.dashboard_data
    const normalized = dashboardData?.map((row: any) => {
      const data_points = row.data_points.reduce((acc: any, curr: any) => {
        const id = formatDataPointId(curr)
        acc[id] = curr
        return acc
      }, {})
      return {
        ...row,
        ...data_points,
        created_at: row.created_at,
        counterPartyId: row?.counter_party?.value && JSON.parse(row.counter_party.value)?.id,
        state: row.status
      }
    })
    return normalized // this is an object with keys that map to the 'accessor' passed to columns below
  }, [dataAdvanced])

  const renderCell = useCallback(
    (column: Column, { row, value }: { row: any; value: any }) => {
      if (!value) return null

      let textValue: string = value?.value || ''

      // format value for special columns
      if (column.data_type === 'MULTI_SELECT_DROP_DOWN' && value && value.value) {
        // Response is a stringified array with single quotes, so this parses it into a list
        try {
          const withQuotesReplaced = value.value.split("'").join('"')
          textValue = JSON.parse(withQuotesReplaced).join(', ') || ''
        } catch (error) {
          captureError(error)
        }
      } else if (column.value === 'assignee') {
        textValue = value.value?.user_name || ''
      } else if (column.value === 'all_assignees') {
        textValue = value.reduce((acc: string, current: any, idx: number) => {
          if (idx !== 0) {
            acc += ', '
          }
          acc += current.value.user_name
          return acc
        }, '')
      } else if (value?.value && column.value === 'integration_type') {
        const userName = row?.original?.created_by?.value?.user_name
        const uploadDate = row?.original?.created_at ? formatDate(row?.original?.created_at?.value) : undefined
        const uploadedBy = userName?.includes('klaritylaw.com') ? (isKlarityEmployee ? userName : 'Klarity') : userName

        const tooltipContent = (
          <>
            {value?.value === 'MANUAL' && uploadedBy && <div>{`Uploaded by: ${uploadedBy}`}</div>}

            {uploadDate && <div>{`Upload Date: ${uploadDate}`}</div>}
          </>
        )

        return (
          <WithTooltip content={tooltipContent}>
            <CellView header={column.label} textValue={value?.value || ''} />
          </WithTooltip>
        )
      }

      return <CellView header={column.label} textValue={textValue} />
    },
    [isKlarityEmployee]
  )

  // Take selectedColumns from reducer, format for react table
  // The table response does not return which values have been sorted by, so we add it into the column data here.
  const dynamicColumns = useMemo(
    () =>
      (selectedColumns || []).map(column => {
        if (!column) return null

        let sortFunction = null
        let sortDirection: -1 | 0 | 1 = 0

        if (column.data_point_field_id) {
          sortDirection = generateSortDirection(sortConfig, 'sort_data_points', column.data_point_field_id)
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, 'sort_data_points', selectedColumns, column.data_point_field_id)
        } else if (column.value === 'created_at' || column.value === 'unresolved_comments_count') {
          sortDirection = generateSortDirection(sortConfig, `sort_${column.value}`)
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, `sort_${column.value}`, selectedColumns)
        } else if (column.value === 'status' || column.value === 'state') {
          sortDirection = generateSortDirection(sortConfig, 'sort_state')
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, 'sort_state', selectedColumns)
        } else if (column.value === 'assignee') {
          sortDirection = generateSortDirection(sortConfig, 'sort_assignee_user_name')
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, 'sort_assignee_user_name', selectedColumns)
        }

        return {
          ...column,
          Header: column.label,
          accessor: getAccessor(column.value),
          sortFunction,
          sortDirection,
          draggable: true,
          Cell: (rowAndValue: { row: any; value: any }) => renderCell(column, rowAndValue)
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedColumns, history, sortConfig]
  )

  const createSelectionActionsRenderer = useCallback(() => {
    if (!hasDealAccess || isRapid7LegalTeam || isSuperAnnotator || isExtractionRerunEnabled) {
      return (selectedFlatRows: Row<{}>[], rows?: Row<{}>[]) =>
        selectedFlatRows?.length > 0 ? (
          <>
            {!hasDealAccess || isRapid7LegalTeam ? (
              <>
                <BulkEditStatusButton resourceName="document" selectedFlatRows={selectedFlatRows} />

                <BulkEditAssigneeButton
                  bulkEditAssigneeMutation={useBulkEditDocumentAssigneeMutation}
                  entityName="Assignee"
                  resourceName="document"
                  selectedFlatRows={selectedFlatRows}
                />
              </>
            ) : isSuperAnnotator ? (
              <BulkEditAssigneeButton
                bulkEditAssigneeMutation={useBulkEditInternalAssigneeMutation}
                entityName="Assignee"
                resourceName="document"
                selectedFlatRows={selectedFlatRows}
              />
            ) : null}

            {isExtractionRerunEnabled && rows && <RerunDocumentExtractionButton rows={rows} selectedFlatRows={selectedFlatRows} />}
          </>
        ) : null
    }
  }, [hasDealAccess, isExtractionRerunEnabled, isRapid7LegalTeam, isSuperAnnotator])

  const { openModal, openPreview } = useModalContext()
  const [modalLoading, setModalLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [modalOption, setModalOption] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState<{
    counterpartyId?: string
    documentAlias?: string
    documentId: string
    documentName: string
    documentTypeId?: string
    isAccountLevel?: boolean
  }>()

  const openLocalModal = (
    menuOption: string,
    documentId: string,
    documentName: string,
    documentAlias?: string,
    counterpartyId?: string,
    isAccountLevel?: boolean
  ) => {
    setIsOpen(true)
    setModalOption(menuOption)
    setModalTitle(menuOption)
    setModalContent({ documentId, documentName, documentAlias, counterpartyId, isAccountLevel })
  }

  const closeModal = () => {
    if (!modalLoading) {
      setIsOpen(false)
      setModalOption('')
      setModalTitle('')
      setModalContent(undefined)
      setModalLoading(true)
      setLoadingMessage('')
    }
  }

  const getModalOptions = useCallback(
    ({ counterpartyId, counterpartyName, documentAlias, documentId, documentName, is_account_level }: any) => [
      { label: 'Add to Deal…', onClick: () => openLocalModal('Add to Deal', documentId, documentName, documentAlias, counterpartyId, is_account_level) },
      { label: 'Rename Document', onClick: () => openLocalModal('Rename Document', documentId, documentName, documentAlias, counterpartyId) },
      ...(canChangeDocumentCounterparty
        ? [
            {
              label: 'Change Document’s Customer',
              onClick: () => {
                openModal('ChangeCounterparty', 'Change Document’s Customer', {
                  itemType: 'Document',
                  itemName: documentAlias || documentName,
                  documentId,
                  counterpartyId,
                  counterpartyName
                })
              }
            }
          ]
        : []),
      ...(hasAccountLevelDocumentAccess && is_account_level
        ? [
            {
              label: 'Remove Document from Customer Level',
              onClick: () => openLocalModal('Change Document Level', documentId, documentName, documentAlias, counterpartyId, is_account_level)
            }
          ]
        : []),
      ...(hasAccountLevelDocumentAccess && !is_account_level
        ? [
            {
              label: 'Convert Document to Customer Level',
              onClick: () => openLocalModal('Change Document Level', documentId, documentName, documentAlias, counterpartyId, is_account_level)
            }
          ]
        : []),
      ...(canDeleteDocument
        ? [
            {
              label: 'Delete Document',
              onClick: () => openLocalModal('Delete Document', documentId, documentName, documentAlias, counterpartyId)
            }
          ]
        : [])
    ],
    [canChangeDocumentCounterparty, canDeleteDocument, hasAccountLevelDocumentAccess, openModal]
  )

  const annotatorStaticColumns = useMemo(
    () =>
      isAnnotator
        ? generateDashboardColumns(history, queryBuilderQuery, sortConfig, selectedColumns, [
            {
              headerName: 'Annotation Status',
              accessor: 'internal_state',
              disableSort: true,
              Cell: ({ value }: any) => <CellView header="Annotation Status" textValue={value?.value || ''} />
            },
            {
              headerName: 'Annotation Assignee',
              accessor: 'internal_assignee',
              disableSort: true,
              Cell: ({ value }: any) => (
                <CellView header="Annotation Assignee" textValue={value?.value ? value.value.user_name || 'Error: missing user_name' : 'Unassigned'} />
              )
            }
          ])
        : [],
    [history, isAnnotator, queryBuilderQuery, selectedColumns, sortConfig]
  )

  const extractionStatusColumns = useMemo(
    () =>
      isExtractionRerunEnabled
        ? generateDashboardColumns(history, queryBuilderQuery, sortConfig, selectedColumns, [
            {
              headerName: 'Extraction Status',
              accessor: 'extraction_status.value',
              disableSort: true,
              Cell: ({ value }: any) => (
                <CellView header="Extraction Status" textValue={ExtractionStatusLabels[value as keyof typeof ExtractionStatuses] || ''} />
              )
            }
          ])
        : [],
    [history, isExtractionRerunEnabled, queryBuilderQuery, selectedColumns, sortConfig]
  )

  const staticColumns = useMemo(
    () => [
      ...generateDashboardColumns(history, queryBuilderQuery, sortConfig, selectedColumns, [
        {
          headerName: 'Document',
          accessor: 'document',
          sortStringName: 'document_name',
          Cell: ({ row, value }: any) => {
            if (!value) return null
            const documentId = value?.id
            const documentName: string = hideFileExtension(value?.value_original || value?.value) || ''
            const documentAlias: string = hideFileExtension(value?.value_alias) || ''
            const counterpartyId = row?.values?.counter_party?.value && JSON.parse(row?.values?.counter_party?.value)?.id
            const counterpartyName = row?.values?.counter_party?.value && JSON.parse(row?.values?.counter_party?.value)?.name
            const hasAnnotatorAccess = isAnnotator && row.original?.extraction_status?.value !== ExtractionStatuses.PROCESSING
            const isProcessed = row.original?.processed !== false
            const is_account_level = Boolean(row?.original?.is_counter_party_level)
            const menuItemList: _MenuItem[] = [
              { label: 'Preview', onClick: () => openPreview(documentId, true, documentAlias || documentName) },
              ...(hasAnnotatorAccess || isProcessed
                ? getModalOptions({
                    documentId,
                    documentName: value?.value,
                    documentAlias: value?.value_alias,
                    counterpartyId,
                    is_account_level,
                    counterpartyName
                  })
                : [])
            ]

            const textValue = documentAlias || documentName
            const title = documentAlias && `Original Name: ${documentName}`

            return (
              <CellView
                header="Document"
                isCustomerLevelDocument={is_account_level}
                menu={<MoreMenu menuItemList={menuItemList} />}
                textValue={textValue}
                title={title}
              >
                {hasAnnotatorAccess || isProcessed ? <Link to={`/documents/${value?.id}`}>{textValue}</Link> : textValue}
              </CellView>
            )
          }
        },
        {
          headerName: 'Customer',
          accessor: 'counter_party',
          sortStringName: 'counter_party_name',
          Cell: ({ value }: any) => {
            const parsedCounterParty = value?.value && JSON.parse(value.value)
            const textValue: string = parsedCounterParty?.name && parsedCounterParty.name !== 'UNASSIGNED' ? parsedCounterParty.name : ''

            return <CellView header="Customer" textValue={textValue} />
          }
        }
      ]),
      ...annotatorStaticColumns,
      ...extractionStatusColumns
    ],
    [annotatorStaticColumns, extractionStatusColumns, getModalOptions, history, isAnnotator, openPreview, queryBuilderQuery, selectedColumns, sortConfig]
  )

  const filteredColumns = useMemo(() => {
    const isColumnIncluded = (internalName: string) =>
      dashboardConfigurationData?.dashboard_config?.document_default_fields?.edges?.find(edge => edge?.node?.internal_name === internalName)?.node
        ?.is_visible !== false

    return [...staticColumns, ...dynamicColumns].filter(column => isColumnIncluded(column?.accessor as string))
  }, [dashboardConfigurationData?.dashboard_config?.document_default_fields?.edges, dynamicColumns, staticColumns])

  return (
    <>
      <Table
        {...paginationControls}
        columns={filteredColumns}
        dispatch={dispatch}
        dummyDataActive={isLoading || !dataCount}
        isLoading={isLoading || !dataCount}
        pageLength={pageLength}
        renderSelectionActions={createSelectionActionsRenderer()}
        rowCount={rowCount}
        setClearAll={setClearAll}
        tableData={tableData}
      />

      <Modal isOpen={isOpen} onRequestClose={closeModal} title={modalTitle}>
        {modalLoading && <ComponentLoadingOverlay loading={modalLoading} message={loadingMessage} />}
        <div className={css.modal}>
          {modalOption !== 'Rename Document' && modalOption !== 'Change Document Level' && modalOption !== 'Delete Document' && (
            <h4>{modalContent?.documentAlias || modalContent?.documentName}</h4>
          )}
          {modalOption === 'Add to Deal' && (
            <AddDocToDeal
              closeModal={closeModal}
              counterpartyId={modalContent?.counterpartyId}
              documentId={modalContent?.documentId}
              loading={modalLoading}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}
          {modalOption === 'Rename Document' && (
            <RenameFile
              closeModal={closeModal}
              documentId={modalContent?.documentId}
              fileAlias={modalContent?.documentAlias}
              fileName={modalContent?.documentName}
              fileType={'Document'}
              loading={modalLoading}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}
          {modalOption === 'Delete Document' && (
            <DeleteDocument
              closeModal={closeModal}
              documentName={modalContent?.documentAlias || modalContent?.documentName}
              document_id={modalContent?.documentId}
              setLoadingMessage={setLoadingMessage}
              setModalLoading={setModalLoading}
            />
          )}
          {modalOption === 'Change Document Level' && ( // @ts-ignore
            <ConvertDocumentLevel
              closeModal={closeModal}
              counterpartyId={modalContent?.counterpartyId}
              // @ts-ignore
              documentName={modalContent?.documentAlias || modalContent?.documentName}
              // @ts-ignore
              document_id={modalContent?.documentId}
              isAccountLevel={modalContent?.isAccountLevel}
              setLoadingMessage={setLoadingMessage}
              setModalLoading={setModalLoading}
              setModalTitle={setModalTitle}
            />
          )}
        </div>
      </Modal>
    </>
  )
}
