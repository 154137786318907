import { Box, Button, Typography } from '@mui/material'
import { green, grey } from '@mui/material/colors'
import { useOverflowDetection } from '../../../hooks/useOverflowDetection'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import React, { FC, ReactNode, useRef, useState } from 'react'
import WithTooltip from '../../WithTooltip'
import useStickyColumnWidths from '../../../hooks/useStickyColumnWidths'

// types

type _ColumnHeaderProps = {
  children: ReactNode
  draggable?: boolean
  handleColumnSwap: any
  hasCheckboxRow: boolean
  id: string
  idx: number
  isResizing: boolean
  loading?: boolean
  setActiveDragColumnIdxs: any
  sortDirection?: _SortDirection
  sortFunction?: () => void
  width?: number | string
}

enum _SortDirection {
  ASCENDING = 1,
  DESCENDING = -1,
  NONE = 0
}

// components

export const ColumnHeader: FC<_ColumnHeaderProps> = ({
  children,
  draggable,
  handleColumnSwap,
  hasCheckboxRow,
  id,
  idx,
  isResizing,
  loading,
  setActiveDragColumnIdxs,
  sortDirection = _SortDirection.NONE,
  sortFunction,
  width
}) => {
  const displayValueRef = useRef(null)
  const { isOverflowingHorizontally } = useOverflowDetection(displayValueRef)
  const [isDragOver, setIsDragOver] = useState(false)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const isSortable = Boolean(sortFunction)
  const isSorted = sortDirection !== _SortDirection.NONE

  useStickyColumnWidths({ id, width, isResizing })

  return (
    <>
      {idx === 0 && hasCheckboxRow ? (
        children // Fixes an issue where the top left checkbox styling was off due to being wrapped in spans.
      ) : (
        <WithTooltip content={isOverflowingHorizontally ? children?.toString() : undefined} visible={isTooltipVisible}>
          <Button
            aria-label={`${isSortable ? 'Sort by' : ''} ${children?.toString()}`}
            draggable={loading ? false : draggable}
            onBlur={() => setIsTooltipVisible(false)}
            onClick={sortFunction}
            onDragEnd={handleColumnSwap}
            onDragLeave={() => setIsDragOver(false)}
            onDragOver={() => {
              if (draggable && isDragOver === false) {
                setIsDragOver(true)
                setActiveDragColumnIdxs((prev: string[]) => [prev[0], idx])
              }
            }}
            onDragStart={() => setActiveDragColumnIdxs([idx, null])}
            onFocus={() => setIsTooltipVisible(true)}
            onMouseOut={event => event.currentTarget.blur()}
            sx={{
              color: '#000',
              borderRadius: 0,
              borderLeft: isDragOver ? '2px solid #000' : 'initial',
              cursor: isSortable ? 'pointer' : 'default',
              justifyContent: 'flex-start',
              m: '1px',
              p: '6px 11px',
              width: 'calc(100% - 2px)',
              '&:focus, &:hover': { backgroundColor: 'transparent', '& > .MuiBox-root': { opacity: 1 } }
            }}
            title={isOverflowingHorizontally ? children?.toString() : undefined}
          >
            <Typography
              component="span"
              ref={displayValueRef}
              // Adding `pr: 1px` ensures the `isOverflowingHorizontally` check works as expected.
              sx={{ fontSize: 14, fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis', pr: '1px' }}
            >
              {children}
            </Typography>

            {isSortable && (
              <Box sx={{ alignItems: 'center', color: grey[600], display: 'inline-flex', ml: 1, opacity: isSorted ? 1 : 0 }}>
                <ArrowDownwardIcon
                  sx={{
                    color: sortDirection === _SortDirection.ASCENDING ? green[500] : grey[600],
                    display: sortDirection === _SortDirection.DESCENDING ? 'none' : 'inline',
                    height: 14,
                    width: 14
                  }}
                />
                <ArrowUpwardIcon
                  sx={{
                    color: sortDirection === _SortDirection.DESCENDING ? green[500] : grey[600],
                    display: sortDirection === _SortDirection.ASCENDING ? 'none' : 'inline',
                    height: 14,
                    width: 14
                  }}
                />
              </Box>
            )}
          </Button>
        </WithTooltip>
      )}
    </>
  )
}
