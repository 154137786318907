import React, { CSSProperties } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

export default function Loader({
  size,
  variant,
  ...rest
}: {
  isFullPage?: boolean
  size?: 'xs' | 's' | 'm' | 'l'
  style?: CSSProperties
  variant?: 'light' | 'dark'
}) {
  return (
    <div className={css.wrapper} {...rest}>
      <div className={clsx(css[size || 'm'], variant === 'light' && css.light)} />
    </div>
  )
}
