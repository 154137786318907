import * as queryString from 'query-string'
import { AdvancedSearch } from '../components/AdvancedSearch'
import { Box } from '@mui/material'
import { Column } from '../reducers/dashboardQueryReducer'
import { Features, Permissions, useUserAccess } from '../hooks/useUserAccess'
import { RuleGroupType } from 'react-querybuilder'
import { encodeQuery, generateColumnsToSave } from '../utils/dashboardQueryUtils'
import { useHistory } from 'react-router-dom'
import React, { Dispatch, FC, SetStateAction } from 'react'
import Skeleton from 'react-loading-skeleton'
import useDashboardQString from '../hooks/useDashboardQString'
import useDocumentDashboardFields from '../hooks/useDocumentDashboardFields'
import useIsRapid7LegalTeam from '../hooks/useIsRapid7LegalTeam'

// types

type _DocumentSearchContainerProps = {
  clearAll: boolean
  filteredCount: number
  selectedColumns: Column[]
  setClearAll: Dispatch<SetStateAction<boolean>>
  totalCount: number
}

// components

export const DocumentSearchContainer: FC<_DocumentSearchContainerProps> = ({ clearAll, filteredCount, selectedColumns, setClearAll, totalCount }) => {
  const history = useHistory()
  const { fields, loading } = useDocumentDashboardFields()
  const { queryBuilderQuery } = useDashboardQString()
  const hasDealAccess = useUserAccess({ feature: Features.DEAL_DASHBOARD, permission: Permissions.READ })
  const isRapid7LegalTeam = useIsRapid7LegalTeam()

  const handleSearch = (query?: RuleGroupType | null) => {
    if (!query) return history.push(hasDealAccess && !isRapid7LegalTeam ? '/dashboard/documents' : '/')

    // This takes the currently selected columns, adds any columns relevant to the search, and then formats them to be stored in the query string.
    const cols = generateColumnsToSave(query, selectedColumns)
    history.push({ search: queryString.stringify({ cols, q: encodeQuery(query) }, { arrayFormat: 'comma' }) })
  }

  return loading ? (
    <Box sx={{ alignItems: 'center', display: 'flex', height: 38 }}>
      <Skeleton width={300} />
    </Box>
  ) : (
    <AdvancedSearch
      clearAll={clearAll}
      fields={fields}
      filteredCount={filteredCount}
      handleSearch={handleSearch}
      isDealsDashboard={false}
      query={queryBuilderQuery}
      recordType="document"
      selectedColumns={selectedColumns}
      setClearAll={setClearAll}
      totalCount={totalCount}
    />
  )
}
