import * as Yup from 'yup'
import { Box, Button, ButtonBase, FormControl, IconButton, MenuItem, Popover, TextField, Typography } from '@mui/material'
import { CSS } from '@dnd-kit/utilities'
import { DndContext } from '@dnd-kit/core'
import { Error } from '../../../../../components/Utils'
import { FragmentsInput } from './FragmentsInput'
import { Opening, useOpening } from '@hoologic/use-opening'
import { SortableContext, arrayMove, horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { TableCompositionConfigEdge, TableCompositionConfigInput } from '../../../../../../../graphql/codegen/schemas'
import { common, grey } from '@mui/material/colors'
import { createValueLabelMap } from '../../../../../../../utils/cci'
import { isEqual, size } from 'lodash'
import { useCciChecklistGptContext } from '../CCI_RightPanel_ChecklistGptTab'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useContextInit } from '../../../../../../../hooks/useContextInit'
import { useFormik } from 'formik'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import React, { Dispatch, FC, SetStateAction, createContext, useCallback, useEffect, useMemo, useState } from 'react'

/* `isNew` indicates that the column has not been submitted to the backend yet.
 *  An `isNew` column can be edited or deleted.
 * `isNew` columns have a white background (gray otherwise).
 *  Columns without the `isNew` property can still be reordered but cannot be edited or deleted. */

// types

type _AddEditColumnProps = { column?: _TableCompositionColumn; index?: number }
type _AddEditPopoverProps = { column?: _TableCompositionColumn; index?: number; opening: Opening }

type _Context = {
  columns: TableCompositionConfigInput[]
  setColumns: Dispatch<SetStateAction<TableCompositionConfigInput[]>>
}

export type _TableCompositionColumn = { column_name: string; column_type: string; isNew?: true }

enum TableCompositionFieldTypeLabels {
  BOOLEAN = 'Boolean',
  CURRENCY = 'Currency',
  DATE = 'Date',
  DURATION = 'Duration',
  FLOAT = 'Number – Decimal',
  NUMBER = 'Number – Non-decimal',
  PERCENTAGE = 'Number – Percentage',
  TEXT = 'Text'
}

enum TableCompositionFieldTypes {
  BOOLEAN = 'BOOLEAN',
  CURRENCY = 'CURRENCY',
  DATE = 'DATE',
  DURATION = 'DURATION',
  FLOAT = 'FLOAT',
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  TEXT = 'TEXT'
}

// constants

const COLUMN_EFFECT_HEIGHT = 1.5

const HORIZONTAL_SCROLLBAR_SX = {
  '::-webkit-scrollbar': {
    '-webkit-appearance': 'none',
    ':horizontal': { height: 11 },
    '&-thumb': { bgcolor: grey[500], border: '2px solid white', borderRadius: 2 },
    '&-track': { bgcolor: common.white, borderRadius: 2 }
  }
}

export const TABLE_COMPOSITION_FIELD_TYPE_LABELS = createValueLabelMap(TableCompositionFieldTypes, TableCompositionFieldTypeLabels)

// context

const Context = createContext<_Context | null>(null)

// hooks

const useLocalContext = () => useContextInit(Context)

// components

const AddEditColumn: FC<_AddEditColumnProps> = ({ column, index }) => {
  const { isEditView } = useCciChecklistGptContext()
  const { columns } = useLocalContext()
  const opening = useOpening()

  const isSortableDisabled = useMemo(() => !column || size(columns) < 2 || opening.isOpen, [column, columns, opening.isOpen])
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ disabled: isSortableDisabled, id: JSON.stringify(column) })

  const isAdd = useMemo(() => !column, [column])

  const columnSx = useMemo(() => {
    const rightBorder = { bgcolor: grey[700], content: '""', position: 'absolute', right: -1, top: -1, bottom: 0, width: '1px', zIndex: 2 }

    // TODO – Asana task #1201687534408127 (1 of 2) – remove `mb: 2` from the following object after allowing CCI edits. */}

    return {
      bgcolor: common.white,
      border: `1px solid ${grey[400]}`,
      borderBottomWidth: 0,
      borderRightWidth: 0,
      display: 'inline-block',
      mb: 2,
      pb: COLUMN_EFFECT_HEIGHT,
      position: 'relative',
      verticalAlign: 'top',
      ...(!isSortableDisabled && { ':hover .MuiSvgIcon-root': { visibility: 'visible' } }),
      ':hover, :hover > :last-child': { borderColor: grey[700] },
      ...(isAdd || column?.isNew ? { cursor: 'pointer' } : { bgcolor: grey[100] }),
      ...(!isDragging && { ':hover::after': rightBorder }),
      ...(isDragging && { zIndex: 1 }),
      ...(opening.isOpen && { '&, & > :last-child': { borderColor: grey[700] }, '::after': rightBorder })
    }
  }, [isAdd, column, isDragging, isSortableDisabled, opening.isOpen])

  const dragIndicatorSx = useMemo(
    () => ({
      color: grey[500],
      ':hover': { color: common.black },
      cursor: isDragging ? 'grabbing' : 'grab',
      fontSize: 18,
      left: 3,
      position: 'absolute',
      top: 5,
      transition: 'color 0.2s',
      visibility: 'hidden'
    }),
    [isDragging]
  )

  const isEdit = useMemo(() => !isAdd, [isAdd])
  const style = useMemo(() => ({ transform: CSS.Translate.toString(transform), transition: transition || undefined }), [transform, transition])

  return (
    <>
      <ButtonBase
        aria-label={isEdit ? 'Edit column' : 'Add column'}
        onClick={isAdd || column?.isNew ? opening.toggle : undefined}
        ref={setNodeRef}
        style={style}
        sx={columnSx}
      >
        {isEdit ? (
          <Box sx={{ position: 'relative', px: 3, py: 0.5 }}>
            <DragIndicatorIcon onClick={event => event.stopPropagation()} sx={dragIndicatorSx} {...attributes} {...listeners} />

            <>
              <Typography sx={{ fontSize: 14 }}>{(column as _TableCompositionColumn).column_name}</Typography>

              <Typography sx={{ color: grey[400], fontSize: 12 }}>
                {TABLE_COMPOSITION_FIELD_TYPE_LABELS.get((column as _TableCompositionColumn).column_type)}
              </Typography>
            </>
          </Box>
        ) : (
          <Box sx={{ pl: 3.75, position: 'relative', pr: 2, py: 0.5, ...(isEditView && { bgcolor: grey[100], borderRight: `1px solid ${grey[400]}` }) }}>
            <AddIcon sx={{ fontSize: 20, left: 8, position: 'absolute' }} />

            <Typography sx={{ fontSize: 14 }}>Column</Typography>
          </Box>
        )}

        <Box sx={{ borderTop: `1px solid ${grey[400]}`, transition: 'border-color 0.1s', ...(isAdd && { mb: 2.25 }) }} />
      </ButtonBase>

      {opening.isOpen && <AddEditPopover column={column} index={index} opening={opening} />}
    </>
  )
}

const AddEditPopover: FC<_AddEditPopoverProps> = ({ column, index, opening }) => {
  const { columns, setColumns } = useLocalContext()

  const { errors, getFieldProps, handleSubmit, initialValues, values } = useFormik<_TableCompositionColumn>({
    initialValues: { column_name: column?.column_name || '', column_type: column?.column_type || '', isNew: true },

    onSubmit: values => {
      setColumns(current => (isEdit ? [...current.slice(0, index), values, ...current.slice((index as number) + 1)] : [...current, values]))

      opening.close()
    },

    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      column_name: Yup.string()
        .trim()
        .required('Required')
        .test('isUnique', 'Column name already exists', value => !columns.some(column => column.column_name === value)),
      column_type: Yup.string().required('Required')
    })
  })

  const fieldMenuItems = useMemo(
    () =>
      Object.entries(TableCompositionFieldTypes)
        .map(([key, value]) => (
          <MenuItem key={value} sx={{ fontSize: 14 }} value={value}>
            {TABLE_COMPOSITION_FIELD_TYPE_LABELS.get(key)}
          </MenuItem>
        ))
        .sort((a, b) => a.props.children.localeCompare(b.props.children)),
    []
  )

  const isAdd = useMemo(() => !column, [column])
  const isEdit = useMemo(() => !isAdd, [isAdd])

  const handleDelete = useCallback(() => {
    setColumns(current => [...current.slice(0, index), ...current.slice((index as number) + 1)])

    opening.close()
  }, [index, opening, setColumns])

  return (
    <Popover
      anchorEl={opening.anchor}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={opening.close}
      open={opening.isOpen}
      sx={{ top: -(COLUMN_EFFECT_HEIGHT * 8), '.MuiPaper-root': { borderRadius: 0 } }}
    >
      <FormControl autoComplete="off" component="form" onSubmit={handleSubmit} sx={{ p: 2, pt: 2.5 }}>
        <TextField
          {...getFieldProps('column_name')}
          InputLabelProps={{ sx: { fontSize: 14 } }}
          InputProps={{ sx: { fontSize: 14, width: 300 } }}
          autoFocus={isAdd}
          label="Column name (required)"
          size="small"
          sx={{ display: 'block' }}
        />

        <Error message={errors.column_name} />

        <TextField
          {...getFieldProps('column_type')}
          InputLabelProps={{ sx: { fontSize: 14 } }}
          InputProps={{ sx: { fontSize: 14, width: 300 } }}
          SelectProps={{ displayEmpty: true }}
          label="Field type (required)"
          select
          size="small"
          sx={{ display: 'block', mt: 2 }}
        >
          {fieldMenuItems}
        </TextField>

        <Error message={errors.column_type} />

        <Box sx={{ display: 'flex', justifyContent: isEdit ? 'space-between' : 'flex-end', mt: 2 }}>
          {isEdit && (
            <IconButton
              aria-label="Delete column"
              color="error"
              onClick={handleDelete}
              size="small"
              sx={{ ':not(:hover)': { bgcolor: grey[100], filter: 'grayscale(100%)', opacity: 0.5 } }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          )}

          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button color="tertiary" onClick={opening.close} size="small" sx={{ bgcolor: 'white' }} variant="outlined">
              Cancel
            </Button>

            <Button color="primary" disabled={isEdit && isEqual(values, initialValues)} size="small" type="submit" variant="contained">
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </Box>
        </Box>
      </FormControl>
    </Popover>
  )
}

export const TableCompositionInput: FC = () => {
  const { updateFieldValue } = useCciChecklistGptContext()
  const { selectedItem } = useCciMainContext()

  const savedColumns = useMemo<TableCompositionConfigInput[]>(
    () =>
      (selectedItem?.table_composition_config?.edges as TableCompositionConfigEdge[])?.map(({ node }) => ({
        column_name: node?.column_name || '',
        column_type: node?.column_type || ''
      })) || [],
    [selectedItem?.table_composition_config?.edges]
  )
  const [columns, setColumns] = useState(savedColumns)

  const items = useMemo(() => columns.map(column => JSON.stringify(column)), [columns])

  const handleDragEnd = useCallback(
    ({ active, over }) => {
      if (active.id && over?.id) {
        setColumns(current =>
          arrayMove(
            current,
            current.findIndex(column => JSON.stringify(column) === active.id),
            current.findIndex(column => JSON.stringify(column) === over.id)
          )
        )
      }
    },
    [setColumns]
  )

  const context = useMemo<_Context>(() => ({ columns, setColumns }), [columns])

  useEffect(() => {
    if (!isEqual(columns, savedColumns)) {
      updateFieldValue(
        'table_composition_config',
        columns.map(({ column_name, column_type }) => ({ column_name, column_type }))
      )
    }
  }, [columns, savedColumns, updateFieldValue])

  return (
    <Context.Provider value={context}>
      <Typography sx={{ fontSize: 14, fontWeight: 700, mt: 2.5 }}>Which columns would you like to include in this table?</Typography>

      <Typography sx={{ fontSize: 12, mb: 1.5 }}>Add columns in the order you’d like them to appear.</Typography>

      {/* TODO – Asana task #1201687534408127 (2 of 2) – add `whiteSpace: 'nowrap'`, and remove `mb: -2`, to the `sx` property below after allowing CCI edits. */}

      <Box sx={{ overflowX: 'auto', mb: -2, pb: 1, ...HORIZONTAL_SCROLLBAR_SX }}>
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext items={items} strategy={horizontalListSortingStrategy}>
            {columns.map((column, index) => (
              <AddEditColumn column={column} index={index} key={column.column_name} />
            ))}

            <AddEditColumn />
          </SortableContext>
        </DndContext>
      </Box>

      <FragmentsInput />
    </Context.Provider>
  )
}
