import { Box, Typography } from '@mui/material'
import { FieldTypeLabels, FieldTypes } from '../CreateInputs/FieldTypeInput'
import { getFieldTypeLabel } from '../../../../../../../utils/cci'
import { sortBy } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

export default function FieldTypeInput({
  currentItemId,
  currentValue,
  handleData,
  isReadOnly = false,
  selectedValue
}: {
  currentItemId?: string
  currentValue?: string
  handleData: any
  isReadOnly?: boolean
  selectedValue?: string
}) {
  const fieldTypeOptions = useMemo(
    () =>
      sortBy(
        Object.entries(FieldTypes).map(([key, value]) => ({
          label: FieldTypeLabels[key as keyof typeof FieldTypes],
          value
        })),
        'label'
      ),
    []
  )

  const handleChange = (item: any) => {
    if (item?.value !== 'DATE' && currentValue === 'DATE') {
      handleData('value_format', '@@_DELETE_THIS_ITEM')
    } else {
      handleData('value_format', null)
    }
    if (!item?.value || item?.value === currentValue) {
      handleData('field_type', null)
    } else {
      handleData('field_type', item?.value)
    }
    handleData('default_value', null)
  }

  useEffect(() => {
    handleData('field_type', null)
    handleData('value_format', null)
    handleData('default_value', null)
    // eslint-disable-next-line
    }, [currentItemId, currentValue])

  if (isReadOnly) {
    return (
      <Box display="flex" mb={1}>
        <Typography fontSize={14} fontWeight={600} width={180}>
          Field Type
        </Typography>

        <Typography fontSize={14}>{getFieldTypeLabel(selectedValue, currentValue)}</Typography>
      </Box>
    )
  }

  return (
    <div className={css.inputWrapper}>
      <Typography component="label" htmlFor="field-type-select-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        Field Type
      </Typography>

      <SelectInput
        id="field-type-select-input"
        isClearable
        onChange={(item: any) => handleChange(item)}
        options={fieldTypeOptions}
        placeholder={getFieldTypeLabel(selectedValue, currentValue)}
        value={selectedValue}
      />
    </div>
  )
}
