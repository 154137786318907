import { Box, IconButton, Tooltip } from '@mui/material'
import { NotesTabCountDocument } from '../../../graphql/codegen/hooks'
import { createComment } from '../../../graphql/mutations/comment.graphql'
import { grey } from '@mui/material/colors'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { usePopper } from 'react-popper'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import CommentSection from '../../../components/CommentSection'
import React, { FC, ReactNode, useRef, useState } from 'react'
import css from '../style.module.scss'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import type { DataPoint } from '../../../graphql/codegen/schemas'

// types

type _CommentButtonProps = { dataPoint: DataPoint; dealIsFinalized: boolean; isNestedChildDataPoint?: boolean }

type _PopperWrapperProps = { children: ReactNode; commentCount?: number; tabIndex?: number }

// components

export const CommentButton: FC<_CommentButtonProps> = ({ dataPoint, dealIsFinalized, isNestedChildDataPoint }) => {
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const [createCommentMutation, commentState] = useMutation(createComment, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: NotesTabCountDocument, variables: { resourceId: dealId || documentId! } }]
  })

  const handleCreate = (text: string) => createCommentMutation({ variables: { dataPointId: dataPoint.id, text } })

  // @ts-ignore
  const comments = dataPoint?.comments?.length > 0 ? [...dataPoint?.comments] : []
  const sortedComments = comments.sort((a: any, b: any) => (new Date(a.created_at) > new Date(b.created_at) ? 1 : -1))

  return (
    <>
      {(!dealIsFinalized || sortedComments?.length > 0) && (
        <PopperWrapper commentCount={dataPoint?.comments?.length} tabIndex={isNestedChildDataPoint ? -1 : 0}>
          <CommentSection
            commentState={commentState}
            comments={sortedComments}
            dataPoint={dataPoint}
            dealIsFinalized={dealIsFinalized || false}
            handleCreate={dealIsFinalized ? undefined : handleCreate}
            isNestedChildDataPoint={isNestedChildDataPoint}
          />
        </PopperWrapper>
      )}
    </>
  )
}

const PopperWrapper: FC<_PopperWrapperProps> = ({ children, commentCount, tabIndex = 0 }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [isVisible, setIsVisible] = useState(false)

  useOnClickOutside(ref, () => setIsVisible(false))

  const { attributes, styles } = usePopper(ref.current, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    placement: 'right-start',
    strategy: 'fixed'
  })

  return (
    <Tooltip arrow placement="top" title={commentCount ? 'View/add comments' : 'Add a comment'}>
      <Box ref={ref} sx={{ alignItems: 'center', display: 'flex' }}>
        <IconButton
          aria-label="Comments"
          onClick={() => setIsVisible(previous => !previous)}
          onKeyUp={event => {
            if (event.key === 'Escape') {
              setIsVisible(false)
            }
          }}
          sx={{
            alignItems: 'center',
            borderRadius: 0.5,
            color: grey[900],
            display: 'flex',
            gap: 0.25,
            height: 20,
            justifyContent: 'center',
            p: 0,
            '&:focus, &:hover': { background: 'none' }
          }}
          tabIndex={tabIndex}
        >
          {Boolean(commentCount) && <span style={{ fontSize: 12 }}>{commentCount}</span>}

          <ChatBubbleOutlineOutlinedIcon />
        </IconButton>

        {isVisible && (
          <div className={css.commentPopover} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {children}
          </div>
        )}
      </Box>
    </Tooltip>
  )
}
