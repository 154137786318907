import { MenuItem, TextField } from '@mui/material'
import { grey } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import { useChecklistQueryLazyQuery } from '../../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import React, { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import useCurrentUser from '../../hooks/useCurrentUser'

// types

type _ChecklistQueriesProps = {
  isDisabled: boolean
  setFilteredDataPointIdList: Dispatch<SetStateAction<string[] | null>>
}

// components

export const ChecklistQueries: FC<_ChecklistQueriesProps> = ({ isDisabled, setFilteredDataPointIdList }) => {
  const currentUser = useCurrentUser()
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()

  const [checklistQueryQuery, { data: checklistQueryData, loading: isChecklistQueryLoading }] = useChecklistQueryLazyQuery({
    fetchPolicy: 'network-only'
  })

  const [checklistQueryId, setChecklistQueryId] = useState('')

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checklistQueryId = event.target.value

      if (checklistQueryId) {
        checklistQueryQuery({ variables: { checklistQueryId, dealId, documentId } })
      }

      setChecklistQueryId(event.target.value)
    },
    [checklistQueryQuery, dealId, documentId]
  )

  useEffect(() => {
    if (checklistQueryData && !isChecklistQueryLoading) {
      setFilteredDataPointIdList(checklistQueryId ? checklistQueryData.checklist_query.data_point_ids : null)
    }
  }, [checklistQueryData, checklistQueryId, isChecklistQueryLoading, setFilteredDataPointIdList])

  if (!currentUser?.checklist_queries || isEmpty(currentUser.checklist_queries)) return null

  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      disabled={isChecklistQueryLoading || isDisabled}
      label="Filter"
      onChange={handleChange}
      select
      size="small"
      sx={{
        flex: 1,
        '& .MuiInputBase-input': { py: 0.9375 },
        '& .MuiInputBase-root': { color: grey[600], fontSize: 13, minHeight: 'auto', minWidth: 75, maxWidth: 150 },
        '& .MuiOutlinedInput-notchedOutline': { borderColor: grey[300] }
      }}
      value={checklistQueryId}
    >
      <MenuItem sx={{ fontSize: 13 }} value="">
        <em>None</em>
      </MenuItem>

      {currentUser.checklist_queries.map(checklistQuery => (
        <MenuItem key={checklistQuery.query_id} sx={{ fontSize: 13 }} value={checklistQuery.query_id}>
          {checklistQuery?.query_name}
        </MenuItem>
      ))}
    </TextField>
  )
}
