import { ActiveComponents, useCciMainContext } from '../../../../CCI_Main'
import { CreateField } from './CreateField'
import { EditDocumentTypes } from './EditDocumentTypes'
import { EditField } from './EditField'
import { Features, Permissions, useUserAccess } from '../../../../../../hooks/useUserAccess'
import { useCciDocumentTypesLazyQuery, useCciGroupsLazyQuery } from '../../../../../../graphql/codegen/hooks'
import { useContextInit } from '../../../../../../hooks/useContextInit'
import React, { FC, createContext, useEffect, useMemo, useState } from 'react'
import ViewField from './ViewField'

// types

type _CciChecklistContext = {
  docTypesLoading: boolean
  documentTypes?: any
  groupOptions?: any
  groupsLoading: boolean
}

export type _CurrentExtractionMethod = {
  description?: string
  extraction_method_id: string | undefined
  extraction_method_instance_id: string
  is_enabled: boolean
}

// context

export const CciChecklistContext = createContext<_CciChecklistContext | null>(null)

// hooks

export const useCciChecklistContext = () => useContextInit(CciChecklistContext)

// components

export const CCI_RightPanel_ChecklistTab_Main: FC = () => {
  const { activeComponent } = useCciMainContext()
  const [groupOptions, setGroupOptions] = useState<any>(false)
  const [documentTypes, setDocumentTypes] = useState<any>(false)
  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })
  const hasLimitedEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD_LIMITED })

  const [getGroups, { data: groupData, loading: groupsLoading }] = useCciGroupsLazyQuery()
  const [getDocumentTypes, { data: docTypesData, loading: docTypesLoading }] = useCciDocumentTypesLazyQuery()

  useEffect(() => {
    if (hasFullEditAccess || hasLimitedEditAccess) {
      getGroups()
      getDocumentTypes()
    }
  }, [hasFullEditAccess, hasLimitedEditAccess, getGroups, getDocumentTypes])

  useEffect(() => {
    setGroupOptions(
      groupData?.cci_groups?.map(item => {
        return { value: item, label: item }
      })
    )
  }, [groupData])

  // onCompleted for getDocumentTypes refused to work, so went with this useEffect instead.
  useEffect(() => {
    setDocumentTypes(
      docTypesData?.cci_document_types?.map((item: any) => {
        return { label: item?.name, value: item?.name }
      })
    )
  }, [docTypesData])

  const context = useMemo<_CciChecklistContext>(
    () => ({ docTypesLoading, documentTypes, groupOptions, groupsLoading }),
    [docTypesLoading, documentTypes, groupOptions, groupsLoading]
  )

  return (
    <CciChecklistContext.Provider value={context}>
      {(!activeComponent || activeComponent === ActiveComponents.DELETE_FIELD || activeComponent === ActiveComponents.EDIT_FIELD) && (
        <>{hasFullEditAccess || hasLimitedEditAccess ? <EditField /> : <ViewField />}</>
      )}

      {activeComponent === ActiveComponents.CREATE_FIELD && <CreateField />}

      {activeComponent === ActiveComponents.EDIT_DOCUMENT_TYPES && <EditDocumentTypes />}
    </CciChecklistContext.Provider>
  )
}
