import { DealsTab } from './DealsTab'
import { DocumentsTab } from './DocumentsTab'
import { Features, Permissions, useIsAnnotator, useUserAccess } from '../../hooks/useUserAccess'
import { InboxTab } from './InboxTab'
import { SearchTab } from './SearchTab/SearchTab'
import { SemanticSearchTab } from './SearchTab/SemanticSearchTab'
import { SemanticSearchV2Tab } from './SearchTab/SemanticSearchV2Tab'
import { Tabs } from '../../components/Tabs'
import { deepPurple, purple } from '@mui/material/colors'
import { getAnnotatorGreeting } from '../../utils/annotatorUtils'
import { useCurrentUserQuery } from '../../graphql/codegen/hooks'
import { useModalContext } from '../../app'
import { useParams } from 'react-router-dom'
import AttachmentsTab from './AttachmentsTab'
import DownloadBulkJob from '../../components/ModalOptions/DownloadBulkJob'
import Helmet from 'react-helmet'
import React, { FC, useEffect, useState } from 'react'
import useIsRapid7LegalTeam from '../../hooks/useIsRapid7LegalTeam'

// types

type _TabInterface = {
  component: FC
  path: string
  title: string
}

// components

export const Dashboard = () => {
  const { openPreview } = useModalContext()
  const [tabs, setTabs] = useState<_TabInterface[]>([])
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const { download_artifact_uuid, previewId } = useParams<{ download_artifact_uuid: string; previewId: string }>()

  useEffect(() => {
    if (download_artifact_uuid) {
      setIsDownloadModalOpen(true)
    }
  }, [download_artifact_uuid])

  useEffect(() => {
    if (previewId) {
      openPreview(previewId, true)
    }
    // eslint-disable-next-line
  }, [previewId])

  const hasDealAccess = useUserAccess({ feature: Features.DEAL_DASHBOARD, permission: Permissions.READ })
  const hasDocAccess = useUserAccess({ feature: Features.DOCUMENT_DASHBOARD, permission: Permissions.READ })
  const hasAttachmentAccess = useUserAccess({ feature: Features.ATTACHMENTS_DASHBOARD, permission: Permissions.READ })
  const hasSemanticSearchAccess = useUserAccess({ feature: Features.SEMANTIC_SEARCH, permission: Permissions.READ })
  const hasSemanticSearchV2Access = useUserAccess({ feature: Features.SEMANTIC_SEARCH_V2, permission: Permissions.READ })
  const hasTextSearchAccess = useUserAccess({ feature: Features.TEXT_SEARCH, permission: Permissions.READ })
  const isAnnotator = useIsAnnotator()
  const { data } = useCurrentUserQuery()
  const { customers, first_name, roles } = data?.current_user || {}
  const roleList = roles?.edges.map(r => r?.node?.name).join(', ')
  const customer = customers?.edges[0]?.node
  const isRapid7LegalTeam = useIsRapid7LegalTeam()

  useEffect(() => {
    const localTabs = []
    if (isAnnotator) {
      localTabs.push({ title: 'Inbox', component: InboxTab, path: '/' })
      if (hasDealAccess) {
        localTabs.push({ title: 'Deals', component: DealsTab, path: '/dashboard/deals' })
      }
      if (hasDocAccess) {
        localTabs.push({ title: 'Documents', component: DocumentsTab, path: '/dashboard/documents' })
      }
    } else {
      if (isRapid7LegalTeam) {
        // Hardcoded feature for this task: https://app.asana.com/0/0/1200501099177444/f
        if (hasDealAccess) {
          localTabs.push({ title: 'Deals', component: DealsTab, path: '/dashboard/deals' })
        }
        if (hasDocAccess) {
          localTabs.push({ title: 'Documents', component: DocumentsTab, path: '/' })
        }
      } else {
        if (hasDealAccess) {
          localTabs.push({ title: 'Deals', component: DealsTab, path: '/' })
        }
        if (hasDocAccess) {
          localTabs.push({ title: 'Documents', component: DocumentsTab, path: hasDealAccess ? '/dashboard/documents' : '/' })
        }
      }
    }
    if (hasAttachmentAccess) {
      localTabs.push({ title: 'Attachments', component: AttachmentsTab, path: '/dashboard/attachments' })
    }

    if (hasSemanticSearchAccess || hasSemanticSearchV2Access || hasTextSearchAccess) {
      const component = hasSemanticSearchV2Access ? SemanticSearchV2Tab : hasSemanticSearchAccess ? SemanticSearchTab : SearchTab

      localTabs.push({ component, path: '/dashboard/search', title: 'Search' })
    }

    setTabs([...localTabs])
  }, [
    hasAttachmentAccess,
    hasDealAccess,
    hasDocAccess,
    hasSemanticSearchAccess,
    hasSemanticSearchV2Access,
    hasTextSearchAccess,
    isAnnotator,
    isRapid7LegalTeam
  ])

  return (
    <>
      <Helmet>
        <title>Dashboard | Klarity</title>
      </Helmet>

      {isAnnotator && (
        <section style={{ padding: '24px', lineHeight: 1.8 }}>
          <h1 style={{ fontSize: 24 }}>
            {getAnnotatorGreeting()}, {first_name}
          </h1>

          <div>
            <b style={{ color: purple.A700 }}>{customer?.name}</b>
          </div>

          {roles && (
            <div>
              <b style={{ color: deepPurple.A400 }}>{roleList}</b>
            </div>
          )}
        </section>
      )}

      <Tabs hasDealAccess={hasDealAccess} tabs={tabs} />

      <DownloadBulkJob isOpen={isDownloadModalOpen} setIsOpen={setIsDownloadModalOpen} />
    </>
  )
}
