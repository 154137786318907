import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default ({ id, isResizing, width }: { id: string; isResizing: boolean; width?: number | string }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (isResizing && id) {
      const key = `${localStorage.getItem('customerId')}__COLUMN_WIDTHS${pathname}`
      const prev = getStickyColumnWidths(pathname)

      localStorage.setItem(key, JSON.stringify({ ...prev, [id]: width }))
    }
  }, [width, id, isResizing, pathname])
}

// Helper so these can be retrieved separately
export function getStickyColumnWidths(pathname: string) {
  const key = `${localStorage.getItem('customerId')}__COLUMN_WIDTHS${pathname}`

  return JSON.parse(localStorage.getItem(key) || '{}')
}
