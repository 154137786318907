import { DraggableContainer, OverflowWrapper } from '../dnd-components'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import RModal, { Styles } from 'react-modal'
import React, { ReactNode } from 'react'
import css from './style.module.scss'
//  (http://reactcommunity.org/react-modal/accessibility/)
RModal.setAppElement('#revrec-app-root')

export interface ModalProps {
  children?: ReactNode
  isDraggable?: boolean
  isOpen: boolean
  onRequestClose?: () => void
  style?: Styles
  title?: string
}

export default function Modal({ children, isDraggable, isOpen, style, title, ...rest }: ModalProps) {
  if (isDraggable && isOpen) {
    return (
      <OverflowWrapper>
        <RModal className={css.draggableModal} isOpen={isOpen} overlayClassName={css.overlay} style={style} {...rest}>
          <DraggableContainer
            content={
              <span className={css.draggableModalContent}>
                {title && <h2 className={css.title}>{title}</h2>}
                {children}
              </span>
            }
            modifiers={[restrictToWindowEdges]}
          />
        </RModal>
      </OverflowWrapper>
    )
  }
  return (
    <RModal className={css.modal} isOpen={isOpen} overlayClassName={css.overlay} style={style} {...rest}>
      {title && <h2 className={css.title}>{title}</h2>}
      {children}
    </RModal>
  )
}

Modal.defaultProps = {
  isDraggable: false
}
