import { Box, Step, StepButton, Stepper, Tooltip, Typography } from '@mui/material'
import { ExtractionFieldTypes } from '../ChecklistTab/SharedInputs/ExtractionFieldTypeInput'
import { Features, Permissions, useUserAccess } from '../../../../../../hooks/useUserAccess'
import { common, grey } from '@mui/material/colors'
import { useCciChecklistGptContext } from './CCI_RightPanel_ChecklistGptTab'
import { useCciMainContext } from '../../../../CCI_Main'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import React, { FC } from 'react'

// enums

export enum Steps {
  CONFIGURATION_DETAILS,
  PREVIEW_WITH_SAMPLES
}

// constants

const STEP_LABEL_MAP: { [key in Steps]: string } = {
  [Steps.CONFIGURATION_DETAILS]: 'Configuration details',
  [Steps.PREVIEW_WITH_SAMPLES]: 'Preview with samples'
}

const STEP_NUMBER_LIST = Object.values(Steps).filter(value => typeof value === 'number') as number[]

// components

export const ConfigurationSteps: FC = () => {
  const { selectedItem } = useCciMainContext()
  const { activeStep, isCreateView, setActiveStep } = useCciChecklistGptContext()

  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })
  const isInternalSource = selectedItem?.source === 'INTERNAL'
  const isPreAnnotationExtractionType = Boolean(selectedItem?.extraction_field_type?.includes(ExtractionFieldTypes['PRE-ANNOTATION']))
  const isPreviewWithSamplesDisabled = isCreateView || !isInternalSource || isPreAnnotationExtractionType

  return (
    <Box
      sx={{
        alignItems: 'center',
        borderBottom: `1px solid ${grey[300]}`,
        display: 'flex',
        gap: 3,
        justifyContent: 'space-between',
        mb: 3,
        ml: -2.5,
        pb: 2.5,
        px: 2.5,
        width: 'calc(100% + 40px)'
      }}
    >
      <Typography noWrap sx={{ fontWeight: 600 }} variant="h6">
        {isCreateView ? 'Create New Field' : selectedItem.name}
      </Typography>

      <Stepper activeStep={activeStep} connector={<></>} nonLinear sx={{ flexShrink: 0, gap: 3 }}>
        {STEP_NUMBER_LIST.map((step: Steps) => {
          const disabledTooltip =
            step === Steps.PREVIEW_WITH_SAMPLES
              ? isCreateView
                ? 'Configuration details must be completed first'
                : !isInternalSource || isPreAnnotationExtractionType
                ? 'Samples are not available for this field'
                : ''
              : ''

          return (
            <Step key={step}>
              {step === Steps.PREVIEW_WITH_SAMPLES && isPreviewWithSamplesDisabled ? (
                <Tooltip arrow placement="top" title={disabledTooltip}>
                  <span>
                    <StepButton disabled sx={{ opacity: 0.5 }}>
                      {STEP_LABEL_MAP[step]}
                    </StepButton>
                  </span>
                </Tooltip>
              ) : (
                <StepButton onClick={() => setActiveStep(step)} sx={{ '&:hover .MuiStepLabel-labelContainer': { color: common.black } }}>
                  {STEP_LABEL_MAP[step]}

                  {step === Steps.CONFIGURATION_DETAILS && !hasFullEditAccess && (
                    <Tooltip arrow placement="top" title="User permissions limit editing of some fields.">
                      <InfoOutlined sx={{ fontSize: 16, ml: 0.5, opacity: 0.6 }} />
                    </Tooltip>
                  )}
                </StepButton>
              )}
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}
