import { UnregisterCallback } from 'history'
import { useCallback, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// https://gist.github.com/sibelius/60a4e11da1f826b8d60dc3975a1ac805
export default (when: boolean, message: string = 'Are you sure you want to leave without saving your changes?') => {
  const history = useHistory()
  const location = useLocation()

  const self = useRef<null | UnregisterCallback>(null)

  const onWindowOrTabClose = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!when) {
        return
      }

      if (event) {
        event.returnValue = message
      }

      return message
    },
    [message, when]
  )

  useEffect(() => {
    if (when) {
      self.current = history.block(nextLocation => {
        // Only block navigation when changing the pathname, not when the search or hash parameters change.
        if (location.pathname !== nextLocation.pathname) {
          return message
        }
      })
    } else {
      self.current = null
    }

    window.addEventListener('beforeunload', onWindowOrTabClose)

    return () => {
      if (self.current) {
        self.current()
        self.current = null
      }

      window.removeEventListener('beforeunload', onWindowOrTabClose)
    }
  }, [history, location, message, onWindowOrTabClose, when])
}
