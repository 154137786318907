import { Box, Container, ScopedCssBaseline } from '@mui/material'
import React, { ReactNode, StrictMode } from 'react'

// types

interface InnerLayoutProps {
  children: ReactNode
}

// components

export const InnerLayout = ({ children }: InnerLayoutProps) => (
  <StrictMode>
    <ScopedCssBaseline>
      <Box height="calc(100vh - 131.5px)" overflow="scroll" sx={{ backgroundColor: '#f3f4f8' }}>
        <Container maxWidth={false}>{children}</Container>
      </Box>
    </ScopedCssBaseline>
  </StrictMode>
)
