import { TextField } from '@mui/material'
import React, { Dispatch, FC, SetStateAction } from 'react'
import css from './style.module.scss'

// types

type _TypeToConfirmProps = { setConfirmed: Dispatch<SetStateAction<boolean>> }

// components

const TypeToConfirm: FC<_TypeToConfirmProps> = ({ setConfirmed }) => (
  <div className={css.inputRow}>
    <h4 style={{ whiteSpace: 'nowrap' }}>Type “confirm” to proceed:</h4>

    <TextField
      autoComplete="off"
      autoFocus
      inputProps={{ 'aria-label': "Type 'confirm' to proceed" }}
      onChange={event => setConfirmed(Boolean(event.target.value.match(/^confirm$/i)))}
      size="small"
    />
  </div>
)

export default TypeToConfirm
