import { CreateOrSelectNeutralTag } from '../../RightPanel/RightPanel_components/ChecklistTab/EditInputs/CreateOrSelectNeutralTag'
import { useCciMainContext } from '../../../CCI_Main'
import { useChangeNeutralTagMutation } from '../../../../../graphql/codegen/hooks'
import Button from '../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

export default function EditNeutralTag() {
  const { modalContent, setMessageTitle, setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess, setSelectedItem } = useCciMainContext()
  const [neutralTag, setNeutralTag] = useState<string | undefined>(modalContent?.oldTag)

  const [changeNeutralTag, { error: changeNeutralTagError, loading: changeNeutralTagLoading }] = useChangeNeutralTagMutation({
    onCompleted: response => {
      setSelectedItem(response.change_neutral_tag?.data_point_fields?.[0])

      setModalSuccess('Automation tag successfully updated.')
    }
  })

  useEffect(() => {
    if (changeNeutralTagLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Updating Automation Tag…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
  }, [changeNeutralTagLoading, setModalLoading, setModalLoadingMessage])

  useEffect(() => {
    if (changeNeutralTagError) {
      setMessageTitle('Error updating automation tag:')
      setModalError(changeNeutralTagError)
    }
  }, [changeNeutralTagError, setModalError, setMessageTitle])

  return (
    <>
      <div style={{ display: 'flex' }}>
        <h4 style={{ marginRight: '8px' }}>{`Field Name:`}</h4>
        <p>{modalContent?.fieldName}</p>
      </div>
      <div style={{ display: 'flex', marginBottom: '16px' }}>
        <h4 style={{ marginRight: '8px' }}>{`Current Automation Tag:`}</h4>
        <p>{modalContent?.oldTag}</p>
      </div>

      <h5 style={{ marginBottom: '6px' }}>Type a new Automation Tag:</h5>

      <CreateOrSelectNeutralTag neutralTag={neutralTag} setNeutralTag={setNeutralTag} />

      <ConfirmNeutralTagEdits changeNeutralTag={changeNeutralTag} fieldId={modalContent?.fieldId} neutralTag={neutralTag} oldTag={modalContent?.oldTag} />
    </>
  )
}

function ConfirmNeutralTagEdits({
  changeNeutralTag,
  fieldId,
  neutralTag,
  oldTag
}: {
  changeNeutralTag: any
  fieldId: string
  neutralTag: string | undefined
  oldTag?: string
}) {
  const { closeModal } = useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)

  const handleTagChange = () => {
    changeNeutralTag({
      variables: {
        data_point_field_id: fieldId,
        new_neutral_tag: neutralTag,
        old_neutral_tag: oldTag || undefined,
        change_other_fields: false,
        change_annotations: true
      }
    })
  }

  return (
    <>
      <TypeToConfirm setConfirmed={setConfirmed} />
      <div className={css.editNeutralTagButtonRow}>
        <Button onClick={closeModal} variant="tertiary">{`Cancel`}</Button>
        <WithTooltip
          content={!neutralTag ? 'No tag selected' : neutralTag === oldTag ? 'Cannot select the current tag' : !confirmed ? 'Type Confirm to proceed' : ''}
        >
          <Button disabled={!neutralTag || neutralTag === oldTag || !confirmed} onClick={handleTagChange} variant="primary">{`Update Automation Tag`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
