import { Box, Typography } from '@mui/material'
import { useFeedbackPageContext } from '../../pages/FeedbackPage'
import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

// components

export const FeedbackPageHeader: FC = () => {
  const { document } = useFeedbackPageContext()

  const documentName = document?.alias || document?.name

  return documentName ? (
    // This specific `py` value is necessary to prevent a layout shift when the skeleton is replaced with the actual content.
    <Box sx={{ display: 'flex', gap: 0.5, px: 3, py: '16.75px' }}>
      <Typography sx={{ fontWeight: 600 }} variant="body2">
        Document:
      </Typography>

      <Typography noWrap variant="body2">
        {documentName}
      </Typography>
    </Box>
  ) : (
    <Box sx={{ px: 3, py: 2 }}>
      <Skeleton width="552px" />
    </Box>
  )
}
