import 'tippy.js/dist/tippy.css'
import React, { ReactElement } from 'react'
import Tippy from '@tippyjs/react'

// HOC for tooltip with optional text
export default function WithTooltip({
  children,
  content,
  tippyClass,
  ...props
}: {
  [x: string]: any
  children: ReactElement
  content?: any
  tippyClass?: string
}) {
  const { style, visible, ...rest } = props // Destructure to avoid passing specific props to the wrong element.

  return content ? (
    <Tippy className={tippyClass || ''} content={content} delay={[150, 0]} visible={visible} {...rest}>
      <div style={style} {...rest}>
        {children}
      </div>
    </Tippy>
  ) : (
    children
  )
}
