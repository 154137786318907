import React, { FC, KeyboardEvent, SyntheticEvent, memo } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type _NumberInputProps = {
  ariaLabel?: string
  id?: string
  initialValue?: any
  isDisabled?: boolean
  isFloat?: boolean
  isFocused?: boolean
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void
  onInput?: (e: SyntheticEvent<HTMLInputElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  setFocused?: (arg: boolean) => void
  value: string
}

// constants

const NEW_INTEGER_PATTERN = /[-,0-9]/g
const NEW_FLOAT_PATTERN = /[-,.0-9]/g
const LETTERS_PATTERN = /[a-zA-Z]/g

// components

const NumberInput: FC<_NumberInputProps> = ({ ariaLabel, id, isDisabled, isFloat, onBlur, onChange, onKeyDown, placeholder, value }) => {
  const onInputValidChange = (evt: any) => {
    evt.preventDefault()
    const trimmedValue = evt.currentTarget.value.trim()
    if (LETTERS_PATTERN.test(trimmedValue)) {
      evt.currentTarget.value = 'INVALID_VALUE'
      if (onChange) {
        onChange(evt)
        return
      }
    }

    const floatValue = trimmedValue.match(NEW_FLOAT_PATTERN)?.join('')
    const integerValue = trimmedValue.match(NEW_INTEGER_PATTERN)?.join('')

    evt.currentTarget.value = null
    if (isFloat) {
      if (onChange && floatValue === undefined) {
        onChange(evt)
        return
      }
      if (onChange && floatValue !== undefined) {
        evt.currentTarget.value = floatValue
        onChange(evt)
      }
    } else if (!isFloat) {
      if (onChange && integerValue === undefined) {
        onChange(evt)
        return
      }
      if (onChange && integerValue !== undefined) {
        evt.currentTarget.value = integerValue
        onChange(evt)
      }
    }
  }

  return (
    <>
      <input
        aria-label={ariaLabel || placeholder || 'Enter a number'}
        className={clsx(css.input, isDisabled && css.disabled)}
        disabled={isDisabled}
        id={id}
        onBlur={(e: SyntheticEvent<HTMLInputElement>) => {
          if (onBlur) {
            onBlur(e)
          }
        }}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => onInputValidChange(e)}
        onKeyDown={(e: any) => {
          if (onKeyDown) {
            onKeyDown(e)
          }
        }}
        placeholder={placeholder}
        style={{ ...(!isDisabled && { backgroundColor: '#fff' }) }}
        type="text"
        value={value || ''}
      />
    </>
  )
}

NumberInput.defaultProps = {
  onBlur: () => ({}),
  onChange: () => ({}),
  onKeyDown: () => ({})
}

export default memo(NumberInput)
