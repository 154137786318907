import 'tippy.js/dist/tippy.css'
import { AccountingImpactButton } from './AccountingImpactButton'
import { Box } from '@mui/material'
import { CommentButton } from './CommentButton/CommentButton'
import { EditDataPointAccountingImpactMutation, EditDataPointManualReviewMutation } from '../../graphql/codegen/operations'
import { FetchResult, MutationResult } from '@apollo/client'
import { Info } from 'react-feather'
import { ManualReviewCheckboxes } from './ManualReviewCheckboxes'
import { useEditDataPointAccountingImpactMutation, useEditDataPointManualReviewMutation, useTransitionAuditsQuery } from '../../graphql/codegen/hooks'
import { useIsAnnotator } from '../../hooks/useUserAccess'
import { useParams } from 'react-router-dom'
import DOMPurify from 'dompurify'
import InputRow from './InputRow'
import PinnedComments from '../PinnedComments/PinnedComments'
import React, { FC, useState } from 'react'
import Tippy from '@tippyjs/react'
import clsx from 'clsx'
import css from './style.module.scss'
import type { CustomState, DataPoint } from '../../graphql/codegen/schemas'

// types

type _LabelAndActionsProps = {
  accountingImpactMutation: (options?: any) => Promise<FetchResult>
  accountingImpactMutationState: MutationResult<EditDataPointAccountingImpactMutation>
  dataPoint: DataPoint
  dealIsFinalized: boolean
  manualReviewMutation: (options?: any) => Promise<FetchResult>
  manualReviewMutationState: MutationResult<EditDataPointManualReviewMutation>
}

type _NestedChildDataPointFieldProps = {
  dataPoint: DataPoint
  dealIsFinalized: boolean
}

// functions

const sanitizeHtml = (description?: string) => ({ __html: DOMPurify.sanitize(description || '') })

// components

export const NestedChildDataPointField: FC<_NestedChildDataPointFieldProps> = ({ dataPoint, dealIsFinalized }) => {
  const [accountingImpactMutation, accountingImpactMutationState] = useEditDataPointAccountingImpactMutation()
  const [isFocused, setFocused] = useState(false)
  const [manualReviewMutation, manualReviewMutationState] = useEditDataPointManualReviewMutation()

  const isMutationLoading = Boolean(accountingImpactMutationState?.loading || manualReviewMutationState?.loading)
  const isMutationSuccess = Boolean(accountingImpactMutationState?.data || manualReviewMutationState?.data)

  return (
    <li className={clsx(css.field, css.nestedChildDataPoint, isFocused && css.focused)} data-id="nestedChildDataPointField">
      <LabelAndActions
        accountingImpactMutation={accountingImpactMutation}
        accountingImpactMutationState={accountingImpactMutationState}
        dataPoint={dataPoint}
        dealIsFinalized={dealIsFinalized}
        manualReviewMutation={manualReviewMutation}
        manualReviewMutationState={manualReviewMutationState}
      />

      <InputRow
        ariaLabel={dataPoint.data_point_field?.name}
        dataPoint={dataPoint}
        dealIsFinalized={dealIsFinalized}
        isFocused={isFocused}
        isMutationLoading={isMutationLoading}
        isMutationSuccess={isMutationSuccess}
        isNestedChildDataPoint
        setFocused={setFocused}
      />

      {/* Override default PinnedComments styles */}
      <Box sx={{ '& > div': { fontSize: 13, mt: 1, pb: 0, pt: 1, px: 0 } }}>
        <PinnedComments dataPoint={dataPoint} dealIsFinalized={dealIsFinalized} isNestedChildDataPoint />
      </Box>
    </li>
  )
}

const LabelAndActions: FC<_LabelAndActionsProps> = ({
  accountingImpactMutation,
  accountingImpactMutationState,
  dataPoint,
  dealIsFinalized,
  manualReviewMutation,
  manualReviewMutationState
}) => {
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const { data_point_field } = dataPoint
  const { description, display_accounting_impact, display_comment_field, display_manual_review } = data_point_field || {}
  const isAnnotator = useIsAnnotator()

  const { data: transitionAuditsData } = useTransitionAuditsQuery({ variables: { dealOrDocumentId: dealId || documentId! } })

  const states = transitionAuditsData?.transition_audit?.states
    ? (transitionAuditsData.transition_audit.states as CustomState[]).filter(state => !state.initial && !state.final)
    : null

  return (
    <Box sx={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column', gap: 1, height: '100%', justifyContent: 'space-between', pb: 1 }}>
      <Box
        sx={{
          color: 'rgba(0, 0, 0, 0.8)',
          display: 'flex',
          fontSize: 14,
          fontWeight: 600,
          gap: 2,
          justifyContent: 'space-between',
          margin: 0,
          position: 'relative',
          verticalAlign: 'middle',
          width: '100%'
        }}
      >
        <span>
          {dataPoint.data_point_field?.name}&nbsp;&nbsp;
          {description && (
            <Tippy
              className={clsx(description?.length > 500 && css.description)}
              content={<div dangerouslySetInnerHTML={sanitizeHtml(description)} />}
              delay={[150, 0]}
            >
              <Info style={{ height: 14, marginBottom: '2px', verticalAlign: 'middle', width: 14 }} tabIndex={-1} />
            </Tippy>
          )}
        </span>

        {!isAnnotator && (display_accounting_impact || display_comment_field) && (
          <Box className={css.actions} component="ul" sx={{ fontWeight: 400 }}>
            {display_accounting_impact && (
              <li>
                <AccountingImpactButton
                  dataPoint={dataPoint}
                  dealIsFinalized={dealIsFinalized || false}
                  mutation={accountingImpactMutation}
                  mutationState={accountingImpactMutationState}
                  tabIndex={-1}
                />
              </li>
            )}

            {display_comment_field && (
              <li>
                <CommentButton dataPoint={dataPoint} dealIsFinalized={dealIsFinalized || false} isNestedChildDataPoint />
              </li>
            )}
          </Box>
        )}
      </Box>

      {!isAnnotator && display_manual_review && states && (
        <Box className={css.actions} component="ul" sx={{ justifyContent: 'flex-end', maxWidth: 'calc(100% - 46px)', width: '100%' }}>
          <li style={{ color: '#57575b', marginRight: 'auto' }}>
            <ManualReviewCheckboxes
              dataPoint={dataPoint}
              dealIsFinalized={dealIsFinalized}
              mutation={manualReviewMutation}
              mutationState={manualReviewMutationState}
              states={states}
              tabIndex={-1}
            />
          </li>
        </Box>
      )}
    </Box>
  )
}
