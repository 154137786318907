import { Box, Typography } from '@mui/material'
import { getSourceLabel } from '../../../../../../../utils/cci'
import React, { FC, useEffect, useMemo } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// types

export enum CalculationSourceLabels {
  CALCULATION = 'Calculation'
}

export enum CalculationSources {
  CALCULATION = 'CALCULATION'
}

export enum ExternalSourceLabels {
  GOOGLE_DRIVE = 'Google Drive',
  IRONCLAD = 'Ironclad',
  NETSUITE = 'NetSuite',
  ORACLE = 'Oracle',
  PEGA = 'Pega',
  SALESFORCE = 'Salesforce',
  SAP = 'SAP',
  SHAREPOINT = 'SharePoint',
  SPRINGCM = 'SpringCM',
  ZUORA = 'Zuora'
}

export enum ExternalSources {
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  IRONCLAD = 'IRONCLAD',
  NETSUITE = 'NETSUITE',
  ORACLE = 'ORACLE',
  PEGA = 'PEGA',
  SALESFORCE = 'SALESFORCE',
  SAP = 'SAP',
  SHAREPOINT = 'SHAREPOINT',
  SPRINGCM = 'SPRINGCM',
  ZUORA = 'ZUORA'
}

type _MergedSourceLabels = { [key in keyof CalculationSourceLabels | keyof ExternalSourceLabels | keyof SourceLabels]: string }

export type _Source = CalculationSources | ExternalSources | Sources

type _SourceInputProps = { handleData: (type: string, value: any) => void; hasFullAccess: boolean; value?: _Source }

export enum SourceLabels {
  INTERNAL = 'Internal',
  MANUAL = 'Manual',
  MATCHING = 'Matching'
}

export enum Sources {
  INTERNAL = 'INTERNAL',
  MANUAL = 'MANUAL',
  MATCHING = 'MATCHING'
}

// components

export const SourceInput: FC<_SourceInputProps> = ({ handleData, hasFullAccess, value }) => {
  const sourceOptions = useMemo(() => {
    const mergedSourceLabels = { ...CalculationSourceLabels, ...SourceLabels, ...ExternalSourceLabels } as unknown as _MergedSourceLabels

    return [
      ...Object.entries({ ...CalculationSources, ...Sources }).map(([key, value]) => ({ label: mergedSourceLabels[key as keyof _MergedSourceLabels], value })),
      { isDisabled: true, label: '_', value: '' },
      ...Object.entries(ExternalSources).map(([key, value]) => ({ label: mergedSourceLabels[key as keyof _MergedSourceLabels], value }))
    ]
  }, [])

  useEffect(() => {
    if (!hasFullAccess) {
      handleData('source', Sources.MANUAL)
    }
  }, [handleData, hasFullAccess])

  return (
    <>
      {hasFullAccess ? (
        <Box className={css.inputWrapper}>
          <Typography component="label" htmlFor="source-select-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
            Source
          </Typography>

          <SelectInput
            id="source-select-input"
            isClearable
            onChange={(item: { value: _Source }) => handleData('source', item?.value)}
            options={sourceOptions}
            placeholder={getSourceLabel(value)}
            value={value}
          />
        </Box>
      ) : (
        <Box className={css.inputWrapper} sx={{ height: 38 }}>
          <Typography component="label" htmlFor="source-select-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
            Source
          </Typography>

          <Typography sx={{ flexGrow: 1 }} variant="body2">
            Manual
          </Typography>
        </Box>
      )}
    </>
  )
}
