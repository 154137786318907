import { oktaClient } from '../../utils/oktaClient'
import { useHistory } from 'react-router'

// functions

export const clearLocalAuthValues = () => {
  deleteAuthCookies()
  deleteLocalStorageAuthItems()
}

const deleteAuthCookies = () => {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookieName = cookies[i].split('=')[0]

    if (cookieName?.includes('okta-oauth-state') || cookieName?.includes('okta-oauth-nonce') || cookieName?.includes('okta-oauth-redirect-params')) {
      deleteCookie(cookieName)
    }
  }
}

const deleteCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

const deleteLocalStorageAuthItems = () => {
  oktaClient.tokenManager.clear()

  localStorage.removeItem('customerId')
  localStorage.removeItem('okta-cache-storage')
  localStorage.removeItem('okta-token-storage')
}

// component

export const Logout = () => {
  const history = useHistory()

  clearLocalAuthValues()

  history.push('/login')

  return null
}
