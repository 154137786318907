import { Box, Typography } from '@mui/material'
import { EXTERNAL_SOURCE_LABELS } from '../../../../../../../utils/cci'
import { ExternalSourceLabels, ExternalSources, _Source } from '../../ChecklistTab/CreateInputs/SourceInput'
import { useCciChecklistGptContext } from '../CCI_RightPanel_ChecklistGptTab'
import React, { FC, useMemo } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// constants

const FIELD = 'source'

const LABEL = 'Source System'

const PLACEHOLDER = 'Select a source system'

// components

export const ExternalSourceInput: FC = () => {
  const { fieldValues, updateFieldValue } = useCciChecklistGptContext()
  const { source: value } = fieldValues || {}

  const externalSourceOptions = useMemo(
    () => Object.entries(ExternalSources).map(([key, value]) => ({ label: ExternalSourceLabels[key as keyof typeof ExternalSourceLabels], value })),
    []
  )

  return (
    <Box className={css.inputWrapper}>
      <Typography component="label" htmlFor="source-select-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}
      </Typography>

      <SelectInput
        id="source-select-input"
        isClearable
        onChange={(item: { value: _Source }) => updateFieldValue(FIELD, item?.value)}
        options={externalSourceOptions}
        placeholder={value ? EXTERNAL_SOURCE_LABELS.get(value as _Source) || value : PLACEHOLDER}
        value={value}
      />
    </Box>
  )
}
