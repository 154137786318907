import { Grid, List, ListItemButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { useCciRolesWithFeaturesQuery, useCciUsersQuery } from '../api'
import { useContextInit } from '../../hooks/useContextInit'
import Layout from '../../components/Layout'
import React, { Dispatch, SetStateAction, createContext, useMemo, useState } from 'react'

// types

type _CciAccessControlContext = {
  rolesUsers: RolesUsersValue
  setRolesUsers: Dispatch<SetStateAction<RolesUsersValues>>
}

type RolesUsersValue = RolesUsersValues

// context

const CciAccessControlContext = createContext<_CciAccessControlContext | null>(null)

// enums

export enum RolesUsersValues {
  ROLES,
  USERS
}

// hooks

const useCciAccessControlContext = () => useContextInit(CciAccessControlContext)

// components

export const CciAccessControlPage = () => {
  const [rolesUsers, setRolesUsers] = useState<RolesUsersValue>(RolesUsersValues.ROLES)

  const context = useMemo<_CciAccessControlContext>(() => ({ rolesUsers, setRolesUsers }), [rolesUsers])

  return (
    <Layout isMaterialUi>
      <CciAccessControlContext.Provider value={context}>
        <Grid container>
          <RolesUsers />

          {rolesUsers === RolesUsersValues.ROLES && <Roles />}

          {rolesUsers === RolesUsersValues.USERS && <Users />}
        </Grid>
      </CciAccessControlContext.Provider>
    </Layout>
  )
}

const Roles = () => {
  const { data: cciRolesWithFeatures } = useCciRolesWithFeaturesQuery()

  if (!cciRolesWithFeatures) return null

  return (
    <Grid item>
      <TableContainer component={Paper} sx={{ marginLeft: 8, marginTop: 2 }}>
        <Table>
          <TableBody>
            {cciRolesWithFeatures.cci_roles?.map(role => (
              <TableRow key={role?.id} sx={{ '&:last-child td': { border: 0 } }}>
                <TableCell>{role?.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

const RolesUsers = () => {
  const { rolesUsers, setRolesUsers } = useCciAccessControlContext()

  return (
    <Grid item>
      <List>
        <ListItemButton onClick={() => setRolesUsers(RolesUsersValues.ROLES)} selected={rolesUsers === RolesUsersValues.ROLES}>
          Roles
        </ListItemButton>

        <ListItemButton onClick={() => setRolesUsers(RolesUsersValues.USERS)} selected={rolesUsers === RolesUsersValues.USERS}>
          Users
        </ListItemButton>
      </List>
    </Grid>
  )
}

const Users = () => {
  const { data: cciUsersData } = useCciUsersQuery()

  if (!cciUsersData) return null

  return (
    <Grid item>
      <TableContainer component={Paper} sx={{ marginLeft: 8, marginTop: 2 }}>
        <Table>
          <TableBody>
            {cciUsersData.cci_users?.map(user => (
              <TableRow key={user?.user_name} sx={{ '&:last-child td': { border: 0 } }}>
                <TableCell>{user?.first_name}</TableCell>

                <TableCell>{user?.last_name}</TableCell>

                <TableCell>{user?.user_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}
