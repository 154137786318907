import { formatBytes } from '../../../utils/stringUtils'
import { useParams } from 'react-router-dom'
import Button from '../../Button'
import ComponentLoadingOverlay from '../../ComponentLoadingOverlay'
import Modal from '../../Modal'
import React, { useEffect, useState } from 'react'
import axiosClient from '../../../utils/axiosClient'
import css from './style.module.scss'

export default function DownloadBulkJob({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen?: any }) {
  const { download_artifact_uuid, file_name } = useParams<{ download_artifact_uuid: string; file_name: string }>()
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [fileInfo, setFileInfo] = useState<any>(undefined)

  const handleDownload = async () => {
    setLoading(true)
    setLoadingMessage('Starting file download…')
    await axiosClient
      .get(`/bulk-job/${download_artifact_uuid}/${file_name}`, {
        responseType: 'blob',
        onDownloadProgress(progressEvent) {
          const total = progressEvent.total ?? 0
          const progress = total ? Math.round((progressEvent.loaded / total) * 100) : 0

          setLoadingMessage(`Downloading File: ${progress}%`)
        }
      })
      .then(response => {
        let blob
        if (fileInfo?.type === 'application/zip') {
          blob = new Blob([response.data], { type: 'application/zip' })
        }
        const href = window.URL.createObjectURL(blob || response?.data)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = href
        a.download = file_name || download_artifact_uuid
        document.body.appendChild(a)
        a.click()
        setSuccess(true)
        setLoading(false)
        setLoadingMessage('')
      })
      .catch(err => {
        console.error(`DownloadBulkJob.tsx handleDownload error: ${err?.response?.data?.message || err}`)
        setError(err?.response?.data?.message || err)
        setLoading(false)
        setLoadingMessage('')
      })
  }

  const closeModal = () => {
    setFileInfo(undefined)
    setSuccess(false)
    setError(false)
    setLoading(false)
    setLoadingMessage('')
    setIsOpen(false)
  }

  useEffect(() => {
    if (download_artifact_uuid) {
      setLoading(true)
      getFileInfo(`/bulk-job/${download_artifact_uuid}/${file_name || ''}`, setLoading, setError, setFileInfo)
    }
    // eslint-disable-next-line
    }, [download_artifact_uuid, file_name])

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={loading ? undefined : closeModal} title={'Download File'}>
        {loading && <ComponentLoadingOverlay loading={loading} message={loadingMessage} />}
        <div style={{ textAlign: 'center' }}>
          {file_name && <h4>{file_name}</h4>}
          {success ? (
            <ModalSuccess closeModal={closeModal} />
          ) : error ? (
            <ModalError closeModal={closeModal} error={error} handleDownload={handleDownload} />
          ) : (
            <>
              {fileInfo && <p style={{ marginTop: '16px' }}>{`File size: ${fileInfo?.size}`}</p>}
              <div className={css.modalButtonRow}>
                <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
                <Button onClick={handleDownload}>{`Download`}</Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

function ModalSuccess({ closeModal }: any) {
  return (
    <>
      <p>{`Download complete.`}</p>
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} style={{ marginRight: 0 }} variant={'secondary'}>{`Close`}</Button>
      </div>
    </>
  )
}

function ModalError({ error, handleDownload }: any) {
  return (
    <>
      <p>{`Failed to download file.`}</p>
      <p>{`${error}`}</p>
      <div className={css.modalButtonRow}>
        <Button onClick={handleDownload} style={{ marginRight: 0 }} variant={'secondary'}>{`Retry Download`}</Button>
      </div>
    </>
  )
}

async function getFileInfo(url: string, setLoading: any, setError: any, setFileInfo: any) {
  await axiosClient
    .head(url)
    .then(response => {
      setFileInfo({ size: formatBytes(response?.headers['content-length']), type: response?.headers['content-type'] })
      setLoading(false)
    })
    .catch(err => {
      console.error(`DownloadBulkJob.tsx getFileInfo error: ${err}`)
      setError(err?.response?.data?.message ? `Error: ${err?.response?.data?.message}` : err)
      setFileInfo(undefined)
      setLoading(false)
    })
}
