import { API_ROOT } from './apiUtils'
import { getIdToken } from './sessionApiUtils'
import axios from 'axios'

const axiosClient = () => {
  const instance = axios.create({ baseURL: API_ROOT })

  // Get auth token from okta manager, set for any request
  instance.interceptors.request.use(async function (config) {
    const idToken = await getIdToken()
    const customerId = localStorage.getItem('customerId')
    const token = btoa(`${idToken}__||__${customerId}`)
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
  })

  return instance
}

export default axiosClient()
