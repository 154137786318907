import { Annotation, AnnotationPositionBbox, AnnotationPositionInput, FeedbackHighlightPositionInput } from '../../graphql/codegen/schemas'
import queryString from 'query-string'

export function positionToFeedbackHighlightPositionInput(position: any): FeedbackHighlightPositionInput {
  const bounding_rect = {
    x1: position.boundingRect.x1,
    x2: position.boundingRect.x2,
    y1: position.boundingRect.y1,
    y2: position.boundingRect.y2
  }

  return {
    page_number: position.pageNumber,
    page_height: position.boundingRect.height,
    page_width: position.boundingRect.width,
    bounding_rect,
    rects: position.rects.map((r: any) => {
      return {
        x1: r.x1,
        x2: r.x2,
        y1: r.y1,
        y2: r.y2
      }
    })
  }
}

// TODO: make this use T_ScaledPosition type from @klarity/pdf-highlighter
export function positionToAnnotationPositionInput(position: any, content: any): AnnotationPositionInput {
  const bounding_rect = {
    x1: position.boundingRect.x1,
    x2: position.boundingRect.x2,
    y1: position.boundingRect.y1,
    y2: position.boundingRect.y2
  }

  return {
    text: content.text,
    page_number: position.pageNumber,
    page_height: position.boundingRect.height,
    page_width: position.boundingRect.width,
    bounding_rect,
    rects: position.rects.map((r: any) => {
      return {
        x1: r.x1,
        x2: r.x2,
        y1: r.y1,
        y2: r.y2
      }
    })
  }
}

type Rect = {
  height: number
  width: number
  x1: number
  x2: number
  y1: number
  y2: number
}

export type HighlighterHighlight = {
  comment: { text: string }
  content: { text: string }
  id: string
  labelType?: string | null // used to separate those with missing positions; label_type: 'MISSING_ANNO' means that the annotation exists on the annodoc but cannot be located on the pdf
  position: { boundingRect: Rect; pageNumber: number; rects: Rect[] }
  review_state?: string | null
}

export function klarityAnnotationToHighlighterFormat(annotation: Annotation): HighlighterHighlight | null {
  const { bounding_rect, page_height, page_number, page_width, rects } = annotation?.positions?.edges[0]?.node || {}

  if (!(page_height && page_width && page_number && rects && bounding_rect)) return null

  return {
    ...annotation,
    id: annotation.id,
    labelType: annotation.label_type,
    content: {
      text: ''
    },
    comment: {
      text: annotation.label_name
    },
    position: {
      pageNumber: page_number,
      boundingRect: {
        ...bounding_rect,
        width: page_width,
        height: page_height
      },
      rects: rects.edges.map(r => {
        return {
          ...(r?.node as AnnotationPositionBbox),
          width: page_width,
          height: page_height
        }
      })
    }
  }
}

export const parseIdFromHash = () => document.location.hash.slice('#highlight-'.length)

export const removeDataPointSearchParam = () => {
  const searchParams = queryString.parse(window.location.search)
  delete searchParams.dataPoint
  const updatedSearchParams = queryString.stringify(searchParams)
  const updatedURL = `${window.location.origin}${window.location.pathname}?${updatedSearchParams}${window.location.hash}`

  window.history.replaceState({}, '', updatedURL)
}

export const updateHash = (highlight: any) => {
  document.location.hash = `highlight-${highlight.id}`
}
