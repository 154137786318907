import RNumberFormat from 'react-number-format'
import React, { FC } from 'react'
import css from './style.module.scss'

// types

type _NumberInputProps = {
  [key: string]: any
  ariaLabel?: string
  isError?: boolean
}

// components

const NumberInput: FC<_NumberInputProps> = ({ ariaLabel, isError, ...rest }) => (
  <RNumberFormat
    allowLeadingZeros={false}
    aria-label={ariaLabel || 'Enter a number'}
    decimalScale={0}
    {...rest}
    className={css.input}
    style={{ border: `${isError ? '1px solid red' : ''}` }}
  />
)

export default NumberInput
