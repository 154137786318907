import { DELETE } from '../../../../../../../utils/cci'
import { Features, Permissions, useUserAccess } from '../../../../../../../hooks/useUserAccess'
import { FiXSquare } from 'react-icons/fi'
import { GenericDataPointInput } from './GenericDataPointInput'
import { Typography } from '@mui/material'
import { useCciChecklistGptContext } from '../CCI_RightPanel_ChecklistGptTab'
import { useCciMainContext } from '../../../../../CCI_Main'
import Button from '../../../../../../../components/Button'
import React, { FC, useEffect, useMemo, useState } from 'react'
import css from './style.module.scss'

// types

type _CreateViewDefaultValueInputProps = {
  options: any[] | undefined
  setValue: (value: string | null | undefined) => void
  value: string | null | undefined
}

type _EditViewDefaultValueInputProps = {
  options: any[] | undefined
  setValue: (value: string | null | undefined) => void
  value: string | null | undefined
}

// constants

const BOOLEAN_OPTIONS = ['Yes', 'No', 'N/A']

const DROPDOWN_FIELD_TYPES = ['DROP_DOWN', 'BOOLEAN', 'MULTI_SELECT_DROP_DOWN']

// components

const CreateViewDefaultValueInput: FC<_CreateViewDefaultValueInputProps> = ({ options, setValue, value }) => {
  const { fieldValues, updateFieldValue } = useCciChecklistGptContext()
  const { default_value: defaultValue, field_type: fieldType, value_format: valueFormat } = fieldValues || {}

  const isDropdown = DROPDOWN_FIELD_TYPES.includes(fieldType || '')

  return (
    <>
      {fieldType && (
        <>
          {isDropdown && !options?.length ? (
            <></>
          ) : (
            <div className={css.inputWrapper} style={{ height: '37px' }}>
              <Typography component="label" htmlFor="default-value-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
                Default Value
              </Typography>

              <div className={css.optionInputWrapper}>
                <div className={css.optionInputWrapper} style={{ flexGrow: 1, height: '37px' }}>
                  <GenericDataPointInput
                    fieldType={fieldType || ''}
                    id="default-value-input"
                    options={options}
                    setValue={setValue}
                    value={value}
                    valueFormat={valueFormat}
                  />
                </div>

                {value && !isDropdown && (
                  <Button
                    className={css.cciButton}
                    icon={<FiXSquare />}
                    onClick={() => {
                      setValue(defaultValue)
                      updateFieldValue('default_value', null)
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

const EditViewDefaultValueInput: FC<_EditViewDefaultValueInputProps> = ({ options, setValue, value }) => {
  const { selectedItem } = useCciMainContext()
  const { fieldValues, updateFieldValue } = useCciChecklistGptContext()
  const { field_type: fieldType, value_format: valueFormat } = fieldValues || {}

  const initialFieldType = useMemo(() => selectedItem?.field_type || '', [selectedItem])
  const initialOptions = useMemo(() => selectedItem?.options || [], [selectedItem])

  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })
  const isBoolean = fieldType === 'BOOLEAN' || (!fieldType && initialFieldType === 'BOOLEAN')

  return (
    <>
      <div className={css.inputWrapper} style={{ height: '37px' }}>
        <Typography component="label" htmlFor="default-value-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
          Default Value
        </Typography>

        <div className={css.optionInputWrapper}>
          <div className={css.optionInputWrapper} style={{ flexGrow: 1, height: '37px' }}>
            <GenericDataPointInput
              fieldType={fieldType || initialFieldType || ''}
              id="default-value-input"
              options={isBoolean ? BOOLEAN_OPTIONS : options || initialOptions}
              setValue={setValue}
              value={value === DELETE ? '' : value}
              valueFormat={valueFormat}
            />
          </div>

          {hasFullEditAccess && value && value !== DELETE && (
            <Button
              className={css.cciButton}
              icon={<FiXSquare />}
              onClick={() => {
                setValue(null)
                updateFieldValue('default_value', DELETE)
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}

export const DefaultValueInput: FC = () => {
  const { selectedItem } = useCciMainContext()
  const { fieldValues, isEditView, updateFieldValue } = useCciChecklistGptContext()
  const { default_value: defaultValue, field_type: fieldType, options } = fieldValues || {}

  const initialDefaultValue = useMemo(() => (selectedItem ? JSON.parse(selectedItem?.inheritance_logic)?.default_value : ''), [selectedItem])
  const initialFieldType = useMemo(() => selectedItem?.field_type || '', [selectedItem])

  const optionList = useMemo(
    () => (fieldType === 'BOOLEAN' ? BOOLEAN_OPTIONS.map(option => ({ value: option, label: option })) : options || undefined),
    [fieldType, options]
  )

  const selectedItemId = useMemo(() => selectedItem?.id, [selectedItem])

  const [value, setValue] = useState<string | undefined | null>(defaultValue || initialDefaultValue || '')

  useEffect(() => {
    if (value !== null) {
      updateFieldValue('default_value', value)
    }
  }, [updateFieldValue, value])

  useEffect(() => {
    setValue(initialDefaultValue || '')
  }, [fieldType, initialFieldType, selectedItemId]) // eslint-disable-line

  const isDataTable = fieldType === 'DATA_TABLE' || (!fieldType && initialFieldType === 'DATA_TABLE')

  if (isDataTable) return null

  return isEditView ? (
    <EditViewDefaultValueInput options={optionList} setValue={setValue} value={value} />
  ) : (
    <CreateViewDefaultValueInput options={optionList} setValue={setValue} value={value} />
  )
}
