import { Typography } from '@mui/material'
import { useCciChecklistContext } from '../CCI_RightPanel_ChecklistTab_Main'
import React from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import Skeleton from 'react-loading-skeleton'
import css from './style.module.scss'

export default function GroupInput({ handleData, value }: { handleData: any; value?: string }) {
  const { groupOptions, groupsLoading } = useCciChecklistContext()

  const handleChange = (item: any) => {
    handleData('group', item?.value)
  }

  return (
    <>
      {/* Select a group from dropdown or input to create new */}
      <div className={css.inputWrapper}>
        <Typography component="label" htmlFor="group-select-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
          Group
        </Typography>

        {groupsLoading ? (
          <Skeleton count={1} height={37} />
        ) : (
          <SelectInput
            creatable
            id="group-select-input"
            isClearable
            onChange={handleChange}
            options={groupOptions}
            placeholder={value || 'Select Group'}
            value={value}
          />
        )}
      </div>
    </>
  )
}
