import { Box, Typography } from '@mui/material'
import { CreateOrSelectNeutralTag } from './CreateOrSelectNeutralTag'
import { Features, Permissions, useUserAccess } from '../../../../../../../hooks/useUserAccess'
import { FiChevronDown, FiChevronUp, FiTrash2, FiXSquare } from 'react-icons/fi'
import { NeutralTagRow } from './NeutralTagRow'
import { captureError } from '../../../../../../../utils/sentry'
import { useCciMainContext } from '../../../../../CCI_Main'
import Button from '../../../../../../../components/Button'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import React, { FC, KeyboardEvent, useEffect, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// types

type _FieldOptionsProps = {
  currentDefaultValue?: string
  currentFieldType?: string
  currentItemId?: string
  currentOptions?: any
  currentValueFormat?: string
  handleData: any
  newDefaultValue?: string
  newFieldType?: string
  newValueFormat?: string
}

type _OptionInputProps = {
  handleChange?: any
  handleData?: any
  handleNeutralTag: any
  hasEditNeutralTagAccess?: boolean
  index: number
  isNew?: boolean
  isOMSDD?: boolean
  neutralTag?: string
  optionsArray?: any
  placeholder?: string
  value?: string
}

type _OptionNeutralTagInputProps = { handleNeutralTag: any; index: number; neutralTag?: string }
type _OptionNeutralTagRowProps = { option: any; optionNeutralTagMapping: string }

// components

export const FieldOptions: FC<_FieldOptionsProps> = ({
  currentDefaultValue,
  currentFieldType,
  currentItemId,
  currentOptions,
  currentValueFormat,
  handleData,
  newDefaultValue,
  newFieldType,
  newValueFormat
}) => {
  const { selectedItem } = useCciMainContext()
  const [optionsArray, setOptionsArray] = useState<Array<any>>([])
  const [deletedOptionsArray, setDeletedOptionsArray] = useState<Array<any>>([])
  const optionsList = ['DROP_DOWN', 'MULTI_SELECT_DROP_DOWN', 'DATE']
  const dropdownTypes = ['DROP_DOWN', 'MULTI_SELECT_DROP_DOWN']
  // @ts-ignore
  const isDropdown = dropdownTypes?.includes(newFieldType) || (!newFieldType && dropdownTypes?.includes(currentFieldType))
  const valueFormatOptions = [
    { value: 'MMMM/DD/YYYY', label: 'MMMM/DD/YYYY' },
    { value: 'MMMM DD, YYYY', label: 'MMMM DD, YYYY' },
    { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
    { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
    { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
    { value: 'DD MMMM, YYYY', label: 'DD MMMM, YYYY' }
  ]
  const hasEditNeutralTagAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_NEUTRAL_TAG })
  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  // @ts-ignore
  const handleChange = (value?: string, index: number, isNew?: boolean) => {
    const localArray: any[] = [...optionsArray]
    const deletedArray: any[] = [...deletedOptionsArray]

    // update default value
    if (!isNew && (newDefaultValue || currentDefaultValue)) {
      if (
        localArray[index].newValue === (newDefaultValue || currentDefaultValue) ||
        (!localArray[index].newValue && localArray[index].currentValue === (newDefaultValue || currentDefaultValue))
      ) {
        if (value === null) {
          handleData('default_value', localArray[index].currentValue)
        } else {
          handleData('default_value', value)
        }
      }
    }

    if (value === '@@_DELETE_THIS_ITEM') {
      const element = localArray[index]
      localArray.splice(index, 1)
      if (element?.currentValue) {
        deletedArray.push({ currentValue: element.currentValue, newValue: value })
      }
    } else if (isNew) {
      if (value) {
        localArray.push({ currentValue: null, newValue: value })
      }
    } else {
      localArray[index].newValue = value
    }

    // delete the options array if no changes have been made
    const changedOptions = localArray?.filter(option => {
      if (option?.currentValue && !option?.newValue) {
        return false
      } else {
        return true
      }
    })

    handleData('options', deletedArray?.length > 0 || changedOptions?.length > 0 ? localArray : undefined)
    handleData('deleted_options', deletedArray?.length > 0 ? deletedArray : undefined)

    setOptionsArray([...localArray])
    setDeletedOptionsArray([...deletedArray])
  }

  const handleDateFormat = (item: any) => {
    if (currentValueFormat) {
      if (item?.value !== currentValueFormat) {
        handleData('value_format', item?.value)
      }
    } else {
      handleData('value_format', item?.value)
    }
  }

  const handleNeutralTag = (index: number, value?: string) => {
    if (!optionsArray || optionsArray?.length < 1) {
      return null
    }
    const localArray: any[] = [...optionsArray]
    localArray[index].neutralTag = value
    setOptionsArray([...localArray])
    setOptionsArray([...localArray])
    handleData('options', localArray)
  }

  useEffect(() => {
    // generate/wipe initial array
    if (newFieldType) {
      setOptionsArray([])
      setDeletedOptionsArray([])
    } else {
      setOptionsArray(currentOptions?.map((item: any) => ({ currentValue: item, newValue: null })))
      setDeletedOptionsArray([])
    }
    handleData('options', null)
    // eslint-disable-next-line
  }, [currentItemId, currentFieldType, newFieldType, currentOptions])

  return (
    <>
      {/* Choose options for dropdown/multi dropdown, value_format for dates, and default values */}
      {/* @ts-ignore */}
      {(optionsList?.includes(newFieldType) || (!newFieldType && optionsList?.includes(currentFieldType))) && (
        <>
          {isDropdown && (
            <>
              <Typography sx={{ fontWeight: 600, mb: 1, mt: 2 }} variant="body2">
                Field Options
              </Typography>

              {optionsArray?.length ? (
                optionsArray?.map((option: any, index: number) => (
                  <div className={css.inputWrapper} key={`${option}${index}`} style={{ flexWrap: 'wrap' }}>
                    <OptionInput
                      handleChange={handleChange}
                      handleData={handleData}
                      handleNeutralTag={handleNeutralTag}
                      hasEditNeutralTagAccess={hasEditNeutralTagAccess}
                      index={index}
                      isOMSDD={!!selectedItem?.option_to_neutral_tag_mapping}
                      key={`${option}${index}`}
                      neutralTag={option?.neutralTag}
                      optionsArray={optionsArray}
                      placeholder={option?.currentValue}
                      value={option?.newValue || ''}
                    />

                    {selectedItem?.option_to_neutral_tag_mapping && hasEditNeutralTagAccess && (
                      <OptionNeutralTagRow option={option} optionNeutralTagMapping={selectedItem?.option_to_neutral_tag_mapping} />
                    )}
                  </div>
                ))
              ) : (
                <></>
              )}

              {hasFullEditAccess && (
                <OptionInput handleChange={handleChange} handleNeutralTag={handleNeutralTag} index={9999} isNew placeholder={'Add item…'} />
              )}
            </>
          )}

          {/* if type = date select value format */}
          {(newFieldType === 'DATE' || (!newFieldType && currentFieldType === 'DATE')) && (
            <div className={css.inputWrapper}>
              <Typography component="label" htmlFor="default-value-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
                Value Format
              </Typography>

              <SelectInput
                id="value-format-select-input"
                isClearable
                isDisabled={!hasFullEditAccess}
                onChange={handleDateFormat}
                options={valueFormatOptions}
                placeholder={newValueFormat === '@@_DELETE_THIS_ITEM' ? 'Select Date Format' : newValueFormat || currentValueFormat || 'Select Date Format'}
                value={newValueFormat || currentValueFormat || ''}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}

const OptionInput: FC<_OptionInputProps> = ({
  handleChange,
  handleData,
  handleNeutralTag,
  hasEditNeutralTagAccess,
  index,
  isNew,
  isOMSDD,
  neutralTag,
  optionsArray,
  placeholder,
  value
}) => {
  const [isFocused, setFocused] = useState(false)
  const [addItemValue, setAddItemValue] = useState('')
  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  const isOnlyOption =
    optionsArray?.filter((option: any) => {
      if (option?.newValue === '@@_DELETE_THIS_ITEM') {
        return false
      } else {
        return true
      }
    }).length === 1

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (isFocused && e?.key === 'Enter') {
      setFocused(false)
      // @ts-ignore
      e?.target?.blur()
    }
  }

  useEffect(() => {
    if (!isFocused && addItemValue) {
      handleChange(addItemValue || null, index, isNew)
      setAddItemValue('')
    } else {
      setAddItemValue(value || '')
    }
    // eslint-disable-next-line
  }, [isFocused])

  const canDeleteOption = isOMSDD && !(value && !placeholder) ? hasEditNeutralTagAccess || false : true //! (value && !placeholder) checks if it is a new option because the old value will be null

  return (
    <>
      {value !== '@@_DELETE_THIS_ITEM' && value !== placeholder && (
        <Box className={isNew ? css.optionInputWrapper : css.inputWrapper} sx={isNew ? { ml: 22.5 } : { m: 0 }}>
          <Box className={css.textInput}>
            {hasFullEditAccess && !isNew && <ReorderButtons handleData={handleData} index={index} optionsArray={optionsArray} />}

            <FreeText
              isDisabled={!hasFullEditAccess}
              isFocused={isFocused}
              onChange={(event: any) => setAddItemValue(event?.currentTarget?.value)}
              onKeyDown={handleKeyDown}
              placeholder={isNew ? 'Add item…' : placeholder}
              setFocused={setFocused}
              value={isFocused ? addItemValue || '' : value || ''}
            />
          </Box>

          {hasFullEditAccess && !isNew && placeholder && (addItemValue || value) && (
            <Button
              className={css.cciButton}
              icon={<FiXSquare />}
              onClick={() => {
                handleChange(null, index)
                setAddItemValue('')
              }}
            />
          )}

          {hasFullEditAccess && !isOnlyOption && (!isNew || !placeholder) && canDeleteOption && (
            <Button
              icon={<FiTrash2 color="#fc3535" />}
              onClick={() => handleChange('@@_DELETE_THIS_ITEM', index)}
              style={{ margin: '-1px 0 0 8px', borderRadius: '4px' }}
            />
          )}
        </Box>
      )}

      {isOMSDD && value && !placeholder && <OptionNeutralTagInput handleNeutralTag={handleNeutralTag} index={index} neutralTag={neutralTag} />}
    </>
  )
}

const OptionNeutralTagInput: FC<_OptionNeutralTagInputProps> = ({ handleNeutralTag, index, neutralTag }) => {
  const [value, setValue] = useState<any>(neutralTag)

  const handleChange = (neutralTagValue: string) => {
    setValue(neutralTagValue)
  }

  useEffect(() => {
    handleNeutralTag(index, value || null)
    // eslint-disable-next-line
  }, [value])

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', ml: 22.5, mr: value ? 0 : 4.5, mt: 1, width: '100%' }}>
      <Typography component="label" htmlFor="automation-tag-value-select-input" sx={{ flex: '1 0 auto', fontWeight: 600, mr: 4 }} variant="body2">
        Automation Tag
      </Typography>

      <div className={css.inputWrapper} style={{ marginBottom: '0', width: '100%' }}>
        <CreateOrSelectNeutralTag id="automation-tag-value-select-input" neutralTag={value} setNeutralTag={handleChange} />
      </div>
    </Box>
  )
}

function ReorderButtons({ handleData, index, optionsArray }: { handleData?: any; index: number; optionsArray?: any }) {
  const handleClick = (direction: string) => {
    if (direction === 'up' && index !== 0) {
      handleData('options', reorderArray(index, index - 1))
    } else if (direction === 'down' && index !== optionsArray?.length - 1) {
      handleData('options', reorderArray(index, index + 1))
    }
  }

  const reorderArray = (fromIndex: number, toIndex: number) => {
    const element = optionsArray[fromIndex]
    optionsArray.splice(fromIndex, 1)
    optionsArray.splice(toIndex, 0, element)
    return [...optionsArray]
  }

  return (
    <Box sx={{ display: 'flex', mr: 2, width: 164 }}>
      <Box className={css.toolbarButton} onClick={() => handleClick('up')} style={{ visibility: index === 0 ? 'hidden' : 'unset' }} title="Move option up">
        <FiChevronUp />
      </Box>

      <Box
        className={css.toolbarButton}
        onClick={() => handleClick('down')}
        sx={{ visibility: index === optionsArray?.length - 1 ? 'hidden' : 'unset' }}
        title="Move option down"
      >
        <FiChevronDown />
      </Box>
    </Box>
  )
}

const OptionNeutralTagRow: FC<_OptionNeutralTagRowProps> = ({ option, optionNeutralTagMapping }) => {
  const getOptionsNeutralTag = () => {
    try {
      // this is to prevent crashes if the tag mapping fails to parse
      if (optionNeutralTagMapping) {
        const tagMapping = JSON.parse(optionNeutralTagMapping)
        if (!tagMapping || !option?.currentValue) {
          return null
        } else {
          return tagMapping[option.currentValue]
        }
      } else {
        return null
      }
    } catch (error) {
      captureError(error, 'FieldOptions.tsx OptionNeutralTagRow')
    }
  }

  const optionNeutralTag = getOptionsNeutralTag()

  return <>{optionNeutralTag && <NeutralTagRow oldTag={optionNeutralTag} />}</>
}

// NOTES:

// for dropdown/multi: list of freetext inputs for each option array. This calls handleChange which formats the inputs and then updates handleData
// empty input at the bottom that adds a new item to the newOptions array
// newOptions array is cleaned up on submit in edit deal- if any newOption has a value or was deleted it will fill in nulls with currentItems. @@_DELETE_THIS_ITEM fields will not be sent
// if any value is changed the entire new options array is sent to the backend in order to replace old values.

// have a way to delete an option row- trashcan button to the right of X
// throw error on submit if type has required options but is missing. update this to show errors
