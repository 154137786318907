import { ActiveComponents, useCciMainContext } from '../../../../CCI_Main'
import CreateIntegration from './CreateIntegration'
import EditIntegration from './EditIntegration'
import React from 'react'

export default function CCI_RightPanel_IntegrationsTab_Main() {
  const { activeComponent } = useCciMainContext()

  return (
    <>
      {(!activeComponent || activeComponent === ActiveComponents.EDIT_INTEGRATION) && <EditIntegration />}

      {activeComponent === ActiveComponents.CREATE_INTEGRATION && <CreateIntegration />}
    </>
  )
}
