import { Box, TextField, Typography } from '@mui/material'
import { common } from '@mui/material/colors'
import React, { ChangeEvent, FC } from 'react'

// types

type _FeedbackCommentProps = { helperText?: string; isDisabled: boolean; label: string; onValueChange: (value: string) => void; value: string }

// components

export const FeedbackComment: FC<_FeedbackCommentProps> = ({ helperText, isDisabled, label, onValueChange, value }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
    <Typography variant="body2">{label}</Typography>

    <TextField
      FormHelperTextProps={{ sx: { fontSize: 10, lineHeight: 1.3, mb: 0, mt: 0.5, mx: 0 } }}
      InputProps={{ sx: { bgcolor: common.white, fontSize: 14, '.MuiInputBase-input': { pl: 0 } } }}
      disabled={isDisabled}
      fullWidth
      helperText={helperText}
      inputProps={{ maxLength: 65535 }}
      maxRows={6}
      multiline
      onChange={(event: ChangeEvent<HTMLInputElement>) => onValueChange(event.target.value)}
      rows={6}
      size="small"
      value={value}
    />
  </Box>
)
