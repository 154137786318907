import { DataPoint } from '../../graphql/codegen/schemas'
import { DocumentPendingProcessingFragment } from '../../graphql/codegen/operations'
import { Opening, useOpening } from '@hoologic/use-opening'
import { Route, useLocation } from 'react-router'
import { Z_INDEX_1, Z_INDEX_HEADER } from '../../utils/styleUtils'
import { useContextInit } from '../../hooks/useContextInit'
import AttachmentOverlay from '../AttachmentOverlay'
import DocumentPanel from '../DocumentPanel'
import Helmet from 'react-helmet'
import React, { Dispatch, FC, ReactNode, SetStateAction, createContext, useEffect, useMemo, useState } from 'react'
import SplitPane from 'react-split-pane'
import css from './style.module.scss'
import useCurrentUser from '../../hooks/useCurrentUser'
import useQString from '../../hooks/useQString'
import useWindowSize from '../../utils/useWindowSize'

// types

type _DocumentPageWrapperContext = {
  activeDocumentId: _ID
  activeParentDataPoint: DataPoint | null
  activeTableId: _ID
  dataTableMatchingOpening: Opening
  documentPagePanelWidth: number
  isDataTableMatchingDemoEnabled: boolean
  setActiveParentDataPoint: Dispatch<SetStateAction<DataPoint | null>>
  setActiveTableId: Dispatch<SetStateAction<_ID>>
}

type _DocumentPageWrapperProps = {
  counterparty?: any
  dataPointPanel: ReactNode
  dealIsFinalized?: boolean
  documents: any
  documentsPendingProcessing?: DocumentPendingProcessingFragment[]
  headerComponent: ReactNode
  loading: boolean
  name?: string
  noChecklist?: boolean
  withDocumentSelector?: boolean
}

type _ID = string | null

// constants

const CUSTOMER_SANKET_MATCHING_TEST_ACCOUNT_2 = 'Q3VzdG9tZXI6NjQ1MzA4MzZiY2U2YTUxNWE0ODJmNTFj'
const CUSTOMER_DEMO_CUSTOMER = 'Q3VzdG9tZXI6NWY4OTNmODBjYTAwNjg3MDE1NGE3ZDQy'
export const DEFAULT_PANEL_WIDTH = 600
export const DEFAULT_PANEL_WIDTH_FOR_SMALL_DEVICES = 477
const SMALL_WIDTH_SCREEN = 1450

// context

const DocumentPageWrapperContext = createContext<_DocumentPageWrapperContext | null>(null)

// hooks

export const useDocumentPageWrapperContext = () => useContextInit(DocumentPageWrapperContext)

const useDocumentPanelWidth = () => {
  const windowSize = useWindowSize()

  const [panelWidth, setPanelWidth] = useState(() => {
    const savedSize = localStorage.getItem('documentPagePanelWidth')

    if (savedSize) return Number(savedSize)

    const isSmallWidthScreen = (windowSize?.width || 0) <= SMALL_WIDTH_SCREEN

    return isSmallWidthScreen ? DEFAULT_PANEL_WIDTH_FOR_SMALL_DEVICES : DEFAULT_PANEL_WIDTH
  })

  useEffect(() => {
    localStorage.setItem('documentPagePanelWidth', String(panelWidth))
  }, [panelWidth])

  return [panelWidth, setPanelWidth] as const
}

// components

export const DocumentPageWrapper: FC<_DocumentPageWrapperProps> = ({
  counterparty,
  dataPointPanel,
  dealIsFinalized,
  documents,
  documentsPendingProcessing,
  headerComponent,
  loading,
  name,
  noChecklist,
  withDocumentSelector
}) => {
  const { documentTab } = useQString()
  const { pathname } = useLocation()

  const [activeParentDataPoint, setActiveParentDataPoint] = useState<DataPoint | null>(null)
  const [activeTableId, setActiveTableId] = useState<_ID>(null)
  const [documentPagePanelWidth, setDocumentPagePanelWidth] = useDocumentPanelWidth()

  const activeDocumentId = useMemo(() => (documentTab as string) || (documents[0]?.id as string) || null, [documentTab, documents])
  const currentUser = useCurrentUser()
  const dataTableMatchingOpening = useOpening()

  const isDataTableMatchingDemoEnabled =
    currentUser?.customers?.edges.some(customerEdge =>
      [CUSTOMER_DEMO_CUSTOMER, CUSTOMER_SANKET_MATCHING_TEST_ACCOUNT_2].includes(customerEdge?.node?.id || '')
    ) || false

  const context = useMemo<_DocumentPageWrapperContext>(
    () => ({
      activeDocumentId,
      activeParentDataPoint,
      activeTableId,
      dataTableMatchingOpening,
      documentPagePanelWidth,
      isDataTableMatchingDemoEnabled,
      setActiveParentDataPoint,
      setActiveTableId
    }),
    [activeDocumentId, activeParentDataPoint, activeTableId, dataTableMatchingOpening, documentPagePanelWidth, isDataTableMatchingDemoEnabled]
  )

  const documentPanel = (
    <DocumentPanel
      counterparty={counterparty}
      dealIsFinalized={dealIsFinalized}
      documents={documents}
      documentsPendingProcessing={documentsPendingProcessing}
      loading={loading}
      parentName={name}
      withSelector={!!withDocumentSelector}
    />
  )

  const customerAndAssigneeView = (
    <SplitPane
      defaultSize={documentPagePanelWidth}
      minSize={DEFAULT_PANEL_WIDTH_FOR_SMALL_DEVICES}
      onChange={size => {
        localStorage.setItem('documentPagePanelWidth', String(size))

        setDocumentPagePanelWidth(size)
      }}
      pane1Style={{ zIndex: 1003 }}
      pane2Style={{ overflowX: 'auto' }}
    >
      {dataPointPanel}

      {documentPanel}
    </SplitPane>
  )

  return (
    <>
      {name && (
        <Helmet>
          <title>{name} | Klarity</title>
        </Helmet>
      )}

      <main
        className={css.documentPage}
        data-active-document-id={activeDocumentId || ''}
        style={{ zIndex: pathname.includes('/attachments/') ? Z_INDEX_HEADER : Z_INDEX_1 }}
      >
        <h1 className={css.screenReaderOnly}>{`${counterparty ? 'Deal' : 'Document'} – ${name}`}</h1>

        {headerComponent}

        <div className={css.documentPageInner}>
          <DocumentPageWrapperContext.Provider value={context}>
            {noChecklist ? documentPanel : customerAndAssigneeView}

            {/* Below is the attachment preview overlay. It was moved here so that it can have a z-index not bound to one of the SplitPane panels */}
            <Route path={['/deals/:dealId/attachments/:attachmentId', '/documents/:documentId/attachments/:attachmentId']}>
              <AttachmentOverlay />
            </Route>
          </DocumentPageWrapperContext.Provider>
        </div>
      </main>
    </>
  )
}
