import { captureError } from '../../utils/sentry'
import { useLocation } from 'react-router-dom'
import {
  useRenameAttachmentMutation,
  useRenameDealAttachmentMutation,
  useRenameDealMutation,
  useRenameDocumentAttachmentMutation,
  useRenameDocumentMutation
} from '../../graphql/codegen/hooks'
import Button from '../Button'
import FreeText from '../DatapointInput/FreeText'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import css from './style.module.scss'

export default function RenameFile({
  attachmentId,
  closeModal,
  dealId,
  documentId,
  fileAlias,
  fileName,
  fileType,
  setLoading,
  setLoadingMessage,
  showTitle
}: {
  attachmentId?: string
  closeModal: any
  dealId?: string
  documentId?: string
  fileAlias?: string
  fileName?: string
  fileType: string
  loading?: boolean
  setLoading?: any
  setLoadingMessage?: any
  showTitle?: boolean
}) {
  const location = useLocation()
  const [value, setValue] = useState<string>('')
  const [isFocused, setFocused] = useState(false)
  const [error, setError] = useState<string>('')
  const [modalOption, setModalOption] = useState<string>('Rename')
  const [removeAlias, setRemoveAlias] = useState(false)

  const [renameDealMutation, { loading: renameDealLoading }] = useRenameDealMutation({
    onCompleted: () => {
      handleCompleted()
    }
  })
  const [renameDocumentMutation, { loading: renameDocumentLoading }] = useRenameDocumentMutation({
    onCompleted: () => {
      handleCompleted()
    }
  })
  const [renameDealAttachmentMutation, { loading: renameDealAttachmentLoading }] = useRenameDealAttachmentMutation({
    onCompleted: () => {
      handleCompleted()
    }
  })
  const [renameDocumentAttachmentMutation, { loading: renameDocumentAttachmentLoading }] = useRenameDocumentAttachmentMutation({
    onCompleted: () => {
      handleCompleted()
    }
  })
  const [renameAttachmentMutation, { loading: renameAttachmentMutationLoading }] = useRenameAttachmentMutation({
    onCompleted: () => {
      handleCompleted()
    }
  })

  const handleCompleted = () => {
    setLoading(false)
    setLoadingMessage('')
    setError('')
    // setValue('');
    setModalOption('Success')
  }

  const handleRename = () => {
    setError('')
    try {
      const newName = formatName()
      if (newName) {
        if (newName === fileName) {
          setRemoveAlias(true)
        }
        if (attachmentId && fileType === 'Attachment') {
          if (location?.pathname?.includes('dashboard')) {
            renameAttachmentMutation({ variables: { attachment_document_id: attachmentId, name: newName } })
          } else {
            if (dealId) {
              renameDealAttachmentMutation({ variables: { dealId, attachmentId, edit_type: 'ALIAS', name: newName } })
            } else if (documentId) {
              renameDocumentAttachmentMutation({ variables: { documentId, attachmentId, edit_type: 'ALIAS', name: newName } })
            } else {
              setError('Deal or Document id is missing')
            }
          }
        } else if (dealId && fileType === 'Deal') {
          renameDealMutation({ variables: { dealId, edit_type: 'ALIAS', name: newName } })
        } else if (documentId && fileType === 'Document') {
          renameDocumentMutation({ variables: { documentId, edit_type: 'ALIAS', name: newName } })
        } else {
          setError('File id or type is missing')
        }
      } else {
        setError('Error formatting new name')
      }
    } catch (error) {
      captureError(error)

      setError(`Error: ${error}`)
    }
  }

  // grab extension from original filename, if no match either edit it to match or throw error
  const formatName = () => {
    if (!value) {
      return fileName
    } // sending original fileName to the backend removes the alias if any set
    else if (fileType === 'Deal') {
      return value
    }
    const split = fileName?.split('.') || []
    const extension = `.${split[1]}`
    const newValueSplit = value?.split('.')
    const newValueExtension = `.${newValueSplit[1]}`

    if (value.includes(extension)) {
      return value
    } else if (newValueSplit?.length > 1 && newValueExtension !== extension) {
      throw new Error('New name cannot contain periods and file extension must match the original')
    } else if (newValueSplit?.length === 1) {
      return `${value}${extension}`
    } // append original extension if none found
  }

  useEffect(() => {
    if (renameDealLoading || renameDocumentLoading || renameDealAttachmentLoading || renameDocumentAttachmentLoading || renameAttachmentMutationLoading) {
      setLoading(true)
      setLoadingMessage('Renaming…')
    } else {
      setLoading(false)
      setLoadingMessage('')
    }
  }, [
    setLoading,
    setLoadingMessage,
    renameDealLoading,
    renameDocumentLoading,
    renameDealAttachmentLoading,
    renameDocumentAttachmentLoading,
    renameAttachmentMutationLoading
  ])

  useEffect(() => {
    if (error) {
      setLoading(false)
      setLoadingMessage('')
    }
  }, [error, setLoading, setLoadingMessage])

  return (
    <div className={css.renameParent}>
      {modalOption === 'Rename' && (
        <>
          {showTitle && fileType && <h3>{`Rename ${fileType}`}</h3>}
          {fileAlias ? (
            <>
              <h4>{'Original name:'}</h4>
              <p>{fileName}</p>
              <h4>{'Current name:'}</h4>
              <p>{fileAlias}</p>
            </>
          ) : (
            <>
              <h4>{'Current name:'}</h4>
              <p>{fileName}</p>
            </>
          )}
          <h4 id={css.newName}>{'Enter a new name:'}</h4>
          <FreeText
            isFocused={isFocused}
            onChange={(e: SyntheticEvent<HTMLTextAreaElement>) => {
              setValue(e.currentTarget.value)
            }}
            placeholder={`Enter a new file name`}
            setFocused={setFocused}
            value={value}
          />
          {error && <p className="error">{error}</p>}
          <div className={css.modalButtonRow}>
            <Button onClick={closeModal} variant={'secondary'}>
              Cancel
            </Button>
            {!value && fileAlias ? (
              <Button onClick={handleRename}>{'Remove Alias'}</Button>
            ) : (
              <Button disabled={!value} onClick={handleRename} title={!value ? 'Enter a new name' : ''}>
                Submit
              </Button>
            )}
          </div>
        </>
      )}
      {modalOption === 'Success' && (
        <>
          <h4>{'Success!'}</h4>
          {removeAlias ? (
            <p>{`The alias has been removed from the ${fileType}.`}</p>
          ) : (
            <>
              <p>{`${fileType} has been renamed to:`}</p>
              <p>{value}</p>
            </>
          )}
          <div className={css.modalButtonRow}>
            <Button onClick={closeModal} variant={'secondary'}>{`Close`}</Button>
          </div>
        </>
      )}
    </div>
  )
}
