import { ApolloCache, FetchResult } from '@apollo/client'
import { Comment as CommentType, DataPoint } from '../../graphql/codegen/schemas'
import {
  DataPointsForGroupDocument,
  NestedChildDataPointsDocument,
  NotesTabCountDocument,
  useDeleteCommentMutation,
  useEditCommentTextMutation,
  useMarkCommentAsPermanentMutation,
  useMarkCommentAsResolvedMutation,
  usePinCommentMutation
} from '../../graphql/codegen/hooks'
import { DeleteCommentMutation, NestedChildDataPointFragmentsFragment } from '../../graphql/codegen/operations'
import { FiCheckCircle, FiLock } from 'react-icons/fi'
import { MoreMenuOld } from '../../components/MoreMenuOld'
import { isEmpty } from 'lodash'
import { useDocumentPageWrapperContext } from '../../components/DocumentPageWrapper'
import { useParams } from 'react-router-dom'
import Comment from '../../components/Comment'
import Loader from '../../components/Loader'
import React, { useEffect, useState } from 'react'
import WithTooltip from '../../components/WithTooltip'
import css from './style.module.scss'
import useCurrentUser from '../../hooks/useCurrentUser'

interface Props {
  [x: string]: any
  comment: CommentType
  dataPoint: DataPoint
  dealIsFinalized?: boolean
  isNestedChildDataPoint?: boolean
}

export default function CommentContainer({ comment, dataPoint, dealIsFinalized, isNestedChildDataPoint, ...rest }: Props) {
  const { activeParentDataPoint } = useDocumentPageWrapperContext()
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const { created_by, id, type } = comment
  const [isEditing, setIsEditing] = useState(false)
  const [menuItemList, setMenuItemList] = useState<any>([])
  const awaitRefetchQueries = true
  const currentUser = useCurrentUser()
  const isMine = currentUser?.id === created_by?.id
  const refetchQueries = [{ query: NotesTabCountDocument, variables: { resourceId: dealId || documentId! } }]

  // Manual update required because the server does not return the updated `dataPoint` object on the mutation response.
  const updateCacheAfterDelete = (cache: ApolloCache<DeleteCommentMutation>, mutationResult: FetchResult) => {
    if (!mutationResult.data?.delete_comment?.status === true) return

    const query = isNestedChildDataPoint ? NestedChildDataPointsDocument : DataPointsForGroupDocument

    const variables = isNestedChildDataPoint
      ? { parentDataPointId: activeParentDataPoint?.id }
      : { group: dataPoint.data_point_field?.group, resourceId: dealId || documentId! }

    const data = cache.readQuery({ query, variables }) as
      | { nested_children_data_points: NestedChildDataPointFragmentsFragment[] }
      | { data_points_for_group: DataPoint[] }

    if (!data) return

    const dataPoints = 'nested_children_data_points' in data ? data.nested_children_data_points : data.data_points_for_group

    const dataPointIndex = dataPoints.findIndex(({ id }) => id === dataPoint.id)

    const updatedDataPoint = { ...dataPoints[dataPointIndex], comments: dataPoints[dataPointIndex].comments?.filter(comment => comment?.id !== id) }

    const updatedDataPoints = [...dataPoints.slice(0, dataPointIndex), updatedDataPoint, ...dataPoints.slice(dataPointIndex + 1)]

    cache.writeQuery({
      query,
      variables,
      data: isNestedChildDataPoint ? { nested_children_data_points: updatedDataPoints } : { data_points_for_group: updatedDataPoints }
    })
  }

  const [deleteMutation, { loading: deleteMutationLoading }] = useDeleteCommentMutation({
    refetchQueries,
    awaitRefetchQueries,
    update: updateCacheAfterDelete,
    variables: { commentId: id }
  })

  const [markPermanentMutation, { loading: permanentMutationLoading }] = useMarkCommentAsPermanentMutation({ refetchQueries, awaitRefetchQueries })

  const [markResolvedMutation, { loading: resolvedMutationLoading }] = useMarkCommentAsResolvedMutation({ refetchQueries, awaitRefetchQueries })

  const [editMutation, editState] = useEditCommentTextMutation({
    onCompleted: () => setIsEditing(false),
    refetchQueries,
    awaitRefetchQueries
  })

  const [pinMutation, { loading: pinMutationLoading }] = usePinCommentMutation({ refetchQueries, awaitRefetchQueries })

  const handleSave = (text: string) => {
    editMutation({ variables: { commentId: id, text } })
  }

  const getMenuItemList = () => {
    const items: any = [
      { label: 'Resolve', onClick: () => markResolvedMutation({ variables: { commentId: id } }) },
      { label: 'Make Permanent', onClick: () => markPermanentMutation({ variables: { commentId: id } }) }
    ]
    if (!comment?.is_pinned) {
      items.push({ label: 'Pin', onClick: () => pinMutation({ variables: { commentId: id, pin_type: 'PIN' } }) })
    }
    if (comment?.is_pinned) {
      items.push({ label: 'Unpin', onClick: () => pinMutation({ variables: { commentId: id, pin_type: 'UNPIN' } }) })
    }
    if (isMine || type === 'SHARED') {
      items.push({ label: 'Edit', onClick: () => setIsEditing(true) })
      if (!comment.is_pinned) {
        items.push({ label: 'Delete', onClick: () => deleteMutation() })
      }
    }
    setMenuItemList([...items])
  }

  // eslint-disable-next-line
  useEffect(() => { getMenuItemList() }, [comment])

  const showOptions = !dealIsFinalized && type !== 'RESOLVED' && type !== 'PERMANENT'

  const renderActions = () => {
    if (type && ['RESOLVED', 'PERMANENT'].includes(type)) {
      return (
        <WithTooltip content={`Marked as ${type.toLowerCase()}.`}>
          <div className={css.lockedIcon}>{type === 'RESOLVED' ? <FiCheckCircle /> : <FiLock />}</div>
        </WithTooltip>
      )
    }
    if (deleteMutationLoading || editState.loading || permanentMutationLoading || resolvedMutationLoading || pinMutationLoading) {
      return (
        <div>
          <Loader size="s" />
        </div>
      )
    }
    return showOptions && !isEmpty(menuItemList) && <MoreMenuOld menuItems={menuItemList} withIcon />
  }

  const props = { isEditing, setIsEditing, editState, handleSave, renderActions }

  return (
    <Comment
      comment={comment}
      id={comment.id}
      isNestedChildDataPoint={isNestedChildDataPoint}
      {...props}
      {...rest}
      author={isMine ? 'You' : comment.created_by?.first_name}
      date={comment.last_updated_at || comment.created_at}
      text={comment.text || ''}
    />
  )
}
