import { FiTrash2, FiXSquare } from 'react-icons/fi'
import { Typography } from '@mui/material'
import Button from '../../../../../../../components/Button'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import React, { FC, KeyboardEvent, SyntheticEvent, useState } from 'react'
import css from './style.module.scss'

// types

type _AdditionalInfoInputProps = { currentValue?: string; handleData: any; newValue?: string }

// components

export const AdditionalInfoInput: FC<_AdditionalInfoInputProps> = ({ currentValue, handleData, newValue }) => {
  const [isFocused, setFocused] = useState(false)
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (isFocused && e?.key === 'Enter') {
      setFocused(false)
      // @ts-ignore
      e?.target?.blur()
    }
  }

  return (
    <div className={css.inputWrapper} style={{ marginTop: 8 }}>
      <Typography component="label" htmlFor="description-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        Additional Info
      </Typography>

      <FreeText
        id="description-input"
        isFocused={isFocused}
        onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => handleData('description', event.currentTarget.value)}
        onKeyDown={handleKeyDown}
        placeholder={currentValue && newValue !== '@@_DELETE_THIS_ITEM' ? currentValue : 'Enter additional info about this field'}
        setFocused={setFocused}
        value={newValue && newValue !== '@@_DELETE_THIS_ITEM' ? newValue : ''}
      />

      {newValue && newValue !== '@@_DELETE_THIS_ITEM' && (
        <Button className={css.cciButton} icon={<FiXSquare />} onClick={() => handleData('description', null)} />
      )}

      {currentValue && newValue !== '@@_DELETE_THIS_ITEM' && (
        <Button
          icon={<FiTrash2 color="#fc3535" />}
          onClick={() => handleData('description', '@@_DELETE_THIS_ITEM')}
          style={{ margin: '-1px 0 0 4px', borderRadius: '4px' }}
        />
      )}
    </div>
  )
}
