import { Alert, Box, Button, IconButton, Typography } from '@mui/material'
import { ButtonLoadingSmall } from '../ButtonLoading'
import { DealRerunExtractionResult, DealsRerunExtractionResult, DocumentsRerunExtractionResult } from '../../graphql/codegen/schemas'
import { ExtractionStatuses } from '../../utils/extractionUtils'
import { Sources } from '../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/CreateInputs/SourceInput'
import { _DataPointsGrouped } from './ChecklistTab'
import { isEmpty } from 'lodash'
import { useAppContext } from '../../app'
import { useDealDocumentsExtractionStatusLazyQuery, useDocumentExtractionStatusLazyQuery, useRerunExtractionMutation } from '../../graphql/codegen/hooks'
import { useDocumentPageWrapperContext } from '../DocumentPageWrapper'
import CloseIcon from '@mui/icons-material/Close'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

// types

type RerunExtractionBannerProps = { dataPointsGrouped: _DataPointsGrouped; dealId?: string; documentId?: string; isLoadingDataPoints: boolean }

// components

export const RerunExtractionBanner: FC<RerunExtractionBannerProps> = ({ dataPointsGrouped, dealId, documentId, isLoadingDataPoints }) => {
  const { documentPagePanelWidth } = useDocumentPageWrapperContext()
  const { setErrorMessage } = useAppContext()

  const [extractionStatus, setExtractionStatus] = useState<ExtractionStatuses | null>(null)
  const [hasTransitionedToComplete, setHasTransitionedToComplete] = useState(false) // Handle the distinction between the initial state vs. subsequent completion after re-running extraction.
  const [isVisible, setIsVisible] = useState(false)
  const [shouldPoll, setShouldPoll] = useState(false)

  const isDealPage = Boolean(dealId)
  const resourceId = dealId || documentId!

  const alertSx = useMemo(
    () => ({
      alignItems: 'center',
      borderRadius: 0,
      left: `${documentPagePanelWidth + 1}px`,
      position: 'absolute',
      top: 48,
      width: `calc(100vw - ${documentPagePanelWidth + 1}px)`,
      zIndex: 2,
      '& .MuiAlert-action': { mr: 0, pb: 0.5 }
    }),
    [documentPagePanelWidth]
  )

  const [getDealDocumentsExtractionStatus, { data: dealPageStatusData, loading: isDealPageStatusLoading }] = useDealDocumentsExtractionStatusLazyQuery({
    fetchPolicy: 'network-only',
    variables: { dealId: resourceId }
  })

  const [getDocumentExtractionStatus, { data: documentPageStatusData, loading: isDocumentPageStatusLoading }] = useDocumentExtractionStatusLazyQuery({
    fetchPolicy: 'network-only',
    variables: { documentId: resourceId }
  })

  const isLoading = isDealPageStatusLoading || isDocumentPageStatusLoading || isLoadingDataPoints

  const [rerunExtraction, { loading: isRerunExtractionLoading }] = useRerunExtractionMutation({
    onCompleted: data => {
      isDealPage
        ? handleDealPageRerunExtractionResult(
            (data?.rerun_extraction?.rerun_extraction_result as DealsRerunExtractionResult)?.deals_rerun_extraction_result?.[0] || null
          )
        : handleDocumentPageRerunExtractionResult(data?.rerun_extraction?.rerun_extraction_result as DocumentsRerunExtractionResult | null)
    },
    variables: { rerunExtractionInput: { [isDealPage ? 'deal_ids' : 'document_ids']: [resourceId] } }
  })

  const getExtractionStatus = useCallback(
    () => (isDealPage ? getDealDocumentsExtractionStatus() : getDocumentExtractionStatus()),
    [isDealPage, getDealDocumentsExtractionStatus, getDocumentExtractionStatus]
  )

  const handleDealPageRerunExtractionResult = useCallback(
    (result?: DealRerunExtractionResult | null) => {
      if (!isEmpty(result?.already_processing_documents) || !isEmpty(result?.queued_documents)) {
        setExtractionStatus(ExtractionStatuses.PROCESSING)
      } else if (result?.failed_to_queue_documents?.some(Boolean)) {
        setErrorMessage('Failed to queue one or more documents in the deal for extraction')
      }
    },
    [setErrorMessage]
  )

  const handleDocumentPageRerunExtractionResult = useCallback(
    (result?: DocumentsRerunExtractionResult | null) => {
      if (result?.already_processing_documents.includes(resourceId) || result?.queued_documents.includes(resourceId)) {
        setExtractionStatus(ExtractionStatuses.PROCESSING)
      } else if (result?.failed_to_queue_documents.includes(resourceId)) {
        setErrorMessage('Failed to queue document for extraction')
      }
    },
    [resourceId, setErrorMessage]
  )

  const setBannerVisibility = useCallback(() => {
    if (extractionStatus === ExtractionStatuses.PROCESSING || (extractionStatus === ExtractionStatuses.COMPLETE && hasTransitionedToComplete)) {
      return setIsVisible(true)
    }

    setIsVisible(
      Object.values(dataPointsGrouped).some(group =>
        group.some(dataPoint => {
          const { data_point_field: dataPointField, last_updated_at: lastUpdatedAt, last_updated_by: lastUpdatedBy } = dataPoint

          const isDataPointUpdatedByKlarityBot = lastUpdatedBy?.first_name === 'Klarity' && lastUpdatedBy?.last_name === 'Bot'
          const isInternalDataPointField = dataPointField?.source === Sources.INTERNAL
          const lastDataPointUpdateTime = new Date(lastUpdatedAt)
          const lastDataPointFieldPublishingTime = dataPointField?.last_published_at ? new Date(dataPointField?.last_published_at) : null

          return (
            isDataPointUpdatedByKlarityBot &&
            isInternalDataPointField &&
            lastDataPointFieldPublishingTime &&
            lastDataPointUpdateTime < lastDataPointFieldPublishingTime
          )
        })
      )
    )
  }, [dataPointsGrouped, extractionStatus, hasTransitionedToComplete])

  const setDealPageStatus = useCallback(() => {
    const documentStatusList = (dealPageStatusData?.deals?.edges[0]?.node?.documents?.edges || []).map(edge => edge?.node?.extraction_status)

    if (documentStatusList.every(status => status === ExtractionStatuses.COMPLETE)) {
      setExtractionStatus(previousStatus => {
        if (previousStatus && previousStatus !== ExtractionStatuses.COMPLETE) setHasTransitionedToComplete(true)

        return ExtractionStatuses.COMPLETE
      })
    } else if (documentStatusList.some(status => status === ExtractionStatuses.PROCESSING)) {
      setExtractionStatus(ExtractionStatuses.PROCESSING)
    } else if (documentStatusList.some(status => status === ExtractionStatuses.FAILED)) {
      setExtractionStatus(ExtractionStatuses.FAILED)
    }
  }, [dealPageStatusData])

  const setDocumentPageStatus = useCallback(() => {
    const status = documentPageStatusData?.documents?.edges[0]?.node?.extraction_status

    setExtractionStatus(previousStatus => {
      if (previousStatus && previousStatus !== ExtractionStatuses.COMPLETE) setHasTransitionedToComplete(true)

      return status as ExtractionStatuses
    })

    setShouldPoll(status === ExtractionStatuses.PROCESSING)
  }, [documentPageStatusData])

  // effects

  useEffect(() => {
    getExtractionStatus()
  }, [getExtractionStatus])

  useEffect(() => {
    if (dealPageStatusData) {
      setDealPageStatus()
    } else if (documentPageStatusData) {
      setDocumentPageStatus()
    }
  }, [dealPageStatusData, documentPageStatusData, setDealPageStatus, setDocumentPageStatus])

  useEffect(() => {
    if (!isLoading && !isEmpty(dataPointsGrouped)) setBannerVisibility()
  }, [dataPointsGrouped, extractionStatus, isLoading, setBannerVisibility])

  useEffect(() => {
    if (extractionStatus) setShouldPoll(extractionStatus === ExtractionStatuses.PROCESSING)
  }, [extractionStatus])

  useEffect(() => {
    if (shouldPoll) {
      const intervalId = setInterval(getExtractionStatus, 30000)

      return () => clearInterval(intervalId)
    }
  }, [shouldPoll, getExtractionStatus])

  useEffect(() => {
    if (extractionStatus === ExtractionStatuses.COMPLETE && hasTransitionedToComplete) {
      const timeoutId = setTimeout(() => window.location.reload(), 5000)

      return () => clearTimeout(timeoutId)
    }
  }, [extractionStatus, hasTransitionedToComplete])

  // render

  if (!isVisible) return null

  if (extractionStatus === ExtractionStatuses.PROCESSING) {
    return (
      <Alert onClose={() => setIsVisible(false)} severity="info" sx={alertSx}>
        Extraction is in progress. Do not modify any field values until the extraction process is complete.
      </Alert>
    )
  }

  if (extractionStatus === ExtractionStatuses.COMPLETE && hasTransitionedToComplete) {
    return (
      <Alert severity="success" sx={alertSx}>
        Extraction complete. This page will automatically reload with the updated field values.
      </Alert>
    )
  }

  return (
    <Alert
      action={
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
          <Button color="warning" disabled={isRerunExtractionLoading} onClick={() => rerunExtraction()} variant="contained">
            <Typography color="inherit" sx={{ opacity: isRerunExtractionLoading ? 0.5 : 1 }} variant="body2">
              Re-run Extraction
            </Typography>

            {isRerunExtractionLoading && <ButtonLoadingSmall />}
          </Button>

          <IconButton aria-label="close" color="inherit" disabled={isRerunExtractionLoading} onClick={() => setIsVisible(false)} size="small">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>
      }
      severity="warning"
      sx={alertSx}
    >
      There are outdated fields in this {isDealPage ? 'deal' : 'document'}. Re-run the extraction process to update the fields.
    </Alert>
  )
}
