import { useEffect, useState } from 'react'
import type { RefObject } from 'react'

export const useOverflowDetection = (elementRef?: RefObject<HTMLElement>) => {
  const [isOverflowingHorizontally, setIsOverflowingHorizontally] = useState(false)
  const [isOverflowingVertically, setIsOverflowingVertically] = useState(false)

  useEffect(() => {
    const element = elementRef?.current

    if (element) {
      setIsOverflowingHorizontally(element.scrollWidth > element.clientWidth)
      setIsOverflowingVertically(element.scrollHeight > element.clientHeight)
    }
  }, [elementRef])

  return { isOverflowingHorizontally, isOverflowingVertically }
}
