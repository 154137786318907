import { ActionType } from '../../../../../../graphql/codegen/schemas'
import { ActiveComponents, useCciMainContext } from '../../../../CCI_Main'
import { ConfigureField } from './ConfigureField'
import { EditDocumentTypes } from '../ChecklistTab/EditDocumentTypes'
import { Features, Permissions, useUserAccess } from '../../../../../../hooks/useUserAccess'
import { Steps } from './ConfigurationSteps'
import { useActionsToExtractionMethodMappingQuery, useCciDocumentTypesLazyQuery, useCciGroupsLazyQuery } from '../../../../../../graphql/codegen/hooks'
import { useContextInit } from '../../../../../../hooks/useContextInit'
import { useExtractionMethodConfiguration } from './inputs/ExtractionMethodInput'
import React, { Dispatch, FC, SetStateAction, createContext, useCallback, useEffect, useMemo, useState } from 'react'
import ViewField from '../ChecklistTab/ViewField'

// types

type _ActionTypeMap = { [key: string]: ActionType }

type _CciChecklistGptContext = {
  actionTypeMap: _ActionTypeMap
  actionTypeMapLoading?: boolean
  activeStep?: number | undefined
  docTypesLoading: boolean
  documentTypes?: any
  extractionMethodId?: string
  extractionMethodList: { label: string; value: string }[]
  fieldValues?: { [key: string]: any }
  groupOptions?: any
  groupsLoading: boolean
  isCreateExtractionMethodLoading?: boolean
  isCreateView: boolean
  isEditExtractionMethodLoading?: boolean
  isEditView?: boolean
  setActiveStep: Dispatch<SetStateAction<number | undefined>>
  setExtractionMethodId: Dispatch<SetStateAction<string | undefined>>
  setFieldValues: Dispatch<SetStateAction<{ [key: string]: any } | undefined>>
  submitExtractionMethod: (dataPointFieldId?: string) => void
  updateFieldValue: (fieldName: string, value: any) => void
}

// context

export const CciChecklistGptContext = createContext<_CciChecklistGptContext | null>(null)

// hooks

export const useCciChecklistGptContext = () => useContextInit(CciChecklistGptContext)

// components

export const CCI_RightPanel_ChecklistGptTab: FC = () => {
  const { activeComponent, selectedItem } = useCciMainContext()

  const [activeStep, setActiveStep] = useState<number | undefined>(Steps.CONFIGURATION_DETAILS)
  const [documentTypes, setDocumentTypes] = useState<any>(false)
  const [fieldValues, setFieldValues] = useState<{ [key: string]: any } | undefined>(undefined)
  const [groupOptions, setGroupOptions] = useState<any>(false)

  const {
    extractionMethodId,
    extractionMethodList,
    isCreateExtractionMethodLoading,
    isEditExtractionMethodLoading,
    setExtractionMethodId,
    submitExtractionMethod
  } = useExtractionMethodConfiguration()

  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })
  const hasLimitedEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD_LIMITED })

  const isCreateView = activeComponent === ActiveComponents.CREATE_FIELD
  const isEditView = !isCreateView

  const [getGroups, { data: groupData, loading: groupsLoading }] = useCciGroupsLazyQuery()
  const [getDocumentTypes, { data: docTypesData, loading: docTypesLoading }] = useCciDocumentTypesLazyQuery()

  const { data: actionsToExtractionMethodMappingData } = useActionsToExtractionMethodMappingQuery()

  const actionTypeMap = useMemo<_ActionTypeMap>(
    () =>
      actionsToExtractionMethodMappingData?.actions_to_extraction_method_mapping?.reduce(
        (previous, current) => (current ? { ...previous, [current.id]: current } : previous),
        {}
      ) || {},
    [actionsToExtractionMethodMappingData]
  )

  const updateFieldValue = useCallback((fieldName: string, value: any) => {
    if (fieldName && !value && value !== false) {
      setFieldValues((previous: any) => {
        const updatedObject = { ...previous }

        delete updatedObject[fieldName]

        return { ...updatedObject }
      })
    } else {
      switch (fieldName) {
        case '':
        case undefined:
        case null:
          console.error('ConfigureField updateFieldValue error: Must provide a valid option')

          return

        case 'deleted_options':
          return setFieldValues((previous: any) => ({ ...previous, deleted_options: [...value] }))

        case 'options':
          return setFieldValues((previous: any) => ({ ...previous, options: [...value] }))

        default:
          return setFieldValues((previous: any) => ({ ...previous, [fieldName]: value }))
      }
    }
  }, [])

  const context = useMemo<_CciChecklistGptContext>(
    () => ({
      actionTypeMap,
      activeStep,
      docTypesLoading,
      documentTypes,
      extractionMethodId,
      extractionMethodList,
      fieldValues,
      groupOptions,
      groupsLoading,
      isCreateExtractionMethodLoading,
      isCreateView,
      isEditExtractionMethodLoading,
      isEditView,
      setActiveStep,
      setExtractionMethodId,
      setFieldValues,
      submitExtractionMethod,
      updateFieldValue
    }),
    [
      actionTypeMap,
      activeStep,
      docTypesLoading,
      documentTypes,
      extractionMethodId,
      extractionMethodList,
      fieldValues,
      groupOptions,
      groupsLoading,
      isCreateExtractionMethodLoading,
      isCreateView,
      isEditExtractionMethodLoading,
      isEditView,
      setExtractionMethodId,
      submitExtractionMethod,
      updateFieldValue
    ]
  )

  // effects

  useEffect(() => {
    setExtractionMethodId(undefined)
    setFieldValues(undefined)
  }, [activeComponent, selectedItem, setExtractionMethodId])

  useEffect(() => {
    if (hasFullEditAccess || hasLimitedEditAccess) {
      getGroups()
      getDocumentTypes()
    }
  }, [hasFullEditAccess, hasLimitedEditAccess, getGroups, getDocumentTypes])

  useEffect(() => {
    setGroupOptions(groupData?.cci_groups?.map(item => ({ value: item, label: item })))
  }, [groupData])

  useEffect(() => {
    setDocumentTypes(docTypesData?.cci_document_types?.map((item: any) => ({ label: item?.name, value: item?.name })))
  }, [docTypesData])

  // render

  return (
    <CciChecklistGptContext.Provider value={context}>
      {(!activeComponent || activeComponent === ActiveComponents.DELETE_FIELD || activeComponent === ActiveComponents.EDIT_FIELD) && (
        <>{hasFullEditAccess || hasLimitedEditAccess ? <ConfigureField /> : <ViewField />}</>
      )}

      {activeComponent === ActiveComponents.CREATE_FIELD && <ConfigureField />}

      {activeComponent === ActiveComponents.EDIT_DOCUMENT_TYPES && <EditDocumentTypes />}
    </CciChecklistGptContext.Provider>
  )
}
