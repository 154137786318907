import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, alpha } from '@mui/material'
import { Z_INDEX_MODAL } from '../../utils/styleUtils'
import { _SystemLearningOutcome } from './FeedbackLearning'
import { deepPurple } from '@mui/material/colors'
import { useFeedbackPageContext } from '../../pages/FeedbackPage'
import React, { FC } from 'react'

// types

export type _SystemPromptData = { systemLearningOutcomeList?: _SystemLearningOutcome[]; userLearningOutcome?: string } | null

// constants

export const promptSx = { color: deepPurple[900], fontFamily: 'revert', lineHeight: 1.5, overflowWrap: 'break-word', whiteSpace: 'break-spaces' }

// components

export const SystemPromptDialog: FC = () => {
  const { setSystemPromptData, systemPromptData, systemPromptDialogOpening } = useFeedbackPageContext()
  const { systemLearningOutcomeList, userLearningOutcome } = systemPromptData || {}

  const handleClose = () => {
    setSystemPromptData(null)

    systemPromptDialogOpening.close()
  }

  if (!systemLearningOutcomeList || !userLearningOutcome) return null

  return (
    <Dialog aria-labelledby="feedback-system-prompt-dialog-title" onClose={handleClose} open={systemPromptDialogOpening.isOpen} sx={{ zIndex: Z_INDEX_MODAL }}>
      <DialogTitle id="feedback-system-prompt-dialog-title">Inspect System Prompt</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.25 }}>
          <Typography sx={{ fontWeight: 600 }} variant="caption">
            Klarity’s Learning
          </Typography>

          <Typography sx={{ bgcolor: alpha(deepPurple[50], 0.3), borderRadius: 2, color: deepPurple[900], p: 1.5 }} variant="body2">
            {userLearningOutcome}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.25 }}>
          <Typography sx={{ fontWeight: 600 }} variant="caption">
            System Prompt
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.25 }}>
            {systemLearningOutcomeList.map((systemLearningOutcome, index) => (
              <Typography component="code" key={index} sx={promptSx} variant="caption">
                {systemLearningOutcome.content}
              </Typography>
            ))}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
