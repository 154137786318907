import { useAdvancedDashboardQuery, useDocumentCountQuery } from '../graphql/codegen/hooks'
import useCurrentUser from './useCurrentUser'

export function getAssignedToMeFilters({
  currentUser,
  sortConfig = [],
  pageLength = 10,
  offset = 0
}: {
  currentUser?: any
  offset?: number
  pageLength?: number
  sortConfig?: any[]
}) {
  return {
    filters: `{"$and":[{"internal_status": "PROCESSING"},{"internal_assignee":"ID__${currentUser?.id}"}]}`,
    chosenDataPointFieldIds: [],
    sortConfig: sortConfig.map((sortObj: any) => JSON.stringify(sortObj)),
    size: pageLength,
    offset
  }
}

export default (config: { offset: number; pageLength: number; sortConfig: any[] }) => {
  const currentUser = useCurrentUser()
  if (!currentUser) return { data: null, loading: true }

  const variables = getAssignedToMeFilters({ ...config, currentUser })
  const { data: dashboardData, loading } = useAdvancedDashboardQuery({
    // Do not add startTime to this query- causes infinite refetches, something to do with the fetchPolicy: no-cache, maybe relayStylePagination (see: apolloClient.ts)
    variables,
    fetchPolicy: 'network-only'
  })

  const { data: documentCountData } = useDocumentCountQuery({
    variables,
    fetchPolicy: 'network-only' // should match the assigned to me query
  })

  return { dashboardData, documentCountData, loading }
}
