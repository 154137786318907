import 'tippy.js/dist/tippy.css'
import { Box, Chip, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { ConfidenceStatus } from '../Confidence'
import {
  DealAttachmentsDocument,
  DealsDocument,
  useDeleteDocumentsFromDealMutation,
  useDocumentTypesQuery,
  useMarkDocumentAsReviewedMutation,
  useMoveDocumentToDealAttachmentMutation,
  useUpdateDocumentTypeMutation
} from '../../graphql/codegen/hooks'
import { DeleteDocumentsFromDealMutation, DocumentPendingProcessingFragment } from '../../graphql/codegen/operations'
import { Document } from '../../graphql/codegen/schemas'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { FiCheck, FiGlobe, FiPlus } from 'react-icons/fi'
import { Link, useHistory, useParams } from 'react-router-dom'
import { MdOutlineDocumentScanner } from 'react-icons/md'
import { MoreMenu, _MenuItem } from '../MoreMenu'
import { Toast } from '../Toast'
import { deepOrange, grey } from '@mui/material/colors'
import { formatTimeAgo } from '../../utils/datetimeUtils'
import { isEmpty } from 'lodash'
import { useIsKlarityEmployee } from '../../hooks/useCurrentUser'
import { useModalContext } from '../../app'
import { useOpening } from '@hoologic/use-opening'
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined'
import Button from '../Button'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import ConvertDocumentLevel from '../ModalOptions/ConvertDocumentLevel'
import DeleteDocument from '../ModalOptions/DeleteDocument'
import LinkIcon from '@mui/icons-material/Link'
import Modal from '../Modal'
import React, { FC, MouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import RenameFile from '../RenameFile'
import SelectInput from '../SelectInput'
import Tippy from '@tippyjs/react'
import WithTooltip from '../WithTooltip'
import clsx from 'clsx'
import copy from 'copy-to-clipboard'
import css from './style.module.scss'
import useSummaryDataPoints from '../../hooks/useSummaryDataPoints'

// types

type _DocumentSelectorProps = {
  currentDocumentId?: string
  dealCounterparty?: any
  dealIsFinalized?: boolean
  documents: Document[]
  documentsPendingProcessing?: DocumentPendingProcessingFragment[]
  hasDealUpdateAccess?: boolean
}

type _DocumentTabLinkProps = { children: ReactNode; isActive: boolean }
type _DocumentTypeSelectorProps = { isError?: boolean; onChange: (value: string | null) => void }
type _DocumentsPendingProcessingMenuProps = { documents: DocumentPendingProcessingFragment[] }
type _SummaryTabLinkProps = { isActive: boolean }
type _UploadDocumentButtonProps = { currentDocumentId?: string; history?: any }

// components

export const DocumentSelector: FC<_DocumentSelectorProps> = ({
  currentDocumentId,
  dealCounterparty,
  dealIsFinalized,
  documents,
  documentsPendingProcessing,
  hasDealUpdateAccess
}) => {
  const { dealId } = useParams<{ dealId: string }>()
  const [isOpen, setIsOpen] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [modalLoading, setModalLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [modalOption, setModalOption] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState<null | {
    currentDocumentType?: string | undefined
    documentAlias?: string
    documentId: string
    documentName: string
    documentTypeId?: string | null
    isAccountLevel?: boolean
  }>(null)
  const history = useHistory()
  const canDeleteDocument = useUserAccess({ feature: Features.DOCUMENT, permission: Permissions.DELETE })
  const hasAccountLevelDocumentAccess = useUserAccess({ feature: Features.DOCUMENT, permission: Permissions.ACCOUNT_LEVEL })
  const isKlarityEmployee = useIsKlarityEmployee()

  const openModal = (menuOption: string, documentId: string, documentName: string, documentAlias?: string, currentType?: string, isAccountLevel?: boolean) => {
    setIsOpen(true)
    setModalOption(menuOption)
    setModalTitle(menuOption)
    setModalContent({ documentId, documentName, documentAlias, currentDocumentType: currentType, isAccountLevel })
    if (menuOption === 'Update Document Type') {
      setIsDisabled(true)
    }
  }

  const closeModal = () => {
    if (!modalLoading) {
      setIsOpen(false)
      setIsDisabled(false)
      setModalOption('')
      setModalTitle('')
      setModalContent(null)
      setLoadingMessage('')
    }
  }

  const getMenuItemList = ({ alias, document_type, id, is_account_level, is_reviewed, isForeignDocument, name }: any): _MenuItem[] => [
    { label: `Mark as ${is_reviewed ? 'Unreviewed' : 'Reviewed'}`, onClick: () => markAsReviewed(id, !is_reviewed) },
    { label: 'Update Document Type', onClick: () => openModal('Update Document Type', id, name, alias, document_type?.name) },
    { label: 'Rename Document', onClick: () => openModal('Rename Document', id, name, alias, document_type?.name) },
    ...(is_account_level
      ? []
      : [
          {
            label: 'Convert Document to Attachment',
            onClick: () => openModal('Convert to Attachment', id, name, alias, document_type?.name)
          }
        ]),
    ...(hasAccountLevelDocumentAccess && is_account_level && !isForeignDocument
      ? [
          {
            label: 'Remove Document from Customer Level',
            onClick: () => openModal('Change Document Level', id, name, alias, document_type?.name, is_account_level)
          }
        ]
      : []),
    ...(hasAccountLevelDocumentAccess && !is_account_level && !isForeignDocument
      ? [
          {
            label: 'Convert Document to Customer Level',
            onClick: () => openModal('Change Document Level', id, name, alias, document_type?.name, is_account_level)
          }
        ]
      : []),
    { label: 'Remove Document from Deal', onClick: () => openModal('Remove Document', id, name, alias, document_type?.name) },
    ...(canDeleteDocument ? [{ label: 'Delete Document', onClick: () => openModal('Delete Document', id, name, alias, document_type?.name) }] : [])
  ]

  // switch to call correct function/args of modal submit button
  const handleSubmit = () => {
    if (modalOption === 'Update Document Type') {
      if (!modalContent?.documentTypeId) return
      const { documentId, documentTypeId } = modalContent
      updateDocType({ variables: { documentId, documentTypeId } })
      setLoadingMessage('Updating document type…')
    } else if (modalOption === 'Convert to Attachment') {
      if (modalContent?.documentId) {
        moveDocumentToDealAttachmentMutation({ variables: { dealId, documentId: modalContent?.documentId } })
        setLoadingMessage('Converting to attachment…')
      }
    } else if (modalOption === 'Remove Document') {
      if (modalContent?.documentId) {
        deleteDocumentsFromDealMutation({ variables: { dealId, documentIds: [modalContent.documentId] } })
        setLoadingMessage('Removing document…')
      }
    }
  }

  const handleSelect = (value: string | null) => {
    if (modalOption === 'Update Document Type') {
      setModalContent(prev => prev && { ...prev, documentTypeId: value })
      if (value) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }
  }

  const [deleteDocumentsFromDealMutation, { loading: mutationLoading }] = useDeleteDocumentsFromDealMutation({
    refetchQueries: ['deals'],
    awaitRefetchQueries: true,
    onCompleted: (res: DeleteDocumentsFromDealMutation) => {
      const newDocumentId = res?.edit_deal?.deal?.documents?.edges[0]?.node?.id
      history.push(`${history.location.pathname}${newDocumentId ? `?documentTab=${newDocumentId}` : ''}}`)
    }
  })

  const [moveDocumentToDealAttachmentMutation, { loading: mutationLoading2 }] = useMoveDocumentToDealAttachmentMutation({
    refetchQueries: ['deals', { query: DealAttachmentsDocument, variables: { dealId } }],
    awaitRefetchQueries: true,
    onCompleted: () => history.push(history.location.pathname)
  })

  const [updateDocType, { loading: updateDocTypeLoading }] = useUpdateDocumentTypeMutation({
    onCompleted: () => setModalContent(null),
    refetchQueries: [{ query: DealsDocument, variables: { id: dealId } }],
    awaitRefetchQueries: true
  })

  const [markAsReviewedMutation] = useMarkDocumentAsReviewedMutation()
  const markAsReviewed = async (documentId: string, isReviewed: boolean) => {
    markAsReviewedMutation({ variables: { documentId, isReviewed } })
  }

  // Sort documents so reviewed items are last
  const sortedDocuments =
    documents &&
    [...documents].sort((a, b) => {
      const [aReview, bReview] = [a?.is_reviewed, b?.is_reviewed]
      return aReview === bReview ? 0 : aReview ? 1 : -1
    })

  useEffect(() => {
    if (mutationLoading || mutationLoading2) {
      setModalLoading(true)
    } else {
      setModalLoading(false)
      if (isOpen) {
        closeModal()
      }
    }
    // eslint-disable-next-line
  }, [mutationLoading, mutationLoading2])

  return (
    <>
      <div className={css.documentSelector}>
        {!isEmpty(documentsPendingProcessing) && <DocumentsPendingProcessingMenu documents={documentsPendingProcessing!} />}

        <div className={css.inner}>
          {hasDealUpdateAccess && !dealIsFinalized && <UploadDocumentButton currentDocumentId={currentDocumentId} history={history} />}

          <div className={css.horizontalTabList}>
            {sortedDocuments?.map((doc: any, idx: any) => {
              if (!doc || doc.external_document === true) {
                return null
              }

              const { alias, counter_party, document_type, id, is_counter_party_level, is_reviewed, name } = doc
              const isActive = id === currentDocumentId
              const is_account_level = !!is_counter_party_level
              const isForeignDocument = counter_party?.id ? counter_party?.id !== dealCounterparty?.id : false

              const uploadedBy = doc?.created_by?.user_name?.includes('klaritylaw.com')
                ? isKlarityEmployee
                  ? doc?.created_by?.user_name
                  : 'Klarity'
                : doc?.created_by?.user_name
              const tooltipContent = (
                <>
                  {alias || name}
                  {doc?.integration_type && <div>{`Source: ${doc?.integration_type}`}</div>}
                  {doc?.integration_type === 'MANUAL' && uploadedBy && <div>{`Uploaded by: ${uploadedBy}`}</div>}
                </>
              )

              return (
                <DocumentTabLink isActive={isActive} key={`${id}-${idx}`}>
                  <Link
                    className={clsx(css.item, { [css.active]: isActive, [css.isReviewed]: is_reviewed })}
                    style={{ border: 'none' }}
                    to={`${history.location.pathname}?documentTab=${id}`}
                  >
                    {doc?.is_legacy && (
                      <div className={css.documentSelectorContainer}>
                        <span className={css.legacyDocumentHighlight}></span>
                      </div>
                    )}

                    {is_account_level && !isForeignDocument && (
                      <Tippy content="Customer Level Document">
                        <div className={css.accountLevelTooltip}>
                          <FiGlobe />
                        </div>
                      </Tippy>
                    )}

                    {isForeignDocument && (
                      <Tippy
                        content={
                          <>
                            {`This document was added from the customer:`}
                            <div>{counter_party?.name}</div>
                          </>
                        }
                      >
                        {/* The div breaks the tooltip text to a new line */}
                        <div className={css.accountLevelTooltip}>
                          <MdOutlineDocumentScanner style={{ width: '22px', height: '22px' }} />
                        </div>
                      </Tippy>
                    )}

                    <WithTooltip content={tooltipContent}>
                      <>
                        {document_type?.name}

                        <ConfidenceStatus documentId={doc.id} />
                      </>
                    </WithTooltip>

                    {is_reviewed && (
                      <WithTooltip content="Reviewed">
                        <FiCheck className={css.check} />
                      </WithTooltip>
                    )}

                    {!dealIsFinalized && hasDealUpdateAccess && (
                      <Box sx={{ display: 'inline-block', ml: 1, mr: -0.5 }}>
                        <MoreMenu menuItemList={getMenuItemList({ id, name, alias, document_type, is_reviewed, is_account_level, isForeignDocument })} />
                      </Box>
                    )}
                  </Link>
                </DocumentTabLink>
              )
            })}
          </div>

          <SummaryTabLink isActive={currentDocumentId === 'summary'} />
        </div>
      </div>

      <Modal isOpen={isOpen} onRequestClose={modalOption === 'Delete Document' ? undefined : closeModal} title={modalTitle}>
        {modalLoading && <ComponentLoadingOverlay loading={modalLoading} message={loadingMessage} />}

        <div className={css.modal}>
          {modalOption === 'Rename Document' ? (
            <RenameFile
              closeModal={closeModal}
              documentId={modalContent?.documentId}
              fileAlias={modalContent?.documentAlias}
              fileName={modalContent?.documentName}
              fileType="Document"
              loading={modalLoading}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          ) : modalOption === 'Delete Document' ? (
            <DeleteDocument
              closeModal={closeModal}
              deal_id={dealId}
              documentName={modalContent?.documentAlias || modalContent?.documentName}
              document_id={modalContent?.documentId}
              setLoadingMessage={setLoadingMessage}
              setModalLoading={setModalLoading}
            />
          ) : modalOption === 'Change Document Level' ? ( // @ts-ignore
            <ConvertDocumentLevel
              closeModal={closeModal}
              deal_id={dealId}
              // @ts-ignore
              documentName={modalContent?.documentAlias || modalContent?.documentName}
              // @ts-ignore
              document_id={modalContent?.documentId}
              isAccountLevel={modalContent?.isAccountLevel}
              setLoadingMessage={setLoadingMessage}
              setModalTitle={setModalTitle}
            />
          ) : (
            <>
              <h5 style={{ textAlign: 'center' }}>{modalContent?.documentAlias || modalContent?.documentName}</h5>

              <p>{`${modalOption === 'Update Document Type' ? 'Current' : 'Document'} Type: ${modalContent?.currentDocumentType || 'Unknown'}`}</p>

              {modalOption === 'Update Document Type' && <DocumentTypeSelector onChange={(v: any) => handleSelect(v?.value)} />}

              {modalOption === 'Convert to Attachment' && (
                <>
                  <p>Are you sure you want to convert this document to an attachment?</p>

                  <p>Previously resolved checklist values may be reset.</p>
                </>
              )}

              {modalOption === 'Remove Document' && (
                <>
                  <p>Are you sure you want to remove this document from this deal?</p>

                  <p>Previously resolved checklist values may be reset.</p>
                </>
              )}

              <div className={css.modalButtonRow}>
                <Button onClick={closeModal} variant="secondary">
                  Cancel
                </Button>

                <Button
                  disabled={isDisabled || updateDocTypeLoading}
                  loading={updateDocTypeLoading}
                  onClick={handleSubmit}
                  title={isDisabled ? 'Select an option' : ''}
                >
                  Submit
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

const DocumentTabLink: FC<_DocumentTabLinkProps> = ({ children, isActive }) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView()
    }
  }, [isActive])

  return (
    <Box className={clsx(css.scrollingTab, { [css.scrollingTabActive]: isActive })} ref={ref} sx={{ display: 'flex' }}>
      {children}
    </Box>
  )
}

const DocumentTypeSelector: FC<_DocumentTypeSelectorProps> = ({ isError, onChange }) => {
  const { data, loading } = useDocumentTypesQuery()

  const options = data?.document_types?.edges?.map(edge => {
    const { id, name } = edge?.node || {}

    return { label: name, value: id }
  })

  return <SelectInput isClearable isError={isError} isLoading={loading} onChange={onChange} options={options} placeholder="Select document type" />
}

const DocumentsPendingProcessingMenu: FC<_DocumentsPendingProcessingMenuProps> = ({ documents }) => {
  const isKlarityEmployee = useIsKlarityEmployee()
  const { openPreview } = useModalContext()
  const opening = useOpening()
  const [toast, setToast] = useState('')

  const copyLink = useCallback(
    (event: MouseEvent, document: DocumentPendingProcessingFragment) => {
      event.stopPropagation()
      copy(`${window.location.origin}/documents/${document.id}`, { format: 'text/plain' })
      setToast('Link copied to clipboard.')
      opening.close()
    },
    [opening]
  )

  const sortedDocuments = [...documents].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
  const tooltipContent = `${sortedDocuments.length} document${sortedDocuments.length === 1 ? '' : 's'} pending processing`

  return (
    <>
      <Box sx={{ alignItems: 'center', borderRight: '1px solid #f3f4f8', display: 'flex', height: '100%' }}>
        <WithTooltip content={tooltipContent}>
          <Chip
            aria-label={tooltipContent}
            clickable
            color="warning"
            icon={<AccessTimeFilledOutlinedIcon sx={{ fontSize: 16 }} />}
            label={`${sortedDocuments.length} Processing`}
            onClick={opening.toggle}
            sx={{ mx: 2.5, '.MuiChip-icon': { ml: 1 }, '.MuiChip-label': { color: deepOrange['900'] } }}
            variant="outlined"
          />
        </WithTooltip>
      </Box>

      <Menu
        MenuListProps={{ dense: true }}
        PaperProps={{ sx: { maxHeight: '50vh', maxWidth: 600 } }}
        anchorEl={opening.anchor}
        anchorOrigin={{ horizontal: 'left', vertical: -8 }}
        onClose={opening.close}
        open={opening.isOpen}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {sortedDocuments.map((document: DocumentPendingProcessingFragment) => (
          <MenuItem
            key={document.id}
            onClick={() => {
              openPreview(document.id, true, document.alias || document.name)
              opening.close()
            }}
            sx={{ gap: 1 }}
          >
            {isKlarityEmployee && (
              <Tooltip arrow placement="left" title="Copy link">
                <IconButton aria-label="Copy link" onClick={event => copyLink(event, document)} sx={{ ml: -1.5, mr: -0.5 }}>
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            )}

            <Box>
              {document.is_legacy && (
                <Typography color="textSecondary" variant="caption">
                  Legacy
                </Typography>
              )}

              <Typography noWrap variant="inherit">
                {document.alias || document.name}
              </Typography>

              <Typography color="textSecondary" variant="caption">
                Processing for {formatTimeAgo(document.created_at)}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>

      <Toast message={toast} setMessage={setToast} />
    </>
  )
}

const SummaryTabLink: FC<_SummaryTabLinkProps> = ({ isActive }) => {
  const history = useHistory()
  const { dealId } = useParams<{ dealId: string }>()
  const summaryData = useSummaryDataPoints(dealId)

  if (!summaryData) return null

  return (
    <Box className={clsx(css.scrollingTab, { [css.scrollingTabActive]: isActive })} sx={{ display: 'flex' }}>
      <Link className={clsx(css.item, { [css.active]: isActive })} style={{ border: 'none' }} to={`${history.location.pathname}?documentTab=summary`}>
        Summary
      </Link>
    </Box>
  )
}

const UploadDocumentButton: FC<_UploadDocumentButtonProps> = ({ currentDocumentId, history }) => (
  <div className={clsx(css.item, css.addItem, { [css.active]: currentDocumentId === 'upload' })} style={{ border: 'none', paddingLeft: 16, paddingRight: 16 }}>
    <WithTooltip content="Add documents to this deal">
      <IconButton
        aria-label="Add documents to this deal"
        onClick={() => history.push(`${history.location.pathname}?documentTab=upload`)}
        sx={{ color: grey[700], padding: 0, '&:hover': { color: grey[900], background: 'none' } }}
      >
        <FiPlus style={{ width: 22, height: 22 }} />
      </IconButton>
    </WithTooltip>
  </div>
)
