import { Box, IconButton, Tooltip } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { FC } from 'react'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'

// types

type _ExpandCollapseButtonsProps = {
  isCollapseDisabled: boolean
  isExpandDisabled: boolean
  onCollapseClick: () => void
  onExpandClick: () => void
}

type _IconButtonWithTooltipProps = {
  isDisabled: boolean
  label: string
  onClick: () => void
}

// components

export const ExpandCollapseButtons: FC<_ExpandCollapseButtonsProps> = ({ isCollapseDisabled, isExpandDisabled, onCollapseClick, onExpandClick }) => (
  <Box sx={{ display: 'flex', gap: 0.5 }}>
    <IconButtonWithTooltip isDisabled={isExpandDisabled} label="Expand all" onClick={onExpandClick}>
      <UnfoldMoreIcon />
    </IconButtonWithTooltip>

    <IconButtonWithTooltip isDisabled={isCollapseDisabled} label="Collapse all" onClick={onCollapseClick}>
      <UnfoldLessIcon />
    </IconButtonWithTooltip>
  </Box>
)

/**
 * See: https://mui.com/material-ui/react-tooltip/#disabled-elements
 */
const IconButtonWithTooltip: FC<_IconButtonWithTooltipProps> = ({ children, isDisabled, label, onClick }) => (
  <Tooltip arrow placement="top" title={label}>
    <span>
      <IconButton
        aria-label={label}
        color="inherit"
        disabled={isDisabled}
        onClick={onClick}
        size="small"
        sx={{
          bgcolor: grey[300],
          borderRadius: 1,
          px: 0,
          '& svg': { transform: 'scale(0.8)' },
          '&:focus, &:hover': { bgcolor: grey[400] },
          '&.Mui-disabled': { bgcolor: grey[100] }
        }}
      >
        {children}
      </IconButton>
    </span>
  </Tooltip>
)
