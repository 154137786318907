import * as queryString from 'query-string'
import { Features, Permissions, useIsAnnotator, useUserAccess } from './useUserAccess'
import { QueryTypes } from './usePrevQueryType'
import { useLocation } from 'react-router-dom'
import useIsRapid7LegalTeam from './useIsRapid7LegalTeam'

export default function usePrevQuery() {
  const location = useLocation()
  const isAnnotator = useIsAnnotator()
  const isRapid7LegalTeam = useIsRapid7LegalTeam()
  const hasDealAccess = useUserAccess({ feature: Features.DEAL_DASHBOARD, permission: Permissions.READ })

  const getPreviousQuery = () => {
    const customerId = localStorage.getItem('customerId')

    if (location?.pathname === '/') {
      if (isAnnotator) {
        return ''
      } else {
        return !isRapid7LegalTeam && hasDealAccess
          ? localStorage.getItem(`${customerId}__${QueryTypes.PREVIOUS_DEAL_QUERY}`)
          : localStorage.getItem(`${customerId}__${QueryTypes.PREVIOUS_DOCUMENT_QUERY}`)
      }
    } else if (location?.pathname?.includes('/dashboard/deals')) {
      return localStorage.getItem(`${customerId}__${QueryTypes.PREVIOUS_DEAL_QUERY}`)
    } else if (location?.pathname?.includes('/dashboard/documents')) {
      return localStorage.getItem(`${customerId}__${QueryTypes.PREVIOUS_DOCUMENT_QUERY}`)
    } else if (location?.pathname?.includes('/dashboard/attachments')) {
      return localStorage.getItem(`${customerId}__${QueryTypes.PREVIOUS_ATTACHMENT_QUERY}`)
    } else {
      return ''
    }
  }

  const prevQuery = getPreviousQuery()

  return prevQuery ? queryString.parse(prevQuery, { arrayFormat: 'comma' }) : false
}
