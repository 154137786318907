import { AiOutlineTable } from 'react-icons/ai'
import { Button } from '@mui/material'
import { useFeedbackPageContext } from '../../pages/FeedbackPage'
import React, { FC } from 'react'

// components

export const FeedbackDataTableButton: FC = () => {
  const { feedbackDataTableOpening } = useFeedbackPageContext()

  return (
    <Button color="tertiary" onClick={feedbackDataTableOpening.open} size="small" startIcon={<AiOutlineTable />} variant="outlined">
      Open Data Table
    </Button>
  )
}
