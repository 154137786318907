import 'react-querybuilder/dist/query-builder.css'
import { AddGroupButton, AddRuleButton, CombinatorSelector, RemoveGroupButton, RemoveRuleButton, Selector, ValueEditor } from './ControlElements'
import { Box, Typography } from '@mui/material'
import { Column } from '../../reducers/dashboardQueryReducer'
import { decodeQuery, encodeQuery } from '../../utils/dashboardQueryUtils'
import { grey } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import { usePrevQueryType } from '../../hooks/usePrevQueryType'
import { usePrevious } from '../../hooks/usePrevious'
import Button from '../Button'
import QueryBuilder, { RuleGroupType, RuleType } from 'react-querybuilder'
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import SavedQueryModal from './SavedQueryModal'
import SavedQuerySelector from './SavedQuerySelector'
import WithTooltip from '../WithTooltip'
import clsx from 'clsx'
import css from './style.module.scss'
import usePrevQuery from '../../hooks/usePrevQuery'

// types

type _AdvancedSearchProps = {
  clearAll: boolean
  fields: any // which fields to display
  filteredCount: number
  handleSearch: (x?: RuleGroupType | null) => void
  isDealsDashboard?: boolean
  query: RuleGroupType | null // optionally pass querybuilder rules to display on first render
  recordType: string
  selectedColumns: Column[]
  setClearAll: Dispatch<SetStateAction<boolean>>
  totalCount: number
}

enum InvalidQueryMessage {
  MISSING_RANGE_INPUT = 'Range inputs must include start and end values',
  MISSING_RULES = 'Search rules/groups cannot be empty'
}

// functions

const hasEmptyQueryRules = (queryRule: RuleType | RuleGroupType): boolean => {
  if ('rules' in queryRule) {
    if (isEmpty(queryRule.rules)) {
      return true
    }

    for (const nestedRule of queryRule.rules) {
      if (hasEmptyQueryRules(nestedRule)) {
        return true
      }
    }
  }

  return false
}

const hasMissingRangeInput = (queryRule: RuleType | RuleGroupType): boolean => {
  if ('rules' in queryRule) {
    return queryRule.rules.some(hasMissingRangeInput)
  }

  return (queryRule.operator === 'between' || queryRule.operator === 'notBetween') && (!Array.isArray(queryRule.value) || !queryRule.value.every(Boolean))
}

// components

export const AdvancedSearch: FC<_AdvancedSearchProps> = ({
  clearAll,
  fields,
  filteredCount,
  handleSearch,
  isDealsDashboard,
  query,
  recordType,
  selectedColumns,
  setClearAll,
  totalCount
}) => {
  const [hasSearchBeenRun, setHasSearchBeenRun] = useState(false)
  const [invalidQueryMessage, setInvalidQueryMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [tempQuery, setTempQuery] = useState<RuleGroupType | null>(query)
  const filteredPlurality = filteredCount === 1 ? '' : 's'
  const prevIsOpen = usePrevious(isOpen)
  const prevQuery = usePrevQuery()
  const prevQueryType = usePrevQueryType()
  const totalPlurality = totalCount === 1 ? '' : 's'

  const handleClearAll = useCallback(() => {
    if (prevQueryType) {
      localStorage.removeItem(`${localStorage.getItem('customerId')}__${prevQueryType}`)
    }

    setIsOpen(false)

    setTempQuery(null)

    handleSearch()
  }, [handleSearch, prevQueryType])

  useEffect(() => setTempQuery(query), [query])

  useEffect(() => {
    if (clearAll) {
      handleClearAll()

      setClearAll(false)
    }
  }, [clearAll, handleClearAll, setClearAll])

  useEffect(() => {
    if (tempQuery && !hasEmptyQueryRules(tempQuery) && !isOpen) {
      setIsOpen(true)
    }

    if (tempQuery && !prevQuery && !window.location.search) {
      setHasSearchBeenRun(false)
    }

    const isMissingRangeInput = tempQuery && hasMissingRangeInput(tempQuery)
    const isMissingRules = tempQuery && hasEmptyQueryRules(tempQuery)

    setInvalidQueryMessage(isMissingRangeInput ? InvalidQueryMessage.MISSING_RANGE_INPUT : isMissingRules ? InvalidQueryMessage.MISSING_RULES : '')
  }, [tempQuery]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleQueryChange = useCallback(
    (newQuery: RuleGroupType) => {
      if (prevQuery && !isEmpty(newQuery?.rules)) {
        // Change search button color if the temp query differs from the saved query.
        const { q } = prevQuery || {}
        const decodedPrevQuery = decodeQuery(q as string) || undefined
        const encodedPrevQuery = encodeQuery(decodedPrevQuery)
        const encodedTempQuery = encodeQuery(newQuery)

        setHasSearchBeenRun(encodedTempQuery === encodedPrevQuery)
      }

      setTempQuery(newQuery)
    },
    [handleSearch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div
      className={clsx(css.wrapper, !isOpen && css.isCollapsed)}
      onKeyUp={({ key }) => {
        if (key === 'Enter' && !invalidQueryMessage) handleSearch(tempQuery)
      }}
    >
      <QueryBuilder
        addRuleToNewGroups={isOpen}
        context={{ isOpen }}
        controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
        controlElements={{
          addGroupAction: AddGroupButton,
          addRuleAction: AddRuleButton,
          combinatorSelector: CombinatorSelector,
          fieldSelector: Selector,
          operatorSelector: Selector,
          removeGroupAction: RemoveGroupButton,
          removeRuleAction: RemoveRuleButton,
          valueEditor: ValueEditor
        }}
        fields={fields && Object.values(fields)?.filter((field: any) => field?.filter_allowed !== false)}
        key={isOpen.toString()} // Ensure elements are correctly shown/hidden when toggling open/closed.
        onQueryChange={handleQueryChange}
        query={tempQuery || undefined}
        resetOnFieldChange // Reset when changing fields/operators to avoid invalid input values.
        resetOnOperatorChange
        showCombinatorsBetweenRules
      />

      {isOpen && isEmpty(tempQuery?.rules) && (
        <Box sx={{ border: `1px dashed ${grey[400]}`, borderRadius: 0.5, mb: 3, px: 2, py: 1, textAlign: 'center' }}>
          <Typography sx={{ color: grey[600] }} variant="body2">
            Add a rule or group to begin searching
          </Typography>
        </Box>
      )}

      <div className={css.buttonRow}>
        <div>
          <SavedQuerySelector
            isDealsDashboard={isDealsDashboard}
            key={Boolean(prevIsOpen && !isOpen).toString()} // Preserve selected value when opening the QueryBuilder, but reset it on close.
          />

          {isOpen && Boolean(totalCount) && (
            <div className={css.buttonRowResults}>
              {`${totalCount} ${recordType}${totalPlurality}${totalCount !== filteredCount ? `, ${filteredCount} result${filteredPlurality}` : ''}`}
            </div>
          )}
        </div>

        {isOpen && (
          <div>
            <div style={{ marginRight: 12 }}>
              <SavedQueryModal
                isDealsDashboard={isDealsDashboard || false}
                isDisabled={Boolean(tempQuery && hasEmptyQueryRules(tempQuery))}
                selectedColumns={selectedColumns}
                tempQuery={tempQuery}
              />
            </div>

            <Button onClick={handleClearAll} variant="tertiary">
              Close
            </Button>

            <WithTooltip content={invalidQueryMessage || (hasSearchBeenRun ? 'Run search again' : 'Run new search')}>
              <Button
                disabled={Boolean(invalidQueryMessage)}
                onClick={() => {
                  setHasSearchBeenRun(true)
                  handleSearch(tempQuery)
                }}
                variant={hasSearchBeenRun ? 'secondary' : 'primary'}
              >
                Search
              </Button>
            </WithTooltip>
          </div>
        )}
      </div>
    </div>
  )
}
