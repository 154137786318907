import { Box, IconButton } from '@mui/material'
import { DocumentPanelBanner, FeedbackDataTable, FeedbackPDFHighlighter } from '.'
import { FiZoomIn, FiZoomOut } from 'react-icons/fi'
import { PageNavigation } from '../DocumentPanel/Toolbar'
import { grey } from '@mui/material/colors'
import { useFeedbackPageContext } from '../../pages/FeedbackPage'
import React, { FC, useCallback, useRef, useState } from 'react'
import WithTooltip from '../WithTooltip'
import clsx from 'clsx'
import css from '../DocumentPanel/style.module.scss'

// components

export const DocumentPanel: FC = () => {
  const { feedbackDataTableOpening, isLoadingFeedbackPage, isSampleValueCorrect, setIsDocumentReady } = useFeedbackPageContext()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [scale, setScale] = useState<'auto' | number>('auto')
  const [totalPages, setTotalPages] = useState(0)
  const feedbackDocumentWrapperRef = useRef<HTMLDivElement | null>(null)

  const handleDocumentReady = useCallback(() => {
    setIsDocumentReady(true)

    setTotalPages(window.PdfViewer?.viewer?._pages?.length)
  }, [setIsDocumentReady, setTotalPages])

  const zoom = (factor: number) => {
    const highlightRoot = window.document.getElementById('popper-root')

    if (highlightRoot) highlightRoot.innerHTML = ''

    setScale(previous => {
      if (previous === 'auto') {
        const { currentScale } = window.PdfViewer?.viewer || {}

        return isNaN(currentScale) ? 1 : currentScale * factor
      }

      return previous * factor
    })
  }

  return (
    <div className={css.documentPanel}>
      <Box className={css.toolbar}>
        <Box sx={{ alignItems: 'center', display: 'flex', ml: 'auto' }}>
          {Boolean(totalPages) && (
            <Box className={css.toolbarPages}>
              <PageNavigation currentPage={currentPage} totalPages={totalPages} />
            </Box>
          )}

          <WithTooltip content="Zoom In">
            <IconButton
              aria-label="Zoom In"
              className={css.toolbarButton}
              onClick={e => {
                e.stopPropagation()
                zoom(1.1)
              }}
              sx={{ color: grey[400], '&:hover': { background: 'none', color: grey[800] } }}
            >
              <FiZoomIn />
            </IconButton>
          </WithTooltip>

          <WithTooltip content="Zoom Out">
            <IconButton
              aria-label="Zoom Out"
              className={css.toolbarButton}
              onClick={e => {
                e.stopPropagation()
                zoom(0.9)
              }}
              sx={{ color: grey[400], '&:hover': { background: 'none', color: grey[800] } }}
            >
              <FiZoomOut />
            </IconButton>
          </WithTooltip>

          <Box sx={{ pr: 2 }} />
        </Box>
      </Box>

      <div className={clsx(css.documentWrapper, css.v2)} ref={feedbackDocumentWrapperRef}>
        {!isLoadingFeedbackPage && !isSampleValueCorrect && <DocumentPanelBanner />}

        <FeedbackPDFHighlighter onDocumentReady={handleDocumentReady} onPageChange={setCurrentPage} scale={scale} />
      </div>

      {feedbackDataTableOpening.isOpen && <FeedbackDataTable />}
    </div>
  )
}
