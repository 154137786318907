import React, { CSSProperties, FC, KeyboardEvent, SyntheticEvent, useEffect, useRef } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type _FreeTextProps = {
  ariaLabel?: string
  autoFocus?: boolean
  handleBlur?: any
  id?: string
  isDisabled?: boolean
  isError?: boolean
  isFocused?: boolean
  onChange?: (e: SyntheticEvent<HTMLTextAreaElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  setFocused?: (arg: boolean) => void
  style?: CSSProperties
  value: string
}

// components

const FreeText: FC<_FreeTextProps> = ({
  ariaLabel,
  autoFocus = false,
  handleBlur,
  id,
  isDisabled,
  isError,
  isFocused,
  onChange,
  onKeyDown,
  placeholder,
  setFocused,
  style,
  value
}) => {
  const ref = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    isFocused ? ref.current?.focus() : ref.current?.blur()
  }, [isFocused])

  return (
    <TextareaAutosize
      aria-label={ariaLabel || placeholder || 'Enter text'}
      autoFocus={autoFocus}
      className={clsx(css.input, isDisabled && css.disabled)}
      disabled={isDisabled}
      id={id}
      onBlur={event => {
        setFocused?.(false)
        handleBlur?.(event)
      }}
      onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => onChange?.(event)}
      onFocus={() => setFocused?.(true)}
      onKeyDown={onKeyDown}
      onKeyPress={(event: KeyboardEvent<HTMLTextAreaElement>) => {
        // Allow newlines if pressing shift
        if (!event.shiftKey && event.key === 'Enter') {
          event.preventDefault()
        }
      }}
      placeholder={placeholder}
      ref={ref}
      style={{
        ...(!isDisabled && { backgroundColor: '#fff' }),
        fontFamily: 'Open Sans',
        lineHeight: '1.4',
        resize: 'none',
        border: `${isError ? '1px solid red' : ''}`,
        ...style
      }}
      type="text"
      value={value || ''}
    />
  )
}

export default FreeText
