import { Box, Typography } from '@mui/material'
import { DELETE } from '../../../../../../../utils/cci'
import { FiXSquare } from 'react-icons/fi'
import { useCciChecklistGptContext } from '../CCI_RightPanel_ChecklistGptTab'
import { useCciMainContext } from '../../../../../CCI_Main'
import Button from '../../../../../../../components/Button'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import React, { FC, KeyboardEvent, SyntheticEvent, useCallback, useState } from 'react'
import css from './style.module.scss'

// constants

const FIELD = 'description'

const LABEL = 'Tooltip Text'

const PLACEHOLDER = 'Enter toolitp text for the info icon next to the field label'

// components

export const TooltipTextInput: FC = () => {
  const { selectedItem } = useCciMainContext()
  const { isEditView, updateFieldValue } = useCciChecklistGptContext()

  const initialValue = selectedItem?.description || ''
  const [value, setValue] = useState<string | null | undefined>(initialValue)

  const [isFocused, setFocused] = useState(false)

  const handleChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    setValue(event.currentTarget.value)
    updateFieldValue(FIELD, event.currentTarget.value)
  }

  const handleDelete = useCallback(() => {
    setValue('')
    updateFieldValue(FIELD, isEditView ? DELETE : null)
  }, [isEditView, updateFieldValue])

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (isFocused && event?.key === 'Enter') {
      setFocused(false)
      ;(event.target as HTMLElement).blur()
    }
  }

  return (
    <Box className={css.inputWrapper} sx={{ mt: 1 }}>
      <Typography component="label" htmlFor="tooltip-text-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}
      </Typography>

      <FreeText
        id="tooltip-text-input"
        isFocused={isFocused}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={PLACEHOLDER}
        setFocused={setFocused}
        value={value && value !== DELETE ? value : ''}
      />

      {value && value !== DELETE && <Button className={css.cciButton} icon={<FiXSquare />} onClick={handleDelete} />}
    </Box>
  )
}
