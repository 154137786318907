import { FiDownloadCloud } from 'react-icons/fi'
import { Info } from 'react-feather'
import { KLARITY_BLUE } from '../../../utils/styleUtils'
import { useDashboardExportLazyQuery } from '../../../graphql/codegen/hooks'
import { useIsAnnotator } from '../../../hooks/useUserAccess'
import Button from '../../Button'
import ComponentLoadingOverlay from '../../ComponentLoadingOverlay'
import Modal from '../../Modal'
import RadioButton from '../../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/SharedInputs/RadioButton/RadioButton'
import React, { FC, useEffect, useState } from 'react'
import Tippy from '@tippyjs/react'
import WithTooltip from '../../WithTooltip'
import css from './style.module.scss'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useDashboardQString from '../../../hooks/useDashboardQString'

// types

type _BulkExportProps = { dashboard_type: _DashboardType; selectedColumns?: any }

type _DashboardType = 'DEAL' | 'DOCUMENT' | 'ATTACHMENT'

// components

export const BulkExport: FC<_BulkExportProps> = ({ dashboard_type, selectedColumns }) => {
  const chosenDataPointFieldIds = selectedColumns?.map((dp: any) => dp.data_point_field_id)
  const { gqlFilters } = useDashboardQString()
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [exportType, setExportType] = useState(dashboard_type === 'ATTACHMENT' ? 'exportAll' : undefined)
  const isAnnotator = useIsAnnotator()

  const [dashboardExport, { error: dashboardExportError, loading: dashboardExportLoading }] = useDashboardExportLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (isAnnotator) {
        // Initiate download after the file is returned
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = data?.fetch_dashboard_export?.spreadsheet_export?.file_contents || ''
        a.download = data?.fetch_dashboard_export?.spreadsheet_export?.file_name || ''
        document.body.appendChild(a)
        a.click()
        setSuccess(true)
        setLoading(false)
        setLoadingMessage('')
      } else {
        setSuccess(true)
        setLoading(false)
        setLoadingMessage('')
      }
    }
  })

  const closeModal = () => {
    if (!loading) {
      setExportType(dashboard_type === 'ATTACHMENT' ? 'exportAll' : undefined)
      setSuccess(false)
      setError(false)
      setLoading(false)
      setLoadingMessage('')
      setIsOpen(false)
    }
  }

  const handleChange = (e: any) => {
    if (e?.target?.name === 'exportType') {
      setExportType(e?.target?.value)
    }
  }

  const handleExport = () => {
    dashboardExport({
      variables: {
        dashboardType: dashboard_type,
        flowType: 'SHEET_EXPORT',
        ...gqlFilters,
        ...(exportType === 'exportAll' ? {} : { chosenDataPointFieldIds })
      }
    })
  }

  useEffect(() => {
    if (isOpen) {
      if (dashboardExportLoading) {
        setLoading(true)
        setLoadingMessage(isAnnotator ? 'Exporting data…' : 'Starting export…')
      } else {
        setLoading(false)
      }
    }
  }, [dashboardExportLoading, isAnnotator, isOpen])

  useEffect(() => {
    if (isOpen) {
      setError(Boolean(dashboardExportError))
    }
  }, [dashboardExportError, isOpen])

  return (
    <>
      <div style={{ marginRight: 5 }}>
        <WithTooltip content="Bulk data export">
          <Button aria-label="Bulk data export" icon={<FiDownloadCloud />} onClick={() => setIsOpen(true)} />
        </WithTooltip>
      </div>

      <Modal isOpen={isOpen} onRequestClose={closeModal} title="Bulk Data Export">
        {loading && <ComponentLoadingOverlay loading={loading} message={loadingMessage} />}
        <div style={{ textAlign: 'center', minWidth: '555px' }}>
          {success ? (
            <ModalSuccess closeModal={closeModal} isAnnotator={isAnnotator} />
          ) : error ? (
            <ModalError closeModal={closeModal} dashboardExportError={dashboardExportError} />
          ) : (
            <>
              <p>
                {dashboard_type === 'ATTACHMENT'
                  ? 'This will export the selected columns into a spreadsheet.'
                  : 'This will export checklist values and comments into a spreadsheet.'}

                <Tippy
                  content={
                    dashboard_type === 'ATTACHMENT'
                      ? 'Please contact us if you also require the attachments themselves.'
                      : 'Please contact us if you also require PDF file exports of your uploaded documents with text annotations included.'
                  }
                >
                  <Info color={KLARITY_BLUE} style={{ height: 16, marginLeft: 6, position: 'relative', top: 2, width: 16 }} />
                </Tippy>
              </p>

              {isAnnotator && <p style={{ marginTop: '8px' }}>Spreadsheet will be downloaded immediately.</p>}

              <div className={css.radioRow} style={{ margin: '16px auto 0 auto' }}>
                <p>What would you like to export?</p>

                <div>
                  <RadioButton
                    handleChange={handleChange}
                    id="exportType_true"
                    isSelected={exportType === 'exportCurrentSelection'}
                    label="Current Field Selection"
                    name="exportType"
                    style={{ marginRight: '16px' }}
                    value="exportCurrentSelection"
                  />

                  <RadioButton
                    handleChange={handleChange}
                    id="exportType_false"
                    isSelected={exportType === 'exportAll'}
                    label="All Fields"
                    name="exportType"
                    value="exportAll"
                  />
                </div>
              </div>

              <div className={css.modalButtonRow}>
                <Button onClick={closeModal} variant="secondary">
                  Cancel
                </Button>

                <WithTooltip content={isAnnotator ? (exportType === undefined ? 'Select an option' : '') : ''}>
                  <Button disabled={exportType === undefined} onClick={handleExport}>
                    Submit
                  </Button>
                </WithTooltip>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

function ModalSuccess({ closeModal, isAnnotator }: any) {
  const currentUser = useCurrentUser()

  return (
    <>
      <p>{isAnnotator ? 'Successfully exported file.' : 'Successfully started export.'}</p>

      {!isAnnotator && (
        <p>
          <p style={{ margin: '16px 0' }}>This may take up to 30 minutes depending on the export size.</p>

          <p>{`We’ll email the results to ${currentUser?.user_name}.`}</p>
        </p>
      )}

      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} style={{ marginRight: 0 }} variant={'secondary'}>{`Close`}</Button>
      </div>
    </>
  )
}

function ModalError({ closeModal, dashboardExportError }: any) {
  return (
    <>
      {dashboardExportError && <p>{dashboardExportError?.message || `Error exporting data: ${dashboardExportError}`}</p>}

      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} style={{ marginRight: 0 }} variant={'secondary'}>{`Close`}</Button>
      </div>
    </>
  )
}
