import { DataPointFieldSources } from '../DatapointField/DataMatching'
import { Mention, MentionsInput } from 'react-mentions'
import { useCciDealDataPointFieldsQuery } from '../../graphql/codegen/hooks'
import React, { FC, useCallback, useMemo } from 'react'
import css from './style.module.scss'

// types

type _DataPointFieldMentionsProps = {
  id?: string
  isDisabled?: boolean
  label: string
  onChange: (event: { target: { value: string } }) => void
  value: string
}

type _MentionData = { display: string; id: string }

// components

export const DataPointFieldMentions: FC<_DataPointFieldMentionsProps> = ({ id, isDisabled = false, label, onChange, value }) => {
  const { data: dealDataPointFieldListData } = useCciDealDataPointFieldsQuery()

  const filteredMentionDataList = useMemo(
    () =>
      dealDataPointFieldListData?.cci_deal_data_point_fields
        ?.reduce((acc, dataPointField) => {
          if (dataPointField?.source !== DataPointFieldSources.EXTERNAL) {
            acc.push({
              display: dataPointField?.name as string,
              id: dataPointField?.id as string
            })
          }

          return acc
        }, [] as _MentionData[])
        ?.sort((a, b) => a.display.localeCompare(b.display)) || [],
    [dealDataPointFieldListData]
  )

  const getFilteredMentionDataList = useCallback(
    (filter: string) => filteredMentionDataList.filter(mentionData => mentionData.display.toLowerCase().includes(filter.toLowerCase())),
    [filteredMentionDataList]
  )

  return (
    <MentionsInput
      aria-label={label}
      className="mentions"
      classNames={css}
      disabled={isDisabled}
      id={id}
      onChange={onChange}
      placeholder="Use ‘@’ to tag data point fields."
      value={value}
    >
      <Mention className={css.mentions__mention} data={getFilteredMentionDataList} regex={/@\[(.*?)\]\((.*?)\)/} trigger="@" />
    </MentionsInput>
  )
}
