import { Box, Typography } from '@mui/material'
import { CreateOrSelectNeutralTag } from '../EditInputs/CreateOrSelectNeutralTag'
import { FiChevronDown, FiChevronUp, FiTrash2 } from 'react-icons/fi'
import Button from '../../../../../../../components/Button'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import React, { FC, KeyboardEvent, useEffect, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// types

type _FieldOptionsProps = {
  defaultValue?: string
  fieldType?: string
  handleData: (key: string, value: any) => void
  optionList?: { [key: string]: any }[]
  tagType?: string
  valueFormat?: string
}

type _OptionInputProps = {
  handleChange?: any
  handleData?: any
  index: number
  isNew?: boolean
  optionList?: any
  placeholder?: string
  value?: string
}

type _OptionNeutralTagInputProps = { handleNeutralTag: any; index: number }

type _ReorderButtonsProps = { handleData?: any; index: number; optionList?: any }

// constants

const DROPDOWN_FIELD_TYPES = ['DROP_DOWN', 'MULTI_SELECT_DROP_DOWN']

const FIELD_TYPES_WITH_OPTIONS = [...DROPDOWN_FIELD_TYPES, 'DATE']

const DATE_FORMAT_OPTIONS = [
  { value: 'MMMM/DD/YYYY', label: 'MMMM/DD/YYYY' },
  { value: 'MMMM DD, YYYY', label: 'MMMM DD, YYYY' },
  { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
  { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
  { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
  { value: 'DD MMMM, YYYY', label: 'DD MMMM, YYYY' }
]

// components

export const FieldOptions: FC<_FieldOptionsProps> = ({ defaultValue, fieldType, handleData, optionList, tagType, valueFormat }) => {
  const isDropdownFieldType = Boolean(fieldType && DROPDOWN_FIELD_TYPES?.includes(fieldType))
  const isFieldTypeWithOptions = Boolean(fieldType && FIELD_TYPES_WITH_OPTIONS?.includes(fieldType))

  // @ts-ignore
  const handleChange = (value?: string, index: number, isNew?: boolean) => {
    const localArray = optionList ? [...optionList] : []

    if (!isNew && localArray[index].option === defaultValue) {
      if (value === '@@_DELETE_THIS_ITEM') {
        handleData('default_value', null)
      } else {
        handleData('default_value', value)
      }
    }

    if (value === '@@_DELETE_THIS_ITEM') {
      localArray.splice(index, 1)
    } else if (isNew) {
      localArray.push({ option: value })
    } else {
      localArray[index].option = value
    }

    handleData('options', localArray)
  }

  const handleDateFormat = (item: any) => {
    handleData('value_format', item?.value)
  }

  const handleNeutralTag = (index: number, value?: string) => {
    const localArray = optionList ? [...optionList] : []
    localArray[index].neutral_tag = value

    handleData('options', localArray)
  }

  if (!isFieldTypeWithOptions) return null

  return (
    <>
      {isDropdownFieldType && (
        <>
          <Typography sx={{ fontWeight: 600, mb: 1, mt: 2 }} variant="body2">
            Field Options
          </Typography>

          {optionList?.length ? (
            optionList?.map((option: any, index: number) => (
              <div className={css.inputWrapper} key={`${option}${index}`} style={{ flexWrap: 'wrap' }}>
                <OptionInput
                  handleChange={handleChange}
                  handleData={handleData}
                  index={index}
                  optionList={optionList}
                  placeholder={option?.option}
                  value={option?.option || ''}
                />

                {tagType === 'option_level' && <OptionNeutralTagInput handleNeutralTag={handleNeutralTag} index={index} />}
              </div>
            ))
          ) : (
            <></>
          )}

          <OptionInput handleChange={handleChange} index={9999} isNew placeholder="Add item…" />
        </>
      )}

      {fieldType === 'DATE' && (
        <div className={css.inputWrapper}>
          <Typography component="label" htmlFor="date-format-select-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
            Value Format
          </Typography>

          <SelectInput
            id="date-format-select-input"
            isClearable
            onChange={handleDateFormat}
            options={DATE_FORMAT_OPTIONS}
            placeholder={valueFormat || 'Select Date Format'}
            value={valueFormat || ''}
          />
        </div>
      )}
    </>
  )
}

const OptionInput: FC<_OptionInputProps> = ({ handleChange, handleData, index, isNew, optionList, placeholder, value }) => {
  const [isFocused, setFocused] = useState(false)
  const [addItemValue, setAddItemValue] = useState('')
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (isFocused && e?.key === 'Enter') {
      setFocused(false)
      // @ts-ignore
      e?.target?.blur()
    }
  }

  useEffect(() => {
    if (!isFocused && addItemValue) {
      handleChange(addItemValue || null, index, isNew)
      setAddItemValue('')
    } else {
      setAddItemValue(value || '')
    }
    // eslint-disable-next-line
  }, [isFocused])

  return (
    <>
      <Box className={isNew ? css.optionInputWrapper : css.inputWrapper} sx={isNew ? { ml: 22.5 } : { m: 0 }}>
        <Box className={css.textInput}>
          {!isNew && <ReorderButtons handleData={handleData} index={index} optionList={optionList} />}

          <FreeText
            isFocused={isFocused}
            onChange={(event: any) => setAddItemValue(event?.target?.value)}
            onKeyDown={handleKeyDown}
            placeholder={isNew ? 'Add item…' : placeholder}
            setFocused={setFocused}
            value={isFocused ? addItemValue || '' : value || ''}
          />
        </Box>

        {(!isNew || !placeholder) && (
          <Button
            icon={<FiTrash2 color="#fc3535" />}
            onClick={() => handleChange('@@_DELETE_THIS_ITEM', index)}
            style={{ margin: '-1px 0 0 8px', borderRadius: '4px' }}
          />
        )}
      </Box>
    </>
  )
}

const OptionNeutralTagInput: FC<_OptionNeutralTagInputProps> = ({ handleNeutralTag, index }) => {
  const [value, setValue] = useState<any>(null)

  const handleChange = (neutralTagValue: string) => {
    setValue(neutralTagValue)
  }

  useEffect(() => {
    handleNeutralTag(index, value || null)
    // eslint-disable-next-line
  }, [value])

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', ml: 22.5, mr: 4.5, mt: 1, width: '100%' }}>
      <Typography component="label" htmlFor="automation-tag-value-select-input" sx={{ flexShrink: 0, fontWeight: 600, mr: 2, width: 128 }} variant="body2">
        Automation Tag
      </Typography>

      <Box className={css.inputWrapper} sx={{ flex: 1, mb: 0 }}>
        <CreateOrSelectNeutralTag id="automation-tag-value-select-input" setNeutralTag={handleChange} />
      </Box>
    </Box>
  )
}

const ReorderButtons: FC<_ReorderButtonsProps> = ({ handleData, index, optionList }) => {
  const handleClick = (direction: string) => {
    if (direction === 'up' && index !== 0) {
      handleData('options', reorderArray(index, index - 1))
    } else if (direction === 'down' && index !== optionList?.length - 1) {
      handleData('options', reorderArray(index, index + 1))
    }
  }

  const reorderArray = (fromIndex: number, toIndex: number) => {
    const element = optionList[fromIndex]
    optionList.splice(fromIndex, 1)
    optionList.splice(toIndex, 0, element)
    return [...optionList]
  }

  return (
    <Box sx={{ display: 'flex', mr: 2, width: 164 }}>
      <Box className={css.toolbarButton} onClick={() => handleClick('up')} style={{ visibility: index === 0 ? 'hidden' : 'unset' }} title="Move option up">
        <FiChevronUp />
      </Box>

      <Box
        className={css.toolbarButton}
        onClick={() => handleClick('down')}
        style={{ visibility: index === optionList?.length - 1 ? 'hidden' : 'unset' }}
        title="Move option down"
      >
        <FiChevronDown />
      </Box>
    </Box>
  )
}

// NOTES:

// for dropdown/multi: list of freetext inputs for each option array. This calls handleChange which formats the inputs and then updates handleData
// empty input at the bottom that adds a new item to the newOptions array
// newOptions array is cleaned up on submit in edit deal- if any newOption has a value or was deleted it will fill in nulls with currentItems. @@_DELETE_THIS_ITEM fields will not be sent
// if any value is changed the entire new options array is sent to the backend in order to replace old values.

// have a way to delete an option row- trashcan button to the right of X
// throw error on submit if type has required options but is missing. update this to show errors
