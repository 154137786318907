import { CciLeftPanelChecklistCard } from './CCI_LeftPanel_ChecklistCard'
import { DndContext, DragOverlay, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { SortablePanel } from './SortablePanel'
import { formatKey } from '../../../../../../utils/stringUtils'
import { isEmpty } from 'lodash'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { useCciLeftPanelContext } from '../../CCI_LeftPanel'
import { useCciMainContext } from '../../../../CCI_Main'
import { useReorderChecklistItemMutation, useReorderGroupMutation } from '../../../../../../graphql/codegen/hooks'
import ChecklistSkeleton from '../../../../../../components/DataPointPanel/ChecklistSkeleton'
import React, { FC, useEffect, useState } from 'react'

// types

type _CciChecklistDndProps = { groups?: any; isReorderingEnabled?: boolean; sortedNames: string[] }

// components

export const CciChecklistDnd: FC<_CciChecklistDndProps> = ({ groups, isReorderingEnabled = true, sortedNames = [] }) => {
  const { setIsModalOpen, setModalError } = useCciMainContext()
  const { activeKeys, setActiveKeys } = useCciLeftPanelContext()
  const [disableCollapse, setDisableCollapse] = useState(false)

  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 10 } }))
  const [activeDPF, setActiveDPF] = useState<any>(undefined)

  const [reorderGroup, { error: reorderGroupError, loading: reorderGroupLoading }] = useReorderGroupMutation()

  const [reorderChecklistItem, { error: reorderChecklistItemError, loading: reorderChecklistItemLoading }] = useReorderChecklistItemMutation()

  useEffect(() => {
    if (reorderGroupError) {
      setModalError(reorderGroupError)
      setIsModalOpen(true)
    } else if (reorderChecklistItemError) {
      setModalError(reorderChecklistItemError)
      setIsModalOpen(true)
    }
    // eslint-disable-next-line
  }, [reorderGroupError, reorderChecklistItemError])

  const handleDragStart = (event: any) => {
    const { active } = event
    const currentGroup = groups[active?.data?.current?.sortable?.containerId]
    let active_dpf
    currentGroup?.forEach((item: any) => {
      if (item?.id === active?.id) {
        active_dpf = item
      }
    })
    setActiveDPF(active_dpf)
    setDisableCollapse(true)
  }

  const handleDragEnd = (event: any) => {
    const { active, over } = event
    const oldContext = active?.data?.current?.sortable?.containerId
    const newContext = over?.data?.current?.sortable?.containerId

    if (active.id !== over.id) {
      if (oldContext === 'group_context' || newContext === 'group_context') {
        handleGroupReorder({ oldContext, newContext, active, over })
      } else {
        handleItemReorder({ oldContext, newContext, active, over })
      }
    }
    setActiveDPF(undefined)
    setTimeout(() => {
      setDisableCollapse(false)
    }, 100)
  }

  const handleGroupReorder = ({ active, newContext, oldContext, over }: any) => {
    // only allow groups inside the group_context and don't allow them to leave it
    if (!((oldContext === 'group_context' && newContext !== 'group_context') || (oldContext !== 'group_context' && newContext === 'group_context'))) {
      const oldIndex = sortedNames.indexOf(active.id)
      const newIndex = sortedNames.indexOf(over.id)
      const newArray = arrayMove(sortedNames, oldIndex, newIndex)
      const newIndexAfterReorder = newArray.indexOf(active.id)
      const moveAfterName = newIndexAfterReorder === 0 ? null : newArray[newIndexAfterReorder - 1]

      reorderGroup({ variables: { group_name: active?.id, move_after_group_name: moveAfterName } })
    }
  }
  const handleItemReorder = ({ active, newContext, oldContext, over }: any) => {
    const oldIndex = active.data.current.sortable.index
    const newIndex = over.data.current.sortable.index
    const previousId = groups[newContext][oldContext === newContext ? (newIndex > oldIndex ? newIndex : newIndex - 1) : newIndex - 1]?.id

    reorderChecklistItem({
      variables: {
        data_point_field_id: active.id,
        ...(newIndex ? { move_after_data_point_field_id: previousId } : { move_to_top_group: newContext })
      }
    })
  }

  if (isEmpty(sortedNames)) return null

  return (
    <>
      {reorderGroupLoading || reorderChecklistItemLoading ? (
        <ChecklistSkeleton />
      ) : (
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          sensors={sensors}
        >
          <SortableContext id={'group_context'} items={sortedNames} strategy={verticalListSortingStrategy}>
            {sortedNames.map((name: any) => (
              <SortablePanel
                activeKeys={activeKeys}
                disableCollapse={disableCollapse}
                id={name}
                isReorderingEnabled={isReorderingEnabled}
                key={formatKey(name)}
                listItems={groups[name]}
                setActiveKeys={setActiveKeys}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {/* set current group, then find the active DPF by ID (or pass it up some other way) */}
            {activeDPF && <CciLeftPanelChecklistCard dataPointField={activeDPF} />}
          </DragOverlay>
        </DndContext>
      )}
    </>
  )
}

// useEffect in child container- listen for changes of like currentDropContext = useState, currentDropArray = reordered array from ondragend.
// when those change fire setState of local array(s) to update
// or will this be done automatically because cache will be updated? doubt it
