import { Box, Typography } from '@mui/material'
import { Features, Permissions, useUserAccess } from '../../../../../../../hooks/useUserAccess'
import { Sources } from '../CreateInputs/SourceInput'
import { getCollisionTypeLabel } from '../../../../../../../utils/cci'
import { sortBy } from 'lodash'
import React, { FC, useEffect, useMemo } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'

// types

type _CollisionTypeInputProps = { collisionType: CollisionTypes; handleData: (type: string, value: any) => void; source: Sources }

export enum CollisionTypeLabels {
  CASCADE = 'Cascade',
  EQUIVALENCE = 'Equivalence',
  UNION = 'Union'
}

export enum CollisionTypes {
  CASCADE = 'CASCADE',
  EQUIVALENCE = 'EQUIVALENCE',
  UNION = 'UNION'
}

// components

export const CollisionTypeInput: FC<_CollisionTypeInputProps> = ({ collisionType, handleData, source }) => {
  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  const collisionTypeList = useMemo(
    () =>
      sortBy(
        Object.values(CollisionTypes).map(value => ({
          label: CollisionTypeLabels[value],
          value
        })),
        'label'
      ),
    []
  )

  useEffect(() => {
    if (source !== Sources.INTERNAL) {
      handleData('collision_type', null)
    }
  }, [source]) // eslint-disable-line react-hooks/exhaustive-deps

  if (source !== Sources.INTERNAL) return null

  return (
    <Box sx={{ alignItems: 'baseline', display: 'flex' }}>
      <Typography component="label" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        Collision Type
      </Typography>

      <Box sx={{ flex: 1 }}>
        <SelectInput
          isClearable
          isDisabled={!hasFullEditAccess}
          onChange={({ value }: { value: string }) => handleData('collision_type', value)}
          options={collisionTypeList}
          placeholder={getCollisionTypeLabel(collisionType)}
          value={collisionType}
        />
      </Box>
    </Box>
  )
}
