import { Features, Permissions, useUserAccess } from '../../../../../../../hooks/useUserAccess'
import { Maybe } from 'graphql/jsutils/Maybe'
import DateInput from '../../../../../../../components/DatapointInput/DateInput'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import NumberInput from '../../../../../../../components/DatapointInput/NumberInput'
import Percentage from '../../../../../../../components/DatapointInput/Percentage'
import React, { FC, SyntheticEvent } from 'react'
import SelectInput from '../../../../../../../components/SelectInput/SelectInput'

// types

type _CCI_InputProps = {
  fieldType: any
  id?: string
  options?: Maybe<Maybe<string>[]>
  placeholder?: string
  setValue: (v: any) => void
  value: any
  valueFormat?: string
}

// components

export const CCI_Input: FC<_CCI_InputProps> = ({ fieldType, id, options, placeholder, setValue, value, valueFormat }) => {
  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  switch (fieldType) {
    case 'TEXT':
    case 'DURATION':
    case 'CURRENCY':
    case 'CURRENCY_TYPE':
    case 'GEO_CITY':
    case 'GEO_STATE':
    case 'GEO_COUNTRY':
    case 'ADDRESS':
      return (
        <FreeText
          id={id}
          isDisabled={!hasFullEditAccess}
          onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => setValue(event.currentTarget.value)}
          placeholder={placeholder || (hasFullEditAccess ? 'Enter a default value (text)' : '')}
          value={value}
        />
      )

    case 'MULTI_SELECT_DROP_DOWN':
    case 'DROP_DOWN':
    case 'BOOLEAN':
      if (!options) return null
      return (
        <SelectInput
          id={id}
          isClearable
          isDisabled={!hasFullEditAccess}
          onChange={(item: any) => setValue(item?.value || '')}
          options={
            // this filters out items that at @@_DELETE_THIS_ITEM as options, and then maps to convert from handleData format to react-select format
            options
              .filter((item: any) => {
                if (typeof item === 'object' && item !== null && item?.newValue === '@@_DELETE_THIS_ITEM') {
                  return false
                } else {
                  return true
                }
              })
              .map((item: any) => {
                if (typeof item === 'object' && item !== null) {
                  return { label: item?.newValue || item?.currentValue, value: item?.newValue || item?.currentValue }
                } else {
                  return { label: item, value: item }
                }
              })
          }
          placeholder={placeholder || (hasFullEditAccess ? 'Select a default value' : '')}
          value={value ? { label: value, value } : null}
        />
      )

    case 'PERCENTAGE':
      return (
        <Percentage
          id={id}
          isDisabled={!hasFullEditAccess}
          name={placeholder || 'Enter a default value (number)'}
          onChange={event => setValue(event.currentTarget.value)}
          value={value}
        />
      )

    case 'FLOAT':
    case 'NUMBER':
      return (
        <NumberInput
          id={id}
          isDisabled={!hasFullEditAccess}
          isFloat={fieldType === 'FLOAT'}
          onChange={(event: SyntheticEvent<HTMLInputElement>) => setValue(event?.currentTarget?.value || null)}
          placeholder={placeholder || 'Enter a default value (number)'}
          value={value}
        />
      )

    case 'DATE':
      return <DateInput displayFormat={valueFormat} id={id} isDisabled={!hasFullEditAccess} onChange={value => setValue(value || '')} value={value} />

    default:
      if (process.env.NODE_ENV !== 'production') {
        console.info(`Unsupported field type: ${fieldType}`)
      }
      return null
  }
}
