import { PreAnnotationChecklist } from '../components/DataPointPanel/PreAnnotationChecklist'
import { useDocumentAttachmentsQuery, useNotesTabCountQuery } from '../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import DataPointPanel from '../components/DataPointPanel'
import React from 'react'

// components

const DocumentDataPointPanelContainer = () => {
  const { documentId } = useParams<{ documentId: string }>()

  const { data: documentAttachmentsData } = useDocumentAttachmentsQuery({ variables: { documentId } })
  const { data: notesTabCountData } = useNotesTabCountQuery({ variables: { resourceId: documentId } })

  const attachmentCount = documentAttachmentsData?.document_attachments?.filter(item => !item?.is_deleted).length || 0
  const noteCount = notesTabCountData?.notes_tab_count || 0

  return <DataPointPanel attachmentCount={attachmentCount} documentId={documentId} noteCount={noteCount} />
}

// eslint-disable-next-line react/display-name
export default ({ isPreAnnot }: { isPreAnnot: boolean | null }) => (isPreAnnot ? <PreAnnotationChecklist /> : <DocumentDataPointPanelContainer />)
