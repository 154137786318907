import { useCciMainContext } from '../../CCI_Main'
import CCI_RightPanel_Content from './CCI_RightPanel_Content'
import React, { useEffect } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

export default function CCI_RightPanel() {
  const { isChecklistGptEnabled, setActiveComponent, tab } = useCciMainContext()

  useEffect(() => {
    switch (tab) {
      case 'checklist':
      case 'documentTypes':
      case 'integrations':
      case 'roles':
      case 'users':
        setActiveComponent(null)
        break
    }
    // eslint-disable-next-line
  }, [tab])

  return (
    <div className={css.documentPanel}>
      {/* fake div below to add drop shadow effect to navbar */}
      <div className={clsx(css.documentSelector, css.dropShadow)}>
        <div className={clsx(css.inner, css.dropShadow)} style={{ height: '1px' }} />
      </div>

      <div className={clsx(css.documentWrapper, css.withSelector)}>
        <CCI_RightPanel_Content key={String(isChecklistGptEnabled)} />
      </div>
    </div>
  )
}
