import useCurrentUser from './useCurrentUser'

// enums

export enum TenantTypes {
  DEMO = 'DEMO',
  GOLD = 'GOLD',
  POC = 'POC',
  PRODUCTION = 'PRODUCTION',
  TEST = 'TEST',
  UAT = 'UAT'
}

// hooks

export const useTenantType = () => {
  const currentUser = useCurrentUser()

  return currentUser?.customers?.edges[0]?.node?.type as TenantTypes | undefined
}

export const useIsNonProductionTenant = () => {
  const currentUser = useCurrentUser()
  const tenantType = useTenantType()

  // Temporary guard to treat the "RevRec Sales Demo" tenant (which the sales team is fiercely protective of) as a production tenant.
  const isRevRecSalesDemo = currentUser?.customers?.edges[0]?.node?.id === 'Q3VzdG9tZXI6NjRmMWNhMjIxNGZjMTUyYTJjNDQ2Yzkz'

  return tenantType !== TenantTypes.PRODUCTION && !isRevRecSalesDemo
}
