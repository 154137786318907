import { CCI_LeftPanel } from './components/LeftPanel/CCI_LeftPanel'
import { ChecklistGptOptionSelector } from './components/Modal_components/ChecklistGptTab/ChecklistGptOptionSelector'
import { CreateNewField } from './components/Modal_components/ChecklistGptTab/CreateNewField'
import { DeleteUser } from './components/Modal_components/UsersTab/DeleteUser'
import { FIELD_CREATION_SUCCESS_MESSAGE, ReviewCreation } from './components/Modal_components/ChecklistTab/ReviewCreation'
import { Helmet } from 'react-helmet'
import { MessageModal } from '../../components/ModalOptions/MessageModal'
import { ReviewEdits as ReviewChecklistGptTabEdits } from './components/Modal_components/ChecklistGptTab/ReviewEdits'
import { ReviewEdits } from './components/Modal_components/ChecklistTab/ReviewEdits'
import { useCciDealDataPointFieldLazyQuery } from '../../graphql/codegen/hooks'
import { useContextInit } from '../../hooks/useContextInit'
import { useHasChecklistGptAccess } from '../../hooks/useUserAccess'
import { useParams } from 'react-router-dom'
import Button from '../../components/Button'
import CCI_RightPanel from './components/RightPanel/CCI_RightPanel'
import ComponentLoadingOverlay from '../../components/ComponentLoadingOverlay'
import DeleteField from './components/Modal_components/ChecklistTab/DeleteField'
import DeleteIntegration from './components/Modal_components/IntegrationsTab/DeleteIntegration'
import EditNeutralTag from './components/Modal_components/ChecklistTab/EditNeutralTag'
import Modal from '../../components/Modal'
import React, { Dispatch, SetStateAction, createContext, useCallback, useMemo, useState } from 'react'
import ReviewDocTypeEdits from './components/Modal_components/ChecklistTab/ReviewDocTypeEdits'
import ReviewIntegrationEdits from './components/Modal_components/IntegrationsTab/ReviewIntegrationEdits'
import ReviewNewIntegration from './components/Modal_components/IntegrationsTab/ReviewNewIntegration'
import ReviewUserEdits from './components/Modal_components/UsersTab/ReviewUserEdits'
import SplitPane from 'react-split-pane'
import css from './style.module.scss'

// types

type _CciMainContext = {
  activeComponent: ActiveComponents | null
  closeModal: any
  contentData: any
  documentTypesField: any
  errors: string[]
  expandAllCallback?: () => void
  expandedAccordionMap: _ExpandedAccordionMap
  isChecklistGptEnabled: boolean
  isModalOpen: boolean
  leftPanelLoading: boolean
  modalContent: any
  openModal: any
  refetchSelectedDataPointField: () => void
  selectedItem: any
  setActiveComponent: Dispatch<SetStateAction<ActiveComponents | null>>
  setContentData: Dispatch<SetStateAction<any>>
  setDocumentTypesField: Dispatch<SetStateAction<any>>
  setErrors: Dispatch<SetStateAction<string[]>>
  setExpandAllCallback: (callback: () => void) => void
  setIsChecklistGptEnabled: Dispatch<SetStateAction<boolean>>
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  setLeftPanelLoading: Dispatch<SetStateAction<boolean>>
  setMessageTitle: Dispatch<SetStateAction<string>>
  setModalContent: Dispatch<SetStateAction<any>>
  setModalError: Dispatch<SetStateAction<any>>
  setModalLoading: Dispatch<SetStateAction<boolean>>
  setModalLoadingMessage: Dispatch<SetStateAction<string>>
  setModalOption: Dispatch<SetStateAction<ModalOptions | null>>
  setModalSuccess: Dispatch<SetStateAction<any>>
  setModalTitle: Dispatch<SetStateAction<string>>
  setSelectedItem: Dispatch<SetStateAction<any>>
  tab?: string
  toggleAccordion: (accordionType: Accordions) => void
}

type _ExpandedAccordionMap = Record<string, boolean>

// enums

export enum Accordions {
  ADVANCED_SETTINGS,
  DATA_TABLE_EXTRACTION_CONFIGURATION,
  EXTERNAL_EXTRACTION_CONFIGURATION,
  FIELD_CONFIGURATION,
  INTERNAL_EXTRACTION_CONFIGURATION
}

export enum ActiveComponents {
  CREATE_FIELD = 'Create Field',
  CREATE_INTEGRATION = 'Create Integration',
  CREATE_USER = 'Create User',
  DELETE_FIELD = 'Delete Field',
  EDIT_DOCUMENT_TYPES = 'Edit Document Types',
  EDIT_FIELD = 'Edit Field',
  EDIT_INTEGRATION = 'Edit Integration',
  EDIT_USER = 'Edit User'
}

export enum ModalOptions {
  CHECKLIST_GPT = 'ChecklistGPT',
  CREATE_NEW_FIELD = 'Create New Field',
  DELETE_FIELD = 'Delete Field',
  DELETE_INTEGRATION = 'Delete Integration',
  DELETE_USER = 'Delete User',
  EDIT_AUTOMATION_TAG = 'Edit Automation Tag',
  REVIEW_DOCUMENT_TYPE_EDITS = 'Review Document Type Edits',
  REVIEW_EDITS = 'Review Edits',
  REVIEW_INTEGRATION_EDITS = 'Review Integration Edits',
  REVIEW_NEW_FIELD = 'Review New Field',
  REVIEW_NEW_INTEGRATION = 'Review New Integration',
  REVIEW_NEW_USER = 'Review New User',
  REVIEW_USER_EDITS = 'Review User Edits'
}

// constants

const MODAL_CONTENT_STYLES = {
  default: { overflow: 'auto' },
  fixedPosition: { maxHeight: '85vh', overflow: 'auto', top: '10vh', transform: 'translate(-50%, 0)' }
}

// context

const CciMainContext = createContext<_CciMainContext | null>(null)

// hooks

export const useCciMainContext = () => useContextInit(CciMainContext)

// components

export default function CCI_Main() {
  const { tab } = useParams<{ tab?: string }>()
  const [activeComponent, setActiveComponent] = useState<ActiveComponents | null>(null)
  const [contentData, setContentData] = useState<any>('')
  const [documentTypesField, setDocumentTypesField] = useState<any>('')
  const [errors, setErrors] = useState<string[]>([])
  const [expandAllCallback, setExpandAllCallback] = useState<() => void>()
  const [expandedAccordionMap, setExpandedAccordionMap] = useState<_ExpandedAccordionMap>({
    [Accordions.ADVANCED_SETTINGS]: true,
    [Accordions.DATA_TABLE_EXTRACTION_CONFIGURATION]: true,
    [Accordions.EXTERNAL_EXTRACTION_CONFIGURATION]: true,
    [Accordions.INTERNAL_EXTRACTION_CONFIGURATION]: true
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [leftPanelLoading, setLeftPanelLoading] = useState<boolean>(true)
  const [messageTitle, setMessageTitle] = useState<any>('')
  const [modalContent, setModalContent] = useState<any>({})
  const [modalError, setModalError] = useState<any>('')
  const [modalLoading, setModalLoading] = useState(false)
  const [modalLoadingMessage, setModalLoadingMessage] = useState('')
  const [modalOption, setModalOption] = useState<ModalOptions | null>(null)
  const [modalSuccess, setModalSuccess] = useState<any>('')
  const [modalTitle, setModalTitle] = useState('')
  const [selectedItem, setSelectedItem] = useState<any>('')

  const hasChecklistGptAccess = useHasChecklistGptAccess()
  const [isChecklistGptEnabled, setIsChecklistGptEnabled] = useState(false)

  const [cciDealDataPointFieldLazyQuery] = useCciDealDataPointFieldLazyQuery({ fetchPolicy: 'network-only' })

  const handleClose = () => {
    if (activeComponent === 'Delete Field') {
      // hides form when clicking off-modal to cancel delete action
      closeDeleteModal()
    } else {
      closeModal()
    }
  }

  const openModal = ({ content, modalOption, title }: { content?: any; modalOption: ModalOptions; title?: string }) => {
    setModalOption(modalOption)
    setModalTitle(title ?? modalOption)
    setModalContent(content ? { ...content } : undefined)
    setIsModalOpen(true)
  }

  const closeDeleteModal = () => {
    closeModal()
    setActiveComponent(null)
    setSelectedItem('')
  }

  const closeModal = useCallback(
    ({ isForced }: { isForced?: boolean } = {}) => {
      if (!modalLoading || isForced) {
        setModalContent(null)
        setModalError('')
        setModalLoading(false)
        setModalLoadingMessage('')
        setModalOption(null)
        setModalSuccess('')
        setModalTitle('')
        setMessageTitle('')
        setIsModalOpen(false)
      }
    },
    [modalLoading]
  )

  const refetchSelectedDataPointField = useCallback(() => {
    if (selectedItem?.id)
      cciDealDataPointFieldLazyQuery({
        variables: { id: selectedItem.id },
        onCompleted: data => {
          const dataPointField = data?.data_point_fields?.edges?.[0]?.node

          if (dataPointField) setSelectedItem(dataPointField)
        }
      })
  }, [cciDealDataPointFieldLazyQuery, selectedItem])

  const toggleAccordion = useCallback(
    (accordionType: Accordions) => setExpandedAccordionMap(prevState => ({ ...prevState, [accordionType]: !prevState[accordionType] })),
    []
  )

  const context = useMemo<_CciMainContext>(
    () => ({
      activeComponent,
      closeModal,
      contentData,
      documentTypesField,
      errors,
      expandAllCallback,
      expandedAccordionMap,
      isChecklistGptEnabled,
      isModalOpen,
      leftPanelLoading,
      modalContent,
      openModal,
      selectedItem,
      toggleAccordion,
      refetchSelectedDataPointField,
      setActiveComponent,
      setContentData,
      setDocumentTypesField,
      setErrors,
      setExpandAllCallback,
      setIsChecklistGptEnabled,
      setIsModalOpen,
      setLeftPanelLoading,
      setMessageTitle,
      setModalContent,
      setModalError,
      setModalLoading,
      setModalLoadingMessage,
      setModalOption,
      setModalSuccess,
      setModalTitle,
      setSelectedItem,
      tab
    }),
    [
      activeComponent,
      closeModal,
      contentData,
      documentTypesField,
      errors,
      expandAllCallback,
      expandedAccordionMap,
      isChecklistGptEnabled,
      isModalOpen,
      leftPanelLoading,
      modalContent,
      refetchSelectedDataPointField,
      selectedItem,
      tab,
      toggleAccordion
    ]
  )

  useMemo(() => {
    // Temporary solution to enable/disable the new `ChecklistGPT` UI.
    if (hasChecklistGptAccess) {
      const storedValue = localStorage.getItem('isChecklistGptEnabled')

      setIsChecklistGptEnabled(storedValue !== null ? JSON.parse(storedValue) : hasChecklistGptAccess)
    } else {
      localStorage.removeItem('isChecklistGptEnabled')

      setIsChecklistGptEnabled(false)
    }
  }, [hasChecklistGptAccess])

  useMemo(() => setErrors([]), [selectedItem.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <title>Configuration | Klarity</title>
      </Helmet>

      <CciMainContext.Provider value={context}>
        <div className={css.documentPage}>
          <div className={css.documentPageInner}>
            <SplitPane
              defaultSize={parseInt(localStorage.getItem('CCI_PanelWidth') || '600', 10)}
              minSize={360}
              onChange={size => localStorage.setItem('CCI_PanelWidth', String(size))}
            >
              <CCI_LeftPanel />
              <CCI_RightPanel />
            </SplitPane>
          </div>
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleClose}
          style={{ content: modalOption === ModalOptions.CHECKLIST_GPT ? MODAL_CONTENT_STYLES.fixedPosition : MODAL_CONTENT_STYLES.default }}
          title={messageTitle ? '' : modalTitle}
        >
          <ComponentLoadingOverlay loading={modalLoading} message={modalLoadingMessage} />

          {modalSuccess ? (
            <MessageModal
              closeModal={closeModal}
              message={modalSuccess}
              messageTitle={messageTitle || modalContent?.dataPointName || ''}
              primaryButton={
                modalSuccess === FIELD_CREATION_SUCCESS_MESSAGE && (
                  <Button
                    onClick={() => {
                      closeModal()
                      expandAllCallback?.()
                      setActiveComponent(ActiveComponents.EDIT_FIELD)
                      setSelectedItem(modalContent?.dataPointField)
                    }}
                  >
                    Go to the field edit page
                  </Button>
                )
              }
            />
          ) : modalError ? (
            <MessageModal closeModal={closeModal} message={modalError} messageTitle={messageTitle || modalContent?.dataPointName || 'Error'} />
          ) : (
            <>
              {modalOption === ModalOptions.DELETE_FIELD && (
                <DeleteField closeModal={closeDeleteModal} dataPointId={modalContent?.dataPointId} dataPointName={modalContent?.dataPointName} />
              )}
              {modalOption === ModalOptions.REVIEW_EDITS &&
                (hasChecklistGptAccess ? (
                  <ReviewChecklistGptTabEdits closeModal={closeModal} modalContent={modalContent} />
                ) : (
                  <ReviewEdits
                    closeModal={closeModal}
                    dataPointId={modalContent?.dataPointId}
                    dataPointName={modalContent?.dataPointName}
                    values={modalContent?.values}
                  />
                ))}
              {modalOption === ModalOptions.REVIEW_NEW_FIELD && <ReviewCreation closeModal={closeModal} values={modalContent?.values} />}
              {modalOption === ModalOptions.CREATE_NEW_FIELD && <CreateNewField closeModal={closeModal} modalContent={modalContent} />}
              {modalOption === ModalOptions.REVIEW_DOCUMENT_TYPE_EDITS && <ReviewDocTypeEdits />}
              {modalOption === ModalOptions.EDIT_AUTOMATION_TAG && <EditNeutralTag />}
              {modalOption === ModalOptions.CHECKLIST_GPT && <ChecklistGptOptionSelector modalContent={modalContent} />}

              {modalOption === ModalOptions.DELETE_INTEGRATION && <DeleteIntegration closeModal={closeModal} />}
              {modalOption === ModalOptions.REVIEW_NEW_INTEGRATION && <ReviewNewIntegration closeModal={closeModal} values={modalContent?.values} />}
              {modalOption === ModalOptions.REVIEW_INTEGRATION_EDITS && <ReviewIntegrationEdits closeModal={closeModal} values={modalContent?.values} />}

              {modalOption === ModalOptions.DELETE_USER && (
                <DeleteUser closeModal={closeModal} userId={modalContent?.userId} userName={modalContent?.userName} />
              )}
              {modalOption === ModalOptions.REVIEW_USER_EDITS && (
                <ReviewUserEdits closeModal={closeModal} userName={modalContent?.userName} user_id={modalContent?.userId} values={modalContent?.values} />
              )}
            </>
          )}
        </Modal>
      </CciMainContext.Provider>
    </>
  )
}
