/**
 * Flattens an object to a single level, with the keys being the path to the value.
 *
 * Example: { a: { b: { c: 1 } } } => { 'a.b.c': 1 }
 */
export const flattenObjectToPathMap = (object: unknown, currentPath: string = ''): Record<string, unknown> => {
  let pathMap: Record<string, unknown> = {}

  if (Array.isArray(object)) {
    object.forEach(item => {
      pathMap = { ...pathMap, ...flattenObjectToPathMap(item, currentPath) }
    })
  } else if (object && typeof object === 'object') {
    Object.entries(object).forEach(([key, value]) => {
      const fullPath = currentPath + (currentPath ? '.' : '') + key

      if (value && typeof value === 'object') {
        pathMap = { ...pathMap, ...flattenObjectToPathMap(value, fullPath) }
      } else {
        pathMap[fullPath] = value
      }
    })
  } else {
    pathMap[currentPath] = object
  }

  return pathMap
}

export const getKeyByValue = (object: any, value?: string) => {
  if (!object || !value) {
    return null
  } // @ts-ignore
  else {
    return Object.keys(object).find(key => object[key] === value)
  }
}
