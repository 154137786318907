import { CreateAnnotationRetry, DataPoint, MutateAnnotation, RetryAnnotationSuccess } from '../../graphql/codegen/schemas'
import { Formik } from 'formik'
import { RetryAnnotationDocument } from '../../graphql/codegen/hooks'
import { toast } from 'react-toastify'
import { useApolloClient } from '@apollo/client'
import Button from '../Button'
import GenericDataPointInput from '../DatapointInput/GenericDataPointInput'
import React, { FC, useState } from 'react'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'

// types

type _AnnotationRetryProps = {
  create_annotation: CreateAnnotationRetry
  documentId: string
  onRequestClose?: () => void
  prevAnnotation: MutateAnnotation
}

// components

const AnnotationRetryForm: FC<_AnnotationRetryProps> = ({ create_annotation, documentId, onRequestClose, prevAnnotation }) => {
  const { data_points } = create_annotation
  const apolloClient = useApolloClient()
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  if (!data_points) return null

  const dataPointById = data_points.reduce((acc: { [k: string]: any }, currDP) => {
    if (!currDP?.id) return acc
    acc[currDP.id] = currDP
    return acc
  }, {})

  // a little redundant, but values are key:value and we need to be able to look up data points by id to get their value separately for the form
  const initialValues = Object.entries(dataPointById).reduce((acc: { [k: string]: string }, [id, dp]) => {
    if (dp?.display_value) {
      acc[id] = dp.display_value
    } else {
      acc[id] = dp.value_iso_date || dp?.value_list?.length > 0 ? dp.value_list : ''
    }
    return acc
  }, {})

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setErrors }) => {
        setIsLoading(true)
        const { data } = await apolloClient.mutate({
          mutation: RetryAnnotationDocument,
          update: (cache, { data }) => {
            // RetryAnnotationSuccess includes the new annotation as the only edge in the annotations connection.
            const newAnnotation = (data?.retry_annotation as RetryAnnotationSuccess)?.document?.annotations?.edges[0]

            if (newAnnotation) {
              cache.modify({
                id: cache.identify({ __typename: 'Document', id: documentId }),
                fields: { annotations: annotations => ({ ...annotations, edges: [...annotations.edges, newAnnotation] }) }
              })
            }
          },
          variables: {
            documentId,
            newAnnotation: prevAnnotation,
            dataPointsInfoToUpdate: Object.entries(values).map(([id, value]) => {
              let rest = {}
              // For multiselect datapoints, the key has to be 'values' and these values have to be an array of strings
              if (Array.isArray(value)) {
                rest = { values: [...value] }
              } else {
                rest = { value }
              }
              return { data_point_id: id, ...rest }
            })
          }
        })

        if (data?.retry_annotation.__typename === 'RetryAnnotationSuccess') {
          toast.success('Annotation saved', { autoClose: 5000 })
          onRequestClose && onRequestClose()
          return
        }

        // @ts-ignore
        const errors = data?.retry_annotation?.errors?.reduce((acc, e) => {
          acc[e.data_point_id] = e.error
          return acc
        }, {})
        if (errors) {
          setErrors(errors)
        }
        setIsLoading(false)
      }}
    >
      {({ errors, handleSubmit, setValues, values }) => {
        let shouldBlockSubmit = false
        Object.entries(values).forEach(([id, value]) => {
          const dp: DataPoint = dataPointById[id]
          const { field_type } = dp?.data_point_field || {}
          if (field_type === 'MULTI_SELECT_DROP_DOWN' && (!value || value?.length < 1)) {
            shouldBlockSubmit = true
          } else if ((field_type === 'DROP_DOWN' || field_type === 'BOOLEAN') && !value) {
            shouldBlockSubmit = true
          }
        })
        setIsDisabled(shouldBlockSubmit)

        return (
          <form
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                e.preventDefault()
              }
            }}
            onSubmit={handleSubmit}
          >
            {Object.entries(values).map(([id, value]) => {
              const dp: DataPoint = dataPointById[id]
              const error = errors[id]

              return (
                <div key={id} style={{ margin: '16px auto', maxWidth: '99%' }}>
                  <div style={{ fontWeight: 800 }}>{dp.data_point_field?.name}</div>

                  <GenericDataPointInput
                    ariaLabel={dp.data_point_field?.name}
                    fieldType={dp.data_point_field?.field_type}
                    options={dp.data_point_field?.options}
                    setValue={(v: string) => setValues(prevValues => ({ ...prevValues, [id]: v }))}
                    value={value}
                  />

                  {error && <p className="error">{error}</p>}
                </div>
              )
            })}

            <div className={css.retryButtonDiv}>
              <Button onClick={onRequestClose} variant="danger">
                Cancel
              </Button>

              <WithTooltip content={isDisabled ? 'Dropdowns must have an option selected' : ''}>
                <Button disabled={isDisabled || isLoading || !!Object.keys(errors).length} loading={isLoading} type="submit">
                  Save
                </Button>
              </WithTooltip>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export default AnnotationRetryForm
