import { MoreVertical } from 'react-feather'
import { usePopper } from 'react-popper'
import Button from '../Button'
import React, { CSSProperties, KeyboardEvent, ReactNode, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import css from './style.module.scss'
import useOnClickOutside from '../../hooks/useOnClickOutside'

export function MoreMenuOld({
  menuItems,
  showOptionsOnMouseHover,
  label,
  placement = 'bottom-end',
  style,
  buttonStyle,
  offset = [8, 8],
  withIcon,
  customIcon,
  loading,
  isFixed
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null)
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [isVisible, setIsVisible] = useState(false)
  const [isHoveringContainer, setIsHoveringContainer] = useState(false)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const { attributes, styles } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: isFixed ? 'fixed' : undefined,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 16 } },
      { name: 'offset', options: { offset } }
    ]
  })
  useOnClickOutside(ref, () => setIsVisible(false))

  useEffect(() => {
    // hides menu options if clicked on outside of element
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event?.target as Node)) {
        setIsHoveringContainer(false)
      }
    }

    // adds event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // removes the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  const showMenuItems = useCallback(() => {
    setIsVisible(true)
  }, [])

  const hideMenuItems = useCallback(() => {
    if (!isHoveringContainer) {
      setIsVisible(false)
    }
  }, [isHoveringContainer])

  useEffect(() => {
    if (showOptionsOnMouseHover) {
      if (isHoveringContainer) {
        showMenuItems()
      } else {
        hideMenuItems()
      }
    }
  }, [hideMenuItems, isVisible, isHoveringContainer, showMenuItems, showOptionsOnMouseHover])

  return (
    <div onMouseEnter={() => setIsHoveringContainer(true)} ref={ref} style={style}>
      <Button
        aria-label="More options"
        icon={withIcon ? customIcon || <MoreVertical /> : null}
        loading={loading}
        onClick={() => setIsVisible(prev => !prev)}
        onKeyUp={(event: KeyboardEvent<HTMLButtonElement>) => {
          if (event.key === 'Escape') {
            setIsVisible(false)
          }
        }}
        setRef={setReferenceElement}
        style={buttonStyle}
        variant="tertiary"
      >
        {label && <div>{label}</div>}
      </Button>

      {isVisible && (
        <div className={css.menuPane} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <ul>{menuItems.map(item => (item?.label ? <MenuItem key={item.label} {...item} setIsVisible={setIsVisible} /> : null))}</ul>

          <div ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </div>
  )
}

type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end'

type Item = {
  label: string
  onClick: (...args: any) => void
}

interface Props {
  buttonStyle?: CSSProperties
  customIcon?: ReactNode
  isFixed?: boolean
  label?: string | null
  loading?: boolean
  menuItems: Item[]
  offset?: any
  placement?: Placement
  showOptionsOnMouseHover?: boolean
  style?: CSSProperties
  withIcon?: boolean
}

function MenuItem({ label, onClick, setIsVisible }: Item & { setIsVisible: (b: boolean) => void }) {
  const handleClick = (event: SyntheticEvent) => {
    setIsVisible(false)
    onClick(event)
  }
  return (
    <li
      className={css.menuItem}
      onClick={handleClick}
      onKeyUp={event => {
        if (event.key === 'Enter') {
          handleClick(event)
        }

        if (event.key === 'Escape') {
          setIsVisible(false)
        }
      }}
      role="button"
      tabIndex={0}
    >
      {label}
    </li>
  )
}
