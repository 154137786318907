import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import { Column, useTable } from 'react-table'
import { Opening } from '@hoologic/use-opening'
import { RESIZABLE_MINIMUM_HEIGHT, Resizable } from '../Resizable'
import { grey } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { FC, SyntheticEvent, useCallback, useState } from 'react'

// types

type _DataTable = { columns: Column<_DataTableRow>[]; data: _DataTableRow[] }
type _DataTableCell = { isChild?: boolean; isMatching?: boolean; text?: number | string }
type _DataTableMatchingProps = { opening: Opening }
type _DataTableRow = { [key: string]: _DataTableCell }

// constants

const BORDER_THICK = '2px solid #888 !important'
const BORDER_THIN = '1px solid #888 !important'
const COLOR_MATCHING = '#fff'
const COLOR_NON_MATCHING = '#eeb995'
const COLOR_TABLE_HEADER = '#ddd'
const COLOR_TABLE_SUBHEADER = '#eee'

const DATA_TABLE_MATCHING_DATA = {
  columns: [
    { Header: '', accessor: '0' },
    { Header: 'SKU ID', accessor: '1' },
    { Header: 'Product Name', accessor: '2' },
    { Header: 'Quantity', accessor: '3' },
    { Header: 'Unit Price', accessor: '4' },
    { Header: 'List Price', accessor: '5' },
    { Header: 'Discount', accessor: '6' },
    { Header: 'Net Amount', accessor: '7' }
  ],
  data: [
    {
      '0': { text: 'Non-matching' },
      '1': { isMatching: true, text: 'SKU-101' },
      '2': { isMatching: true, text: 'Matching' },
      '3': { isMatching: false, text: 'Non-matching' },
      '4': { isMatching: true, text: 'Matching' },
      '5': { isMatching: true, text: 'Matching' },
      '6': { isMatching: true, text: 'Matching' },
      '7': { isMatching: false, text: 'Non-matching' }
    },
    {
      '0': { isChild: true, text: 'Order Form' },
      '1': { isMatching: true, text: 'SKU-101' },
      '2': { isMatching: true, text: 'Advanced Analytics' },
      '3': { isMatching: false, text: '10' },
      '4': { isMatching: true, text: '99' },
      '5': { isMatching: true, text: '$120.00' },
      '6': { isMatching: true, text: '0.15' },
      '7': { isMatching: false, text: '$891.00' }
    },
    {
      '0': { isChild: true, text: 'Purchase Order' },
      '1': { isMatching: true, text: 'SKU101' },
      '2': { isMatching: true, text: 'Advanced Analytics' },
      '3': { isMatching: false, text: '12' },
      '4': { isMatching: true, text: '99' },
      '5': { isMatching: true, text: '120' },
      '6': { isMatching: true, text: '0.15' },
      '7': { isMatching: false, text: '1069.20' }
    },
    {
      '0': { text: 'Matching' },
      '1': { isMatching: true, text: 'SKU-102' },
      '2': { isMatching: true, text: 'Matching' },
      '3': { isMatching: true, text: 'Matching' },
      '4': { isMatching: true, text: 'Matching' },
      '5': { isMatching: true, text: 'Matching' },
      '6': { isMatching: true, text: 'Matching' },
      '7': { isMatching: true, text: 'Matching' }
    },
    {
      '0': { isChild: true, text: 'Order Form' },
      '1': { isMatching: true, text: 'SKU-102' },
      '2': { isMatching: true, text: 'Cloud Storage' },
      '3': { isMatching: true, text: '5' },
      '4': { isMatching: true, text: '50' },
      '5': { isMatching: true, text: '$60' },
      '6': { isMatching: true, text: '0.12' },
      '7': { isMatching: true, text: '$269.95' }
    },
    {
      '0': { isChild: true, text: 'Purchase Order' },
      '1': { isMatching: true, text: 'SKU102' },
      '2': { isMatching: true, text: 'Cloud Storage' },
      '3': { isMatching: true, text: '5' },
      '4': { isMatching: true, text: '50' },
      '5': { isMatching: true, text: '60' },
      '6': { isMatching: true, text: '0.12' },
      '7': { isMatching: true, text: '269.95' }
    },
    {
      '0': { text: 'Non-matching' },
      '1': { isMatching: true, text: 'SKU-103' },
      '2': { isMatching: true, text: 'Matching' },
      '3': { isMatching: true, text: 'Matching' },
      '4': { isMatching: false, text: 'Non-matching' },
      '5': { isMatching: true, text: 'Matching' },
      '6': { isMatching: true, text: 'Matching' },
      '7': { isMatching: true, text: 'Matching' }
    },
    {
      '0': { isChild: true, text: 'Order Form' },
      '1': { isMatching: true, text: 'SKU-103' },
      '2': { isMatching: true, text: 'Real-time Monitoring' },
      '3': { isMatching: true, text: '2' },
      '4': { isMatching: false, text: '199' },
      '5': { isMatching: true, text: '$250.00' },
      '6': { isMatching: true, text: '0.2' },
      '7': { isMatching: true, text: '$358.00' }
    },
    {
      '0': { isChild: true, text: 'Purchase Order' },
      '1': { isMatching: true, text: 'SKU103' },
      '2': { isMatching: true, text: 'Real-time Monitoring' },
      '3': { isMatching: true, text: '2' },
      '4': { isMatching: false, text: '19' },
      '5': { isMatching: true, text: '250' },
      '6': { isMatching: true, text: '0.2' },
      '7': { isMatching: true, text: '358' }
    },
    {
      '0': { text: 'Non-matching' },
      '1': { isMatching: true, text: 'SKU-104' },
      '2': { isMatching: false, text: 'Non-matching' },
      '3': { isMatching: true, text: 'Matching' },
      '4': { isMatching: true, text: 'Matching' },
      '5': { isMatching: true, text: 'Matching' },
      '6': { isMatching: true, text: 'Matching' },
      '7': { isMatching: false, text: 'Non-matching' }
    },
    {
      '0': { isChild: true, text: 'Order Form' },
      '1': { isMatching: true, text: 'SKU-104' },
      '2': { isMatching: false, text: 'Data Encryption Silver' },
      '3': { isMatching: true, text: '8' },
      '4': { isMatching: true, text: '20' },
      '5': { isMatching: true, text: '$25.00' },
      '6': { isMatching: true, text: '0.08' },
      '7': { isMatching: false, text: '$143.92' }
    },
    {
      '0': { isChild: true, text: 'Purchase Order' },
      '1': { isMatching: true, text: 'SKU104' },
      '2': { isMatching: false, text: 'Data Encryption Gold' },
      '3': { isMatching: true, text: '8' },
      '4': { isMatching: true, text: '20' },
      '5': { isMatching: true, text: '25' },
      '6': { isMatching: true, text: '0.08' },
      '7': { isMatching: false, text: '133.92' }
    },
    {
      '0': { text: 'Matching' },
      '1': { isMatching: true, text: 'SKU-105' },
      '2': { isMatching: true, text: 'Matching' },
      '3': { isMatching: true, text: 'Matching' },
      '4': { isMatching: true, text: 'Matching' },
      '5': { isMatching: true, text: 'Matching' },
      '6': { isMatching: true, text: 'Matching' },
      '7': { isMatching: true, text: 'Matching' }
    },
    {
      '0': { isChild: true, text: 'Order Form' },
      '1': { isMatching: true, text: 'SKU-105' },
      '2': { isMatching: true, text: 'User Access Management' },
      '3': { isMatching: true, text: '3' },
      '4': { isMatching: true, text: '80' },
      '5': { isMatching: true, text: '$90.00' },
      '6': { isMatching: true, text: '0.1' },
      '7': { isMatching: true, text: '$215.97' }
    },
    {
      '0': { isChild: true, text: 'Purchase Order' },
      '1': { isMatching: true, text: 'SKU105' },
      '2': { isMatching: true, text: 'User Access Management' },
      '3': { isMatching: true, text: '3' },
      '4': { isMatching: true, text: '80' },
      '5': { isMatching: true, text: '90' },
      '6': { isMatching: true, text: '0.1' },
      '7': { isMatching: true, text: '215.97' }
    }
  ]
}

// components

export const DataTableMatching: FC<_DataTableMatchingProps> = ({ opening }) => {
  const [table] = useState<_DataTable>(DATA_TABLE_MATCHING_DATA)

  const [height, setHeight] = useState(RESIZABLE_MINIMUM_HEIGHT)
  const [hiddenList, setHiddenList] = useState<boolean[]>(table.data.map(row => Boolean(row[0].isChild)))
  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable(table)

  const handleClick = useCallback(
    (event: SyntheticEvent | undefined, rowIndex: number) => {
      event?.stopPropagation()

      setHiddenList(hiddenList => {
        let isCollapseGroup = false

        return hiddenList.map((isHidden, hiddenIndex) => {
          if (hiddenIndex === rowIndex) {
            isCollapseGroup = true
          } else {
            if (isCollapseGroup && 'isChild' in table.data[hiddenIndex][0]) {
              return !isHidden
            } else {
              isCollapseGroup = false
            }
          }

          return isHidden
        })
      })
    },
    [table.data]
  )

  const renderCell = (cell: _DataTableCell) => cell?.text || ''

  if (!opening.isOpen) return null

  return (
    <Resizable height={height} setHeight={setHeight}>
      <Box sx={{ height }}>
        <Box sx={{ overflowY: 'scroll' }}>
          <Table
            {...getTableProps()}
            sx={{
              borderBottom: BORDER_THICK,
              borderCollapse: 'separate',
              '& td, & th': { borderWidth: 0, borderLeft: `1px solid ${grey[400]}` }
            }}
          >
            <TableHead>
              {headerGroups.map(headerGroup => (
                // eslint-disable-next-line react/jsx-key
                <TableRow
                  {...headerGroup.getHeaderGroupProps()}
                  sx={{ backgroundColor: COLOR_TABLE_HEADER, position: 'sticky', top: 0, zIndex: 1, '& th': { fontWeight: 600 } }}
                >
                  {headerGroup.headers.map(column => (
                    // eslint-disable-next-line react/jsx-key
                    <TableCell {...column.getHeaderProps()} sx={{ borderBottom: BORDER_THICK, p: 1, pt: 1.5 }}>
                      {isEmpty(column.Header) ? (
                        <Tooltip arrow placement="left" sx={{ m: -0.5 }} title="Close table">
                          <IconButton onClick={opening.close} size="small">
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        column.Header
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                const isChildRow = 'isChild' in table.data[rowIndex][0]
                const isFirstRow = rowIndex === 0

                prepareRow(row)

                return (
                  // eslint-disable-next-line react/jsx-key
                  <TableRow
                    {...row.getRowProps()}
                    {...(!isChildRow && { onClick: () => handleClick(undefined, rowIndex) })}
                    sx={{
                      ...(!isChildRow &&
                        !hiddenList[rowIndex + 1] && {
                          '& td': { backgroundColor: COLOR_TABLE_SUBHEADER, borderBottom: BORDER_THIN, fontWeight: 600 }
                        }),
                      ...(!isChildRow && { cursor: 'pointer' }),
                      ...(hiddenList[rowIndex] && { display: 'none' })
                    }}
                  >
                    {row.cells.map((cell, cellIndex) => (
                      // eslint-disable-next-line react/jsx-key
                      <TableCell
                        {...cell.getCellProps()}
                        sx={{
                          backgroundColor: 'isMatching' in cell.value ? (cell.value.isMatching ? COLOR_MATCHING : COLOR_NON_MATCHING) : COLOR_MATCHING,
                          p: 0.5,
                          pl: 1,
                          ...(!isChildRow && !isFirstRow && { borderTop: BORDER_THICK })
                        }}
                      >
                        <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
                          {!cellIndex && !isChildRow && (
                            <Tooltip arrow placement="left" sx={{ m: -0.5 }} title={`${hiddenList[rowIndex + 1] ? 'Show' : 'Hide'} documents`}>
                              <IconButton onClick={(event: SyntheticEvent) => handleClick(event, rowIndex)} size="small" sx={{ ml: -0.5 }}>
                                <ExpandMoreIcon
                                  color="action"
                                  sx={{ transform: `rotate(${hiddenList[rowIndex + 1] ? '-90' : '0'}deg)`, transition: 'transform 0.3s' }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}

                          <Box sx={{ ...(isChildRow && !cellIndex && { ml: 4.25 }) }}>{renderCell(cell.value)}</Box>
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Resizable>
  )
}
