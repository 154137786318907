import { KLARITY_BLUE } from '../../../utils/styleUtils'
import React, { FC, useEffect, useRef } from 'react'
import Select, { components } from 'react-select'

// types

type _DropdownProps = {
  ariaLabel?: string
  data_point_field: any
  isDisabled?: boolean
  isExpanded?: boolean
  isFocused: boolean
  isMulti?: boolean
  onChange?: (e: any) => void
  placeholder?: string
  setFocused: (arg: boolean) => void
  value: any
}

// components

const Dropdown: FC<_DropdownProps> = ({
  ariaLabel,
  data_point_field,
  isDisabled,
  isExpanded,
  isFocused,
  isMulti,
  onChange,
  placeholder,
  setFocused,
  value
}) => {
  const ref = useRef<HTMLElement>()
  const { options } = data_point_field

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus()
    }
  }, [isFocused])

  // https://react-select.com/components#replaceable-components
  // setting title on the container didn't work, had to add to props.innerprops
  const MultiValueContainer = (props: any) => (
    <components.MultiValueContainer {...{ ...props, innerProps: { ...props?.innerProps, title: props?.data?.label } }} />
  )

  return (
    <Select
      aria-label={ariaLabel || 'Select an option'}
      components={{ MultiValueContainer }}
      isClearable
      isDisabled={isDisabled}
      isMulti={isMulti}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      onBlur={() => setFocused(false)}
      onChange={(e: any) => {
        if (onChange) {
          onChange(e)
        }
      }}
      onFocus={() => setFocused(true)}
      openMenuOnFocus
      options={options.map((label: string) => ({ value: label.toLowerCase().replace(' ', '_'), label }))}
      placeholder={placeholder || ''}
      // @ts-ignore
      ref={ref}
      styles={{
        clearIndicator: (provided, { isFocused }) => ({ ...provided, visibility: isFocused ? 'visible' : 'hidden' }),
        container: provided => ({ ...provided, padding: 0 }),
        control: provided => ({ ...provided, borderColor: '#bdbec2', borderRadius: 4, boxShadow: 'none', fontSize: 14 }),
        dropdownIndicator: () => ({ display: 'none' }),
        indicatorSeparator: () => ({ display: 'none' }),
        menuPortal: provided => ({ ...provided, zIndex: 999999999 }),
        multiValue: provided => ({ ...provided, maxWidth: '300px' }),
        option: provided => ({ ...provided, fontSize: 14 }),
        placeholder: provided => ({ ...provided, color: '#e0e0e0' }),
        singleValue: provided => ({ ...provided, color: '#333' }),
        valueContainer: (provided, state) => {
          let styleOverrides = {}
          if (state.isMulti && isExpanded) {
            styleOverrides = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }
          }
          return { ...provided, ...styleOverrides }
        }
      }}
      tabSelectsValue={false}
      theme={theme => ({ ...theme, borderRadius: 2, colors: { ...theme.colors, primary: KLARITY_BLUE } })}
      value={value}
    />
  )
}

export default Dropdown
