import { useHasChecklistGptAccess } from './useUserAccess'
import { useIsNonProductionTenant } from './useTenantType'

// hooks

export const useIsExtractionRerunEnabled = () => {
  const hasChecklistGptAccess = useHasChecklistGptAccess()
  const isNonProductionTenant = useIsNonProductionTenant()

  return hasChecklistGptAccess && isNonProductionTenant
}
