import { ANALYTICS_URL } from '../../utils/apiUtils'
import { getIdToken } from '../../utils/sessionApiUtils'
import Loader from '../../components/Loader'
import React, { useEffect, useState } from 'react'

function Analytics() {
  const [src, setSrc] = useState<null | string>(null)

  useEffect(() => {
    async function fetchAnalytics() {
      const idToken = await getIdToken()
      const customerId = localStorage.getItem('customerId')
      const token = btoa(`${idToken}__||__${customerId}`)
      const xhr = new XMLHttpRequest()
      xhr.open('POST', ANALYTICS_URL, true)
      xhr.withCredentials = true
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onerror = function (e) {
        console.error(e)
      }
      xhr.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          const { analytics_url } = JSON.parse(this.response)
          setSrc(`${analytics_url}?embed=true`)
        }
      }
      xhr.send()
    }

    fetchAnalytics()
  }, [])

  return src ? <iframe frameBorder="0" src={src} style={{ height: '100%', width: '100%' }} title="RevRec Analytics" /> : <Loader size="l" />
}

export default Analytics
