import { Document, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { DocumentProps } from '../DocumentPanel/DocumentPanel'
import { FixedSizeList, FixedSizeList as WindowedList } from 'react-window' // switching to this instead of from 'react-pdf'; fixed mismatched api/worker version issue
import { debounce } from 'throttle-debounce'
import { getDocument } from '../../utils/documentApiUtils'
import DocumentPlaceholder from '../DocumentPlaceholder'
import PageRenderer from './PageRenderer'
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import useQString from '../../hooks/useQString'
import useResizeObserver from 'use-resize-observer'
import useSWR from 'swr'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface PageDimensions {
  height: number
  width: number
}
const DEFAULT_SCALE = 8.5 / 11
const DEFAULT_HEIGHT = 768
const GUTTER = 32 // Adding 16 for width further down to account for scrollbar

export default function PDFDoc({ documentFormat, documentId, loading }: DocumentProps) {
  const { data, error } = useSWR(documentId + documentFormat, async () => {
    // default to pdf view
    if (documentId && documentId !== 'upload') {
      return getDocument(documentId, documentFormat)
    }
  })

  return <BasePDFDoc data={data} error={error} id={documentId} loading={loading} />
}

// data can be a URL, base64 content, Uint8Array...
export function BasePDFDoc({ data, error, id, loading }: any) {
  const [numPages, setNumPages] = useState<number | null>(null)
  const [containerHeight, setContainerHeight] = useState<number | null>(null)
  const [pageDimensions, setPageDimensions] = useState<PageDimensions>({ width: DEFAULT_HEIGHT * DEFAULT_SCALE, height: DEFAULT_HEIGHT })
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleResize = useMemo(
    () =>
      debounce(100, () => {
        const rect = wrapperRef.current?.getBoundingClientRect()
        if (rect) {
          const { width } = rect
          setContainerHeight(rect.height)
          setPageDimensions({ width: width - GUTTER - 16, height: width / DEFAULT_SCALE - GUTTER })
        }
      }),
    [wrapperRef]
  )

  // set initial page dimensions on mount
  useLayoutEffect(() => {
    handleResize()
  }, [handleResize])

  // Listen to resize on wrapper as well to resize when panel dimensions change
  useResizeObserver({ ref: wrapperRef, onResize: handleResize })

  const [listRef, setListRef] = useState<FixedSizeList | null>()
  const { page } = useQString()

  // Assuming that all pages are 8.5 x 11, we don't have to do much heavy lifting
  // In case we do need to account for variable page dimensions, see here: https://github.com/michaeldzjap/react-pdf-sample/blob/master/src/Viewer.js
  const handleLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  const { height, width } = pageDimensions

  useEffect(() => {
    if (page && listRef) {
      listRef.scrollToItem(parseInt(page as string, 10) - 1, 'center')
    }
  }, [page, listRef])

  return (
    <div ref={wrapperRef} style={{ height: '100%', overflow: 'hidden', maxWidth: '100%' }}>
      {!data && (
        <div>
          <DocumentPlaceholder error={loading ? false : !!error} />
        </div>
      )}
      {data && containerHeight && (
        <Document
          file={data}
          key={`document_${id}`}
          loading={<DocumentPlaceholder />}
          onLoadError={console.error}
          onLoadSuccess={handleLoadSuccess}
          // Use cMapUrl and cMapPacked to handle East Asian characters
          options={{ cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`, cMapPacked: true }}
        >
          {height &&
            numPages && ( // @ts-ignore
              <WindowedList
                height={containerHeight}
                itemCount={numPages}
                itemData={{ width }}
                itemSize={height}
                ref={setListRef}
                style={{ overflowX: 'hidden' }} // itemData is passed to child as `data` prop, itemSize is row height
              >
                {PageRenderer}
              </WindowedList>
            )}
        </Document>
      )}
    </div>
  )
}
