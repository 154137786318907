import type { RuleGroupType } from 'react-querybuilder'

/**
 * Keys are returned by querybuilder, values are valid Mongo query operators https://docs.mongodb.com/manual/reference/operator/query/
 */
const FORMATTERS: { [key: string]: any } = {
  contains: (v: string) => ({ $regex: v }),
  doesNotContain: (v: string) => ({ $regex: `^((?!${v}).)*$`, $options: 's' }),
  null: () => null,
  notNull: () => ({ $ne: null }),
  in: (values: { id?: string; value?: string }[]) => {
    if (typeof values === 'string') {
      return null
    }
    return {
      $in: values?.map(v => {
        // We have to differentiate between data point values and hard-coded string values
        if (v.id) {
          return `ID__${v.id}`
        } else if (v.value) {
          return v.value
        }
        console.error(`Unable to parse value for value: ${v}`)
        return null
      })
    }
  },
  notIn: (values: { id?: string; value?: string }[]) => {
    if (typeof values === 'string') {
      return null
    }
    return {
      $nin: values?.map(v => {
        if (v.id) {
          return `ID__${v.id}`
        } else if (v.value) {
          return v.value
        }
        console.error(`Unable to parse value for value: ${v}`)
        return null
      })
    }
  },

  '=': (v: string) => v,
  '<': (v: string) => ({ $lt: v }),
  '<=': (v: string) => ({ $lte: v }),
  '>': (v: string) => ({ $gt: v }),
  '>=': (v: string) => ({ $gte: v }),
  '!=': (v: string) => ({ $ne: v }),

  // Prevent errors when react-querybuilder passes an empty string if the user has not yet entered a start or end value.
  between: (valueTuple: string | [string, string]) => {
    const [rangeStart, rangeEnd] = Array.isArray(valueTuple) ? valueTuple : ['', '']

    return { $gte: rangeStart, $lte: rangeEnd }
  },
  notBetween: (valueTuple: string | [string, string]) => {
    const [rangeStart, rangeEnd] = Array.isArray(valueTuple) ? valueTuple : ['', '']

    return { $lt: rangeStart, $gt: rangeEnd }
  }
}

const NUMBER_DATE_OPERATORS = ['=', '<', '<=', '>', '>=', '!=', 'between', 'notBetween']

/**
 * Format queries built by react-querybuilder and returns a MongoDB find object
 */
export default function formatMongoFilters(queryRules: RuleGroupType): any {
  const { combinator, rules } = queryRules

  const mongoRules = rules?.map(rule => {
    // if this rule is a RuleGroup itself, recur
    if ('rules' in rule) {
      return formatMongoFilters(rule)
    }

    const { field, operator } = rule
    let { value } = rule

    if (!FORMATTERS[operator] && process.env.NODE_ENV !== 'production') {
      throw Error(`no formatter exists for ${operator}`)
    } else if (value?.isDropDown && value.value) {
      return { [field]: value.value }
    } else if (['in', 'notIn'].includes(operator) && Array.isArray(value) && value.length > 0 && value[0].isMultiSelectDropDown) {
      return { [field]: { [`${operator === 'in' ? '$in' : '$nin'}`]: value.map(v => v.value) } }
    }

    if ((field.indexOf('IDDATE__') === 0 || field === 'created_at') && value) {
      // no-op – `value` is already either `yyyy-MM-dd` or a tuple of that pattern
    } else if (NUMBER_DATE_OPERATORS.includes(operator)) {
      if (Array.isArray(value)) {
        value = value.map(v => parseInt(v, 10))
      } else if (field !== '_id') {
        value = parseInt(value, 10)
      }
    }

    return { [field]: FORMATTERS[operator](value) }
  })

  return { [`$${combinator}`]: mongoRules }
}
