import { CciUsersDocument, useDeleteUserMutation } from '../../../../../graphql/codegen/hooks'
import { useCciMainContext } from '../../../CCI_Main'
import Button from '../../../../../components/Button'
import React, { FC, useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

// types

interface DeleteUserProps {
  closeModal: () => void
  userId?: string
  userName?: string
}

// components

export const DeleteUser: FC<DeleteUserProps> = ({ closeModal, userId, userName }) => {
  const { setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess } = useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const [deleteUser, { error: deleteUserError, loading: deleteUserLoading }] = useDeleteUserMutation({
    onCompleted: () => setModalSuccess('User successfully deleted.'),
    refetchQueries: [{ query: CciUsersDocument }]
  })

  const handleDelete = () => (userId ? deleteUser({ variables: { user_id: userId } }) : setModalError('Missing user ID.'))

  useEffect(() => setIsDisabled(!confirmed), [confirmed])

  useEffect(() => {
    if (deleteUserLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Deleting User…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
    }, [deleteUserLoading])

  // eslint-disable-next-line
    useEffect(()=>{setModalError(deleteUserError)}, [deleteUserError])

  return (
    <>
      {userName && <h5 style={{ textAlign: 'center' }}>{userName}</h5>}

      <p style={{ textAlign: 'center', marginTop: '8px' }}>Are you sure you want to delete this user?</p>

      <TypeToConfirm setConfirmed={setConfirmed} />

      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant="secondary">
          Cancel
        </Button>

        <WithTooltip content={!userId ? 'Missing user ID.' : isDisabled ? 'Type Confirm to proceed' : ''}>
          <Button disabled={!userId || isDisabled} onClick={handleDelete}>
            Submit
          </Button>
        </WithTooltip>
      </div>
    </>
  )
}
