import { Box, FormControlLabel, Switch, Typography } from '@mui/material'
import React, { ChangeEvent, FC, useCallback } from 'react'

// types

type _EnableExtractionCheckboxProps = { currentValue?: boolean; handleData: (value: boolean) => void; isDisabled?: boolean }

// components

export const EnableExtractionCheckbox: FC<_EnableExtractionCheckboxProps> = ({ currentValue, handleData, isDisabled }) => {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => handleData(Boolean(event.target.checked)), [handleData])

  return (
    <Box sx={{ my: 3 }}>
      <FormControlLabel
        control={<Switch checked={currentValue} disabled={isDisabled} id="enable-extraction-input" onChange={handleChange} value={currentValue} />}
        label={<Typography variant="body2">Enable extraction</Typography>}
        labelPlacement="end"
      />
    </Box>
  )
}
