import { Box, Typography, alpha } from '@mui/material'
import { FeedbackCard, FeedbackComment, FeedbackOptionButton, NegativeFeedbackSubmissionControls, PositiveFeedbackSubmissionControls } from '.'
import { FeedbackSessionEventType, FeedbackStatus, UserLearningOptionName, UserOption } from '../../graphql/codegen/schemas'
import { LearningFeedbackOptionLabels } from '../../pages/FeedbackPage/FeedbackPage'
import { MoreMenu } from '../MoreMenu'
import { deepPurple, grey } from '@mui/material/colors'
import { useFeedbackPageContext } from '../../pages/FeedbackPage'
import React, { FC, useEffect, useState } from 'react'

// types

type _FeedbackLearningEvent = {
  feedback_session_event_type?: FeedbackSessionEventType
  learning_outcome_log?: string
  system_learning_outcome?: _SystemLearningOutcome[]
  user_feedback?: string
  user_learning_option?: UserLearningOptionName
  user_learning_outcome?: string
  user_option?: UserOption
}

type _FeedbackLearningProps = { learningEvent: _FeedbackLearningEvent }

export type _SystemLearningOutcome = { content?: string; internal_name?: string }

// components

export const FeedbackLearning: FC<_FeedbackLearningProps> = ({ learningEvent }) => {
  const { system_learning_outcome, user_feedback, user_learning_option, user_learning_outcome } = learningEvent

  const {
    feedbackComment,
    feedbackPanelRef,
    feedbackStatus,
    isSubmittingFeedback,
    selectedLearningFeedbackOption,
    setFeedbackComment,
    setSelectedLearningFeedbackOption,
    setSystemPromptData,
    submitFeedback,
    submitFeedbackMutationError,
    systemPromptDialogOpening
  } = useFeedbackPageContext()

  const [selectedOption, setSelectedOption] = useState<UserLearningOptionName | null>(null)

  const isProcessing = Boolean(feedbackStatus === FeedbackStatus.FeedbackInProgressExtractionInProgress)

  const isFeedbackCardDisabled = Boolean(user_feedback) || isSubmittingFeedback || isProcessing

  // functions

  const openSystemPromptDialog = () => {
    setSystemPromptData({ systemLearningOutcomeList: system_learning_outcome, userLearningOutcome: user_learning_outcome })

    systemPromptDialogOpening.open()
  }

  // effects

  useEffect(() => {
    if (user_learning_option) setSelectedOption(user_learning_option)
  }, [user_learning_option])

  useEffect(() => {
    if (selectedOption !== selectedLearningFeedbackOption) setSelectedLearningFeedbackOption(selectedOption)
  }, [selectedOption, setSelectedLearningFeedbackOption]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (feedbackPanelRef.current) {
      feedbackPanelRef.current.scrollTop = feedbackPanelRef.current.scrollHeight
    }
  }, [feedbackPanelRef, isSubmittingFeedback, selectedOption])

  // render

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ color: grey[700], py: 2, textAlign: 'center' }} variant="caption">
        Klarity has learned from your feedback.
      </Typography>

      <FeedbackCard>
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between', position: 'relative' }}>
          <Typography sx={{ color: grey[800] }} variant="body2">
            Review Klarity’s learning and tell us if this is correct:
          </Typography>

          <Box sx={{ position: 'absolute', right: -4, top: -4 }}>
            <MoreMenu menuItemList={[{ label: 'Inspect Prompt', onClick: openSystemPromptDialog }]} />
          </Box>
        </Box>

        <Typography
          sx={{ bgcolor: alpha(deepPurple[50], 0.3), borderRadius: 2, color: deepPurple[900], overflowWrap: 'break-word', p: 1.5, whiteSpace: 'break-spaces' }}
          variant="body2"
        >
          {user_learning_outcome}
        </Typography>

        <Box sx={{ gridTemplateColumns: 'min-content min-content', display: 'grid', gap: 1 }}>
          {Object.values(UserLearningOptionName).map(option => (
            <FeedbackOptionButton
              isDisabled={isFeedbackCardDisabled}
              isSelected={selectedOption === option || (isProcessing && !user_feedback && option === UserLearningOptionName.LearningIsCorrect)}
              key={option}
              label={LearningFeedbackOptionLabels[option]}
              onClick={() => setSelectedOption(option)}
            />
          ))}
        </Box>

        {(selectedOption === UserLearningOptionName.LearningIsCorrect || (isProcessing && !user_feedback)) && (
          <PositiveFeedbackSubmissionControls
            hasError={Boolean(submitFeedbackMutationError)}
            isLoading={isSubmittingFeedback || isProcessing}
            loadingMessage={
              isProcessing ? 'Working on re-processing the document; this may take up to 20 seconds to 2 minutes…' : 'Klarity is learning from your feedback…'
            }
            onCancel={() => setSelectedOption(null)}
            onSubmit={submitFeedback}
          />
        )}

        {selectedOption === UserLearningOptionName.LearningNeedsImprovement && (
          <>
            <FeedbackComment
              isDisabled={isFeedbackCardDisabled}
              label="Tell us how the learning can be improved (required)"
              onValueChange={setFeedbackComment}
              value={user_feedback || feedbackComment}
            />

            {!isProcessing && !user_feedback && (
              <NegativeFeedbackSubmissionControls
                buttonTooltip={!feedbackComment ? 'Provide additional feedback in the comment box' : ''}
                isButtonDisabled={!feedbackComment || isSubmittingFeedback}
                isLoading={isSubmittingFeedback}
                onSubmit={submitFeedback}
              />
            )}
          </>
        )}
      </FeedbackCard>
    </Box>
  )
}
