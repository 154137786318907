import { useSortable } from '@dnd-kit/sortable'
import React, { FC } from 'react'

// types

type _DraggableWrapperProps = { canReorderItems: boolean; id: string }

// components

export const DraggableWrapper: FC<_DraggableWrapperProps> = ({ canReorderItems, children, id }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id, disabled: !canReorderItems })
  const style = { transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined, transition: transition || '' }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  )
}
