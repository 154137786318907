import { DataPointInput } from '../DatapointInput'
import { JumpToAnnotationIcon } from './JumpToAnnotationIcon'
import { JumpToPageIcon } from './JumpToPageIcon'
import { MessageModal } from '../ModalOptions/MessageModal'
import { _DataMatching } from './DatapointField'
import { isEmpty } from 'lodash'
import { useEditDataPointValueMutation } from '../../graphql/codegen/hooks'
import { useNestedChildDataPointsPanelContext } from '../dialogs/NestedChildDataPointsPanel'
import Button from '../Button'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import DeleteOSMDDOption from '../ModalOptions/DeleteOSMDDOption'
import Modal from '../Modal'
import React, { FC, useEffect, useRef, useState } from 'react'
import SaveProgressIcon from './SaveProgressIcon'
import clsx from 'clsx'
import css from './style.module.scss'
import type { DataPoint } from '../../graphql/codegen/schemas'

// types

type _InputRowProps = {
  ariaLabel?: string
  dataMatching?: _DataMatching
  dataPoint: DataPoint
  dealIsFinalized: boolean
  isDisabled?: boolean
  isFocused?: boolean
  isMutationLoading?: boolean
  isMutationSuccess?: boolean
  isNestedChildDataPoint?: boolean
  setFocused?: (x: boolean) => void
}

type _JumpButtonProps = { annotations: any; dataPoint: DataPoint; pageIds: any }

type _OptionMultiSelectInputProps = {
  annotations: any
  dataPoint: DataPoint
  error?: any
  inputRowHeight?: any
  isExpanded: boolean
  loading: boolean
  pageIds: any
  setIsExpanded?: any
  success: boolean
}

type _SaveProgressIconAndJumpButtonProps = {
  annotations: any
  dataPoint: DataPoint
  error?: any
  isNestedChildDataPoint?: boolean
  loading: boolean
  pageIds: any
  success: boolean
}

// constants

const ABORT_ERROR_MESSAGE = 'The user aborted a request.'

// components

const InputRow: FC<_InputRowProps> = ({
  ariaLabel,
  dataMatching,
  dataPoint,
  dealIsFinalized,
  isDisabled = false,
  isFocused,
  isMutationLoading: isOuterMutationLoading,
  isMutationSuccess: isOuterMutationSuccess,
  isNestedChildDataPoint,
  setFocused
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [removedOption, setRemovedOption] = useState('')
  const [annotationCount, setAnnotationCount] = useState<any>(undefined)
  const [modalTitle, setModalTitle] = useState('')
  const [modalLoading, setModalLoading] = useState(false)
  const [modalLoadingMessage, setModalLoadingMessage] = useState('')
  const [modalError, setModalError] = useState<any>('')
  const [modalSuccess, setModalSuccess] = useState<any>('')
  const [value, setValue] = useState<string | string[]>(() => dataPoint.value_iso_date || dataPoint.display_value || '')
  const [isOptionMulti, setIsOptionMulti] = useState(false)
  const [multiLength, setMultiLength] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const [annotations, setAnnotations] = useState<any>(undefined)
  const [pageIds, setPageIds] = useState<any>(undefined)
  const [inputRowHeight, setInputRowHeight] = useState('')
  const inputRowRef = useRef<any>()

  const { expandedNestedChildDataPoint, setExpandedNestedChildDataPointMutationState } = useNestedChildDataPointsPanelContext()

  useEffect(() => {
    setInputRowHeight(inputRowRef?.current?.clientHeight)
    const multiLength = dataPoint.value_list?.length || 0
    if (multiLength < 2) {
      setIsExpanded(false)
    }
  }, [inputRowRef, isExpanded, dataPoint.value_list])

  useEffect(() => {
    // The data point value may be modified by methods other than editDataPointValueMutation (e.g., on annotation creation/deletion),
    // so the input value must always be updated to match the data point value.
    setValue(dataPoint.value_iso_date || dataPoint.display_value || '')

    // Annotations from multiple documents appear as separate objects, each containing an array of annotations and a corresponding document object.
    // This process appends the appropriate documentId to each annotation and consolidates all annotations into a single array.
    setAnnotations(
      dataPoint?.annotations
        ?.map((annotation: any) => annotation?.annotations?.map((innerAnnotation: any) => ({ ...innerAnnotation, documentId: annotation?.document?.id })))
        .flat()
    )

    // [...new Set()] removes duplicates. pageIds had dupes in it, which resulted in extra jump buttons for the same pageID
    // @ts-ignore
    setPageIds([...new Set(dataPoint.hyperlink?.filter(Boolean))])

    const isMulti = dataPoint?.data_point_field?.field_type === 'MULTI_SELECT_DROP_DOWN'
    setMultiLength(dataPoint.value_list?.length || 0)
    setIsOptionMulti(isMulti && dataPoint?.data_point_field?.extraction_logic ? !!JSON.parse(dataPoint?.data_point_field?.extraction_logic)?.mapping : false)
  }, [dataPoint])

  const [editDataPointValueMutation, valueMutationState] = useEditDataPointValueMutation({
    onCompleted: () => {
      if (dataMatching?.isDataMatchingBusy) {
        dataMatching.setIsDataMatchingBusy(false)
      }
    },
    onError: error => {
      if (error.message.includes(ABORT_ERROR_MESSAGE)) return // Aborted requests are handled as errors, but should be ignored.

      console.error(error)

      setValue(dataPoint.value_iso_date || dataPoint.display_value || '') // Reset input value to match the last saved value.
    }
  })

  useEffect(() => {
    if (valueMutationState.loading && !dataMatching?.isDataMatchingBusy) {
      dataMatching?.setIsDataMatchingBusy(true)
    }
  }, [dataMatching, valueMutationState.loading])

  useEffect(() => {
    if (dataPoint.id === expandedNestedChildDataPoint?.id) {
      setExpandedNestedChildDataPointMutationState?.({ ...valueMutationState })
    }
  }, [valueMutationState.loading]) // eslint-disable-line react-hooks/exhaustive-deps

  const openModal = (removedOption: string, annotationCount: number) => {
    setRemovedOption(removedOption)
    setModalTitle('')
    setAnnotationCount(annotationCount)
    setIsOpen(true)
  }

  const closeModal = () => {
    if (!modalLoading) {
      setModalLoading(false)
      setModalError('')
      setModalSuccess('')
      setIsOpen(false)
    }
  }

  return (
    <>
      <div className={css.inputRow}>
        <span className={clsx(css.inputWrapper, isNestedChildDataPoint && css.nestedChildDataPoint)} ref={inputRowRef}>
          <DataPointInput
            annotations={annotations}
            ariaLabel={ariaLabel}
            dataPoint={dataPoint}
            dealIsFinalized={dealIsFinalized}
            editDataPointValueMutation={editDataPointValueMutation}
            isDisabled={isDisabled}
            isExpanded={isExpanded}
            isFocused={isFocused}
            isNestedChildDataPoint={isNestedChildDataPoint}
            isOptionMulti={isOptionMulti}
            openModal={openModal}
            setFocused={setFocused}
            setValue={setValue}
            value={value}
          />
        </span>

        {isOptionMulti && multiLength > 1 && annotations?.length ? (
          <OptionMultiSelectInput
            annotations={annotations}
            dataPoint={dataPoint}
            error={valueMutationState.error && !valueMutationState.error.message.includes(ABORT_ERROR_MESSAGE)}
            inputRowHeight={inputRowHeight}
            isExpanded={isExpanded}
            loading={Boolean(valueMutationState.loading || isOuterMutationLoading)}
            pageIds={pageIds}
            setIsExpanded={setIsExpanded}
            success={Boolean(valueMutationState.data || isOuterMutationSuccess)}
          />
        ) : (
          <SaveProgressIconAndJumpButton
            annotations={annotations}
            dataPoint={dataPoint}
            error={valueMutationState.error && !valueMutationState.error.message.includes(ABORT_ERROR_MESSAGE)}
            isNestedChildDataPoint={isNestedChildDataPoint}
            loading={Boolean(valueMutationState.loading || isOuterMutationLoading)}
            pageIds={pageIds}
            success={Boolean(valueMutationState.data || isOuterMutationSuccess)}
          />
        )}
      </div>

      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} title={modalTitle}>
        <ComponentLoadingOverlay loading={modalLoading || !removedOption} message={modalLoadingMessage} />
        {modalSuccess ? (
          <MessageModal closeModal={closeModal} message={modalSuccess} messageTitle={''} />
        ) : modalError ? (
          <MessageModal closeModal={closeModal} message={modalError} messageTitle={''} />
        ) : (
          <DeleteOSMDDOption
            annotationCount={annotationCount}
            closeModal={closeModal}
            dataPoint={dataPoint}
            removedOption={removedOption}
            setModalError={setModalError}
            setModalLoading={setModalLoading}
            setModalLoadingMessage={setModalLoadingMessage}
            setModalSuccess={setModalSuccess}
          />
        )}
      </Modal>
    </>
  )
}

const OptionMultiSelectInput: FC<_OptionMultiSelectInputProps> = ({
  annotations,
  dataPoint,
  error,
  inputRowHeight,
  isExpanded,
  loading,
  pageIds,
  setIsExpanded,
  success
}) => (
  <>
    {loading ? (
      <SaveProgressIcon error={error} loading={loading} success={success} />
    ) : (
      <div className={css.multiControls} style={{ height: inputRowHeight }}>
        {isExpanded ? (
          <>
            {dataPoint?.value_list?.map((item, index) => {
              const itemAnnotations = annotations.filter((annotation: any) => annotation?.label_name === item)

              return (
                <ul className={css.actions} key={`${item}${index}`} style={{ justifyContent: 'flex-end' }}>
                  <li style={{ paddingLeft: '4px', cursor: annotations?.length || pageIds?.length ? 'pointer' : 'default' }}>
                    {!isEmpty(itemAnnotations) && <JumpToAnnotationIcon annotations={itemAnnotations} />}
                  </li>
                </ul>
              )
            })}
            <Button onClick={() => setIsExpanded(!isExpanded)} size="s" variant="secondary">
              Collapse
            </Button>
          </>
        ) : (
          <Button onClick={() => setIsExpanded(!isExpanded)} size="s" variant="secondary">
            Expand
          </Button>
        )}
      </div>
    )}
  </>
)

const SaveProgressIconAndJumpButton: FC<_SaveProgressIconAndJumpButtonProps> = ({
  annotations,
  dataPoint,
  error,
  isNestedChildDataPoint,
  loading,
  pageIds,
  success
}) => (
  <>
    <SaveProgressIcon error={error} loading={loading} success={success} />

    {!isNestedChildDataPoint && <JumpButton annotations={annotations} dataPoint={dataPoint} pageIds={pageIds} />}
  </>
)

const JumpButton: FC<_JumpButtonProps> = ({ annotations, dataPoint, pageIds }) =>
  !isEmpty(annotations) ? (
    <JumpToAnnotationIcon annotations={annotations} dataPointId={dataPoint.id} />
  ) : !isEmpty(pageIds) ? (
    <JumpToPageIcon documentId={dataPoint.document?.id} pages={pageIds} />
  ) : (
    <span style={{ width: 24 }} />
  )

export default InputRow
