import * as queryString from 'query-string'
import { Column } from '../../../reducers/dashboardQueryReducer'
import { DashboardQueriesDocument, useCreateDashboardQueryMutation } from '../../../graphql/codegen/hooks'
import { FiSave } from 'react-icons/fi'
import { Formik } from 'formik'
import { encodeQuery, generateColumnsToSave } from '../../../utils/dashboardQueryUtils'
import { useLocation } from 'react-router-dom'
import Button from '../../Button'
import Input from '../../Input'
import Modal from '../../Modal'
import React, { FC, SyntheticEvent, useEffect, useState } from 'react'
import Toggle from '../../Toggle'
import WithTooltip from '../../WithTooltip'
import css from '../style.module.scss'

// types

type _CreateQueryModalContentProps = { closeModal: () => void; formattedTempQuery?: string; isDealsDashboard: boolean }

type _SavedQueryModalProps = { isDealsDashboard: any; isDisabled: boolean; selectedColumns: Column[]; tempQuery: any }

// components

const SavedQueryModal: FC<_SavedQueryModalProps> = ({ isDealsDashboard, isDisabled = false, selectedColumns, tempQuery }) => {
  const [isOpen, setIsOpen] = useState(false)
  const closeIt = () => setIsOpen(false)
  const [formattedTempQuery, setFormattedTempQuery] = useState<string | undefined>(undefined)

  useEffect(() => {
    // This func takes the currently selected cols and adds any columns relevant to the search and then formats them to be stored in the query string
    const cols = generateColumnsToSave(tempQuery, selectedColumns)
    const tempQueryFormatted = queryString.stringify({ cols, q: encodeQuery(tempQuery) }, { arrayFormat: 'comma' })
    setFormattedTempQuery(tempQueryFormatted)
  }, [tempQuery, selectedColumns])

  return (
    <>
      <WithTooltip content={isDisabled ? '' : 'Save query'}>
        <Button aria-label={isDisabled ? '' : 'Save query'} disabled={isDisabled} icon={<FiSave />} onClick={() => setIsOpen(true)} />
      </WithTooltip>

      <Modal isOpen={isOpen} onRequestClose={closeIt} title="Save Query">
        <CreateQueryModalContent closeModal={closeIt} formattedTempQuery={formattedTempQuery} isDealsDashboard={isDealsDashboard} />
      </Modal>
    </>
  )
}

const CreateQueryModalContent: FC<_CreateQueryModalContentProps> = ({ closeModal, formattedTempQuery, isDealsDashboard }) => {
  const location = useLocation()
  const isAttachmentsDashboard = location?.pathname?.includes('/dashboard/attachments') || false
  const [createDashboardQuery, { error: submissionError, loading }] = useCreateDashboardQueryMutation({
    onCompleted: closeModal,
    refetchQueries: [
      {
        query: DashboardQueriesDocument,
        variables: { dashboard: isAttachmentsDashboard ? 'ATTACHMENT' : isDealsDashboard ? 'DEAL' : 'DOCUMENT' }
      }
    ],
    awaitRefetchQueries: true
  })

  return (
    // NOTE: isDefault is expected by the api, to be implemented on the frontend in the future
    <Formik
      initialValues={{ name: '', isShared: true, isDefault: false }}
      onSubmit={values => {
        createDashboardQuery({
          variables: {
            ...values,
            queryString: formattedTempQuery || '',
            dashboard: isAttachmentsDashboard ? 'ATTACHMENT' : isDealsDashboard ? 'DEAL' : 'DOCUMENT'
          }
        })
      }}
      validate={values => {
        const errors: { [k: string]: string } = {}
        if (!values.name) {
          errors.name = 'Required'
        }
        return errors
      }}
    >
      {({ errors, handleSubmit, setFieldValue, touched, values }) => (
        <form className={css.saveQueryModalContent} onSubmit={handleSubmit}>
          <label>
            <h4>Query Name:</h4>

            <Input
              onChange={(e: SyntheticEvent<HTMLInputElement>) => setFieldValue('name', e.currentTarget.value)}
              placeholder="Give this query a unique name"
              value={values.name}
            />

            {errors.name && touched.name && <p className="error">{errors.name}</p>}
          </label>

          <div className={css.row}>
            <Toggle
              aria-label="Toggle on to share query"
              checked={values.isShared}
              onChange={(e: SyntheticEvent<HTMLInputElement>) => setFieldValue('isShared', e.currentTarget.checked)}
            />

            <div>
              <h4>Share query</h4>

              <p>Make this query available to others within your organization.</p>
            </div>
          </div>

          <Button loading={loading}>Save</Button>

          {submissionError && <p className="error">Something went wrong</p>}
        </form>
      )}
    </Formik>
  )
}

export default SavedQueryModal
