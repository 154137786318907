import { Box, Button, CircularProgress, Link, Tooltip, Typography } from '@mui/material'
import { common } from '@mui/material/colors'
import React, { FC, ReactNode } from 'react'

// types

type _NegativeFeedbackSubmissionControlsProps = {
  buttonTooltip: string | ReactNode
  isButtonDisabled: boolean
  isLoading: boolean
  isShowingSupportLink?: boolean
  onSubmit: () => void
}

// components

export const NegativeFeedbackSubmissionControls: FC<_NegativeFeedbackSubmissionControlsProps> = ({
  buttonTooltip,
  isButtonDisabled,
  isLoading,
  isShowingSupportLink,
  onSubmit
}) =>
  isLoading ? (
    <Box sx={{ alignItems: 'center', display: 'flex', gap: 1.25, py: '5.5px' }}>
      <CircularProgress size={16} />

      <Typography variant="body2">Processing your feedback to improve accuracy…</Typography>
    </Box>
  ) : (
    <Box sx={{ alignItems: 'center', display: 'flex', gap: 1.25, justifyContent: 'flex-end' }}>
      {/* TODO: This will be changed to a button that triggers an API call – don't use this for now. */}
      {isShowingSupportLink && (
        <Link color="inherit" href="mailto:hello@klarity.ai" variant="caption">
          Need support?
        </Link>
      )}

      <Tooltip arrow title={buttonTooltip || ''}>
        <Box sx={{ bgcolor: common.white }}>
          <Button color="tertiary" disabled={isButtonDisabled} onClick={onSubmit} size="small" type="submit" variant="outlined">
            Submit Feedback
          </Button>
        </Box>
      </Tooltip>
    </Box>
  )
