import { API_ROOT } from '../../../utils/apiUtils'
import { captureError } from '../../../utils/sentry'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useModalContext } from '../../../app'
import Button from '../../Button'
import Lottie from 'lottie-react'
import ManualUpload from '../../UploadModal/ManualUpload'
import React, { useEffect, useState } from 'react'
import animationData from '../../../assets/lottie/document-scan.json'
import axiosClient from '../../../utils/axiosClient'
import css from '../style.module.scss'

export default function UploadNewDocument({ counterparty, dealName, parentRef, setFormType, setLoading, setLoadingMessage }: any) {
  const { openModal, openPreview, setModalError } = useModalContext()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { dealId } = useParams<{ dealId: string }>()
  const [componentLoading, setComponentLoading] = useState(false)
  const [requiredCount, setRequiredCount] = useState<number>(0)
  const [requiredValues, setRequiredValues] = useState([])
  const [optionalValues, setOptionalValues] = useState([])
  const [document, setDocument] = useState<File>()
  const [confirmClose, setConfirmClose] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [disabledMessage, setDisabledMessage] = useState<any>()
  const [validationErrors, setValidationErrors] = useState<any>([])
  const [success, setSuccess] = useState<boolean>(false)
  const [customStyles, setCustomStyles] = useState<any>({})
  const [dealData, setDealData] = useState<any>({
    counterparty_name: counterparty?.name,
    counterparty_id: counterparty?.id,
    document_level: 'deal_level',
    deal_name: dealName,
    deal_id: dealId
  })
  const [initialData] = useState<any>({
    counterparty_name: counterparty?.name,
    counterparty_id: counterparty?.id,
    document_level: 'deal_level',
    deal_name: dealName,
    deal_id: dealId
  })

  useEffect(() => {
    const parentHeight = parentRef?.current?.offsetHeight
    const heightOffset = -48
    const calculatedHeight = parentHeight + heightOffset
    setCustomStyles({ maxHeight: `${calculatedHeight}px` })
  }, [parentRef, setCustomStyles])

  useEffect(() => {
    // validator useEffect
    const message = []
    let shouldEnable = true
    if (componentLoading) {
      shouldEnable = false
      message.push('Component loading')
    }
    if (!document || !dealData.document_level || !dealData?.document_type?.id) {
      shouldEnable = false
      message.push('Missing document data')
    }
    if (requiredCount > 0 && requiredValues?.length !== requiredCount) {
      shouldEnable = false
      message.push('Missing required values')
    }
    if (shouldEnable) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
    setDisabledMessage([...message])
  }, [componentLoading, requiredCount, requiredValues, document, dealData?.document_type?.id, dealData?.document_level])

  const handleClose = () => {
    if (success) {
      wipeForm()
      history.push(`${url}`)
    } else if (!confirmClose) {
      setConfirmClose(true)
    } else {
      wipeForm()
      setFormType('Initial')
    }
  }

  const wipeForm = () => {
    setIsDisabled(true)
    setConfirmClose(false)
    setRequiredValues([])
    setOptionalValues([])
    setDocument(undefined)
    setDealData({ ...initialData })
    setSuccess(false)
  }

  const handleSubmit = async () => {
    if (confirmClose) {
      handleClose()
    } else {
      setLoading(true)
      setLoadingMessage('Uploading Document…')
      await handleManualUpload()
    }
  }

  const handleError = (err: any) => {
    if (err === 'Document and document_type.id must be present') {
      setModalError({ title: 'Error: Missing required data', message: err })
      openModal()
    } else if (err === 'Counterparty name or ID is required') {
      setModalError({ title: 'Error: Missing required data', message: err })
      openModal()
    } else {
      if (err?.response?.data?.link) {
        const documentId = err.response.data.link.split('/documents/')[1] || undefined

        const isAlreadyUploaded: boolean = err.response.data.message.startsWith('File already present in Klarity with status')

        setModalError({
          title: isAlreadyUploaded ? '' : 'Error uploading document:',
          messageNode: (
            <>
              <p style={{ ...(isAlreadyUploaded && { fontWeight: 600 }) }}>{err.response.data.message}</p>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', maxWidth: '80%', margin: '32px auto 0 auto' }}>
                <Button variant="tertiary">
                  <a href={err.response.data.link}>{`Open Document`}</a>
                </Button>

                {isAlreadyUploaded || (
                  <Button
                    onClick={() => {
                      openPreview(documentId, true, 'Duplicate Document')
                    }}
                    variant="tertiary"
                  >{`Preview Document`}</Button>
                )}
              </div>
            </>
          )
        })
      } else {
        setModalError({
          title: 'Error uploading Document:',
          message: err?.response?.data?.message || err || 'An unknown error occurred. Please contact us for assistance.'
        })
      }
      openModal()
    }
  }

  const handleValidationErrors = (errors: any) => {
    try {
      const parsed = JSON.parse(errors)
      if (parsed?.['1__document'].length > 0) {
        setValidationErrors([...parsed?.['1__document']])
      }
    } catch (error) {
      captureError(error, 'UploadTab handleValidationErrors')
    }
  }

  const handleManualUpload = () => {
    if (document === undefined || dealData?.document_type?.id === undefined) {
      handleError('Document and document_type.id must be present')
    } else if (!dealData?.counterparty_id && !dealData?.counterparty_name) {
      handleError('Counterparty name or ID is required')
    } else {
      let data_point_values = {}
      requiredValues.forEach((item: any) => {
        data_point_values = { ...data_point_values, ...item }
      })
      optionalValues.forEach((item: any) => {
        data_point_values = { ...data_point_values, ...item }
      })

      const formData = new FormData()
      formData.append('1__document', document)
      formData.append('1__document_type_id', dealData?.document_type?.id)
      formData.append('1__document_type_name', dealData?.document_type?.name)
      formData.append('1__data_points', JSON.stringify(data_point_values))
      if (dealData?.counterparty_id) {
        formData.append('1__counter_party_id', dealData.counterparty_id)
      }
      if (dealData?.counterparty_name) {
        formData.append('1__counter_party_name', dealData.counterparty_name)
      }
      if (dealData?.deal_id) {
        formData.append('1__deal_id', dealData.deal_id)
      }
      if (dealData?.deal_name) {
        formData.append('1__deal_name', dealData.deal_name)
      }

      // console.info('data_point_values', data_point_values);
      // formData.forEach((item, index)=>{
      //   console.info(`formData[${index}]:`, item)
      // });

      return axiosClient
        .post(`${API_ROOT}/document/manual_upload`, formData)
        .then(() => {
          setLoading(false)
          setLoadingMessage('')
          setSuccess(true)
        })
        .catch(err => {
          console.error('ManualUpload upload error:', err?.response?.data?.message || err)
          if (err?.response?.data?.status === 'validation-error') {
            handleValidationErrors(err?.response?.data?.message)
          } else {
            handleError(err)
          }
          setLoading(false)
          setLoadingMessage('')
        })
    }
  }

  return (
    <div className={css.upload} style={customStyles}>
      <h3>{confirmClose ? 'Confirm Close' : success ? 'Success!' : `Upload documents to ${dealName}`}</h3>
      {confirmClose ? (
        <p>{`Are you sure you want to go back? Inputted data will be lost.`}</p>
      ) : success ? (
        <Success />
      ) : (
        <ManualUpload
          componentLoading={componentLoading}
          dealData={dealData}
          document={document}
          hasInitial
          optionalValues={optionalValues}
          requiredValues={requiredValues}
          setComponentLoading={setComponentLoading}
          setDealData={setDealData}
          setDocument={setDocument}
          setOptionalValues={setOptionalValues}
          setRequiredCount={setRequiredCount}
          setRequiredValues={setRequiredValues}
          validationErrors={validationErrors}
          wipeForm={wipeForm}
        />
      )}
      <div className={css.uploadButtonRow}>
        {success ? (
          <Button onClick={handleClose} variant={'secondary'}>{`Close`}</Button>
        ) : (
          <>
            <Button onClick={handleClose} variant={'secondary'}>{`Cancel`}</Button>
            <Button disabled={confirmClose ? false : isDisabled} onClick={handleSubmit} title={confirmClose ? '' : isDisabled ? disabledMessage : ''}>
              {confirmClose ? 'Close' : 'Submit'}
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

function Success() {
  return (
    <div className={css.success}>
      <Lottie animationData={animationData} style={{ height: 100 }} />
      <p>{`Your document was successfully uploaded.`}</p>
      <p>{`It will be available when finished processing.`}</p>
    </div>
  )
}
