import { useLayoutEffect } from 'react'

export const useLockBodyScroll = () => {
  const lockBodyScroll = () => {
    document.body.style.overflow = 'hidden'
  }

  const unlockBodyScroll = () => {
    document.body.style.overflow = ''
  }

  useLayoutEffect(() => unlockBodyScroll, [])

  return [lockBodyScroll, unlockBodyScroll]
}
