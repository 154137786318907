import React, { useEffect } from 'react'

export const useIntersectionObserver = (target: React.RefObject<HTMLElement>, onIntersect: () => void, onUnintersect: () => void, options = {}) => {
  useEffect(() => {
    const currentTarget = target.current

    if (!currentTarget) return

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          entry.isIntersecting ? onUnintersect() : onIntersect()
        })
      },
      { ...options }
    )

    observer.observe(currentTarget)

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget)
      }
    }
  }, [target, onIntersect, onUnintersect, options])
}
