import { Box, Button, List, ListItem, Tooltip, Typography } from '@mui/material'
import { CollisionTypes } from './SharedInputs/CollisionTypeInput'
import { EmptyStateMessage } from '../../../../../../components/EmptyStateMessage'
import { ExternalExtractionConfigurationAccordion } from './Accordions/ExternalExtractionConfigurationAccordion'
import { Features, Permissions, useUserAccess } from '../../../../../../hooks/useUserAccess'
import { FieldConfigurationAccordion } from './Accordions/FieldConfigurationAccordion'
import { FieldSemanticsInput } from './SharedInputs/FieldSemanticsInput'
import { InternalExtractionConfigurationAccordion } from './Accordions/InternalExtractionConfigurationAccordion'
import { ModalOptions, useCciMainContext } from '../../../../CCI_Main'
import { NeutralTagRow } from './EditInputs/NeutralTagRow'
import { Sources } from './CreateInputs/SourceInput'
import { isEmpty } from 'lodash'
import CircleIcon from '@mui/icons-material/Circle'
import InfoIcon from '@mui/icons-material/Info'
import NameInput from './EditInputs/NameInput'
import React, { FC, useEffect, useMemo, useState } from 'react'
import WithTooltip from '../../../../../../components/WithTooltip'
import css from './style.module.scss'

// types

type _GroupProps = { name: string }

// functions

const validateInputs = (selectedItem: any, values: any, setIsDisabled: any, setDisabledMessage: any, existingErrors: string[]) => {
  const errors = [...existingErrors]

  if (
    isEmpty(existingErrors) &&
    (!values || (Object.keys(values).length === 0 && values.constructor === Object)) &&
    (selectedItem?.source !== Sources.INTERNAL || !isEmpty(selectedItem?.extraction_field_type))
  ) {
    errors.push('No changes have been made')
  }

  if (selectedItem?.source === Sources.INTERNAL && isEmpty(selectedItem?.extraction_field_type) && isEmpty(values?.extraction_field_type)) {
    errors.push('Internal sources require an extraction type')
  }

  // If source is INTERNAL must have collision type
  if (selectedItem?.source === 'INTERNAL' && !selectedItem?.collision_type && !values?.collision_type) {
    errors.push(`Collision Type is required for INTERNAL Sources`)
  }

  // If dropdown or multi dropdown must have an option
  if (
    (selectedItem?.field_type === 'DROP_DOWN' ||
      selectedItem?.field_type === 'MULTI_SELECT_DROP_DOWN' ||
      values?.field_type === 'DROP_DOWN' ||
      values?.field_type === 'MULTI_SELECT_DROP_DOWN') &&
    (!selectedItem?.options?.length || selectedItem?.options?.length < 1) &&
    (!values?.options?.length || values?.options?.length < 1)
  ) {
    errors.push(`Dropdown and Multi-Dropdowns must have at least one option`)
  }

  // If field_type is DATE must have a value format
  if ((selectedItem?.field_type === 'DATE' || values?.field_type === 'DATE') && !selectedItem?.value_format && !values?.value_format) {
    errors.push(`Value Format is required when Field Type is DATE`)
  }

  // no duplicate options
  if (selectedItem?.options?.length || values?.options?.length) {
    const formattedValues =
      values?.options?.map((item: any) => {
        return item?.newValue || item?.currentValue
      }) || []
    const duplicateTags =
      formattedValues.filter((item?: any) => {
        if (!item || item === '@@_DELETE_THIS_VALUE') {
          return false
        }
        // should be one match for the item to match itself, but if length is more than one there is a duplicate in the newValue array
        const newValueDuplicates = formattedValues?.filter((newValue?: any) => {
          if (item === newValue) {
            return true
          } else {
            return false
          }
        })
        if (newValueDuplicates?.length > 1) {
          return true
        }
        // should be one match for the item to match itself, but if length is more than one there is a duplicate in the newValue array
        const currentValueDuplicates =
          selectedItem?.options?.filter((currentValue?: any, currentValueIndex?: any) => {
            if (!currentValue) {
              return false
            } // cant be duplicate if null
            // ignore currentValue if there is a new value for it, otherwise return true if there is a match
            else if (currentValue && !formattedValues[currentValueIndex] && item === currentValue) {
              return true
            } else {
              return false
            }
          }) || []
        if (currentValueDuplicates?.length > 1) {
          return true
        } else {
          return false
        }
      }) || []
    if (duplicateTags.length > 0) {
      errors.push(`Options must be unique`)
    }
  }

  // new OMSDD options must have an automation tag
  if (selectedItem?.option_to_neutral_tag_mapping !== null) {
    // only OMSDDs can/must have this field
    values?.options?.forEach((option: any) => {
      if (!option.currentValue && option?.newValue && !option?.neutralTag) {
        errors.push(`Automation tag is missing for option: ${option.newValue}`)
      }
    })
  }

  if (
    (values?.collision_type === CollisionTypes.CASCADE && isEmpty(values?.resolution_strategy)) ||
    (selectedItem?.collision_type === CollisionTypes.CASCADE && !values?.collision_type && values?.resolution_strategy && isEmpty(values?.resolution_strategy))
  ) {
    errors.push('Cascade collision types require a resolution strategy')
  }

  if (errors.length > 0) {
    setIsDisabled(true)

    setDisabledMessage(
      <Box sx={{ p: 1 }}>
        <Typography sx={{ fontSize: 14 }}>Missing required values:</Typography>

        <List sx={{ pb: 0 }}>
          {errors.map((error: string) => (
            <ListItem key={error} sx={{ alignItems: 'baseline', gap: 1, py: 0.5 }}>
              <CircleIcon sx={{ bottom: 1, fontSize: 6, position: 'relative' }} />

              <Typography sx={{ fontSize: 14 }}>{error}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    )
  } else {
    setIsDisabled(false)
    setDisabledMessage('')
  }
}

// components

export const EditField: FC = () => {
  const { errors, openModal, selectedItem } = useCciMainContext()
  const [disabledMessage, setDisabledMessage] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [values, setValues] = useState<any>(undefined)

  const hasCCIExtractionConfigurationAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_EXTRACTION_CONFIGURATION })
  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  const isExternalSource = useMemo(() => selectedItem?.source === 'EXTERNAL', [selectedItem])
  const isInternalSource = useMemo(() => selectedItem?.source === 'INTERNAL', [selectedItem])

  const handleData = (type: string, value: any) => {
    if (type && !value && value !== false) {
      setValues((prev: any) => {
        const updatedObject = { ...prev }
        delete updatedObject[type]
        return { ...updatedObject }
      })
    } else {
      switch (type) {
        case '':
        case undefined:
        case null:
          console.error(`EditField handleData error: Must provide a valid option`)
          return
        case 'deleted_options':
          return setValues((prev: any) => ({ ...prev, deleted_options: [...value] }))
        case 'options':
          return setValues((prev: any) => ({ ...prev, options: [...value] }))
        default:
          return setValues((prev: any) => ({ ...prev, [type]: value }))
      }
    }
  }

  useEffect(() => setValues(undefined), [selectedItem.id])

  // Any change to values checks if required values are present and enables/disables the submit button
  useEffect(() => {
    validateInputs(selectedItem, values, setIsDisabled, setDisabledMessage, errors)
  }, [errors, selectedItem, values])

  return (
    <>
      {selectedItem ? (
        <Box className={css.editField} key={selectedItem.id} sx={{ mb: 1.5 }}>
          <Typography sx={{ mb: 2, mt: 1.5, textAlign: 'center' }} variant="h2">
            {selectedItem.name}
          </Typography>

          <Typography sx={{ mb: 4, textAlign: 'center' }} variant="h3">
            <Box component="span" sx={{ position: 'relative' }}>
              <>Edit Field</>

              {!hasFullEditAccess && (
                <Tooltip arrow placement="right" title="User permissions limit editing of some fields.">
                  <InfoIcon color="primary" fontSize="small" sx={{ position: 'absolute', ml: 0.5 }} />
                </Tooltip>
              )}
            </Box>
          </Typography>

          <Box sx={{ mb: 2.5 }}>
            <NameInput currentValue={selectedItem.name || ''} handleData={handleData} newValue={values?.name} />

            <FieldSemanticsInput handleData={handleData} value={values?.field_semantics || selectedItem.field_semantics || ''} />

            <Group name={selectedItem.group || ''} />

            {!hasCCIExtractionConfigurationAccess && isInternalSource && (
              <Box sx={{ mt: 1.5 }}>
                <NeutralTagRow />
              </Box>
            )}
          </Box>

          {hasCCIExtractionConfigurationAccess && isInternalSource && <InternalExtractionConfigurationAccordion />}

          {hasCCIExtractionConfigurationAccess && isExternalSource && <ExternalExtractionConfigurationAccordion handleData={handleData} />}

          <FieldConfigurationAccordion handleData={handleData} values={values} />

          <Box className={css.modalButtonRow}>
            <WithTooltip content={disabledMessage}>
              <Button
                disableElevation
                disabled={isDisabled}
                onClick={() =>
                  openModal({ content: { dataPointId: selectedItem.id, dataPointName: selectedItem.name, values }, modalOption: ModalOptions.REVIEW_EDITS })
                }
                variant="contained"
              >
                Review changes
              </Button>
            </WithTooltip>
          </Box>
        </Box>
      ) : (
        <EmptyStateMessage message="Select an item from the left panel." />
      )}
    </>
  )
}

const Group: FC<_GroupProps> = ({ name }) => (
  <Box sx={{ alignItems: 'center', display: 'flex', height: 38, mb: 1 }}>
    <Typography sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
      Group
    </Typography>

    <Typography sx={{ py: 1 }} variant="body2">
      {name}
    </Typography>
  </Box>
)
