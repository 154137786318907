import { Box, ToggleButton, Tooltip } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import React, { FC, useCallback } from 'react'

// types
type _AccountImpactFilterProps = {
  accountingImpactDataPointCount: number
  expandAll: () => void
  hasAccountingImpactFields: boolean
  isDisabled: boolean
  setValue: (value: boolean) => void
  value: boolean
}

// components

export const AccountingImpactFilter: FC<_AccountImpactFilterProps> = ({
  accountingImpactDataPointCount,
  expandAll,
  hasAccountingImpactFields,
  isDisabled,
  setValue,
  value
}) => {
  const handleChange = useCallback(() => {
    expandAll()

    setValue(!value)
  }, [expandAll, setValue, value])

  if (!hasAccountingImpactFields) return null

  return (
    <ToggleButton
      aria-label="Accounting impact fields"
      disabled={isDisabled}
      onChange={handleChange}
      selected={value}
      size="small"
      sx={{ pl: 0.25, pr: 0.75 }}
      value={value}
    >
      <Tooltip arrow placement="top" title="Accounting impact fields">
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <AttachMoneyIcon color="warning" sx={{ height: 18, mr: -0.33 }} />

          <>{accountingImpactDataPointCount}</>
        </Box>
      </Tooltip>
    </ToggleButton>
  )
}
