// types

export type _HLTW = { height: number; left: number; top: number; width: number }

// functions

export const getBoundingRect = (clientRects: _HLTW[], padding = 0): _HLTW => {
  const rects = Array.from(clientRects).map(rect => {
    const { height, left, top, width } = rect

    const x0 = left
    const x1 = left + width

    const y0 = top
    const y1 = top + height

    return { x0, x1, y0, y1 }
  })

  const { x0, x1, y0, y1 } = rects.reduce(
    (res, rect) => ({ x0: Math.min(res.x0, rect.x0), x1: Math.max(res.x1, rect.x1), y0: Math.min(res.y0, rect.y0), y1: Math.max(res.y1, rect.y1) }),
    rects[0]
  )

  return { height: y1 - y0 + padding * 2, left: x0 - padding, top: y0 - padding, width: x1 - x0 + padding * 2 }
}
