import { RiPushpin2Line } from 'react-icons/ri'
import { formatTimeAgo } from '../../utils/datetimeUtils'
import CommentContainer from '../../containers/CommentContainer'
import React from 'react'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'
import useCurrentUser from '../../hooks/useCurrentUser'
import type { DataPoint } from '../../graphql/codegen/schemas'

// types

type _PinnedCommentsProps = {
  dataPoint: DataPoint
  dealIsFinalized: boolean
  isNestedChildDataPoint?: boolean
}

// components

export default function PinnedComments({ dataPoint, dealIsFinalized, isNestedChildDataPoint }: _PinnedCommentsProps) {
  const currentUser = useCurrentUser()
  let sortedFilteredComments: any = [] // @ts-ignore
  if (dataPoint?.comments?.length > 0) {
    // @ts-ignore
    sortedFilteredComments = dataPoint?.comments
      .filter(item => item?.is_pinned)
      .sort((a, b) => new Date(a?.pinned_at).valueOf() - new Date(b?.pinned_at).valueOf())
  }
  const pinnedComments = sortedFilteredComments.map((item: any) => {
    const isMine = currentUser?.id === item?.created_by?.id
    const pinnedBy = isMine ? 'You' : item?.pinned_by?.first_name || undefined
    const timeAgoMessage = `${formatTimeAgo(item?.pinned_at)} ago`
    const contentMessage = pinnedBy ? `Pinned by ${pinnedBy} ${timeAgoMessage}` : `Pinned ${timeAgoMessage}`

    return (
      <CommentContainer
        comment={item}
        dataPoint={dataPoint}
        dealIsFinalized={dealIsFinalized}
        isNestedChildDataPoint={isNestedChildDataPoint}
        key={item?.id}
        renderTopRow={(author: string, timeAgo: string) => (
          <div style={{ display: 'flex' }}>
            <WithTooltip content={contentMessage}>
              <div className={css.icon}>
                <RiPushpin2Line />
              </div>
            </WithTooltip>
            <span className={css.commentTopRow}>{`${author} commented ${timeAgo} ago`}</span>
          </div>
        )}
      />
    )
  })

  return <>{pinnedComments?.length > 0 && pinnedComments}</>
}
