import { API_ROOT } from '../../utils/apiUtils'
import { Box } from '@mui/system'
import { Button, InputLabel } from '@mui/material'
import { getIdToken } from '../../utils/sessionApiUtils'
import React, { useEffect, useState } from 'react'
import SelectInput from '../../components/SelectInput'
import WithTooltip from '../../components/WithTooltip'
import axios from 'axios'

// types

type _Customer = { id: string; name: string }

type _Option = { label: string; value: string }

// components

/**
 * NOTE: This 'page' is sometimes rendered outside the context of the app, so it cannot access Apollo state.
 *
 * All user-facing language should refer to a "customer" as a "tenant". The term "customer" should only be used
 * internally to refer to the customer object (until it is later renamed on the backend in the future).
 */
export default function Admin() {
  const [selectedCustomer, setSelectedCustomer] = useState<_Customer | null>(null)
  const [options, setOptions] = useState<_Option[] | null>(null)

  useEffect(() => {
    const fetchUsers = async () => {
      const idToken = await getIdToken()
      const res = await axios.get(`${API_ROOT}/user/customers`, { headers: { Authorization: `Bearer: ${idToken}` } })
      const { customers } = res.data

      setOptions(customers.map(({ id, name }: _Customer) => ({ label: name, value: id })))
    }

    fetchUsers()
  }, [])

  const handleChange = ({ label, value }: _Option) => setSelectedCustomer({ id: value, name: label })

  const handleSubmit = () => {
    if (!selectedCustomer) throw new Error('No tenant selected')

    localStorage.setItem('customerId', selectedCustomer.id)
    localStorage.setItem('customerName', selectedCustomer.name)

    window.location.href = '/' // Handle this outside of react-router context so that requests are re-submitted
  }

  return options ? (
    <Box sx={{ m: 'auto', maxWidth: 600, width: '100%' }}>
      <Box sx={{ mb: 1, width: '100%' }}>
        <InputLabel htmlFor="select-input" sx={{ color: 'inherit', fontWeight: 600 }}>
          Select Tenant
        </InputLabel>
      </Box>

      <SelectInput id="select-input" loading={!options} onChange={handleChange} options={options} selected={selectedCustomer} />

      <WithTooltip content={!selectedCustomer ? 'Select a tenant' : ''}>
        <Box sx={{ mt: 2, width: '100%' }}>
          <Button disabled={!selectedCustomer} fullWidth onClick={handleSubmit} variant="contained">
            Go to Dashboard
          </Button>
        </Box>
      </WithTooltip>
    </Box>
  ) : null
}
