import { KLARITY_BLUE } from '../../utils/styleUtils'
import { common, deepPurple, orange } from '@mui/material/colors'

export const PALETTE = {
  info: {
    main: KLARITY_BLUE
  },
  orange: {
    contrastText: common.white,
    light: orange[600],
    main: orange[700],
    dark: orange[800]
  },
  primary: {
    main: KLARITY_BLUE
  },
  secondary: {
    main: deepPurple.A200
  },
  tertiary: {
    main: '#000'
  },
  text: {
    primary: '#000'
  }
}
