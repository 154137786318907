import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'
import { ExportToCsv } from 'export-to-csv'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import { useLazySubmitSemanticSearchQuery } from '../../../app/restApi'
import KlarityCard from '../../../components/Card'
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import useCurrentUser from '../../../hooks/useCurrentUser'

// types

type FormValues = {
  counterparty: string[]
  documentType: string[]
  query: string
  sortBy: SortOptions
}

type MultiSelectFilterProps = {
  disabled?: boolean
  fieldProps: { [key: string]: unknown }
  id: string
  items: { [key: string]: number }
  label: string
  onChange: (event: SelectChangeEvent) => void
}

type ParsedQueryResponse = {
  counterpartyDict: { [key: string]: number }
  documentTypeDict: { [key: string]: number }
  results: {
    counterparty: string
    created_at: string
    document_id: string
    document_name: string
    document_type: string
    low_confidence: boolean
    match_score: number
    match_text: string
  }[]
}

type QueryResponse = {
  match_score: number
  match_text: string
  meta_data: {
    counterparty: string
    created_at: string
    document_id: string
    document_name: string
    document_type: string
    low_confidence: boolean
  }
}[]

enum SortOptions {
  CREATED_AT = 'created_at',
  DOCUMENT_NAME = 'document_name',
  RELEVANCE = 'relevance'
}

// constants

const PINNED_SEARCH_BAR_OFFSET = 137 // Height of the search bar (105px) + padding above search results (32px)

const SORT_OPTION_LABELS: Record<SortOptions, string> = {
  [SortOptions.RELEVANCE]: 'Relevance',
  [SortOptions.CREATED_AT]: 'Recently uploaded',
  [SortOptions.DOCUMENT_NAME]: 'Document name'
}

// functions

const parseQueryResponse = (searchResults: QueryResponse | undefined): ParsedQueryResponse => {
  if (!searchResults) return { counterpartyDict: {}, documentTypeDict: {}, results: [] }

  const counterpartyDict: ParsedQueryResponse['counterpartyDict'] = {}
  const documentTypeDict: ParsedQueryResponse['documentTypeDict'] = {}
  const results: ParsedQueryResponse['results'] = []

  searchResults.forEach(result => {
    const {
      match_score,
      match_text,
      meta_data: { counterparty, created_at, document_id, document_name, document_type, low_confidence }
    } = result

    counterpartyDict[counterparty] = (counterpartyDict[counterparty] ?? 0) + 1

    documentTypeDict[document_type] = (documentTypeDict[document_type] ?? 0) + 1

    results.push({ counterparty, created_at, document_id, document_name, document_type, low_confidence, match_score, match_text })
  })

  return { counterpartyDict, documentTypeDict, results }
}

// components

const MultiSelectFilter = ({ disabled, fieldProps, id, items, label, onChange }: MultiSelectFilterProps) => (
  <FormControl fullWidth sx={{ mb: 3 }}>
    <InputLabel id={`${id}-label`} shrink>
      {label}
    </InputLabel>

    <Select
      {...fieldProps}
      MenuProps={{ sx: { maxWidth: '341px' } }} // Prevents the Menu from expanding beyond the width of the Select
      disabled={disabled}
      displayEmpty // Ensures "All" is displayed when no value is selected
      label={label}
      labelId={`${id}-label`}
      multiple
      onChange={onChange}
      renderValue={(selected: unknown) => (isEmpty(selected as string[]) ? 'All' : (selected as string[]).join(', '))} // Only display an item's label, not its count
      size="small"
      sx={{ '& legend': { maxWidth: 'none' } }} // Fixes bug where Select outline intersects with shrunken InputLabel if displayEmpty
    >
      <MenuItem value="">All</MenuItem>

      {!isEmpty(items) && <Divider />}

      {Object.entries(items)
        .sort((a, b) => a[0].localeCompare(b[0]) || b[1] - a[1]) // Sort alphabetically by label, then by count if labels match
        .map(([item, count]) => (
          <MenuItem key={item} sx={{ display: 'flex', justifyContent: 'space-between' }} value={item}>
            <Typography sx={{ pr: 4, whiteSpace: 'pre-wrap' }}>{item}</Typography>

            <Badge badgeContent={count} />
          </MenuItem>
        ))}
    </Select>
  </FormControl>
)

export const SemanticSearchTab = () => {
  const formik = useFormik<FormValues>({
    initialValues: { counterparty: [], documentType: [], query: '', sortBy: SortOptions.RELEVANCE },

    onSubmit: ({ query }) => submitQuery({ query })
  })

  const { getFieldProps, handleChange, handleSubmit, setValues, values } = formik

  const [submitQuery, { data: queryResponse, isFetching, isUninitialized }] = useLazySubmitSemanticSearchQuery()

  const { counterpartyDict, documentTypeDict, results } = useMemo(() => parseQueryResponse(queryResponse), [queryResponse])

  const currentUser = useCurrentUser()

  const customerName = currentUser?.customers?.edges[0]?.node?.name

  // state

  const [filteredCounterparties, setFilteredCounterparties] = useState(counterpartyDict)
  const [filteredDocumentTypes, setFilteredDocumentTypes] = useState(documentTypeDict)
  const [filteredResults, setFilteredResults] = useState(results)
  const [isSearchBarPinned, setIsSearchBarPinned] = useState(false)
  const [sortedAndFilteredResults, setSortedAndFilteredResults] = useState(filteredResults)

  // refs

  const resultsRef = useRef<HTMLDivElement>(null)
  const searchBoxRef = useRef<HTMLDivElement>(null)

  // functions

  const exportResults = () => {
    const options = {
      filename: `Search Results${customerName && ` - ${customerName}${values.query && ` - ${values.query}`}`}`,
      useKeysAsHeaders: true
    }

    const csvExporter = new ExportToCsv(options)

    const transformedResults = sortedAndFilteredResults.map((result, index) => {
      const { counterparty, created_at, document_id, document_name, document_type, match_text } = result

      return {
        result_number: index + 1,
        match_text,
        document_name,
        document_url: `${window.location.origin}/documents/${document_id}`,
        document_type,
        counterparty,
        upload_date: new Intl.DateTimeFormat(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(created_at))
      }
    })

    csvExporter.generateCsv(transformedResults)
  }

  // effects

  useEffect(() => {
    setValues({ ...values, counterparty: [], documentType: [] }) // Reset filters on new query

    if (isEmpty(queryResponse)) searchBoxRef.current?.querySelector('input')?.focus() // Refocus on search input if no results found
  }, [queryResponse]) // eslint-disable-line react-hooks/exhaustive-deps

  // Apply selected filters
  useEffect(() => {
    const counterpartyFilters = values.counterparty
    const documentTypeFilters = values.documentType

    const _filteredResults = results.filter(result => {
      const counterpartyMatch = isEmpty(counterpartyFilters) || counterpartyFilters.includes(result.counterparty)
      const documentTypeMatch = isEmpty(documentTypeFilters) || documentTypeFilters.includes(result.document_type)

      return counterpartyMatch && documentTypeMatch
    })

    const _filteredCounterparties = results
      .filter(result => isEmpty(documentTypeFilters) || documentTypeFilters.includes(result.document_type))
      .reduce<{ [key: string]: number }>((counterpartiesDict, result) => {
        counterpartiesDict[result.counterparty] = (counterpartiesDict[result.counterparty] ?? 0) + 1

        return counterpartiesDict
      }, {})

    const _filteredDocumentTypes = results
      .filter(result => isEmpty(counterpartyFilters) || counterpartyFilters.includes(result.counterparty))
      .reduce<{ [key: string]: number }>((documentTypesDict, result) => {
        documentTypesDict[result.document_type] = (documentTypesDict[result.document_type] ?? 0) + 1

        return documentTypesDict
      }, {})

    setFilteredResults(_filteredResults)
    setFilteredCounterparties(_filteredCounterparties)
    setFilteredDocumentTypes(_filteredDocumentTypes)
  }, [counterpartyDict, documentTypeDict, results, values.counterparty, values.documentType])

  // Sort filtered results
  useEffect(() => {
    let newResults = [...filteredResults]

    switch (values.sortBy) {
      case SortOptions.RELEVANCE:
        newResults = newResults.sort(
          (a, b) => (a.low_confidence ? 1 : 0) - (b.low_confidence ? 1 : 0) || b.match_score - a.match_score || a.document_name.localeCompare(b.document_name)
        )
        break

      case SortOptions.CREATED_AT:
        newResults = newResults.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime() ||
            (a.low_confidence ? 1 : 0) - (b.low_confidence ? 1 : 0) ||
            b.match_score - a.match_score
        )
        break

      case SortOptions.DOCUMENT_NAME:
        newResults = newResults.sort(
          (a, b) => a.document_name.localeCompare(b.document_name) || (a.low_confidence ? 1 : 0) - (b.low_confidence ? 1 : 0) || b.match_score - a.match_score
        )
        break
    }

    setSortedAndFilteredResults(newResults)
  }, [filteredResults, values.sortBy])

  // Scroll to the top of the results when running a new query or changing the sort/filter options (only necessary when the search bar is pinned)
  useLayoutEffect(() => {
    if (isSearchBarPinned && resultsRef.current) {
      // Add 1px to account for negative `top` value on the search bar
      window.scrollTo({ top: resultsRef.current.getBoundingClientRect().top + window.scrollY - PINNED_SEARCH_BAR_OFFSET + 1 })
    }
  }, [isSearchBarPinned, queryResponse, values.counterparty, values.documentType, values.sortBy])

  useIntersectionObserver(
    searchBoxRef,
    () => setIsSearchBarPinned(true),
    () => setIsSearchBarPinned(false),
    { threshold: 1 }
  )

  // render

  return (
    <KlarityCard>
      <FormControl autoComplete="off" component="form" fullWidth onSubmit={handleSubmit}>
        {/* Search Bar – Negative `top` required for sticky positioning with IntersectionObserver – https://stackoverflow.com/a/57991537/9027907 */}
        <Box ref={searchBoxRef} sx={{ background: 'white', borderBottom: '1px solid #eeeeee', position: 'sticky', py: 4, top: '-1px', zIndex: 1 }}>
          <Container sx={{ display: 'flex' }}>
            <TextField autoFocus disabled={isFetching} fullWidth label="Search" size="small" type="search" variant="outlined" {...getFieldProps('query')} />

            <Button disableElevation disabled={isFetching} sx={{ ml: 2 }} type="submit" variant="contained">
              Search
            </Button>
          </Container>
        </Box>

        <Box sx={{ background: '#fcfcfc', display: isUninitialized ? 'none' : 'block', py: 4 }}>
          <Container>
            <Grid columnSpacing={8} container>
              {/* Sidebar */}
              <Grid item xs={4}>
                <Box sx={{ position: 'sticky', top: `${PINNED_SEARCH_BAR_OFFSET}px` }}>
                  <Box sx={{ alignItems: 'baseline', display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                    <Typography sx={{ opacity: !sortedAndFilteredResults ? 0 : 1 }} variant="subtitle2">
                      {isFetching ? (
                        'Loading…'
                      ) : (
                        <>
                          Showing{' '}
                          <Typography component="span" sx={{ fontWeight: 600 }} variant="subtitle2">
                            {sortedAndFilteredResults.length}
                          </Typography>{' '}
                          result{sortedAndFilteredResults.length === 1 ? '' : 's'}
                        </>
                      )}
                    </Typography>

                    <Button
                      onClick={exportResults}
                      size="small"
                      sx={{ visibility: isFetching || isEmpty(sortedAndFilteredResults) ? 'hidden' : 'initial' }}
                      variant="text"
                    >
                      Export as CSV
                    </Button>
                  </Box>

                  <FormControl fullWidth>
                    <InputLabel id="sortBy-label">Sort by</InputLabel>

                    <Select {...getFieldProps('sortBy')} disabled={isFetching} label="Sort by" labelId="sortBy-label" onChange={handleChange} size="small">
                      {Object.entries(SORT_OPTION_LABELS).map(([value, label]) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography color="text.secondary" sx={{ mb: 2, mt: 4 }} variant="subtitle2">
                      Filter by
                    </Typography>

                    {(!isEmpty(values.documentType) || !isEmpty(values.counterparty)) && (
                      <Button
                        onClick={() => setValues({ ...values, counterparty: [], documentType: [] })}
                        size="small"
                        sx={{ alignSelf: 'self-end', mb: '11px' }}
                        variant="text"
                      >
                        Clear filters
                      </Button>
                    )}
                  </Box>

                  <MultiSelectFilter
                    disabled={isFetching}
                    fieldProps={{ ...getFieldProps('documentType') }}
                    id="documentType"
                    items={filteredDocumentTypes}
                    label="Document type"
                    onChange={event => (event.target.value.includes('') ? setValues({ ...values, documentType: [] }) : handleChange(event))}
                  />

                  <MultiSelectFilter
                    disabled={isFetching}
                    fieldProps={{ ...getFieldProps('counterparty') }}
                    id="counterparty"
                    items={filteredCounterparties}
                    label="Counterparty"
                    onChange={event => (event.target.value.includes('') ? setValues({ ...values, counterparty: [] }) : handleChange(event))}
                  />
                </Box>
              </Grid>

              {/* Results */}
              <Grid item xs={8}>
                <Box ref={resultsRef} sx={{ height: '100%', minHeight: 'calc(100vh - 334px)' }}>
                  {!isFetching && isEmpty(sortedAndFilteredResults) ? (
                    <Typography sx={{ pt: 9, textAlign: 'center' }}>No results found</Typography>
                  ) : (
                    sortedAndFilteredResults.map(({ counterparty, created_at, document_id, document_name, document_type, match_text }, index) => (
                      <Card key={index} sx={{ mb: 3, opacity: isFetching ? 0.25 : 1, pointerEvents: isFetching ? 'none' : 'auto' }}>
                        <CardContent>
                          <Link to={`/documents/${document_id}`}>
                            <Typography sx={{ color: 'primary.main', display: 'block', fontWeight: 500, mb: 1, '&:hover': { color: '#004fdb' } }}>
                              {document_name}
                            </Typography>
                          </Link>

                          <Typography color="text.secondary" sx={{ lineHeight: 1 }} variant="overline">
                            {document_type} &nbsp;&nbsp;•&nbsp;&nbsp;
                            {counterparty} &nbsp;&nbsp;•&nbsp;&nbsp;
                            {new Intl.DateTimeFormat(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(created_at))}
                          </Typography>

                          <Typography color="text.primary" sx={{ mt: 1 }}>
                            … {match_text} …
                          </Typography>
                        </CardContent>
                      </Card>
                    ))
                  )}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </FormControl>
    </KlarityCard>
  )
}
