import { CciUsersDocument, useCreateUserMutation } from '../../../../../../graphql/codegen/hooks'
import { RoleInput } from './RoleInput'
import { Toast } from '../../../../../../components/Toast'
import { useCciMainContext } from '../../../../CCI_Main'
import { validateEmail } from '../../../../../../utils/stringUtils'
import Button from '../../../../../../components/Button'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import TextInput from '../CCI_Inputs/TextInput'
import WithTooltip from '../../../../../../components/WithTooltip'
import css from './style.module.scss'

// types

type _CreateUserProps = { roleOptions?: any }

// functions

const formatRoleIds = (values: any) => {
  if (!values?.role_ids?.length) {
    return undefined
  }

  return (
    values?.role_ids?.map((role: any) => {
      return role?.value
    }) || undefined
  )
}

const validateInputs = (values?: any, setIsDisabled?: any, setDisabledMessage?: any) => {
  const errors: any[] = []

  if (!values?.user_primary_email) {
    errors.push(`Email`)
  } else if (!validateEmail(values?.user_primary_email)) {
    errors.push(`Email is invalid`)
  }
  if (!values?.first_name) {
    errors.push(`First Name`)
  }
  if (!values?.last_name) {
    errors.push(`Last Name`)
  }
  if (!values?.role_ids?.length || values?.roles?.length < 1) {
    errors.push(`At least one role is required`)
  }

  if (errors.length > 0) {
    setIsDisabled(true)
    setDisabledMessage(
      <>
        {`Required Values are missing:`}
        {errors.map((error?: string) => {
          return <div key={error}>{error}</div>
        })}
      </>
    )
  } else {
    setIsDisabled(false)
    setDisabledMessage(undefined)
  }
}

// components

export const CreateUser: FC<_CreateUserProps> = ({ roleOptions }) => {
  const { activeComponent, setIsModalOpen, setModalError, setModalLoading, setModalLoadingMessage, tab } = useCciMainContext()
  const [disabledMessage, setDisabledMessage] = useState<any>(undefined)
  const [isDisabled, setIsDisabled] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [values, setValues] = useState<any>(undefined)

  const resetRoleInput = useRef<() => void>(() => {}) // Necessary to reset RoleInput's internal state from outside the component.

  const [createUserMutation, { error: createUserError, loading: isCreateUserLoading }] = useCreateUserMutation({
    onCompleted: () => {
      setToastMessage('User successfully created.')
      setIsModalOpen(false)
      setValues(undefined)

      resetRoleInput.current?.()
    },
    refetchQueries: [{ query: CciUsersDocument }]
  })

  const createNewUser = () => {
    const localValues = { ...values }
    const role_ids = formatRoleIds(values)
    delete localValues.role_ids

    setIsModalOpen(true)

    createUserMutation({ variables: { role_ids, ...localValues } })
  }

  const handleData = (type: string, value: any) => {
    if (type && !value && value !== false) {
      setValues((prev: any) => {
        const updatedObject = { ...prev }
        delete updatedObject[type]
        return { ...updatedObject }
      })
    } else {
      switch (type) {
        case '':
        case undefined:
        case null:
          console.error(`CreateUser handleData error: Must provide a valid option`)
          return
        case 'role_ids':
          return setValues((prev: any) => ({ ...prev, role_ids: [...value] }))
        default:
          return setValues((prev: any) => ({ ...prev, [type]: value }))
      }
    }
  }

  const setRoleInputResetFunction = useCallback((resetFunction: () => void) => {
    resetRoleInput.current = resetFunction
  }, [])

  useEffect(() => {
    setValues(undefined)
  }, [activeComponent, tab])

  useEffect(() => {
    validateInputs(values, setIsDisabled, setDisabledMessage)
  }, [values])

  useEffect(() => {
    if (isCreateUserLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Creating User…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
  }, [isCreateUserLoading, setModalLoading, setModalLoadingMessage])

  useEffect(() => {
    if (createUserError) setModalError(createUserError)
  }, [createUserError, setModalError])

  return (
    <>
      <div>
        <h3 style={{ textAlign: 'center', marginBottom: '24px' }}>Create User</h3>

        <TextInput
          displayName="Email:"
          handleData={handleData}
          placeholder="Enter user's email"
          value={values?.user_primary_email}
          variableName="user_primary_email"
        />

        <TextInput
          displayName="First Name:"
          handleData={handleData}
          placeholder="Enter user's first name"
          value={values?.first_name}
          variableName="first_name"
        />

        <TextInput displayName="Last Name:" handleData={handleData} placeholder="Enter user's last name" value={values?.last_name} variableName="last_name" />

        <RoleInput handleData={handleData} options={roleOptions} setResetFunction={setRoleInputResetFunction} />
      </div>

      <div className={css.modalButtonRow}>
        <WithTooltip content={disabledMessage}>
          <Button disabled={isDisabled} onClick={createNewUser}>
            Create New User
          </Button>
        </WithTooltip>
      </div>

      <Toast message={toastMessage} setMessage={setToastMessage} />
    </>
  )
}
