import { AiAnalyticsButton, AiAnalyticsDialog } from '../AiAnalytics'
import { Box } from '@mui/material'
import { DealsTab } from '../../pages/Dashboard/DealsTab'
import { DocumentsTab } from '../../pages/Dashboard/DocumentsTab'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { NavLink, Route, Switch, useLocation } from 'react-router-dom'
import { useOpening } from '@hoologic/use-opening'
import React, { FC } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type _Tab = { component: FC; path: string; title: string }

type _TabsProps = { hasDealAccess: boolean; tabs: _Tab[] }

// components

export const Tabs: FC<_TabsProps> = ({ hasDealAccess, tabs }) => {
  const { pathname } = useLocation()
  const aiAnalyticsOpening = useOpening()
  const hasAiAnalyticsChatAccess = useUserAccess({ feature: Features.ANALYTICS_CHAT, permission: Permissions.READ })

  return (
    <main className={css.main} id="main">
      <h1 className={css.screenReaderOnly}>Dashboard</h1>

      <Box id="tabs" sx={{ alignItems: 'start', display: 'flex', justifyContent: 'space-between', pl: 2, pr: hasAiAnalyticsChatAccess ? 3 : 2 }}>
        <ul className={css.tabRow}>
          {tabs.map(({ path, title }) => (
            <li key={path}>
              <NavLink
                activeClassName={css.active}
                className={clsx({
                  // Manually adding active styling for the proper tab link when at /downloads or /preview
                  [css.active]: (pathname.includes('downloads') || pathname.includes('preview')) && title === (hasDealAccess ? 'Deals' : 'Documents')
                })}
                exact
                to={path}
              >
                {title}
              </NavLink>
            </li>
          ))}
        </ul>

        {hasAiAnalyticsChatAccess && <AiAnalyticsButton opening={aiAnalyticsOpening} />}
      </Box>

      <div className={css.tabBody} id="tab-body">
        <Switch>
          {tabs.map(({ component, path }) => (
            <Route component={component} exact key={path} path={path} />
          ))}
          <Route component={hasDealAccess ? DealsTab : DocumentsTab} path="/downloads" />;
          <Route component={hasDealAccess ? DealsTab : DocumentsTab} path="/preview" />;
        </Switch>
      </div>

      {hasAiAnalyticsChatAccess && <AiAnalyticsDialog opening={aiAnalyticsOpening} />}
    </main>
  )
}
