import { InlineQueryMenu, QueryNames, QuerySubNames } from '../../InlineQueryMenu'
import { useAddDocumentsToDealMutation, useCounterPartyDealsLazyQuery } from '../../../graphql/codegen/hooks'
import Button from '../../Button'
import RadioButton from '../../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/SharedInputs/RadioButton/RadioButton'
import React, { useEffect, useState } from 'react'
import css from './style.module.scss'

export default function AddDocToDeal({
  closeModal,
  counterpartyId,
  documentId,
  loading,
  parentComponent,
  setLoading,
  setLoadingMessage,
  setSelectedDeal
}: {
  closeModal: any
  counterpartyId?: any
  documentId?: string
  loading: boolean
  parentComponent?: string
  setLoading: any
  setLoadingMessage: any
  setSelectedDeal?: any
}) {
  const [isSearchingAllCustomers, setIsSearchingAllCustomers] = useState(false)
  const [queryVars, setQueryVars] = useState<any>('')
  const [mutationVars, setMutationVars] = useState<any>('')
  const [isMutationSuccessful, setIsMutationSuccessful] = useState(false)

  const [getCounterPartyDeals, { data: counterPartyDeals, loading: counterPartyDealsLoading }] = useCounterPartyDealsLazyQuery({
    fetchPolicy: 'no-cache' // Need to always get fresh search results from backend. Query is manually triggered by user, limited to 20 results max, and returns minor amount of data.
  })
  const [addDocumentsToDealMutation, { loading: addDocumentLoading }] = useAddDocumentsToDealMutation({
    onCompleted: () => {
      setLoadingMessage('')
      setIsMutationSuccessful(true)
    }
  })

  useEffect(() => {
    if (counterpartyId) {
      setMutationVars({ required: { documentIds: [documentId] }, optional: {} })
      setQueryVars({
        required: {},
        optional: {
          counterpartyId: isSearchingAllCustomers ? undefined : counterpartyId,
          filters: parentComponent === 'ConvertDocumentLevel' ? undefined : JSON.stringify({ exclude_deals_by_document_ids: [documentId] })
        }
      })
      getCounterPartyDeals({
        variables: {
          counterpartyId: isSearchingAllCustomers ? undefined : counterpartyId,
          filters: parentComponent === 'ConvertDocumentLevel' ? undefined : JSON.stringify({ exclude_deals_by_document_ids: [documentId] }),
          size: 20
        }
      })
    }
    // eslint-disable-next-line
    }, [isSearchingAllCustomers, counterpartyId])

  useEffect(() => {
    let localLoading = false
    if (addDocumentLoading) {
      localLoading = true
    }
    setLoading(localLoading)
    // eslint-disable-next-line
    }, [addDocumentLoading, setLoading])

  const handleClose = () => {
    if (!loading) {
      setQueryVars('')
      setMutationVars('')
      setIsMutationSuccessful(false)
      closeModal()
    }
  }

  const handleAdd = (vars: any) => {
    if (parentComponent === 'ConvertDocumentLevel') {
      setSelectedDeal(vars)
    } else {
      setLoadingMessage('Adding Document…')
      delete vars.dealName
      addDocumentsToDealMutation(vars)
    }
  }

  return (
    <>
      {isMutationSuccessful ? (
        <p>Successfully added document.</p>
      ) : (
        queryVars && (
          <>
            {parentComponent !== 'ConvertDocumentLevel' && (
              <div className={css.radioRow}>
                <p>Include deals from other customers?</p>

                <div>
                  <RadioButton
                    handleChange={() => setIsSearchingAllCustomers(true)}
                    id="isSearchingAllCustomers_true"
                    isSelected={isSearchingAllCustomers}
                    label="Yes"
                    name="isSearchingAllCustomers"
                    style={{ marginRight: '16px' }}
                    value={true}
                  />

                  <RadioButton
                    handleChange={() => setIsSearchingAllCustomers(false)}
                    id="isSearchingAllCustomers_false"
                    isSelected={!isSearchingAllCustomers}
                    label="No"
                    name="isSearchingAllCustomers"
                    value={false}
                  />
                </div>
              </div>
            )}

            <InlineQueryMenu
              handleChange={handleAdd}
              isAlwaysOpen
              isLoading={counterPartyDealsLoading}
              key={isSearchingAllCustomers ? 'isSearchingAllCustomers' : counterpartyId} // Re-render when switching between all customers and a specific customer.
              mutationVars={mutationVars}
              queryData={counterPartyDeals}
              queryFunction={getCounterPartyDeals}
              queryItemName="documents"
              queryName={QueryNames.DEALS_BY_COUNTERPARTY}
              queryNoResultsMessage="No deals found. Deals that the document already belongs to are not shown in this list."
              queryPlaceholder="Input a name and press Enter to search"
              querySubName={QuerySubNames.ADD_DOCUMENT_TO_DEAL}
              queryVars={queryVars}
            />
          </>
        )
      )}

      {!parentComponent && (
        <div className={css.modalButtonRow}>
          <Button onClick={handleClose} variant="secondary">
            {isMutationSuccessful ? 'Close' : 'Cancel'}
          </Button>
        </div>
      )}
    </>
  )
}
