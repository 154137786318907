import * as queryString from 'query-string'
import { AttachmentSearchContainer } from '../../../containers/AttachmentSearchContainer'
import { BulkExport } from '../../../components/ModalOptions/BulkExport'
import { BulkExportWithPdfs } from '../../../components/ModalOptions/BulkExport/BulkExportWithPdfs'
import { Features, Permissions, useUserAccess } from '../../../hooks/useUserAccess'
import { QueryTypes } from '../../../hooks/usePrevQueryType'
import { UploadModal } from '../../../components/UploadModal'
import { compareArrayOfStrings } from '../../../utils/stringUtils'
import { decodeQuery, encodeQuery } from '../../../utils/dashboardQueryUtils'
import { useAdvancedAttachmentsDashboardFieldsQuery } from '../../../graphql/codegen/hooks'
import AttachmentList from '../../../components/AttachmentList'
import Card from '../../../components/Card'
import DocumentUploadModal from '../../../components/DocumentUploadModal'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import css from './style.module.scss'
import dashboardQueryReducer, {
  getSavedColumns,
  initialState,
  parseTemporaryColumnsFromQuery,
  resetToDefaultColumns
} from '../../../reducers/dashboardQueryReducer'
import useDashboardQString from '../../../hooks/useDashboardQString'
import useGetExcludedFields from '../../../hooks/useGetExcludedFields'
import usePrevQuery from '../../../hooks/usePrevQuery'

// components

export default function AttachmentsTab() {
  const [{ selectedColumns }, dispatch] = useReducer(dashboardQueryReducer, initialState)
  const { data } = useAdvancedAttachmentsDashboardFieldsQuery()
  const hasAutoUploadAccess = useUserAccess({ feature: Features.AUTOMATED_DOCUMENT, permission: Permissions.CREATE })
  const hasManualUploadAccess = useUserAccess({ feature: Features.MANUAL_DOCUMENT, permission: Permissions.CREATE })
  const hasBulkExportAccess = useUserAccess({ feature: Features.CUSTOMER_DATA, permission: Permissions.EXPORT })
  const hasBulkExportWithPdfsAccess = useUserAccess({ feature: Features.CUSTOMER_DATA_WITH_PDFS, permission: Permissions.EXPORT })
  const fieldsToExclude = useGetExcludedFields('Attachments', 'FieldSelector')
  const { queryColumns } = useDashboardQString()
  const prevQuery = usePrevQuery()
  const formattedQueryColumns = typeof queryColumns === 'string' ? queryColumns.split(',') : queryColumns

  const [totalCount, setTotalCount] = useState(0)
  const [filteredCount, setFilteredCount] = useState(0)
  const [clearAll, setClearAll] = useState(false)

  const setDefaultFields = useCallback(() => {
    resetToDefaultColumns(data, fieldsToExclude, dispatch)
  }, [data, fieldsToExclude])

  useEffect(() => {
    const temporaryColumns = parseTemporaryColumnsFromQuery(data, formattedQueryColumns, fieldsToExclude)
    const savedColumns = getSavedColumns()

    if (temporaryColumns) {
      dispatch({ type: 'SAVE_TEMPORARY_COLUMNS', selectedColumns: temporaryColumns })
    } else if (savedColumns) {
      dispatch({ type: 'SAVE_SELECTED_COLUMNS', selectedColumns: savedColumns })
    } else {
      setDefaultFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, JSON.stringify(queryColumns)])

  useEffect(() => {
    if (window.location.search) {
      const customerId = localStorage.getItem('customerId')

      localStorage.setItem(`${customerId}__${QueryTypes.PREVIOUS_ATTACHMENT_QUERY}`, window.location.search)
    }
    // eslint-disable-next-line
  }, [window.location.search]);

  useEffect(() => {
    if (prevQuery && data) {
      // this is to check that tempColumns arnt still loading
      const tempColumns = parseTemporaryColumnsFromQuery(data, formattedQueryColumns, fieldsToExclude)?.map(column => column.value)
      const newColumns = selectedColumns?.map(column => column.value)

      // check for differences between prev and current (COLUMNS ONLY- adding/removing/updating rules is handled by hitting the search button)
      const arraysAreEqual = compareArrayOfStrings(tempColumns || [], newColumns || [])

      if (!arraysAreEqual) {
        const customerId = localStorage.getItem('customerId')

        // update the query with the new columns/order
        const prevQueryRules = prevQuery?.q ? decodeQuery(prevQuery?.q as string) : ''
        const cols = selectedColumns?.map(column => column.value)
        const updatedPrevQuery = queryString.stringify({ cols, q: encodeQuery(prevQueryRules) }, { arrayFormat: 'comma' })

        localStorage.setItem(`${customerId}__${QueryTypes.PREVIOUS_ATTACHMENT_QUERY}`, updatedPrevQuery)
      }
    }
    // eslint-disable-next-line
  }, [data, selectedColumns])

  return (
    <>
      <Card>
        <div className={css.tableHeader}>
          <AttachmentSearchContainer
            clearAll={clearAll}
            filteredCount={filteredCount}
            selectedColumns={selectedColumns}
            setClearAll={setClearAll}
            totalCount={totalCount}
          />
          <div className={css.modalButtonWrapper}>
            {hasBulkExportWithPdfsAccess ? (
              <BulkExportWithPdfs dashboard_type="ATTACHMENT" selectedColumns={selectedColumns} />
            ) : hasBulkExportAccess ? (
              <BulkExport dashboard_type="ATTACHMENT" selectedColumns={selectedColumns} />
            ) : null}

            {hasManualUploadAccess ? <UploadModal /> : hasAutoUploadAccess ? <DocumentUploadModal /> : null}
          </div>
        </div>

        <AttachmentList dispatch={dispatch} setClearAll={setClearAll} setFilteredCount={setFilteredCount} setTotalCount={setTotalCount} />
      </Card>
    </>
  )
}
