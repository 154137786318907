import { captureError } from './sentry'

export const deleteCaches = async () => {
  try {
    const cacheNames = await caches.keys()

    await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)))
  } catch (error) {
    captureError(error, 'Error deleting caches')
  }
}
