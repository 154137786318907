// enums

export enum ExtractionStatuses {
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  NOT_APPLICABLE = 'N/A',
  PROCESSING = 'PROCESSING'
}

export enum ExtractionStatusLabels {
  COMPLETE = 'Complete',
  FAILED = 'Failed',
  NOT_APPLICABLE = '',
  PROCESSING = 'Processing'
}
