import { CellView } from '../../components/dashboard/CellView'
import { Link } from 'react-router-dom'
import { Table } from '../../components/Table'
import Card from '../../components/Card'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import useAssignedToMe from '../../hooks/useAssignedToMe'
import useTablePagination from '../../hooks/useTablePagination'

// constants

const ANNOTATION_ASSIGNEE = 'Annotation Assignee'
const ANNOTATION_STATUS = 'Annotation Status'
const CREATED_AT = 'Created At'
const NAME = 'Name'

// components

export const InboxTab: FC = () => {
  const [rowCount, setRowCount] = useState(10)
  const { offset, pageLength, ...paginationControls } = useTablePagination({ total: rowCount })
  const [sortConfig, setSortConfig] = useState<any[]>([{ sort_created_at: 1 }])
  const { dashboardData, documentCountData, loading } = useAssignedToMe({ sortConfig, pageLength: pageLength || 10, offset })
  const total = documentCountData?.fetch_advanced_dashboard_count?.total_number_of_filtered_documents

  useEffect(() => setRowCount(total || 0), [total])

  const tableData = useMemo(() => {
    if (!dashboardData) return []
    else {
      return dashboardData?.fetch_advanced_dashboard?.dashboard_data
    }
  }, [dashboardData])

  const getSortDirection = useCallback(
    (key: string) => {
      return sortConfig.find((a: any) => a[key])?.[key] || 0
    },
    [sortConfig]
  )

  const columns = useMemo(() => {
    const updateSortConfig = (key: string) => {
      const sortDirection = getSortDirection(key)
      setSortConfig([{ [key]: sortDirection === 1 ? -1 : ((sortDirection + 1) as -1 | 0 | 1) }])
    }

    return [
      {
        Header: NAME,
        accessor: 'document',
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value: { value } }: any) => {
          if (!value) return null

          const { extraction_status, id } = row.original

          if (extraction_status?.value === 'PROCESSING') {
            return (
              <CellView header={NAME} textValue={value}>
                <>{value}</>

                <span style={{ backgroundColor: 'rgba(71, 172, 255, 0.24)', borderRadius: 8, color: '#5a5b5e', fontSize: 12, marginLeft: 8, padding: '0 6px' }}>
                  Extraction in progress
                </span>
              </CellView>
            )
          }

          return (
            <CellView header={NAME} textValue={value}>
              <Link to={`/documents/${id}?from=inbox`}>{value}</Link>
            </CellView>
          )
        },
        width: 400,
        sortFunction: () => updateSortConfig('sort_document_name'),
        sortDirection: getSortDirection('sort_document_name')
      },
      {
        Header: ANNOTATION_STATUS,
        accessor: 'internal_state',
        Cell: ({ value: { value } }: any) => <CellView header={ANNOTATION_STATUS} textValue={value} />
      },
      {
        Header: ANNOTATION_ASSIGNEE,
        accessor: 'internal_assignee',
        Cell: ({ value: { value } }: any) => {
          const textValue: string = value ? value.user_name || 'Error: missing user_name' : 'Unassigned'

          return (
            <CellView header={ANNOTATION_ASSIGNEE} textValue={textValue}>
              {textValue}
            </CellView>
          )
        }
      },
      {
        Header: CREATED_AT,
        accessor: 'created_at',
        Cell: ({ value: { value } }: any) => <CellView header={CREATED_AT} textValue={value} />,
        sortFunction: () => updateSortConfig('sort_created_at'),
        sortDirection: getSortDirection('sort_created_at')
      }
    ]
  }, [getSortDirection])

  return (
    <Card>
      <Table {...paginationControls} columns={columns} isLoading={loading} pageLength={pageLength} rowCount={rowCount} tableData={tableData} variant="inbox" />
    </Card>
  )
}
