import { CustomerEdge } from '../graphql/codegen/schemas'
import { TENANT_ZUORA_PROD, TENANT_ZUORA_UAT } from '../utils/tenantUtils'
import useCurrentUser from './useCurrentUser'

// hooks

export const useIsZuoraTenant = () => {
  const currentUser = useCurrentUser()

  if (!currentUser) return false

  return (currentUser.customers?.edges as CustomerEdge[]).some(edge => [TENANT_ZUORA_PROD, TENANT_ZUORA_UAT].includes(edge.node?.id as string))
}
