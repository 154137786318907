import { captureError } from './sentry'
import axiosClient from '../utils/axiosClient'
import type { DocumentFormat } from '../components/DocumentPanel/DocumentPanel'

// constants

const DOCUMENTS_CACHE = 'documents-cache'

// functions

export const getDocument = async (documentId: string, type: DocumentFormat = 'ORIGINAL_PDF') => {
  const cacheKey = `${documentId}-${type}`

  try {
    const cacheStorage = await caches.open(DOCUMENTS_CACHE)
    const cachedResponse = await cacheStorage.match(cacheKey)

    if (cachedResponse) return cachedResponse.json()
  } catch (error) {
    captureError(error, 'Error getting document from cache')
  }

  const response = (await axiosClient.get(`/document/${documentId}?type=${type}`)).data.data

  try {
    const cacheStorage = await caches.open(DOCUMENTS_CACHE)

    await cacheStorage.put(cacheKey, new Response(JSON.stringify(response), { status: 200, statusText: 'OK', headers: { 'Content-Type': 'application/json' } }))
  } catch (error) {
    captureError(error, 'Error caching document')
  }

  return response
}

export const downloadDocument = async (documentId: string, documentName: string) => {
  const {
    data: { data }
  } = await axiosClient.get(`/document/${documentId}?type=ORIGINAL_PDF`)

  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = data
  // documentName is alias if present- passed into toolbar as alias || name
  a.download = `${documentName.split('.')[0]}.pdf` // TODO: return name w/ response. this is built on the assumption that all document names end with .docx

  document.body.appendChild(a).click()
  document.body.removeChild(a)
}

export const saveDocument = async (documentId: string, version_name?: string | null) => {
  try {
    // @ts-ignore
    await window.editor.saveDocument()
  } catch (error) {
    captureError(error)
  }

  return await axiosClient.post(`/document/${documentId}`, { version_name })
}
