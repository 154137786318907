import { CellView } from '../dashboard/CellView'
import { DocumentQueryAction } from '../../reducers/dashboardQueryReducer'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { MoreMenu, _MenuItem } from '../MoreMenu'
import { Table } from '../Table'
import { formatDate } from '../../utils/datetimeUtils'
import { generateDashboardColumns } from '../../utils/dashboardColumnUtils'
import { useAdvancedAttachmentsDashboardLazyQuery, useAttachmentCountLazyQuery } from '../../graphql/codegen/hooks'
import { useHistory } from 'react-router-dom'
import { useIsKlarityEmployee } from '../../hooks/useCurrentUser'
import { useModalContext } from '../../app'
import AttachedTo from '../ModalOptions/AttachedTo'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import Modal from '../Modal'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import RenameFile from '../RenameFile'
import WithTooltip from '../WithTooltip'
import axiosClient from '../../utils/axiosClient'
import css from './style.module.scss'
import useDashboardQString from '../../hooks/useDashboardQString'
import useTablePagination from '../../hooks/useTablePagination'

const PREVIEWABLE_EXT_REGEX = /^.*\.(jpg|jpeg|gif|png|pdf)$/

export default function AttachmentList({
  dispatch,
  setClearAll,
  setFilteredCount,
  setTotalCount
}: {
  dispatch: Dispatch<DocumentQueryAction>
  setClearAll: Dispatch<SetStateAction<boolean>>
  setFilteredCount: Dispatch<SetStateAction<number>>
  setTotalCount: Dispatch<SetStateAction<number>>
}) {
  const isKlarityEmployee = useIsKlarityEmployee()
  const history = useHistory()
  const [rowCount, setRowCount] = useState(10)
  const { offset, pageLength, ...paginationControls } = useTablePagination({ total: rowCount })
  const { gqlFilters, queryBuilderQuery, sortConfig } = useDashboardQString()
  const hasDownloadAccess = useUserAccess({ feature: Features.ATTACHMENT, permission: Permissions.DOWNLOAD })

  const [advancedDashboardLazyQuery, { data: dataAdvanced, loading: loadingAdvanced }] = useAdvancedAttachmentsDashboardLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
    // these fetch policies force the first query of the page to be from the network, then to prefer the cache afterwards.
    // this is needed in order to have the search re-fire when the search button is pressed.
  })
  const [attachmentCountLazyQuery, { data: dataCount, loading: loadingCount }] = useAttachmentCountLazyQuery({
    variables: { ...gqlFilters },
    fetchPolicy: 'network-only'
  })
  const loading = loadingAdvanced || loadingCount

  useEffect(() => {
    if (pageLength) {
      advancedDashboardLazyQuery({ variables: { ...gqlFilters, size: pageLength, offset } })
    }
    // eslint-disable-next-line
  }, [pageLength, gqlFilters, offset])

  useEffect(() => {
    // ONLY recount if the search changed!
    attachmentCountLazyQuery({ variables: gqlFilters })
  }, [gqlFilters, attachmentCountLazyQuery])

  useEffect(() => {
    // ONLY recount if the search changed!
    if (dataCount && dataCount.fetch_advanced_attachments_dashboard_count) {
      setFilteredCount(dataCount.fetch_advanced_attachments_dashboard_count.total_number_of_filtered_documents!)
      setTotalCount(dataCount.fetch_advanced_attachments_dashboard_count.total_number_of_documents!)
    } else {
      setFilteredCount(0)
      setTotalCount(0)
    }
  }, [dataCount, setFilteredCount, setTotalCount])

  const totalFiltered = dataCount?.fetch_advanced_attachments_dashboard_count?.total_number_of_filtered_documents
  useEffect(() => {
    setRowCount(totalFiltered || 0)
  }, [totalFiltered])

  const tableData = useMemo(() => {
    if (!dataAdvanced) return []

    const dashboardData = dataAdvanced.fetch_advanced_attachments_dashboard?.dashboard_data
    const normalized = dashboardData?.map((row: any) => {
      return { ...row, created_at: row.created_at, counterPartyId: row?.counter_party?.value && JSON.parse(row.counter_party.value)?.id }
    })
    return normalized // this is an object with keys that map to the 'accessor' passed to columns below
  }, [dataAdvanced])

  // #region Modal state/functions
  const { openPreview } = useModalContext()
  const [modalLoading, setModalLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [modalOption, setModalOption] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState<{
    attachmentAlias?: string
    attachmentId: string
    attachmentName: string
    counterpartyId?: string
    documentTypeId?: string
    isAccountLevel?: boolean
  }>()

  const openModal = (menuOption: string, attachmentId: string, attachmentName: string, attachmentAlias?: string, counterpartyId?: string) => {
    setIsOpen(true)
    setModalOption(menuOption)
    setModalTitle(menuOption)
    setModalContent({ attachmentId, attachmentName, attachmentAlias, counterpartyId })
  }

  const closeModal = () => {
    if (!modalLoading) {
      setIsOpen(false)
      setModalOption('')
      setModalTitle('')
      setModalContent(undefined)
      setModalLoading(true)
      setLoadingMessage('')
    }
  }
  // #endregion

  const handleDownload = useCallback(async ({ id, name }: any) => {
    const { data } = await axiosClient.get(`attachments/${id}`, { responseType: 'blob' })
    const href = window.URL.createObjectURL(data)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = href
    a.download = name
    document.body.appendChild(a)
    a.click()
  }, [])

  const getMenuItemList = useCallback(
    ({ attachmentAlias, attachmentId, attachmentName, counterPartyId }): _MenuItem[] => {
      // For now, only files that can render inside an <img/> tag are previewable
      const canPreview = attachmentName?.match(PREVIEWABLE_EXT_REGEX) || attachmentAlias?.match(PREVIEWABLE_EXT_REGEX)

      return [
        ...(canPreview ? [{ label: 'Preview', onClick: () => openPreview(attachmentId, false, attachmentAlias || attachmentName) }] : []),
        { label: 'View Attached To', onClick: () => openModal('Attached To', attachmentId, attachmentName, attachmentAlias, counterPartyId) },
        ...(hasDownloadAccess ? [{ label: 'Download', onClick: () => handleDownload({ id: attachmentId, name: attachmentAlias || attachmentName }) }] : []),
        { label: 'Rename Attachment', onClick: () => openModal('Rename Attachment', attachmentId, attachmentName, attachmentAlias, counterPartyId) }
      ]
    },
    [handleDownload, hasDownloadAccess, openPreview]
  )

  // these columns will always be shown and cannot be reordered.
  const staticColumns = [
    ...generateDashboardColumns(
      history,
      queryBuilderQuery,
      sortConfig,
      [],
      [
        {
          headerName: 'Attachment',
          accessor: 'document',
          sortStringName: 'document_name',
          Cell: ({ row, value }: any) => {
            if (!value) return null
            const attachmentId = value?.id
            const attachmentName: string = value?.value_original || value?.value || ''
            const attachmentAlias: string = value?.value_alias || ''
            const counterPartyId = row?.values?.counter_party?.value && JSON.parse(row?.values?.counter_party?.value)?.id

            const textValue = attachmentAlias || attachmentName
            const title = attachmentAlias && `Original Name: ${attachmentName}`

            return (
              <CellView
                header="Attachment"
                menu={<MoreMenu menuItemList={getMenuItemList({ attachmentId, attachmentName, attachmentAlias, counterPartyId })} />}
                textValue={textValue}
                title={title}
              >
                {attachmentName?.match(PREVIEWABLE_EXT_REGEX) || attachmentAlias?.match(PREVIEWABLE_EXT_REGEX) ? (
                  <a onClick={() => openPreview(attachmentId, false, textValue)} style={{ cursor: 'pointer' }}>
                    {textValue}
                  </a>
                ) : hasDownloadAccess ? (
                  <a onClick={() => handleDownload({ id: attachmentId, name: textValue })} style={{ cursor: 'pointer' }}>
                    {textValue}
                  </a>
                ) : (
                  <>{textValue}</>
                )}
              </CellView>
            )
          }
        },
        {
          headerName: 'Customer',
          accessor: 'counter_party',
          sortStringName: 'counter_party_name',
          Cell: ({ value }: any) => {
            const parsedCounterParty = value?.value && JSON.parse(value.value)
            const textValue: string = parsedCounterParty?.name && parsedCounterParty.name !== 'UNASSIGNED' ? parsedCounterParty.name : ''

            return <CellView header="Customer" textValue={textValue} />
          }
        },
        {
          headerName: 'Integration Type',
          accessor: 'integration_type',
          Cell: ({ row, value }: { row: any; value: any }) => {
            if (!value || !value?.value || !row) {
              return null
            } else {
              const userName = row?.original?.created_by?.value?.user_name
              const uploadDate = row?.original?.created_at ? formatDate(row?.original?.created_at?.value) : undefined
              const uploadedBy = userName?.includes('klaritylaw.com') ? (isKlarityEmployee ? userName : 'Klarity') : userName
              const tooltipContent = (
                <>
                  {value?.value === 'MANUAL' && uploadedBy && <div>{`Uploaded by: ${uploadedBy}`}</div>}
                  {uploadDate && <div>{`Upload Date: ${uploadDate}`}</div>}
                </>
              )
              return (
                <WithTooltip content={tooltipContent}>
                  <CellView header="Integration Type" textValue={value?.value || ''} />
                </WithTooltip>
              )
            }
          }
        },
        {
          headerName: 'Upload Date',
          accessor: 'created_at',
          Cell: ({ value }: { value: any }) => <CellView header="Upload Date" textValue={value?.value || ''} />
        }
      ]
    )
  ]

  return (
    <>
      <Table
        {...paginationControls}
        columns={[...staticColumns]}
        dispatch={dispatch}
        dummyDataActive={loading || !dataCount}
        isLoading={loading || !dataCount}
        pageLength={pageLength}
        rowCount={rowCount}
        setClearAll={setClearAll}
        tableData={tableData}
      />

      <Modal isOpen={isOpen} onRequestClose={closeModal} title={modalTitle}>
        <ComponentLoadingOverlay loading={modalLoading} message={loadingMessage} />
        <div className={css.modal}>
          {modalOption === 'Attached To' && (
            <AttachedTo
              attachmentDocId={modalContent?.attachmentId}
              closeModal={closeModal}
              fileName={modalContent?.attachmentAlias || modalContent?.attachmentName}
              setModalLoading={setModalLoading}
            />
          )}
          {modalOption === 'Rename Attachment' && (
            <RenameFile
              attachmentId={modalContent?.attachmentId}
              closeModal={closeModal}
              fileAlias={modalContent?.attachmentAlias}
              fileName={modalContent?.attachmentName}
              fileType={'Attachment'}
              loading={modalLoading}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}
        </div>
      </Modal>
    </>
  )
}
