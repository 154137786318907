import { ActiveComponents, useCciMainContext } from '../../../../CCI_Main'
import { KLARITY_BLUE } from '../../../../../../utils/styleUtils'
import React, { FC, useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import css from './style.module.scss'

// types

type RoleInputProps = { currentValues?: any; handleData: any; options?: any; setResetFunction?: (resetFunction: () => void) => void }

// components

export const RoleInput: FC<RoleInputProps> = ({ currentValues, handleData, options, setResetFunction }) => {
  const { activeComponent, selectedItem, tab } = useCciMainContext()
  const ref = useRef<HTMLElement>()
  const [isFocused, setFocused] = useState(false)
  const [values, setValues] = useState(currentValues?.length > 0 ? [...currentValues] : [])

  const handleChange = (values?: any) => {
    if (activeComponent === ActiveComponents.CREATE_USER) {
      handleData('role_ids', values?.length > 0 ? [...values] : null)
    } else if (activeComponent === ActiveComponents.EDIT_USER) {
      // this is filtering the currently selected options in the MSDD (values) to show only items that are not inside the original array of values (currentValues)
      // the currentValues?.filter function is filtering current values by the current index of the values array, if it finds a match it will add that match to the addedOptions array
      const addedOptions =
        values?.filter((value: any) => {
          return (
            currentValues?.filter((cv: any) => {
              return cv?.value === value?.value
            })?.length === 0
          )
        }) || []
      const removedOptions =
        values === null
          ? // if values is null but currentValues has length then all options have been removed
            currentValues?.length > 0
            ? [...currentValues]
            : null
          : // filter logic is the same addedOptions, but the arrays are flipped to look for things that are in current values but not in values
            currentValues?.filter((cv: any) => {
              return (
                values?.filter((value: any) => {
                  return value?.value === cv?.value
                })?.length === 0
              )
            }) || []

      handleData('roles_to_add', addedOptions?.length > 0 ? [...addedOptions] : null)
      handleData('roles_to_remove', removedOptions?.length > 0 ? [...removedOptions] : null)
    }
    setValues(values?.length > 0 ? [...values] : [])
  }

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus()
    }
  }, [isFocused])

  // reset values on tab/component/selectedItem change
  useEffect(() => {
    setValues(currentValues?.length > 0 ? [...currentValues] : [])
    // eslint-disable-next-line
    }, [selectedItem, tab, activeComponent])

  useEffect(() => {
    setResetFunction?.(() => setValues([]))
  }, [setResetFunction])

  return (
    <>
      <div className={css.inputWrapper}>
        <h4>{`Roles:`}</h4>
        {/* @ts-ignore  */}
        <Select
          isMulti
          menuPlacement="auto"
          menuPortalTarget={document.body}
          onBlur={() => setFocused(false)}
          onChange={(values: any) => {
            handleChange(values)
          }}
          onFocus={() => setFocused(true)}
          openMenuOnFocus
          options={options}
          placeholder={`Select user's Roles`}
          // @ts-ignore
          ref={ref}
          styles={{
            control: provided => ({ ...provided, fontSize: 14, borderRadius: 4, borderColor: '#bdbec2', boxShadow: 'none' }),
            option: provided => ({ ...provided, fontSize: 14 }),
            container: provided => ({ ...provided, padding: 0 }),
            multiValue: provided => ({ ...provided, maxWidth: '300px' }),
            placeholder: provided => ({ ...provided, color: '#e0e0e0' }),
            dropdownIndicator: () => ({ display: 'none' }),
            indicatorSeparator: () => ({ display: 'none' }),
            clearIndicator: (provided, { isFocused }) => ({ ...provided, visibility: isFocused ? 'visible' : 'hidden' }),
            menuPortal: provided => ({ ...provided, zIndex: 999999999 })
          }}
          theme={theme => ({ ...theme, borderRadius: 2, colors: { ...theme.colors, primary: KLARITY_BLUE } })}
          value={values}
        />
      </div>
    </>
  )
}
