import { TypographyOptions } from '@mui/material/styles/createTypography'

// constants

export const TYPOGRAPHY: TypographyOptions = {
  fontFamily: ['Open Sans', 'Helvetica Neue', 'sans-serif'].join(','),
  h1: { fontSize: 24, fontWeight: 700 },
  h2: { fontSize: 20, fontWeight: 700 },
  h3: { fontSize: 18, fontWeight: 700 },
  h4: { fontSize: 16, fontWeight: 600 },
  h5: { fontSize: 16 }
}
