import * as queryString from 'query-string'
import { AiOutlineFileSearch } from 'react-icons/ai'
import { Box, IconButton } from '@mui/material'
import { isEmpty } from 'lodash'
import { useAppContext } from '../../app'
import { useHistory, useLocation } from 'react-router-dom'
import { useIsFeedbackPage } from '../../pages/FeedbackPage'
import React, { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react'
import SaveProgressIcon from './SaveProgressIcon'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'

// types

type _Annotation = { documentId: string; id: string }
type _JumpToAnnotationIconProps = { annotations: _Annotation[]; dataPointId?: string }

// components

export const JumpToAnnotationIcon: FC<_JumpToAnnotationIconProps> = ({ annotations, dataPointId }) => {
  const { isAnnotationAdded, isAnnotationDeleted, setErrorMessage } = useAppContext()
  const history = useHistory()
  const isFeedbackPage = useIsFeedbackPage()
  const location = useLocation()
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)

  // annotationId-related

  const [annotationId, setAnnotationId] = useState('')

  useMemo(() => {
    const annotationId = location.hash.slice('#highlight-'.length)

    if (annotationId) {
      setAnnotationId(annotationId)
    }
  }, [location.hash])

  // nextAnnotationIndex-related

  const [nextAnnotationIndex, setNextAnnotationIndex] = useState(0)

  useMemo(() => {
    const annotationIndex = annotations.findIndex(item => item.id === annotationId)

    if (annotationIndex !== -1) {
      setNextAnnotationIndex(annotationIndex === annotations.length - 1 ? 0 : annotationIndex + 1)
    } else if (isAnnotationAdded) {
      // `annotationId` is an empty string until the user views at least one annotation, and it's never an empty string thereafter, meaning `nextAnnotationIndex` will remain at `0` until the initial annotation viewing.

      setNextAnnotationIndex(current => current || (annotationId ? annotations.length - 1 : 0))
    } else if (isAnnotationDeleted) {
      setNextAnnotationIndex(current => (current ? current - 1 : 0))
    }
  }, [annotationId, annotations, isAnnotationAdded, isAnnotationDeleted])

  // other

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation()

      if (!isEmpty(annotations)) {
        if (!isFeedbackPage) {
          const documentTab = location.pathname.includes('deals') ? annotations[nextAnnotationIndex]?.documentId : ''

          const search = queryString.stringify({
            ...(documentTab ? { documentTab } : {}),
            ...(dataPointId ? { dataPoint: dataPointId } : {})
          })

          history.push({ search })
        }

        document.location.hash = `#highlight-${annotations[nextAnnotationIndex].id}`
      }

      setErrorMessage('')
      setShowLoadingSpinner(true)
      setTimeout(() => setShowLoadingSpinner(false), 1000)
    },
    [annotations, dataPointId, history, isFeedbackPage, location.pathname, nextAnnotationIndex, setErrorMessage]
  )

  const tooltipContent = useMemo(
    () => `Jump to annotation${annotations.length === 1 ? '' : ` ${nextAnnotationIndex + 1}/${annotations.length}`}`,
    [annotations.length, nextAnnotationIndex]
  )

  return (
    <>
      {showLoadingSpinner ? (
        <SaveProgressIcon error={false} loading={showLoadingSpinner} success={false} />
      ) : (
        <WithTooltip content={tooltipContent} style={{ display: 'flex' }}>
          <IconButton
            aria-label={tooltipContent}
            className={css.scrollIcon}
            onClick={handleClick}
            sx={{ borderRadius: 0.5, color: 'primary.main', height: 16, ml: 0.5, p: 0, width: 16 }}
          >
            <AiOutlineFileSearch />

            <Box className={css.badge}>{annotations.length > 99 ? '99+' : annotations.length}</Box>
          </IconButton>
        </WithTooltip>
      )}
    </>
  )
}
