import { Box } from '@mui/material'
import { common } from '@mui/material/colors'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import React, { FC } from 'react'

// components

export const FeedbackCard: FC = ({ children }) => (
  <Box sx={{ display: 'flex', gap: 1 }}>
    <AutoAwesomeIcon color="secondary" sx={{ fontSize: 18, mt: 0.125 }} />

    <Box
      sx={{
        background: common.white,
        borderRadius: 1,
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 2,
        width: '100%'
      }}
    >
      {children}
    </Box>
  </Box>
)
