import { InlineQueryMenu, QueryNames } from '../../InlineQueryMenu'
import { useDealDocumentsLazyQuery } from '../../../graphql/codegen/hooks'
import Button from '../../Button'
import React, { useEffect, useState } from 'react'
import css from './style.module.scss'

export default function ViewDealsDocuments({ closeModal, dealCounterpartyId, dealId }: { closeModal: any; dealCounterpartyId?: any; dealId?: string }) {
  const [queryVars, setQueryVars] = useState<any>('')

  const [getDealDocuments, { data: dealDocuments, loading: dealDocumentsLoading }] = useDealDocumentsLazyQuery({ fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (dealId) {
      setQueryVars({ required: { dealId }, optional: {} })
      getDealDocuments({ variables: { dealId } })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <InlineQueryMenu
        dealCounterpartyId={dealCounterpartyId}
        isAlwaysOpen
        isLoading={dealDocumentsLoading}
        queryData={dealDocuments}
        queryFunction={getDealDocuments}
        queryItemName="documents"
        queryName={QueryNames.DOCUMENTS_BY_DEAL}
        queryPlaceholder="Input a name and press Enter to search"
        queryVars={queryVars}
      />

      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant="secondary">
          Close
        </Button>
      </div>
    </>
  )
}
