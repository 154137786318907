import React, { FC, KeyboardEvent, SyntheticEvent, useEffect, useRef } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type _FreeTextProps = {
  ariaLabel?: string
  isDisabled?: boolean
  isError?: boolean
  isFocused?: boolean
  onChange?: (e: SyntheticEvent<HTMLTextAreaElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  setFocused?: (arg: boolean) => void
  value: string
}

// components

const FreeText: FC<_FreeTextProps> = ({ ariaLabel, isDisabled, isError, isFocused, onChange, onKeyDown, placeholder, setFocused, value }) => {
  const ref = useRef<HTMLTextAreaElement>()

  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.focus()
    }
  }, [isFocused])

  return (
    <TextareaAutosize
      aria-label={ariaLabel || placeholder || 'Enter text'}
      className={clsx(css.input, isDisabled && css.disabled)}
      disabled={isDisabled}
      onBlur={() => (setFocused ? setFocused(false) : null)}
      onChange={onChange}
      onFocus={() => (setFocused ? setFocused(true) : null)}
      onKeyDown={onKeyDown}
      onKeyPress={(e: KeyboardEvent<HTMLTextAreaElement>) => {
        // Allow newlines if pressing shift
        if (!e.shiftKey && e.key === 'Enter') {
          e.preventDefault()
        }
      }}
      placeholder={placeholder}
      ref={(r: HTMLTextAreaElement) => (ref.current = r)}
      style={{ fontFamily: 'Open Sans', lineHeight: '1.4', resize: 'none', border: `${isError ? '1px solid red' : ''}` }}
      type="text"
      value={value || ''}
    />
  )
}

export default FreeText
