import { FiXSquare } from 'react-icons/fi'
import { Typography } from '@mui/material'
import Button from '../../../../../../../components/Button'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import React, { FC, KeyboardEvent, SyntheticEvent, useState } from 'react'
import css from './style.module.scss'

// types

type _AdditionalInfoInputProps = { handleData: any; value?: string }

// components

export const AdditionalInfoInput: FC<_AdditionalInfoInputProps> = ({ handleData, value }) => {
  const [isFocused, setFocused] = useState(false)
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (isFocused && e?.key === 'Enter') {
      setFocused(false)
      // @ts-ignore
      e?.target?.blur()
    }
  }

  return (
    <div className={css.inputWrapper}>
      <Typography component="label" htmlFor="description-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        Additional Info
      </Typography>

      <FreeText
        id="description-input"
        isFocused={isFocused}
        onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => handleData('description', event.currentTarget.value)}
        onKeyDown={handleKeyDown}
        placeholder={value || 'Enter additional info about this field'}
        setFocused={setFocused}
        value={value || ''}
      />

      {value && <Button className={css.cciButton} icon={<FiXSquare />} onClick={() => handleData('description', null)} />}
    </div>
  )
}
