import {
  ActionProps,
  ActionWithRulesAndAddersProps,
  ActionWithRulesProps,
  CombinatorSelectorProps,
  FieldSelectorProps,
  OperatorSelectorProps,
  Option,
  ValueEditorProps
} from 'react-querybuilder'
import { DatePicker } from '../DatePicker'
import { FiX } from 'react-icons/fi'
import { OptionTypeBase } from 'react-select'
import { Tooltip } from '@mui/material'
import { Z_INDEX_HEADER } from '../../utils/styleUtils'
import { debounce } from 'lodash'
import { format, isValid, parseISO } from 'date-fns'
import { grey } from '@mui/material/colors'
import Button from '../Button'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react'
import SelectInput from '../SelectInput'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'
import useOnClickOutside from '../../hooks/useOnClickOutside'

// types

type _MultiSelectInputProps = { handleChange: (value: any) => void; options: OptionTypeBase[] | undefined; value: any }

// components

export const AddGroupButton: FC<ActionWithRulesAndAddersProps> = ({ context, handleOnClick, level }) =>
  !context?.isOpen || level > 1 ? null : (
    <>
      <Button noWrap onClick={handleOnClick} style={{ height: 38 }} variant="tertiary">
        + Add {level === 0 ? 'Group' : 'Subgroup'}
      </Button>

      {level === 0 && (
        <Tooltip
          PopperProps={{ sx: { zIndex: Z_INDEX_HEADER } }}
          arrow
          placement="right"
          title={
            <>
              <p>Use rules to structure simple queries. Multiple rules within a simple query must be evaluated using the same AND/OR operator. Examples:</p>

              <p style={{ marginLeft: 8 }}>
                • <strong>A</strong> AND <strong>B</strong> AND <strong>C</strong>
              </p>

              <p style={{ marginBottom: 8, marginLeft: 8 }}>
                • <strong>A</strong> OR <strong>B</strong> OR <strong>C</strong> OR <strong>D</strong>
              </p>

              <p>
                Use groups to structure complex queries. All rules within a group must be evaluated using the same operator. Rules and groups can be combined
                but must also be evaluated using the same operator. Examples:
              </p>

              <p style={{ marginLeft: 8 }}>
                • (<strong>A</strong> AND <strong>B</strong> AND <strong>C</strong>) OR (<strong>D</strong> AND <strong>E</strong>)
              </p>

              <p style={{ marginBottom: 8, marginLeft: 8 }}>
                • (<strong>A</strong> OR <strong>B</strong>) AND (<strong>C</strong> OR <strong>D</strong>) AND <strong>E</strong> AND <strong>F</strong>
              </p>

              <p>Subgroups can be nested within groups for even more complex queries.</p>
            </>
          }
        >
          <HelpOutlineIcon fontSize="small" sx={{ color: grey[500], ml: 1.5, '&:hover': { color: grey[600] } }} />
        </Tooltip>
      )}
    </>
  )

export const AddRuleButton: FC<ActionWithRulesAndAddersProps> = ({ context, handleOnClick }) => (
  <Button noWrap onClick={handleOnClick} style={{ height: 38 }} variant="tertiary">
    {context?.isOpen ? '+ Add Rule' : 'Advanced Search'}
  </Button>
)

export const CombinatorSelector: FC<CombinatorSelectorProps> = ({ handleOnChange, options, value }) => {
  // QueryBuilder defines options with { label, name }, but the SelectInput expects { label, value }.
  const inputOptions = options.map((option: any) => ({ ...option, value: option.name }))
  const inputValue = inputOptions.filter((option: any) => option.name === value)

  return (
    <SelectInput
      onChange={({ name }: { name: string }) => handleOnChange(name)}
      options={options}
      placeholder="Select combinator"
      styles={{ container: { width: 88 }, option: { textTransform: 'uppercase' }, singleValue: { textTransform: 'uppercase' } }}
      value={inputValue}
    />
  )
}

const MultiSelectInput: FC<_MultiSelectInputProps> = ({ handleChange, options, value }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(containerRef, () => setIsMenuOpen(false))

  return (
    <div ref={containerRef}>
      <SelectInput
        aria-label="Select one or more options"
        isMulti
        menuIsOpen={isMenuOpen} // Allow the user to select multiple options without having to re-open the menu each time.
        onChange={handleChange}
        onEscapeKeyDown={() => setIsMenuOpen(false)}
        onMenuOpen={() => setIsMenuOpen(true)}
        options={options}
        placeholder=""
        value={value}
      />
    </div>
  )
}

export const RemoveGroupButton: FC<ActionWithRulesProps> = ({ handleOnClick, level }) => (
  <WithTooltip content={`Remove ${level === 1 ? '' : 'sub'}group`} style={{ marginLeft: 'auto' }}>
    <Button aria-label={`Remove ${level === 1 ? '' : 'sub'}group`} onClick={handleOnClick} style={{ height: 38, marginLeft: 'auto' }} variant="secondary">
      <FiX />
    </Button>
  </WithTooltip>
)

export const RemoveRuleButton: FC<ActionProps> = ({ handleOnClick }) => (
  <WithTooltip content="Remove rule" style={{ marginLeft: 'auto', marginTop: 16 }}>
    <Button aria-label="Remove rule" onClick={handleOnClick} style={{ height: 38, marginLeft: 'auto' }} variant="secondary">
      <FiX />
    </Button>
  </WithTooltip>
)

export const Selector: FC<FieldSelectorProps | OperatorSelectorProps> = ({ handleOnChange, options, title, value }) => {
  const ariaLabel = title === 'Fields' ? 'Select field' : 'Select operator'

  // QueryBuilder defines options with { label, name }, but the SelectInput expects { label, value }.
  const inputOptions = options.sort((a, b) => a.label.localeCompare(b.label)).map(option => ({ ...option, value: (option as Option).name }))
  const inputValue = inputOptions.filter((option: any) => option.name === value)

  return (
    <span aria-label={ariaLabel} className="fieldSelector">
      <label>{title === 'Fields' ? 'Field' : 'Operator'}</label>

      <SelectInput onChange={({ name }: { name: string }) => handleOnChange(name)} options={inputOptions} placeholder={ariaLabel} value={inputValue} />
    </span>
  )
}

/**
 * This component uses an `internalValue` to update the displayed value as the user types in the input field, while `handleOnChange()` is debounced to delay
 * updating the QueryBuilder's `value`. This is necessary because, otherwise, the QueryBuilder's `onQueryChange()` function would be called on each keystroke,
 * causing the QueryBuilder to re-render on each keystroke. As the number of query inputs increases, the QueryBuilder would become progressively unresponsive.
 *
 * The `handleOnChange()` function is debounced using the `useRef()` hook to prevent the debounced function from being re-created on each render.
 * This requires using a known anti-pattern, as described in the React Hooks FAQ; however, debouncing will not work correctly without it.
 * See: https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback.
 */
export const ValueEditor: FC<ValueEditorProps> = ({ field, fieldData, handleOnChange, operator, value }) => {
  const [internalValue, setInternalValue] = useState<any>(value)
  const handleOnChangeRef = useRef(debounce(handleOnChange, 150))

  const handleChange = (newValue: any) => {
    setInternalValue(newValue)
    handleOnChangeRef.current(newValue)
  }

  useEffect(() => {
    handleOnChangeRef.current = debounce(handleOnChange, 150)
  }, [handleOnChange])

  // The QueryBuilder uses `resetOnFieldChange` and `resetOnOperatorChange` to reset the `value` when the field/operator changes.
  // However, such actions will not cause the `internalValue` to be updated, so it must be updated manually.
  useEffect(() => {
    setInternalValue(value)
  }, [field, operator]) // eslint-disable-line react-hooks/exhaustive-deps

  if (fieldData?.filter_type === 'select' && fieldData.data_type !== 'MULTI_SELECT_DROP_DOWN' && operator === '=') {
    return (
      <span aria-label="Select an option" className="fieldSelector">
        <label>Option</label>

        <SelectInput
          onChange={handleChange}
          options={fieldData?.valueOptions?.map((option: string) => ({ isDropDown: true, value: option, label: option }))}
          placeholder=""
          value={internalValue}
        />
      </span>
    )
  }

  if ((fieldData?.data_type === 'DROP_DOWN' || fieldData?.data_type === 'MULTI_SELECT_DROP_DOWN') && ['in', 'notIn'].includes(operator)) {
    return (
      <span aria-label="Select one or more options" className="fieldSelector">
        <label>Options</label>

        <MultiSelectInput
          handleChange={handleChange}
          options={fieldData?.valueOptions?.map((option: string) => ({ isMultiSelectDropDown: true, value: option, label: option }))}
          value={internalValue}
        />
      </span>
    )
  }

  switch (operator) {
    case 'contains':
    case 'doesNotContain':
      return (
        <span aria-label="Enter text" className="fieldSelector">
          <label>Text</label>

          <input
            aria-label="Enter text"
            key={field}
            onChange={event => {
              event.preventDefault()
              handleChange(event.currentTarget.value)
            }}
            type="text"
            value={internalValue}
          />
        </span>
      )

    case 'in':
    case 'notIn':
      return (
        <span aria-label="Select one or more options" className="fieldSelector">
          <label>Options</label>

          <MultiSelectInput
            handleChange={handleChange}
            options={fieldData?.valueOptions?.map((option: OptionTypeBase | string) =>
              typeof option === 'string'
                ? { value: option, label: option }
                : typeof option === 'object'
                ? { ...option, value: option.id, label: 'name' in option ? option.name : option.user_name }
                : undefined
            )}
            value={internalValue}
          />
        </span>
      )

    case '<':
    case '<=':
    case '=':
    case '>=':
    case '>':
    case '!=':
      if (fieldData?.filter_type === 'date') {
        const typedValue = value as string | null // `value` is of type `all`

        return (
          <span aria-label="Enter a date" className="fieldSelector">
            <label>Date</label>

            <DatePicker onChange={date => handleChange(date ? format(date, 'yyyy-MM-dd') : null)} value={typedValue} />
          </span>
        )
      }

      return (
        <span aria-label={fieldData?.filter_type === 'string' ? 'Enter text' : 'Enter a number'} className="fieldSelector">
          <label>{fieldData?.filter_type === 'string' ? 'Text' : 'Number'}</label>

          <input
            aria-label={fieldData?.filter_type === 'string' ? 'Enter text' : 'Enter a number'}
            onChange={(event: SyntheticEvent<HTMLInputElement>) => handleChange(event.currentTarget.value)}
            type={fieldData?.filter_type === 'string' ? 'text' : 'number'}
            value={internalValue}
          />
        </span>
      )

    case 'between':
    case 'notBetween':
      if (fieldData?.filter_type === 'double') {
        const [rangeStart, rangeEnd] = value || ['', '']

        return (
          <div className={css.betweenValues} key="between-num">
            <span aria-label="Enter range start (number)" className="fieldSelector">
              <label>Range Start</label>

              <input
                aria-label="Enter range start (number)"
                onChange={(event: SyntheticEvent<HTMLInputElement>) => handleChange([event.currentTarget.value, rangeEnd])}
                type="number"
                value={rangeStart}
              />
            </span>

            <span>and</span>

            <span aria-label="Enter range end (number)" className="fieldSelector">
              <label>Range End</label>

              <input
                aria-label="Enter range end (number)"
                onChange={(event: SyntheticEvent<HTMLInputElement>) => handleChange([rangeStart, event.currentTarget.value])}
                type="number"
                value={rangeEnd}
              />
            </span>
          </div>
        )
      } else if (fieldData?.filter_type === 'date') {
        const [rangeStart, rangeEnd] = (value || ['', '']) as [string | Date | null, string | Date | null]

        const getStringValue = (value: string | Date | null) => {
          const date = typeof value === 'string' ? parseISO(value) : value

          return date && isValid(date) ? format(date, 'yyyy-MM-dd') : null // `date &&` portion is to satisfy TypeScript
        }

        return (
          <div className={css.betweenValues} key="between-date">
            <span aria-label="Enter start date" className="fieldSelector">
              <label>Start Date</label>

              <DatePicker onChange={dateString => handleChange([getStringValue(dateString), rangeEnd])} value={getStringValue(rangeStart)} />
            </span>

            <span>and</span>

            <span aria-label="Enter end date" className="fieldSelector">
              <label>End Date</label>

              <DatePicker onChange={dateString => handleChange([rangeStart, getStringValue(dateString)])} value={getStringValue(rangeEnd)} />
            </span>
          </div>
        )
      }

      return null

    case 'null':
    case 'notNull':
    default:
      return null
  }
}
