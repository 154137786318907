import { Button, CircularProgress, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { Z_INDEX_OVERLAY } from '../utils/styleUtils'
import { isEmpty } from 'lodash'
import { useOpening } from '@hoologic/use-opening'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import React, { FC, MouseEvent, ReactNode, useCallback } from 'react'

// types

export type _MenuItem = { disabledMessage?: string; isDisabled?: boolean; label: string; onClick: () => void }
type _MoreMenuProps = { icon?: ReactNode; isBusy?: boolean; isDisabled?: boolean; label?: string; menuItemList: _MenuItem[] }

// components

export const MoreMenu: FC<_MoreMenuProps> = ({ icon, isBusy = false, isDisabled = false, label, menuItemList }) => {
  const opening = useOpening()

  const handleButtonClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()

      opening.toggle(event)
    },
    [opening]
  )

  const handleMenuItemClick = useCallback(
    (handleClick: () => void) => {
      handleClick()

      opening.close()
    },
    [opening]
  )

  if (isEmpty(menuItemList.filter(Boolean))) return null

  if (isBusy) return <CircularProgress size={30} sx={{ p: 0.625 }} />

  return (
    <>
      {label ? (
        <Button onClick={handleButtonClick} variant="outlined">
          {label}
        </Button>
      ) : (
        <IconButton
          aria-label="More options"
          disabled={isDisabled}
          onClick={handleButtonClick}
          size="small"
          sx={{
            aspectRatio: '1 !important',
            borderRadius: '50% !important',
            height: 'fit-content',
            ...(opening.isOpen && { opacity: '1 !important', visibility: 'visible !important' })
          }}
        >
          {icon || <MoreVertIcon fontSize="small" />}
        </IconButton>
      )}

      <Menu
        PaperProps={{ sx: { minWidth: 160 } }}
        anchorEl={opening.anchor}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={opening.close}
        open={opening.isOpen}
        sx={{ zIndex: Z_INDEX_OVERLAY }}
      >
        {menuItemList.map(({ disabledMessage = '', isDisabled = false, label, onClick }) => (
          <Tooltip arrow key={label} placement="right" title={isDisabled ? disabledMessage : ''}>
            <span>
              <MenuItem disabled={isDisabled} onClick={() => handleMenuItemClick(onClick)} sx={{ fontSize: 13, py: 1 }}>
                {label}
              </MenuItem>
            </span>
          </Tooltip>
        ))}
      </Menu>
    </>
  )
}
