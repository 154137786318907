import { Features, Permissions, useUserAccess } from '../../../../../../../hooks/useUserAccess'
import { TextField } from '@mui/material'
import React, { FC } from 'react'

// types

type _CreateOrSelectNeutralTagProps = {
  id?: string
  neutralTag?: string
  setNeutralTag: (value: string) => void
}

// components

export const CreateOrSelectNeutralTag: FC<_CreateOrSelectNeutralTagProps> = ({ id, neutralTag, setNeutralTag }) => {
  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  return (
    <TextField
      InputProps={{ autoComplete: 'off', sx: { fontSize: 14 } }}
      disabled={!hasFullEditAccess}
      fullWidth
      id={id}
      onChange={event => setNeutralTag(event.target.value)}
      placeholder="Enter an automation tag"
      size="small"
      type="search"
      value={neutralTag}
    />
  )
}
