import { Box } from '@mui/material'
import { FiXSquare } from 'react-icons/fi'
import { InlineQueryMenu, QueryNames } from '../InlineQueryMenu'
import {
  useCounterPartiesByNameLazyQuery,
  useCounterPartyDealsLazyQuery,
  useDocumentTypesQuery,
  useManualUploadFieldsLazyQuery
} from '../../graphql/codegen/hooks'
import Button from '../Button'
import Dropzone from '../Dropzone'
import React, { useEffect, useState } from 'react'
import SelectInput from '../SelectInput'
import UploadInput from './UploadInput'
import css from './style.module.scss'

export default function ManualUpload(props: any) {
  const { data: documentTypesData, loading: docTypesLoading } = useDocumentTypesQuery()
  const [documentTypes, setDocumentTypes] = useState<any>()
  const [customersLoading, setCustomersLoading] = useState<boolean>(false)
  const [dealsLoading, setDealsLoading] = useState<boolean>(false)
  const [fieldsLoading, setFieldsLoading] = useState<boolean>(false)

  useEffect(() => {
    setDocumentTypes(
      documentTypesData?.document_types?.edges?.map((edge: any) => {
        const { id, name } = edge?.node || {}
        return { label: name, value: id }
      })
    )
  }, [documentTypesData])

  useEffect(() => {
    if (docTypesLoading || customersLoading || dealsLoading || fieldsLoading) {
      props?.setComponentLoading(true)
    } else if (!(docTypesLoading || customersLoading || dealsLoading || fieldsLoading)) {
      props?.setComponentLoading(false)
    }
    // eslint-disable-next-line
  }, [docTypesLoading, customersLoading, dealsLoading, fieldsLoading])

  const handleDealData = (type: string, value: any) => {
    props?.setRequiredValues([])
    props?.setOptionalValues([])
    switch (type) {
      case 'counterparty_name':
        props?.setClearDealValue(true)
        props?.setRequiredValues([])
        props?.setOptionalValues([])
        return props.setDealData((prev: any) => {
          return {
            ...prev,
            counterparty_name: value,
            counterparty_id: undefined,
            document_level: undefined,
            deal_id: undefined,
            deal_name: undefined,
            document_type: undefined
          }
        })
      case 'counterparty_id':
        props?.setClearDealValue(true)
        return props.setDealData((prev: any) => {
          return { ...prev, counterparty_id: value, document_level: undefined, deal_id: undefined, deal_name: undefined, document_type: undefined }
        })
      case 'document_level':
        props?.setClearDealValue(true)
        return props.setDealData((prev: any) => {
          return { ...prev, document_level: value, deal_id: undefined, deal_name: undefined, document_type: undefined }
        })
      case 'deal_name':
        return props.setDealData((prev: any) => {
          return { ...prev, deal_name: value, deal_id: undefined, document_type: undefined }
        })
      case 'deal_id':
        return props.setDealData((prev: any) => {
          return { ...prev, deal_id: value, document_type: undefined }
        })
      case 'document_type':
        return props.setDealData((prev: any) => {
          return { ...prev, document_type: { ...value } }
        })
      default:
        console.error(`ManualUpload handleDealData error: ${type} is not a valid option`)
    }
  }

  return (
    <div className={css.inputWrapper}>
      <div className={css.documentInput}>
        <DocumentInput document={props.document} setDocument={props.setDocument} wipeForm={props.wipeForm} />

        {props?.document?.name && (
          <>
            {!props.hasInitial && (
              <Box sx={{ mt: 2 }}>
                <CustomerInput
                  clearSelectedValue={props?.clearCounterPartyValue}
                  dealData={props?.dealData}
                  handleDealData={handleDealData}
                  hasInitial={props?.hasInitial}
                  setClearSelectedValue={props?.setClearCounterPartyValue}
                  setLoading={setCustomersLoading}
                />

                {(props?.dealData?.counterparty_name || props?.dealData?.counterparty_name) && (
                  <DocumentLevelInput dealData={props?.dealData} handleDealData={handleDealData} />
                )}

                {props?.dealData?.document_level === 'deal_level' && (
                  <DealInput
                    clearSelectedValue={props?.clearDealValue}
                    dealData={props?.dealData}
                    handleDealData={handleDealData}
                    hasInitial={props?.hasInitial}
                    setClearSelectedValue={props?.setClearDealValue}
                    setLoading={setDealsLoading}
                  />
                )}
              </Box>
            )}

            {(props?.dealData?.document_level === 'account_level' ||
              (props?.dealData?.document_level === 'deal_level' && (props?.dealData?.deal_name || props?.dealData?.deal_id))) && (
              <DocumentTypeInput dealData={props?.dealData} docTypesLoading={docTypesLoading} documentTypes={documentTypes} handleDealData={handleDealData} />
            )}

            {props?.dealData?.document_type?.id && (
              <ManualUploadInputs
                dealData={props?.dealData}
                optionalValues={props.optionalValues}
                requiredValues={props.requiredValues}
                setLoading={setFieldsLoading}
                setOptionalValues={props.setOptionalValues}
                setRequiredCount={props?.setRequiredCount}
                setRequiredValues={props.setRequiredValues}
                validationErrors={props?.validationErrors}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

function DocumentInput({ document, setDocument, wipeForm }: any) {
  return (
    <>
      <h4>Document:</h4>

      {!document?.name ? (
        <Dropzone
          accept=".docx,.pdf"
          idleMessage={'Drag and drop a .docx or .pdf document here, or click to select'}
          multiple={false}
          onDrop={(acceptedFiles: File[]) => setDocument(acceptedFiles[0])}
        />
      ) : (
        <div className={css.documentRow}>
          <span>{document.name}</span>

          <Button aria-label="Remove document" icon={<FiXSquare />} onClick={wipeForm} />
        </div>
      )}
    </>
  )
}

function CustomerInput({
  clearSelectedValue,
  dealData,
  handleDealData,
  hasInitial,
  setClearSelectedValue,
  setLoading
}: {
  clearSelectedValue: boolean
  dealData: any
  handleDealData: any
  hasInitial: boolean
  setClearSelectedValue: any
  setLoading: any
}) {
  const [getCounterParties, { data: counterParties, loading: counterPartiesLoading }] = useCounterPartiesByNameLazyQuery({ fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (!hasInitial) {
      getCounterParties()
    }
  }, [hasInitial, getCounterParties])

  useEffect(() => {
    setLoading(counterPartiesLoading)
  }, [setLoading, counterPartiesLoading])

  return (
    <>
      {!hasInitial && (
        <>
          <h4>Customer:</h4>

          <div className={css.menuItemStylingFix}>
            <InlineQueryMenu
              clearSelectedValue={clearSelectedValue}
              existingData={dealData?.counterparty_name}
              handleChange={handleDealData}
              isLoading={counterPartiesLoading}
              queryData={counterParties}
              queryFunction={getCounterParties}
              queryItemName="customers"
              queryName={QueryNames.COUNTERPARTIES_BY_NAME}
              queryPlaceholder="Select an existing Customer or create a new one"
              setClearSelectedValue={setClearSelectedValue}
            />
          </div>
        </>
      )}
    </>
  )
}

function DocumentLevelInput({ dealData, handleDealData }: any) {
  const documentLevelOptions = [
    { label: 'A single deal', value: 'deal_level' },
    { label: `All deals for ${dealData?.counterparty_name}`, value: 'account_level' }
  ]

  return (
    <>
      <h5>Upload Document to:</h5>
      <SelectInput
        menuPlacement="auto"
        menuPortalTarget={document.body}
        onChange={({ value }: { value: string }) => handleDealData('document_level', value)}
        options={documentLevelOptions}
        placeholder="Select an option"
        value={documentLevelOptions?.filter(({ value }: { value: string }) => value === dealData?.document_level)}
      />
    </>
  )
}

function DealInput({
  clearSelectedValue,
  dealData,
  handleDealData,
  hasInitial,
  setClearSelectedValue,
  setLoading
}: {
  clearSelectedValue: boolean
  dealData: any
  handleDealData: any
  hasInitial: boolean
  setClearSelectedValue: any
  setLoading: any
}) {
  const [getCounterPartyDeals, { data: counterPartyDeals, loading: counterPartyDealsLoading }] = useCounterPartyDealsLazyQuery({ fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (!hasInitial && dealData?.counterparty_id) {
      getCounterPartyDeals({ variables: { counterpartyId: dealData?.counterparty_id } })
    }
  }, [hasInitial, dealData?.counterparty_id, getCounterPartyDeals])

  useEffect(() => {
    setLoading(counterPartyDealsLoading)
  }, [setLoading, counterPartyDealsLoading])

  return (
    <>
      <h5>Deal:</h5>

      <InlineQueryMenu
        clearSelectedValue={clearSelectedValue}
        existingData={dealData?.deal_name}
        handleChange={handleDealData}
        isLoading={counterPartyDealsLoading}
        queryData={counterPartyDeals}
        queryFunction={getCounterPartyDeals}
        queryItemName="deals"
        queryName={QueryNames.DEALS_BY_COUNTERPARTY}
        queryPlaceholder={!dealData?.counterparty_id ? 'Create customer’s first deal' : 'Select an existing Deal or create a new one'}
        queryVars={{ required: { counterpartyId: dealData?.counterparty_id } }}
        setClearSelectedValue={setClearSelectedValue}
      />
    </>
  )
}

function DocumentTypeInput({ dealData, docTypesLoading, documentTypes, handleDealData }: any) {
  return (
    <>
      {docTypesLoading ? (
        <h6 style={{ paddingTop: '8px' }}>{'Loading document types…'}</h6>
      ) : (
        <>
          <h5>Document Type:</h5>
          <SelectInput
            menuPlacement="auto"
            menuPortalTarget={document.body}
            onChange={({ value }: { value: string }) => {
              documentTypes?.forEach((item: any) => {
                if (item?.value === value) {
                  handleDealData('document_type', { id: value, name: item?.label })
                }
              })
            }}
            options={documentTypes}
            placeholder="Select document type"
            value={documentTypes?.filter(({ value }: { value: string }) => value === dealData?.document_type?.id)}
          />
        </>
      )}
    </>
  )
}

// @ts-ignore
function ManualUploadInputs({
  dealData,
  optionalValues,
  requiredValues,
  setLoading,
  setOptionalValues,
  setRequiredCount,
  setRequiredValues,
  validationErrors
}: {
  data?: Array<any> | null
  dealData: any
  optionalValues: Array<any>
  requiredValues: Array<any>
  setLoading: any
  setOptionalValues: any
  setRequiredCount: any
  setRequiredValues: any
  validationErrors?: Array<any>
}) {
  const [requiredFields, setRequiredFields] = useState<Array<any>>()
  const [optionalFields, setOptionalFields] = useState<Array<any>>()
  const [data, setData] = useState<any>()

  const [getDocumentFields, { loading: documentFieldsLoading }] = useManualUploadFieldsLazyQuery({
    onCompleted: data => {
      let requiredCount = 0
      data?.manual_upload_fields?.manual_upload_fields?.forEach(item => {
        if (item?.is_optional === false) {
          requiredCount = requiredCount += 1
        }
      })
      setRequiredCount(requiredCount)
      setData(data?.manual_upload_fields?.manual_upload_fields)
    }
  })

  useEffect(() => {
    if (dealData?.document_type?.name) {
      getDocumentFields({ variables: { configType: 'BY_DOCTYPE', documentTypeName: dealData?.document_type?.name } })
    }
  }, [dealData?.document_type?.name, getDocumentFields])

  useEffect(() => {
    setLoading(documentFieldsLoading)
  }, [setLoading, documentFieldsLoading])

  const handleChange = (type: string, id: any, value: any, values?: Array<any>) => {
    if (type === 'required') {
      setRequiredValues((prev: any) => {
        // remove previous value for current id
        const newArray = prev?.filter((item: any) => {
          // Object.prototype.hasOwnProperty.call(item, id) checks to see if the item has a key that is equal to id
          return !Object.prototype.hasOwnProperty.call(item, id)
        })
        const valueObj = values ? { values } : { value }
        // remove item from values arary if new value is null
        if ((values?.length === 0 || values === undefined) && !value) {
          return [...newArray]
        } else {
          // spread all other values and add current value for the id in
          return [...newArray, { [id]: valueObj }]
        }
      })
    } else if (type === 'optional') {
      setOptionalValues((prev: any) => {
        const newArray = prev?.filter((item: any) => {
          return !Object.prototype.hasOwnProperty.call(item, id)
        })
        const valueObj = values ? { values } : { value }
        if ((values?.length === 0 || values === undefined) && !value) {
          return [...newArray]
        } else {
          return [...newArray, { [id]: valueObj }]
        }
      })
    }
  }

  const findValue = (type: string, id: any) => {
    let item: any
    if (type === 'required') {
      item = requiredValues?.filter((item: any) => {
        // Object.prototype.hasOwnProperty.call(item, id) checks to see if the item has a key that is equal to id
        if (Object.prototype.hasOwnProperty.call(item, id)) {
          return item[id]
        }

        return undefined
      })
    } else if (type === 'optional') {
      item = optionalValues?.filter((item: any) => {
        if (Object.prototype.hasOwnProperty.call(item, id)) {
          return item[id]
        }

        return undefined
      })
    }
    let value = ''
    if (item[0]) {
      value = item[0][id]?.value ? item[0][id]?.value : item[0][id]?.values ? item[0][id]?.values : ''
    }
    return value
  }

  useEffect(() => {
    const required: any = []
    const optional: any = []
    if (data?.length) {
      data.forEach((item: any) => {
        if (item?.is_optional) {
          optional.push({ ...item?.data_point_field, deal_or_document: item?.deal_or_document })
        } else {
          required.push({ ...item?.data_point_field, deal_or_document: item?.deal_or_document })
        }
      })
    }
    setRequiredFields([...required])
    setOptionalFields([...optional])
  }, [data])

  const getErrors = (id: string) => {
    const errors: any = []
    validationErrors?.forEach(item => {
      // console.info('validationitem test:', item[id])
      if (item[id]) {
        if (item[id]?.includes('INVALID CURRENCY VALUE')) {
          errors.push('Invalid currency value. Accepted formats: $1234, $1.234, $1,234')
        } else {
          errors.push(item[id])
        }
      }
    })
    return errors
  }

  const requiredDealFields = requiredFields
    ?.filter(item => {
      return item?.deal_or_document === 'DEAL'
    })
    ?.map((item: any, index: any) => {
      const value = findValue('required', item.id)
      const errors: any = getErrors(item?.id)
      return (
        <div key={item?.id || index}>
          <h6>{item?.name}</h6>
          <UploadInput dataPointField={item} handleChange={handleChange} isError={errors?.length > 0} value={value} valueType={'required'} />
          {errors?.length > 0 &&
            errors.map((err: any) => (
              <p className="error" key={err}>
                {err}
              </p>
            ))}
        </div>
      )
    })
  const requiredDocumentFields = requiredFields
    ?.filter(item => {
      return item?.deal_or_document === 'DOCUMENT'
    })
    ?.map((item: any, index: any) => {
      const value = findValue('required', item.id)
      const errors: any = getErrors(item?.id)
      return (
        <div key={item?.id || index}>
          <h6>{item?.name}</h6>
          <UploadInput dataPointField={item} handleChange={handleChange} isError={errors?.length > 0} value={value} valueType={'required'} />
          {errors?.length > 0 &&
            errors.map((err: any) => (
              <p className="error" key={err}>
                {err}
              </p>
            ))}
        </div>
      )
    })

  const optionalDealFields = optionalFields
    ?.filter(item => {
      return item?.deal_or_document === 'DEAL'
    })
    ?.map((item: any, index: any) => {
      const value = findValue('optional', item.id)
      const errors: any = getErrors(item?.id)
      return (
        <div key={item?.id || index}>
          <h6>{item?.name}</h6>
          <UploadInput dataPointField={item} handleChange={handleChange} isError={errors?.length > 0} value={value} valueType={'optional'} />
          {errors?.length > 0 &&
            errors.map((err: any) => (
              <p className="error" key={err}>
                {err}
              </p>
            ))}
        </div>
      )
    })
  const optionalDocumentFields = optionalFields
    ?.filter(item => {
      return item?.deal_or_document === 'DOCUMENT'
    })
    ?.map((item: any, index: any) => {
      const value = findValue('optional', item.id)
      const errors: any = getErrors(item?.id)
      return (
        <div key={item?.id || index}>
          <h6>{item?.name}</h6>
          <UploadInput dataPointField={item} handleChange={handleChange} isError={errors?.length > 0} value={value} valueType={'optional'} />
          {errors?.length > 0 &&
            errors.map((err: any) => (
              <p className="error" key={err}>
                {err}
              </p>
            ))}
        </div>
      )
    })

  return (
    <>
      {documentFieldsLoading ? (
        <h6 style={{ paddingTop: '8px' }}>{'Loading document fields…'}</h6>
      ) : (
        <div style={{ paddingLeft: '8px' }}>
          {/* @ts-ignore */}
          {requiredDealFields?.length > 0 && (
            <>
              <h5 style={{ marginLeft: '-8px' }}>Required Deal Fields:</h5>
              {requiredDealFields}
            </>
          )}
          {/* @ts-ignore */}
          {requiredDocumentFields?.length > 0 && (
            <>
              <h5 style={{ marginLeft: '-8px' }}>Required Document Fields:</h5>
              {requiredDocumentFields}
            </>
          )}
          {/* @ts-ignore */}
          {optionalDealFields?.length > 0 && (
            <>
              <h5 style={{ marginLeft: '-8px' }}>Optional Deal Fields:</h5>
              {optionalDealFields}
            </>
          )}
          {/* @ts-ignore */}
          {optionalDocumentFields?.length > 0 && (
            <>
              <h5 style={{ marginLeft: '-8px' }}>Optional Document Fields:</h5>
              {optionalDocumentFields}
            </>
          )}
        </div>
      )}
    </>
  )
}
