import { ActiveComponents, useCciMainContext } from '../../../../CCI_Main'
import { CreateUser } from './CreateUser'
import { useCciRolesLazyQuery } from '../../../../../../graphql/codegen/hooks'
import ComponentLoadingOverlay from '../../../../../../components/ComponentLoadingOverlay'
import EditUser from './EditUser'
import React, { useEffect, useState } from 'react'

export default function CCI_RightPanel_UsersTab_Main() {
  const { activeComponent } = useCciMainContext()
  const [roleOptions, setRoleOptions] = useState<any>(undefined)

  const [getRoles, { data: roleData, loading: rolesLoading }] = useCciRolesLazyQuery()

  useEffect(() => {
    getRoles()
  }, [getRoles])

  useEffect(() => {
    const roles = roleData?.cci_roles?.map(item => {
      return { value: item?.id, label: item?.name }
    })
    setRoleOptions(roles && roles?.length > 0 ? [...roles] : undefined)
  }, [roleData])

  return (
    <>
      <ComponentLoadingOverlay loading={rolesLoading} />

      {(!activeComponent || activeComponent === ActiveComponents.EDIT_USER) && <EditUser roleOptions={roleOptions} />}

      {activeComponent === ActiveComponents.CREATE_USER && <CreateUser roleOptions={roleOptions} />}
    </>
  )
}
