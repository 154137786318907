import { KLARITY_BLUE } from '../../utils/styleUtils'
import { Maybe } from 'graphql/jsutils/Maybe'
import DateInput from './DateInput'
import FreeText from './FreeText'
import NumberInput from './NumberInput'
import Percentage from './Percentage'
import React, { FC, SyntheticEvent } from 'react'
import Select from 'react-select'

// types

type _GenericDataPointInputProps = {
  ariaLabel?: string
  fieldType: any
  options?: Maybe<Maybe<string>[]>
  placeholder?: string
  setValue: (v: any) => void
  value: any
}

// components

const GenericDataPointInput: FC<_GenericDataPointInputProps> = ({ ariaLabel, fieldType, options, placeholder, setValue, value }) => {
  let currentValues: any = []

  if (Array.isArray(value)) {
    currentValues = value?.map(val => ({ label: val, value: val }))
  }

  switch (fieldType) {
    case 'TEXT':
    case 'DURATION':
    case 'CURRENCY':
    case 'CURRENCY_TYPE':
    case 'GEO_CITY':
    case 'GEO_STATE':
    case 'GEO_COUNTRY':
    case 'ADDRESS':
      return (
        <FreeText
          ariaLabel={ariaLabel}
          onChange={(e: SyntheticEvent<HTMLTextAreaElement>) => setValue(e.currentTarget.value)}
          placeholder={placeholder}
          value={value}
        />
      )

    case 'DROP_DOWN':
    case 'BOOLEAN':
      if (!options) return null

      return (
        <Select
          aria-label={ariaLabel || 'Select an option'}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          onChange={v => setValue(v?.value || '')}
          options={options.map(v => ({ label: v, value: v }))}
          styles={{
            clearIndicator: (provided, { isFocused }) => ({ ...provided, visibility: isFocused ? 'visible' : 'hidden' }),
            container: provided => ({ ...provided, padding: 0 }),
            control: provided => ({ ...provided, fontSize: 14, borderRadius: 4, borderColor: '#bdbec2', boxShadow: 'none' }),
            dropdownIndicator: () => ({ display: 'none' }),
            indicatorSeparator: () => ({ display: 'none' }),
            menuPortal: provided => ({ ...provided, zIndex: 999999999 }),
            multiValue: provided => ({ ...provided, maxWidth: '300px' }),
            option: provided => ({ ...provided, fontSize: 14 }),
            placeholder: provided => ({ ...provided, color: '#e0e0e0' })
          }}
          theme={theme => ({ ...theme, borderRadius: 2, colors: { ...theme.colors, primary: KLARITY_BLUE } })}
          value={{ label: value, value }}
        />
      )

    case 'MULTI_SELECT_DROP_DOWN':
      if (!options) return null

      return (
        <Select
          aria-label={ariaLabel || 'Select an option'}
          isMulti
          menuPlacement="auto"
          menuPortalTarget={document.body}
          onChange={v => setValue(v?.map(item => item?.value))}
          options={options.map(v => ({ label: v, value: v }))}
          styles={{
            clearIndicator: (provided, { isFocused }) => ({ ...provided, visibility: isFocused ? 'visible' : 'hidden' }),
            container: provided => ({ ...provided, padding: 0 }),
            control: provided => ({ ...provided, fontSize: 14, borderRadius: 4, borderColor: '#bdbec2', boxShadow: 'none' }),
            dropdownIndicator: () => ({ display: 'none' }),
            indicatorSeparator: () => ({ display: 'none' }),
            menuPortal: provided => ({ ...provided, zIndex: 999999999 }),
            multiValue: provided => ({ ...provided, maxWidth: '300px' }),
            option: provided => ({ ...provided, fontSize: 14 }),
            placeholder: provided => ({ ...provided, color: '#e0e0e0' })
          }}
          theme={theme => ({ ...theme, borderRadius: 2, colors: { ...theme.colors, primary: KLARITY_BLUE } })}
          value={currentValues}
        />
      )

    case 'PERCENTAGE':
      return <Percentage ariaLabel={ariaLabel} onChange={e => setValue(e.currentTarget.value)} value={value} />

    case 'FLOAT':
    case 'NUMBER':
      return (
        <NumberInput
          ariaLabel={ariaLabel}
          isFloat={fieldType === 'FLOAT'}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => setValue(e?.currentTarget?.value || null)}
          placeholder={placeholder}
          value={value}
        />
      )

    case 'DATE':
      return <DateInput ariaLabel={ariaLabel} onChange={v => setValue(v || '')} value={value} />

    default:
      if (process.env.NODE_ENV !== 'production') {
        console.info(`Unsupported field type: ${fieldType}`)
      }

      return null
  }
}

export default GenericDataPointInput
