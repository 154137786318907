import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, CircularProgress, Typography } from '@mui/material'
import { Accordions, useCciMainContext } from '../../../../../../CCI_Main'
import { CreateFieldExtractionMethodInstanceMutation, EditFieldExtractionMethodInstanceMutation } from '../../../../../../../../graphql/codegen/operations'
import { EnableExtractionCheckbox } from '../../EditInputs/EnableExtractionCheckbox'
import { Exact, InputMaybe } from '../../../../../../../../graphql/codegen/schemas'
import { ExtractionConfirmationModalContent } from '../../EditInputs/ExtractionConfirmationModalContent'
import { ExtractionDescriptionInput } from '../../EditInputs/ExtractionDescriptionInput'
import { ExtractionMethodTypeInput } from '../../EditInputs/ExtractionMethodTypeInput'
import { MutationFunctionOptions } from '@apollo/client'
import { NeutralTagRow } from '../../EditInputs/NeutralTagRow'
import { _CurrentExtractionMethod } from '../../CCI_RightPanel_ChecklistTab_Main'
import { formatMentions } from '../../../../../../../../utils/commentUtils'
import { toast } from 'react-toastify'
import {
  useCreateFieldExtractionMethodInstanceMutation,
  useEditFieldExtractionMethodInstanceMutation,
  useExtractionMethodsQuery
} from '../../../../../../../../graphql/codegen/hooks'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Modal from '../../../../../../../../components/Modal'
import React, { FC, useEffect, useMemo, useState } from 'react'
import WithTooltip from '../../../../../../../../components/WithTooltip'

// components

export const InternalExtractionConfigurationAccordion: FC = () => {
  const { data: extractionMethodsData } = useExtractionMethodsQuery()
  const { expandedAccordionMap, selectedItem, toggleAccordion } = useCciMainContext()

  const [extractionDescription, setExtractionDescription] = useState<string>()
  const [extractionMethodId, setExtractionMethodId] = useState<string | undefined>()
  const [isExtractionConfirmationModalVisible, setIsExtractionConfirmationModalVisible] = useState(false)
  const [isExtractionEnabled, setIsExtractionEnabled] = useState(true)
  const [isExtractionMethodEmpty, setIsExtractionMethodEmpty] = useState(true)
  const [touchedExtractionConfigurations, setTouchedExtractionConfigurations] = useState<{ [dataPointFieldIdKey: string]: _CurrentExtractionMethod }>({})

  const currentExtractionMethod = useMemo(() => {
    const hasExtractionBeenTouched = selectedItem?.id && Boolean(touchedExtractionConfigurations[selectedItem.id])

    if (hasExtractionBeenTouched) {
      return (touchedExtractionConfigurations[selectedItem?.id] || {}) as _CurrentExtractionMethod
    }

    return JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}') as _CurrentExtractionMethod
  }, [selectedItem, touchedExtractionConfigurations])

  const extractionMethodList = useMemo(
    () => extractionMethodsData?.extraction_methods?.edges.map(item => ({ label: item?.node?.display_name || '', value: item?.node?.id || '' })) || [],
    [extractionMethodsData]
  )

  const [createFieldExtractionMethodInstanceMutation, { loading: createFieldExtractionMethodInstanceLoading }] = useCreateFieldExtractionMethodInstanceMutation(
    {
      onCompleted: (response: CreateFieldExtractionMethodInstanceMutation) => {
        let newExtractionConfig

        if (response.create_field_extraction_method_instance && response.create_field_extraction_method_instance.data_point_field) {
          newExtractionConfig = JSON.parse(response.create_field_extraction_method_instance.data_point_field.extraction_method_instances_config?.[0] || '{}')

          const touchedExtractions = touchedExtractionConfigurations

          if (selectedItem.id) {
            touchedExtractions[selectedItem.id] = newExtractionConfig as unknown as _CurrentExtractionMethod
          }

          setTouchedExtractionConfigurations(touchedExtractions)
        }

        toast.success('Extractions configurations saved', { autoClose: 5000 })

        setIsExtractionConfirmationModalVisible(false)
      },
      onError: error => {
        console.error(error)
        setIsExtractionConfirmationModalVisible(false)
      }
    }
  )

  const [editFieldExtractionMethodInstanceMutation, { loading: editFieldExtractionMethodInstanceLoading }] = useEditFieldExtractionMethodInstanceMutation({
    onCompleted: (response: EditFieldExtractionMethodInstanceMutation) => {
      let newExtractionConfig

      if (response.edit_field_extraction_method_instance && response.edit_field_extraction_method_instance.data_point_field) {
        newExtractionConfig = JSON.parse(response.edit_field_extraction_method_instance.data_point_field.extraction_method_instances_config?.[0] || '{}')

        const touchedExtractions = touchedExtractionConfigurations

        if (selectedItem.id) {
          touchedExtractions[selectedItem.id] = newExtractionConfig as unknown as _CurrentExtractionMethod
        }

        setTouchedExtractionConfigurations(touchedExtractions)
      }

      toast.success('Extractions configurations saved', { autoClose: 5000 })

      setIsExtractionConfirmationModalVisible(false)
    },
    onError: error => {
      console.error(error)
      setIsExtractionConfirmationModalVisible(false)
    }
  })

  const getExtractionDisabledMessage = (): false | string => {
    if (!extractionMethodId) {
      return 'No extraction has been selected'
    }

    if (extractionDescription !== currentExtractionMethod?.description) {
      return false
    }

    if (extractionMethodId && extractionMethodId !== currentExtractionMethod?.extraction_method_id) {
      return false
    }

    if (isExtractionEnabled !== Boolean(currentExtractionMethod?.is_enabled)) {
      return false
    }

    return 'No changes have been made'
  }

  const getVariablesForCreate = () => {
    let variables: MutationFunctionOptions<
      CreateFieldExtractionMethodInstanceMutation,
      Exact<{
        dataPointFieldId: string
        extractionDescription?: InputMaybe<string> | undefined
        extractionMethodId: string
        isEnabled?: InputMaybe<boolean> | undefined
      }>
    >['variables']

    if (selectedItem?.id) {
      variables = {
        dataPointFieldId: selectedItem.id,
        extractionDescription: extractionDescription ? formatMentions(extractionDescription) : '',
        extractionMethodId: extractionMethodId || '',
        isEnabled: Boolean(isExtractionEnabled)
      }

      return variables
    }

    return variables
  }

  const getVariablesForEdit = () => {
    let variables = {}

    if (selectedItem?.id) {
      variables = { ...variables, dataPointFieldId: selectedItem.id }
    }

    if (extractionDescription !== currentExtractionMethod?.description) {
      variables = { ...variables, extractionDescription: extractionDescription || '' }
    }

    if (extractionMethodId && extractionMethodId !== currentExtractionMethod?.extraction_method_id) {
      variables = { ...variables, extractionMethodId }
    }

    variables = { ...variables, isEnabled: Boolean(isExtractionEnabled), extractionMethodInstanceId: currentExtractionMethod.extraction_method_instance_id }

    return variables
  }

  const handleEnableExtractionChange = (value: boolean) => setIsExtractionEnabled(value)

  const handleExtractionData = (key: string, value: string | undefined) => {
    if (key === 'extraction_method' && value) {
      setExtractionMethodId(value)
    }

    if (key === 'extraction_description') {
      setExtractionDescription(value || '')
    }
  }

  const submitExtraction = () => {
    const isEditing = Boolean(currentExtractionMethod?.extraction_method_instance_id)

    if (isEditing) {
      editFieldExtractionMethodInstanceMutation({ variables: getVariablesForEdit() })
    } else {
      createFieldExtractionMethodInstanceMutation({ variables: getVariablesForCreate() })
    }
  }

  useEffect(() => {
    if (currentExtractionMethod?.extraction_method_instance_id) {
      setExtractionDescription(currentExtractionMethod?.description)
      setExtractionMethodId(currentExtractionMethod?.extraction_method_id)
      setIsExtractionEnabled(Boolean(currentExtractionMethod?.is_enabled))
      setIsExtractionMethodEmpty(false)
    } else {
      setExtractionDescription('')
      setExtractionMethodId(undefined)
      setIsExtractionEnabled(true)
      setIsExtractionMethodEmpty(true)
    }
  }, [currentExtractionMethod])

  return (
    <>
      <Accordion
        disableGutters
        expanded={Boolean(expandedAccordionMap[Accordions.INTERNAL_EXTRACTION_CONFIGURATION])}
        onChange={() => toggleAccordion(Accordions.INTERNAL_EXTRACTION_CONFIGURATION)}
        sx={{ borderBottom: '1px solid #e0e0e0', boxShadow: 'none', m: '0 -20px', '&.Mui-expanded:before': { opacity: 1 } }}
      >
        <AccordionSummary aria-controls="extraction-configuration-content" expandIcon={<ExpandMoreIcon />} id="extraction-configuration-header">
          <Typography sx={{ p: 0.5 }} variant="h4">
            Extraction Configuration
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ pb: 2.5, pt: 2, px: 2.5 }}>
          <Box sx={{ mb: 1 }}>
            <NeutralTagRow />
          </Box>

          {isExtractionMethodEmpty ? (
            <Alert
              action={
                <Button onClick={() => setIsExtractionMethodEmpty(false)} size="small" variant="outlined">
                  + Add Extraction Method
                </Button>
              }
              severity="info"
              sx={{ mt: 2.5, '.MuiAlert-action': { alignItems: 'center', display: 'flex', pt: 0 } }}
            >
              No extraction method detected.
            </Alert>
          ) : (
            <>
              <ExtractionMethodTypeInput
                currentMethodId={extractionMethodId}
                extractionMethodList={extractionMethodList}
                handleData={handleExtractionData}
                isDisabled={createFieldExtractionMethodInstanceLoading || editFieldExtractionMethodInstanceLoading}
              />

              <ExtractionDescriptionInput
                currentValue={extractionDescription || ''}
                handleData={handleExtractionData}
                isDisabled={createFieldExtractionMethodInstanceLoading || editFieldExtractionMethodInstanceLoading}
              />

              <EnableExtractionCheckbox
                currentValue={isExtractionEnabled}
                handleData={handleEnableExtractionChange}
                isDisabled={createFieldExtractionMethodInstanceLoading || editFieldExtractionMethodInstanceLoading}
              />

              <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 4 }}>
                <WithTooltip content={getExtractionDisabledMessage()}>
                  <Button
                    disableElevation
                    disabled={Boolean(getExtractionDisabledMessage())}
                    onClick={() => (isExtractionEnabled ? submitExtraction() : setIsExtractionConfirmationModalVisible(true))}
                    size="small"
                    startIcon={
                      !isExtractionConfirmationModalVisible && (editFieldExtractionMethodInstanceLoading || createFieldExtractionMethodInstanceLoading) ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null
                    }
                    variant="contained"
                  >
                    Save configuration
                  </Button>
                </WithTooltip>

                <Box ml={1}>
                  <Button
                    disabled={Boolean(getExtractionDisabledMessage())}
                    onClick={() => {
                      setExtractionDescription(currentExtractionMethod.description)
                      setExtractionMethodId(currentExtractionMethod.extraction_method_id)
                      setIsExtractionEnabled(true)
                    }}
                    size="small"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>

      <Modal isOpen={isExtractionConfirmationModalVisible} onRequestClose={() => setIsExtractionConfirmationModalVisible(false)} title="Confirm Submission">
        <ExtractionConfirmationModalContent
          isLoading={editFieldExtractionMethodInstanceLoading || createFieldExtractionMethodInstanceLoading}
          onClose={() => setIsExtractionConfirmationModalVisible(false)}
          onSubmit={submitExtraction}
        />
      </Modal>
    </>
  )
}
