import { captureError } from '../../utils/sentry'
import { toast } from 'react-toastify'
import { uploadAttachment } from '../../utils/uploadApiUtils'
import { useDealAttachmentsQuery, useDocumentAttachmentsQuery } from '../../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import Attachment from '../Attachment'
import CommentSkeleton from '../CommentSection/Skeleton'
import Dropzone from '../Dropzone' // stealing this from comments for now
import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

interface Props {
  attachments?: any[] | null
  dealIsFinalized?: boolean
  isProcessing?: boolean
  loading: boolean
  onDrop: (x: any) => void
}

export default function AttachmentsTab({ attachments, dealIsFinalized, isProcessing, loading, onDrop }: Props) {
  return (
    <div className={clsx(css.inner, css.attachmentsTab)}>
      {!loading && !dealIsFinalized && (
        <div className={css.dropzoneWrapper}>
          <Dropzone isLoading={isProcessing} onDrop={onDrop} />
        </div>
      )}
      {loading && <CommentSkeleton />}
      {!loading && attachments && (
        <ul className={css.attachments}>
          {attachments.map(attachment => {
            return <Attachment attachment={attachment} dealIsFinalized={dealIsFinalized} key={attachment?.id} />
          })}
        </ul>
      )}
      {!loading && !attachments?.length && <div className={css.emptyState}>{`This document doesn't have any attachments yet.`}</div>}
    </div>
  )
}

/* Container for Deal Attachments Tab */
export function DealAttachmentsTabContainer({ dealIsFinalized }: { dealIsFinalized?: boolean }) {
  const { dealId } = useParams<{ dealId: string }>()
  const { data, loading, refetch } = useDealAttachmentsQuery({ variables: { dealId } })
  const [isProcessing, setIsProcessing] = useState(false)

  const onDrop = useCallback(
    async acceptedFiles => {
      setIsProcessing(true)
      try {
        await uploadAttachment(`deals/${dealId}/attachments`, acceptedFiles)
        await refetch()
      } catch (error) {
        captureError(error)
        toast.error('Something went wrong', { autoClose: 5000 })
      } finally {
        setIsProcessing(false)
      }
    },
    [dealId, refetch]
  )

  return (
    <AttachmentsTab
      attachments={data?.deal_attachments?.filter((a: any) => a.is_deleted === false)}
      dealIsFinalized={dealIsFinalized}
      isProcessing={isProcessing}
      loading={loading}
      onDrop={onDrop}
    />
  )
}

/* Container for Document Attachments Tab */
export function DocumentAttachmentsTabContainer() {
  const { documentId } = useParams<{ documentId: string }>()
  const { data, loading, refetch } = useDocumentAttachmentsQuery({
    variables: { documentId }
  })
  const [isProcessing, setIsProcessing] = useState(false)

  const onDrop = useCallback(
    async acceptedFiles => {
      setIsProcessing(true)
      try {
        await uploadAttachment(`documents/${documentId}/attachments`, acceptedFiles)
        await refetch()
      } catch (error) {
        captureError(error)
        toast.error('Something went wrong', { autoClose: 5000 })
      } finally {
        setIsProcessing(false)
      }
    },
    [documentId, refetch]
  )

  return (
    <AttachmentsTab
      attachments={data?.document_attachments?.filter((a: any) => a.is_deleted === false)}
      isProcessing={isProcessing}
      loading={loading}
      onDrop={onDrop}
    />
  )
}
