import { Box, IconButton } from '@mui/material'
import { KLARITY_BLUE } from '../../../utils/styleUtils'
import { UrlMenu } from '../../UrlMenu'
import { useScrollability } from '../../../hooks/useScrollability'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import React, { ChangeEvent, ComponentPropsWithoutRef, FC, SyntheticEvent, useEffect, useState } from 'react'
import ReactDOM from 'react-dom' // Styles should mimic FreeText component to maintain consistency.
import TextField from '@mui/material/TextField'
import WithTooltip from '../../WithTooltip/WithTooltip'
import clsx from 'clsx'
import css from '../FreeText/style.module.scss'

// types

type _EnhancedTextFieldButtonsProps = { isDisabled?: boolean }

type _EnhancedTextFieldProps = _EnhancedTextFieldBoxProps & { expandedContainer?: HTMLElement | null; isExpanded?: boolean }

type _EnhancedTextFieldBoxProps = {
  ariaLabel?: string
  handleBlur?: (event: any) => void
  hyperlinkUrl?: string | null
  isDisabled?: boolean
  isFocused?: boolean
  maxRows?: number
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onExpandButtonClick?: () => void
  placeholder?: string
  setFocused?: (value: boolean) => void
  value: string
}

type _ExpandButtonProps = {
  iconButtonProps?: ComponentPropsWithoutRef<typeof IconButton>
  isCollapseIcon?: boolean
  onClick: (event: SyntheticEvent) => void
}

// components

export const EnhancedTextField: FC<_EnhancedTextFieldProps> = ({ expandedContainer, isExpanded, onExpandButtonClick, ...rest }) =>
  expandedContainer && isExpanded ? (
    ReactDOM.createPortal(<EnhancedTextFieldBox {...rest} />, expandedContainer)
  ) : (
    <EnhancedTextFieldBox onExpandButtonClick={onExpandButtonClick} {...rest} />
  )

const EnhancedTextFieldBox: FC<_EnhancedTextFieldBoxProps> = ({
  ariaLabel,
  handleBlur,
  hyperlinkUrl,
  isDisabled,
  isFocused,
  maxRows,
  onChange,
  onExpandButtonClick,
  placeholder,
  setFocused,
  value
}) => {
  const { element, isScrollableDown, isScrollableUp, refCallback } = useScrollability({ offset: 2 })
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    isFocused ? element?.focus() : element?.blur()
  }, [element, isFocused])

  return (
    <Box onMouseLeave={() => setIsHovered(false)} onMouseOver={() => setIsHovered(true)} sx={{ position: 'relative' }}>
      <TextField
        className={clsx(css.input, isDisabled && css.disabled)}
        disabled={isDisabled}
        fullWidth
        inputProps={{ 'aria-label': ariaLabel || placeholder || 'Enter text' }}
        inputRef={refCallback}
        maxRows={maxRows}
        multiline
        onBlur={event => {
          setFocused?.(false)
          handleBlur?.(event)
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange?.(event)}
        placeholder={placeholder}
        size="small"
        sx={{
          lineHeight: 1.4,
          ...(!isDisabled && { backgroundColor: 'white' }),
          '&:focus-within': { zIndex: 1 },
          '.MuiInputBase-input': { color: '#333', fontSize: 14, px: 0.25 },
          '.MuiInputBase-root': {
            p: 1,
            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'hsl(0,0%,70%)' },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'hsl(0,0%,70%)', borderWidth: 1 },
            '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': { borderColor: KLARITY_BLUE }
          },
          '.MuiOutlinedInput-notchedOutline': { transition: 'border-color 100ms' },
          'textarea.Mui-disabled': { WebkitTextFillColor: 'inherit' }
        }}
        value={value}
      />

      <EnhancedTextFieldButtons isDisabled={isDisabled}>
        {isHovered && onExpandButtonClick && <ExpandButton onClick={onExpandButtonClick} />}

        <UrlMenu staticUrls={hyperlinkUrl ? [hyperlinkUrl] : []} value={value} />
      </EnhancedTextFieldButtons>

      {isScrollableDown && <ScrollDownArrow />}

      {isScrollableUp && <ScrollUpArrow />}
    </Box>
  )
}

export const EnhancedTextFieldButtons: FC<_EnhancedTextFieldButtonsProps> = ({ children, isDisabled = false }) => (
  <Box
    sx={{
      alignItems: 'center',
      background: `linear-gradient(to top, transparent, ${isDisabled ? '#f2f2f2' : 'white'} 10%)`,
      backgroundRepeat: 'no-repeat',
      borderRadius: '0 3px 3px 0',
      display: 'flex',
      gap: 1,
      justifyContent: 'center',
      padding: '0 6px',
      position: 'absolute',
      right: 2,
      top: 2,
      '&::before': {
        background: `linear-gradient(to right, transparent, ${isDisabled ? '#f2f2f2' : 'white'})`,
        content: '""',
        height: 'calc(100% - 5px)',
        left: -6,
        position: 'absolute',
        top: 2,
        width: 6
      }
    }}
  >
    {children}
  </Box>
)

export const ExpandButton: FC<_ExpandButtonProps> = ({ iconButtonProps, isCollapseIcon = false, onClick }) => (
  <Box
    sx={{
      alignItems: 'center',
      borderRadius: '50%',
      display: 'flex',
      height: 36,
      justifyContent: 'center',
      width: 24
    }}
  >
    <WithTooltip content={isCollapseIcon ? 'Collapse' : 'Expand'}>
      <IconButton
        aria-label={isCollapseIcon ? 'Collapse' : 'Expand'}
        onClick={onClick}
        sx={{
          backgroundColor: 'transparent',
          border: '1px solid transparent',
          color: '#57575b',
          opacity: 0.5,
          p: 0.25,
          transition: 'all 0.1s ease-in-out',
          '&:focus, &:hover': { backgroundColor: '#f3f4f8', opacity: 1, zIndex: 1 }
        }}
        tabIndex={-1} // Allow users to tab between field inputs without focusing on this button.
        {...iconButtonProps}
      >
        {isCollapseIcon ? <FullscreenExitIcon sx={{ fontSize: 20 }} /> : <FullscreenIcon sx={{ fontSize: 20 }} />}
      </IconButton>
    </WithTooltip>
  </Box>
)

export const ScrollDownArrow: FC = () => (
  <ArrowDropDown fontSize="small" sx={{ bottom: -4, fill: '#bdbec2', left: '50%', position: 'absolute', transform: 'translateX(-50%)', zIndex: 1 }} />
)

export const ScrollUpArrow: FC = () => (
  <ArrowDropUp fontSize="small" sx={{ fill: '#bdbec2', left: '50%', position: 'absolute', top: -4, transform: 'translateX(-50%)', zIndex: 1 }} />
)
