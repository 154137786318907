import * as QB from 'react-querybuilder'
import * as queryString from 'query-string'
import { Column } from '../reducers/dashboardQueryReducer'
import { JSONCrush, JSONUncrush } from './jsonCrush'
import { RuleGroupType } from 'react-querybuilder'
import { captureError } from './sentry'
import DOMPurify from 'dompurify'

type FilterList = {
  [key: string]: {
    [key: string]: {
      [key: string]: string
    }
  }
}

export type DocumentQueryState = { chosenDataPointFieldIds: string[]; filters: FilterList | unknown; offset: number; size: number; sortConfig: any[] }

const DEFAULT_QUERY = { filters: {}, chosenDataPointFieldIds: [], sortConfig: [], size: 10, offset: 0 }

// Stringify certain filters & sort strings as api expects,
// or return a default formatted query
export function formatDashboardQueryFilters(query?: DocumentQueryState) {
  if (!query) return

  return Object.entries(query || DEFAULT_QUERY).reduce((acc: any, current) => {
    const [key, value] = current
    if (['sortDataPoints', 'filters'].includes(key)) {
      acc[key] = JSON.stringify(value)
    } else {
      acc[key] = value
    }
    return acc
  }, {})
}

export function encodeQuery(q: QB.RuleGroupType) {
  if (!q) {
    return null
  }
  // The second argument to JSON.stringify is a 'replacer' function which will removed unused keys to shorten url
  const stringified = JSON.stringify(q, function (key, value) {
    if (key === 'id' && (value.indexOf('g-') === 0 || value.indexOf('r-') === 0)) {
      return undefined
    } else if (key === '__typename') {
      return undefined
    }
    return value
  })

  // JSONCrush is a package to shorten json strings https://github.com/KilledByAPixel/JSONCrush
  return JSONCrush(stringified)
}

export function decodeQuery(qString: string) {
  if (!qString) {
    return null
  }
  try {
    const escapedQString = decodeURIComponent(DOMPurify.sanitize(qString))
    const uncrushed = JSONUncrush(escapedQString)
    const qsParsedToString = queryString.parse(uncrushed, { arrayFormat: 'comma' })?.q?.toString() || null

    // qsParsedToString is needed in order to grab just the query from the string and removed saved columns.
    // without this JSON.parse(uncrushed) will error out because saved queries got fixed to be able to include selected columns also.

    if (qsParsedToString) {
      return JSON.parse(qsParsedToString)
    } else {
      return JSON.parse(uncrushed)
    }
  } catch (error) {
    captureError(error, 'decodeQuery error')
  }
}

export function getQueryColumns(qString: string) {
  if (!qString) {
    return null
  }
  try {
    const escapedQString = decodeURIComponent(DOMPurify.sanitize(qString))
    const uncrushed = JSONUncrush(escapedQString)
    const columns = queryString.parse(uncrushed, { arrayFormat: 'comma' })?.cols || undefined

    if (columns) {
      return columns
    } else {
      const uncrushedParsed = JSON.parse(uncrushed)
      const uncrushedColumns = uncrushedParsed?.rules?.map((r: any) => r?.field)
      return uncrushedColumns
    }
  } catch (error) {
    captureError(error, 'getQueryColumns error')
  }
}

// this is a function we can use in case a search field/column was renamed. This will take the old col/new col and the saved string and return a fixed string.
export function renameQueryColumn(oldColumnName: string, newColumnName: string, qString: string) {
  if (!qString) {
    return null
  }
  try {
    const escapedQString = decodeURIComponent(DOMPurify.sanitize(qString))
    const uncrushed = JSONUncrush(escapedQString)
    const uncrushedParsed = JSON.parse(uncrushed)
    const tempQuery = {
      ...uncrushedParsed,
      rules: uncrushedParsed?.rules?.map((rule: any) => {
        if (rule?.field === oldColumnName) {
          return { ...rule, field: newColumnName }
        } else {
          return rule
        }
      })
    }
    const cols = tempQuery?.rules.map((r: any) => r?.field)
    const tempQueryFormatted = queryString.stringify({ cols, q: encodeQuery(tempQuery) }, { arrayFormat: 'comma' })

    return tempQueryFormatted
  } catch (error) {
    captureError(error, 'renameQueryColumn error')
  }
}

export const generateColumnsToSave = (q: RuleGroupType | null, selectedColumns: Column[]) => {
  const selectedCols =
    selectedColumns?.map(column => {
      return column.value
    }) || [] // @ts-ignore
  const searchColumns = q?.rules?.map(rule => {
    // @ts-ignore
    return rule.field
  })
  const filteredSearchColumns =
    searchColumns?.filter(col => {
      return !selectedCols || !selectedCols?.includes(col)
    }) || []
  const combinedColumns = [...selectedCols, ...filteredSearchColumns]
  // @ts-ignore
  const duplicatesRemoved = [...new Set(combinedColumns)]

  return duplicatesRemoved
}

// example renameQueryColumn call fixing an array of bad keys
// const badKeys = [
//   "('rules!%5B('field!'name'~value!'Deal%205'~operator!'contains')%5D~combinator!'and'~not!false)_",
//   "('rules!%5B('field!'name'~value!'Klarity'~operator!'contains')%5D~combinator!'and'~not!false)_",
//   "('rules!%5B('field!'name'~value!'1'~operator!'contains')%5D~combinator!'and'~not!false)_",
//   "('rules!%5B('field.state'~value!%5B('id*name-~value*label-)%5D~operator.in')%5D~combinator.and'~not!false)*.U3RhdGU6NWY4OTNmODJjYTAwNjg3MDE1NGE3ZDQ4'~-.L2%20Review'.!'%01.-*_"
// ]

// console.info('fixed keys', badKeys.map((key)=>{ return renameQueryColumn('name', 'combined_name', key); }))
