import {
  Alert,
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'
import { ButtonLoading } from '../ButtonLoading'
import { Column, useTable } from 'react-table'
import { DataPoint, DataPointDataTable } from '../../graphql/codegen/schemas'
import { DataPointsForGroupDocument, useDocumentDataTableFieldsQuery, useEditDataTableDataPointValueMutation } from '../../graphql/codegen/hooks'
import { Opening, useOpening } from '@hoologic/use-opening'
import { SlideUpTransition } from '../Transition'
import { Toast } from '../Toast'
import { concat, isEmpty, last } from 'lodash'
import { copyDataTable, downloadDataTableCsvFile } from '../DocumentPanel/DataTable/DataTable'
import { useContextInit } from '../../hooks/useContextInit'
import { useDocumentPageWrapperContext } from '../DocumentPageWrapper'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import CopyIcon from '@mui/icons-material/ContentCopy'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import HelpIcon from '@mui/icons-material/Help'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import MergeIcon from '@mui/icons-material/Merge'
import React, { FC, createContext, useCallback, useMemo, useRef, useState } from 'react'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import TableView from '@mui/icons-material/TableView'
import UndoIcon from '@mui/icons-material/Undo'
import WithTooltip from '../WithTooltip'

// types

type _DataTableCell = { pageNumber: number; tableNumber: number; text: string }

type _DataTableEditorContext = {
  dealId: string
  documentId: string
  savedOpening: Opening
}

type _DataTableEditorProps = {
  dataTableList: _DataTableList
  documentId: string
  savedOpening: Opening
}

type _DataTableEditorDialogProps = {
  dataTableList: _DataTableList
  opening: Opening
}

type _DataTableEditorHelpProps = {
  toggleHelp: () => void
}

type _DataTableEditorSaveProps = {
  closeEditor: (delay?: number) => void
  opening: Opening
  table: _ReactTable
}

export type _DataTableList = _DataTableCell[][][]

type _FormValues = {
  dataPointFieldId: string
}

type _ReactTable = {
  columns: Column<_ReactTableRow>[]
  data: _ReactTableRow[]
  id: string
}

type _ReactTableProps = {
  deleteTable?: () => void
  setTable: (table: _ReactTable) => void
  setUndo: (title: string) => void
  table: _ReactTable
  title: string
}

type _ReactTableRow = { [key: string]: _DataTableCell }

type _Undo = {
  index: number
  tableList: _ReactTable[]
  title: string
}

// constants

const ICON_BUTTON_HOVER = { '&:hover': { bgcolor: 'rgba(211, 47, 47, 0.04)', color: 'error.main' } }

// context

const DataTableEditorContext = createContext<_DataTableEditorContext | null>(null)

// hooks

const useDataTableEditorContext = () => useContextInit(DataTableEditorContext)

// components

const ReactTable: FC<_ReactTableProps> = ({ deleteTable, setTable, setUndo, table, title }) => {
  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable(table)

  const columnRowCount = `${table.columns.length} column${table.columns.length === 1 ? '' : 's'}, ${table.data.length - 1} row${
    table.data.length === 2 ? '' : 's'
  }`

  const deleteColumn = useCallback(
    (id: string) => {
      setUndo('column deletion')

      let index = 0

      const columns = table.columns.reduce(
        (previous, current) => (current.accessor === id ? previous : [...previous, { Header: current.Header, accessor: String(index++) }]),
        [] as Column<_ReactTableRow>[]
      )

      const data = table.data.map(datum => {
        index = 0

        return Object.keys(datum).reduce(
          (previous, current) => (current === id ? previous : { ...previous, [String(index++)]: datum[current] }),
          {} as _ReactTableRow
        )
      })

      setTable({ ...table, columns, data })
    },
    [setTable, setUndo, table]
  )

  const deleteRow = useCallback(
    (rowIndex: number) => {
      setUndo('row deletion')

      setTable({ ...table, data: table.data.filter((_, index) => index !== rowIndex) })
    },
    [setTable, setUndo, table]
  )

  const renderCell = useCallback(({ text }: _DataTableCell) => text, [])

  return (
    <>
      <Box display="flex" justifyContent="space-between" pb={1} px={1} width="100%">
        <Typography component="h2" variant="h4">
          {title}
        </Typography>

        <Typography component="h3" variant="h4">
          {columnRowCount}
        </Typography>
      </Box>

      <Paper elevation={2} sx={{ width: '100%' }}>
        <Table {...getTableProps()}>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row)

              const isDisabled = table.data.length === 2

              const rowTooltipTitle = isDisabled ? 'Cannot delete (header row and at least one other row must be present)' : 'Delete row'

              return (
                // eslint-disable-next-line react/jsx-key
                <TableRow {...row.getRowProps()} sx={{ ...(!rowIndex && { backgroundColor: '#f1f5fa', '& td': { fontWeight: 'bold' } }) }}>
                  {row.cells.map(cell => (
                    // eslint-disable-next-line react/jsx-key
                    <TableCell {...cell.getCellProps()} sx={{ border: '1px solid #ddd', p: 1 }}>
                      {renderCell(cell.value)}
                    </TableCell>
                  ))}

                  <TableCell sx={{ backgroundColor: '#f1f5fa', border: '1px solid #ddd', lineHeight: 0, p: 0, textAlign: 'center', width: 0 }}>
                    <Tooltip PopperProps={{ sx: { zIndex: 6002 } }} arrow placement="left" title={rowTooltipTitle}>
                      <Box>
                        <IconButton aria-label={rowTooltipTitle} disabled={isDisabled} onClick={() => deleteRow(rowIndex)} sx={ICON_BUTTON_HOVER}>
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}

            {headerGroups.map(headerGroup => {
              const tableTooltipTitle = deleteTable ? 'Delete table' : 'Cannot delete (at least one table must be present)'

              return (
                // eslint-disable-next-line react/jsx-key
                <TableRow {...headerGroup.getHeaderGroupProps()} sx={{ backgroundColor: '#f1f5fa' }}>
                  {headerGroup.headers.map(column => {
                    const isDisabled = table.columns.length === 1

                    const columnTooltipTitle = isDisabled ? 'Cannot delete (at least one column must be present)' : 'Delete column'

                    return (
                      // eslint-disable-next-line react/jsx-key
                      <TableCell {...column.getHeaderProps()} sx={{ border: '1px solid #ddd', fontWeight: 600, minWidth: 50, p: 0 }}>
                        <Box alignItems="center" display="flex" flexDirection="column">
                          <Tooltip PopperProps={{ sx: { zIndex: 6002 } }} arrow title={columnTooltipTitle}>
                            <Box>
                              <IconButton aria-label={columnTooltipTitle} disabled={isDisabled} onClick={() => deleteColumn(column.id)} sx={ICON_BUTTON_HOVER}>
                                <RemoveCircleOutlineIcon fontSize="inherit" />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    )
                  })}

                  <TableCell sx={{ border: '1px solid #ddd', py: 0 }}>
                    <Tooltip PopperProps={{ sx: { zIndex: 6002 } }} arrow placement="left" title={tableTooltipTitle}>
                      <Box>
                        <IconButton aria-label={tableTooltipTitle} disabled={!deleteTable} onClick={deleteTable} sx={ICON_BUTTON_HOVER}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export const DataTableEditor: FC<_DataTableEditorProps> = ({ dataTableList, documentId, savedOpening }) => {
  const dataTableEditorOpening = useOpening()
  const { dealId } = useParams<{ dealId: string }>()

  const context = useMemo<_DataTableEditorContext>(
    () => ({ dataTableList, dealId, documentId, savedOpening }),
    [dataTableList, dealId, documentId, savedOpening]
  )

  const tooltipContent = `Data table editor${isEmpty(dataTableList) ? ' (select at least one table first)' : ''}`

  return (
    <DataTableEditorContext.Provider value={context}>
      <WithTooltip content={tooltipContent}>
        <Box position="relative" top={2}>
          <IconButton aria-label={tooltipContent} disabled={isEmpty(dataTableList)} onClick={dataTableEditorOpening.open} size="small">
            <TableView />
          </IconButton>
        </Box>
      </WithTooltip>

      {!isEmpty(dataTableList) && <DataTableEditorDialog dataTableList={dataTableList} opening={dataTableEditorOpening} />}
    </DataTableEditorContext.Provider>
  )
}

const DataTableEditorDialog: FC<_DataTableEditorDialogProps> = ({ dataTableList, opening }) => {
  const initialTableList: _ReactTable[] = useMemo(
    () =>
      dataTableList.map((table, index) => ({
        columns: Array.from(Array(table[0].length), (_, index) => ({ accessor: String(index) })),
        data: table.map(tableRow => tableRow.reduce((previous, current, index) => ({ ...previous, [index.toString()]: current }), {})),
        id: String(index)
      })),
    [dataTableList]
  )
  const copiedOpening = useOpening()
  const saveOpening = useOpening()
  const isFirstRender = useRef(true)
  const [isDataTableEditorHelpShown, setIsDataTableEditorHelpShown] = useState(Boolean(localStorage.getItem('isDataTableEditorHelpShown')))
  const [tableList, setTableList] = useState(initialTableList)
  const [toast, setToast] = useState('')
  const [undoStack, setUndoStack] = useState<_Undo[]>([])

  const resetState = useCallback(() => {
    setTableList(initialTableList)
    setUndoStack([])
  }, [initialTableList])

  useMemo(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    }

    resetState()
  }, [resetState])

  const closeEditor = useCallback(
    (delay = 500) => {
      setTimeout(() => {
        resetState()

        opening.close()
      }, delay)
    },
    [opening, resetState]
  )

  const deleteTable = useCallback((index: number) => setTableList(tableList.filter((_, _index: number) => _index !== index)), [tableList, setTableList])

  const mergeTables = useCallback(
    (index: number) => {
      setUndoStack([...undoStack, { tableList, index: index - 1, title: 'table merge' }])

      setTableList(
        tableList.reduce((previous, current, _index) => {
          if (_index === index - 1) {
            return [...previous, { ...current, data: concat(current.data, tableList[index].data) }]
          }

          if (_index === index) {
            return previous
          }

          return [...previous, current]
        }, [] as _ReactTable[])
      )
    },
    [tableList, setTableList, undoStack]
  )

  const setTable = useCallback(
    (table: _ReactTable, index: number) => setTableList(tableList.map((_table, _index) => (_index === index ? table : _table))),
    [tableList]
  )

  const toggleHelp = useCallback(() => {
    isDataTableEditorHelpShown ? localStorage.removeItem('isDataTableEditorHelpShown') : localStorage.setItem('isDataTableEditorHelpShown', '1')

    setIsDataTableEditorHelpShown(previous => !previous)
  }, [isDataTableEditorHelpShown])

  const undo = useCallback(() => {
    if (!isEmpty(undoStack)) {
      setTableList(last(undoStack)!.tableList)

      setUndoStack(undoStack.slice(0, -1))
    }
  }, [undoStack])

  const dataTable: DataPointDataTable = useMemo(
    () => ({
      id: tableList[0].id,
      rows: JSON.stringify(tableList[0].data.map(row => Object.entries(row).reduce((previous, [key, value]) => ({ ...previous, [key]: value.text }), {})))
    }),
    [tableList]
  )

  const isMultipleTables = tableList.length > 1

  return (
    <>
      <Dialog
        PaperProps={{ sx: { backgroundColor: '#e7ebf0' } }}
        TransitionComponent={SlideUpTransition}
        fullScreen
        onClose={opening.close}
        open={opening.isOpen}
        sx={{ zIndex: 6001 }}
      >
        <AppBar sx={{ mb: 1, position: 'relative' }}>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Box alignItems="center" display="flex" gap={1}>
              <Typography component="h2" variant="h4">
                Data Table Editor
              </Typography>

              <Tooltip PopperProps={{ sx: { zIndex: 6002 } }} arrow title="Show help">
                <Box>
                  <IconButton aria-label="Show help" color="inherit" disabled={!isDataTableEditorHelpShown} onClick={toggleHelp}>
                    <HelpIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>

            <Box display="flex" gap={1}>
              <Tooltip PopperProps={{ sx: { zIndex: 6002 } }} arrow title="Copy table">
                <Box>
                  <IconButton aria-label="Copy table" color="inherit" disabled={isMultipleTables} onClick={() => copyDataTable(dataTable, setToast)}>
                    <CopyIcon />
                  </IconButton>
                </Box>
              </Tooltip>

              <Tooltip PopperProps={{ sx: { zIndex: 6002 } }} arrow title="Download table">
                <Box>
                  <IconButton aria-label="Download table" color="inherit" disabled={isMultipleTables} onClick={() => downloadDataTableCsvFile(dataTable)}>
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                </Box>
              </Tooltip>

              <Tooltip PopperProps={{ sx: { zIndex: 6002 } }} arrow title={`Undo ${last(undoStack)?.title || ''}`}>
                <Box>
                  <IconButton aria-label="Undo" color="inherit" disabled={isEmpty(undoStack)} onClick={undo}>
                    <UndoIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>

            <Box display="flex" gap={1}>
              <Button color="inherit" onClick={closeEditor}>
                Cancel
              </Button>

              <Button color="inherit" disabled={isMultipleTables} onClick={saveOpening.open} variant="outlined">
                Save
              </Button>
            </Box>

            <Snackbar autoHideDuration={3000} onClose={copiedOpening.close} open={copiedOpening.isOpen}>
              <Alert severity="success">Table copied.</Alert>
            </Snackbar>
          </Toolbar>
        </AppBar>

        <DialogContent>
          {!isDataTableEditorHelpShown && <DataTableEditorHelp toggleHelp={toggleHelp} />}

          {tableList.map((table, index) => {
            if (index >= tableList.length) return null

            const isDisabled = Boolean(index && tableList[index].columns.length !== tableList[index - 1].columns.length)

            const tooltipTitle = isDisabled ? 'Column counts must match to merge tables' : 'Merge tables'

            return (
              <Box alignItems="center" display="flex" flexDirection="column" key={table.id}>
                {index ? (
                  <Tooltip PopperProps={{ sx: { zIndex: 6002 } }} arrow title={tooltipTitle}>
                    <Box sx={{ mt: 4 }}>
                      <IconButton
                        aria-label={tooltipTitle}
                        color="primary"
                        disabled={isDisabled}
                        onClick={() => mergeTables(index)}
                        sx={{ backgroundColor: '#fff' }}
                      >
                        <MergeIcon />
                      </IconButton>
                    </Box>
                  </Tooltip>
                ) : null}

                <ReactTable
                  deleteTable={
                    tableList.length === 1
                      ? undefined
                      : () => {
                          setUndoStack([...undoStack, { tableList, index, title: 'table deletion' }])
                          deleteTable(index)
                        }
                  }
                  setTable={(table: _ReactTable) => setTable(table, index)}
                  setUndo={title => setUndoStack([...undoStack, { tableList, index, title }])}
                  table={table}
                  title={`Page ${table.data[0][0].pageNumber}, table ${table.data[0][0].tableNumber}`}
                />
              </Box>
            )
          })}
        </DialogContent>
      </Dialog>

      <DataTableEditorSave closeEditor={closeEditor} opening={saveOpening} table={tableList[0]} />

      <Toast message={toast} setMessage={setToast} />
    </>
  )
}

const DataTableEditorHelp: FC<_DataTableEditorHelpProps> = ({ toggleHelp }) => (
  <Card elevation={2} sx={{ mb: 3 }}>
    <CardContent>
      <Typography>Use this editor to prepare a single table for a checklist annotation.</Typography>

      <List
        sx={{
          '&': { listStyleType: 'disc', pl: 3 },
          '& .MuiListItem-root': { display: 'list-item', listStyleType: 'unset', pl: 0.25 }
        }}
      >
        <ListItem>
          <>Use the</>

          <IconButton aria-label="Delete column or row" sx={ICON_BUTTON_HOVER}>
            <RemoveCircleOutlineIcon />
          </IconButton>

          <>and</>

          <IconButton aria-label="Delete table" sx={ICON_BUTTON_HOVER}>
            <HighlightOffIcon />
          </IconButton>

          <>buttons to delete any undesired columns, rows or tables.</>
        </ListItem>

        <ListItem>
          <>Use the </>

          <IconButton aria-label="Merge" color="primary" size="small" sx={{ backgroundColor: '#fff', border: '1px solid #ddd', mx: 0.5 }}>
            <MergeIcon fontSize="inherit" />
          </IconButton>

          <> buttons to merge tables with matching column counts.</>
        </ListItem>
      </List>

      <Typography>Once you have a single table, click “Save” to annotate the checklist.</Typography>
    </CardContent>

    <CardActions sx={{ mb: 1, ml: 1 }}>
      <Button onClick={toggleHelp} variant="outlined">
        Got It
      </Button>
    </CardActions>
  </Card>
)

const DataTableEditorSave: FC<_DataTableEditorSaveProps> = ({ closeEditor, opening, table }) => {
  const { setActiveTableId } = useDocumentPageWrapperContext()
  const { dealId, documentId, savedOpening } = useDataTableEditorContext()
  const { data } = useDocumentDataTableFieldsQuery({ fetchPolicy: 'network-only' })

  const [editDataTableDataPointValue, { loading: isLoading }] = useEditDataTableDataPointValueMutation({
    awaitRefetchQueries: true,
    onCompleted: () => {
      closeEditor()
      opening.close()
      resetForm()
      savedOpening.open()
      setActiveTableId(null)
    },
    refetchQueries: ({ data }) =>
      (data?.edit_data_table_data_point_value?.data_points as DataPoint[]).map(dataPoint => ({
        query: DataPointsForGroupDocument,
        variables: { group: dataPoint.data_point_field?.group, resourceId: dealId || documentId }
      }))
  })

  const tableColumns = useMemo(() => {
    let counter = 1

    return Object.values(table.data[0]).map(({ text }) => text || `<Untitled ${counter++}>`)
  }, [table.data])

  const tableRows = useMemo(
    () =>
      JSON.stringify(
        table.data.slice(1).map(row => Object.entries(row).reduce((previous, [key, { text }]) => ({ ...previous, [tableColumns[Number(key)]]: text }), {}))
      ),
    [table.data, tableColumns]
  )

  const formik = useFormik({
    initialValues: { dataPointFieldId: '' },

    onSubmit: ({ dataPointFieldId }: _FormValues) =>
      editDataTableDataPointValue({ variables: { dataPointFieldId, dealId: '', documentId, tableColumns, tableRows } })
  })

  const { getFieldProps, handleSubmit, resetForm, values } = formik

  const documentDataTableFieldList = useMemo(() => data?.document_data_table_fields?.edges || [], [data])

  return (
    <>
      <Dialog PaperProps={{ sx: { minWidth: '60vh' } }} onClose={opening.close} open={opening.isOpen} sx={{ zIndex: 6002 }}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Save Data Table</DialogTitle>

          <DialogContent>
            <TextField
              {...getFieldProps('dataPointFieldId')}
              InputLabelProps={{ shrink: true }}
              SelectProps={{ MenuProps: { sx: { zIndex: 6002 } } }}
              disabled={!data}
              fullWidth
              label="Select a data table field"
              select
              sx={{ mt: 1 }}
              variant="outlined"
            >
              {documentDataTableFieldList.map(documentDataTableField => (
                <MenuItem key={documentDataTableField?.node?.id} value={documentDataTableField?.node?.id}>
                  {documentDataTableField?.node?.name}
                </MenuItem>
              ))}
            </TextField>
          </DialogContent>

          <DialogActions>
            <Button color="primary" disabled={isLoading} onClick={opening.close} variant="outlined">
              Cancel
            </Button>

            <Button disabled={isLoading || !values.dataPointFieldId} type="submit" variant="contained">
              {isLoading ? (
                <>
                  <>Saving…</>

                  <ButtonLoading />
                </>
              ) : (
                <>Save</>
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
