import { CustomerEdge } from '../graphql/codegen/schemas'
import { TENANT_GOOGLE_CIR_PROD, TENANT_GOOGLE_CIR_TESTING } from '../utils/tenantUtils'
import useCurrentUser from './useCurrentUser'

// hooks

export const useIsGoogleTenant = (externalSource: string) => {
  const currentUser = useCurrentUser()

  if (!currentUser) return false

  return (
    (currentUser.customers?.edges as CustomerEdge[]).some(edge => [TENANT_GOOGLE_CIR_PROD, TENANT_GOOGLE_CIR_TESTING].includes(edge?.node?.id as string)) &&
    externalSource !== 'CALCULATION'
  )
}
