import { AiOutlineTable } from 'react-icons/ai'
import { IconButton, Tooltip } from '@mui/material'
import { useDocumentPageWrapperContext } from '../DocumentPageWrapper'
import React, { FC } from 'react'
import type { DataPoint } from '../../graphql/codegen/schemas'

// types

type _DataTableIconProps = { dataPoint: DataPoint }

// components

export const DataTableIcon: FC<_DataTableIconProps> = ({ dataPoint }) => {
  const { dataTableMatchingOpening, setActiveTableId } = useDocumentPageWrapperContext()

  return (
    <Tooltip arrow placement="top" title="Open data table">
      <IconButton
        aria-label="Open data table"
        onClick={() => {
          dataTableMatchingOpening.close()

          setActiveTableId(dataPoint.resolved_data_points?.edges?.[0]?.node?.id || dataPoint.id)
        }}
        sx={{ mr: 0.25 }}
      >
        <AiOutlineTable />
      </IconButton>
    </Tooltip>
  )
}
