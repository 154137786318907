import { Alert, Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { CollisionTypeLabels } from '../../RightPanel/RightPanel_components/ChecklistTab/SharedInputs/CollisionTypeInput'
import { Sources } from '../../RightPanel/RightPanel_components/ChecklistTab/CreateInputs/SourceInput'
import { getFieldTypeLabel } from '../../../../../utils/cci'
import { grey } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import { useCciMainContext } from '../../../CCI_Main'
import { useGetChecklistGptOptionsQuery } from '../../../../../app/restApi'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Button from '../../../../../components/Button'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

// types

type _ChecklistGptOptionSelectorProps = {
  modalContent: { actionType: string; fieldName: string; handleData: (key: string, value: any) => void }
}

type _Option = { [key: string]: any }

type _OptionContentProps = { option: _Option }

type _OptionNavigationButtonsProps = {
  handleNavigation: (direction: NavigationDirections) => void
  optionList: _Option[]
  selectedOption: _Option
}

type _RowProps = { children: ReactNode; label: string }

// enums

enum NavigationDirections {
  NEXT,
  PREVIOUS
}

// functions

const removeDuplicates = (items: _Option[]): _Option[] => {
  const uniqueOptions = new Map<string, _Option>()

  for (const item of items) {
    const key = JSON.stringify(item)

    if (!uniqueOptions.has(key)) {
      uniqueOptions.set(key, item)
    }
  }

  return Array.from(uniqueOptions.values())
}

// components

export const ChecklistGptOptionSelector: FC<_ChecklistGptOptionSelectorProps> = ({ modalContent }) => {
  const { actionType, fieldName, handleData } = modalContent
  const { closeModal } = useCciMainContext()

  const { data: checklistGptOptions, isError, isLoading } = useGetChecklistGptOptionsQuery({ action_type: actionType, field_name: fieldName })

  const [selectedOption, setSelectedOption] = useState<_Option | undefined>(undefined)

  const optionList = useMemo(() => {
    const filteredOptions =
      // TODO: Fix and reimplement `options` (currently not propagating correctly to `fieldValues.options` when a `selectedOption` is chosen).
      checklistGptOptions
        ?.filter(option => option.source === Sources.INTERNAL || option.source === Sources.MANUAL)
        .map(({ external_source, extraction_field_type, metadata, options, score, source, ...rest }) => rest) || [] // eslint-disable-line

    return removeDuplicates(filteredOptions)
  }, [checklistGptOptions])

  const hasMultipleOptions = optionList.length > 1

  // functions

  const handleNavigation = useCallback(
    (direction: NavigationDirections) => {
      const currentIndex = optionList.findIndex(option => option === selectedOption)
      let newIndex = currentIndex

      if (direction === NavigationDirections.NEXT && currentIndex < optionList.length - 1) {
        newIndex = currentIndex + 1
      } else if (direction === NavigationDirections.PREVIOUS && currentIndex > 0) {
        newIndex = currentIndex - 1
      }

      setSelectedOption(optionList[newIndex])
    },
    [optionList, selectedOption]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handleNavigation(NavigationDirections.PREVIOUS)
      } else if (event.key === 'ArrowRight') {
        handleNavigation(NavigationDirections.NEXT)
      }
    },
    [handleNavigation]
  )

  const handleSubmit = () => {
    if (!selectedOption) return

    if (selectedOption.source === Sources.INTERNAL) handleData('neutral_tag', selectedOption.name)

    Object.entries(selectedOption).forEach(([key, value]) => {
      if (key === 'options') {
        handleData(
          key,
          value.map((option: string) => ({ option }))
        )
      } else {
        handleData(key, value)
      }
    })

    closeModal()
  }

  // effects

  useEffect(() => {
    setSelectedOption(optionList[0])
  }, [optionList])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  // render

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, width: 636 }}>
      {isError ? (
        <Alert severity="error">An error occurred while fetching recommendations. Please try again later.</Alert>
      ) : isEmpty(optionList) && !isLoading ? (
        <Alert severity="info">No recommendations found for this field.</Alert>
      ) : (
        <>
          {selectedOption && (
            <Box sx={{ display: 'inline-flex', gap: 1, pr: 3 }}>
              <AutoAwesomeIcon color="secondary" sx={{ fontSize: 18, mt: 0.125 }} />

              <Typography variant="body2">
                Here {hasMultipleOptions ? 'are' : 'is'} Klarity AI’s recommendation{hasMultipleOptions ? 's' : ''} for <strong>“{fieldName}”</strong>.{' '}
                {hasMultipleOptions ? 'Please select one that is closest to what you need and modify from there.' : ''}
              </Typography>
            </Box>
          )}

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {hasMultipleOptions && selectedOption && (
              <OptionNavigationButtons handleNavigation={handleNavigation} optionList={optionList} selectedOption={selectedOption} />
            )}

            <Box sx={{ border: `1px solid ${grey[500]}`, borderRadius: 1, display: 'flex', flexDirection: 'column', gap: 2.25, p: 3 }}>
              {selectedOption ? <OptionContent option={selectedOption} /> : <OptionLoader />}
            </Box>
          </Box>
        </>
      )}

      <Box sx={{ display: 'flex', gap: 1, ml: 'auto' }}>
        <Button onClick={closeModal} variant="secondary">
          {isError || (isEmpty(optionList) && !isLoading) ? 'Close' : 'Cancel'}
        </Button>

        {selectedOption && <Button onClick={handleSubmit}>Use this recommendation</Button>}
      </Box>
    </Box>
  )
}

const OptionContent: FC<_OptionContentProps> = ({ option }) => (
  <>
    {option?.name && <Row label="Field Name">{option?.name}</Row>}

    {option?.field_semantics && <Row label="Description">{option?.field_semantics}</Row>}

    {option?.field_type && <Row label="Field Type">{getFieldTypeLabel(option.field_type)}</Row>}

    {!isEmpty(option?.options) && (
      <Row label="Options">
        {option?.options?.map((option: string) => (
          <Typography component="span" key={option} sx={{ display: 'block' }} variant="body2">
            {option}
          </Typography>
        ))}
      </Row>
    )}

    {option?.collision_type && <Row label="Collision Type">{CollisionTypeLabels[option?.collision_type as keyof typeof CollisionTypeLabels]}</Row>}
  </>
)

const OptionLoader: FC = () => (
  <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, m: 'auto', minHeight: '30vh' }}>
    <CircularProgress size={16} sx={{ mt: -3.5 }} />

    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, justifyContent: 'center' }}>
      <Typography variant="body2">Klarity AI is generating recommendations…</Typography>

      <Typography variant="body2">This usually takes a few seconds.</Typography>
    </Box>
  </Box>
)

const OptionNavigationButtons: FC<_OptionNavigationButtonsProps> = ({ handleNavigation, optionList, selectedOption }) => {
  const buttonStyles = { border: `1px solid ${grey[300]}`, borderRadius: 0.5, padding: 0.25 }
  const optionCount = optionList.length
  const selectedIndex = optionList.findIndex(option => option === selectedOption)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
      <Typography sx={{ color: grey[600], fontWeight: 600, mr: 1 }} variant="body2">
        Option {selectedIndex + 1} / {optionCount}
      </Typography>

      <IconButton
        aria-label="previous"
        disabled={selectedIndex === 0}
        onClick={() => handleNavigation(NavigationDirections.PREVIOUS)}
        size="small"
        sx={buttonStyles}
      >
        <ChevronLeftIcon sx={{ fontSize: 18 }} />
      </IconButton>

      <IconButton
        aria-label="next"
        disabled={selectedIndex === optionCount - 1}
        onClick={() => handleNavigation(NavigationDirections.NEXT)}
        size="small"
        sx={buttonStyles}
      >
        <ChevronRightIcon sx={{ fontSize: 18 }} />
      </IconButton>
    </Box>
  )
}

const Row: FC<_RowProps> = ({ children, label }) => (
  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 3fr', gap: 2 }}>
    <Typography sx={{ fontWeight: 700 }} variant="body2">
      {label}
    </Typography>

    <Typography sx={{ opacity: 0.7 }} variant="body2">
      {children}
    </Typography>
  </Box>
)
