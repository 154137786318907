import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { blue } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import { useFeedbackPageContext } from '../../pages/FeedbackPage'
import InfoIcon from '@mui/icons-material/Info'
import React, { FC } from 'react'
import UndoIcon from '@mui/icons-material/Undo'

// components

export const DocumentPanelBanner: FC = () => {
  const { feedbackHighlightList, setFeedbackHighlightList } = useFeedbackPageContext()

  return (
    <Box sx={{ alignItems: 'center', bgcolor: blue[50], display: 'flex', gap: 1.5, px: 2 }}>
      <InfoIcon sx={{ color: blue[500], fontSize: 20 }} />

      <Typography sx={{ py: 2 }} variant="body2">
        Click and drag your cursor to draw a box around any sections of text which have not been correctly extracted.
      </Typography>

      <Tooltip PopperProps={{ sx: { zIndex: 6002 } }} arrow title={isEmpty(feedbackHighlightList) ? '' : 'Undo last drawn box'}>
        <Box sx={{ ml: 'auto' }}>
          <IconButton
            aria-label="Undo last drawn box"
            color="inherit"
            disabled={isEmpty(feedbackHighlightList)}
            onClick={() => setFeedbackHighlightList(previous => previous.slice(0, -1))}
          >
            <UndoIcon sx={{ opacity: 0.7 }} />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  )
}
