import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { FirstLookStatusChip } from '../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistGptTab/VerifiedSamples'
import { Info } from 'react-feather'
import { MoreMenu } from '../MoreMenu'
import { Opening, useOpening } from '@hoologic/use-opening'
import { Source } from '../DatapointField/Source'
import { Z_INDEX_OVERLAY } from '../../utils/styleUtils'
import { common, grey } from '@mui/material/colors'
import { promptSx } from './SystemPromptDialog'
import { sanitizeHtml } from '../DatapointField/DatapointField'
import { useFeedbackPageContext } from '../../pages/FeedbackPage'
import { useLatestVerifiedSampleQuery } from '../../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import { useParseJsonString } from '../../hooks/useParseJsonString'
import InputRow from '../DatapointField/InputRow'
import React, { FC, useMemo } from 'react'
import Tippy from '@tippyjs/react'

// types

type _EventLog = { sample_generation_prompt: { content: string }[] }
type _InspectPromptDialogProps = { dataPointFieldId: string; documentId: string; opening: Opening }

// components

export const FeedbackSampleValue: FC = () => {
  const { dataPoint, latestVerifiedSample } = useFeedbackPageContext()
  const opening = useOpening()
  const { documentId } = useParams<{ documentId: string }>()

  const menuItemList = useMemo(() => [{ label: 'Inspect prompt', onClick: opening.open }], [opening])

  if (!dataPoint) return null

  const { data_point_field: dataPointField } = dataPoint

  if (!dataPointField) return null

  const { description, name } = dataPointField
  const { first_look_status: firstLookStatus } = latestVerifiedSample || {}

  return (
    <>
      <Box sx={{ bgcolor: common.white, display: 'flex', flexDirection: 'column', gap: 2, pb: 4, pt: 3, px: 3 }}>
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 1.5 }}>
          <Typography sx={{ color: grey[800], fontWeight: 600 }} variant="caption">
            Review Sample
          </Typography>

          <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
            <FirstLookStatusChip firstLookStatus={firstLookStatus} />

            <MoreMenu menuItemList={menuItemList} />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
            <Typography sx={{ fontWeight: 900 }} variant="body1">
              {name}
            </Typography>

            {description && (
              <Tippy content={<div dangerouslySetInnerHTML={sanitizeHtml(description)} />} delay={[150, 0]}>
                <Info style={{ height: 14, width: 14 }} />
              </Tippy>
            )}

            <Source {...dataPointField} />
          </Box>

          <InputRow ariaLabel={name} dataPoint={dataPoint} dealIsFinalized={true} />
        </Box>
      </Box>

      <InspectPromptDialog dataPointFieldId={dataPointField.id} documentId={documentId} opening={opening} />
    </>
  )
}

const InspectPromptDialog: FC<_InspectPromptDialogProps> = ({ dataPointFieldId, documentId, opening }) => {
  const { data: latestVerifiedSampleData } = useLatestVerifiedSampleQuery({ variables: { dataPointFieldId, documentId } })
  const eventLog = useParseJsonString<_EventLog>(latestVerifiedSampleData?.latest_verified_sample?.feedback?.feedback_session_events?.edges[0]?.node?.event_log)

  const prompts = useMemo(() => eventLog?.sample_generation_prompt?.map(({ content }) => content) || ['No prompt found.'], [eventLog])

  return (
    <Dialog fullWidth maxWidth="sm" onClose={opening.close} open={opening.isOpen} sx={{ zIndex: Z_INDEX_OVERLAY }}>
      <DialogTitle>Inspect Prompt</DialogTitle>

      <DialogContent>
        {prompts.map((prompt, index) => (
          <Typography component="code" key={index} sx={promptSx} variant="caption">
            {prompt}
          </Typography>
        ))}
      </DialogContent>

      <DialogActions>
        <Button onClick={opening.close} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
