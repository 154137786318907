import { Box } from '@mui/material'
import { EnhancedTextFieldButtons, ExpandButton, ScrollDownArrow, ScrollUpArrow } from '../DatapointInput/EnhancedTextField/EnhancedTextField'
import { KLARITY_BLUE } from '../../utils/styleUtils'
import { RiPushpin2Line } from 'react-icons/ri'
import { captureError } from '../../utils/sentry'
import { formatCommentText } from '../../utils/commentUtils'
import { formatTimeAgo } from '../../utils/datetimeUtils'
import { useScrollability } from '../../hooks/useScrollability'
import { useUsersQuery } from '../../graphql/codegen/hooks'
import CommentBox from '../CommentBox'
import DOMPurify from 'dompurify'
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import WithTooltip from '../WithTooltip'
import clsx from 'clsx'
import css from './style.module.scss'
import useCurrentUser from '../../hooks/useCurrentUser'

// types

interface Props {
  author?: any
  comment?: any
  date: string
  editState?: any
  handleSave?: (x: any) => void
  id: string
  isDisabled?: boolean
  isEditing?: boolean
  isNestedChildDataPoint?: boolean
  renderActions?: (commentId: string) => ReactNode
  renderTopRow?: (author?: string, timeAgo?: string) => ReactNode
  setIsEditing?: (x: boolean) => void
  text: string
}

// constants

const COMMENT_MAX_HEIGHT = 150

// components

export default function Comment({
  author,
  comment,
  date,
  editState,
  handleSave,
  id,
  isDisabled,
  isEditing,
  isNestedChildDataPoint,
  renderActions,
  renderTopRow,
  setIsEditing,
  text
}: Props) {
  const currentUser = useCurrentUser()
  const { element, isScrollableDown, isScrollableUp, refCallback } = useScrollability()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [isScrollable, setIsScrollable] = useState(false)
  const { data, loading: usersLoading } = useUsersQuery()

  useMemo(() => {
    if (isScrollableDown || isScrollableUp) {
      setIsScrollable(true)
    }
  }, [isScrollableDown, isScrollableUp])

  const handleClick = useCallback(() => {
    if (element) {
      element.style.maxHeight = window.getComputedStyle(element).getPropertyValue('max-height') === 'none' ? `${COMMENT_MAX_HEIGHT}px` : 'none'

      setIsExpanded(previous => !previous)
    }
  }, [element])

  const users = data?.users?.map((node: any) => ({ id: node.id, display: node.user_name }))

  const _renderTopRow = () => {
    const timeAgo = date && formatTimeAgo(date)
    if (renderTopRow) {
      return renderTopRow(author, timeAgo)
    }
    const isMine = currentUser?.id === comment?.created_by?.id

    let pinnedBy = ''
    let timeAgoMessage = ''
    let contentMessage = ''

    if (comment?.is_pinned) {
      pinnedBy = isMine ? 'You' : comment?.pinned_by?.first_name || false
      timeAgoMessage = comment?.pinned_at ? `${formatTimeAgo(comment?.pinned_at)} ago` : ''
      contentMessage = pinnedBy ? `Pinned by ${pinnedBy} ${timeAgoMessage}` : `Pinned ${timeAgoMessage}`
    }

    return (
      <>
        {comment?.is_pinned && (
          <WithTooltip content={contentMessage}>
            <div className={css.icon}>
              <RiPushpin2Line />
            </div>
          </WithTooltip>
        )}
        <div>{`${author} ${timeAgo && `${timeAgo} ago`}`}</div>
      </>
    )
  }

  const _renderActions = () => {
    if (renderActions && !isEditing) {
      return renderActions(id)
    } else {
      return null
    }
  }

  const _renderCommentBody = (isScrollableDown: boolean, isScrollableUp: boolean) => {
    if (usersLoading) {
      return <Skeleton />
    }
    if (!users) {
      return null
    }
    let sanitizedLines = ''
    try {
      // All comment text is escaped before save on the backend, but we use DOMPurify to be safe.
      const sanitizedCommentText = DOMPurify.sanitize(formatCommentText(text as string, users))
      sanitizedLines = sanitizedCommentText.split(/\n/).join('<br/>')
    } catch (error) {
      captureError(error)
    }

    if (isEditing && handleSave) {
      return (
        <CommentBox autofocus commentState={editState} handleCancel={() => setIsEditing && setIsEditing(false)} handleCreate={handleSave} initialValue={text} />
      )
    }

    return (
      <Box
        onMouseLeave={() => setIsHovered(false)}
        onMouseOver={() => setIsHovered(true)}
        sx={{
          backgroundColor: 'white',
          border: '1px dashed lightgrey',
          borderRadius: 1,
          p: 1.5,
          position: 'relative'
        }}
      >
        <Box
          ref={refCallback}
          sx={{
            fontSize: 14,
            maxHeight: COMMENT_MAX_HEIGHT,
            overflowWrap: 'break-word',
            overflowY: 'auto',
            '& > div > span': { color: KLARITY_BLUE }
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: sanitizedLines }} tabIndex={isScrollable ? 0 : -1} />

          {isHovered && isScrollable && (
            <EnhancedTextFieldButtons>
              <ExpandButton iconButtonProps={{ tabIndex: 0 }} isCollapseIcon={isExpanded} onClick={handleClick} />
            </EnhancedTextFieldButtons>
          )}

          {isScrollableDown && <ScrollDownArrow />}

          {isScrollableUp && <ScrollUpArrow />}
        </Box>
      </Box>
    )
  }

  return (
    <div className={clsx(css.comment, isDisabled && css.disabled, isNestedChildDataPoint && css.nestedChildDataPoint)}>
      <div className={css.topRow}>
        {_renderTopRow()}

        <div className={css.commentActions}>{_renderActions()}</div>
      </div>

      {_renderCommentBody(isScrollableDown, isScrollableUp)}
    </div>
  )
}
