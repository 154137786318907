import { formatFieldList } from '../utils/fieldUtils'
import { useDealsDashboardFieldsQuery } from '../graphql/codegen/hooks'
import useGetExcludedFields from './useGetExcludedFields'

export default () => {
  const { data, loading } = useDealsDashboardFieldsQuery()
  const fieldsToExclude = useGetExcludedFields('Deals', 'Search')

  return { loading, fields: data && formatFieldList(data, fieldsToExclude) }
}
