import { ModalOptions, useCciMainContext } from '../../../../CCI_Main'
import Button from '../../../../../../components/Button'
import CCI_Dropdown from '../CCI_Inputs/CCI_Dropdown'
import React, { useEffect, useState } from 'react'
import TextInput from '../CCI_Inputs/TextInput'
import WithTooltip from '../../../../../../components/WithTooltip'
// eslint-disable-next-line
import css from './style.module.scss';


export default function CreateIntegration() {
  const { activeComponent, openModal, tab } = useCciMainContext()
  const [values, setValues] = useState<any>(undefined)
  const [isDisabled, setIsDisabled] = useState(false)
  const [disabledMessage, setDisabledMessage] = useState<any>(undefined)

  // wipe values on tab or component change
  useEffect(() => {
    setValues(undefined)
    // eslint-disable-next-line
    }, [tab, activeComponent])

  const handleData = (type: string, value: any) => {
    // console.info('handleData firing, type:', type, 'value:', value)
    if (type && !value && value !== false) {
      setValues((prev: any) => {
        const updatedObject = { ...prev }
        delete updatedObject[type]
        return { ...updatedObject }
      })
    } else {
      switch (type) {
        case '':
        case undefined:
        case null:
          console.error(`CreateIntegration handleData error: Must provide a valid option`)
          return
        default:
          return setValues((prev: any) => {
            return { ...prev, [type]: value }
          })
      }
    }
  }

  // this capitalizes the first letter of a string and lowercases the rest
  const formatLabel = (string: string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
  }

  // Any change to values checks if required values are present and enables/disables the submit button
  useEffect(() => {
    validateInputs(values, setIsDisabled, setDisabledMessage)
  }, [values])

  return (
    <>
      <div>
        <h3 style={{ textAlign: 'center', marginBottom: '24px' }}>{`Create Integration`}</h3>

        {/* integration_type can be SALESFORCE or IRONCLAD */}
        <CCI_Dropdown
          displayName={'Integration Type:'}
          handleData={handleData}
          options={[
            { value: 'SALESFORCE', label: 'Salesforce' },
            { value: 'IRONCLAD', label: 'Ironclad' }
          ]}
          placeholder={`Select Integration type`}
          value={values?.integration_type ? { value: values.integration_type, label: formatLabel(values.integration_type) } : undefined}
          variableName={'integration_type'}
        />

        {/* token_type can be BEARER, ACCESS, or SECURITY */}
        {/* <TextInput  value={values?.token_type} /> */}
        <CCI_Dropdown
          displayName={'Token Type:'}
          handleData={handleData}
          options={[
            { value: 'BEARER', label: 'Bearer' },
            { value: 'ACCESS', label: 'Access' },
            { value: 'SECURITY', label: 'Security' }
          ]}
          placeholder={`Select Token type`}
          value={values?.token_type ? { value: values.token_type, label: formatLabel(values.token_type) } : undefined}
          variableName={'token_type'}
        />

        <TextInput displayName={'Token:'} handleData={handleData} placeholder={`Enter token`} value={values?.token} variableName={'token'} />
        <TextInput displayName={'API URL:'} handleData={handleData} placeholder={`Enter api_url`} value={values?.api_url} variableName={'api_url'} />
        <TextInput displayName={'Username:'} handleData={handleData} placeholder={`Enter user_name`} value={values?.user_name} variableName={'user_name'} />
        <TextInput displayName={'Password:'} handleData={handleData} placeholder={`Enter password`} value={values?.password} variableName={'password'} />
        <TextInput displayName={'Domain:'} handleData={handleData} placeholder={`Enter domain`} value={values?.domain} variableName={'domain'} />
        <TextInput
          displayName={'Organization Id:'}
          handleData={handleData}
          placeholder={`Enter organization_id`}
          value={values?.organization_id}
          variableName={'organization_id'}
        />
      </div>
      <div className={css.modalButtonRow}>
        {/* <Button onClick={()=>{console.info('values', values)}} variant={'secondary'}>Console log values</Button> */}
        <WithTooltip content={disabledMessage}>
          <Button disabled={isDisabled} onClick={() => openModal({ content: { values }, modalOption: ModalOptions.REVIEW_NEW_INTEGRATION })}>
            {`Review New Integration`}
          </Button>
        </WithTooltip>
      </div>
    </>
  )
}

const validateInputs = (values?: any, setIsDisabled?: any, setDisabledMessage?: any) => {
  const errors: any[] = []

  // integration_type can be SALESFORCE or IRONCLAD
  if (!values?.integration_type) {
    errors.push(`Integration Type`)
  } else if (values?.integration_type !== 'SALESFORCE' && values?.integration_type !== 'IRONCLAD') {
    errors.push(`Invalid Integration Type`)
  }

  // token_type can be BEARER, ACCESS, or SECURITY
  if (!values?.token_type) {
    errors.push(`Token Type`)
  } else if (values?.token_type !== 'BEARER' && values?.token_type !== 'ACCESS' && values?.token_type !== 'SECURITY') {
    errors.push(`Invalid Token Type`)
  }

  if (!values?.token) {
    errors.push(`Token`)
  }
  if (!values?.api_url) {
    errors.push(`API URL`)
  }
  if (!values?.user_name) {
    errors.push(`Username`)
  }
  if (!values?.password) {
    errors.push(`Password`)
  }

  if (!values?.domain && !values?.organization_id) {
    errors.push(`Either Domain or Organization Id is required`)
  }

  if (errors.length > 0) {
    setIsDisabled(true)
    setDisabledMessage(
      <>
        {`Required Values are missing:`}
        {errors.map((error?: string) => {
          return <div key={error}>{error}</div>
        })}
      </>
    )
  } else {
    setIsDisabled(false)
    setDisabledMessage(undefined)
  }
}
