import { Box, TextField, Typography } from '@mui/material'
import { FiXSquare } from 'react-icons/fi'
import { debounce } from 'lodash'
import Button from '../../../../../../../components/Button'
import Loader from '../../../../../../../components/Loader'
import React, { ChangeEvent, Dispatch, FC, KeyboardEvent, SetStateAction, useState } from 'react'
import css from './style.module.scss'

// types

type _SampleDealUrlInputProps = { errorMessage?: string; handleSubmit: Dispatch<SetStateAction<string>>; isLoading?: boolean }

// components

export const SampleDealUrlInput: FC<_SampleDealUrlInputProps> = ({ errorMessage = '', handleSubmit, isLoading = false }) => {
  const [value, setValue] = useState('')

  const submitDebouncedValue = debounce((value: string) => handleSubmit(value), 1250)

  const clearValue = () => {
    setValue('')

    handleSubmit('')
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value)

    submitDebouncedValue(event.currentTarget.value)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      ;(event.target as HTMLInputElement).blur()

      handleSubmit(value)
    }
  }

  return (
    <>
      <div className={css.inputWrapper}>
        <Typography component="label" htmlFor="sample-deal-url-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
          Sample Deal URL
        </Typography>

        <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            InputProps={{ sx: { fontSize: 14 } }}
            autoComplete="off"
            disabled={isLoading}
            error={Boolean(errorMessage)}
            fullWidth
            id="sample-deal-url-input"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Enter a sample deal URL to begin extraction configuration"
            size="small"
            value={value}
            variant="outlined"
          />

          {value && <Button className={css.cciButton} disabled={isLoading} icon={<FiXSquare />} onClick={clearValue} />}
        </Box>
      </div>

      <Box sx={{ lineHeight: 1, ml: 22.5 }}>
        {errorMessage && (
          <Typography color="error" sx={{ display: 'inline-block', lineHeight: 1.5 }} variant="caption">
            {errorMessage}
          </Typography>
        )}

        {isLoading && (
          <Box sx={{ display: 'flex', gap: 1, mt: 0.5, width: 'fit-content' }}>
            <Loader size="s" />

            <Typography variant="caption">Processing…</Typography>
          </Box>
        )}
      </Box>
    </>
  )
}
