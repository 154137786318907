import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Tooltip, Typography } from '@mui/material'
import { Accordions, useCciMainContext } from '../../../../../../CCI_Main'
import { ExtractionPathSelector, _ExtractionPathOption } from '../../EditInputs/ExtractionPathSelector'
import { NEXT_JS_API_DATA_MAPPING } from '../../../../../../../../utils/apiUtils'
import { SampleDealUrlInput } from '../../EditInputs/SampleDealUrlInput'
import { captureError } from '../../../../../../../../utils/sentry'
import { getIdToken } from '../../../../../../../../utils/sessionApiUtils'
import { useDealExternalMetadataLazyQuery } from '../../../../../../../../graphql/codegen/hooks'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'

// types

type _ExternalExtractionConfigurationAccordionProps = { handleData: (key: string, value: any) => void; isChecklistGpt?: boolean }

// constants

const CHECKLIST_GPT_SX = {
  border: '1px solid #e0e0e0',
  borderRadius: 1,
  boxShadow: 'none',
  my: 4,
  '.MuiAccordionSummary-root': { pointerEvents: 'auto' },
  '.MuiCollapse-wrapper': { borderTop: '1px solid #e0e0e0' },
  '&:before': { opacity: 0 }
}

const DEFAULT_SX = { borderBottom: '1px solid #e0e0e0', boxShadow: 'none', m: '0 -20px', '&.Mui-expanded:before': { opacity: 1 } }

// functions

const extractDealId = (url: string): string | null => {
  const pattern = /\/deals\/([^/?#]+)/

  const match = url.match(pattern)

  return match ? match[1] : null
}

// Adds a zero-width space after each part of the extraction path to allow line breaks to occur between parts.
export const formatDisplayedExtractionPath = (extractionPath: string) => {
  const parts = extractionPath.split('.')

  return parts.map((part, index) => (
    <Fragment key={index}>
      {part}
      {index < parts.length - 1 && (
        <>
          <span>.</span>&#8203;
        </>
      )}
    </Fragment>
  ))
}

// components

export const ExternalExtractionConfigurationAccordion: FC<_ExternalExtractionConfigurationAccordionProps> = ({ handleData, isChecklistGpt }) => {
  const { expandedAccordionMap, selectedItem, toggleAccordion } = useCciMainContext()

  const [areSuggestedExtractionPathsLoading, setAreSuggestedExtractionPathsLoading] = useState(false)
  const [externalMetadata, setExternalMetadata] = useState<object | null>(null)
  const [sampleDealUrl, setSampleDealUrl] = useState('')
  const [suggestedExtractionPaths, setSuggestedExtractionPaths] = useState<string[] | null>(null)
  const [suggestedExtractionPathsError, setSuggestedExtractionPathsError] = useState<Error | null>(null)
  const [urlInputErrorMessage, setUrlInputErrorMessage] = useState('')

  const [getDealExternalMetadata, { loading: isDealExternalMetadataLoading }] = useDealExternalMetadataLazyQuery({
    onCompleted: data => {
      const { external_metadata: externalMetadataString } = data?.deals?.edges[0]?.node || {}

      if (!externalMetadataString || externalMetadataString === '{}') {
        setUrlInputErrorMessage('No external metadata found for this deal.')
      } else {
        try {
          setExternalMetadata(JSON.parse(externalMetadataString))

          getSuggestedExtractionPaths()
        } catch (error) {
          captureError(error, 'Error parsing external metadata')
        }
      }
    },
    onError: error => {
      captureError(error, 'Error fetching external metadata')

      setUrlInputErrorMessage(error.message)
    }
  })

  const dealId = useMemo(() => extractDealId(sampleDealUrl), [sampleDealUrl])

  const extractionLogic = useMemo(() => {
    const { extraction_logic } = selectedItem

    if (!extraction_logic || extraction_logic === '{}') return

    try {
      return JSON.parse(extraction_logic)
    } catch (error) {
      captureError(error, 'Error parsing extraction logic')
    }
  }, [selectedItem])

  const isLoading = useMemo(
    () => areSuggestedExtractionPathsLoading || isDealExternalMetadataLoading,
    [areSuggestedExtractionPathsLoading, isDealExternalMetadataLoading]
  )

  // functions

  const getSuggestedExtractionPaths = useCallback(async () => {
    if (!dealId) return

    setAreSuggestedExtractionPathsLoading(true)
    setSuggestedExtractionPaths(null)
    setSuggestedExtractionPathsError(null)

    try {
      const idToken = await getIdToken()

      const response = await fetch(`${NEXT_JS_API_DATA_MAPPING}?dataPointFieldName=${selectedItem.name}&dealId=${dealId}`, {
        headers: { Authorization: `Bearer ${btoa(`${idToken}__||__${localStorage.getItem('customerId')}`)}` }
      })

      const { data, error, status } = await response.json()

      if (!response.ok) throw new Error(error || status || response.statusText || 'Error fetching suggested extraction paths.')

      const parsedData = JSON.parse(data)

      // Handle cases where the API erroneously returns a single suggestion as a `string` instead of a `string[]`.
      setSuggestedExtractionPaths(typeof parsedData === 'string' ? [parsedData] : parsedData)
    } catch (error) {
      captureError(error)

      setSuggestedExtractionPathsError(error as Error)
    } finally {
      setAreSuggestedExtractionPathsLoading(false)
    }
  }, [dealId, selectedItem.name])

  const handleExtractionPathSelection = useCallback(
    (selectedOption?: _ExtractionPathOption) => {
      if (selectedOption && extractionLogic?.selector?.split('external_metadata.')[1] !== selectedOption.value) {
        handleData('deal_id', dealId)
        handleData('extraction_logic_path', selectedOption.value)
        handleData('extraction_path_selection_type', selectedOption.type)
      } else {
        resetExtractionPathValues()
      }
    },
    [dealId] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const resetExtractionPathValues = useCallback(() => {
    handleData('deal_id', undefined)
    handleData('extraction_logic_path', undefined)
    handleData('extraction_path_selection_type', undefined)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // effects

  useEffect(() => {
    resetExtractionPathValues()
    setExternalMetadata(null)
    setSuggestedExtractionPaths(null)
    setUrlInputErrorMessage('')

    if (dealId) {
      getDealExternalMetadata({ variables: { id: dealId } })
    }
  }, [dealId, getDealExternalMetadata, resetExtractionPathValues])

  useEffect(() => {
    if (sampleDealUrl && !dealId) {
      setUrlInputErrorMessage('Please enter a valid deal URL.')
    }
  }, [dealId, sampleDealUrl])

  // render

  return (
    <Accordion
      disableGutters
      expanded={Boolean(expandedAccordionMap[Accordions.EXTERNAL_EXTRACTION_CONFIGURATION])}
      onChange={() => toggleAccordion(Accordions.EXTERNAL_EXTRACTION_CONFIGURATION)}
      sx={isChecklistGpt ? CHECKLIST_GPT_SX : DEFAULT_SX}
    >
      <AccordionSummary aria-controls="external-extraction-configuration-content" expandIcon={<ExpandMoreIcon />} id="external-extraction-configuration-header">
        <Typography sx={{ p: 0.5 }} variant="h4">
          Extraction Configuration
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ pb: 2.5, pt: isChecklistGpt ? 1.5 : 0, px: 2.5 }}>
        {extractionLogic ? (
          <Box sx={{ alignItems: 'center', display: 'flex', minHeight: 38, mb: 1 }}>
            <Typography sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
              Current Extraction Path
            </Typography>

            <Typography sx={{ maxWidth: 505, py: 1 }} variant="body2">
              {extractionLogic.selector ? (
                <>{formatDisplayedExtractionPath(extractionLogic.selector?.split('external_metadata.')[1])}</> // Remove the `external_metadata.` prefix from the extraction path.
              ) : (
                <>
                  Extraction path set
                  <Tooltip
                    arrow
                    placement="right"
                    sx={{ ml: 0.5, position: 'absolute' }}
                    title="This field’s extraction path was previously configured in Klarity using a legacy method. The extraction path cannot be displayed here, but it is still in effect."
                    // NOTE: Legacy extraction paths were configured in MiniApps prior to the introduction of the `extract_arbitrary_metadata_with_selector()` extraction logic function.
                  >
                    <InfoIcon color="info" fontSize="small" />
                  </Tooltip>
                </>
              )}
            </Typography>
          </Box>
        ) : (
          <Alert severity="info" sx={{ mb: 3 }}>
            Configure the extraction path here to ensure field values are populated properly for all deals.
          </Alert>
        )}

        <SampleDealUrlInput errorMessage={urlInputErrorMessage} handleSubmit={setSampleDealUrl} isLoading={isLoading} />

        {externalMetadata && !isLoading && !urlInputErrorMessage && (
          <Fragment key={dealId}>
            <ExtractionPathSelector
              externalMetadata={externalMetadata}
              handleSelection={handleExtractionPathSelection}
              networkErrorMessage={suggestedExtractionPathsError?.message}
              suggestedExtractionPaths={suggestedExtractionPaths}
            />

            <Button color="tertiary" onClick={getSuggestedExtractionPaths} size="small" sx={{ ml: 22.5, mt: 2.5 }} variant="outlined">
              Rerun auto suggestion
            </Button>
          </Fragment>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
