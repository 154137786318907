import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { MoreMenu, _MenuItem } from '../MoreMenu'
import { NavLink } from 'react-router-dom'
import { Typography } from '@mui/material'
import { logOut } from '../../utils/sessionApiUtils'
import React, { useMemo } from 'react'
import css from './style.module.scss'
import logo from '../../assets/logo.png'
import useCurrentUser from '../../hooks/useCurrentUser'

// constants

const IMAGES = require.context('../../assets/customer-logos/', false, /\.(png|jpe?g|svg)$/)

const LOGO_MAP = IMAGES.keys().reduce((previous: { [k: string]: any }, current) => {
  const companyName = current.match(/.\/(.+)\.(png|jpe?g|svg)$/)?.[1]

  return companyName ? { ...previous, [companyName]: IMAGES(current) } : previous
}, {})

// components

export const Navbar = ({ pathname }: { pathname: string }) => {
  const currentUser = useCurrentUser()
  const hasAnalyticsAccess = useUserAccess({ feature: Features.ANALYTICS, permission: Permissions.READ })
  const hasCCIAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.READ })
  const currentCustomerId = localStorage.getItem('customerId')
  const currentCustomerName = currentUser?.customers?.edges?.find(customerEdge => customerEdge?.node?.id === currentCustomerId)?.node?.name
  const customerLogoKey = currentCustomerName && currentCustomerName.toLowerCase().split(' ').join('_')
  const customerLogo = customerLogoKey && LOGO_MAP[customerLogoKey]

  const menuItemList: _MenuItem[] = useMemo(() => [{ label: 'Log out', onClick: logOut }], [])

  return (
    <>
      {currentUser?.is_super_admin && (
        <div className={css.superAdminHeader}>
          <Typography component="span" fontWeight={600} variant="body2">
            Tenant:{' '}
          </Typography>

          <Typography component="span" variant="body2">
            {currentCustomerName}
          </Typography>
        </div>
      )}

      <div className={css.nav} id="nav">
        <ul className={css.navMenu}>
          <li>
            <img alt="Klarity Logo" className={css.logo} src={logo} />
          </li>

          <li>
            {currentUser ? (
              <NavLink
                activeClassName={css.active}
                className={css.navLink}
                exact
                isActive={() => pathname === '/' || pathname.indexOf('/dash') === 0}
                to="/"
              >{`Dashboard`}</NavLink>
            ) : (
              <NavLink className={css.navLink} exact to="/login">{`Login`}</NavLink>
            )}
          </li>

          {hasAnalyticsAccess && (
            <li>
              <NavLink activeClassName={css.active} className={css.navLink} to="/analytics">
                Analytics
              </NavLink>
            </li>
          )}

          {hasCCIAccess && (
            <li>
              <NavLink activeClassName={css.active} className={css.navLink} to="/config/">{`Configuration`}</NavLink>
            </li>
          )}

          {currentUser?.is_super_admin && (
            <li>
              <NavLink activeClassName={css.active} className={css.navLink} to="/tenants">
                Tenants
              </NavLink>
            </li>
          )}
        </ul>

        <ul className={css.navMenu}>
          {currentUser && (
            <li>
              <MoreMenu label={currentUser.first_name as string} menuItemList={menuItemList} />
            </li>
          )}

          {customerLogo && (
            <li>
              <img className={css.customerLogo} src={customerLogo} />
            </li>
          )}
        </ul>
      </div>
    </>
  )
}
