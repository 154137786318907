import { Box, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material'
import { DEFAULT_PANEL_WIDTH, DEFAULT_PANEL_WIDTH_FOR_SMALL_DEVICES } from '../DocumentPageWrapper'
import { DocumentPanel, SystemPromptDialog } from '.'
import { FeedbackPageHeader } from './FeedbackPageHeader'
import { FeedbackPanel } from './FeedbackPanel'
import { Toast } from '../Toast'
import { Z_INDEX_1, Z_INDEX_MODAL } from '../../utils/styleUtils'
import { useFeedbackPageContext } from '../../pages/FeedbackPage'
import Helmet from 'react-helmet'
import React, { FC, useEffect, useMemo, useState } from 'react'
import SplitPane from 'react-split-pane'
import css from '../DocumentPageWrapper/style.module.scss'
import useWindowSize from '../../utils/useWindowSize'

// components

export const FeedbackDocumentPageWrapper: FC = () => {
  const { dataPoint, document, isPublishMutationPending, isPublishingInProgress } = useFeedbackPageContext()
  const [toastMessage, setToastMessage] = useState('')
  const name = document?.alias || document?.name
  const windowSize = useWindowSize()

  const defaultSize = useMemo(() => {
    const currentWindowWidth = windowSize?.width
    const isSmallWidthScreen = currentWindowWidth <= 1450

    return isSmallWidthScreen && currentWindowWidth !== DEFAULT_PANEL_WIDTH_FOR_SMALL_DEVICES ? DEFAULT_PANEL_WIDTH_FOR_SMALL_DEVICES : DEFAULT_PANEL_WIDTH
  }, [windowSize])

  useEffect(() => {
    setToastMessage(isPublishingInProgress ? `Publishing of “${dataPoint?.data_point_field?.name}” is in progress…` : '')
  }, [dataPoint?.data_point_field?.name, isPublishingInProgress])

  return (
    <>
      {name && (
        <Helmet>
          <title>Feedback | {name} | Klarity</title>
        </Helmet>
      )}

      <main className={css.documentPage} style={{ zIndex: Z_INDEX_1 }}>
        <h1 className={css.screenReaderOnly}>Feedback – Document – {name}</h1>

        <FeedbackPageHeader />

        <div className={css.documentPageInner}>
          <SplitPane
            defaultSize={defaultSize}
            minSize={DEFAULT_PANEL_WIDTH_FOR_SMALL_DEVICES}
            onChange={size => localStorage.setItem('documentPagePanelWidth', String(size))}
            pane1Style={{ zIndex: 1003 }}
            pane2Style={{ overflowX: 'auto' }}
          >
            <FeedbackPanel />

            <DocumentPanel />
          </SplitPane>
        </div>
      </main>

      <SystemPromptDialog />

      <Dialog disableEscapeKeyDown open={isPublishMutationPending} sx={{ zIndex: Z_INDEX_MODAL }}>
        <DialogContent sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: 3, p: 6 }}>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
            <CircularProgress size={16} />

            <Typography variant="body2">Preparing “{dataPoint?.data_point_field?.name}” for publishing…</Typography>
          </Box>

          <Typography variant="body2">This may take a few moments. Please do not refresh the page.</Typography>
        </DialogContent>
      </Dialog>

      <Toast message={toastMessage} setMessage={setToastMessage} />
    </>
  )
}
