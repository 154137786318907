import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import { DataPoint } from '../../../graphql/codegen/schemas'
import { EditDataPointValueMutation } from '../../../graphql/codegen/operations'
import { MutationResult } from '@apollo/client'
import { NestedChildDataPointField } from '../../DatapointField/NestedChildDataPointField'
import { RESIZABLE_MINIMUM_HEIGHT, Resizable } from '../../Resizable'
import { Z_INDEX_MODAL } from '../../../utils/styleUtils'
import { useAutoScrollOnTabKeyPress } from '../../../hooks/useAutoScrollOnTabKeyPress'
import { useContextSilentFailInit } from '../../../hooks/useContextInit'
import { useDocumentPageWrapperContext } from '../../DocumentPageWrapper'
import { useNestedChildDataPointsQuery } from '../../../graphql/codegen/hooks'
import CloseIcon from '@mui/icons-material/Close'
import Loader from '../../Loader/Loader'
import React, { FC, createContext, useEffect, useMemo, useRef, useState } from 'react'
import SaveProgressIcon from '../../DatapointField/SaveProgressIcon'

// types

type _DataPointsGridProps = { dataPoints: DataPoint[]; dealIsFinalized: boolean; group: string }

type _ExpandedNestedChildPanelProps = { dealIsFinalized: boolean }

type _NestedChildDataPointsPanelContext = {
  expandedNestedChildContentRef: React.RefObject<HTMLDivElement> | null
  expandedNestedChildDataPoint: DataPoint | null
  expandedNestedChildDataPointMutationState: MutationResult<EditDataPointValueMutation> | null
  setExpandedNestedChildDataPoint: (dataPoint: DataPoint | null) => void
  setExpandedNestedChildDataPointMutationState: (mutationState: MutationResult<EditDataPointValueMutation> | null) => void
}

type _NestedChildDataPointsPanelProps = { activeDocumentId?: string | null; dealIsFinalized: boolean; parentDataPoint: DataPoint }

type _PanelHeaderProps = { fieldName?: string; onClose: () => void }

// constants

const LOCAL_STORAGE_HEIGHT_KEY = 'nestedChildDataPointsPanelHeight'

// context

const NestedChildDataPointsPanelContext = createContext<_NestedChildDataPointsPanelContext | null>(null)

// functions

const getSortedGroups = (dataPoints: DataPoint[]): { group: string; priority: number }[] => {
  const groups: Record<string, number> = {}

  dataPoints.forEach(dataPoint => {
    const group = dataPoint.data_point_field?.group || ''
    const priority = dataPoint.data_point_field?.group_priority || 0

    if (!groups[group]) {
      groups[group] = priority
    }
  })

  return Object.entries(groups)
    .map(([group, priority]) => ({ group, priority }))
    .sort((a, b) => a.priority - b.priority)
}

const sortDataPointsByPriority = (dataPoints: DataPoint[]): DataPoint[] =>
  [...dataPoints].sort((a, b) => (a.data_point_field!.priority > b.data_point_field!.priority ? 1 : -1))

// hooks

export const useNestedChildDataPointsPanelContext = () => useContextSilentFailInit(NestedChildDataPointsPanelContext)

// components

export const NestedChildDataPointsPanel: FC<_NestedChildDataPointsPanelProps> = ({ activeDocumentId, dealIsFinalized, parentDataPoint }) => {
  const { data_point_field } = parentDataPoint
  const [expandedNestedChildDataPoint, setExpandedNestedChildDataPoint] = useState<DataPoint | null>(null)
  const [expandedNestedChildDataPointMutationState, setExpandedNestedChildDataPointMutationState] = useState<MutationResult<EditDataPointValueMutation> | null>(
    null
  )
  const expandedNestedChildContentRef = useRef<HTMLDivElement>(null)
  const { setActiveParentDataPoint } = useDocumentPageWrapperContext()

  const localStorageHeight = Number(localStorage.getItem(LOCAL_STORAGE_HEIGHT_KEY))
  const initialHeight = localStorageHeight > RESIZABLE_MINIMUM_HEIGHT ? localStorageHeight : RESIZABLE_MINIMUM_HEIGHT
  const [height, setHeight] = useState(initialHeight)

  const { data, loading: isLoading } = useNestedChildDataPointsQuery({ variables: { parentDataPointId: parentDataPoint.id } })

  const dataPoints = useMemo(() => {
    const nestedChildDataPoints = data?.nested_children_data_points || []

    return sortDataPointsByPriority(nestedChildDataPoints.filter(Boolean) as DataPoint[])
  }, [data])

  const sortedGroups = useMemo(() => getSortedGroups(dataPoints), [dataPoints])

  const gridContainerRef = useRef<HTMLDivElement>(null)

  const context = useMemo(
    () => ({
      expandedNestedChildContentRef,
      expandedNestedChildDataPoint,
      expandedNestedChildDataPointMutationState,
      setExpandedNestedChildDataPoint,
      setExpandedNestedChildDataPointMutationState
    }),
    [expandedNestedChildContentRef, expandedNestedChildDataPoint, expandedNestedChildDataPointMutationState]
  )

  // effects

  useAutoScrollOnTabKeyPress({ containerRef: gridContainerRef, elementSelector: '[data-id="nestedChildDataPointField"]' })

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_HEIGHT_KEY, height.toString())
  }, [height])

  // render

  return (
    <NestedChildDataPointsPanelContext.Provider value={context}>
      <Resizable height={height} setHeight={setHeight}>
        <Box sx={{ height, mb: !activeDocumentId || activeDocumentId === 'upload' ? 6 : 0 }}>
          <PanelHeader fieldName={data_point_field?.name} onClose={() => setActiveParentDataPoint(null)} />

          {isLoading ? (
            <Loader />
          ) : (
            <Box ref={gridContainerRef} sx={{ overflowY: expandedNestedChildDataPoint ? 'clip' : 'scroll' }}>
              {sortedGroups.map(({ group }, index) => (
                <DataPointsGrid
                  dataPoints={dataPoints.filter(dataPoint => (dataPoint.data_point_field?.group || '') === group)}
                  dealIsFinalized={dealIsFinalized}
                  group={group}
                  key={group + index}
                />
              ))}
            </Box>
          )}

          <ExpandedNestedChildPanel dealIsFinalized={dealIsFinalized} />
        </Box>
      </Resizable>
    </NestedChildDataPointsPanelContext.Provider>
  )
}

const DataPointsGrid: FC<_DataPointsGridProps> = ({ dataPoints, dealIsFinalized, group }) => (
  <>
    <Typography sx={{ fontSize: 14, fontWeight: 600, mt: 4, mb: 2, pl: 5 }} variant="h6">
      {group}
    </Typography>

    <Box
      component="ul"
      sx={{
        display: 'grid',
        gap: 0,
        gridTemplateColumns: 'repeat(auto-fit, minmax(325px, 1fr))',
        justifyContent: 'center',
        maxWidth: '100%',
        mb: 4,
        mt: 2,
        px: 4
      }}
    >
      {dataPoints.map(dataPoint => (
        <NestedChildDataPointField dataPoint={dataPoint} dealIsFinalized={dealIsFinalized} key={dataPoint.id} />
      ))}
    </Box>
  </>
)

const ExpandedNestedChildPanel: FC<_ExpandedNestedChildPanelProps> = ({ dealIsFinalized }) => {
  const {
    expandedNestedChildContentRef,
    expandedNestedChildDataPoint,
    expandedNestedChildDataPointMutationState,
    setExpandedNestedChildDataPoint,
    setExpandedNestedChildDataPointMutationState
  } = useNestedChildDataPointsPanelContext()
  const { data_point_field } = expandedNestedChildDataPoint || {}
  const { data: success, error, loading } = expandedNestedChildDataPointMutationState || {}

  const closeExpandedNestedChild = () => {
    setExpandedNestedChildDataPoint?.(null)
    setExpandedNestedChildDataPointMutationState?.(null)
  }

  return (
    <Dialog
      PaperProps={{ sx: { backgroundColor: '#f1f5fa', boxShadow: 'none' } }}
      disablePortal
      fullScreen
      hideBackdrop
      onClose={closeExpandedNestedChild}
      open
      sx={{ display: expandedNestedChildDataPoint ? 'block' : 'none', position: 'absolute', top: 4, zIndex: Z_INDEX_MODAL }}
      transitionDuration={0}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          backgroundColor: '#fff',
          border: 0,
          boxShadow: '0 0.125em 0.313em rgba(50, 50, 93, 0.09), 0 0.063em 0.125em rgba(0, 0, 0, 0.07)',
          display: 'flex',
          fontSize: 16,
          fontWeight: 900,
          justifyContent: 'space-between',
          m: 0,
          opacity: 0.8,
          pb: 2,
          pt: 1.5,
          px: 5
        }}
      >
        {data_point_field?.name}
      </DialogTitle>

      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxWidth: 1200, mx: 'auto', width: '100%' }}>
        <DialogContent
          ref={expandedNestedChildContentRef}
          sx={{
            pt: 5,
            px: 5,
            '& > .MuiBox-root': { height: '100%' },
            '.MuiFormControl-root': { height: '100%' },
            '.MuiInputBase-root': { height: '100%' },
            'textarea[aria-invalid="false"]': { height: '100% !important', overflow: 'scroll !important' }
          }}
        />

        <DialogActions sx={{ gap: 2, mb: 2.5, mt: 0, mx: 5 }}>
          <SaveProgressIcon error={error} loading={Boolean(loading)} success={Boolean(success)} />

          <Button onClick={closeExpandedNestedChild} variant="contained">
            {`Close${dealIsFinalized ? '' : ' and save changes'}`}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

const PanelHeader: FC<_PanelHeaderProps> = ({ fieldName, onClose }) => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: '#fff',
      boxShadow: '0 0.125em 0.313em rgba(50, 50, 93, 0.09), 0 0.063em 0.125em rgba(0, 0, 0, 0.07)',
      display: 'flex',
      justifyContent: 'space-between',
      pl: 5,
      pr: 2,
      py: 2,
      zIndex: 1
    }}
  >
    {fieldName && (
      <Typography sx={{ fontWeight: 900, fontSize: 16, opacity: 0.8 }} variant="h6">
        {fieldName}
      </Typography>
    )}

    <Tooltip arrow placement="left" sx={{ m: -0.5 }} title="Close panel">
      <IconButton onClick={onClose} size="small">
        <CloseIcon />
      </IconButton>
    </Tooltip>
  </Box>
)
