import { ActiveComponents, useCciMainContext } from '../../CCI_Main'
import { Button } from '@mui/material'
import { Features, Permissions, useUserAccess } from '../../../../hooks/useUserAccess'
import React, { ReactNode, useEffect, useState } from 'react'
import css from './style.module.scss'

export default function Footer() {
  const { leftPanelLoading, selectedItem, setActiveComponent, tab } = useCciMainContext()
  const [footerButton, setFooterButton] = useState<ReactNode>()
  const hasChecklistCreateAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.CREATE_FIELD })
  const hasLimitedChecklistCreateAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.CREATE_FIELD_LIMITED })

  const generateFooterButton = (tab: string | undefined) => {
    if (!tab) {
      tab = 'checklist'
    }
    switch (tab) {
      case 'checklist':
        if (hasChecklistCreateAccess || hasLimitedChecklistCreateAccess) {
          createFooterButton(ActiveComponents.CREATE_FIELD)
        } else {
          setFooterButton(null)
        }
        break
      case 'documentTypes':
      case 'roles':
        setFooterButton(null)
        break
      case 'integrations':
        createFooterButton(ActiveComponents.CREATE_INTEGRATION)
        break
      case 'users':
        createFooterButton(ActiveComponents.CREATE_USER)
        break
    }
  }

  const createFooterButton = (activeComponent: ActiveComponents) => {
    setFooterButton(
      <div className={css.footer} style={{ padding: '0 20px' }}>
        <Button disableElevation onClick={() => setActiveComponent(activeComponent)} variant="contained">
          {activeComponent}
        </Button>
      </div>
    )
  }

  // eslint-disable-next-line
  useEffect(() => generateFooterButton(tab), [tab])

  return <>{leftPanelLoading ? null : tab === 'integrations' ? (selectedItem ? null : footerButton) : footerButton}</>
}
