import { Chip } from '@mui/material'
import { common, deepPurple } from '@mui/material/colors'
import React, { FC } from 'react'

// types

type _FeedbackOptionButtonProps = { isDisabled: boolean; isSelected: boolean; label: string; onClick: () => void }

// components

export const FeedbackOptionButton: FC<_FeedbackOptionButtonProps> = ({ isDisabled, isSelected, label, onClick }) => {
  return (
    <Chip
      disabled={isDisabled}
      label={label}
      onClick={onClick}
      size="small"
      sx={{
        bgcolor: common.white,
        flex: 1,
        px: 1,
        ...(isSelected
          ? {
              bgcolor: deepPurple[50],
              borderColor: deepPurple[600],
              color: deepPurple[600],
              '&:focus, &:hover': { bgcolor: `${deepPurple[50]} !important`, borderColor: deepPurple[600], color: deepPurple[600] }
            }
          : {})
      }}
      variant="outlined"
    />
  )
}
