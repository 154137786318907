import { API_ROOT } from './apiUtils'
import { CurrentUserDocument } from '../graphql/codegen/hooks'
import { IDToken } from '@okta/okta-auth-js'
import { captureError } from './sentry'
import { clearLocalAuthValues } from '../pages/Logout/Logout'
import { deleteCaches } from './cacheUtils'
import { oktaClient } from '../utils/oktaClient'
import { print } from 'graphql'
import axios from 'axios'
import axiosClient from './axiosClient'

// functions

export const getAuthorization = (idToken: string) => `Bearer ${btoa(`${idToken}__||__${localStorage.getItem('customerId')}`)}`

// Fetch identity provider from RevRec backend (empty string for internal Klarity users)
export const fetchIDP = async (userEmail: string): Promise<string> => {
  const { data } = await axios.post(`${API_ROOT}/idp`, { user_name: userEmail })

  return data.idp_id
}

// The Okta client automatically refreshes/validates tokens, so they should be fetched from the client, not local storage
export const getIdToken = async () => ((await oktaClient.tokenManager.get('idToken')) as IDToken)?.idToken

// For bootstrapping before we have the ApolloClient set up
export const getCurrentUserInApolloFormat = async () => {
  const idToken = await getIdToken()

  if (!idToken) return null

  const res = await axiosClient.post('/graphql', { query: print(CurrentUserDocument) })

  return res.data.data.current_user
}

export const logOut = async () => {
  await deleteCaches()

  localStorage.removeItem('customerId')
  localStorage.removeItem('messages') // TODO: Remove this once we are able to persist messages on the backend
  localStorage.removeItem('isAnnotator')

  try {
    await oktaClient.signOut() // Redirects to Okta signout page, which redirects back to /logout (where local auth values are cleared)
  } catch (error) {
    captureError(error, 'oktaClient sign out error')

    if (window.location.pathname === '/login') {
      clearLocalAuthValues()
    } else {
      window.location.replace('/logout')
    }
  }
}
