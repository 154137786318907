import React, { FC, SyntheticEvent, useEffect, useRef } from 'react'
import css from './style.module.scss'

// types

type _PercentageProps = {
  ariaLabel?: string
  isError?: boolean
  isFocused?: boolean
  name?: string
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void
  onKeyDown?: (e: any) => void
  setFocused?: (arg: boolean) => void
  value: string
}

// components

const Percentage: FC<_PercentageProps> = ({ ariaLabel, isError, isFocused, name, onChange, onKeyDown, setFocused, value }) => {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.focus()
    }
  }, [isFocused])

  return (
    <div>
      <input
        aria-label={ariaLabel || name || 'Enter a percentage'}
        className={css.input}
        onBlur={() => (setFocused ? setFocused(false) : null)}
        onChange={onChange}
        onFocus={() => (setFocused ? setFocused(true) : null)}
        onKeyDown={onKeyDown}
        placeholder={name}
        ref={ref}
        style={{ border: `${isError ? '1px solid red' : ''}` }}
        type="number"
        value={value}
      />
    </div>
  )
}

export default Percentage
