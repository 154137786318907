import React, { FC, SyntheticEvent, useEffect, useRef } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type _PercentageProps = {
  ariaLabel?: string
  handleBlur?: any
  id?: string
  isDisabled?: boolean
  isFocused?: boolean
  name?: string
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void
  onKeyDown?: (e: any) => void
  setFocused?: (arg: boolean) => void
  value: string
}

// components

const Percentage: FC<_PercentageProps> = ({ ariaLabel, handleBlur, id, isDisabled, isFocused, onChange, onKeyDown, setFocused, value }) => {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.focus()
    }
  }, [isFocused])

  return (
    <div className={clsx(isDisabled && css.disabled)}>
      <input
        aria-label={ariaLabel || 'Enter a percentage'}
        className={css.input}
        disabled={isDisabled}
        id={id}
        onBlur={event => {
          setFocused?.(false)
          handleBlur?.(event)
        }}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
          if (onChange) {
            onChange(e)
          }
        }}
        onFocus={() => (setFocused ? setFocused(true) : null)}
        onKeyDown={onKeyDown}
        ref={ref}
        style={{ ...(!isDisabled && { backgroundColor: '#fff' }) }}
        type="number"
        value={value}
      />
    </div>
  )
}

export default Percentage
