import { Box, Tooltip } from '@mui/material'
import { ModalOptions, useCciMainContext } from '../../../../../CCI_Main'
import { Sources } from '../../ChecklistTab/CreateInputs/SourceInput'
import { useCciChecklistGptContext } from '../CCI_RightPanel_ChecklistGptTab'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Button from '@mui/material/Button'
import React, { FC, useMemo } from 'react'

// components

export const ChecklistGptButton: FC = () => {
  const { openModal } = useCciMainContext()
  const { actionTypeMap, fieldValues, updateFieldValue } = useCciChecklistGptContext()
  const { action_type_id: actionTypeId, group, name, source } = fieldValues || {}

  const tooltipTitle = useMemo(() => {
    if (source !== Sources.INTERNAL && source !== Sources.MANUAL) {
      return 'This action type is not supported'
    } else if (!name) {
      return 'Enter a field name'
    } else if (!actionTypeId) {
      return 'Select an action type'
    } else if (!group) {
      return 'Select a section or type to create a new section'
    } else {
      return ''
    }
  }, [actionTypeId, group, name, source])

  const isDisabled = useMemo(() => Boolean(tooltipTitle), [tooltipTitle])

  const onClick = () =>
    openModal({
      content: { actionType: actionTypeMap[actionTypeId].name, fieldName: name, handleData: updateFieldValue },
      modalOption: ModalOptions.CHECKLIST_GPT,
      title: ''
    })

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 1 }}>
      <Tooltip PopperProps={{ sx: { maxWidth: 292 } }} arrow placement="left" title={tooltipTitle}>
        <span>
          <Button color="secondary" disabled={isDisabled} onClick={onClick} startIcon={<AutoAwesomeIcon />} variant="contained">
            Get Config Recommendations
          </Button>
        </span>
      </Tooltip>
    </Box>
  )
}
