import Button from '../../Button'
import React, { FC, ReactNode, SyntheticEvent } from 'react'
import css from './style.module.scss'

// types

type _MessageModalProps = {
  closeModal: any
  message?: any
  messageNode?: ReactNode
  messageTitle: string
  primaryButton?: ReactNode
}

// components

export const MessageModal: FC<_MessageModalProps> = ({ closeModal, message, messageNode, messageTitle, primaryButton }) => {
  const handleClose = (event: SyntheticEvent) => {
    event.stopPropagation()

    closeModal()
  }

  return (
    <div className={css.messageModal}>
      <h2>{messageTitle}</h2>

      {message && (
        <>
          {message?.errors?.length > 0 ? (
            message?.errors?.map((error: any, index: any) => <p key={`${error?.message}${index}`}>{error?.message}</p>)
          ) : message?.message ? (
            <p>{message?.message}</p>
          ) : (
            <p>{message}</p>
          )}
        </>
      )}

      {messageNode && <p>{messageNode}</p>}

      <div className={css.modalButtonRow}>
        <Button onClick={handleClose} variant={primaryButton ? 'secondary' : 'primary'}>
          Close
        </Button>

        {primaryButton && primaryButton}
      </div>
    </div>
  )
}
