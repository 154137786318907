import { Features, Permissions, useIsAnnotator, useUserAccess } from './useUserAccess'
import { useLocation } from 'react-router-dom'
import useIsRapid7LegalTeam from './useIsRapid7LegalTeam'

// enums

export enum QueryTypes {
  PREVIOUS_ATTACHMENT_QUERY = 'PREVIOUS_ATTACHMENT_QUERY',
  PREVIOUS_DEAL_QUERY = 'PREVIOUS_DEAL_QUERY',
  PREVIOUS_DOCUMENT_QUERY = 'PREVIOUS_DOCUMENT_QUERY'
}

// functions

export const usePrevQueryType = () => {
  const location = useLocation()
  const isAnnotator = useIsAnnotator()
  const isRapid7LegalTeam = useIsRapid7LegalTeam()
  const hasDealAccess = useUserAccess({ feature: Features.DEAL_DASHBOARD, permission: Permissions.READ })

  const getPreviousQueryType = () => {
    if (isAnnotator) {
      if (location?.pathname?.includes('/dashboard/deals')) {
        return QueryTypes.PREVIOUS_DEAL_QUERY
      } else if (location?.pathname?.includes('/dashboard/documents')) {
        return QueryTypes.PREVIOUS_DOCUMENT_QUERY
      } else if (location?.pathname?.includes('/dashboard/attachments')) {
        return QueryTypes.PREVIOUS_ATTACHMENT_QUERY
      }
    } else if (isRapid7LegalTeam) {
      if (location?.pathname?.includes('/dashboard/deals')) {
        return QueryTypes.PREVIOUS_DEAL_QUERY
      } else if (location?.pathname === '/') {
        return QueryTypes.PREVIOUS_DOCUMENT_QUERY
      } else if (location?.pathname?.includes('/dashboard/attachments')) {
        return QueryTypes.PREVIOUS_ATTACHMENT_QUERY
      }
    } else {
      if (location?.pathname === '/') {
        return hasDealAccess ? QueryTypes.PREVIOUS_DEAL_QUERY : QueryTypes.PREVIOUS_DOCUMENT_QUERY
      } else if (location?.pathname?.includes('/dashboard/documents')) {
        return QueryTypes.PREVIOUS_DOCUMENT_QUERY
      } else if (location?.pathname?.includes('/dashboard/attachments')) {
        return QueryTypes.PREVIOUS_ATTACHMENT_QUERY
      } else {
        return ''
      }
    }
  }

  const prevQueryType = getPreviousQueryType()

  return prevQueryType || null
}
