import { AiOutlineTable } from 'react-icons/ai'
import { Alert, Snackbar } from '@mui/material'
import { Annotation, CreateAnnotationSuccess, DataPoint, DocumentEdge, MutateAnnotation } from '../../graphql/codegen/schemas'
import {
  DataPointsForGroupDocument,
  DocumentsDocument,
  useCreateAnnotationMutation,
  useDeleteAnnotationMutation,
  useDocumentsQuery,
  useUpdateAnnotationReviewStateMutation
} from '../../graphql/codegen/hooks'
import { DocumentsQuery } from '../../graphql/codegen/operations'
import { ExportToCsv } from 'export-to-csv'
import { Highlight, PdfHighlighter as ReactPdfHighlighter, Table } from '@klarity/pdf-highlighter'
import { HighlightPopup } from './HighlightPopup'
import { HighlighterHighlight, parseIdFromHash, positionToAnnotationPositionInput, removeDataPointSearchParam, updateHash } from './utils'
import { MoreMenu, _MenuItem } from '../MoreMenu'
import { NovelSegment, ReviewStates, _IsConfidenceMutationBusyMap, useConfidenceGlobalContext } from '../Confidence'
import { Opening, useOpening } from '@hoologic/use-opening'
import { isEmpty } from 'lodash'
import { useAppContext } from '../../app'
import { useHistory, useParams } from 'react-router-dom'
import { useIsAnnotator, useIsSuperAnnotator } from '../../hooks/useUserAccess'
import { useKeyPressed } from '../../hooks/useKeyPressed'
import AnnotationMissingAlert from './AnnotationMissingAlert'
import AnnotationRetryModal from './AnnotationRetryModal'
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import Tip from './Tip'
import copy from 'copy-to-clipboard'
import useCurrentUser from '../../hooks/useCurrentUser'
import useGetOperatingSystem from '../../hooks/useGetOperatingSystem'
import useIsPreAnnot from '../../hooks/useIsPreAnnot'
import type { _TableCell } from '../DocumentPanel/TablesQueryButton/TablesQueryButton'

// types

type _PDFHighlighterWrapperProps = {
  dealIsFinalized: any
  documentId: any
  highlights: (HighlighterHighlight | null)[]
  isConfidenceMutationBusyMap: _IsConfidenceMutationBusyMap
  isDataTableSelectedList: boolean[][]
  novelSegments: (HighlighterHighlight | null)[]
  onDocumentReady: any
  onPageChange: any
  pdfDocumentProxy: any
  scale: any
  setIsConfidenceMutationBusyMap: Dispatch<SetStateAction<_IsConfidenceMutationBusyMap>>
  setIsDataTableSelectedList: Dispatch<SetStateAction<boolean[][]>>
  showAllTags: any
  tables: _TableCell[][][][]
}

// hooks

const useShouldBlockAnnotation = (data: DocumentsQuery | undefined) => {
  const isAnnotator = useIsAnnotator()
  const isSuperAnnotator = useIsSuperAnnotator()
  const currUser = useCurrentUser()

  return data?.documents?.edges[0]?.node?.internal_status === 'COMPLETE'
    ? false
    : isAnnotator && !isSuperAnnotator && data?.documents?.edges[0]?.node?.internal_assignee?.id !== currUser?.id
}

// components

export const PDFHighlighterWrapper: FC<_PDFHighlighterWrapperProps> = ({
  dealIsFinalized,
  documentId,
  highlights,
  isConfidenceMutationBusyMap,
  isDataTableSelectedList,
  novelSegments,
  onDocumentReady,
  onPageChange,
  pdfDocumentProxy,
  scale,
  setIsConfidenceMutationBusyMap,
  setIsDataTableSelectedList,
  showAllTags,
  tables
}) => {
  const { isConfidenceVisibleMap, isReviewingNovelSegmentsMap } = useConfidenceGlobalContext()
  const { data: documentData } = useDocumentsQuery({ skip: !documentId, variables: { documentId } })
  const { dealId } = useParams<{ dealId?: string }>()
  const { isPreAnnot } = useIsPreAnnot()
  const { setErrorMessage, setIsAnnotationAdded, setIsAnnotationDeleted } = useAppContext()
  const [retryModalContent, setRetryModalContent] = useState<null | { create_annotation: any; prevAnnotation: any; prevSelectedLabel: string | null }>(null)
  const copiedOpening = useOpening()
  const prevAnnotationRef = useRef<{ prevAnnotation: null | MutateAnnotation; prevSelectedLabel: string | null }>({
    prevAnnotation: null,
    prevSelectedLabel: null
  })
  const scrollRef = useRef<(highlight: any) => void>()
  const shouldBlockAnnotation = useShouldBlockAnnotation(documentData)
  const history = useHistory()

  const clearHash = useCallback(() => history.replace({ pathname: window.location.pathname, search: window.location.search }), [history])

  useEffect(() => {
    if (!isEmpty(tables)) {
      setIsDataTableSelectedList(tables.map(tableList => tableList.map(() => false)))
    }
  }, [setIsDataTableSelectedList, tables])

  const copyTable = (table: { text: string }[][], copiedOpening: Opening) => {
    let counter = 1

    copy(
      [table[0].map(({ text }) => text || `<Untitled ${counter++}>`).join('\t'), ...table.slice(1).map(row => row.map(({ text }) => text).join('\t'))].join(
        '\n'
      ),
      {
        format: 'text/plain'
      }
    )

    copiedOpening.open()
  }

  const downloadTable = (table: { text: string }[][]) => {
    const options = {
      filename: 'table'
    }

    let counter = 1

    new ExportToCsv(options).generateCsv([
      table[0].map(({ text }) => text || `<Untitled ${counter++}>`),
      ...table.slice(1).map(row => row.map(({ text }) => text))
    ])
  }

  const scrollToHighlight = useCallback(
    targetHighlight => {
      const foundHighlight = highlights.find(highlight => highlight !== null && highlight.id === targetHighlight.id)

      scrollRef?.current?.(foundHighlight)

      if (!foundHighlight) {
        setErrorMessage('Annotation not found in document. Please contact us for assistance.')
      }
    },
    [highlights, setErrorMessage]
  )

  const scrollToHighlightFromHash = useCallback(() => {
    const id = parseIdFromHash()

    const foundNovelSegment = novelSegments.find(novelSegment => novelSegment?.id === id)

    if (foundNovelSegment) {
      scrollRef?.current?.(foundNovelSegment)

      return
    }

    if (id) scrollToHighlight({ id })
  }, [novelSegments, scrollToHighlight])

  useEffect(() => {
    if (scrollRef?.current) {
      scrollToHighlightFromHash()
      window.addEventListener('hashchange', scrollToHighlightFromHash)

      return () => window.removeEventListener('hashchange', scrollToHighlightFromHash)
    }
  }, [highlights, scrollToHighlightFromHash])

  const [createAnnotationMutation] = useCreateAnnotationMutation({
    onCompleted: ({ create_annotation }) => {
      setIsAnnotationAdded(true)

      if (create_annotation?.__typename === 'CreateAnnotationRetry') {
        const { prevAnnotation, prevSelectedLabel } = prevAnnotationRef.current
        setRetryModalContent({ create_annotation, prevAnnotation, prevSelectedLabel })
      }
    },
    refetchQueries: response =>
      (response.data?.create_annotation?.data_points as DataPoint[]).map(dataPoint => ({
        query: DataPointsForGroupDocument,
        variables: { group: dataPoint.data_point_field?.group, resourceId: dealId || documentId }
      })),
    update: (cache, { data }) => {
      // CreateAnnotationSuccess includes the new annotation as the only edge in the annotations connection.
      const newAnnotation = (data?.create_annotation as CreateAnnotationSuccess)?.document?.annotations?.edges[0]

      if (newAnnotation) {
        cache.modify({
          id: cache.identify({ __typename: 'Document', id: documentId }),
          fields: { annotations: annotations => ({ ...annotations, edges: [...annotations.edges, newAnnotation] }) }
        })
      }
    }
  })

  const createAnnotation = (annotation: MutateAnnotation, selectedLabel: string) => {
    // Store the prev value for retries
    prevAnnotationRef.current.prevAnnotation = annotation
    prevAnnotationRef.current.prevSelectedLabel = selectedLabel

    return createAnnotationMutation({ variables: { documentId, annotation } })
  }

  // The backend does not respond with the ID of the deleted annotation, so it must be tracked here to handle the manual cache update.
  const [deletedAnnotationId, setDeletedAnnotationId] = useState<string | null>(null)

  const [deleteAnnotationMutation, { loading: deleteMutationLoading }] = useDeleteAnnotationMutation({
    onCompleted: () => {
      setIsAnnotationDeleted(true)

      setDeletedAnnotationId(null)
    },
    // The cache is updated manually to avoid straining the backend with a request for the entire updated document.
    update: cache => {
      const documentData = cache.readQuery<DocumentsQuery>({ query: DocumentsDocument, variables: { documentId } })

      if (!documentData) return

      cache.writeQuery<DocumentsQuery>({
        query: DocumentsDocument,
        variables: { documentId },
        data: {
          documents: {
            ...documentData.documents,
            edges: documentData.documents!.edges.map(edge => ({
              ...edge,
              node: {
                ...edge!.node,
                annotations: { ...edge!.node!.annotations, edges: edge!.node!.annotations!.edges.filter((edge: any) => edge.node.id !== deletedAnnotationId) }
              }
            })) as DocumentEdge[]
          }
        }
      })
    }
  })

  const deleteAnnotation = (annotationId: string) => {
    clearHash()
    setDeletedAnnotationId(annotationId)
    deleteAnnotationMutation({ variables: { documentId, annotationId } })
  }

  const [updateAnnotationReviewState] = useUpdateAnnotationReviewStateMutation({
    onCompleted: data =>
      setIsConfidenceMutationBusyMap(isConfidenceMutationBusyMap => ({
        ...isConfidenceMutationBusyMap,
        [data.update_annotation_review_state!.annotation.id]: false
      }))
  })

  const toggleReviewState = useCallback(
    (novelSegment: Annotation) => {
      setIsConfidenceMutationBusyMap(isConfidenceMutationBusyMap => ({ ...isConfidenceMutationBusyMap, [novelSegment.id]: true }))

      updateAnnotationReviewState({
        variables: {
          documentId,
          annotationId: novelSegment.id,
          reviewState: novelSegment.review_state === ReviewStates.REVIEWED ? ReviewStates.NEEDS_REVIEW : ReviewStates.REVIEWED
        }
      })
    },
    [documentId, setIsConfidenceMutationBusyMap, updateAnnotationReviewState]
  )

  const getMenuItemList = useCallback(
    (pageIndex: number, table: any, tableIndex: number): _MenuItem[] => [
      { label: 'Copy table', onClick: () => copyTable(table, copiedOpening) },
      { label: 'Download table', onClick: () => downloadTable(table) },
      {
        label: `${isDataTableSelectedList[pageIndex]?.[tableIndex] ? 'Des' : 'S'}elect table`,
        onClick: () =>
          setIsDataTableSelectedList(isDataTableSelectedList =>
            isDataTableSelectedList.map((tablePageList, tablePageIndex) =>
              tablePageIndex === pageIndex
                ? tablePageList.map((isTableSelected: boolean, index: number) => (index === tableIndex ? !isTableSelected : isTableSelected))
                : tablePageList
            )
          )
      }
    ],
    [copiedOpening, isDataTableSelectedList, setIsDataTableSelectedList]
  )

  // Hide highlights when ctrl is pressed to enable stacking highlights
  const operatingSystem = useGetOperatingSystem()
  const isHighlightingHidden = useKeyPressed(event => (operatingSystem === 'Mac' ? event.ctrlKey : event.altKey))

  return (
    <>
      <AnnotationRetryModal
        documentId={documentId}
        isOpen={!!retryModalContent}
        modalContent={retryModalContent}
        onRequestClose={() => setRetryModalContent(null)}
      />

      <ReactPdfHighlighter
        highlights={!isHighlightingHidden && highlights ? highlights : []}
        isBusyMap={isConfidenceMutationBusyMap}
        novelSegments={isHighlightingHidden ? [] : novelSegments}
        onDocumentReady={onDocumentReady}
        onPageChange={onPageChange}
        onScrollChange={() => {
          removeDataPointSearchParam()
          clearHash()
        }}
        onSelectionFinished={(position, content, hideTipAndSelection, transformSelection) => {
          if (shouldBlockAnnotation || isPreAnnot || dealIsFinalized) return null
          return (
            <Tip
              documentId={documentId}
              onConfirm={(selectedTag: { label: string; value: string }) => {
                const internal_name = selectedTag.value
                const positions = [positionToAnnotationPositionInput(position, content)]
                const annotation = { internal_name, positions }
                createAnnotation(annotation, selectedTag.label).then(hideTipAndSelection)
              }}
              onOpen={transformSelection}
            />
          )
        }}
        pdfDocument={pdfDocumentProxy}
        pdfScaleValue={scale}
        renderAnnotationMissingAlert={(annos: any) => (
          <AnnotationMissingAlert annotations={annos} deleteAnnotation={dealIsFinalized ? null : deleteAnnotation} loading={deleteMutationLoading} />
        )}
        renderHighlight={(highlight, isHighlightPopupDismissedViaClick, isScrolledTo) => {
          // For highlights that have misconfigured positions, eventually this will be removed as the docx to pdf highlighting improves
          if (highlight.labelType === 'MISSING_ANNO') {
            return null
          }
          const key = highlight.id || 'new-highlight' // highlights won't have id's until they are created on the backend, but there will only ever be one of these at a time

          return (
            <Highlight
              highlight={highlight}
              isScrolledTo={!isHighlightPopupDismissedViaClick && isScrolledTo}
              key={key}
              onClick={e => {
                e.preventDefault()
                removeDataPointSearchParam()
                updateHash(highlight)
                scrollToHighlight(highlight)
              }}
              renderPopup={highlight =>
                !isHighlightPopupDismissedViaClick && (
                  <HighlightPopup
                    deleteAnnotation={shouldBlockAnnotation || isPreAnnot || dealIsFinalized ? null : deleteAnnotation}
                    highlight={highlight}
                    isScrolledTo={isScrolledTo}
                  />
                )
              }
              showAllTags={showAllTags}
            />
          )
        }}
        renderNovelSegment={novelSegment =>
          isConfidenceVisibleMap[documentId] &&
          isReviewingNovelSegmentsMap[documentId] && (
            <NovelSegment
              isConfidenceMutationBusyMap={isConfidenceMutationBusyMap}
              key={novelSegment.id}
              novelSegment={novelSegment}
              toggleReviewState={toggleReviewState}
            />
          )
        }
        renderTable={(pageIndex, table, tableIndex, viewport) => (
          <Table
            icon={<MoreMenu icon={<AiOutlineTable />} menuItemList={getMenuItemList(pageIndex, table, tableIndex)} />}
            index={tableIndex}
            isSelected={isDataTableSelectedList[pageIndex]?.[tableIndex]}
            key={`${pageIndex}-${tableIndex}`}
            table={table}
            viewport={viewport}
          />
        )}
        scrollRef={triggerScrollTo => {
          scrollRef.current = triggerScrollTo
          scrollToHighlightFromHash()
        }}
        tables={!isHighlightingHidden && tables ? tables : []}
      />

      <Snackbar autoHideDuration={3000} onClose={copiedOpening.close} open={copiedOpening.isOpen}>
        <Alert severity="success">Table copied.</Alert>
      </Snackbar>
    </>
  )
}
