import { DataPointField } from '../DatapointField'
import { useParams } from 'react-router-dom'
import Card from '../Card'
import React from 'react'
import useSummaryDataPoints from '../../hooks/useSummaryDataPoints'

export default function SummaryTab({ dealIsFinalized }: { dealIsFinalized: boolean }) {
  const { dealId } = useParams<{ dealId: string }>()
  const summaryData = useSummaryDataPoints(dealId)

  return (
    <Card style={{ maxHeight: '90%', overflowY: 'scroll' }}>
      <div style={{ padding: 16 }}>
        {summaryData?.map(dp => {
          return <DataPointField dataPoint={dp} dealIsFinalized={dealIsFinalized} key={'SummaryTab' + dp.id} />
        })}
      </div>
    </Card>
  )
}
